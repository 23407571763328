<template>
  <v-container>
    <v-row v-if="!loading">

      <v-col cols="12" md="12" v-if="profile_shop.master === true || $store.state.auth.user.user.permition_level === 99"
        justify="center">
        <StatisticsDashboard :compras="compras" :compra_location="compra_location" />

      </v-col>



      <v-col cols="12" md="12"
        v-if="profile_shop.solicitante === true || profile_shop.master === true || $store.state.auth.user.user.permition_level === 99"
        class="mt-6" justify="center">
        <ApplicantDashboard :status_request="status_request" :compra_location="compra_location" />

      </v-col>


    
      <v-col cols="12" md="12" v-if="profile_shop.contador===true ||profile_shop.master===true||$store.state.auth.user.user.permition_level === 99" class="mt-6" justify="center" >
        <v-card outlined>
        <Approvers :series="series_contador" :labels="labels_contador" :all_data="contador_data"  />
      </v-card>

      </v-col>
    
      <v-col cols="12" md="12"   v-if="profile_shop.aprobador===true ||profile_shop.master===true||$store.state.auth.user.user.permition_level === 99" class="mt-6" justify="center">
        <v-card outlined>

 
        <Approvers :series="series_aprobador" :labels="labels_aprobador" :all_data="aprobador_data"  />
      </v-card>
      </v-col>
    
      <v-col   cols="12" md="12"  v-if="profile_shop.comprador===true ||profile_shop.master===true||$store.state.auth.user.user.permition_level === 99" class="mt-8"  justify="center">
      <v-card outlined>
        <OrderDashboard :orders="orders" />

      </v-card>
     
    </v-col>

     
    </v-row>

  </v-container>
</template>

<script>
import StatisticsDashboard from "@/components/Shop/Dashboards/StatisticsDashboard.vue";
import ApplicantDashboard from "@/components/Shop/Dashboards/ApplicantDashboard.vue";
import QuoteDashboard from "@/components/Shop/Dashboards/QuoteDashboard.vue";
import OrderDashboard from "@/components/Shop/Dashboards/OrderDashboard.vue";
import ReceiptDashboard from "@/components/Shop/Dashboards/ReceiptDashboard.vue";
import Approvers from "@/components/Shop/Dashboards/Approvers.vue";

export default {
  components: {
    StatisticsDashboard,
    ApplicantDashboard,
    OrderDashboard,
    QuoteDashboard,
    ReceiptDashboard,
    Approvers
  },
  props: {
    filters: {
      type: Object,
      default: function () {
        return {
          business: null,
          location: null,
          department_id: null,
        };
      },
    },
  },
  watch: {
    filters: {
      handler: function (nuevoValor, viejoValor) {
        // Lógica para manejar el cambio del objeto filters
        this.updateData(nuevoValor);
      },
      deep: true, // Para obserlet cambios profundos en el objeto filters
      immediate: true, // Para ejecutar el watch inmediatamente al crear el componente
    },
  },
  data: () => ({
    loading: false,
    status_request: { series: [], label: [] },
    compra_location: { series: [], label: [] },
    compras: { series: [], label: [] },
    orders: { series: [], label: [] },
    series_contador: [],
    labels_contador: [],
    contador_data: [],
    series_aprobador: [],
    labels_aprobador: [],
    aprobador_data: [],
    profile_shop: {
      master: false,
      solicitante: false,
      comprador: false,
      aprobador: false,
      contador: false,
      recepcionista: false,
      facturacion: false,
    },
    series: [44, 55, 13, 43, 22],
    chartOptions: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: [" Solicitados", " Cotizados", "Pendientes  ", "Rechazados", "Comprado"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  }),

  methods: {
    async updateData(data) {
      this.loading = true
    
      const typeAccess = this.$store.state.auth.user.type_access;

const hasAccessProfile17 = typeAccess.some((items) => items.access.acces_profile === 17);

if (hasAccessProfile17) {
  await this.getMaster(data);
  this.profile_shop.master = true;
  await this.Getsolicitante(data);
        this.profile_shop.solicitante = true;
        await this.getComprador(data);
        this.profile_shop.comprador = true;
        await this.getAprover();
        this.profile_shop.aprobador = true;
        await this.getAprover();
        this.profile_shop.aprobador = true;
        
} else {
  typeAccess.forEach(async (items) => {
    switch (items.access.acces_profile) {
      case 19:
        await this.Getsolicitante(data);
        this.profile_shop.solicitante = true;
        break;
      case 20:
        await this.getComprador(data);
        this.profile_shop.comprador = true;
        break;
      case 21:
        await this.getAprover();
        this.profile_shop.aprobador = true;
        break;
      case 22:
        await this.getContador();
        this.profile_shop.contador = true;
        break;
      case 23:
        this.profile_shop.recepcionista = true;
        break;
      case 24:
        this.profile_shop.facturacion = true;
        break;
    }
  });
}
      this.loading = false
    },
    async getMaster(filter) {
      let resultComprasMonth = await this.api_Post("income/dashboard_montly", {
        invoice: 1,
        business: filter.business,
      });
      let comprasMonth = await resultComprasMonth.json();
      let resultCompraLocations = await this.api_Post("income/dashboard_location", {
        invoice: 1,
        business: filter.business,
      });
      let comprasLocations = await resultCompraLocations.json();

      let compra_location_label = [];
      let compra_location_series = [];
      let compras_label = [];
      let compras_series = [];

      for (let item of comprasMonth) {
        compras_label.push(item.month_year);
        compras_series.push(item.total_sales);
      }
      for (let item of comprasLocations) {
        compra_location_label.push(item.description_short);
        compra_location_series.push(parseFloat(item.total_amount_sum));
      }


      this.compra_location.label = compra_location_label;
      this.compra_location.series = compra_location_series;
      this.compras.label = compras_label;
      this.compras.series = compras_series;

    },
    async Getsolicitante(filter) {
      let resultStatusRquest = await this.api_Post("shopping/get_request_status", {
        business: filter.business,
      });
      let statusRquest = await resultStatusRquest.json();



      let status_request_label = [];
      let status_request_series = [];

      for (let item of statusRquest) {
        status_request_label.push(item.status_description);
        status_request_series.push(parseFloat(item.quantity));
      }


      this.status_request.label = status_request_label;
      this.status_request.series = status_request_series;

    },
    async getContador() {
      let filters = " "
      filters += " AND  BUSINESS_UNIT_ID in (" + this.$store.state.auth.user.acess_business_unit_finance + ") "
      filters += "AND last_status_id in  (2,3) "

      this.contador_data =[]
      this.labels_contador=[]
      this.series_contador=[]
      let resultOrders = await this.api_Post("shopping/orders_dasboard", { filters: filters });
      let data = await resultOrders.json()
      this.contador_data = data
      for (let item of data) {
        this.labels_contador.push(item.name)
        this.series_contador.push(parseFloat(item.quantity))
      }
    },
    async getAprover() {

      let resultLevel = await this.api_Post("shopping/order_level_user", { user_id: this.$store.state.auth.user.user.user_id });
      let level = await resultLevel.json()
      let status = level.length > 0 ? level[0].status_id : 0
      let location = level.map((elementoOriginal) => {
        return elementoOriginal.location_id
      })
     
      let st = "" + status + "," + (parseFloat(status) + 1)


      if(location.length>0)
      {
        let resultOrders = await this.api_Post("shopping/orders_aprovers", { locations: location, status: st });
      let data = await resultOrders.json()
      this.aprobador_data = data
      for (let item of data) {
        this.labels_aprobador.push(item.name)
        this.series_aprobador.push(parseFloat(item.quantity))
      }
      }
   
    },
    async getComprador(filter) {

      let resultStatusOrders = await this.api_Post("shopping/get_order_status", {
        business: filter.business,
      });
      let statusOrders = await resultStatusOrders.json();


      let status_orders_label = [];
      let status_orders_series = [];

      for (let item of statusOrders) {
        status_orders_label.push(item.last_status_name);
        status_orders_series.push(parseFloat(item.quantity));
      }


      this.orders.label = status_orders_label;
      this.orders.series = status_orders_series;
     }
  }
};
</script>

<style></style>
