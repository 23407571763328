<template>
  <v-container fluid>
    <v-data-table :headers="headers" :items="projects" :search="search" sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Proyectos</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="mt-2" v-model="search" label="Buscar"></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" outlined dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon>add</v-icon>
                Nuevo proyecto
              </v-btn>
            </template>
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Nuevo Projecto</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="close"> Save </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card class="mt-4" elevation="0">
                <v-row class="mt-0" justify="center">
                  <v-col cols="12" sm="3" md="3">
                    <v-autocomplete dense outlined v-model="editedItem.business_unit_id" :items="itemsBussines"
                      label="Unidad de negocio" color="cyanText" @change="
                        itemsLocations2 = updateCombo(
                          itemsLocations,
                          itemsLocations2,
                          editedItem.business_unit_id,
                          'location'
                        )
                        " prepend-inner-icon="corporate_fare" required></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-autocomplete dense outlined v-model="editedItem.location_id" :items="itemsLocations2"
                      color="cyanText" label="Location" prepend-inner-icon="my_location" required></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-autocomplete outlined dense v-model="filters.department_id" :items="itemsDepartment" return-object
                      color="cyanText" label="Departamento" prepend-inner-icon="corporate_fare" required></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-card width="80%" height="85vh" outlined>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-row class="mt-5" no-gutters dense justify="center">
                            <v-col cols="12" md="8">
                              <v-text-field v-model="editedItem.name" label="Nombre Projecto" outlined />
                            </v-col>
                          </v-row>
                          <v-row no-gutters dense justify="center">
                            <v-col cols="12" md="8">
                              <v-text-field v-model="editedItem.code" label="Codigo Projecto" outlined />
                            </v-col>
                          </v-row>
                          <v-row dense justify="center">
                            <v-col cols="12" sm="4" md="4">
                              <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="10"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field outlined v-model="dateFormatDMY" label="Desde"
                                    prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field outlined v-model="date2FormatDMY" label="Hasta"
                                    prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-row no-gutters dense justify="center">
                            <v-col cols="12" md="8">
                              <v-text-field v-model="editedItem.contract_code" label="Codigo contracto" outlined />
                            </v-col>
                          </v-row>
                          <v-row no-gutters dense justify="center">
                            <v-file-input v-show="false" id="fileInput" v-model="url_image"
                              @change="onFileChange"></v-file-input>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-row class="mt-n0" no-gutters justify="center" dense>
                            <v-card width="100%" height="auto" outlined>
                              <v-hover v-slot="{ hover }">
                                <v-card flat height="65vh" @click="takeImage()" :class="hover ? 'grey lighten-3' : 'grey lighten-4'
                                  ">
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col cols="12" md="12" align="center">
                                        <v-icon v-if="image_storage == null" color="grey lighten-2"
                                          x-large>add_a_photo</v-icon>
                                        <iframe v-else style="
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 640px;
                                          " :src="image_storage" frameborder="0">
                                        </iframe>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card>
                              </v-hover>
                            </v-card>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row justify="center" class="mt-16">
                        <v-btn plain>Cancelar</v-btn>
                        <v-btn @click="save" color="primary" outlined>Guardar</v-btn>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-row>
              </v-card>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { throws } from "assert";
import moment from "moment";
export default {
  name: "my-perfil",
  data() {
    return {
      dialogDelete: false,
      search: "",
      headers: [
        {
          text: "Codigo",
          align: "start",
          sortable: false,
          value: "code",
        },
        { text: "Nombre", value: "name" },
        { text: "Unidad negocio", value: "business_description_long" },
        { text: "Locartion", value: "location_description_long" },

        { text: "Fecha inicio", value: "start_date" },
        { text: "Fecha final", value: "end_date" },

        { text: "Actions", value: "actions", sortable: false },
      ],
      projects: [],
      editedIndex: -1,
      editedItem: {},
      image_storage: null,
      date: moment().startOf("month").format("YYYY-MM-DD"),
      date2: moment().endOf("month").format("YYYY-MM-DD"),
      menu: false,
      menu2: false,
      dialog: false,
      url_image: "",
      itemsDepartment: [],
      department_id: null,
      filters: {
        business: null,
        location: null,
        department_id: null,
        warehouse_id: null,
      },
      itemsBussines: [],
      itemsLocations: [],
      itemsLocations2: [],
    };
  },
  created() {
    this.init();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    dateFormatDMY() {
      return moment(this.date).format("DD/MM/YYYY");
    },
    date2FormatDMY() {
      return moment(this.date2).format("DD/MM/YYYY");
    },
  },

  methods: {
    async init() {
      const response = await this.api_Get("project/projects");

      this.projects = await response.json();

      this.itemsBussines = Object.assign([], this.$store.state.auth.bussines_units)

      this.filters.business = this.itemsBussines[0].value;
      this.itemsLocations = this.$store.state.auth.locations;
      this.itemsDepartment = await this.g_departments();
    },
    async editItem(item) {
      this.editedIndex = this.projects.indexOf(item);

      this.editedItem = Object.assign({}, item);

      this.date = this.editedItem.start_date;
      this.dat2 = this.editedItem.end_date;
      this.filters.business = this.editedItem.business_unit_id;
      this.filters.location = this.editedItem.location_id;

      this.image_storage = await this.get_storage(
        "contracts",
        this.editedItem.contract_url
      );
      this.itemsLocations2 = this.updateCombo(
        this.itemsLocations,
        this.itemsLocations2,
        this.editedItem.business_unit_id,
        "location"
      );
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.projects.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.projects.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.image_storage = null;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedItem.business_unit_id == null) {
        this.$toast.info("Debes selecionar una unidad de negocio !!!");
        return;
      }
      if (this.editedItem.location_id == null) {
        this.$toast.info("Debes selecionar una location!!!");
        return;
      }
      if (
        this.editedItem.name == "" ||
        this.editedItem.name == undefined ||
        this.editedItem.name == null
      ) {
        this.$toast.info("Debes elegir un nombre!!!");
        return;
      }
      if (
        this.editedItem.code == "" ||
        this.editedItem.code == undefined ||
        this.editedItem.code == null
      ) {
        this.$toast.info("Debes elegir un codigo!!!");
        return;
      }
      try {
        let record_url = null;

        if (this.url_image != "") {
          const fileInput = document.getElementById("fileInput");
          let record = await this.upload_images("contracts", fileInput);
          record_url = await record.id;
        }

        let data = {
          id: this.editedItem.id == undefined ? null : this.editedItem.id,
          bussiness_unit_id: this.editedItem.business_unit_id,
          location_id:
            this.editedItem.location_id == undefined
              ? null
              : this.editedItem.location_id,
          department_id:
            this.editedItem.department_id == undefined
              ? null
              : this.editedItem.department_id,

          created_by: this.$store.state.auth.user.user.user_id,
          code: this.editedItem.code,
          name: this.editedItem.name,
          start_date: this.dateFormatDMY,
          end_date: this.date2FormatDMY,
          contract_code: this.editedItem.contract_code,
          contract_url: record_url,
          finished: true,
        };
        const response = await this.api_Post("project/projects", data);
        if (response.status == 200) {
          this.$toast.success("Contracto creado con exito");
          this.init();
        } else {
          this.$toast.error("error ");
        }
      } catch (e) {
        this.$toast.error("error " + e.message);
      }

      this.close();
    },

    getDate(date) {
      let newDate = moment(date).format("YYYY-MM-DD HH:MM:SS A").toString();
      return newDate;
    },
    takeImage() {
      //this.fileInput.click();
      document.getElementById("fileInput").click();

      //this.this.dialogImage = true;
    },
    onFileChange() {
      this.image_storage = URL.createObjectURL(this.url_image);
    },
    updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
      itemsDestino.splice(0, itemsDestino.length);

      for (let item of itemsOrigen) {
        if (item.bus_id === condicion) {
          itemsDestino.push({
            value: item.value,
            text: item.text,
            routes: item.routes,
          });
        }
      }

      //this.filters.location = this.itemsLocations2[0].value;

      return itemsDestino;
    },
  },
};
</script>
