<template>
  <v-card flat>
    <v-row justify="center">
      <v-col cols="12" md="6" class="pb-0">
        <v-row justify="end">
          <v-col cols="12" md="4" class="pa-0 pr-1">
            <v-autocomplete v-model="business_selected" :items="$store.state.auth.bussines_units"
              label="Unidad de negocio" color="cyanText" prepend-inner-icon="corporate_fare" outlined dense required
              :disabled="disable_all">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="pa-0 pr-1">
            <v-autocomplete v-model="location_selected" :items="locationsFilter" color="cyanText" label="Location"
              prepend-inner-icon="my_location" outlined return-object required dense :disabled="disable_all">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="pa-0 pr-1">
            <v-autocomplete v-model="filters.warehouse" :disabled="disable_all" @change="refresFilters()"
              :items="warehouseFilter" color="cyanText" label="Almacen" item-value="id" item-text="name"
              prepend-inner-icon="business" outlined required dense return-object>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="pb-0">
        <v-row justify="start">
          <v-col cols="12" md="3" class="pa-0 pr-1">
            <v-autocomplete label="Departamento" v-model="filters.department_id" color="cyanText"
              prepend-inner-icon="corporate_fare" outlined dense required :disabled="disable_all" :items="itemsDepartment"
              @change="refresFilters()">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="pa-0 pr-1">
            <v-autocomplete v-model="filters.project" :items="projectFilter" label="Proyecto" color="cyanText"
              :disabled="disable_all" return-object item-value="id" item-text="name" prepend-inner-icon="folder_open"
              outlined dense required @change="refresFilters()">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="pa-0 pr-1">
            <v-autocomplete v-model="filters.applicant" :items="applicantsFilter" label="Solicitante" color="cyanText"
              :disabled="disable_all" return-object item-value="id" item-text="employee_fullname"
              prepend-inner-icon="folder_open" outlined dense required @change="refresFilters()">
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    filters: {
      department_id: 0,
      location: { catalogue_id: null, id: null },
      business: null,
      warehouse: { id: null, name: null },
      project: { id: null, name: null },
      applicant: { id: 0, name: "todos" },
    },
    itemsWarehouse: [],
    itemsDepartment: [],
    itemsProjects: [],
    allProjects: [],
    allApplicants: [],
  }),
  name: "QuotesFilters",
  props: {
    filter_default: {
      type: Array,
      default() {
        return [];
      },
    },
    disable_all: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    business_selected: {
      get() {
        return this.$store.state.app.filters.business;
      },
      set(value) {
        this.$store.commit("setBusiness", value);
      },
    },
    location_selected: {
      get() {
        return this.$store.state.app.filters.location;
      },
      set(value) {
        this.$store.commit("setLocation", value);
      },
    },
    projectFilter() {
      if (this.location_selected == null) {
        return;
      }

      let itemsProjects = this.allProjects.filter(
        (element) =>
          element.location_id == this.location_selected.id &&
          element.finished == false
      );
      itemsProjects.unshift({ name: "Todos", id: 0 });
      return itemsProjects;
    },
    locationsFilter() {
      if (this.location_selected == null) {
        return;
      }
      let locations = this.$store.state.auth.locations.filter(
        (element) => element.bus_id == this.business_selected
      );
      locations.unshift({ id: 0, text: "TODOS" });
      return locations;
    },
    warehouseFilter() {
      if (this.location_selected == null) {
        return;
      }

      let warehouse = this.itemsWarehouse.filter(
        (element) => element.location_id == this.location_selected.id
      );

      warehouse.unshift({ id: 0, name: "TODOS" });
      return warehouse;
    },
    applicantsFilter() {
      if (this.location_selected == null) {
        return;
      }
      let applicants = this.allApplicants.filter((element) =>
        element.access.locations.includes(this.location_selected.id)
      );
      applicants.unshift({ id: 0, employee_fullname: "TODOS" });
      return applicants;
    },
  },
  watch: {
    location_selected(val) {
      if (val == null) {
        return;
      }
      this.filters.warehouse = this.warehouseFilter[0];
      this.filters.project = this.projectFilter[0];
      this.filters.applicant = this.applicantsFilter[0];
      this.refresFilters();
    },
    filter_default(val) {
      if (val.length > 0) {
        this.filters = val[0];
      }
    },
  },
  methods: {
    async initialize() {
      this.filters.business = this.$store.state.auth.bussines_units[0].value;
      const warehouses = await this.api_Get("inventory/allWarehouses");
      this.itemsWarehouse = await warehouses.json();
      if (this.itemsWarehouse.length > 0) {
        this.filters.warehouse = { id: 0, name: "Todos" };
      }
      const requestProjects = await this.api_Get("project/projects");
      this.allProjects = await requestProjects.json();
      this.filters.project = { id: 0 };

      this.itemsDepartment = await this.g_departments();
      this.itemsDepartment.unshift({ text: "Todos", value: 0 });
      //SOLICITANTES
      const request = await this.api_Get("shopping/applicants");
      this.allApplicants = await request.json();
      this.refresFilters();
    },
    refresFilters() {
      this.filters.business = this.business_selected;
      this.filters.location = this.location_selected;

      this.$emit("setFilters", this.filters);
    },
  },
  components: {},
};
</script>
