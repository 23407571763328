var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('form',{attrs:{"action":""}},[_c('v-row',{staticClass:"mt-2 mb-2",attrs:{"justify":"center"}},[_c('span',{staticStyle:{"font-size":"28px","color":"#616161"}},[_vm._v("Libro diario")])]),_c('SearchHeader',{on:{"updateSearch":function($event){return _vm.searchLoaded($event)}}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12","aling":"center"}},[(_vm.loading == false)?_c('v-data-table',{staticClass:"elevation-1 rounded-lg",attrs:{"headers":_vm.headers,"items":_vm.dataDiary,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Asientos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:`item.diary_book_code`,fn:function({ item }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('span',{staticClass:"primary--text font-weight-normal text-truncate font-weight-semibold",style:({
                  color: hover ? 'red' : 'black',
                  cursor: hover ? 'pointer' : '',
                  'text-decoration': hover ? 'underline' : '',
                }),on:{"click":function($event){return _vm.showItem(item)}}},[_vm._v(" "+_vm._s(item.diary_book_code))])]}}],null,true)})]}},{key:`item.created_date`,fn:function({ item }){return [_c('span',{staticClass:"font-weight-normal subtitle-3"},[_vm._v(" "+_vm._s(_vm.getDate(item.created_date)))])]}},{key:`item.accounts`,fn:function({ item }){return _vm._l((item.diary_book_details),function(items,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(items.chart_of_account_code)+" - "+_vm._s(items.chart_of_account_name)+" "),_c('br')])})}},{key:`item.tercero`,fn:function({ item }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('span',{staticClass:"primary--text font-weight-normal text-truncate font-weight-semibold",staticStyle:{"font-size":"14px"},style:({
                  color: hover ? 'red' : 'black',
                  cursor: hover ? 'pointer' : '',
                  'text-decoration': hover ? 'underline' : '',
                }),on:{"click":function($event){return _vm.viewEntity(item.entity_id)}}},[_vm._v(_vm._s(item.entity_name))])]}}],null,true)})]}},{key:`item.debito`,fn:function({ item }){return [(item.entry_type_id == 1)?[_vm._v(" "+_vm._s(item.currency_code)+" "+_vm._s(item.amount)+" ")]:_vm._e(),_c('br')]}},{key:`item.credito`,fn:function({ item }){return [(item.entry_type_id == 2)?[_vm._v(" "+_vm._s(item.currency_code)+" "+_vm._s(item.amount)+" ")]:_vm._e(),_c('br')]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"mt-2 mb-2"},[_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"font-weight-bold",staticStyle:{"color":"#607d8b","font-size":"24px"}},[_vm._v(" TOTAL ")]),_c('th',{staticClass:"font-weight-bold text-right",staticStyle:{"color":"#607d8b","font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.currencyFormat(_vm.debitoAndCredito.debito))+" ")]),_c('th',{staticClass:"font-weight-bold text-right",staticStyle:{"color":"#607d8b","font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.currencyFormat(_vm.debitoAndCredito.credito))+" ")])])])],2):_c('v-skeleton-loader',_vm._b({attrs:{"type":"list-item-avatar, divider, list-item-three-line, card-heading, image, actions","height":"500px"}},'v-skeleton-loader',_vm.attrs,false))],1)],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('ViewEntryDiary',{key:_vm.selectedItem.diary_book_id,attrs:{"entryDiary":_vm.selectedItem},on:{"close":function($event){_vm.dialog = false}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }