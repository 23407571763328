<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="center">
          <v-row no-gutters justify="center">
            <span style="font-size: 34px; color: #616161"><v-icon large class="pr-1 pb-2">loyalty</v-icon>Activos
              fijos</span>
          </v-row>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" sm="3" md="3" class="pt-0 pb-0">
                <v-autocomplete v-model="business_selected" :items="$store.state.auth.bussines_units"
                  label="Unidad de negocio" color="cyanText" prepend-inner-icon="corporate_fare" outlined dense
                  required></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="pt-0 pb-0">
                <v-autocomplete v-model="location_selected" :items="$store.state.auth.locations.filter(
                  (element) => element.bus_id == business_selected
                )
                  " color="cyanText" label="Location" prepend-inner-icon="my_location" outlined required dense
                  clearable>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                <v-row>
                  <v-col cols="12" md="8" sm="8" xs="6">
                    <v-autocomplete v-model="filters.department_id" :items="itemsDepartment" return-object
                      color="cyanText" label="Departamento" prepend-inner-icon="business" outlined required dense
                      clearable>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2" sm="2" xs="6">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" size="40px" v-bind="attrs" v-on="on" @click="loadBoxes()">
                          search
                        </v-icon>
                      </template>
                      <span>Buscar los activos fijos con esta configuracion</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-data-table :headers="headersAssets" :search="search" :items="assets">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Activos</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <span>{{ assets.length }}</span>
                  <v-spacer></v-spacer>
                  <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" single-line
                    hide-details>
                  </v-text-field>
                  <v-spacer></v-spacer>
                  <v-divider class="mx-3" inset vertical></v-divider>
                  <v-btn color="success" class="mr-2" @click="openDialogAssets()">
                    Nuevo activo
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editAsset(item)">
                      edit
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>

          <v-dialog v-model="dialogAssets" fullscreen persistent>
            <v-card outlined color="#F4F5FB">
              <v-card-title>
                <v-spacer></v-spacer>
                <span :style="`color:#757575;font-size:20px`">{{
                  dialogTitle
                }}</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogAssets = false">
                  <v-icon>clear</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-divider class="mx-2"></v-divider>
                <v-container>
                  <v-row justify="center">
                    <v-col cols="12" md="8" class="pb-0 mb-0">
                      <v-card outlined class="pt-0 mt-0">
                        <v-card-text>
                          <v-container fluid>
                            <v-card flat class="mb-3">
                              <v-row>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-text-field class="text-xs-center" v-model="newAssets.code" label="Codigo" dense
                                    outlined>
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="8" class="pt-0 mt-0 pb-0">
                                  <v-text-field class="text-xs-center" v-model="newAssets.item_name" label="Descripcion"
                                    readonly v-on:keyup.enter="dialogItem = true" dense outlined append-icon="search"
                                    @click:append="dialogItem = true">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-text-field class="text-xs-center" v-model="newAssets.model" label="Modelo" dense
                                    outlined>
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-text-field class="text-xs-center" v-model="newAssets.serial_number" label="Serial"
                                    dense outlined>
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-autocomplete v-model="newAssets.category_id" :items="categorys" item-value="id"
                                    item-text="name" color="cyanText" label="Categoria" outlined required dense>
                                  </v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-card>
                            <v-divider></v-divider>
                            <v-card flat class="mt-7 mb-3">
                              <v-row>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-text-field class="text-xs-center" v-model="newAssets.invoice_id" label="# Factura"
                                    dense outlined>
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-menu :v-model="menuDatePurchase == true" close-on-content-click :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field dense v-model="datePurchaseDMY" label="Fecha de compra"
                                        color="cyanText" readonly v-bind="attrs" v-on="on" outlined>
                                      </v-text-field>
                                    </template>
                                    <v-date-picker v-model="newAssets.date_of_purchase">
                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-text-field class="text-xs-center" v-model="newAssets.amount" label="Precio de compra"
                                    dense outlined>
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-text-field class="text-xs-center" v-model="newAssets.entity_name" label="Proveedor"
                                    readonly dense outlined append-icon="search" @click:append="dialogSupplier = true">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-text-field class="text-xs-center" v-model="newAssets.useful_life" label="Vida util"
                                    dense outlined>
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-text-field class="text-xs-center" v-model="newAssets.salvage_value"
                                    label="Valor de salvamento" dense outlined>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-card>
                            <v-divider></v-divider>
                            <v-card flat class="mt-6">
                              <v-row class="mt-3">
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-autocomplete v-model="newAssets.business_unit_id"
                                    :items="$store.state.auth.bussines_units" color="cyanText" label="Unidad de negocios"
                                    outlined required dense>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-autocomplete v-model="newAssets.location_id" :items="$store.state.auth.locations.filter(
                                    (element) =>
                                      element.bus_id ==
                                      newAssets.business_unit_id
                                  )
                                    " color="cyanText" label="Location" outlined required dense>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-autocomplete v-model="newAssets.department_id" :items="itemsDepartment"
                                    color="cyanText" label="Departamento" outlined required dense>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-autocomplete v-model="newAssets.created_by" :items="categorys" item-value="id"
                                    item-text="name" return-object color="cyanText" label="Responsable" outlined required
                                    dense>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                                  <v-autocomplete v-model="newAssets.active" :items="[
                                    { id: true, name: 'Activo' },
                                    { id: false, name: 'Inactivo' },
                                  ]" item-value="id" item-text="name" color="cyanText" label="Estatus" outlined
                                    required dense>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="12" class="pt-0 mt-0 pb-0">
                                  <v-text-field class="text-xs-center" v-model="newAssets.note" label="Nota" dense
                                    outlined>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="4" class="pb-0 mb-0">
                      <v-card width="100%" height="250" outlined>
                        <v-hover v-slot="{ hover }">
                          <v-card flat height="200" :class="hover ? 'grey lighten-3' : 'grey lighten-4'">
                            <v-img contain :src="assetsImage" height="200">
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col cols="12" md="2" align="center">
                                    <v-file-input v-show="assetsImage == null ? true : hover
                                      " v-model="url_image" @change="takeImage" name="upload" id="fileInput"
                                      accept="image/png, image/jpeg, image/jpg" :prepend-icon="assetsImage == null
                                          ? 'add_a_photo'
                                          : 'cameraswitch'
                                        " hide-details hide-input hide-spin-buttons>
                                    </v-file-input>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-img>
                          </v-card>
                        </v-hover>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="success" text @click="saveAsset">
                            Guardar activo
                          </v-btn>
                          <v-btn color="error" text @click="dialogAssets = false">
                            Cancelar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogItem" max-width="55%" persistent>
            <v-card>
              <v-card-title>
                <v-icon>add</v-icon>
                <span class="headline">Escoger articulos a agregar</span>
                <v-spacer></v-spacer>
                <v-menu bottom left>
                  <template v-slot:activator="{ }">
                    <v-btn icon @click="dialogItem = false">
                      <v-icon>clear</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </v-card-title>
              <v-card-text>
                <v-row justify="center">
                  <ArticleFilters @getItemToEdit="addItemSelected" :dial="false" icon="done" name="Agregar"
                    :searchWithLike="true" />
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="dialogItem = false">
                  <v-icon> done </v-icon>
                  Aceptar
                </v-btn>
                <v-btn color="red darken-1" text @click="dialogItem = false">
                  <v-icon> clear </v-icon>
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog width="60%" v-model="dialogSupplier">
            <v-card>
              <SupplierFilters @close="dialogSupplier = false" @selected-item="selectSupplier" />
            </v-card>
          </v-dialog>
        </v-row> </v-col></v-row></v-container>
</template>
<script>
import moment from "moment";
import ArticleFilters from "@/components/Inventory/ArticleSelectionList.vue";
import SupplierFilters from "@/components/Income/SearchClientOrSupplier.vue";
export default {
  data: () => ({
    filters: {
      business: "",
      location: "",
      department_id: "",
      chart_of_account: "",
    },
    newAssets: {
      code: "",
      item_id: "",
      item_name: "",
      date_of_purchase: "",
      category: "",
      category_id: "",
      entity_id: "",
      entity_name: "",
      invoice_id: "",
      business_unit_id: "",
      location_id: "",
      department_id: "",
      amount: "",
      useful_life: "",
      salvage_value: "",
      created_date: "",
      created_by: "",
      assigned_user: "",
      model: "",
      serial_number: "",
      image_url: "",
      note: "",
      active: true,
    },
    categorys: [],
    itemsDepartment: [],
    chartOfAccount: [],
    assets: [],
    search: "",
    dialogAssets: false,
    assetsImage: null,
    headersAssets: [
      {
        text: "Codigo",
        align: "start",
        value: "code",
      },
      { text: "Activo", value: "item_name" },
      { text: "Categoria", value: "category_id" },
      { text: "Modelo", value: "model" },
      { text: "Serial", value: "serial_number" },
      { text: "Unidad", value: "business_unit_id" },
      { text: "Location", value: "invoice_id" },
      { text: "", value: "actions", sortable: false },
      { text: "", value: "show", sortable: false },
    ],
    menuDatePurchase: false,
    dialogItem: false,
    url_image: null,
    dialogSupplier: false,
    changeImage: false,
    isNewAsset: false,
  }),
  components: {
    ArticleFilters,
    SupplierFilters,
  },
  created() {
    this.getData();
  },
  computed: {
    business_selected: {
      get() {
        return this.$store.state.app.filters.business;
      },
      set(value) {
        this.$store.commit("setBusiness", value);
      },
    },
    location_selected: {
      get() {
        return this.$store.state.app.filters.location;
      },
      set(value) {
        this.$store.commit("setLocation", value);
      },
    },
    dialogTitle() {
      return "Nuevo activo";
    },
    datePurchaseDMY() {
      if (this.newAssets.date_of_purchase == "") {
        return null;
      }
      return moment(
        this.newAssets.date_of_purchase,
        "YYYY-MM-DD HH:mm:ss"
      ).format("DD/MM/YYYY h:mm a");
    },
  },
  watch: {},
  methods: {
    async getData() {
      this.filterAssets();
      // this.filters.business = this.$store.state.auth.bussines_units[0].value;
      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      const dataTypeAccounts = await resultTypeAccounts.json();
      for (let item of dataTypeAccounts) {
        this.chartOfAccount.push({
          text: item.name,
          value: item.id,
          dgii_category_id: item.dgii_category_id,
        });
      }
      this.itemsDepartment = await this.g_departments();
      const categoryRequest = await this.api_Get("inventory/categories");
      this.categorys = await categoryRequest.json();
    },
    async filterAssets() {
      const request = await this.api_Post("inventory/assetsFilter", {
        filters: "",
      });
      const data = await request.json();
      if (request.status == 200) {
        this.assets = data;
      } else {
        this.$toast.error(data.error);
      }
    },
    openDialogAssets() {
      this.dialogAssets = true;
      this.isNewAsset = true;
      this.assetsImage = null;
      this.newAssets = {
        code: "",
        item_id: "",
        item_name: "",
        date_of_purchase: "",
        category: "",
        category_id: "",
        entity_id: "",
        entity_name: "",
        invoice_id: "",
        business_unit_id: "",
        location_id: "",
        department_id: "",
        amount: "",
        useful_life: "",
        salvage_value: "",
        created_date: "",
        created_by: "",
        assigned_user: "",
        model: "",
        serial_number: "",
        image_url: null,
        note: "",
        active: true,
      };
    },
    addItemSelected(item) {
      this.newAssets.item_name = item.item_name;
      this.newAssets.item_id = item.item_id;
      this.dialogItem = false;
    },
    selectSupplier(supplier) {
      this.newAssets.entity_id = supplier.entity_id;
      this.newAssets.entity_name = supplier.name;
      this.dialogSupplier = false;
    },
    takeImage() {
      if (this.url_image == null || this.url_image == undefined) {
        return;
      }
      this.assetsImage = URL.createObjectURL(this.url_image);
      this.changeImage = true;
    },
    async saveAsset() {
      if (this.newAssets.item_name == "") {
        this.$toast.warning("Escoja un articulo!");
        return;
      }

      try {
        if (
          this.newAssets.image_url != null &&
          this.newAssets.image_url != "null"
        ) {
          await this.delete_file_storage(
            "fixed_assets",
            this.newAssets.image_url
          );
        }
        let newImage = { id: null };
        if (this.changeImage == true) {
          const fileInput = document.getElementById("fileInput");
          newImage = await this.upload_images("fixed_assets", fileInput);
        }
        let assetData = {
          code: this.newAssets.code,
          item_id: this.newAssets.item_id,
          date_of_purchase: this.newAssets.date_of_purchase,
          category_id: this.newAssets.category_id,
          entity_id: this.newAssets.entity_id,
          invoice_id:
            this.newAssets.invoice_id == "" ? null : this.newAssets.invoice_id,
          business_unit_id: this.newAssets.business_unit_id,
          location_id: this.newAssets.location_id,
          department_id: this.newAssets.department_id,
          amount: this.newAssets.amount,
          useful_life: this.newAssets.useful_life,
          salvage_value: this.newAssets.salvage_value,
          created_by: this.$store.state.auth.user.user.user_id,
          assigned_user: null,
          model: this.newAssets.model,
          serial_number: this.newAssets.serial_number,
          image_url: newImage.id,
          note: this.newAssets.note,
          active: this.newAssets.active,
        };
        let request = null;
        if (this.isNewAsset == true) {
          request = await this.api_Post("inventory/assets", assetData);
        } else {
          request = await this.api_Put("inventory/assets", assetData);
        }
        const data = await request.json();
        if (request.status == 200) {
          this.filterAssets();
          this.$toast.success("Registro realizado con exito!");
          this.dialogAssets = false;
        } else {
          this.$toast.error(data.error);
          await this.delete_file_storage("fixed_assets", newImage.id);
        }
      } catch (error) {
        this.$toast.error(error);
      }
    },
    editAsset(item) {
      this.getImage(item.image_url);
      this.newAssets = item;
      this.dialogAssets = true;
      this.isNewAsset = false;
    },
    async getImage(imageId) {
      if (imageId == null || imageId == "" || imageId == undefined) {
        this.assetsImage = null;
        this.newAssets.image_url = null;
        return;
      }
      this.assetsImage = await this.get_storage("fixed_assets", imageId);
    },
  },
};
</script>
