<template>
  <v-flex>
    <v-row class="mt-2" justify="center">
      <v-col cols="12" md="12">
        <v-container>
          <v-row justify="center">
            <span class="mx-3" style="font-size: 34px; color: #616161">Categorías</span>
          </v-row>
          <v-row justify="center" class="mt-n1 mx-2">
            <span style="font-size: 16px; color: #757575">Crea nuevas categorías para clasificar tus productos y ubicarlos
              fácilmente
            </span>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="8">
              <search-bussines-locations @filter_loaded="changeBussines($event)" />
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12">
              <v-data-table v-if="loaded == false" :headers="headers" :items="data" show-expand :search="search"
                class="elevation-1">
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="'100%'">
                    <v-data-table :headers="headers" :items="item.children" :items-per-page="5" show-expand
                      hide-default-header hide-default-footer class="elevation-0">
                      <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-data-table :headers="headers" :items="item.children" :items-per-page="5" show-expand
                      hide-default-header hide-default-footer class="elevation-0">
                      <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="'100%'">
                    <v-data-table :headers="headers" :items="item.children" :items-per-page="5" show-expand
                      hide-default-header hide-default-footer class="elevation-0">
                      <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-data-table :headers="headers" :items="item.children" :items-per-page="5" show-expand
                      hide-default-header hide-default-footer class="elevation-0">
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" medium color="success" @click="addItem(item)">
                              add
                            </v-icon>
                          </template>
                          <span>Agregar categoria en
                            {{ item.name }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editItem(item)">
                              mdi-pencil
                            </v-icon>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>

                        <v-switch @change="updateState(item)" v-model="item.active" :label="item.active == true
                            ? 'ACTIVO'
                            : 'INACTIVO'
                          "></v-switch>
                      </template>
                    </v-data-table>
                  </td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" medium color="success" @click="addItem(item)">
                        add
                      </v-icon>
                    </template>
                    <span>Agregar categoria en
                      {{ item.name }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-switch @change="updateState(item)" v-model="item.active" :label="item.active == true
                      ? 'ACTIVO'
                      : 'INACTIVO'
                    "></v-switch>
                </template>
              </v-data-table>
              </td>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" medium color="success" @click="addItem(item)">
                      add
                    </v-icon>
                  </template>
                  <span>Agregar categoria en {{ item.name }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch @change="updateState(item)" v-model="item.active" :label="item.active == true ? 'ACTIVO' : 'INACTIVO'
                  "></v-switch>
              </template>
              </v-data-table>
              </td>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" medium color="success" @click="addItem(item)">
                      add
                    </v-icon>
                  </template>
                  <span>Agregar categoria en {{ item.name }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch @change="updateState(item)" v-model="item.active"
                  :label="item.active == true ? 'ACTIVO' : 'INACTIVO'"></v-switch>
              </template>
              </v-data-table>
              </td>
              </template>

              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Categorias</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer> </v-spacer>
                  <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" single-line
                    hide-details>
                  </v-text-field>

                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="700px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="
                      (editedItem.business_unit_id =
                        lastFiltersItem.busines),
                        (editedItem.location_id =
                          lastFiltersItem.location),
                        (itemsLocations2 = updateCombo(
                          itemsLocations,
                          itemsLocations2,
                          editedItem.business_unit_id,
                          'location'
                        ))
                        " color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                        <v-icon> add </v-icon>
                        Nueva categoria
                      </v-btn>
                    </template>
                    <v-card height="auto">
                      <v-row no-gutters justify="center">
                        <v-card-title class="text-center">
                          <span class="text-h5 text-center">{{ formTitle }} {{ newItemName }}</span>
                        </v-card-title>
                      </v-row>

                      <v-card-text>
                        <v-row justify="center">
                          <v-col cols="12" md="12">
                            <v-container>
                              <v-row justify="start">
                                <v-col cols="12" md="12">
                                  <v-row justify="center" no-gutters>
                                    <v-col cols="10">
                                      <v-row>
                                        <v-col cols="12" md="6">
                                          <v-autocomplete dense outlined :disabled="disable" v-model="editedItem.business_unit_id
                                            " :items="itemsBussines" label="Unidad de negocio" color="cyanText"
                                            @change="
                                              itemsLocations2 = updateCombo(
                                                itemsLocations,
                                                itemsLocations2,
                                                editedItem.business_unit_id,
                                                'location'
                                              )
                                              " prepend-inner-icon="corporate_fare" required></v-autocomplete></v-col>
                                        <v-col cols="12" md="6">
                                          <v-autocomplete dense :disabled="disable" outlined
                                            v-model="editedItem.location_id" :items="itemsLocations2" color="cyanText"
                                            label="Location" prepend-inner-icon="my_location"
                                            required></v-autocomplete></v-col>
                                      </v-row>
                                      <v-row no-gutters>
                                        <v-text-field v-model="editedItem.name" label="Nombre" outlined
                                          dense></v-text-field>
                                      </v-row>
                                      <v-row no-gutters>
                                        <v-textarea v-model="editedItem.note" label="Descripcion" outlined
                                          dense></v-textarea>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-card-actions class="m">
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancelar
                        </v-btn>
                        <v-btn outlined color="blue darken-1" @click="save">
                          Guardar
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="600px">
                    <v-card>
                      <v-card-title class="text-h5">Estas seguro que desea eliminar esta
                        categoria?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" medium color="success" @click="addItem(item)">
                      add
                    </v-icon>
                  </template>
                  <span>Agregar categoria en {{ item.name }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch @change="updateState(item)" v-model="item.active"
                  :label="item.active == true ? 'ACTIVO' : 'INACTIVO'"></v-switch>
              </template>
              <template v-slot:no-data>
                <v-row justify="center" class="pt-10">
                  <v-icon size="80px">token</v-icon>
                </v-row>
                <v-row justify="center" class="pb-6"> No hay datos </v-row>
              </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col></v-row>
  </v-flex>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    search: "",
    disable: false,
    dialogDelete: false,
    loaded: false,
    itemsletiant: [],
    newItemName: "",
    parentId: null,
    headers: [
      {
        text: "Codigo",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Nombre", value: "name" },
      { text: "Unidad de negocio", value: "bussines_unit_description" },
      { text: "Location", value: "location_description" },

      { text: "Actions", value: "actions", sortable: false },
      { text: "Status", value: "status", sortable: false, width: "8%" },
    ],
    data: [],
    lastFilters: "",
    lastFiltersItem: "",
    itemsBussines: [],
    itemsLocations: [],
    itemsLocations2: [],
    // filters: {
    //   business: null,
    //   location: null,
    //   department_id: null,
    //   routes: "",
    // },
    editedIndex: -1,
    editedItem: {
      name: "",
      business_unit_id: null,
      location_id: null,
    },
    defaultItem: {
      name: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva categoria" : "Editar categoria";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    //  this.initialize();
  },
  created() {
    this.initialize();
  },

  methods: {
    async changeBussines(event) {
      let filters = " where ITEM_CATEGORIES.BUSINESS_UNIT_ID=" + event.business;

      if (event.location != null) {
        filters += " AND LOCATION_ID=" + event.location;
      }

      const resultCategories = await this.api_Post("inventory/GetCategories", {
        filters: filters,
      });
      this.lastFilters = filters;
      this.lastFiltersItem = event.data;
      let data = await resultCategories.json();
      let child = [];
      for (let items of data) {
        child.push({
          id: items.id,
          parent: items.parent_category_id,
          name: items.name,
          note: items.note,
          business_unit_id: items.business_unit_id,
          bussines_unit_description: items.bussines_unit_description,
          location_id: items.location_id,
          location_description: items.location_description,
          code: items.code,
          account_type_id: items.account_type_id,
          dgii_category_id: items.dgii_category_id,
          active: items.active,
        });
      }

      const nest = (items, id = null, link = "parent") =>
        items
          .filter((item) => item[link] === id)
          .map((item) => ({ ...item, children: nest(items, item.id) }));

      this.data = nest(child);
    },
    async loadData() {
      const resultCategories = await this.api_Post("inventory/GetCategories", {
        filters: this.lastFilters,
      });

      let data = await resultCategories.json();
      let child = [];
      for (let items of data) {
        child.push({
          id: items.id,
          parent: items.parent_category_id,
          name: items.name,
          note: items.note,
          business_unit_id: items.business_unit_id,
          bussines_unit_description: items.bussines_unit_description,
          location_id: items.location_id,
          location_description: items.location_description,
          code: items.code,
          account_type_id: items.account_type_id,
          dgii_category_id: items.dgii_category_id,
          active: items.active,
        });
      }

      const nest = (items, id = null, link = "parent") =>
        items
          .filter((item) => item[link] === id)
          .map((item) => ({ ...item, children: nest(items, item.id) }));

      this.data = nest(child);
    },
    async initialize() {
      this.loaded = true;
      // let resultCategories = await this.api_Get("inventory/categories");

      // let data = await resultCategories.json();

      // let child = [];
      // for (let items of data) {
      //   child.push({
      //     id: items.id,
      //     parent: items.parent_category_id,
      //     name: items.name,
      //     note: items.note,
      //     business_unit_id: items.business_unit_id,
      //     bussines_unit_description: items.bussines_unit_description,
      //     location_id: items.location_id,
      //     location_description: items.location_description,
      //     code: items.code,
      //     account_type_id: items.account_type_id,
      //     dgii_category_id: items.dgii_category_id,
      //     active: items.active,
      //   });
      // }

      // const nest = (items, id = null, link = "parent") =>
      //   items
      //     .filter((item) => item[link] === id)
      //     .map((item) => ({ ...item, children: nest(items, item.id) }));

      // this.data = nest(child);

      this.itemsBussines = Object.assign(
        [],
        this.$store.state.auth.bussines_units
      );

      this.itemsLocations = this.$store.state.auth.locations;
      this.loaded = false;
    },
    updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
      itemsDestino.splice(0, itemsDestino.length);

      for (let item of itemsOrigen) {
        if (item.bus_id === condicion) {
          itemsDestino.push({
            value: item.value,
            text: item.text,
          });
        }
      }

      // this.filters.location = this.itemsLocations2[0].value;

      return itemsDestino;
    },
    async updateState(item) {
      let data = {
        id: item.id,
        active: item.active,
      };

      const responseRequest = await this.api_Post(
        "inventory/categories/updateState",
        data
      );

      if (responseRequest.status == 200) {
        // this.$toast.success("agregado con exito!");
        this.dialog = false;
      } else {
        this.$toast.error("error!");
      }
    },
    addItem(item) {
      this.disable = true;
      this.itemsletiant = [];
      let index = this.data.indexOf(item);

      this.editedItem = Object.assign({}, item);

      this.parentId = this.editedItem.id;
      this.newItemName = this.editedItem.name;
      this.editedItem.name = "";
      this.editedItem.note = "";
      this.itemsLocations2 = this.updateCombo(
        this.itemsLocations,
        this.itemsLocations2,
        this.editedItem.business_unit_id,
        "location"
      );

      //this.editedItem.location_id
      // this.editedItem.location_id=
      this.dialog = true;
    },
    editItem(item) {
      //this.parentId = null;

      this.disable = true;
      this.newItemName = "";
      this.itemsletiant = [];
      this.editedIndex = 1;

      this.editedItem = Object.assign({}, item);

      this.itemsLocations2 = this.updateCombo(
        this.itemsLocations,
        this.itemsLocations2,
        this.editedItem.business_unit_id,
        "location"
      );

      // this.editedItem.location_id=
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.data.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.disable = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      this.disable = false;
      if (this.editedIndex > -1) {
        let data = {
          id: this.editedItem.id,
          parent_category_id: this.editedItem.parent,
          business_unit_id: this.editedItem.business_unit_id,
          location_id: this.editedItem.location_id,
          image_url: "",
          name: this.editedItem.name,
          note: this.editedItem.note == undefined ? null : this.editedItem.note,
          active: true,
        };
        const responseRequest = await this.api_Put(
          "inventory/categories",
          data
        );

        if (responseRequest.status == 200) {
          this.loadData();

          // this.$toast.success("agregado con exito!");
          this.dialog = false;
        } else {
          this.$toast.error("error al agregar!");
        }
      } else {
        let data = {
          parent_category_id: this.parentId,
          business_unit_id: this.editedItem.business_unit_id,
          location_id: this.editedItem.location_id,
          image_url: "",
          name: this.editedItem.name,
          note: this.editedItem.note == undefined ? null : this.editedItem.note,
          active: true,
        };
        const responseRequest = await this.api_Post(
          "inventory/categories",
          data
        );

        if (responseRequest.status == 200) {
          this.loadData();

          // this.$toast.success("agregado con exito!");
          this.dialog = false;
        } else {
          this.$toast.error("error al agregar!");
        }
      }

      this.close();
    },
  },
};
</script>
