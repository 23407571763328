<template>
    <div>

        <v-row justify="center">
            <v-icon class="mx-2" size="35">currency_exchange</v-icon> <span style="font-size: 20px;">Cambio de moneda</span>
        </v-row>
        <v-row justify="center" class="mt-5 mb-2">
            <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">

                <v-autocomplete dense outlined v-model="rangeSelected" :items="rangeTime" label="Rango" color="cyanText"
                    item-text="text" item-value="value" @change="updateRange(rangeSelected)" required></v-autocomplete>
            </v-col>
            <v-col v-show="rangeSelected == 7" cols="12" sm="2" md="2" class="pt-0 pb-0">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="10" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field dense outlined v-model="dateFormatDMY" label="Desde" prepend-inner-icon="mdi-calendar"
                            readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col v-show="rangeSelected == 7" cols="12" sm="2" md="2" class="pt-0 pb-0">
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field dense outlined v-model="date2FormatDMY" label="Hasta"
                            prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">

                <v-autocomplete dense outlined item-text="name" item-value="id" v-model="filters.groups"
                    :items="itemsGroups" label="Grupo de moneda" color="cyanText" @change="
                        updateData()
                        " required></v-autocomplete>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-data-table :headers="headers" :items="money_exchange" sort-by="calories" class="elevation-1">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Monedas</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="1000px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn outlined color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        <v-icon>add</v-icon> Nuevo cambio
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title primary-title class="justify-center">
                                        <div>
                                            <span class="text-h5 ">{{ formTitle }}</span>
                                        </div>

                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row justify="center">
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-autocomplete dense outlined v-model="editedItem.currency_group_id"
                                                        :items="itemsGroups" label="Grupos de cambio" item-text="name"
                                                        item-value="id" color="cyanText" prepend-inner-icon="speaker_group"
                                                        required></v-autocomplete>

                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-menu v-model="menus" :close-on-content-click="false"
                                                        :nudge-right="10" transition="scale-transition" offset-y
                                                        min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field dense outlined v-model="dateFormatDMY"
                                                                label="Fecha efectiva" prepend-inner-icon="mdi-calendar"
                                                                readonly v-bind="attrs" v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="editedItem.effective_date"
                                                            @input="menu = false"></v-date-picker>
                                                    </v-menu>

                                                </v-col>
                                            </v-row>
                                            <v-row justify="center">

                                                <v-col cols="12" sm="6" md="3">
                                                    <v-autocomplete dense outlined v-model="editedItem.default_currency_id"
                                                        :items="currencies" label="Moneda base" color="cyanText"
                                                        prepend-inner-icon="attach_money" required>
                                                        <template slot="item" slot-scope="{ item }">
                                                            <country-flag class="mr-1" :country='getFlag(item.value)'
                                                                size='small' />
                                                            {{ item.text }}
                                                        </template>
                                                    </v-autocomplete>

                                                </v-col>
                                                <v-col cols="12" sm="6" md="3">
                                                    <v-autocomplete dense outlined v-model="editedItem.foreing_currency_id"
                                                        :items="currencies" label="Moneda destino" color="cyanText"
                                                        prepend-inner-icon="attach_money" required>
                                                        <template slot="item" slot-scope="{ item }">
                                                            <country-flag class="mr-1" :country='getFlag(item.value)'
                                                                size='small' />
                                                            {{ item.text }}
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="3">
                                                    <v-text-field type="number" dense outlined v-model="editedItem.value"
                                                        label="Valor"></v-text-field>
                                                </v-col>

                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions class="justify-center">

                                        <v-btn color="red darken-1" text @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="blue darken-1" outlined @click="save">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="text-h5">Seguro que desea eliminar?</v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.effective_date`]="{ item }">
                        <span>
                            {{ getDate(item.effective_date) }}
                        </span>
                    </template>
                    <template v-slot:[`item.default_currency_code`]="{ item }">

                        <span> <country-flag :country='getFlag(item.default_currency_id)' size='small' />
                            {{ item.default_currency_description }}
                        </span> <v-icon>arrow_right_alt</v-icon>
                    </template>
                    <template v-slot:[`item.foreing_currency_code`]="{ item }">

                        <span> <country-flag :country='getFlag(item.foreing_currency_id)' size='small' />
                            {{ item.foreing_currency_description }}
                        </span> <v-icon>arrow_right_alt</v-icon>
                    </template>
                    <template v-slot:[`item.value`]="{ item }">
                        <span>
                            <country-flag :country='getFlag(item.foreing_currency_id)' size='small' /> {{ item.value }} {{
                                item.foreing_currency_code }}
                        </span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                    <template v-slot:no-data>

                    </template>
                </v-data-table>
            </v-col>

        </v-row>

    </div>
</template>

<script>
import moment from 'moment'

export default {
    data: () => ({
        rangeSelected: "",
        dates: moment().startOf("day").format("YYYY-MM-DD"),
        dialog: false,
        menus: false,
        menu2: false,
        menu: false,
        date: moment().startOf("month").format("YYYY-MM-DD"),
        date2: moment().endOf("month").format("YYYY-MM-DD"),
        lastdata: "",
        loading: false,
        dialogDelete: false,
        itemsGroups: [],
        headers: [
            { text: 'Fecha', value: 'effective_date' },
            {
                text: 'Grupo de cambio',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: 'Moneda base', value: 'default_currency_code' },
            { text: 'Moneda destino', value: 'foreing_currency_code' },
            { text: 'Valor', value: 'value' },
            { text: 'Action', value: 'actions' },
        ],
        money_exchange: [],
        currencies: [],
        editedIndex: -1,
        editedItem: {
            currency_group_id: null,
            foreing_currency_id: null,
            default_currency_id: 3,
            effective_date: moment().startOf("week").add(1, 'days').format("YYYY-MM-DD"),
            value: ""
        },
        defaultItem: {
            currency_group_id: null,
            foreing_currency_id: null,
            default_currency_id: 3,
            effective_date: moment().startOf("week").add(1, 'days').format("YYYY-MM-DD"),
            value: ""
        }, filters: {
            groups: null,
            location: null,
            department_id: null,
            dateStart: moment().startOf("month").format("YYYY-MM-DD"),
            dateEnd: moment().endOf("month").format("YYYY-MM-DD"),
        }, rangeTime: [
            { text: "Hoy", value: "1" },
            { text: "Este semana", value: "2" },
            { text: "Siguiente semana", value: "3" },
            { text: "Este mes", value: "4" },
            { text: "Ultimos 3 meses", value: "5" },
            { text: "Este año", value: "6" },
            { text: "Personalizado", value: "7" },

        ],
    }),

    computed: {
        dateFormatDMY() {
            return moment(this.date).format("DD/MM/YYYY");
        },
        date2FormatDMY() {
            return moment(this.date2).format("DD/MM/YYYY");
        },
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo cambio' : 'Editar cambio'
        }, dateFormatDMY() {
            return moment(this.editedItem.effective_date).format("DD/MM/YYYY");
        },

    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        date(val, newVal) {
            //console.log(val + " " + newVal);
            this.filters.dateStart = val
            if (this.rangeSelected == 7) {
                this.updateData();
            }


        },
        date2(val, newVal) {
            // console.log(val + " " + newVal);
            this.filters.dateEnd = val
            if (this.rangeSelected == 7) {
                this.updateData();
            }
        },
    },


    created() {
        this.initialize()
    },

    methods: {
        async initialize() {

            let resultGroup = await this.api_Get("transactions/group_money");

            this.itemsGroups = await resultGroup.json();
            let resultCurrencies = await this.api_Get("accounting/currencies");
            for (let item of await resultCurrencies.json()) {
                this.currencies.push({ text: item.description, value: item.id });
            }
            let week = this.rangeTime.filter((element) => element.value == 2)
            if (this.itemsGroups.length > 0) {
                this.filters.groups = this.itemsGroups[0].id
            }
            this.rangeSelected = week[0]
            this.updateData()
        },

        async getData() {



        },
        async updateData() {
            this.loading = true
            let filtro = "   WHERE  ";

            this.filters.groups != undefined
                ? (filtro += "currency_group_id=" + this.filters.groups + " AND ")
                : (filtro += "");

            filtro +=
                " effective_date::DATE BETWEEN '" +
                this.date +
                "' and '" +
                this.date2 +
                "' ";

            let resultInvoices = await this.api_Post("transactions/get_money", { filters: filtro });

            this.money_exchange = await resultInvoices.json();
            this.loading = false
        },
        updateRange(item) {
            if (item == 1) {
                this.date = moment().format("YYYY-MM-DD");
                this.date2 = moment().format("YYYY-MM-DD");
            }
            if (item == 2) {
                this.date = moment().startOf("week").add('days', 1).format("YYYY-MM-DD");
                this.date2 = moment().endOf("week").add('days', 1).format("YYYY-MM-DD");
            }
            if (item == 3) {
                conosle.log("aqio")
                this.date = moment().startOf("week").add('days', 8).format("YYYY-MM-DD");
                this.date2 = moment().endOf("week").add('days', 8).format("YYYY-MM-DD");
            }

            if (item == 4) {
                conosle.log("aqio")
                this.date = moment().startOf("month").format("YYYY-MM-DD");
                this.date2 = moment().endOf("month").format("YYYY-MM-DD");
            }

            if (item == 5) {
                // = moment().startOf("month").format("YYYY-MM-DD");
                const fechaEspecifica = moment();
                const fechaHace3Meses = fechaEspecifica.clone().subtract(3, 'months');
                this.date = fechaHace3Meses.format('YYYY-MM-DD');
                this.date2 = moment().endOf("month").format("YYYY-MM-DD");
            }

            if (item == 6) {
                this.date = moment().startOf("year").format("YYYY-MM-DD");
                this.date2 = moment().endOf("year").format("YYYY-MM-DD");
            }
            this.filters.dateStart = this.date;
            this.filters.dateEnd = this.date2;

            if (this.rangeSelected != 7) {

                this.updateData();
            }
            // this.updateData();
        },
        getDate(item) {
            return moment(item).format("DD/MM/YYYY");
        },

        editItem(item) {
            this.editedIndex = this.money_exchange.indexOf(item)
            this.editedItem = Object.assign({}, item)

            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.money_exchange.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm() {
            try {
                const response = this.api_Post(
                    "transactions/delete_exchange",
                    this.editedItem
                );

                const data = await response;

                if (data.status == 200) {
                    this.$toast.success("Registro con exito!");
                    this.changeBussines(this.lastdata)
                } else {
                    this.$toast.error("data.error");
                }
            } catch (err) {
                console.log(err);
            }
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        async save() {
            if (this.editedItem.currency_group_id === null) {
                this.$toast.warning("Seleciona la unidad de negocio")
                return
            }
            if (this.editedItem.default_currency_id === null) {
                this.$toast.warning("Seleciona la mondeda base")
                return
            }
            if (this.editedItem.foreing_currency_id === 0) {
                this.$toast.warning("Seleciona la moneda a convertir")
                return
            }
            if (this.editedItem.value === "") {
                this.$toast.warning("Elige el monto a convertir")
                return
            }
            if (this.editedIndex > -1) {


                try {
                    const response = this.api_Put(
                        "transactions/save_exchange",
                        Object.assign(this.money_exchange[this.editedIndex], this.editedItem)
                    );

                    const data = await response;

                    if (data.status == 200) {
                        this.$toast.success("Actualizado con exito!");
                        this.initialize()
                    } else {
                        this.$toast.error("data.error");
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                console.log(this.editedItem)
                try {
                    const response = this.api_Post(
                        "transactions/save_exchange",
                        this.editedItem
                    );

                    const data = await response;

                    if (data.status == 200) {
                        this.$toast.success("Registro con exito!");
                        this.initialize()
                    } else {
                        this.$toast.error("data.error");
                    }
                } catch (err) {
                    console.log(err);
                }

                //this.money_exchange.push(this.editedItem)
            }
            this.close()
        },
    },
}
</script>

<style></style>