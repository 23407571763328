<template>
    <v-card width="100%" outlined>
        <apexchart height="250%" type="donut" :options="chartOptions" :series="series"></apexchart>
    </v-card>
</template>
<script>
export default {
    data: () => ({
        series: [],
        chartOptions: {
            title: {
                text: "",
                align: "center",
            },
            labels: [],
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 360,
                options: {
                    chart: {
                        width: 50
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },

    }),
    props: {
        title_header: {
            type: String
        },
        data: {
            type: Object,
        },

    },

    watch: {
        data: {
            handler: function (nuevoValor, viejoValor) {
                // Lógica para manejar el cambio del objeto filters

                this.chartOptions.labels = nuevoValor.label
                this.series = nuevoValor.series
                this.chartOptions.title.text = this.title_header
            },
            deep: true, // Para obserlet cambios profundos en el objeto filters
            immediate: true // Para ejecutar el watch inmediatamente al crear el componente
        }
    },

};
</script>