<template>
  <v-flex>
    <v-row justify="center" no-gutters>
      <v-col cols="12" md="12"><v-row justify="start" class="mb-3 mt-1 ml-5"> </v-row>
        <v-container v-if="data_order != null" class="">
          <v-row>
            <span class="mb-4 ml-4 mt-0" style="font-size: 24px; color: #616161">Orden de compra #{{
              filLeft(data_order[0].order_id.toString(), 12, "0")
            }}
            </span>
          </v-row>
          <v-row no-gutters class="mr-2" justify="start">
            <v-btn @click="beforeRoute" small outlined class="mr-2 rounded text-capitalize" tile
              color="primary"><v-icon>keyboard_backspace</v-icon> Volver</v-btn>
            <!-- <v-icon class="mx-2" size="35px">receipt_long</v-icon> -->
            <v-btn :disabled="can_edit" @click="dialog = true" small outlined class="mr-2 rounded text-capitalize" tile
              color="primary">
              <v-icon left>edit</v-icon>
              Editar
            </v-btn>
            <v-btn :disabled="data_order[0].last_status_id >= 10 ? false : true" @click="downloadInvoice" small outlined
              class="mx-2 rounded text-capitalize" tile color="primary">
              <v-icon left> print </v-icon>
              Imprimir
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn v-if="is_approved == true &&
              statusAproved &&
              data_order[0].last_status_id < 10
              " @click="AprroveOrReject(false, true)" small outlined class="mx-2 rounded text-capitalize" tile
              color="orange dark-2">
              <v-icon left> keyboard_backspace </v-icon>
              Devolver
            </v-btn>
            <v-btn v-if="is_approved == true && statusAproved" @click="AprroveOrReject(false)" small outlined
              class="mx-2 rounded text-capitalize" tile color="red dark-2">
              <v-icon left> thumb_down </v-icon>
              Cancelar
            </v-btn>
            <v-btn v-if="is_approved == true && statusAproved" @click="AprroveOrReject(true)" small outlined
              class="mx-2 rounded text-capitalize" tile color="success">
              <v-icon left> thumb_up </v-icon>
              {{ aprroved_button }}
            </v-btn>

            <v-btn v-if="data_order[0].last_status_id === 13 && is_reception == true" @click="dialog = true" small
              outlined class="mr-2 rounded text-capitalize" tile color="green">
              <v-icon left>warehouse</v-icon>
              Hacer Recepcion
            </v-btn>
            <v-btn v-if="data_order[0].last_status_id <= 13 && is_reception == true" @click="AprroveOrReject(true)" small
              outlined class="mr-2 rounded text-capitalize" tile color="blue">
              <v-icon left>lock</v-icon>
              Cerrar orden
            </v-btn>

            <!-- <v-btn v-if="data_order[0].last_status_id == 14" @click="AprroveOrReject(true)" small outlined
                            class="mr-2 rounded text-capitalize" tile color="blue">
                            <v-icon left>receipt</v-icon>
                            Convertir en factura
                        </v-btn> -->
          </v-row>
          <v-row class="mt-4" no-gutters justify="center">
            <div class="folded_corner mt-5">
              <v-row class="mt-8" justify="center">
                <v-col cols="2" md="3">
                  <v-row justify="center">
                    <v-img contain width="100px" height="100px" :src="locationImage"></v-img>
                  </v-row>
                </v-col>
                <v-col cols="2" md="6">
                  <v-row justify="center">
                    <span class="font-weight-bold" style="font-size: 20px; color: #616161">{{
                      data_order[0].business_unit_description_long }}</span>
                  </v-row>

                  <v-row justify="center">
                    <span style="font-size: 16px; color: #616161">{{
                      data_order[0].location_description_long
                    }}</span>
                  </v-row>

                  <v-row justify="center">
                    <span style="font-size: 16px; color: #616161">
                      <!-- {{
                                                                                data_order[0].business_unit_
                                                                            }} -->
                    </span>
                  </v-row>
                  <v-row justify="center">
                    <span style="font-size: 14px; color: #616161">
                      <!-- gro@gmail.com -->
                    </span>
                  </v-row>
                  <v-row justify="center">
                    <!-- <span style="font-size: 14px; color: #616161">829-449-6091</span> -->
                  </v-row>
                </v-col>
                <v-col cols="2" md="3">
                  <v-row justify="end">
                    <span class="mr-6 mt-0" style="font-size: 24px; color: #616161">#{{
                      filLeft(data_order[0].order_id.toString(), 12, "0")
                    }}
                    </span>
                  </v-row>
                  <v-row no-gutters justify="start"> </v-row>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="2" md="8">
                  <span class="ml-16">Proveedor:</span>
                  <span @click="viewEntity(data_order[0].entity_id)" style="text-decoration: underline; cursor: pointer"
                    class="mx-4 font-weight-bold">{{ data_order[0].entity_name }}</span>
                </v-col>
                <v-col cols="2" md="4">
                  <span class="mr-12 float-right">Creacion
                    <b>{{ getDate(data_order[0].emission_date) }}</b>
                  </span>
                </v-col>
                <v-divider inset class="mr-8"></v-divider>
              </v-row>
              <v-row justify="center">
                <v-col cols="2" md="6">
                  <span class="ml-16">RNC o Cédula :</span>
                  <span class="ml-2">{{ data_order[0].rnc }}</span>
                </v-col>
                <v-col cols="2" md="6">
                  <span class="mr-12 float-right">Almacen:
                    <span class="mx-5 font-weight-bold">{{
                      data_order[0].warehouse_name
                    }}</span>
                  </span>
                </v-col>
                <v-divider inset class="mr-8"></v-divider>
              </v-row>

              <v-row v-if="data_order[0].quotation_id != null" justify="center" class="mt-4">
                <v-col cols="2" md="6">
                  <span class="ml-16">Cotizacion: </span>
                  <span style="text-decoration: underline; cursor: pointer" @click="goQuotation(data_order[0])"
                    class="mx-2 font-weight-bold">
                    {{
                      filLeft(data_order[0].quotation_id.toString(), 12, "0")
                    }}</span>
                </v-col>
                <v-col cols="2" md="6">
                  <span v-if="data_order[0].project_id != 0" class="mr-12 float-right">Proyecto {{
                    data_order[0].project_name }}
                  </span>
                </v-col>
                <!-- <v-divider inset class="mr-8"></v-divider> -->
              </v-row>

              <v-row justify="center" class="mt-7">
                <v-col cols="12" md="11">
                  <v-container class="">
                    <v-data-table :headers="headers" dense :items="data_order[0].order_details"
                      class="elevation-0 rounded-lg" hide-default-footer>
                      <template v-slot:[`item.item_chart_of_account_id`]="{ item }">
                        <span>{{ item.item_chart_of_account_id }} </span>
                      </template>
                      <template v-slot:[`item.price`]="{ item }">
                        <span>{{ data_order[0].currency_code }}
                          {{ currencyFormat(item.price) }}</span>
                      </template>

                      <template v-slot:[`item.total`]="{ item }">
                        <span>{{ data_order[0].currency_code }}
                          {{ currencyFormat(item.price * item.quantity) }}</span>
                      </template>
                      <template v-slot:footer>
                        <v-row class="mt-2" justify="center" style="margin-right: 0.5em">
                          <v-col cols="12" md="4">
                            <v-row style="margin-bottom: 130px" justify="center" class="mt-4">
                              <v-col cols="12">
                                <v-row>
                                  <span class="font-weight-bold ml-6">Notas</span>
                                </v-row>

                                <v-row>
                                  <span> {{ data_order[0].note }} </span>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="8">
                            <v-row justify="end">
                              <v-card style="margin-top: 30px; margin-bottom: 30px" elevation="0" width="30%"
                                height="70px">
                                <v-layout fill-height align-center>
                                  <v-col cols="12" md="6">
                                    <v-row justify="start">
                                      <span class="font-weight-bold">
                                        Subtotal
                                      </span>
                                    </v-row>
                                    <v-row justify="start">
                                      <!-- <span class="font-weight-bold">
                                                                                                            Descuento
                                                                                                        </span> -->
                                    </v-row>
                                    <v-row justify="start">
                                      <!-- <span class="font-weight-bold"> Itbis </span> -->
                                    </v-row>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-row justify="end">
                                      <span class="font-weight-normal">{{ data_order[0].currency_code }}

                                        {{ currencyFormat(total) }}
                                      </span>
                                    </v-row>
                                    <v-row justify="end"> </v-row>
                                    <v-row justify="end">
                                      <span class="font-weight-normal"> </span>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="12" md="1"></v-col>
                                </v-layout>
                                <v-divider class="mt-2"></v-divider>
                                <v-row class="mt-2">
                                  <v-col cols="12" md="2">
                                    <v-row justify="start">
                                      <span style="font-size: 3vh; color: #58595f" class="font-weight-bold ml-2">
                                        Total
                                      </span>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="12" md="10">
                                    <v-row justify="end" class="">
                                      <v-spacer></v-spacer>
                                      <span style="font-size: 2vh; color: #58595f" class="mt-1 mx-1 font-weight-normal">{{
                                        data_order[0].currency_code }}</span>
                                      <span style="font-size: 2.5vh; color: #58595f" class="font-weight-bold">
                                        {{ currencyFormat(total) }}
                                      </span>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-row class="mt-14 mb-14" justify="center">
                          <v-col cols="12" md="8">
                            <v-divider inset></v-divider>
                            <v-row class="mt-2" justify="center">
                              <span class="font-weight-bold text-center">
                                Elaborado por - {{ data_order[0].use_fullname }}
                              </span>
                            </v-row>
                          </v-col>
                        </v-row>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-col>
              </v-row>
            </div>
          </v-row>

          <v-row class="mt-8" no-gutters justify="center">
            <v-col cols="12" md="12"><v-card height="auto">
                <v-tabs v-model="tab" background-color="grey lighten-5 ">
                  <v-tab v-for="(item, i) in items" :key="i">
                    <span class="text-capitalize"> {{ item }}</span>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <v-row class="mt-2 mb-2" justify="center">
                      <v-col cols="12" md="11">
                        <v-data-table :headers="headerReceptions" :items="data_entry_order"
                          class="elevation-0 rounded-lg mt4 mb-4" hide-default-footer style="">
                          <template v-slot:[`item.id`]="{ item }">
                            <span @click="
                              viewReception(
                                data_order[0].order_id,
                                item.entry_id
                              )
                              " style="
                                text-decoration: underline;
                                cursor: pointer;
                              " class="primary--text">
                              {{ filLeft(item.entry_id.toString(), 12, "0") }}
                            </span>
                          </template>
                          <template v-slot:[`item.created_date`]="{ item }">
                            <span>
                              {{ dateFormated(item.created_date) }}
                            </span>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row class="mt-0" justify="center">
                      <v-col cols="12" md="11">
                        <v-data-table :headers="headersContabilidad" :items="data_order[0].invoice_entry_book"
                          class="elevation-0 rounded-lg mt4 mb-4" hide-default-footer style="">
                          <template v-slot:[`item.chart_of_account_name`]="{ item }">
                            <span class="primary--text">
                              {{ item.chart_of_account_code }}
                            </span>
                            -
                            <span>
                              {{ item.chart_of_account_name }}
                            </span>
                          </template>

                          <template v-slot:[`item.debito`]="{ item }">
                            <span v-if="item.entry_type_id == 1">
                              {{ currencyFormat(item.amount) }}
                            </span>
                          </template>

                          <template v-slot:[`item.credito`]="{ item }">
                            <span v-if="item.entry_type_id == 2">
                              {{ currencyFormat(item.amount) }}</span>
                          </template>
                          <template slot="body.append">
                            <tr class="mt-2 mb-2">
                              <th></th>
                              <th class="font-weight-bold" style="color: #616161; font-size: 20px">
                                TOTAL
                              </th>

                              <th style="font-size: 18px" class="font-weight-normal text-right success--text">
                                {{ data_order[0].currency_code }}
                                {{ currencyFormat(totalContabilidad.debito) }}
                              </th>
                              <th class="font-weight-normal text-right error--text"
                                style="color: #616161; font-size: 18px">
                                {{ data_order[0].currency_code }}
                                {{ currencyFormat(totalContabilidad.credito) }}
                              </th>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row class="mt-0" justify="center">
                      <v-col cols="12" md="11"> </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-8" no-gutters justify="center">
            <v-col cols="12" md="6">
              <v-card class="mr-6" min-height="200">
                <v-card-title style="font-size: 18px; color: #424242" class="pb-0 d-flex justify-center">
                  Historico orden #{{
                    filLeft(data_order[0].order_id.toString(), 12, "0")
                  }}</v-card-title>
                <v-divider class="ma-4"></v-divider>
                <v-responsive :aspect-ratio="16 / 9">
                  <v-container fluid>
                    <template>
                      <v-timeline>
                        <v-timeline-item v-for="item in this.data_order[0].order_histories" small
                          :key="item.item_request_status_id" :color="time_line_orders(item.order_status_id).color"
                          :icon="time_line_orders(item.order_status_id).icon" class="pb-0">
                          <template v-slot:opposite>
                            <span>{{ item.use_fullname }}</span>
                          </template>
                          <v-card outlined>
                            <v-card-title class="d-flex justify-center align-center pt-0 pb-0" :class="time_line_orders(item.order_status_id).color
                              ">
                              <span style="font-size: 14px; color: #ffffff">
                                {{ dateFormated(item.created_date) }}</span>
                            </v-card-title>
                            <v-card-text>
                              <span class="d-flex justify-center align-center">
                                {{ item.name }}</span>
                            </v-card-text>
                          </v-card>
                        </v-timeline-item>
                      </v-timeline>
                    </template>
                  </v-container>
                </v-responsive>
              </v-card>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="6">
              <v-card v-if="selectedAttached == ''" width="80%" @click="takeImage" style="
                  cursor: pointer;
                  border: 0.5px solid rgb(0, 0, 0);
                  border-radius: 6px;
                  background-color: #fafafa;
                " class="mx-2 mt-6 align-self-center" outlined>
                <v-row no-gutters justify="center">
                  <v-card-title class="headline">Archivos adjuntos
                  </v-card-title>
                </v-row>
                <v-row class="mb-4" no-gutters justify="center">
                  <v-icon style="transform: rotate(30deg)" size="60px">
                    attach_file
                  </v-icon>
                </v-row>
              </v-card>
              <div class="align-self-center mb-10" v-else style="
                  position: relative;
                  padding-bottom: 56.25%; /* 16:9 */
                  padding-top: 25px;
                  height: 0;
                ">
                <iframe style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 85vh;
                  " :src="selectedAttached" frameborder="0">
                </iframe>
              </div>
            </v-col>
            <v-file-input v-show="false" style="opacity: 0" name="upload" id="fileInput"
              @change="onFileChange"></v-file-input>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog transition="slide-x-transition" v-model="dialog" fullscreen>
      <v-card style="background-color: #f4f5fa">
        <v-toolbar flat dark color="transparent">
          <v-btn icon dark @click="dialog = false">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" md="11">
              <Order :data_order="data_order" :is_reception="is_reception" @save-order="editOrder($event)" />
            </v-col>
          </v-row>
        </v-container>
        <v-card-text>
          <v-row> </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import moment from "moment";
import Order from "./Order.vue";
import momentTimezone from "moment-timezone";
export default {
  data: () => ({
    dialog: false,
    hasFiles: false,
    search: "",
    is_reception: false,
    selectedAttached: "",
    loadingAttachment: false,
    dialogAttached: false,
    date: moment().startOf("month").format("YYYY-MM-DD"),
    date2: moment().startOf("month").format("YYYY-MM-DD"),
    id_orders: null,
    storage_invoice: "",
    data_order: null,
    detailsItems: [],
    tab: null,
    paymentsInvoice: [],
    reponse_level: [],
    locationImage: null,
    storage_json: [],
    items: ["Recepciones", "Facturas"],
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    headersContabilidad: [
      {
        text: "Asiento",
        align: "start",
        sortable: false,
        value: "diary_book_code",
      },
      { text: "Cuenta", value: "chart_of_account_name" },

      { text: "	Debito", value: "debito", align: "right" },
      { text: "	Credito", value: "credito", align: "right" },
    ],
    headerReceptions: [
      {
        text: "Recepcion",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Almacen", value: "warehouses_name" },

      { text: "Fecha", value: "created_date", align: "start" },
      { text: "Usuario", value: "user_fullname", align: "start" },
    ],
    data_entry_order: [],
    headersInventario: [{}],
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "item_id",
      },
      {
        text: "Producto/servicio",
        align: "start",
        sortable: false,
        value: "item_name",
      },
      // { text: "Referencia", value: "item_description" },
      { text: "Cuenta", value: "item_chart_of_account_id", align: "end" },
      { text: "Precio", value: "price", align: "end", width: "15%" },
      //   { text: "	Desc %", value: "", align: "end" },

      { text: "Cantidad", value: "quantity", align: "end" },
      { text: "Total", value: "total", align: "end" },
    ],
    itemsFiles: [],
    headersFiles: [
      {
        text: "Descripcion",
        align: "start",
        sortable: false,
        value: "description",
      },

      { text: "	Action", value: "actions", align: "end" },
    ],
    level: [],
    commentHeader: "",
  }),
  components: { Order },
  computed: {
    statusAproved() {
      let st = false;
      // if (this.data_order[0].last_status_id != 2) {
      //   let data = [];
      //   console.log(this.data_order[0].orders_approvals)

      for (let item of this.data_order[0].orders_approvals == null
        ? []
        : this.data_order[0].orders_approvals) {
        if (item.last_status === null) {
          // if(item.user_id==4)
          if (item.user_id == this.$store.state.auth.user.user.user_id) {
            st = true;

            //return true
          } else {
          }
          break;
        }
      }

      if (this.data_order[0].last_status_id == 2) {
        for (let items of this.$store.state.auth.user.type_access) {
          if (items.access.acces_profile === 22) {
            st = true;
          }
        }
        if (this.$store.state.auth.user.user.permition_level == 99) {
          st = true;
        }
      }

      return st;
    },
    can_edit() {
      if (parseInt(this.data_order[0].last_status_id) == 12) {
        return false;
      }

      if (parseInt(this.data_order[0].last_status_id) > 2) {
        return true;
      } else {
        return false;
      }
    },
    aprroved_button() {
      if (this.data_order[0].last_status_id == 2) {
        return "Aprobar Contador";
      }
      if (this.data_order[0].last_status_id == 3) {
        return "Aprobar";
      }
      if (this.data_order[0].last_status_id == 4) {
        return "Aprobar";
      }
      if (this.data_order[0].last_status_id == 5) {
        return "Aprobar";
      }
      if (this.data_order[0].last_status_id == 6) {
        return "Aprobar";
      }
      if (this.data_order[0].last_status_id == 7) {
        return "Aprobar";
      }
      if (this.data_order[0].last_status_id == 8) {
        return "Aprobar";
      }
      if (this.data_order[0].last_status_id == 9) {
        return "Aprobar";
      }
      if (this.data_order[0].last_status_id == 10) {
        return "Aprobar";
      }
    },
    is_approved() {
      if (this.$route.params.type == "a2xrbWFuaW4") {
        if (this.data_order[0].last_status_id == 1) {
          return false;
        }

        if (this.data_order[0].last_status_id == 11) {
          return false;
        }
        if (this.data_order[0].last_status_id == 12) {
          return false;
        } else {
          return true;
        }
      }
    },
    total() {
      let tot = 0;
      for (let item of this.data_order[0].order_details) {
        tot = tot + item.price * item.quantity;
      }
      return tot;
    },
    totalContabilidad() {
      let debito = 0;
      let credito = 0;

      return { debito: debito.toFixed(2), credito: credito.toFixed(2) };
    },
  },

  created() {
    if (this.$route.params.typeb == "a2xrbWFuaW4Rtgsdsx") {
      this.is_reception = true;
    }

    this.getData();
  },
  methods: {
    async goQuotation(item) {
      const request = await this.api_Get(
        "shopping/quotesDetails/" + item.quotation_id
      );

      let response = await request.json();

      let quote = [];

      for (let data of response) {
        if (data.supplier_id == item.entity_id) {
          quote.push(data);
        }
      }

      if (quote.length == 0) {
        return;
      }

      this.$router.push({
        name: "QuoteDetailView",
        params: { supplier: item.entity_id, quote: quote[0].quotation_id },
      });
    },
    getStatus(type) {
      let count = 0;
      let status = 0;

      if (this.data_order[0].orders_approvals != null) {
        for (let it of this.data_order[0].orders_approvals) {
          if (it.last_status == null) {
            //    end = false;
            count++;
          }
        }

        if (type == true) {
          const data = this.data_order[0].orders_approvals.find(
            (element) => element.last_status == null
          );

          const limit = data === undefined ? 0 : data.limit_amount;

          if (this.data_order[0].last_status_id == 2) {
            //    status = 3;

            status = 3;
          }
          if (this.data_order[0].last_status_id == 3) {
            if (this.total >= 0 && this.total <= limit) {
              status = 13;
            } else {
              status = 4;
            }
            status = 4;
          }

          if (this.data_order[0].last_status_id == 4) {
            if (this.total <= limit) {
              status = 13;
            } else {
              status = 5;
            }
          }
          if (this.data_order[0].last_status_id == 5) {
            if (this.total <= limit) {
              status = 13;
            } else {
              status = 6;
            }
          }
          if (this.data_order[0].last_status_id == 6) {
            status = 10;
          }
          if (this.data_order[0].last_status_id == 7) {
            status = 8;
          }
          if (this.data_order[0].last_status_id == 8) {
            status = 9;
          }

          if (this.data_order[0].last_status_id == 9) {
            status = 10;
          }
          if (this.data_order[0].last_status_id == 13) {
            status = 14;
          }
        }
        if (type == false) {
          status = 11;
        }
        if (count > 0 && count < 2) {
          status = 13;
        }
      } else {
        if (this.data_order[0].last_status_id == 13) {
          status = 14;
        } else {
          status = 13;
        }
      }
      return status;
    },
    async AprroveOrReject(type, back) {
      let status = back == undefined ? this.getStatus(type) : 12;

      let data = {
        order_id: this.id_orders,
        user_id: this.$store.state.auth.user.user.user_id,
        order_status_id: status,
      };

      const responseApprove = await this.api_Post(
        "shopping/approve_or_reject",
        data
      );
      if (responseApprove.status == 200) {
        this.getData();
        this.$toast.success("Aprobado!");
      } else {
        this.$toast.error("error al cargar la factura!");
      }
    },

    dateFormated(date) {
      //const dates = moment(date).format('DD/MM/YYYY h:mm a');
      return momentTimezone(date)
        .tz("America/Santo_Domingo")
        .format("DD/MM/YYYY h:mm a");
    },
    async loadLogo() {
      let result = this.$store.state.auth.bussines_units.filter(
        (element) => element.value == this.data_order[0].business_unit_id
      );

      if (result[0].image_storage != null) {
        this.locationImage = await this.get_storage(
          "logo_bussines_units",
          result[0].image_storage
        );
      } else {
        this.locationImage = null;
      }
    },
    async getData() {
      this.loading = true;
      this.id_orders = atob(this.$route.params.id);

      const responseRequest = await this.api_Post("shopping/search_order_id", {
        number: this.id_orders,
      });
      if (responseRequest.status == 200) {
        this.data_order = await responseRequest.json();

        if (this.data_order[0].last_status_id >= 13) {
          const responseEntry = await this.api_Post(
            "shopping/search_entry_order",
            {
              order_id: this.data_order[0].order_id,
              filters: "",
            }
          );
          if (responseEntry.status == 200) {
            this.data_entry_order = await responseEntry.json();
          }
        }
        let resultLevel = await this.api_Post("shopping/order_level", {
          business_unit_id: this.data_order[0].business_unit_id,
          location_id: this.data_order[0].location_id,
        });
        let level = await resultLevel.json();
        this.level = level;

        if (this.data_order[0].data_json != null) {
          this.viewAddtached(this.data_order[0].data_json);
        }
      } else {
        this.$toast.error("error al cargar la factura!");
      }
      const responseLevel = await this.api_Post("shopping/order_level", {
        business_unit_id: this.data_order[0].business_unit_id,
        location_id: this.data_order[0].location_id,
      });
      if (responseLevel.status == 200) {
        this.reponse_level = await responseLevel.json();
      } else {
        this.$toast.error("error al cargar la factura!");
      }
      this.loading = false;
    },
    getDate(item) {
      return moment(item).format("DD/MM/YYYY");
    },
    async editOrder(item) {
      let route = "";
      route =
        item.type != 3
          ? "shopping/order_register"
          : "shopping/reception_register";

      const responseRequest = await this.api_Post(route, item);

      if (responseRequest.status == 200) {
        let data = await responseRequest.json();
        this.$toast.success("Guardado");
        this.getData();
        this.dialog = false;
      } else {
        this.$toast.error("error al registrar la orden!");
      }
    },
    async viewAddtached(item) {
      this.loadingAttachment = true;

      try {
        this.selectedAttached = await this.get_storage(
          "orders_atached",
          item.id
        );
      } catch (err) {
        console.log(err);
      }

      this.loadingAttachment = false;
    },
    async onFileChange() {
      try {
        const fileInput = document.getElementById("fileInput");
        let record = await this.upload_images("orders_atached", fileInput);

        const responseRequest = await this.api_Post("shopping/order_json", {
          order_id: this.data_order[0].order_id,
          data_json: record,
        });
        if (responseRequest.status == 200) {
          this.$toast.success("Guardado!");
          this.getData();
        } else {
          this.$toast.error("error al cargar!");
        }
      } catch (e) {
        this.$toast.error("error " + e.message);
      }
    },
    takeImage() {
      //this.fileInput.click();
      document.getElementById("fileInput").click();

      //this.this.dialogImage = true;
    },

    downloadInvoice() {
      this.$router.push({
        name: "DownloadInvoice",
        params: { id: this.data_order[0].invoice_number },
      });
    },
    selectItem(item) {
      this.selectedEntity = item;
      this.dialogSearch = false;
    },
    selectPayment(item) {
      this.selectedPayments = item;
      this.dialogPayments = false;
    },
    deleteItem(item) {
      let editIndex = this.selectedPayments.indexOf(item);

      this.selectedPayments.splice(editIndex, 1);
    },
  },
};
</script>

<style scoped>
.etiqueta_invoice {
  width: 180px;
  height: 50px;
  background-color: rgb(18, 116, 18);
  color: white;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;

  transform: rotate(-45deg);
}

.folded_corner {
  position: relative;
  width: 100%;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  height: auto;
  border-radius: 5px;
  height: auto;
  /* background: #58a; */
  background: linear-gradient(to left bottom,
      transparent 50%,
      rgba(194, 193, 193, 0.4) 0) no-repeat 100% 0 / 2em 2em,
    linear-gradient(-135deg, transparent 1.5em, rgb(255, 255, 255) 0);

  /* -webkit-filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
  /* filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
}
</style>
