<template>
  <v-expand-transition>
    <v-card class="overflow-hidden" v-show="showBar" outlined>
      <v-bottom-navigation :input-value="showBar">
        <v-btn :to="{ name: 'Articles' }">
          <span>Productos y servicios</span>

          <v-icon>local_mall</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'InventoryManagement' }">
          <span>Inventario</span>

          <v-icon>rule</v-icon>
        </v-btn>
        <v-btn :to="{
          name: 'Adjustments',
        }">
          <span>Ajustes</span>

          <v-icon>autorenew</v-icon>
        </v-btn>
        <v-btn :to="{
          name: 'TransferRequest',
          params: {
            type: '1',
          },
        }">
          <span>Transferencias</span>

          <v-icon>sync_alt</v-icon>
        </v-btn>
        <v-btn :to="{
          name: 'Approvals',
        }">
          <span>Aprobaciones</span>

          <v-icon>task_alt</v-icon>
        </v-btn>
        <v-btn :to="{
          name: 'TransferReceivedt',
          params: {
            type: '2',
          },
        }">
          <span>Recepcion</span>

          <v-icon>archive</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-card>
  </v-expand-transition>
</template>
<script>
export default {
  data: () => ({
    value: 0,
  }),
  name: "NavigationButonsTop",
  props: {
    showBar: {
      type: Boolean,
      default: false,
    },
  },
  created() { },
  computed: {},
  watch: {},
  methods: {},
  components: {},
};
</script>
