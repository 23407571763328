import moment from "moment";
const state = {
  filters: {
    business: null,
    location: null,
    department_id: null,
    accountBank: null,
    payments_id: null,
    dateStart: moment().startOf("month").format("YYYY-MM-DD"),
    dateEnd: moment().endOf("month").format("YYYY-MM-DD"),
  },
  

};

const getters = {
    filters:state=>state.filters

};
const actions = {
  
};
const mutations = {
  setBusiness(state, newValue) {
    console.log(newValue)
    state.filters.business = newValue;
  },
  setLocation(state, newValue) {
    state.filters.location = newValue;
  },
  setDepartment(state, newValue) {
    state.filters.department_id = newValue;
  },
  setDate(state, newValue) {
    state.filters.dateStart = newValue.dateStart;
    state.filters.dateEnd = newValue.dateEnd;
  },
   
    setValues(state, filters) {
        
    state.filter=Object.assign({},filters) 
      
     

    // router.push({ name: "Home" });
    // location.reload();


  },

};


export default {
  state,
  getters,
  actions,
  mutations,

//  namespaced: true,
};
