<template>
    <v-layout align-start>
        <v-flex>
            <v-card outlined>
                <v-row no-gutters justify="center">
                    <span style="font-size: 20px; padding-top: 1rem">
                        Areas y mesas <v-icon>table_chart</v-icon>
                    </span>
                </v-row>
                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12" sm="3" md="3" class="pt-0 pb-0">
                            <v-autocomplete dense v-model="filters.business" :items="$store.state.auth.bussines_units"
                                label="Unidad de negocio" color="cyanText" prepend-inner-icon="corporate_fare" outlined
                                required></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" md="3" class="pt-0 pb-0">
                            <v-autocomplete dense v-model="filters.location" :items="$store.state.auth.locations.filter(
                                (element) => element.bus_id == filters.business
                            )
                                " color="cyanText" label="Location" prepend-inner-icon="my_location" outlined required
                                clearable>
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="1" md="1" class="pt-0 pb-0">
                            <v-row class="pt-2" justify="center" no-gutters>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="primary" class="mr-2" size="40px" v-bind="attrs" v-on="on"
                                            @click="loadPlaces()" :loading="loadingFilter">
                                            search
                                        </v-icon>
                                    </template>
                                    <span>Buscar lugares con estos parametros</span>
                                </v-tooltip>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-card outlined class="mt-4 pt-3">
                        <v-card-text>
                            <v-data-table :headers="headerPlaces" :search="search" :items="places">
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-toolbar-title>Lugares</v-toolbar-title>
                                        <v-divider class="mx-4" inset vertical></v-divider>
                                        <span>{{ places.length }}</span>
                                        <v-spacer></v-spacer>
                                        <v-text-field class="text-xs-center" v-model="search" append-icon="search"
                                            label="Buscar" single-line hide-details>
                                        </v-text-field>
                                        <v-spacer></v-spacer>
                                        <v-divider class="mx-3" inset vertical></v-divider>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="success" class="mr-2" v-bind="attrs" v-on="on" outlined
                                                    @click="showPlaces">
                                                    Nueva area
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Crear nueva area</span>
                                        </v-tooltip>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editPLace(item)">
                                                edit
                                            </v-icon>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="mx-1" v-bind="attrs" v-on="on" medium color="green"
                                                @click="addTable(item)">
                                                add
                                            </v-icon>
                                        </template>
                                        <span>Agregar mesa a {{ item.name }}</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:[`item.show`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" color="primary" @click="showBox(item)">
                                                read_more
                                            </v-icon>
                                        </template>
                                        <span>Historico</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-dialog v-model="dialogPlaces" max-width="50%">
                    <v-card outlined>
                        <v-card-title class="mb-3">
                            <span>{{ titleTipo }}</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogPlaces = false">
                                <v-icon>clear</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row justify="center">
                                    <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                                        <v-text-field class="text-xs-center" v-model="newPlace.in_place_name" label="Nombre"
                                            outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                                        <v-autocomplete v-model="newPlace.business_unit_id"
                                            :items="$store.state.auth.bussines_units" label="Unidad de negocio"
                                            color="cyanText" outlined required>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                                        <v-autocomplete v-model="newPlace.in_location_id" :items="$store.state.auth.locations.filter(
                                            (element) =>
                                                element.bus_id == newPlace.business_unit_id
                                        )
                                            " color="cyanText" label="Location" outlined required>
                                        </v-autocomplete>
                                    </v-col>

                                    <!-- <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                                        <v-text-field class="text-xs-center" v-model="lastBoxUpdate"
                                            label="Ultima actualizacion" outlined readonly></v-text-field>
                                    </v-col> -->
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="success" text @click="save()" :loading="loadingSave">
                                {{ is_new_type == true ? "Guardar" : "Actualizar" }}
                            </v-btn>
                            <v-btn color="error" text @click="dialogPlaces = false">
                                Cancelar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogTable" fullscreen>
                    <v-card outlined>
                        <v-card-title class="mb-3">
                            <span>Mesas y sillas </span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogTable = false">
                                <v-icon>clear</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="headerTables" :search="search" :items="detailTable">
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-toolbar-title>Mesas</v-toolbar-title>
                                        <v-divider class="mx-4" inset vertical></v-divider>
                                        <span>{{ detailTable.length }}</span>
                                        <v-spacer></v-spacer>
                                        <v-text-field class="text-xs-center" v-model="search" append-icon="search"
                                            label="Buscar" single-line hide-details>
                                        </v-text-field>
                                        <v-spacer></v-spacer>
                                        <v-divider class="mx-3" inset vertical></v-divider>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="success" class="mr-2" v-bind="attrs" v-on="on" outlined
                                                    @click="showAddDetails">
                                                    Nueva mesa
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Crear nuevo tipo</span>
                                        </v-tooltip>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" medium color="blue"
                                                @click="editDetailsType(item)">
                                                edit
                                            </v-icon>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="mx-1" v-bind="attrs" v-on="on" medium color="red"
                                                @click="deleteSide(item)">
                                                delete
                                            </v-icon>
                                        </template>
                                        <span>Eliminar {{ item.name }}</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:[`item.show`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" color="primary" @click="showBox(item)">
                                                read_more
                                            </v-icon>
                                        </template>
                                        <span>Historico</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn color="success" text @click="save()" :loading="loadingSave">
                                {{ is_new_type == true ? 'Guardar' : 'Actualizar' }}
                            </v-btn>
                            <v-btn color="error" text @click="dialogTable = false">
                                Cancelar
                            </v-btn> -->
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="DialogAddDetails" width="60%">
                    <v-card outlined height="50vh">
                        <v-card-title class="mb-3">
                            <span>Nueva mesa</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="DialogAddDetails = false">
                                <v-icon>clear</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field v-model="newTable.in_name" label="Nombre" color="cyanText"
                                        outlined></v-text-field>
                                </v-col>

                                <v-col cols="4">
                                    <v-autocomplete v-model="newTable.in_table_type_id" :items="types_tables" label="Tipo"
                                        color="cyanText" outlined item-value="id" item-text="name"
                                        required></v-autocomplete>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field v-model="newTable.in_chairs" label="Sillas" type="number" color="cyanText"
                                        outlined></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="success" text @click="saveDetails()" :loading="loadingSave">
                                {{ "Guardar" }}
                            </v-btn>
                            <v-btn color="error" text @click="DialogAddDetails = false">
                                Cancelar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
import moment from "moment";
export default {
    data: () => ({
        filters: {
            business: "",
            location: "",
            department_id: "",
            chart_of_account: "",
        },
        newPlace: {
            in_place_id: null,
            in_location_id: null,
            in_side_type_id: null,
            in_place_name: "",
        },

        newTable: {
            in_table_id: null,
            in_table_type_id: null,
            in_place_id: null,
            in_name: null,
            in_chairs: null,
        },
        places: [],
        search: "",

        dialogConfirm: false,
        titleTipo: "Nueva area",
        is_new_type: false,
        titleDialogConfirm: "Seguro que desea realizar esta acción?",
        dialogPlaces: false,
        loadingSave: false,
        loadingFilter: false,
        headerPlaces: [
            {
                text: "Id",
                align: "start",
                value: "in_place_id",
            },
            { text: "Nombre", value: "in_place_name" },
            { text: "Unidad de negocio", value: "business_unit_description_long" },
            { text: "Location", value: "location_description_long" },

            { text: "Actions", value: "actions", sortable: false },
        ],
        headerTables: [
            // {
            //     text: "Id",
            //     align: "start",
            //     value: "id",
            // },
            { text: "Nombre", value: "table_name" },
            { text: "Tipo", value: "table_type_name" },
            { text: "Lugar", value: "place_name" },
            { text: "Sillas", value: "chairs" },
            { text: "Actions", value: "actions", sortable: false },
        ],
        types_tables: [],
        detailTable: [],
        selectePlaces: null,
        loadingInvoices: false,
        dialogTable: false,
        DialogAddDetails: false,
        dialogTitle: "-",
    }),
    components: {},
    created() {
        this.getData();
    },

    watch: {},
    methods: {
        async getData() {
            const detail_tables = await this.api_Get("restaurant/view_types_tables");
            this.types_tables = await detail_tables.json();
        },
        async loadPlaces() {
            this.loadingFilter = true;
            this.places = [];
            let filtros = " WHERE BUSINESS_UNIT_ID=" + this.filters.business;
            filtros += Number.isInteger(this.filters.location)
                ? " AND LOCATION_ID=" + this.filters.location
                : " ";

            try {
                const placeRequest = await this.api_Post("restaurant/view_places", {
                    filters: filtros,
                });
                const typeData = await placeRequest.json();

                if (placeRequest.status != 200) {
                    this.$toast.error(typeData.error);
                }
                if (typeData.length === 0) {
                    this.$toast.warning("No se econtraron caja");
                }
                this.places = typeData;
            } catch (error) {
                console.log(error);
            }
            this.loadingFilter = false;
        },

        showPlaces() {
            this.newPlace.in_place_id = null;
            this.newPlace.in_location_id = null
            this.newPlace.in_place_name = null


            this.dialogPlaces = true;
        },
        showAddDetails() {
            this.newTable.in_table_id = null;
            this.newTable.in_table_type_id = null;
            //    this.newTable.in_place_id = null;
            this.newTable.in_name = '';
            this.newTable.table_type_name = '';
            this.newTable.in_chairs = null;

            this.DialogAddDetails = true;
        },
        editDetailsType(item) {
            null,
                // in_table_type_id: null,
                // in_place_id: null,
                // in_name: null,
                // in_chairs: null,
                this.newTable.in_table_id = item.id;
            this.newTable.in_table_type_id = item.table_type_id;
            this.newTable.in_place_id = item.place_id;
            this.newTable.in_name = item.table_name;
            this.newTable.table_type_name = item.table_type_name;
            this.newTable.in_chairs = item.chairs;
            this.DialogAddDetails = true;
        },
        editPLace(item) {

            this.newPlace.business_unit_id = item.business_unit_id;
            this.newPlace.in_location_id = item.location_id;
            this.newPlace.in_place_name = item.in_place_name;
            this.newPlace.in_place_id = item.in_place_id;

            this.is_new_type = false;
            this.titleTipo = "Editar ";

            //   this.newPlace = Object.assign({}, item);

            this.dialogPlaces = true;
        },
        async getTables(id) {
            let filters = `where place_id=${id}`
            const detail_tables = await this.api_Post("restaurant/view_places_tables", {
                filters: filters,
            });
            this.detailTable = await detail_tables.json();
        },
        async addTable(item) {
            this.selectePlaces = item;
            this.newTable.in_place_id = item.in_place_id
            this.dialogTable = false;

            this.getTables(item.in_place_id);
            // const all_articles = await this.api_Post("inventory/inventory_value", {
            //     date: moment().format("YYYY-MM-DD"),
            //     business_unit_id: item.business_unit_id,
            //     location_id: item.location_id,
            // });
            // this.types_tables = await all_articles.json();

            this.dialogTable = true;
        },
        async save() {
            this.loadingSave = true;

            try {
                let data = {
                    in_place_id: this.newPlace.in_place_id,
                    in_location_id: this.newPlace.in_location_id,
                    in_place_name: this.newPlace.in_place_name
                };
                const newPlace = await this.api_Post("restaurant/add_places", data);
                if (newPlace.status === 200) {
                    this.$toast.success("Guardado");
                    this.loadPlaces();
                    this.dialogPlaces = false;
                }
            } catch (error) {
                this.$toast.error(error);
                console.log(error);
            }
            this.loadingSave = false;
        },
        async saveDetails() {
            this.loadingSave = true;

            try {
                let data = {

                    in_table_id: this.newTable.in_table_id,
                    in_table_type_id: this.newTable.in_table_type_id,
                    in_place_id: this.newTable.in_place_id,
                    in_name: this.newTable.in_name,
                    in_chairs: this.newTable.in_chairs
                };

                const newPlace = await this.api_Post("restaurant/add_table", data);
                if (newPlace.status === 200) {
                    this.$toast.success("Guardado");
                    //this.loadPlaces();
                    this.getTables(this.newTable.in_place_id);
                    this.DialogAddDetails = false;
                }
            } catch (error) {
                this.$toast.error(error);
                console.log(error);
            }
            this.loadingSave = false;
        },



        async deleteSide(item) {

            const request = await this.api_Delete(
                "restaurant/side/" + item.id
            );
            if (request.status == 200) {
                await this.getGetails(item.side_type_id);
                this.$toast.success("eliminado!");

                this.DialogAddDetails = false
            }
        },

    },
};
</script>
  