<template>
  <v-dialog v-model="showDialog" :max-width="confirm_settings.size" persistent>
    <v-card>
      <v-card-title class="headline">
        <v-row no-gutters justify="center">
          <v-icon class="pr-1">{{ confirm_settings.icon }}</v-icon>
          {{ confirm_settings.title }}
        </v-row>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="action(true)">{{ confirm_settings.btn_affirmative }}</v-btn>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn color="red darken-1" text @click="action(false)">{{ confirm_settings.btn_negative }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({

  }),
  name: "DialogConfirm",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    confirm_settings: {
      type: Object,
      default() {
        return {
          btn_affirmative: 'Si',
          btn_negative: 'No',
          icon: 'list_alt',
          size: "45%",
          title: 'Seguro que desea realizar esta accion?',
        }
      }
    },
  },
  methods: {
    action(value) {
      this.$emit('closeDialog', value);
    }
  },
  components: {},
};
</script>
