<template>
  <v-container fluid>
    <v-layout align-start>
      <v-flex>
        <v-card flat>
          <v-row no-gutters justify="center">
            <span style="font-size: 34px; color: #616161">
              <v-icon large class="pr-1 pb-2">add_shopping_cart</v-icon>
              Cotizaciones de compra
            </span>
          </v-row>
          <v-card-text>
            <HeaderFilters @setFilters="getDataFilter" :disable_all="disableFilters" />
            <v-card flat>
              <v-expand-transition>
                <v-card outlined class="mt-2 pt-2" v-show="step == 1">
                  <v-card-title class="pb-0 pt-0">
                    <span style="font-size: 18px; color: #616161">
                      Lista de articulos solicitados
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn @click="step = 2" text color="success" v-show="itemSelecteds.length > 0">
                      Cotizar
                      <v-icon>chevron_right</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-data-table v-model="itemSelecteds" :headers="headersForItemsQuote" :items="selectedsFilter"
                      :search="search" item-key="item_key" dense show-select checkbox-color="success"
                      no-data-text="No se encontraron solicitudes con los filtros aplicados">
                      <template v-slot:top>
                        <v-toolbar flat>
                          <span :style="`font-size:15px`">
                            Total: {{ selectedsFilter.length }}
                          </span>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <span :style="`font-size:15px`">
                            Seleccionados: {{ itemSelecteds.length }}
                          </span>
                          <v-spacer></v-spacer>
                          <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar"
                            single-line hide-details>
                          </v-text-field>
                          <v-spacer></v-spacer>
                          <v-col cols="12" sm="2" md="2" class="pt-0">
                            <v-autocomplete class="pt-5 pb-0" v-model="statusType"
                              :items="[{ value: 0, text: 'Todo' }, { value: 1, text: 'Pendiente' }, { value: 2, text: 'Cotizado' }]"
                              label="Tipo" color="primary" outlined dense required>
                            </v-autocomplete>
                          </v-col>
                          <v-divider class="mx-2 mt-0" inset vertical></v-divider>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon @click="dialogSetting = true" v-bind="attrs" v-on="on" class="mb-4">
                                <v-icon>settings</v-icon>
                              </v-btn>
                            </template>
                            <span>Ajustes de la tabla</span>
                          </v-tooltip>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.status_quote`]="{ item }">
                        <v-chip :color="item.status_quote.length > 0 ? 'green darken-1' : 'grey darken-1'" dark small>
                          {{ item.status_quote.length > 0 ? 'Cotizado' : 'Pendiente' }}
                        </v-chip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
              <v-expand-transition>
                <v-card outlined class="mt-2 pt-2" v-show="step == 2">
                  <v-card-title class="pb-0 pt-0">
                    <v-btn text color="error" @click="step = 1" dense>
                      <v-icon>arrow_back_ios</v-icon>
                      Volver
                    </v-btn>
                    <span style="font-size: 18px; color: #616161" class="pl-2 pr-2">
                      Indique la cantidad a cotizar de cada articulo
                    </span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">help</v-icon>
                      </template>
                      <span>Si no se indica la cantidad completa, el resto quedara como pendiente</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-btn text color="success" @click="validateNextStep()">
                      siguiente
                      <v-icon>chevron_right</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-data-table :headers="headersForItemsQuote" :items="itemSelecteds" :search="searchSelected" dense
                      no-data-text="No hay articulos seleccionados" item-key="item_key">
                      <template v-slot:top>
                        <v-toolbar flat>
                          <span :style="`font-size:15px`">
                            Articulos: {{ itemSelecteds.length }}
                          </span>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-text-field class="text-xs-center" v-model="searchSelected" append-icon="search"
                            label="Buscar" single-line hide-details>
                          </v-text-field>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" text color="primary" @click="selectMaxAll(false)" dense>
                                Cotizar todo
                                <v-icon>apps</v-icon>
                              </v-btn>
                            </template>
                            <span>Se aplicara automaticamente la cantidad solicitada de cada articulo</span>
                          </v-tooltip>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.quantity_selected`]="{ item }">
                        <v-text-field v-model="item.quantity_selected" dense :full-width="false" type="number"
                          hide-details min="1" :max="item.quantity" @change="validateQuantity(item)">
                        </v-text-field>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
              <v-expand-transition>
                <v-card outlined class="mt-2 pt-2" v-show="step == 3">
                  <v-card-title class="pb-0 pt-0">
                    <v-btn text color="error" @click="step = 2" dense class="pl-2">
                      <v-icon>arrow_back_ios</v-icon>
                      Volver
                    </v-btn>
                    <span style="font-size: 18px; color: #616161">
                      Lista de suplidores
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn text color="success" @click="save">
                      Guardar
                      <v-icon>chevron_right</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-data-table v-model="supplierSelected" :headers="headerSuppliers" :items="suppliers"
                      :search="searchSuppliers" dense no-data-text="No hay suplidores registrados" item-key="document_id"
                      show-select checkbox-color="success">
                      <template v-slot:top>
                        <v-toolbar flat>
                          <span :style="`font-size:15px`">
                            Total: {{ suppliers.length }}
                          </span>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <span :style="`font-size:15px`">
                            Seleccionados: {{ supplierSelected.length }}
                          </span>
                          <v-spacer></v-spacer>
                          <v-text-field class="text-xs-center" v-model="searchSuppliers" append-icon="search"
                            label="Buscar" single-line hide-details>
                          </v-text-field>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <TableSettings :showDialog="dialogSetting" :header_table="headersForItemsQuote" @exit="dialogSetting = false" />
  </v-container>
</template>

<script>
import HeaderFilters from "@/components/Shop/QuotesFilters.vue";
import DialogConfirm from "@/components/DialogConfirm.vue";
import TableSettings from "@/components/TableSettings.vue";
export default {
  data: () => ({
    search: "",
    searchSelected: '',
    step: 1,
    headersForItemsQuote: [
      {
        text: 'ID Solicitud',
        align: 'start',
        value: 'request_id',
        require: true,
        active: true
      },
      { text: 'ID', value: 'item_code', align: 'center', require: true, active: true },
      { text: 'Item', value: 'item_name', align: 'start', require: true, active: true },
      { text: 'Cant. Solicitada', value: 'quantity', align: 'center', require: true, active: true },
      { text: 'Cant. cotizada', value: 'quantity_selected', width: "7%", align: ' d-none', require: true, active: false },
      { text: 'Precio estimado', value: 'price', align: 'center', require: false, active: true },
      { text: 'Solicitante', value: 'requested_by', align: 'center', require: false, active: true },
      { text: 'Almacen', value: 'warehouse_name', align: 'center', require: false, active: true },
      { text: 'Depto', value: 'department_names[0].department_name.description', align: 'center', require: false, active: true },
      { text: 'Disponible', value: 'department_avaliable', align: ' d-none', require: false, active: false },
      { text: 'Proyecto', value: 'project_name', align: 'center', require: false, active: true },
      { text: 'Disponible', value: 'project_avaliable', align: ' d-none', require: false, active: false },
      { text: 'Estatus', value: 'status_quote', align: 'center', require: true, active: true },
    ],
    headerSuppliers: [
      {
        text: 'Identificacion',
        align: 'start',
        value: 'document_id',
      },
      { text: 'Nombre', value: 'name', align: 'start' },
      { text: 'Telefono', value: 'document_id', align: 'start' },
      { text: 'Correo', value: 'mail', align: 'center' },
    ],
    statusType: 1,
    filters: {
      department_id: 0,
      location: { catalogue_id: "", id: "" },
      business: "",
      warehouse: { id: "", name: "" },
      dateStart: null,
      dateEnd: null,
    },
    dialogConfirm: false,
    itemsQuoteData: [],
    itemSelecteds: [],
    suppliers: [],
    supplierSelected: [],
    searchSuppliers: '',
    dialogSetting: false,
  }),
  components: {
    HeaderFilters,
    DialogConfirm,
    TableSettings
  },
  created() {
    this.getInitialData();
  },
  computed: {
    disableFilters() {
      return false;
    },
    selectedsFilter() {
      if (this.statusType == 1) {
        return this.itemsQuoteData.filter((element) => element.status_quote.length == 0);
      } else if (this.statusType == 2) {
        return this.itemsQuoteData.filter((element) => element.status_quote.length > 0);
      }
      return this.itemsQuoteData;
    }
  },
  watch: {
    step(val) {
      if (val == 1) {
        this.headersForItemsQuote[4].align = ' d-none'
        this.headersForItemsQuote[4].active = false;

        this.headersForItemsQuote[12].align = 'center'
        this.headersForItemsQuote[12].active = true;
        return
      }

      this.headersForItemsQuote[4].align = 'center'
      this.headersForItemsQuote[4].active = true;
      this.headersForItemsQuote[12].align = ' d-none'
      this.headersForItemsQuote[12].active = false;
    }
  },
  methods: {
    async getInitialData() {
      //this.getDataFilter('');
      let resultEntity = await this.api_Get("accounting/entity_all/2");
      this.suppliers = await resultEntity.json();
    },
    async getDataFilter(filters) {
      this.filters = filters;

      let filtros = ` WHERE BUSINESS_UNIT_ID=${filters.business}`;
      filtros += filters.location.id != 0 ? (' AND LOCATION_ID=' + filters.location.id) : ''
      filtros += filters.warehouse.id != 0 ? (' AND WAREHOUSE_ID=' + filters.warehouse.id) : ''
      filtros += filters.project.id != 0 ? (' AND PROJECT_ID=' + filters.project.id) : ''
      filtros += filters.applicant.id != 0 ? (' AND REQUESTED_BY=' + filters.applicant.id) : ''
      filtros += filters.department_id != 0 ? (' AND DEPARTMENT_ID=' + filters.department_id) : ''
      try {
        const request = await this.api_Post("shopping/articles_for_quotes", {
          filters: filtros
        });
        const data = await request.json();
        if (request.status == 200) {
          this.itemsQuoteData = data;
        } else {
          this.$toast.error('Error:' + data.error);
        }
      } catch (error) {
        this.$toast.error('Error:' + error);
      }
    },
    getSelecteds() {
      this.step = 2;
    },
    validateQuantity(item) {
      if (parseFloat(item.quantity_selected) > parseFloat(item.quantity)) {
        this.$toast.warning('La cantidad indicada es mayor a la solicitada!. Se ha ajustado automaticamente.');
        let index = this.itemSelecteds.indexOf(item);
        this.itemSelecteds[index].quantity_selected = item.quantity;
      }
    },
    //aplica la cantidad maxima de cada articulo, o valida si hay alguno con cantidad 0
    selectMaxAll(is_validate) {
      for (let key in this.itemSelecteds) {
        if (is_validate == false) {
          this.itemSelecteds[key].quantity_selected = this.itemSelecteds[key].quantity;
          continue;
        }
        if (parseInt(this.itemSelecteds[key].quantity_selected) == 0) {
          this.$toast.warning(`Debe indicar la cantidad a cotizar del articulo: ${this.itemSelecteds[key].item_name}.`);
          return false
        }

      }
      return true
    },
    validateNextStep() {
      if (this.selectMaxAll(true))
        this.step = 3;
    }, ViewGroup(item) {
      //this.$router.push({ name: "ViewQuoteGroup", params: { id: item } });

      const routeData = this.$router.resolve({ name: 'ViewQuoteGroup', params: { id: item } });
      window.open(routeData.href, '_blank');
    },
    async save() {
      if (this.supplierSelected.length == 0) {
        this.$toast.warning('Debe seleccionar al menos un suplidor!');
        return;
      }
      let suppliersArray = [];
      for (let item of this.supplierSelected) {
        suppliersArray.push(item.entity_id)
      }
      let itemDetails = [];
      for (let item of this.itemSelecteds) {

        itemDetails.push({
          item_id: item.item_id,
          request_id: item.request_id,
          quantity: parseInt(item.quantity_selected),
          price: null
          // price: parseFloat(item.price)*parseFloat*item.quantity_selected
        })
      }
      try {
        const request = await this.api_Post("shopping/save_quotes", {
          business_unit_id: this.filters.business,
          user_id: this.$store.state.auth.user.user.user_id,
          suppliers: suppliersArray,
          quotation_details: itemDetails
        });
        const data = await request.json();

        if (request.status == 200) {
          this.$toast.success('Cotizaciones registradas correctamente!');

          this.ViewGroup(data[0].quotation_id)
          this.clear()
          this.getDataFilter(this.filters);
        } else {
          this.$toast.error('Error:' + data.error);
        }
      } catch (error) {
        this.$toast.error('Error:' + error);
      }
    },
    clear() {
      this.supplierSelected = [];
      this.itemSelecteds = [];
    }
  },
};
</script>
