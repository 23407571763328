<template>
  <v-flex>
    <v-row no-gutters class="mt-4" justify="center">

      <v-col class="ml-3" cols="12" lg="3">
        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field readonly v-model="dateAccount" label="Fecha contable" outlined persistent-hint
              prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="dateAccount" no-title @input="menu1 = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="3">
        <v-autocomplete return-object outlined v-model="selectedCurrecies" :items="currencies" label="Moneda"
          color="cyanText" prepend-inner-icon="attach_money" item-text="code" item-id="id" required>
          <template #item="{ item }">
            <span>
              <country-flag :country="getFlag(item.id)" size="small" />
              {{ item.code }}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="3">
        <v-text-field outlined label="Cotizacion a dolar" color="cyanText"
          :value="selectedCurrecies == null ? '' : selectedCurrecies.value"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col class="ml-3" cols="12" lg="3">
        <v-menu ref="menu1" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field outlined v-model="datePay" label="Fecha pago" persistent-hint prepend-inner-icon="mdi-calendar"
              v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="datePay" no-title @input="menu2 = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="3">
        <v-autocomplete return-object outlined v-model="selectedPayments" :items="payments" label="Metodo de pago"
          color="cyanText" prepend-inner-icon="attach_money" item-text="name" item-id="id" required>
          <template #item="{ item }">
            <v-icon class="mx-1">{{ getIconPayments(item.id) }}</v-icon>
            {{ item.name }}
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" lg="5">
        <v-autocomplete return-object outlined v-model="selectedAccontBank" :items="accountBank" label="Cuenta de banco"
          color="cyanText" prepend-inner-icon="account_balance" item-text="text" item-id="account_id" required>
          <template #item="{ item }">
            <!-- {{ item.text }} -->
            <div class="d-flex align-center justify-start my-1">
              <v-img contain width="30" height="30" class="ml-n1" :src="getIconBank(item.account_bank_id)"></v-img>
              <span class="">{{ item.text }}</span>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field outlined label="Nota" color="cyanText"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-n12">
        <v-data-table :search="search" :headers="headers" :items="data" :items-per-page="100" class="elevation-0" item-key="invoice_number">
          <template v-slot:top>
            <v-toolbar class="elevation-0 rounded-lg">
              <v-toolbar-title>Facturas a cobrar </v-toolbar-title>

              <v-spacer></v-spacer>
              <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
              <v-spacer></v-spacer>
              <span class="mx-5 success--text"> Total a pagar:  <b>$  {{ currencyFormat( data.reduce((total, item) => total + parseFloat(item.paid), 0)) }}</b></span>

            </v-toolbar>
          </template>
          <template v-slot:[`item.invoice_number`]="{ item }">
            <v-hover v-slot="{ hover }">
              <span @click="showInvoice(item, true)"
                class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                  color: hover ? 'red' : 'black',
                  cursor: hover ? 'pointer' : '',
                  'text-decoration': hover ? 'underline' : '',
                }">
                {{ item.invoice_number }}</span>
            </v-hover>
          </template>
          <template v-slot:[`item.total_amount`]="{ item }">
            <span>
              {{ currencyFormat(item.total_amount) }}
              {{ item.currency_code }}</span>
          </template>
          <template v-slot:[`item.total_paid_amount`]="{ item }">
            <span style="color: green">
              {{ currencyFormat(item.total_paid_amount) }}
              {{ item.currency_code }}</span>
          </template>
          <template v-slot:[`item.total_pending_amount`]="{ item }">
            <template v-if="selectedCurrecies">
              <span style="color: red">
                {{
                  currencyFormat(
                    getAmountCurrency(
                      selectedCurrecies,
                      item.currency_code,
                      item.total_amount - item.total_paid_amount
                    )
                  )
                }}
                {{ selectedCurrecies.code }}</span>
            </template>

            <template v-else>
              <span style="color: red">
                {{ currencyFormat(item.total_amount - item.total_paid_amount) }}
                {{ item.currency_code }}
              </span>
            </template>
          </template>
          <template v-slot:[`item.paid`]="{ item }">
            <v-text-field type="number" :max="item.total_amount - item.total_paid_amount" v-model="item.paid"
              prefix="$"></v-text-field>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="viewInvoices(item)" color="success" class="mx-2 mt-n2" v-bind="attrs" v-on="on">
                  payments
                </v-icon>
              </template>
              <span>Agregar pago</span>
            </v-tooltip>
          </template>
          <template v-if="selectedCurrecies" slot="body.append">
            <tr class="mt-2 mb-2">
              <th></th>
              <th></th>
              <th></th>
              <th class="font-weight-bold text-right" style="color: #616161; font-size: 20px">
                TOTAL
              </th>

              <th class="font-weight-normal text-start success--text" style="font-size: 18px">
                {{
                  currencyFormat(
                    data.reduce((sum, value) => sum + parseFloat(value.paid), 0)
                  )
                }}
                {{ selectedCurrecies.code }}
              </th>
            </tr>
          </template>
        </v-data-table></v-col>
    </v-row>
    <v-row justify="center" class="mb-4">
      <v-btn @click="save" color="primary" outlined>
        <v-icon>save</v-icon> Guardar
      </v-btn>
    </v-row>
  </v-flex>
</template>

<script>
import moment from "moment";

export default {
  data: (vm) => ({
    loading: false,
    selectedCurrecies: null,
    currencies: [],
    dateAccount: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    datePay: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: false,
    menu2: false,
    headers: [
      { text: "Factura", value: "invoice_number", align: "start" },
      {
        text: "Factura Suplidor",
        value: "supplier_invoice_number",
        align: "start",
      },
      { text: "Monto", value: "total_amount" },
      { text: "Total Pagado", value: "total_paid_amount", align: "end" },
      {
        text: "Balance pendiente",
        value: "total_pending_amount",
        align: "end",
      },

      { text: "Monto abono", value: "paid", align: "center", width: "25%" },
    ],
    search: "",
    payments: [],
    selectedPayments: [],
    accountBank: [],
    selectedAccontBank: [],
  }),

  props: {
    data: {
      type: Array,
      default: [],
    },
    type: {
      type: Boolean,
    },
  },
  created() {
    this.getData();
  },
  watch: {
    dateAccount(val) {
      // console.log(val)

      this.getCurrencies(val);
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  methods: {
    async save() {
      let payments = [];
      for (let item of this.data) {
        payments.push({
          entity_id: item.entity_id,
          invoice_id: item.invoice_id,
          payment_amount: parseFloat(item.paid),
        });
      }


      const data = {
        in_payment_id:null,
        in_payment_type_id:this.selectedPayments.id,
        in_invoice_type_id:this.type,
        in_business_unit_id: this.data[0].business_unit_id,
        in_location_id:this.data[0].location_id,
        in_department_id:this.data[0].department_id,
        in_effective_date:this.datePay,
        in_account_date : this.dateAccount,
        in_created_by: this.$store.state.auth.user.user.user_id,
        in_bank_account_id:this.selectedAccontBank.account_id,
        in_currency_id :this.selectedCurrecies.id,
        in_total_amount :this.data.reduce(
          (sum, value) => sum + parseFloat(value.paid),
          0
        ),
        in_invoice_payments :payments,
        in_entity_payments:[]
      }


      console.log(data)
      let response = await this.api_Post("transactions/save_pay", data);
      if (response.status == 200) {
        let datas = await response.json();
        console.log(datas)
        let route = this.$router.resolve({
          name: "ViewPaymentsCxP",
          params: { id: datas[0].id },
        });
        window.open(route.href);
        this.$emit("close");
      }
    },
    async getData() {
      this.loading = true;
      let business = await this.g_bussines();
      const data = await this.api_Get("income/payments_types");
      this.payments = await data.json();

      let datas = await business.filter(
        (element) => element.id == this.data[0].business_unit_id
      );

      for (let item of datas[0].business_unit_accounts) {
        this.accountBank.push({
          account_id: item.account_id,
          account_name: item.account_name,
          account_type_id: item.account_type_id,
          account_type_description: item.account_type_description,
          account_bank_id: item.account_bank_id,
          bank_description_long: item.bank_description_long,
          account_check_digit: item.account_check_digit,
          currency_id: item.currency_id,
          currency_description: item.currency_description,
          text:
            item.bank_description_long +
            " ( Cta. " +
            item.account_check_digit +
            " )",
          chart_of_account_id: item.chart_of_account_id,
        });
      }
      this.getCurrencies();
      this.loading = false;
    },
    getAmountCurrency(currencieSelected, currencieItem, paid) {
      let amount = 0;
      let data = this.currencies.filter(
        (element) => element.code === currencieSelected.code
      );

      if (currencieSelected.code == "USD" && currencieItem == "USD") {
        amount = parseFloat(parseFloat(paid));
      }
      if (currencieSelected.code == "USD" && currencieItem == "DOP") {
        let datas = this.currencies.filter(
          (element) => element.code === currencieItem
        );
        amount = parseFloat(parseFloat(paid) / datas[0].value);
      }

      if (currencieSelected.code == "HTG" && currencieItem == "DOP") {
        let datas = this.currencies.filter(
          (element) => element.code === currencieItem
        );
        amount = parseFloat(parseFloat(paid) * datas[0].value);
      }
      if (currencieSelected.code == "HTG" && currencieItem == "USD") {
        amount = parseFloat(parseFloat(paid) * data[0].value);
      }

      if (currencieSelected.code == "DOP" && currencieItem == "USD") {
        amount = parseFloat(parseFloat(paid) * parseFloat(data[0].value));
      }

      if (currencieSelected.code == "DOP" && currencieItem == "DOP") {
        amount = parseFloat(parseFloat(paid));
      }

      return amount;
    },
    async getCurrencies(val) {
      this.selectedCurrecies = null;
      const resultCurrencies = await this.api_Post(
        "accounting/currenciesBusiness",
        {
          business: this.data[0].business_unit_id,
          default_currency_id: 3,
          effective_date: this.dateAccount,
        }
      );
      this.currencies = await resultCurrencies.json();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped></style>
