<template>
    <v-container>
        <v-row class="mt-3 mb-3" justify="center">
            <h2>Aprobaciones</h2>
 
        </v-row>
        <v-row v-if="series2[0].data.length>0" justify="center">
            <v-col cols="6" justify="center">
                <apexchart type="bar" width="380" :options="options2" :series="series2"></apexchart>

            </v-col>
            <v-col cols="6">
                <v-row justify="center">




                </v-row>
                <v-row justify="center" v-if="series.length>0">
                    <div id="chart">
                        <apexchart type="pie" width="480" :options="chartOptions" :series="series"></apexchart>
                    </div>
                </v-row>
            </v-col>

        </v-row>
    </v-container>
</template>
  
<script>
export default {
    data() {
        return {
       
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: this.labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    
        options2 :{
            yaxis: {
  reversed: false
},
  plotOptions: {
    bar: {
      distributed: true
    }
  }  
}
        }

    
    },

    props:{
        series:{
            type:Array,
        },
        labels:{
            type:Array
        },
        all_data:{
            type:Array
        }
    },
    computed: {
        series2() {
     let data=   [{data: this.all_data.map((elementoOriginal) => {

                    return {
                    x: elementoOriginal.name,
                    y: elementoOriginal.quantity,

                    };
                    })
                }]  
                    return data
             } 
   
    },
};
</script>
  
<style></style>
  