<template>
  <div>
    <v-row justify="end">
      <v-btn :disabled="type === 3 || type === 4" v-if="!disableInput" small @click="$emit('showItems')" color="primary"
        class="mx-8 mt-4" outlined>
        <v-icon>add</v-icon>Agregar item
      </v-btn>
    </v-row>
    <v-row justify="end">
      <v-btn :disabled="type === 3 || type === 4" v-if="!disableInput" small @click="$emit('showVouchers')" color="primary"
        class="mx-8 mt-4" outlined>
        <v-icon>add</v-icon>Agregar Vocuhers
      </v-btn>
    </v-row>

    <v-col v-if="selected_withholdings != []" cols="12" md="8">
      <v-row class="mt-5" v-for="(item, i) of selected_withholdings" :key="i" justify="start" no-gutters>
        <span style="font-size: 20px; color: grey" class="mt-1">Retencion -
        </span>
        <v-col cols="12" md="4">
          <v-text-field class="mx-2" dense outlined :value="item.name" readonly>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field class="mx-2" v-model="item.percent" type="number" outlined readonly dense>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="1">
          <v-row no-gutters>
            <v-icon @click="$emit('delete_withholdings', item)" class="mt-2" color="black">close</v-icon>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-row justify="end">
      <v-btn :disabled="type === 3 || type === 4" @click="$emit('dialogWithholdings')" small color="primary"
        class="ma-1 mr-7 mt-9" outlined>
        <v-icon>add</v-icon>Agregar retencion
      </v-btn>
    </v-row>
    <v-row justify="end">
      <v-container>
        <v-row class="mx-4" justify="end">
          <v-btn :disabled="type === 3 || type === 4" v-if="!disableInput" small @click="$emit('showDialogPayments')"
            color="primary" outlined>
            <v-icon> add </v-icon>
            Agregar pago
          </v-btn>
        </v-row>
      </v-container>
    </v-row>
    <v-row justify="end">
      <v-card elevation="0" width="50%">
        <v-col v-if="selectedPayments != []" cols="12" md="12">
          <v-row v-for="(item, i) of selectedPayments" :key="i" justify="end" no-gutters>
            <v-col cols="12" md="5">
              <v-text-field class="mx-2" dense outlined :value="item.name" readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field class="mx-2" v-model="item.amount" type="number" outlined dense>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-row no-gutters>
                <v-icon @click="$emit('deletePayment',item)" class="mt-2" color="black">close</v-icon>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-row>
    <v-row class="mt-n16" justify="start">
      <v-col cols="12" md="5">
        <v-textarea v-model="nota" class="pa-2" outlined label="Nota"></v-textarea>
      </v-col>
    </v-row>
    <v-row class="mb-4" justify="end" style="margin-right: 0.2vh; margin-top: -160px">
      <v-card style="margin-top: 5px; margin-bottom: 20px" elevation="0" width="20%" height="auto" outlined>
        <v-layout fill-height align-center>
          <v-col cols="12" md="6">
            <v-row justify="end">
              <span style="font-size: 1vw" class="font-weight-bold">
                Subtotal
              </span>
            </v-row>
            <v-row justify="end">
              <span style="font-size: 1vw" class="font-weight-bold">
                Descuento
              </span>
            </v-row>
            <v-row justify="end">
              <span style="font-size: 1vw" class="font-weight-bold">
                Itbis
              </span>
            </v-row>
          </v-col>
          <v-col cols="12" md="5">
            <v-row justify="end">
              <span style="font-size: 1vw" class="font-weight-normal">{{
           totales.tot_sub
              }}</span>
            </v-row>
            <v-row justify="end">
              <span style="font-size: 1vw" class="font-weight-normal">0.00
              </span>
            </v-row>
            <v-row justify="end">
              <span style="font-size: 1vw" class="font-weight-normal">{{ currencyFormat(totales.tot_itbis) }}
              </span>
            </v-row>
          </v-col>
          <v-col cols="12" md="1"></v-col>
        </v-layout>
        <v-divider class="mt-4" style="background-color: grey"></v-divider>

        <v-row class="mt-5" justify="end">
          <v-spacer></v-spacer>
          <span style="font-size: 1.2em" class="font-weight-bold">Total
            <template v-if="seleted_currencies != null">
              {{
                currencies.filter(
                  (element) => element.id == seleted_currencies
                )[0].code
              }}
            </template>
          </span>
          <v-spacer></v-spacer>

          <span style="font-size: 1.2em" class="font-weight-bold">{{
            totales.tot_amount
          }}</span>
        </v-row>
  
      </v-card>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {},
  data: () => ({}),
  created() {
    //this.getData();
  },

  props: {
    disableInput: {
      type: Boolean,
    },
    type: {
      type: Number,
    },
    selected_withholdings: {
      type: Array,
    },
    selectedPayments: {
      type: Array,
    },
    currencies: {
      type: Array,
    },
    nota: {
      type: String,
    },
    totales: {
      type: Object,
    },
    nota: {
      type: String,
    },
    seleted_currencies: {
      type: Number,
    },
  },

  methods: {
    delete_withholdings(item) {
      this.$emit("delete_withholdings", item);
    },
  },
};
</script>
