
const { default: pocketbase } = require("pocketbase");


exports.install = function (Vue, options) { 
 
  const version = "1.0.0";
  const url =process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL_PRODUCTION:process.env.VUE_APP_API_URL_DEV
  //const url =process.env.VUE_APP_API_URL_PRODUCTION
  const pb = new pocketbase(process.env.VUE_APP_POCKETBASE);
  const store = require('../store').default;
  const token = store.getters["auth/getToken"]
 
  const cabecera = {
    "Content-Type": "application/json",
    Authorization: "bearer " + token
  };

  const locationsAccess = JSON.parse(localStorage.getItem("locations_access"));
  Vue.prototype.api_Get = async function (link) {

 
    const request = { method: "GET", headers: cabecera };
    const response = await fetch(url + link, request);

    return response;
  };
  //consulta POST global---------------------------------------------------------------------
  Vue.prototype.api_Post = async function (link, body) {

    const request = {
      method: "POST",
      headers: cabecera,
      body: JSON.stringify(body),
    };
    const response = await fetch(url + link, request);
    return response;
  };
  //consulta POST global---------------------------------------------------------------------
  Vue.prototype.api_PostPdf = async function (link, body) {
 
    const cabeceraPdf = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token
    };
    const request = {
      method: "POST",
      headers: cabeceraPdf,
      body: JSON.stringify(body),
    };
    const response = await fetch(url + link, request);
    return response;
  };
  //consulta PUT global---------------------------------------------------------------------
  Vue.prototype.api_Put = async function (link, body) {
    const request = {
      method: "PUT",
      headers: cabecera,
      body: JSON.stringify(body),
    };
    const response = await fetch(url + link, request);
    return response;
  };
  //consulta DELETE global---------------------------------------------------------------------
  Vue.prototype.api_Delete = async function (link) {
    const request = { method: "DELETE", headers: cabecera };
    const response = await fetch(url + link, request);
    return response;
  };
  //combo generico (solo contiene id y description)--------------------------------------------
  Vue.prototype.g_ComboGeneric = async function (link) {
    let items = [];
    let result = await this.api_Get(link);
    let data = await result.json();
    data.forEach((element) =>
      items.push({
        value: element.id,
        text: element.description,
      })
    );
    return items;
  };

  Vue.prototype.loadingpage = function () {
    let loader = this.$loading.show({
      // Pass props by their camelCased names
      container: this.$refs.loadingContainer,
      canCancel: false, // default false
      onCancel: this.yourCallbackMethod,
      color: "#007bff",
      loader: "dots",
      width: 150,
      height: 150,
      backgroundColor: "#000000",
      opacity: 0.2,
      zIndex: 999,
    });

    return loader;
  };
  Vue.prototype.time_line_config = function (type,status_id) {
        if (type==2 && status_id === 3) {
            return  {color:'deep-orange accent-3',icon:'cancel'}
        }
        if (status_id === 1) {
            return {color:'blue-grey lighten-1',icon:'auto_delete'}
        }
        if (status_id === 2) {
            return {color:'amber accent-2',icon:'hourglass_empty'}
        }
        if (status_id === 3) {
            return {color:'orange accent-4',icon:'vertical_align_bottom'}
        }
        if (status_id === 4) {
            return {color:'green darken-1',icon:'download_done'}
        }
        return {color:'blue-grey lighten-1',icon:'auto_delete'}
  };


  Vue.prototype.time_line_orders = function (type) {
    if (type === 1) {
      return { color: 'grey', icon: 'auto_delete' }
  }
  if (type === 2) {
      return { color: 'amber', icon: 'hourglass_empty' }
  }
  if (type === 3) {
      return { color: 'blue', icon: 'vertical_align_bottom' }
  }
  if (type === 4) {
      return { color: 'green', icon: 'download_done' }
  }
  if (type === 5) {
      return { color: 'green', icon: 'download_done' }
  }
  if (type === 6) {
      return { color: 'green', icon: 'download_done' }
  }
  if (type === 7) {
      return { color: 'green', icon: 'download_done' }
  }
  if (type === 8) {
      return { color: 'green', icon: 'download_done' }
  }
  if (type === 9) {
      return { color: 'green', icon: 'download_done' }
  }
  if (type === 10) {
      return { color: 'green', icon: 'download_done' }
  }
  if (type === 11) {
      return { color: 'red', icon: 'gpp_bad' }
  }
  if (type === 12) {
      return { color: 'brown', icon: 'disabled_by_default' }
  }
  if (type === 13) {
      return { color: 'orange', icon: 'warehouse' }
  }
  if (type === 14) {
      return { color: 'cyan', icon: 'lock' }
  }

  };
  Vue.prototype.getFlag = function (type) {
    if (type === 1) {
      return 'do'
  }
  if (type === 2) {
    return 'ht'
  }
  if (type === 3) {
    return 'us'
  }


  };

  //combo turnos------------------------------------------------------------------------------
  Vue.prototype.g_Shifts = async function () {
    let itemShift = [];
    let result = await this.api_Get("humanResource/shifts");
    let data = await result.json();
    data.forEach((element) =>
      itemShift.push({
        value: element.id,
        text: element.description_long,
        horas: element.work_hours,
        real_hours: element.real_hours,
      })
    );
    return itemShift;
  };
  //combo bancos------------------------------------------------------------------------------
  Vue.prototype.g_Bancos = async function () {
    let itemsBanks = [];
    let result = await this.api_Get("humanResource/banks");
    let dataBanks = await result.json();

    return dataBanks;
  };

  //tipo cuenta------------------------------------------------------------------------------
  Vue.prototype.g_type_account = async function () {
    let result = await this.api_Get("humanResource/accounts/types");
    let dataTypeAccount = await result.json();

    return dataTypeAccount;
  };

  //combo tipos dni---------------------------------------------------------------------------
  Vue.prototype.g_dniTypes = async function () {
    let itemsDni = [];
    let result = await this.api_Get("recruitment/dni_types");
    let data = await result.json();
    data.forEach((element) =>
      itemsDni.push({
        value: element.id,
        text: element.dni_type_description,
        contry_id: element.country_id,
      })
    );
    return itemsDni;
  };
  //combo nacionalidades-----------------------------------------------------------------------
  Vue.prototype.g_nacionalities = async function () {
    let itemsNationalities = [];
    let result = await this.api_Get("recruitment/nationalities");
    let data = await result.json();
    data.forEach((element) =>
      itemsNationalities.push({
        value: element.id,
        text: element.nationality_description,
        country_id: element.country_id,
      })
    );
    return itemsNationalities;
  };
  //combo companies--------------------------------------------------------------------------
  Vue.prototype.g_companies = async function () {
    let result = await this.api_Get("humanResource/companies");
    return await result.json();
  };
  //combo ars--------------------------------------------------------------------------
  Vue.prototype.g_ars = async function () {
    let result = await this.api_Get("humanResource/ars");
    return await result.json();
  };
  //combo Bussines unit-----------------------------------------------------------------------
  Vue.prototype.g_bussines = async function () {
    let result = await this.api_Get("humanResource/bussines_unit");

    return await result.json();
  };
  //combo monedas--------------------------------------------------------------------------
  Vue.prototype.g_currencies = async function () {
    let result = await this.api_Get("humanResource/currencies");
    let dataCurrencies = await result.json();

    return dataCurrencies;
  };
  //combo nlocations-----------------------------------------------------------------------
  Vue.prototype.g_locations = async function () {
    let itemsLocations = [];
    let result = await this.api_Get("humanResource/locations");
    let data = await result.json();
    console.log(data)
    for (var item of data) {
     
      // if (locationsAccess.includes(item.id)) {
        itemsLocations.push({
          value: item.id,
          text: item.desc_loc_long,
          bus_id: item.bus_id,
          condicion: item.bus_id,
          catalogue_id:item.catalogue_id,

          description_short: item.desc_loc_short,
        });
     // }
    }

    return itemsLocations;
  };
  //combo departamentos------------------------------------------------------------------------
  Vue.prototype.g_departments = async function () {
    let itemsDepartment = [];
    let result = await this.api_Get("humanResource/departments");
    let data = await result.json();

    
    data.forEach((element) =>
      itemsDepartment.push({
        value: element.id,
        routes: element.department_routes,
        text:
          element.department_names.length > 0
            ? element.department_names[0].department_names_description
            : "Departamento (" + element.id + ") Sin descripcion",
      })
    );

    return itemsDepartment;
  };

  //combo tipos categorias-------------------------------------------------------------------
  Vue.prototype.g_typesCategories = async function () {
    let itemType = [];
    let result = await this.api_Get(
      "humanResource/employees/actions/categoryTypes"
    );
    let data = await result.json();
    data.forEach((element) =>
      itemType.push({
        value: element.id,
        text: element.employee_type_names_description,
      })
    );
    return itemType;
  };

  //combo grupos de pago con location2----------------------------------------------------------------------
  Vue.prototype.g_payGroupsWithLocations = async function () {
    let itemPayGroup = [];

    let result = await this.api_Post("humanResource/payGroups2", {
      filters: locationsAccess,
    });
    let data = await result.json();
    data.forEach((element) =>
      itemPayGroup.push({
        value: element.id,
        text: element.description_long,
        condicion: element.location_id,
      })
    );
    return itemPayGroup;
  };
  //combo semanas sin confrimar---------------------------------------------------------------------
  Vue.prototype.g_paryrollWeeks = async function () {
    let itemsWeek = [];
    let result = await this.api_Get("payroll/payrollWeeks");
    let data = await result.json();

    data.forEach((element) => {
      if (element.is_payroll_opened == false) {
        itemsWeek.push({
          value: element.date_begin,
          text:
            "Semana (" +
            element.description +
            ") desde " +
            element.date_begin +
            " hasta " +
            element.id,
          date_end: element.id,
        });
      }
    });
    return itemsWeek;
  };
  //obtiene el paygroup de un location----------------------------------------------------------------------
  Vue.prototype.g_payGroupWithLocation = async function (location_id) {
    let result = await this.api_Get(
      "humanResource/getPaygroupWhitLocation/" + location_id
    );
    let data = await result.json();
    return data[0].id;
  };
  //combo semanas todas las semanas---------------------------------------------------------------------
  Vue.prototype.g_paryrollAllWeeks = async function () {
    let itemsWeek = [];
    let result = await this.api_Get("payroll/payrollWeeks");
    let data = await result.json();

    data.forEach((element) => {
      itemsWeek.push({
        value: element.date_begin,
        text:
          "Semana (" +
          element.description +
          ") desde " +
          element.date_begin +
          " hasta " +
          element.id,
        date_end: element.id,
      });
    });
    return itemsWeek;
  };

  //convierte primera letra mayuscula y el resto minuscula en cada palabra de un string------------
  Vue.prototype.firtsLetterMinRestUp = function (string) {
    let stringSplit = string.split(" ");
    let newString = "";
    for (var item in stringSplit) {
      newString +=
        stringSplit[item].charAt(0).toUpperCase() +
        stringSplit[item].slice(1).toLowerCase() +
        " ";
    }
    return newString;
  };
  Vue.prototype.filLeft = function (str, length, add) {
    //rellena caracteres a la izauierda
    while (str.length < length) {
      str = add + str;
    }
    return str;
  };
  Vue.prototype.filRight = function (str, length, add) {
    //rellena caracteres a la derecha
    while (str.length < length) {
      str += add;
    }
    return str;
  };

  Vue.prototype.ViewWarehouse = function (warehouse_id) {
    //rellena caracteres a la derecha
    this.$router.push({
      name: "ViewWarehouse",
      params: { id: warehouse_id },
    });
  };
  Vue.prototype.viewEntity = function (entity_id,type) {
    //rellena caracteres a la derecha
 

    if(type==true)
    {
      let route = this.$router.resolve({
        name: "ViewEntitys",
        params: { id: btoa( entity_id)  },
      });
      window.open(route.href);
    }
    else
    {
      this.$router.push({
        name: "ViewEntitys",
        params: { id:btoa( entity_id) },
      });
    }
  };

  Vue.prototype.showInvoice = function (number,type) {
    if(type==true)
    {
      let route = this.$router.resolve({
        name: "ViewInvoice",
        params: { id:btoa( number.invoice_number)  },
      });
      window.open(route.href);
    }
    else
    {
      this.$router.push({
        name: "ViewInvoice",
        params: { id:btoa( number.invoice_number) },
      });
    }

  };
  Vue.prototype.viewReception = function (order_id,id) {
   
      this.$router.push({
        name: "ViewReceptions",

          params: { order_id: btoa(order_id), id: btoa(id )},
        })
      
    
  
  };
  Vue.prototype.showPayments = function (number,type) {

  
      let route = this.$router.resolve({
        name:type===1? "ViewPaymentsCxC":"ViewPaymentsCxP",
        params: { id: number },
      });
  
   
      window.open(route.href);
      // this.$router.push({
      //   name: "ViewTransactions",

      //     params: { id: btoa(number)},
      //   })
  };
  Vue.prototype.showTransactions = function (number,type) {

  
      let route = this.$router.resolve({
        name:type===1? "ViewTransactionsCxC":"ViewTransactionsCxP",
        params: { id:btoa( number) },
      });
  
   
      window.open(route.href);
      // this.$router.push({
      //   name: "ViewTransactions",

      //     params: { id: btoa(number)},
      //   })
  };
  Vue.prototype.showOrder = function (number,types,typeb) {
   
    var typesb=0
    if(typeb==2)
    {
      typesb='a2xrbWFuaW4Rtgsdsx'
    }
    var type='adsa'
    if(types==true)
    {
      type='a2xrbWFuaW4'
    }
 
    this.$router.push({
      name: "ViewOrders",
 
      params: { id: btoa( number),type:type,typeb:typesb},

    });
  };
  Vue.prototype.showQuotation = function (number) {

    this.$router.push({
      name: "ViewOrders",
      params: { id: number },
    });
  };

  Vue.prototype.getIconPayments = function (type) {

      
      if(type==1)
      {
          return "mdi-cash"
      }
      if(type==2)
      {
        return "mdi-credit-card"
      }
      if(type==3)
      {
        return "mdi-bank-check"
      }
      if(type==4)
      {
        return "mdi-bank-transfer"
      }
      if(type==5)
      {
        return "mdi-bank-transfer"
      }
      if(type==6)
      {
        return "mdi-bank-transfer"
      }
      if(type==7)
      {
        return "mdi-bank-transfer"
      }

    
  };

  Vue.prototype.getIconBank = function (type) {

      
      if(type==20)
      {
          return "popular.jpg"
      }
      if(type==21)
      {
          return "banreservas.png"
      }
      if(type==2)
      {
        return "mdi-credit-card"
      }
   

    
  };

  Vue.prototype.beforeRoute = function () {
    window.history.back();
  };
  Vue.prototype.clearEmployeeCodeToFilter = function (code) {
    return this.filLeft(code.replaceAll("'","").replaceAll("-","").replaceAll("/","").replaceAll("*","").replaceAll('%',''), 6, "0");
  }
  Vue.prototype.get_storage = async function (bucket, id_storage) {

    // const responseUrl = await this.api_Post("storage/get_files", {
    //   bucket: bucket,
    //   id_file: id_storage,
    // });

    // if (responseUrl.status == 200) {
    //   var invoice = await responseUrl.json();
    //   var url = invoice.url;
    // }

    return "";
  };

  Vue.prototype.delete_file_storage = async function (bucket, filesRecived) {
    console.log(bucket)
    console.log(filesRecived)
   // let result= await pb.records.delete(bucket, filesRecived);
    let result= await pb.collection(bucket).update(filesRecived, {
      'documents-': [],
  });
console.log(result)
    return result;
  };

  Vue.prototype.upload_images = async function (bucket, filesRecived) {
    
    const formData = new FormData();

    // listen to file input changes and add the selected files to the form data
    for (let file of filesRecived.files) {
      formData.append("documents", file);
    }
   
    // upload and create new record
    try{
      const createdRecord = await pb.collection(bucket).create( formData
        );
        return createdRecord;
    }
  catch(err){console.log(err)}
    
  };


  
  Vue.prototype.currencyFormat = function (number) {
    // return new Intl.NumberFormat('en-IN', {style: 'currency',currency: 'USD', minimumFractionDigits: 2}).format(number);
    if (number == undefined || number === 0) {
      return "0.00";
    }
    let numberFormat = Intl.NumberFormat("en-US", { style: "decimal" })
      .format(number)
      .split(".");
    if (numberFormat.length == 1) {
      numberFormat.push("00");
    }
    return (
      numberFormat[0] +
      "." +
      (parseInt(numberFormat[1]) < 10 && parseInt(numberFormat[1]) > 0
        ? "0" + parseInt(numberFormat[1])
        : numberFormat[1])
    );
  };
  Vue.prototype.intToColum = function (number) {
    let numberReturn = 0;
    if (number - parseInt(number) > 0) {
      numberReturn = parseInt(number) + 1;
    } else {
      numberReturn = number;
    }
    return numberReturn;
  };
  Vue.prototype.textFormater = function (text) {
    let arrayWords = text.toLowerCase().split(" ");

    for (var key in arrayWords) {
      arrayWords[key] =
        arrayWords[key].charAt(0).toUpperCase() + arrayWords[key].slice(1);
    }

    return arrayWords.join(" ");
  };

  Vue.prototype.generateCsvFile = function (filename, csv) {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    // downloadLink.setAttribute("href","data:text/plain;charset=utf-8," + encodeURIComponent(csv));
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  Vue.prototype.generateTxtFile = function (filename, text) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  Vue.prototype.monthsArray = [
    { value: 1, text: "Enero" },
    { value: 2, text: "Febrero" },
    { value: 3, text: "Marzo" },
    { value: 4, text: "Abril" },
    { value: 5, text: "Mayo" },
    { value: 6, text: "Junio" },
    { value: 7, text: "Julio" },
    { value: 8, text: "Agosto" },
    { value: 9, text: "Septiembre" },
    { value: 10, text: "Octubre" },
    { value: 11, text: "Noviembre" },
    { value: 12, text: "Diciembre" },
  ];
};
