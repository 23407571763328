<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" md="5" class="pa-0">
                <v-row justify="center">
                    <span class="ma-3" style="font-size: 18px; color: #616161">{{ title.all }}</span>
                </v-row>
                <v-card outlined>
                    <v-card-text class="pa-0">
                        <v-data-table :items-per-page="5" :headers="headers" :search="searchAll" :items="getItemAvailable">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-spacer></v-spacer>
                                    <v-text-field class="text-xs-center" v-model="searchAll" append-icon="search"
                                        label="Buscar" single-line hide-details>
                                    </v-text-field>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.agre`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" medium color="success" @click="move(item, 1)">
                                            arrow_forward
                                        </v-icon>
                                    </template>
                                    <span>Escoger</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="1" class="ma-0 pa-0">
                <v-container fill-height fluid>
                    <v-row align="center">
                        <v-col cols="12" align="center">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" color="success" class="mr-2 pa-1" icon
                                        @click="moveAll('right')">
                                        <v-icon>arrow_forward_ios</v-icon>
                                    </v-btn>
                                </template>
                                <span>Agregar todo</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="12" align="center">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" color="error" class="mr-2 pa-1" icon
                                        @click="moveAll('left')">
                                        <v-icon>arrow_back_ios</v-icon>
                                    </v-btn>
                                </template>
                                <span>Quitar todo</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-col cols="12" md="5" class="pa-0">
                <v-row justify="center">
                    <span class="ma-3" style="font-size: 18px; color: #616161">{{ title.selected }}</span>
                </v-row>
                <v-card outlined>
                    <v-card-text class="pa-0">
                        <v-data-table :items-per-page="5" :headers="headerSelected" :search="searchSelected"
                            :items="getItemSelected">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-spacer></v-spacer>
                                    <v-text-field class="text-xs-center" v-model="searchSelected" append-icon="search"
                                        label="Buscar" single-line hide-details>
                                    </v-text-field>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.put_off`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" medium color="error" @click="move(item, 2)">
                                            arrow_back
                                        </v-icon>
                                    </template>
                                    <span>Quitar</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            allItems: [],
            searchAll: '',
            headers: [
                {
                    text: "ID",
                    align: "start",
                    value: "id",
                },
                { text: "Descripcion", value: "name" },
                { text: "", value: "agre", sortable: false },
            ],
            headerSelected: [
                {
                    text: "",
                    align: "start",
                    value: "put_off",
                    sortable: false
                },
                { text: "ID", value: "id" },
                { text: "Descripcion", value: "name" },
            ],
            searchSelected: ''
        }
    },
    name: "DataIterator",
    props: {
        all_items: {
            type: Array,
            default() {
                return []
            }
        },
        items_selected: {
            type: Array,
            default() {
                return []
            }
        },
        title: {
            type: Object,
            default() {
                return {
                    all: 'Todo',
                    selected: 'Seleccionado'
                }
            }
        },
    },
    created() {
    },
    mounted() {
        this.initialized()
    },
    computed: {
        getItemAvailable() {
            return this.allItems.filter((element) => !this.items_selected.includes(element.id));
        },
        getItemSelected() {
            return this.allItems.filter((element) => this.items_selected.includes(element.id));
        }
    },
    watch: {
    },
    methods: {
        initialized() {
            this.allItems = Object.assign([], this.all_items);
        },
        move(item, type) {
            if (type == 1) {
                this.items_selected.push(item.id);
                return;
            }

            let index = this.items_selected.indexOf(item.id);
            this.items_selected.splice(index, 1);
        },
        moveAll(type) {
            if (type == 'right') {
                for (let item of this.allItems) {
                    this.items_selected.push(item.id);
                }
                return;
            }
            this.items_selected.splice(0, this.items_selected.length);
        }
    },
}
</script>