<template>
    <div>

        <!-- <v-row justify="center">
            <v-icon class="mx-2" size="35">currency_exchange</v-icon> <span style="font-size: 20px;">Comprobantes
                fiscales</span>
        </v-row> -->
        <v-row justify="center">
            <v-col cols="12" sm="4" md="4" class="pt-0 pb-0">
                <v-autocomplete class="mt-4" dense outlined v-model="editedItem.business_unit_id" :items="itemsBussines"
                    label="Unidad de negocio" color="cyanText" @change=" changeBussines()"
                    prepend-inner-icon="corporate_fare" required></v-autocomplete>
            </v-col>
        </v-row>
        <v-row justify="center">

            <v-col cols="12">
                <v-data-table :headers="headers" :search="search" :items="vouchers" sort-by="calories" class="elevation-1">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Comprobantes</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="1000px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn outlined @clikc="editedItem.invoice_voucher_type_id = null" color="primary" dark
                                        class="mb-2" v-bind="attrs" v-on="on">
                                        <v-icon>add</v-icon> Nuevo comprobantes
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title primary-title class="justify-center">
                                        <div>
                                            <span class="text-h5 ">{{ formTitle }}</span>
                                        </div>

                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row justify="center">
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-autocomplete ref="business_unit_id" dense outlined
                                                        @change="getUltimateSequence" v-model="editedItem.business_unit_id"
                                                        :items="itemsBussines" label="Unidad de negocio" color="cyanText"
                                                        prepend-inner-icon="corporate_fare" required></v-autocomplete>

                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-autocomplete @change="getUltimateSequence" class="mr-9 "
                                                        ref="invoice_voucher_type_id" outlined dense
                                                        v-model="editedItem.invoice_voucher_type_id" :items="ncfTypes"
                                                        item-text="name" item-value="id" label="Tipo NCF" color="cyanText"
                                                        required></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="10"
                                                        transition="scale-transition" offset-y min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field ref="expire_at" dense outlined
                                                                v-model="dateFormatDMY" label="Fecha vencimineto"
                                                                prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                                                                v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="editedItem.expire_at"
                                                            @input="menu = false"></v-date-picker>
                                                    </v-menu>

                                                </v-col>
                                            </v-row>
                                            <v-row justify="center">


                                                <v-col cols="12" sm="6" md="3">
                                                    <v-text-field ref="from_sequence" type="number" dense outlined
                                                        v-model="editedItem.from_sequence"
                                                        label="Inicio secuencia"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="3">
                                                    <v-text-field :min="editedItem.from_sequence + 1" ref="Final secuencia"
                                                        type="number" dense outlined v-model="editedItem.to_sequence"
                                                        label="Final secuencia"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="3">
                                                    <v-text-field ref="number_auth" dense outlined
                                                        v-model="editedItem.number_auth"
                                                        label="Numero de autorizacion"></v-text-field>
                                                </v-col>

                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions class="justify-center">

                                        <v-btn color="red darken-1" text @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="primary" outlined @click="save">
                                            Generar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.available_voucher_quantity`]="{ item }">
                        <v-chip hidde-details small v-if="item.available_voucher_quantity < 10" outlined color="error">
                            {{ item.available_voucher_quantity }}
                        </v-chip>
                        <v-chip hidde-details small v-else outlined color="success">
                            {{ item.available_voucher_quantity }}
                        </v-chip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">

                        <v-icon color="primary" class="mr-2" @click="editItem(item)">
                            visibility
                        </v-icon>

                    </template>
                    <template v-slot:no-data>

                    </template>
                </v-data-table>
            </v-col>

        </v-row>
        <v-dialog v-model="dialogViewVoucher" width="600px">

            <v-card>
                <v-card-title>
                    <v-row justify="center">
                        <span class="text-h6">{{ editedItem.business_unit_description_long }}</span>
                    </v-row>
                    <v-row justify="center">
                        <span class="text-h6">{{ editedItem.invoice_voucher_type_name }}</span>
                    </v-row>

                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="headers_vouchers_selected" :items="vouchers_selected" sort-by="calories"
                        class="elevation-1">
                        <template v-slot:top>
                            <v-toolbar flat>

                                <v-spacer></v-spacer>
                                <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
                                <v-spacer></v-spacer>

                            </v-toolbar>
                        </template>

                        <template v-slot:[`item.expiration_date`]="{ item }">

                            <span>{{ getDate(item.expiration_date) }}</span>

                        </template>
                        <template v-slot:[`item.invoice_number`]="{ item }">

                            <v-hover v-slot="{ hover }">
                                <span @click="showInvoice(item, true)"
                                    class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                                        color: hover ? 'red' : 'black',
                                        cursor: hover ? 'pointer' : '',
                                        'text-decoration': hover ? 'underline' : '',
                                    }">
                                    {{ item.invoice_number }}</span>
                            </v-hover>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip hidde-details small v-if="item.invoice_number === null" outlined color="success">
                                DISPONILBIE
                            </v-chip>
                            <v-chip hidde-details small v-else-if="item.invoice_number != null" outlined color="info">
                                USADO
                            </v-chip>
                            <v-chip hidde-details small v-else outlined color="info">
                                VENCIDO
                            </v-chip>
                        </template>

                        <template v-slot:no-data>

                        </template>
                    </v-data-table>
                </v-card-text>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import moment from 'moment'

export default {
    data: () => ({
        date: moment().startOf("day").format("YYYY-MM-DD"),
        dialog: false,
        dialogViewVoucher: false,
        menu: false,
        lastdata: "",
        search: "",
        loading: false,
        dialogDelete: false,
        itemsBussines: [],
        vouchers_selected: [],
        headers_vouchers_selected: [
            {
                text: 'Comprobante',
                align: 'start',
                sortable: false,
                value: 'voucher_description',
            },

            { text: 'Fecha vencimineto', value: 'expiration_date' },
            { text: 'Factura', value: 'invoice_number' },
            { text: 'Status', value: 'status' },

        ],
        headers: [

            {
                text: 'Unidad de negocio',
                align: 'start',
                sortable: false,
                value: 'business_unit_description_long',
            },

            { text: 'Tipo de comprobante', value: 'invoice_voucher_type_name' },
            { text: 'Cantidad', value: 'voucher_quantity' },
            { text: 'Usado', value: 'used_voucher_quantity' },
            { text: 'Vencido', value: 'expired_voucher_quantity' },
            { text: 'Disponible', value: 'available_voucher_quantity' },

            { text: 'Action', value: 'actions' },
        ],
        filters: {
            business: 0
        },
        money_exchange: [],
        vouchers: [],
        today: moment().format("YYYY-MM-DD"),
        ncfTypes: [],
        editedIndex: -1,
        editedItem: {
            business_unit_id: 0,
            invoice_voucher_type_id: null,
            expire_at: moment().startOf("week").add(1, 'days').format("YYYY-MM-DD"),
            from_sequence: null,
            to_sequence: null,
            number_auth: ""
        },
        defaultItem: {
            business_unit_id: 0,
            invoice_voucher_type_id: null,
            expire_at: moment().startOf("week").add(1, 'days').format("YYYY-MM-DD"),
            from_sequence: null,
            to_sequence: null,
            number_auth: ""
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo comprobante' : 'Editar comprobante'
        }, dateFormatDMY() {
            return moment(this.editedItem.expire_at).format("DD/MM/YYYY");
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        async initialize() {
            this.itemsBussines = Object.assign(
                [],
                this.$store.state.auth.bussines_units
            );
            let resultTypesNcf = await this.api_Get("accounting/nfc_types");
            this.ncfTypes = await resultTypesNcf.json();

            this.itemsBussines.unshift({ text: "Seleciona una unidad", value: 0 });
            this.changeBussines()
        },
        async getUltimateSequence() {

            if (this.editedItem.invoice_voucher_type_id === null) {
                return;
            }
            let data = {
                business_unit_id: this.editedItem.business_unit_id,
                invoice_voucher_type_id: this.editedItem.invoice_voucher_type_id
            }
            try {
                let result_sequence = await this.api_Post("accounting/ultimate_sequence_vouchers", data);
                let data = await result_sequence.json()

                if (data.length === 0) {

                    this.editedItem.from_sequence = 1
                }
                else {

                    this.editedItem.from_sequence = parseFloat(data[0].sequence_number) + 1

                }
                // editedItem.invoice_voucher_type_id
            } catch (error) {
                console.log(error)
            }

        },

        async changeBussines() {

            this.loading = true
            let filters = ""
            ///console.log(business)
            filters += this.editedItem.business_unit_id == 0 ? "" : " WHERE BUSINESS_UNIT_ID =" + this.editedItem.business_unit_id + " "

            // let resultInvoices = await this.api_Post("transactions/get_money", { filters: filters });

            // this.money_exchange = await resultInvoices.json();

            try {
                let resultVouchers = await this.api_Post("accounting/vouchers", { filters: filters });
                this.vouchers = await resultVouchers.json()
            } catch (error) {
                console.log(error)
            }


            this.loading = false

        },
        getDate(item) {
            return moment(item).format("DD/MM/YYYY");
        },

        async editItem(item) {
            this.editedIndex = this.money_exchange.indexOf(item)
            this.editedItem = Object.assign({}, item)

            let data = {
                business_unit_id: this.editedItem.business_unit_id,
                invoice_voucher_type_id: this.editedItem.invoice_voucher_type_id
            }
            try {
                let resultVouchers = await this.api_Post("accounting/resume_vouchers", data);
                this.vouchers_selected = await resultVouchers.json()
                this.dialogViewVoucher = true
            } catch (error) {

                console.log(error)
            }

        },

        deleteItem(item) {
            this.editedIndex = this.money_exchange.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm() {
            try {
                const response = this.api_Post(
                    "transactions/delete_exchange",
                    this.editedItem
                );

                const data = await response;

                if (data.status == 200) {
                    this.$toast.success("Registro con exito!");
                    this.changeBussines(this.lastdata)
                } else {
                    this.$toast.error("data.error");
                }
            } catch (err) {
                console.log(err);
            }
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        async save() {
            if (this.editedItem.business_unit_id === 0) {
                this.$toast.warning("Seleciona la unidad de negocio")
                this.$refs.business_unit_id.focus()
                return
            }
            if (this.editedItem.invoice_voucher_type_id === null) {
                this.$toast.warning("Seleciona el tipo de comprobante")
                this.$refs.invoice_voucher_type_id.focus()
                return
            }
            if (this.editedItem.from_sequence === null) {
                this.$toast.warning("Inidica donde empieza la secuencia")
                this.$refs.from_sequence.focus()
                return
            }
            if (this.editedItem.to_sequence === null) {
                this.$toast.warning("Indica donde termina la secuencia")
                this.$refs.to_sequence.focus()
                return
            }
            if (this.editedItem.number_auth === "") {
                this.$toast.warning("Indica el numero de autorizacion")
                this.$refs.number_auth.focus()
                return
            }
            let data_voucher = {
                business_unit_id: this.editedItem.business_unit_id,
                invoice_voucher_type_id: this.editedItem.invoice_voucher_type_id,
                expire_at: this.editedItem.expire_at,
                from_sequence: this.editedItem.from_sequence,
                to_sequence: this.editedItem.to_sequence,
                number_auth: this.editedItem.number_auth
            }
            this.loading = true
            try {
                const response = this.api_Post(
                    "accounting/add_vouchers",
                    data_voucher
                );

                const data = await response;

                if (data.status == 200) {
                    this.$toast.success("Registro realizado con exito!");
                    this.changeBussines()
                } else {
                    this.$toast.error("data.error");
                }
            } catch (err) {
                console.log(err);
            }

            this.loading = false

            this.close()
        },
    },
}
</script>

<style></style>