<template>
  <v-flex>
    <v-btn @click="dialogSearchReceipt = true" small color="primary" class="mt-4" outlined>
      <v-icon>search</v-icon>Buscar recepcion
    </v-btn>

    <v-dialog persitent width="70%" v-model="dialogSearchReceipt">
      <v-card width="100%" height="100%">
        <v-toolbar flat dark color="transparent">
          <v-spacer></v-spacer>
          <v-btn @click="dialogSearchReceipt = false" icon dark>
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> </v-toolbar-title>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <v-card elevation="0">
            <v-data-table id="tabla" :headers="headers" :items="receptions" :search="search">
              <template v-slot:top>
                <v-toolbar flat id="toolbar">
                  <v-toolbar-title>Recepciones</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" single-line
                    hide-details>
                  </v-text-field>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:[`item.entry_id`]="{ item }">
                <v-hover v-slot="{ hover }">
                  <span class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }">
                    {{ filLeft(item.entry_id.toString(), 12, "0") }}</span>
                </v-hover>
              </template>
              <template v-slot:[`item.order_id`]="{ item }">
                <v-hover v-slot="{ hover }">
                  <span class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }">
                    {{ filLeft(item.order_id.toString(), 12, "0") }}</span>
                </v-hover>
              </template>

              <template v-slot:[`item.entity_name`]="{ item }">
                <v-hover v-slot="{ hover }">
                  <span @click="viewEntity(item.entity_id)" style="font-size: 14px"
                    class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                      color: hover ? 'red' : 'black',
                      cursor: hover ? 'pointer' : '',
                      'text-decoration': hover ? 'underline' : '',
                    }">{{ item.entity_name }}</span></v-hover>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip outlined small class="white--text" :color="time_line_orders(item.last_status_id).color">
                  {{ item.last_status_name }}
                </v-chip>
              </template>
              <template v-slot:no-data>
                <v-row justify="center" class="pt-10">
                  <v-icon size="80px">token</v-icon>
                </v-row>
                <v-row justify="center" class="pb-6"> No hay datos </v-row>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon large color="blue" class="mr-2" v-bind="attrs" v-on="on" @click="selectItem(item)">
                      done
                    </v-icon>
                  </template>
                  <span>Selecionar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card></v-card-text>
      </v-card>
    </v-dialog>
  </v-flex>
</template>
<script>
export default {
  data: () => ({
    receptions: [],
    search: "",
    dialogSearchReceipt: false,
    headers: [
      {
        text: "Recepcion",
        align: "start",

        value: "entry_id",
      },
      {
        text: "Orden",
        align: "start",

        value: "order_id",
      },
      { text: "Unidad negocio", value: "business_unit_description_long" },
      { text: "Location", value: "location_description_long" },
      { text: "Suplidor", value: "entity_name" },
      { text: "Status", value: "status" },
      // { text: "Total", value: "total_amount", align: "end" },
      // { text: "Status", value: "sta_description" },

      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo contacto" : "Editar contacto";
    },
  },
  props: {
    types_receptions: {
      type: Number,
    },
  },
  watch: {
    // search(val, newVal) {
    //     if (newVal.length >= 2) {
    //         this.searchClient(newVal);
    //     } else {
    //         this.receptions = [];
    //     }
    // },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {},
  created() {
    this.initialize();
  },

  methods: {
    async getData() {
      let filtersNew = "WHERE last_status_id in (13,14) AND invoice_id isnull";
      //     filtersNew+=" AND location_id in ("+this.$store.state.auth.acces_finance.locations+")"
      // let resultOrders = await this.api_Post("shopping/orders", { filters: filtersNew });
      this.order_id = this.$route.params.order_id;
      let id_receptions = this.$route.params.id;

      let filters = filtersNew;
      const responseRequest = await this.api_Post(
        "shopping/search_entry_receptions",
        {
          filters: filters,
        }
      );

      this.receptions = await responseRequest.json();
    },

    async initialize() {
      this.getData();
    },
    selectItem(item) {
      // console.log(item);
      this.$emit("selected-item", item);
    },
  },
};
</script>

<style></style>
