<template>
  <v-layout align-start>
    <v-flex>
      <v-container fluid>
        <v-row no-gutters justify="center">
          <span style="font-size: 34px; color: #616161">Impuestos</span>
        </v-row>
        <v-card outlined>
          <v-card-text>
            <v-card flat class="mt-0 pt-0">
              <v-card-text class="mt-0 pt-0">
                <v-data-table :headers="headersItbis" :search="search" :items="itbis">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Impuestos</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <span>{{ itbis.length }}</span>
                      <v-spacer></v-spacer>
                      <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar"
                        single-line hide-details>
                      </v-text-field>
                      <v-spacer></v-spacer>
                      <v-divider class="mx-3" inset vertical></v-divider>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" class="mr-2" v-bind="attrs" v-on="on" outlined @click="newItbis()">
                            Nuevo impuesto
                            <v-icon>add</v-icon>
                          </v-btn>
                        </template>
                        <span>Crear nuevo impuesto</span>
                      </v-tooltip>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.chart_of_account_id`]="{ item }">
                    <span v-if="chartOfAccount.length > 0">{{
                      chartOfAccount.filter((element) => element.id == item.chart_of_account_id)[0].name }}</span>
                  </template>
                  <template v-slot:[`item.supply_chart_of_account_id`]="{ item }">
                    <span v-if="chartOfAccount.length > 0">{{
                      chartOfAccount.filter((element) => element.id == item.supply_chart_of_account_id)[0].name }}</span>
                  </template>
                  <template v-slot:[`item.edit`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editItbis(item)">
                          edit
                        </v-icon>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-dialog v-model="dialogEdit" max-width="50%">
            <v-card outlined>
              <v-card-title class="mb-3">
                <span>{{ titleDialog }}</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogEdit = false">
                  <v-icon>clear</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row justify="center">
                    <v-col cols="12" sm="3" md="12">
                      <v-text-field class="text-xs-center" v-model="newItbis.name" label="Descripcion" outlined>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="12">

                      <v-text-field class="text-xs-center" v-model="newItbis.value" prepend-inner-icon="percent"
                        label="Porcentaje" type="number" max="100" min="0" outlined>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="12">
                      <v-autocomplete v-model="newItbis.chart_of_account_id" :items="chartOfAccount" item-value="id"
                        item-text="name" color="cyanText" label="Cuenta contable" outlined required>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="12">
                      <v-autocomplete v-model="newItbis.supply_chart_of_account_id" :items="chartOfAccount"
                        item-value="id" item-text="name" color="cyanText" label="Cuenta contable" outlined required>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="saveItbis()" :loading="loadingSave">
                  {{ nameButon }}
                </v-btn>
                <v-btn color="error" text @click="dialogEdit = false">
                  Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-container>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  data: () => ({
    search: "",
    itbis: [],
    newItbis: {
      name: "",
      chart_of_account_id: "",
      supply_chart_of_account_id: "",
      percent: 0.0,
      value: 0,
    },
    headersItbis: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      { text: "Descripcion", value: "name" },
      { text: "Porcentaje", value: "value" },
      { text: "Cuenta venta", value: "chart_of_account_id" },
      { text: "Cuenta compra", value: "supply_chart_of_account_id" },
      { text: "Editar", value: "edit", sortable: false },
    ],
    dialogEdit: false,
    chartOfAccount: [],
    nameButon: "",
    is_new_Withholdings: true,
    titleDialog: "",
    loadingSave: false,
  }),
  components: {},
  created() {
    this.initialized();
  },
  computed: {},
  watch: {},
  methods: {
    async initialized() {
      this.getData();
      const resultTypeAccounts = await this.api_Get("accounting/accounts");
      this.chartOfAccount = await resultTypeAccounts.json();
    },
    async getData() {
      const request = await this.api_Get("accounting/taxes");
      const data = await request.json();

      if (request.status == 200) {
        this.itbis = data;

      }
    },
    editItbis(item) {
      this.is_new_Withholdings = false;
      this.titleDialog = "Editar " + item.id + "-" + item.name;
      this.newItbis = Object.assign({}, item);
      this.newItbis.value = (item.value * 100).toFixed(2);
      this.nameButon = "Actualizar";
      this.dialogEdit = true;
    },
    newItbis() {
      this.clearDialog();
      this.titleDialog = "Nuevo itbis ";
      this.nameButon = "Guardar";
      this.dialogEdit = true;
    },
    clearDialog() {
      this.newItbis = {
        chart_of_account_id: "",
        percent: 0.0,
        porcentaje: 0,
      };
    },
    async saveItbis() {
      this.loadingSave = true;

      let datos = {
        id: "",
        name: this.newItbis.name,
        percent: this.newItbis.value / 100,
        chart_of_account_id: this.newItbis.chart_of_account_id,
        supply_chart_of_account_id: this.newItbis.supply_chart_of_account_id,
      };

      let requSave;
      if (this.is_new_Withholdings == true) {
        requSave = await this.api_Post("accounting/itbis", datos);
      } else {
        datos.id = this.newItbis.id;
        requSave = await this.api_Put("accounting/itbis", datos);
      }
      if (requSave.status === 200) {
        this.$toast.success(
          "Restriccion " + this.is_new_Withholdings == true
            ? "registrada"
            : "actualizada" + " correctamente!"
        );
        this.getData();
        this.dialogEdit = false;
      } else {
        const data = await requSave.json();
        this.$toast.error(data.error);
      }

      this.loadingSave = false;
    },
  },
};
</script>
  