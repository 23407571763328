<template>
    <v-container fluid>
        <v-data-table :headers="headers" :items="proyectos" :search="search" sort-by="calories" class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Categorías Contabilidad</v-toolbar-title>

                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field class="mt-2" v-model="search" label="Buscar"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" width="50%" height="50%" persistent hide-overlay
                        transition="dialog-bottom-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" outlined dark class="mb-2" v-bind="attrs" v-on="on">
                                <v-icon>add</v-icon>
                                Nuevo categoría
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="text-h5">
                                {{ formTitle }}
                            </v-card-title>
                            <v-card-text>


                                <v-container fluid>
                                    <v-row justify="center">
                                        <v-col cols="12" md="10">
                                            <v-row class="mt-5" no-gutters dense justify="center">
                                                <v-col cols="12" md="8">
                                                    <v-text-field v-model="editedItem.name" label="Nombre" outlined />
                                                </v-col>
                                            </v-row>

                                        </v-col>

                                    </v-row>
                                    <v-row justify="center" class="mt-2 ">
                                        <v-btn @click="dialog = false, editedItem.name = '', editedItem.id = null"
                                            plain>Cancelar</v-btn>
                                        <v-btn @click="save" color="primary" outlined>Guardar</v-btn>
                                    </v-row>
                                </v-container>

                            </v-card-text>
                        </v-card>

                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="600px">
                        <v-card>
                            <v-card-title class="text-h5">Esta seguro que deseas eliminar este proyecto?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">

                        <v-icon color="primary" class="mr-2" @click="addSubcategories(item)"> add </v-icon>
                    </template>
                    <span>Agregar sub-categoria</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">

                        <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
                    </template>
                    <span>Eliminar </span>
                </v-tooltip>


            </template>
        </v-data-table>
    </v-container>
</template>
<script>
import { throws } from "assert";
import moment from "moment";
export default {
    name: "my-perfil",
    data() {
        return {
            dialogDelete: false,
            search: "",
            isSub: false,
            headers: [
                {
                    text: "Codigo",
                    align: "start",
                    sortable: false,
                    value: "id",
                },
                { text: "Nombre", value: "name" },


                { text: "Actions", value: "actions", sortable: false },
            ],
            proyectos: [],
            editedIndex: -1,
            editedItem: { id: null, name: "", parent_id: null },
            defaultItem: {},

            dialog: false,

        };
    },
    created() {
        this.init();
    },

    computed: {
        formTitle() {
            if (this.isSub) {
                return "Agregar sub categoria"
            }
            return this.editedIndex === -1 ? "Nuevo proyecto" : "Editar proyecto";
        },

    },

    methods: {
        async init() {
            const response = await this.api_Get("accounting/categories_diary_book");

            this.proyectos = await response.json();


        },
        async editItem(item) {
            this.isSub = false
            this.editedItem = { id: null, name: "" }
            this.editedIndex = this.proyectos.indexOf(item);

            this.editedItem = Object.assign({}, item);

            this.dialog = true;
        },
        async addSubcategories(item) {
            this.isSub = true
            this.editedItem = { parent_id: item.id, id: null, name: "" }
            //  this.editedIndex = this.proyectos.indexOf(item);

            //this.editedItem = Object.assign({}, item);

            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.proyectos.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            try {
                const request = await this.api_Delete(
                    "accounting/projects/" + this.editedItem.id
                );
                if (request.status == 200) {
                    this.$toast.success("Categoria eliminado!");
                    this.init();
                }
            } catch (error) {
                console.log(error)
            }
            this.closeDelete();
        },
        close() {
            this.isSub = false
            this.dialog = false;
            this.editedItem = Object.assign({}, this.defaultItem);
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                //this.image_storage = null;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        async save() {
            if (this.editedItem.name == "") {
                this.$toast.info("Debes elegir un nombre");
                return;
            }

            try {



                let data = {
                    id: this.editedItem.id,
                    name: this.editedItem.name,
                    parent_id: this.editedItem.parent_id

                };
                if (this.isSub) {
                    const response = await this.api_Post("accounting/projects", data);
                    if (response.status == 200) {
                        this.$toast.success("Categoria creado con exito");
                        this.init();
                    } else {
                        this.$toast.error("error ");
                    }

                }
                else {
                    if (this.editedItem.id === null) {
                        const response = await this.api_Post("accounting/projects", data);
                        if (response.status == 200) {
                            this.$toast.success("Categoria creado con exito");
                            this.init();
                        } else {
                            this.$toast.error("error ");
                        }
                    }
                    else {
                        const response = await this.api_Put("accounting/projects", data);
                        if (response.status == 200) {
                            this.$toast.success("Categoria actualizada con exito");
                            this.init();
                        } else {
                            this.$toast.error("error ");
                        }
                    }
                }


            } catch (e) {
                this.$toast.error("error " + e.message);
            }

            this.close();
        },

        getDate(date) {
            let newDate = moment(date).format("YYYY-MM-DD HH:MM:SS A").toString();
            return newDate;
        },


    },
};
</script>
  