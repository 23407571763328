<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon @click="updateDetail(item)" color="primary" class="mx-1 mt-n2" v-bind="attrs" v-on="on">
          edit
        </v-icon>
      </template>
 
      <span>Ajuste contabilidad</span>
    </v-tooltip>
    <v-dialog width="92%" persistent v-model="dialogCb">
      
      <v-card v-if="editedIndex >= 0">

        <v-card-title>Ajuste -
          <span class="ml-1 primary--text">
            ( {{ items[editedIndex].item_name }} ) ${{
              currencyFormat(
                type === 3 || type === 4
                  ? (parseFloat(items[editedIndex].amount) *
                    parseFloat(items[editedIndex].quantity_return) +
                    items[editedIndex].itbis.value *
                    parseFloat(items[editedIndex].amount) *
                    parseFloat(items[editedIndex].quantity_return)) *
                  currency_rate_selected
                  : (parseFloat(items[editedIndex].amount) *
                    parseFloat(items[editedIndex].quantity) +
                    items[editedIndex].itbis.value *
                    parseFloat(items[editedIndex].amount) *
                    parseFloat(items[editedIndex].quantity)) *
                  currency_rate_selected
              )
            }}
          </span>
          <v-spacer></v-spacer><v-icon @click="close">close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field outlined label="Referencia" v-model="items[editedIndex].reference"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined label="Nota" v-model="items[editedIndex].note"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n10 ml-2">
            <v-checkbox v-model="items[editedIndex].accountable" :label="`Editar contabilidad`"></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn class="mt-2" v-if="items[editedIndex].accountable === true" @click="addLine()" outlined
              color="primary">
              <v-icon>add</v-icon> Agregar linea
            </v-btn>
          </v-row>

          <v-row v-if="items[editedIndex].accountable === true" class="ml-2 mt-3">
            <v-col cols="12">
         
              <span class="ml-4 text-h6" :style="totCont.totalAmount == items[editedIndex].amount
                  ? 'color:green;'
                  : 'color:red'
                ">Consumido {{ currencyFormat(totCont.totalAmount) }}</span>
          
              <v-data-table :headers="headers_account" :items="items[editedIndex].accounts_edited"
                class="elevation-0 pr-6" hide-default-footer>
                <template v-slot:[`item.cuentaContable`]="{ item }">
                 
                  <v-autocomplete dense item-value="id" item-text="name" v-model="item.cuentaContable" return-object
                    :items="accounts" @change="
                      item.dgii_category_id =
                      item.cuentaContable.dgii_category_id
                      " color="cyanText" label="Cuenta contable" outlined required></v-autocomplete>
                </template>
                <template v-slot:[`item.categoryDgii`]="{ item }">
                  <v-autocomplete dense v-model="item.dgii_category_id" :items="categoryDgii" color="cyanText"
                    label="Categoria Dgii" outlined required></v-autocomplete>
                </template>
                <template v-slot:[`item.descripcion`]="{ item }">
                  <v-textarea dense rows="2" row-height="10" v-model="item.note" label="Descripción"
                    outlined></v-textarea>
                </template>

                <template v-slot:[`item.department_id`]="{ item }">
                  <v-autocomplete dense outlined v-model="item.department_id" :items="itemsDepartment" color="cyanText"
                    label="Departamento" prepend-inner-icon="business" required></v-autocomplete>
                </template>
                <template v-slot:[`item.diary_book_category_id`]="{ item }">
                  <v-autocomplete dense outlined v-model="item.diary_book_category_id" :items="categories.filter((element) => element.parent_id === null)
                    " color="cyanText" label="Categoría" item-text="name" item-value="id" prepend-inner-icon="business"
                    required></v-autocomplete>
                </template>
                <template v-slot:[`item.diary_book_sub_category_id`]="{ item }">
                  <v-autocomplete dense outlined v-model="item.diary_book_sub_category_id" :items="categories.filter(
                    (element) =>
                      element.parent_id === item.diary_book_category_id
                  )
                    " color="cyanText" label="Sub-Categoría" item-text="name" item-value="id"
                    prepend-inner-icon="business" required></v-autocomplete>
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  
                  <v-text-field dense v-model="item.amount" label="Monto" type="number" min="0" outlined></v-text-field>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="deleteItem(item)" class="mt-n5" color="black">close</v-icon>
                </template>
                <template v-slot:footer>
                  <v-row class="mt-n6" justify="center">
                    <v-col cols="12" md="6">
                      <v-row justify="center">
      
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-row justify="end">
                        <v-card style="
                            margin-right: 150px;
                            margin-top: 30px;
                            margin-bottom: 30px;
                          " elevation="0" width="350px" height="100px" outlined>
                          <v-layout fill-height align-center>
                            <v-col cols="12" md="6" align="center">
                              <v-row justify="center">
                                <span> TOTAL </span>
                              </v-row>
                              <v-row justify="center">
                                <span v-if="items[editedIndex].accounts_edited.length >
                                  0
                                  ">
                                  $
                                  {{
                                    currencyFormat(
                                      totCont.totalAmount *
                                      currency_rate_selected
                                    )
                                  }}
                                </span>
                              </v-row>
                            </v-col>
                            <v-col cols="12" md="6" align="center">
                            </v-col>
                          </v-layout>
                        </v-card>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close" color="red">Cancelar</v-btn>
          <v-btn color="primary" @click="save">Guardar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";

export default {
  components: {},
  data: () => ({
    date: moment().format("YYYY-MM-DD"),

    headers_account: [
      //{ text: "Entidad", value: "entity", width: "12%" },
      { text: "Cuenta contable", value: "cuentaContable", width: "16%" },
      { text: "Categoría dgii", value: "categoryDgii", width: "12%" },
      { text: "Descripción", value: "descripcion" },
      { text: "Departamento", value: "department_id", width: "12%" },
      { text: "Categoría", value: "diary_book_category_id", width: "12%" },
      {
        text: "Sub Categoría",
        value: "diary_book_sub_category_id",
        width: "12%",
      },
      { text: "Monto", value: "amount", width: "15%" },
      { text: "Actions", value: "actions", width: "5%" },
    ],
    itemsDepartment: [],
    editedIndex: -1,
    categoryDgii: [],
    dialogCb: false,
    accounts: [],
    categories: [],
  }),
  created() {
    this.getData();
  },

  props: {
    items: {
      type: Array,
    },
    item: {
      type: Object,
    },
    currency_rate_selected: {
      type: String,
    },
    type: {
      type: Number,
    },
  },
  computed: {
    headers() {
      let data = [
        //{ text: "Otros", value: "cb", width:"6%", align: "start" },
        { text: "ID", value: "id", width: "3%", align: "start" },
        {
          text: "Producto/servicio",
          align: "start",
          sortable: false,
          width: "20%",
          value: "item_name",
        },
        //{ text: "Referencia", value: "reference",    align: "start" },
        { text: "Unidad", value: "unit", align: "start" },

        // { text: "	Desc %", value: "discount", width: "10%", align: "start" },
        //{ text: "Cuenta", value: "account",  align: "start" },
        { text: "Impuesto", value: "itbis", width: "10%", align: "start" },
        { text: "Cantidad", value: "quantity", width: "10%", align: "start" },
        { text: "Precio", value: "amount", width: "15%", align: "start" },
        {
          text: "Cantidad a devolver",
          value: "quantity_return",
          align: " d-none",
        },
        { text: "Total", value: "total", align: "start" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,

          align: "start",
        },
      ];
      if (this.type === 3 || this.type === 4) {
        data[8].align = "start";
        data[8].active = true;
      }
      return data;
    },

    totCont() {
      let totalAmount = 0;

      if (
        this.items[this.editedIndex].accountable === true &&
        this.items[this.editedIndex].accounts_edited.length > 0
      ) {
        this.items[this.editedIndex].accounts_edited.map(function (objeto) {
          let amount = parseFloat(objeto.amount || 0);

          totalAmount += amount; // Acumular el valor de débito

          return { amount };
        });
      }
      return { totalAmount };
    },
  },
  methods: {
    updateDetail(item) {
      let editIndex = this.items.indexOf(item);

      this.editedIndex = editIndex;

      this.dialogCb = true;
    },

    async getData() {
      let resultTypeCategories = await this.api_Get(
        "accounting/categories_diary_book"
      );
      this.categories = await resultTypeCategories.json();
      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      this.accounts = await resultTypeAccounts.json();
      let resultCategoryDgii = await this.api_Get("accounting/category_dgii");
      const dataCategoryDgii = await resultCategoryDgii.json();
      for (let item of dataCategoryDgii) {
        this.categoryDgii.push({ text: item.name, value: item.id });
      }
      this.itemsDepartment = await this.g_departments();
    },
    close() {
      if (this.items[this.editedIndex].accountable) {
        if (
          this.items[this.editedIndex].amount !=
          this.totCont.totalAmount * this.currency_rate_selected
        ) 
        {
          // this.$toast.warning(
          //   "Tienes la contabilidad habilitada El monto de la contabilidad debe ser igual al del item!"
          // );
          // return;
        }
      }
      if (!this.items[this.editedIndex].accountable) {
      //  this.items[this.editedIndex].accounts_edited = [];
      }

      this.dialogCb = false;
    },
    save() {
      // if (
      //   this.items[this.editedIndex].amount !=
      //   this.totCont.totalAmount * this.currency_rate_selected
      // ) {
      //   this.$toast.warning(
      //     "El monto de la contabilidad debe ser igual al del item!"
      //   );
      //   return;
      // }
      this.dialogCb = false;
    },
    addLine() {
      if (this.items[this.editedIndex].accounts_edited === undefined) {
        this.items[this.editedIndex].accounts_edited = [];
      }
      this.items[this.editedIndex].accounts_edited.push({
        is_new:true,
        idSuplidor: "",
        idCliente: "",
        entry_type_id: this.items[this.editedIndex].accounts_edited[0].entry_type_id ,
        cuentaContable: "",
        categoryDgii: "",
        descripcion: "",
        debito: "",
        credito: "",
      });
    },
    updateDetail(item) {
      let editIndex = this.items.indexOf(item);

      this.editedIndex = editIndex;
      // this.editedItem = Object.assign({}, item);
      // this.items.splice(editIndex, 1);
      this.dialogCb = true;
    },
  },
};
</script>
