<template>
    <v-container>

        <v-row justify="center">
            <v-col cols="12">
                <v-row justify="center" class="mt-4 mx-1 mb-4">
                    <v-icon class="mx-2" size="35px">receipt_long</v-icon>

                    <span style="font-size: 34px; color: #616161">Notas de debito</span>
                </v-row>
                <v-row justify="center" class="mb-1">
                    <v-col cols="12" md="10">
                        <v-row justify="center">
                            <search-header @dataFilters="changeBussines($event)" :type="false" />
                        </v-row>
                    </v-col>


                </v-row>
                <v-card class="rounded-lg">
                    <v-expand-transition>
                        <v-row v-if="loading" justify="center">
                            <v-col cols="12" md="10">
                                <v-skeleton-loader
                                    type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions">
                                </v-skeleton-loader>

                            </v-col>
                        </v-row>
                        <v-data-table v-else :search="search" :headers="headers" :items="invoices" class="elevation-0">
                            <template v-slot:top>
                                <v-toolbar class="elevation-0 rounded-lg">
                                    <v-toolbar-title>Notas de debito</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-spacer></v-spacer>
                                    <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
                                    <v-spacer></v-spacer>
                                    <v-dialog transition="slide-x-transition" v-model="dialog" fullscreen>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                <v-icon small>add</v-icon>
                                                Nueva nota debito
                                            </v-btn>
                                        </template>
                                        <v-card style="background-color: #f4f5fa">
                                            <v-toolbar flat dark color="transparent">
                                                <v-btn icon dark @click="close">
                                                    <v-icon color="black">mdi-close</v-icon>
                                                </v-btn>
                                                <v-toolbar-title> </v-toolbar-title>
                                                <v-spacer></v-spacer>
                                            </v-toolbar>
                                            <v-container>
                                                <v-row justify="center">
                                                    <v-col cols="12" md="11">
                                                        <invoice :type="4" @close-dialog="dialog = false"
                                                            @save-invoice="save($event)" />
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            <v-card-text>
                                                <v-row> </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-dialog>
                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <v-card>
                                            <v-card-title class="text-h5">Are you sure you want to delete this
                                                item?</v-card-title>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.invoice_number`]="{ item }">
                                <v-hover v-slot="{ hover }">
                                    <span @click="showInvoice(item)"
                                        class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                                            color: hover ? 'red' : 'black',
                                            cursor: hover ? 'pointer' : '',
                                            'text-decoration': hover ? 'underline' : '',
                                        }">
                                        {{ item.invoice_number }}</span>
                                </v-hover>
                            </template>
                            <template v-slot:[`item.total_amount`]="{ item }">
                                <span>{{ item.currency_code }}
                                    {{ currencyFormat(item.total_amount) }}</span>
                            </template>
                            <template v-slot:[`item.entity_name`]="{ item }">
                                <v-hover v-slot="{ hover }">
                                    <span @click="viewEntity(item.entity_id)" style="font-size: 14px"
                                        class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                                            color: hover ? 'red' : 'black',
                                            cursor: hover ? 'pointer' : '',
                                            'text-decoration': hover ? 'underline' : '',
                                        }">{{ item.entity_name }}</span></v-hover>
                            </template>
                            <template v-slot:[`item.status_id`]="{ item }">
                                <v-chip small outlined color="success" v-if="item.status_id == 1">
                                    Activa
                                </v-chip>
                                <v-chip small outlined color="red" v-else>
                                    Anulada
                                </v-chip>
                            </template>

                            <!-- <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template> -->
                            <template v-slot:no-data>
                                <v-row justify="center" class="pt-10">
                                    <v-icon size="80px">token</v-icon>
                                </v-row>
                                <v-row justify="center" class="pb-6">
                                    No hay datos
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-expand-transition>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import invoice from "@/components/Income/Invoice.vue";

//mport Filters from "@/components/FilterBussinesLocations";
export default {
    components: { invoice },
    data: () => ({
        search: "",
        loading: false,
        dialog: false,
        dialogDelete: false,
        suplidor: false,
        cliente: false,
        disabledName: false,
        plazoPagoSuplidor: null,
        plazoPagoCliente: null,
        documents: [],
        rncValid: false,
        ncfTypes: [],
        accounts: [],
        provincies: [],
        invoices: [],
        cxc: "",
        cxp: "",
        selectedDocument: "",
        selectedtypesNcfCliente: "",
        selectedtypesNcfSuplidor: "",
        selectedProvincies: "",
        headers: [
            {
                text: "Factura",
                align: "start",

                value: "invoice_number",
            },
            { text: "Unidad negocio", value: "business_unit_description" },
            { text: "Location", value: "location_description" },
            { text: "Cliente", value: "entity_name" },
            { text: "Total", value: "total_amount", align: "end" },
            { text: "Status", value: "status_id" },

            //{ text: "Actions", value: "actions", sortable: false },
        ],

        editedIndex: -1,
        editedItem: [],
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "Nuevo contacto" : "Editar contacto";
        },
    },

    watch: {},

    created() {
        this.initialize();
    },

    methods: {
        async getData(data) {

            this.loading = true
            let filters = ""
            filters += data.business == null ? " " : " AND  BUSINESS_UNIT_ID in (" + data.business + ") "
            filters += data.business == null ? " " : " AND  BUSINESS_UNIT_ID in (" + data.business + ") "
            filters += data.location == null || data.location == 0 ? "" : " AND LOCATION_ID IN (" + data.location + ")"
            filters += data.department_id == null || data.department_id == 0 ? "" : " AND DEPARTMENT_ID =" + data.department_id + ""
            filters +=
                " AND EMISSION_DATE::DATE BETWEEN '" +
                data.dateStart +
                "' and '" +
                data.dateEnd +
                "' ";
            let resultInvoices = await this.api_Post("shopping/invoice_debit_notes", { filters: filters });

            this.invoices = await resultInvoices.json();
            this.loading = false
            //   console.log(this.invoices);
        },
        async initialize() {

            //   this.getData({busines:null,location:this.$store.state.auth.user.acces_finance[0].locations});

        },
        changeBussines(data) {

            if (data.business == 0) {
                this.getData({ business: this.$store.state.auth.user.acess_business_unit_finance, location: null, dateStart: data.dateStart, dateEnd: data.dateEnd });
            }
            else {
                this.getData(data);
            }
            //   this.getData(data.data)
            // if(data.data.busines)
        },
        editItem(item) {
            this.editedIndex = this.entitys.indexOf(item);
            this.editedItem = Object.assign({}, item);

            this.dialog = true;
        },

        deleteItem(item) {
            this.editedIndex = this.entitys.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm() {
            this.entitys.splice(this.editedIndex, 1);
            this.closeDelete();
        },

        close() {
            this.dialog = false;

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        async save(item) {

            try {
                const responseRequest = await this.api_Post(
                    "income/invoice_register",
                    item
                );

                if (responseRequest.status == 200) {
                    //this.getData();

                    let data = await responseRequest.json();

                    this.$router.push({
                        name: "ViewInvoice",
                        params: { id: btoa(data[0].invoice_number) },
                    });

                    // this.$toast.success("agregado con exito!");
                    this.dialog = false;
                }
                else {
                    let data = await responseRequest.json()
                    this.$toast.error(data.error);
                    //  console.log(data.error.error)
                }
            } catch (error) {
                console.log(error)
                this.$toast.error("Error " + error);
            }

        },
    },
};
</script>
  
<style></style>
  