<template>
    <v-row>
            
        <v-col cols="12" md="8" class="mt-2 mb-2">
           
        <BarChart :title_header="'Gastos / Compras'" :data="compras" />
      </v-col>
      <v-col  cols="12" md="4" class="mt-2 mb-2">
        <PieChart :title_header="'Compras '" :data="compra_location" />
      </v-col>
    </v-row>
</template>
  
<script>
import PieChart from "@/components/Charts/Pie.vue";
import BarChart from "@/components/Charts/Bar.vue";
export default {
    components: {
      PieChart,
    BarChart,
  },
  props: {
    compras: {
      type: Object,
    },
    compra_location: {
      type: Object,
    },
  },
   

}
</script>
  
<style></style>