<template>
  <v-app>
    <v-container fluid>
      <v-row justify="center" class="mt-7">

        <img class="pb-0" data-aos="zoom-out-up" data-aos-duration="1300" src="@/assets/logo.jpeg" contain />
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="4" sm="6">
          <v-form>
            <v-container fluid> 
              <v-row class="flex-column">
                <v-form>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" md="8">
                      <v-text-field name="username" ref="username" @keyup.enter="$refs.password.focus()"
                        v-model="user.username" :rules="emailRules" prepend-icon="person" label="Usuario"
                        required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" md="8">
                      <v-text-field name="password" ref="password" v-model="user.password"
                        :type="showPassword ? 'text' : 'password'" prepend-icon="key" :append-icon="showPassword ? 'visibility' : 'visibility_off'
                          " @click:append="showPassword = !showPassword" label="Contraseña" :rules="passRules"
                        hint="At least 6 characters" @keyup.enter="handleSubmit" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-alert v-if="hasError" color="red" outlined type="error">Usuario o password incorrectos</v-alert>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-center">
                      <v-btn :loading="loading" class="text-capitalize" large color="primary" @click="login">
                        Acceder <v-icon>login</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import LogoSVG from "@/assets/grupompng.svg";

export default {
  name: "Login",
  components: {
    Loading: LogoSVG,
  },

  data: () => ({
    hasError: false,
    visible: false,
    loading: false,
    capsTooltip: false,
    options: {
      minimizable: false,
      playerSize: "standard",
      backgroundColor: "#fff",
      backgroundStyle: "color",
      theme: {
        controlsView: "standard",
        active: "light",
        light: {
          color: "#3D4852",
          backgroundColor: "#fff",
          opacity: "0.7",
        },
        dark: {
          color: "#fff",
          backgroundColor: "#202020",
          opacity: "0.7",
        },
      },
    },
    showPassword: false,
    alert: false,
    emailRules: [
      (v) => !!v || "Usuario es requerido",
      (v) => v.length >= 3 || "Complete el campo",
    ],

    passRules: [
      (v) => !!v || "Password es requerido",
      (v) => v.length >= 4 || "Minimo 4 caracteres",
    ],
    user: {
      username: "",
      password: "",
      localhost: "",
      router: null,
      toast: null,
    },
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.authStatus;
    },
  },
  mounted() {
    this.$refs.username.focus();
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    checkCapslock(e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },

    handleSubmit(e) {
      e.preventDefault();
      this.login();
    },
    toggleLocation() {
      this.position = "top-left";
      this.$toasted.options.position = this.position;
      this.$toasted.show(null);
    },
    addErrorNotification(text) {
      this.$toast.error(text, {
        position: "top-right",
        timeout: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    async login() {
      this.loading = true;

      const data = await this.$store.dispatch("auth/login", this.user);

      this.loading = false;
      if (data === true) {
        location.reload();
        //setTimeout(() => {
        this.$router.push({ name: "Home" });
        //}, 150);
      } else {
        this.hasError = true;
        setTimeout(() => {
          this.hasError = false;
        }, 15000);
      }
    },
  },
};
</script>

<style scoped></style>
