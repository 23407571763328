<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="10">
                <v-row justify="start" class="mt-1 mb-3">
                    <v-btn small @click="beforeRoute" color="primary" outlined><v-icon>keyboard_backspace</v-icon>
                        Volver</v-btn>
                </v-row>
                <v-row v-if="loading == false">
                    <iframe style="min-height: 90vh; width: 100%; position: relative" :src="storage_invoice"
                        frameborder="0">
                    </iframe>
                </v-row>
                <v-row v-else>
                    <v-skeleton-loader
                        type="list-item-avatar, divider, list-item-three-line, card-heading ard-heading, image,image, actions"
                        height="100vh"></v-skeleton-loader>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        loading: false,
        storage_invoice: "",
    }),
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            this.loading = true;
            // let quotation_id = this.$route.params.id;
            
                                   // this.storage_invoice=this.$route.params.id;
            this.storage_invoice = await this.get_storage(
                "quotes",
                 this.$route.params.id
            );
            // const responseUrl = await this.api_Post("storage/get_files", {
            //   bucket: "invoices",
            //   id_file: data_invoice[0].storage_id,
            // });
            // if (responseUrl.status == 200) {
            //   let invoice = await responseUrl.json();
            //   this.storage_invoice = invoice.url;
            // }
            this.loading = false;
        },
    },
};
</script>