import moment from "moment";
import { jsPDF } from "jspdf";
class ReportFunctions {

    fileDepositPopular(data) {
        let sequence = '1';
        let company_rnc = data[0].rnc == undefined ? '102318654' : data[0].rnc;
        let total = parseFloat(parseFloat(data[0].payment_transaction_amount).toFixed(2));
        let transaction_check_number = 1, invoice_quantity = data[0].payment_transaction_details.length;

        let txt = "H"
        txt += filRight(company_rnc.toString(), 15, ' ');
        txt += filRight(data[0].business_unit_description == undefined ? 'LM INDUSTRIES, S.A.' : data[0].business_unit_description, 35, ' ');
        txt += filLeft(sequence.toString(), 7, '0');
        txt += data[0].deposit_type == undefined ? "02" : filLeft(data[0].deposit_type.toString(), 2, '0');
        txt += moment(data[0].payment_date).format('YYYYMMDD');
        txt += filRight('0', 24, '0');
        txt += filLeft(invoice_quantity.toString(), 11, "0");
        txt += filLeft(total.toFixed(2).toString().replace('.', ''), 13, "0");
        txt += filRight('0', 15, '0');
        txt += moment().format('YYYYMMDDHHMM')
        // txt+=filRight(data[0].data[0].business_unit_id===117?'jennym@brandm.com.do':'fveras@grupom.com.do',40,' ');
        txt += filRight(data[0].business_unit_id === 117 ? 'fveras@grupom.com.do' : 'fveras@grupom.com.do', 40, ' ');
        txt += filRight(' ', 137, " ") + "\n";

        for (var transaction of data[0].payment_transaction_details) {
            let email = transaction.entity_contact_json.find((element) => element.id_type === 5);
            txt += "N" + filRight(company_rnc.toString(), 15, ' ')//condicionarlo
                + filLeft(sequence.toString(), 7, '0')
                + filLeft(transaction_check_number.toString(), 7, '0')
                + filRight(transaction.bank_account_description_short, 20, ' ')
                + transaction.account_type_id//revisar
                + "214"//codigo de la moneda
                + "10101070"//banco popular-falta poner dinamico
                + "8"//banco popular tambien-falta poner dinamico
                + (transaction.account_type_id == "1" ? "32" : "22")//-falta poner dinamico
                + filLeft(currencyFormat(transaction.payment_amount).replaceAll(',', '').replace('.', ''), 13, '0')
                + "RN"//CEDULA-condicionar
                + filRight(transaction.entity_document_id, 15, ' ')//condicionar
                + filRight(deleteDiacriticos(transaction.entity_name.slice(0, 35)), 35, ' ')
                + filRight(' ', 56, ' ')//12 espacios numero de referencia, luego 40 de concepto de pago, 4 fecha de vencimiento, 1 forma de contacto,40 para el email,
                + (email == undefined ? ' ' : '1') + filRight(email == undefined ? ' ' : email, 40, ' ')
                + filRight('0', 14, '0')//12 ceros del numero de telefono, 2 ceros uso futuro
                + filRight(' ', 79, ' ') + "\n";//numero que no sabemo 15, y 3 mas de un codigo de retorno, 3 de remoto, 3 razon interno, 1 de procesador de transaccion, 2 respuesta de validacion, 50 de nada
                for (let inv_detail of transaction.payment_transaction_invoice_details) {
                    txt += "R" + filRight(company_rnc.toString(), 15, ' ');
                    txt += filLeft(sequence.toString(), 7, '0');
                    txt += filLeft(transaction_check_number.toString(), 7, '0');
                    txt += filRight(transaction.bank_account_description_short, 20, ' ');
                    txt += filLeft(inv_detail.invoice_number.slice(0, 15),15,' ');
                    txt += '00'+moment(inv_detail.emission_date).format('YYYYMMDD');
                    txt += filRight('0', 13, '0');
                    txt += filRight('0', 11, '0');
                    txt += filRight('0', 11, '0');
                    txt += filLeft(currencyFormat(inv_detail.payment_amount).replaceAll(',', '').replace('.', ''), 13, '0');
                    txt += filRight(('Fact.: '+deleteDiacriticos(inv_detail.supplier_invoice_number)).slice(0, 50),50,' ');
                    txt += filLeft(' ',147,' ');
                    txt += "\n";
                }
                transaction_check_number++;
        }
        return txt;

    }
}


function deleteDiacriticos(texto) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}
function filRight(str, length, add) {//rellena caracteres a la derecha
    while (str.length < length) {
        str += add;
    } return str;
}
function filLeft(str, length, add) {//rellena caracteres a la izauierda
    while (str.length < length) {
        str = add + str;
    }
    return str;
}

function currencyFormat(number) {
    if (number == null || number == undefined) {
        return '0.00'
    }
    number = parseFloat(number);
    const formated = number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        currencySign: 'accounting',
        minimumFractionDigits: 2,
    });
    return formated.replace('$', '');
}
function filCenter(str, max) {//centra
    let size = str.length;
    if (size > max) { return str.slice(0, max); }
    function addSpacer(cant) {
        let spacers = "";
        for (let x = 0; x < cant; x++) {
            spacers += " ";
        }
        return spacers;
    }
    return addSpacer((max - size) / 2) + str + addSpacer((max - size) / 2);
}
export default new ReportFunctions();