import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=0d744d9e&scoped=true&"
var script = {}
import style0 from "./ErrorPage.vue?vue&type=style&index=0&id=0d744d9e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d744d9e",
  null
  
)

export default component.exports