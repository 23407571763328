<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="start">
          <span class="mx-3 mt-2" style="font-size: 34px; color: #616161">Cotizaciones</span>
        </v-row>
        <v-row justify="start" class="mt-n1 mx-1">
          <span style="font-size: 16px; color: #757575">Revisa y edita cotizaciones
          </span>
        </v-row>

        <v-data-table :headers="headers" :items="data" :search="search" class="elevation-1 mt-4 rounded-lg">
          <template v-slot:top>
            <v-toolbar class="rounded-lg" flat>
              <v-toolbar-title>Cotizaciones</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer> </v-spacer>
              <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" single-line
                hide-details>
              </v-text-field>

              <v-spacer></v-spacer>
              <!-- <v-dialog v-model="dialog" max-width="700px">
                <v-card height="auto">
                  <v-row no-gutters justify="center">
                    <v-card-title class="text-center">
                      <span class="text-h5 text-center"
                        >{{ formTitle }} {{ newItemName }}</span
                      >
                    </v-card-title>
                  </v-row>

                  <v-card-text>
                    <v-row justify="center">
                      <v-col cols="12" md="12">
                        <v-container>
                          <v-row justify="start">
                            <v-col cols="12" md="12">
                              <v-row justify="center" no-gutters>
                                <v-col cols="10">
                                  <v-row>
                                    <v-col cols="12" md="6">
                                      <v-autocomplete
                                        dense
                                        outlined
                                        :disabled="disable"
                                        v-model="editedItem.business_unit_id"
                                        :items="itemsBussines"
                                        label="Unidad de negocio"
                                        color="cyanText"
                                        @change="
                                          itemsLocations2 = updateCombo(
                                            itemsLocations,
                                            itemsLocations2,
                                            editedItem.business_unit_id,
                                            'location'
                                          )
                                        "
                                        prepend-inner-icon="corporate_fare"
                                        required
                                      ></v-autocomplete
                                    ></v-col>
                                    <v-col cols="12" md="6">
                                      <v-autocomplete
                                        dense
                                        :disabled="disable"
                                        outlined
                                        v-model="editedItem.location_id"
                                        :items="itemsLocations2"
                                        color="cyanText"
                                        label="Location"
                                        prepend-inner-icon="my_location"
                                        required
                                      ></v-autocomplete
                                    ></v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                    <v-text-field
                                      v-model="editedItem.name"
                                      label="Nombre"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-row>
                                  <v-row no-gutters>
                                    <v-textarea
                                      v-model="editedItem.note"
                                      label="Descripcion"
                                      outlined
                                      dense
                                    ></v-textarea>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions class="m">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn outlined color="blue darken-1" @click="save">
                      Guardar
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="600px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Estas seguro que desea eliminar esta
                    categoria?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
            </v-toolbar>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span> {{ getDate(item.created_at) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="ViewGroup(item)">
                  visibility
                </v-icon>
              </template>
              <span>Ver</span>
            </v-tooltip>
          </template>

          <template v-slot:no-data>
            <v-row justify="center" class="pt-10">
              <v-icon size="80px">token</v-icon>
            </v-row>
            <v-row justify="center" class="pb-6">
              No hay datos
            </v-row>
          </template>
        </v-data-table>
      </v-col>
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="primary">
            <!-- <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn> -->
            <v-toolbar-title>Cotizacion {{ editedItem.id }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="mt-4">
            <QuotesGroupsVue :quote="editedItem.id" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import QuotesGroupsVue from "@/components/Shop/QuotesGroups.vue";
export default {
  data: () => ({
    dialog: false,
    editedIndex: -1,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },

      { text: "Unidad de negocio", value: "bussines_unit_description" },
      { text: "Fecha", value: "created_at" },
      { text: "Usuario", value: "user_fullname" },
      { text: "Actions", value: "actions" },
    ],
    editedItem: {},
    search: "",
    data: [],
  }),
  components: { QuotesGroupsVue },
  created() {
    this.getInitialData();
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      //  console.log(this.editedItem);
      this.dialog = true;
    },
    getDate(date) {
      let newDate = moment(date).format("DD-MM-YYYY HH:MM:SS A").toString();
      return newDate;
    },
    ViewGroup(item) {
      this.$router.push({ name: "ViewQuoteGroup", params: { id: item.id } });
    },
    async getInitialData() {
      const request = await this.api_Get("shopping/quotes");
      this.data = await request.json();

    },
  },
};
</script>

<style></style>
