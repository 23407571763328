var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-4 mx-1 mb-2",attrs:{"justify":"center"}},[_c('v-icon',{staticClass:"mx-2",attrs:{"size":"35px"}},[_vm._v("receipt_long")]),_c('span',{staticStyle:{"font-size":"34px","color":"#616161"}},[_vm._v("Recepcion de ordenes")])],1),_c('v-row',{staticClass:"mb-4",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('search-header',{staticClass:"pt-3",attrs:{"selected_week":5},on:{"dataFilters":function($event){return _vm.changeBussines($event)}}})],1)],1)],1),_c('v-card',{staticClass:"rounded-lg"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.orders},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"elevation-0 rounded-lg"},[_c('v-toolbar-title',[_vm._v("Ordenes")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-6",attrs:{"label":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}})],1)]},proxy:true},{key:`item.order_id`,fn:function({ item }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('span',{staticClass:"primary--text font-weight-normal text-truncate font-weight-semibold",style:({
                                    color: hover ? 'red' : 'black',
                                    cursor: hover ? 'pointer' : '',
                                    'text-decoration': hover ? 'underline' : '',
                                }),on:{"click":function($event){return _vm.showOrder(item.order_id, false, 2)}}},[_vm._v(" "+_vm._s(_vm.filLeft(item.order_id.toString(), 12, '0')))])]}}],null,true)})]}},{key:`item.entity_name`,fn:function({ item }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('span',{staticClass:"primary--text font-weight-normal text-truncate font-weight-semibold",staticStyle:{"font-size":"14px"},style:({
                                    color: hover ? 'red' : 'black',
                                    cursor: hover ? 'pointer' : '',
                                    'text-decoration': hover ? 'underline' : '',
                                }),on:{"click":function($event){return _vm.viewEntity(item.entity_id)}}},[_vm._v(_vm._s(item.entity_name))])]}}],null,true)})]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{staticClass:"white--text",attrs:{"outlined":"","small":"","color":_vm.time_line_orders(item.last_status_id).color}},[_vm._v(" "+_vm._s(item.last_status_name)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"pt-10",attrs:{"justify":"center"}},[_c('v-icon',{attrs:{"size":"80px"}},[_vm._v("token")])],1),_c('v-row',{staticClass:"pb-6",attrs:{"justify":"center"}},[_vm._v(" No hay datos ")])]},proxy:true}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }