<template>
  <v-flex v-if="data_transactions">
    <v-row justify="center" no-gutters>
      <v-col cols="12" md="10">
        <v-row no-gutters class="mt-3 mx-2" justify="start">
          <v-btn @click="generateFile()" :disabled="data_transactions[0].status_id != 2" small outlined
            class="mx-2 rounded text-capitalize" tile color="primary">
            <v-icon left>
              {{
                data_transactions[0].invoice_payment_type_id === 3
                ? "print"
                : "download"
              }}
            </v-icon>
            {{
              data_transactions[0].invoice_payment_type_id === 3
              ? "Imprimir cheque"
              : "Archivo de banco"
            }}
          </v-btn>
          <v-btn :disabled="data_transactions[0].status_id != 1" @click="dialogCancelTransaction = true" small outlined
            class="mx-2 rounded text-capitalize" tile color="primary">
            <v-icon left> cancel </v-icon>
            Anular transacion
          </v-btn>

          <v-chip text-color="white" small :color="getColor(data_transactions[0].status_id)">
            {{ data_transactions[0].status_name }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn v-if="data_transactions[0].status_id === 1" @click="AprroveOrReject(false)" small outlined
            class="mx-2 rounded text-capitalize" tile color="red dark-2">
            <v-icon left> thumb_down </v-icon>
            Rechazar
          </v-btn>
          <v-btn v-if="data_transactions[0].status_id === 1" @click="AprroveOrReject(true)" small outlined
            class="mx-2 rounded text-capitalize" tile color="success">
            <v-icon left> thumb_up </v-icon>
            Aprobar
          </v-btn>
        </v-row>

        <v-container v-if="data_transactions != null" fluid>
          <div class="folded_corner mt-5">
            <v-row justify="center">
              <span class="mt-12" style="font-size: 24px; color: #616161">{{
                data_transactions[0].business_unit_description }}
              </span>
            </v-row>
            <v-row justify="center">
              <span class="">ID TRANSFERENCIA:</span>
              <span class="mx-1 font-weight-bold">
                #{{
                  filLeft(
                    data_transactions[0].payment_transaction_id.toString(),
                    12,
                    "0"
                  )
                }}
              </span>
            </v-row>
            <v-row justify="center" class="mt-14">
              <v-col cols="12" md="1"> </v-col>
              <v-col cols="12" md="5">
                <span style="text-decoration: underline" class="mx-4 font-weight-bold">{{
                  data_transactions[0].bank_account_description_long
                }}:</span>
                <span>Cta.
                  {{
                    data_transactions[0].bank_account_description_short
                  }}</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-row justify="end">
                  <span class="mr-3 float-right">Fecha
                    <b>{{ getDate(data_transactions[0].payment_date) }}</b>
                  </span>
                </v-row>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="1"> </v-col>
              <v-col cols="12" md="5">
                <span class="">{{
                  data_transactions[0].document_id_type_name
                }}</span>
                <span class="mx-4 font-weight-bold">{{
                  data_transactions[0].entity_document_id
                }}</span>
              </v-col>
              <v-col cols="12" md="5"> </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>
            <v-row class="mt-n12" justify="center">
              <v-col cols="12" md="1"> </v-col>
              <v-col cols="12" md="5">
                <span class="ml-3 font-weight-bold">Tipo de pago:</span>
                <span class="mx-1">{{
                  data_transactions[0].payment_type_name
                }}</span>
              </v-col>
              <v-col cols="12" md="5"> </v-col>

              <v-col cols="12" md="1"> </v-col>
            </v-row>

            <v-row justify="center" class="mt-15">
              <v-col cols="12" md="11">
                <v-container class="">
                  <v-data-table :headers="headers" dense :items="data_transactions[0].payment_transaction_details"
                    class="elevation-0 rounded-lg" hide-default-footer>
                    <template v-slot:[`item.total_amount`]="{ item }">
                      <span>
                        {{ currencyFormat(item.payment_amount) }}
                        {{ item.currency_code }}</span>
                    </template>

                    <template v-slot:footer>
                      <v-row style="margin-top: 200px" class="mb-10" justify="start">
                        <v-col cols="12" md="8">
                          <v-divider inset></v-divider>
                          <v-row class="mt-2" justify="center">
                            <span class="font-weight-bold text-center">
                              Elaborado por
                              {{ data_transactions[0].payment_by_name }}
                            </span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-row justify="end">
                            <span style="font-size: 2.5vh; color: #58595f" class="font-weight-bold ml-2 mt-1">
                              Total
                              {{
                                currencyFormat(
                                  data_transactions[0]
                                    .payment_transaction_amount
                                )
                              }}
                            </span>
                          </v-row>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-container>
              </v-col>
            </v-row>
          </div>
          <div class="folded_corner mt-10">
            <v-row justify="center">
              <span class="mt-12" style="font-size: 24px; color: #616161">Facturas afectadas
              </span>
            </v-row>

            <v-row justify="center" class="mt-8">
              <v-col cols="12" md="12">
                <v-row class="mb-3" v-for="(transactions, i) of data_transactions[0]
                  .payment_transaction_details" :key="i" justify="center">
                  <v-col cols="12" md="12">
                    <v-row class="mt-1 mb-4" justify="center">
                      <span class="text-h6">
                        {{ transactions.entity_name }}</span>
                    </v-row>

                    <v-row v-for="(
                        item, n
                      ) of transactions.payment_transaction_invoice_details" :key="n" justify="center">
                      <v-col cols="12" md="6">
                        <v-row justify="end">
                          <span @click="showInvoice(item, true)"
                            class="primary--text font-weight-normal text-truncate font-weight-semibold" s>
                            {{ item.invoice_number }} -
                            {{ item.supplier_invoice_number }}</span>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row justify="start">
                          <span class="ml-12 font-weight-bold">
                            {{ currencyFormat(item.payment_amount) }}
                            {{ transactions.currency_code }}</span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogCancelTransaction" transition="dialog-top-transition" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>Anular transacion</v-toolbar>
        <v-card-text class="text-center">
          <div class="text-h5 pa-5 text-center">
            Seguro que deseas eliminar esta transacion?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="dialogCancelTransaction = false">Volver</v-btn>
          <v-btn outlined color="primary" @click="cancelTransaction">Anular transacion</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import moment from "moment";
import global_reports from "@/services/global_reports.js";
export default {
  data: () => ({
    dialogCancelTransaction: false,
    data_transactions: null,
    headers: [
      {
        text: "Secuencia",
        value: "payment_transaction_sequence",
        align: "start",
        sortable: false,
      },
      {
        text: "Suplidor",

        value: "entity_name",
      },

      // { text: "Referencia", value: "item_description" },
      { text: "Monto", value: "total_amount", align: "end" },
    ],
  }),
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;

      this.id_transactions = atob(this.$route.params.id);

      const responseRequest = await this.api_Post(
        "transactions/viewAllTransactions",
        {
          filters: "WHERE PAYMENT_TRANSACTION_ID=" + this.id_transactions,
        }
      );
      if (responseRequest.status == 200) {
        this.data_transactions = await responseRequest.json();

        //   this.data_transactions_entity=  this.groupByEntityId(this.data_transactions [0].payments_details)
      } else {
        this.$toast.error("error al cargar el pago!");
      }

      this.loading = false;
    },
    async AprroveOrReject(type) {
      let status = type === true ? 2 : 3;

      let data = {
        transaction_id: this.id_transactions,
        status_id: status,
      };

      const responseApprove = await this.api_Post(
        "transactions/updateStatusTransaction",
        data
      );
      if (responseApprove.status == 200) {
        this.getData();
        this.$toast.success("Actualizado!");
      } else {
        this.$toast.error("error al guardar!");
      }
    },
    async cancelTransaction() {
      this.loading = true;

      this.id_transactions = atob(this.$route.params.id);

      const responseRequest = await this.api_Post(
        "transactions/cancelTransaction",
        {
          id: this.id_transactions,
        }
      );
      if (responseRequest.status == 200) {
        this.getData();
        this.dialog = false;
        this.$toast.success("Transacion anulada!");
      } else {
        this.$toast.error("error al cargar el pago!");
      }

      this.loading = false;
    },
    getColor(color) {
      if (color === 1) {
        return "orange";
      }
      if (color === 2) {
        return "green";
      }
      if (color === 3) {
        return "red ";
      }
      if (color === 4) {
        return "red";
      }

      return "grey";
    },
    groupByEntityId(array) {
      const grouped = {};
      array.forEach((item) => {
        const entityId = item.entity_id;
        if (!grouped[entityId]) {
          grouped[entityId] = [];
        }
        grouped[entityId].push(item);
      });
      return grouped;
    },

    getDate(item) {
      return moment(item).format("DD/MM/YYYY");
    },
    generateFile() {
      let txt = global_reports.fileDepositPopular(this.data_transactions);
      let depositSequence = this.filLeft("1", 7, "0"),
        loc_desc_short = "LOCAT";
      let transfer_id = "1",
        date =
          this.filLeft((moment().month() + 1).toString(), 2, "0") +
          this.filLeft(moment().date().toString(), 2, "0");
      let txtName = `PE${transfer_id}02${date}${depositSequence}-${loc_desc_short}E`;
      this.generateTxtFile(txtName, txt);
    },
  },
};
</script>

<style scoped>
.folded-paper:before {
  content: "";
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 30px 30px 0;
  border-style: solid;
  border-color: rgb(74, 92, 67) #fff;
  transition: 0.3s;
  border-radius: 0 0 0 4px;
}

.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.folded_corner {
  position: relative;
  width: 100%;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  height: auto;
  border-radius: 5px;

  /* background: #58a; */
  background: linear-gradient(to left bottom,
      transparent 50%,
      rgba(194, 193, 193, 0.4) 0) no-repeat 100% 0 / 2em 2em,
    linear-gradient(-135deg, transparent 1.5em, rgb(255, 255, 255) 0);

  /* -webkit-filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
  /* filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
}

/* .folded_corner::before {
    content: " ";
    position: absolute;
    top: 2em;
    right: 0;
    background: linear-gradient(
        to left bottom,
        transparent 50%,
        rgba(0, 0, 0, 0.1) 0
      )
      100% 0 no-repeat;
    width: 2em;
    height: 2em;
    transform: rotate(180deg);
  } */
.some-other-style {
  background: blue;
}

.my-header-style {
  color: #6f8fb9 !important;
}

.containerIframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
