<template>
  <v-row justify="center">
          
      <!-- <v-col cols="12" md="7" class="mt-2 mb-2">
         
      <LineChart :title_header="'Gastos / Compras'" :data="compras" />
    </v-col> -->
    <v-col  cols="12" md="12" class="mt-2 mb-2">
     
      <PieChart :title_header="'Solicitudes de compra '" :data="status_request" />
    </v-col>
  </v-row>
</template>

<script>
import PieChart from "@/components/Charts/Pie.vue";
import LineChart from "@/components/Charts/Line.vue";
export default {
  components: {
    PieChart,
  LineChart,
},
props: {
  status_request: {
    type: Object,
  },
  compra_location: {
    type: Object,
  },
},
 

}
</script>

<style></style>