import Shop from "@/views/Shop/Shop.vue";
import PurchaseRequest from "@/views/Shop/PurchaseRequest.vue";
import Quotes from "@/views/Shop/Quotes.vue";
import QuotesGroup from "@/views/Shop/QuotesGroup.vue";
import QuoteDetailView from "@/views/Shop/QuoteDetailView.vue";
import ViewQuoteGroup from "@/views/Shop/ViewQuoteGroup.vue";
import PurchaseOrder  from "@/views/Shop/PurchaseOrder.vue"
import Approved  from "@/views/Shop/Approved.vue"
import ViewOrders from "@/components/Shop/ViewOrders.vue";
import ViewReceptions from "@/components/Shop/ViewReceptions.vue";
import DownloadQuotation from "@/components/Shop/DownloadQuotation.vue"
import Receptions from "@/views/Shop/Receptions.vue"
import Approvers from "@/views/Maintenance/Approvers.vue"
import InvoiceShop from "@/views/Shop/InvoiceShop.vue"
import Debit from "@/views/Shop/DebitNotes.vue"
 const shop={
    path: "/shop",
    name: "Shop",
    component: Shop,
    meta: {
      SHOP: true,
    },
    children: [
      {
        path: "purchase_request",
        name: "PurchaseRequest",
        component: PurchaseRequest,
        meta: {
          SHOP: true,
        },
      },
      {
        path: "quotes",
        name: "Quotes",
        component: Quotes,
        meta: {
          SHOP: true,
        },
      },
      {
        path: "quotesGroup",
        name: "QuotesGroup",
        component: QuotesGroup,
        meta: {
          SHOP: true,
        },
      },
      {
        path: "quote_detal",
        name: "QuoteDetailView",
        props: true,
        component: QuoteDetailView,
        meta: {
          SHOP: true,
        },
      },
      {
        path: "view_quote_group/:id",
        name: "ViewQuoteGroup",
        component: ViewQuoteGroup,
        meta: {
          SHOP: true,
        },
      },
      {
        path: "view_order/:id/:type/:typeb",
        name: "ViewOrders",
        component: ViewOrders,
        meta: {
          SHOP: true,
        },
      },
      {
        path: "view_receptions/:order_id/:id",
        name: "ViewReceptions",
        component: ViewReceptions,
        meta: {
          SHOP: true,
        },
      },
      {
        path: "purchase_order",
        name: "PurchaseOrder",
        component: PurchaseOrder,
        meta: {
          SHOP: true,
        },
      },
      {
        path: "download_quotation/:id?",
        name: "DownloadQuotation",
        component: DownloadQuotation,
        meta: {
          SHOP: true,
        },
      },
      {
        path: "receptions",
        name: "Receptions",
        component: Receptions,
        meta: {
          SHOP: true,
        },
      },
      {
        path: "approved",
        name: "Approved",
        component: Approved,
        meta: {
          SHOP: true,
        },
      },
      
      {
        path: "approvers",
        name: "Approvers",
        component: Approvers,
        meta: {
          SHOP: true,
        },
      },
      {
        path: "invoice_shop",
        name: "InvoiceShop",
        component: InvoiceShop,
        meta: {
          SHOP: true,
        },
      },
      {
        path: "debit_notes",
        name: "DebitNotes",
        component: Debit,
        meta: {
          SHOP: true,
        },
      },
      
    ],
  }
  export default shop