<template>
  <v-container class="mt-4" fluid>
    <v-row justify="center">
      <span style="font-size: 34px; color: #616161">Catálogo suplidor ({{ data_supplier.name }})</span> </v-row><v-row
      justify="start" class="mb-3 mt-1 ml-5">
      <v-btn small @click="beforeRoute" color="primary" outlined><v-icon>keyboard_backspace</v-icon> Volver</v-btn>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="11">
        <v-data-table :headers="headersItemSelected" :items="itemSelected" :search="search" :items-per-page="8" dense
          class="elevation-1 mt-5">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Articulos selecionados</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <span>{{ itemSelected.length }}</span>

              <v-spacer></v-spacer>
              <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" single-line
                hide-details>
              </v-text-field>

              <v-spacer></v-spacer>
              <ArticleFilters @getItemToEdit="addItem($event)" :showButonNewArticle="false" icon="arrow_right_alt"
                name="Agregar" />
            </v-toolbar>
          </template>
          <template v-slot:[`item.code`]="{ item }">
            <v-row class="mt-2">
              <v-text-field style="font-size: 0.8vw" dense outlined prefix="#" v-model="item.code">
              </v-text-field>
            </v-row>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <v-row class="mt-2">
              <v-text-field style="font-size: 0.8vw" prefix="$" type="number" dense outlined min="1" v-model="item.price">
              </v-text-field>
            </v-row>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" medium color="red" @click="deleteItemSelected(item)">
                  delete
                </v-icon>
              </template>
              <span>Quitar</span>
            </v-tooltip>
            <!-- <v-icon small class="mr-2" @click="editItem(item)">
                              mdi-pencil
                            </v-icon>
                            <v-icon small @click="deleteItem(item)">
                              mdi-delete
                            </v-icon> -->
          </template>
          <template v-slot:no-data>
            <span>No hay artiuclos selecionados</span>
          </template>
        </v-data-table>
      </v-col></v-row>
    <v-row justify="center">
      <v-btn @click="beforeRoute" text class="mx-2">Cancelar</v-btn>
      <v-btn @click="save" color="primary" outlined>Guardar</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import ArticleFilters from "../Inventory/ArticleSelectionList.vue";
export default {
  components: { ArticleFilters },
  data: () => ({
    id_supplier: null,
    data_supplier: [],
    headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Unidad de negocio", value: "bussines_unit_description" },
      { text: "Location", value: "location_description" },

      { text: "Actions", value: "actions", sortable: false },
    ],
    headersItemSelected: [
      {
        text: "ID",
        align: "start",
        value: "item_id",
        width: "5%",
      },
      { text: "Descripcion", value: "item_name", width: "20%" },
      { text: "Medida", value: "unit_name", align: "center" },
      { text: "Codigo Suplidor", value: "code", width: "20%" },
      { text: "Precio base", value: "price", width: "20%" },
      { text: "Acciones", value: "actions", sortable: false, width: "7%" },
    ],
    search: "",
    itemSelected: [],
    catalogues: [],
  }),
  created() {
    this.id_supplier = this.$route.params.id;
    this.init();
  },
  methods: {
    addItem(item) {
      let status = this.itemSelected.find(
        (element) => element.item_id == item.item_id
      );

      if (status == undefined) {
        this.itemSelected.unshift(item);
      } else {
        this.$toast.info("El articulo no esta, o ya encuentra agregado!");
      }
    },
    async deleteItemSelected(item) {
      let data = { supplier_id: item.supplier_id, item_id: item.item_id };

      const responseRequest = await this.api_Post("entity/deleteItem", data);

      if (responseRequest.status == 200) {
        let editedIndex = this.itemSelected.indexOf(item);

        this.itemSelected.splice(editedIndex, 1);
        this.$toast.success("articulo eliminado con exito!");
      } else {
        this.$toast.error("error al eliminar el catalogo!");
      }
    },
    async init() {
      let resultData = await this.api_Get(
        "entity/entity_id/" + this.id_supplier
      );
      let data = await resultData.json();
      this.data_supplier = data[0];
      let resultCatalogue = await this.api_Get(
        "entity/supplier_catalogue/" + this.id_supplier
      );
      let dataCatalogue = await resultCatalogue.json();

      this.itemSelected = dataCatalogue;
    },
    async save() {
      let items = [];
      for (let item of this.itemSelected) {
        items.push({
          item_id: item.item_id,
          code: item.code,
          base_price: item.price,
        });
      }

      let data = {
        supplier_id: this.id_supplier,
        items: items,
      };
      const responseRequest = await this.api_Post("entity/itemsSupplier", data);

      if (responseRequest.status == 200) {
        this.$toast.success("agregado con exito!");
      } else {
        this.$toast.error("error al agregar!");
      }
    },
  },
};
</script>

<style></style>
