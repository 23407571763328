import InvoiceSales from "@/views/Income/InvoiceSales.vue";
import Prepaid from "@/views/Income/Prepaid.vue";
import ViewInvoice from "@/components/Income/ViewInvoice.vue";
import DownloadInvoice from "@/components/Income/DownloadInvoice.vue";
import Invoice from "@/views/Income/Invoice.vue";
import CreditNotes from "@/views/Income/CreditNotes.vue";
const invoiceROutes= {
    path: "/invoice",
    name: "Invoice",
    component: Invoice,
    meta: {
      SALES: true,
    },
    children: [
      {
        path: "sales",
        name: "InvoiceSales",
        component: InvoiceSales,
        meta: {
          SALES: true,
        },
      },
      {
        path: "prepaid",
        name: "Prepaid",
        component: Prepaid,
        meta: {
          SALES: true,
        },
      },
      {
        path: "credit_notes",
        name: "CreditNotes",
        component: CreditNotes,
        meta: {
          SALES: true,
        },
      },
      {
        path: "view_invoice/:id?",
        name: "ViewInvoice",
        component: ViewInvoice,
        meta: {
          SALES: true,
        },
      },

      {
        path: "download_invoice/:id?",
        name: "DownloadInvoice",
        component: DownloadInvoice,
        meta: {
          SALES: true,
        },
      },
    ],
  }

export default invoiceROutes