import Inventory from "@/views/Inventory/Inventory.vue";
import InventoryManagement from "@/views/Inventory/InventoryManagement.vue";
import ArticleConversions from "@/views/Inventory/ArticleConversions.vue";
import Articles from "@/views/Inventory/Articles.vue";

import Adjustments from "@/views/Inventory/Adjustments.vue";
import Variant from "@/views/Inventory/Variant.vue";
import Category from "@/views/Inventory/Category.vue";
import Warehouse from "@/views/Inventory/Warehouse.vue";
import ViewWarehouse from "@/components/Inventory/ViewWarehouse.vue";
import Catalogue from "@/views/Inventory/Catalogue.vue";
import TransferRequest from "@/views/Inventory/Transfers/Request.vue";
import Approvals from "@/views/Inventory/Transfers/Approvals.vue";
import Menu from "@/views/Restaurant/Menu.vue"

const invetoryRoutes={
    path: "/inventory",
    name: "Inventory",
    component: Inventory,
    meta: {
      IVENTORY: true,
    },
    children: [
      {
        path: "variant",
        name: "Variant",
        component: Variant,
        meta: {
          IVENTORY: true,
        },
      },
      {
        path: "menu",
        name: "Menu",
        component: Menu,
        meta: {
          IVENTORY: true,
        },
      },
      {
        path: "category",
        name: "Category",
        component: Category,
        meta: {
          IVENTORY: true,
        },
      },
      {
        path: "warehouse",
        name: "Warehouse",
        component: Warehouse,
        meta: {
          IVENTORY: true,
        },
      },
      {
        path: "catalogue",
        name: "Catalogue",
        component: Catalogue,
        meta: {
          IVENTORY: true,
        },
      },

      {
        path: "view_warehouse/:id",
        name: "ViewWarehouse",
        component: ViewWarehouse,
        meta: {
          IVENTORY: true,
        },
      },
      {
        path: "adjustments",
        name: "Adjustments",
        component: Adjustments,
        meta: {
          IVENTORY: true,
        },
      },
      {
        path: "transfer_request",
        name: "TransferRequest",
        component: TransferRequest,
        params: "type",
        meta: {
          IVENTORY: true,
        },
      },
      {
        path: "transfer_received",
        name: "TransferReceivedt",
        component: TransferRequest,
        params: "type",
        meta: {
          IVENTORY: true,
        },
      },
      {
        path: "approvals",
        name: "Approvals",
        component: Approvals,
        meta: {
          IVENTORY: true,
        },
      },
      {
        path: "maintenace_articles",
        name: "Articles",
        component: Articles,
        meta: {
          IVENTORY: true,
        },
      },
      {
        path: "inventory_management",
        name: "InventoryManagement",
        component: InventoryManagement,
        meta: {
          IVENTORY: true,
        },
      },
      {
        path: "article_conversions",
        name: "ArticleConversions",
        component: ArticleConversions,
        meta: {
          IVENTORY: true,
        },
      },
    ],
  }
  export default invetoryRoutes