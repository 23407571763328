<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-row no-gutters justify="start">
          <v-btn small @click="beforeRoute" color="primary" outlined
            class="rounded text-capitalize"><v-icon>keyboard_backspace</v-icon> Volver</v-btn>
        </v-row>
        <v-row justify="center">
          <QuotesGroupsVue :quote="id_group" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QuotesGroupsVue from "@/components/Shop/QuotesGroups.vue";
export default {
  components: { QuotesGroupsVue },
  data: () => ({
    id_group: null,
  }),

  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.id_group = this.$route.params.id;
    },
  },
};
</script>

<style></style>
