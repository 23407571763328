<template>
  <v-container>
    <v-row justify="center">
      <!-- <div class="hello">
        <h1></h1>
        <div id="reportViewer1">loading...</div>
      </div> -->
      <v-col cols="12" md="12">
        <v-row justify="start" class="mt-1 mb-3">
          <v-btn small @click="beforeRoute" color="primary" outlined
            ><v-icon>keyboard_backspace</v-icon> Volver</v-btn
          >
        </v-row>
        <v-row v-if="!loading ">
          <!-- <iframe
            style="min-height: 90vh; width: 100%; position: relative"
            :src="storage_invoice"
            frameborder="0"
          >
          </iframe> -->
        
    <!-- Utiliza v-if para asegurarte de que pdfUrl tiene una URL antes de mostrar el iframe -->
    <iframe v-if="pdfUrl"    style="min-height: 90vh; width: 100%; position: relative" :src="pdfUrl" frameborder="0" allowfullscreen></iframe>
 
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-skeleton-loader
            type="list-item-avatar, divider, list-item-three-line, card-heading ard-heading, image,image, actions"
            height="100vh"
          ></v-skeleton-loader>
          </v-col>
        
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    storage_invoice: "",
    pdfUrl: null
  }),
  async mounted() {
    //this.init();
  },
  created() {
    this.getData();
  },
  methods: {
    async init() {
      let token = await this.login();

      this.$nextTick(function () {
        $("#reportViewer1").telerik_ReportViewer({
          serviceUrl: "http://128.1.0.244:83/api/reports/",
          ready: function () {
            $("[data-command*='telerik_ReportViewer_historyBack']")
              .parent()
              .hide();
            $("[data-command*='telerik_ReportViewer_historyForward']")
              .parent()
              .hide();
            $("[data-command*='telerik_ReportViewer_zoomIn']").parent().hide();
            $("[data-command*='telerik_ReportViewer_zoomOut']").parent().hide();
            $("[data-command*='telerik_ReportViewer_toggleDocumentMap']")
              .parent()
              .hide();
          },
          authenticationToken: token,
          reportSource: {
            report: "Samples/Report1.trdx",
            parameters: {
              id: "36313",
            },
          },
          viewMode: telerikReportViewer.ViewModes.INTERACTIVE,
          scaleMode: telerikReportViewer.ScaleModes.SPECIFIC,
          scale: 1.0,
          sendEmail: { enabled: true },
        });
      });
    },
    async login(username, password) {
      let serverHost = "http://128.1.0.244:83/";
      let accessToken = "";

      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          username: "dev_gabriel",
          password: "#*Klkmanin",
          grant_type: "password",
        }),
      };

      try {
        let response = await fetch(serverHost + "Token", options);
        let data = await response.json();
        return data.access_token;
      } catch (error) {
        console.error("Error: " + error);
      }
    },
    async getData() {
      this.loading = true;
      let invoice_id = this.$route.params.id;
      const responseRequest = await this.api_Post("income/search_invoice_id", {
        number: invoice_id,
      });
      let data_invoice =''
      if (responseRequest.status == 200) {
         data_invoice = await responseRequest.json();
         console.log(data_invoice)
      } else {
        this.$toast.error("error al cargar la factura!");
      }
    
      const responseDataPdf = await this.api_Post("income/view_invoice", {
        id: data_invoice[0].invoice_id,
      });
      
      const blob = await responseDataPdf.blob();
      const pdfUrl = URL.createObjectURL(blob);
      
      console.log(pdfUrl)
      this.pdfUrl = pdfUrl;
  
      this.loading = false;
    },
  },
};
</script>
<style scoped>
body {
  font-family: Verdana, Arial;
  margin: 5px;
}

#reportViewer1 {
  position: relative;
  width: 80vw;
  height: 1000px;
}
</style>
