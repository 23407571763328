<template>
  <v-flex>
    <v-container>
      <v-row class="mt-n1">
        <v-col v-for="(item, i) in quotes" :key="i" cols="12" md="12">
          <QuoteDetailsVue v-if="item[0].supplier_id == supplier" :quote="item" />
        </v-col>
      </v-row>
    </v-container>
  </v-flex>
</template>

<script>
import QuoteDetailsVue from "@/components/Shop/QuoteDetails.vue";
export default {
  data() {
    return {
      quotes: [],
    };
  },
  components: { QuoteDetailsVue },
  props: {
    supplier: {
      type: Number,
      required: true,
    },
    quote: {
      type: String,
      required: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const request = await this.api_Get(
        "shopping/quotesDetails/" + this.quote
      );
      let data = await request.json();

      let quote = this.groupBy(data, "supplier_id");
      let result = [];

      let quantity = 0;
      for (let item of quote) {
        let data = [];
        item.reduce(function (res, value) {
          if (!res[value.item_id]) {
            res[value.item_id] = value;

            data.push(res[value.item_id]);
          }
          res[value.item_id].quantity += parseFloat(value.quantity);
          return res;
        }, {});
        result.push(data);
      }

      this.quotes = result;
    },
    groupBy(collection, property) {
      let i = 0,
        val,
        index,
        values = [],
        result = [];
      for (; i < collection.length; i++) {
        val = collection[i][property];
        index = values.indexOf(val);
        if (index > -1) result[index].push(collection[i]);
        else {
          values.push(val);
          result.push([collection[i]]);
        }
      }
      return result;
    },
  },
};
</script>

<style></style>
