<template>
  <v-flex>
    <v-row justify="center">
      <!-- <v-container>
        <v-carousel
          :continuous="false"
          :cycle="cycle"
          :show-arrows="true"
          delimiter-icon="mdi-minus"
          v-model="model"
        >
          <v-carousel-item v-for="(item, i) in quotes" :key="i">
            <QuoteDetailsVue :quote="item" />
          </v-carousel-item>
        </v-carousel>
      </v-container> -->
      <!-- :outline="item[i].supplier_id==item[i].order_supplier_id" -->
      <v-col v-for="(item, i) in quotes" :key="i" cols="12" md="6">
        <v-card style="" @click="go(item)" elevation="15">
          <QuoteDetailsVue :type="2" :quote="item" />
        </v-card>
      </v-col>
    </v-row>
  </v-flex>
</template>

<script>
import QuoteDetailsVue from "./QuoteDetails.vue";
export default {
  components: { QuoteDetailsVue },
  data: () => ({
    quotes: [],
    model: false,
  }),
  props: {
    quote: {
      type: String,
    },
  },

  created() {
    this.init();
  },
  watch: {
    quote(val, newVal) {
      this.init();
    },
  },
  methods: {
    go(item) {
      this.$router.push({
        name: "QuoteDetailView",
        params: { supplier: item[0].supplier_id, quote: this.quote },
      });
    },
    async init() {
      const request = await this.api_Get(
        "shopping/quotesDetails/" + this.quote
      );
      let data = await request.json();

      let quote = this.groupBy(data, "supplier_id");
      let result = [];
      //console.log(quote)
      let quantity = 0;
      for (let item of quote) {
        let data = [];

        item.reduce(function (res, value) {
          if (!res[value.item_id]) {
            res[value.item_id] = value;

            data.push(res[value.item_id]);
          }

          res[value.item_id].quantity += parseFloat(value.quantity);
          return res;
        }, {});

        result.push(data);
      }

      this.quotes = result;
    },
    groupBy(collection, property) {
      let i = 0,
        val,
        index,
        values = [],
        result = [];
      for (; i < collection.length; i++) {
        val = collection[i][property];
        index = values.indexOf(val);
        if (index > -1) result[index].push(collection[i]);
        else {
          values.push(val);
          result.push([collection[i]]);
        }
      }
      return result;
    },
  },
};
</script>

<style></style>
