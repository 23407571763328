//const url =process.env.VUE_APP_API_URL_PRODUCTION ;
const url =process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL_PRODUCTION:process.env.VUE_APP_API_URL_DEV
const token = localStorage.getItem("token");
//console.log(token);
const cabecera = {
  "Content-Type": "application/json",
  Authorization: "bearer " + token,
};

export const api_Get = async function (link) {

  const request = { method: "GET", headers: cabecera };
  const response = await fetch(url + link, request);
  // console.log(response);
  return response;
};
export const api_Post = async function (link, body) {
  const request = {
    method: "POST",
    headers: cabecera,
    body: JSON.stringify(body),
  };

  const response = await fetch(url + link, request);
 
  return response;
};

export const api_Put = async function (link, body) {
  const request = {
    method: "PUT",
    headers: cabecera,
    body: JSON.stringify(body),
  };
  const response = await fetch(url + link, request);
  return response;
};

export const api_Delete = async function (link) {
  const request = { method: "DELETE", headers: cabecera };
  const response = await fetch(url + link, request);
  return response;
};
