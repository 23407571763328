<template>
  <v-container fluid>
    <v-layout align-start>
      <v-flex>
        <v-card flat>
          <v-row no-gutters justify="center">
            <span style="font-size: 34px; color: #616161">
              <v-icon large class="pr-1 pb-2">add_shopping_cart</v-icon>
              Solicitudes de compra
            </span>
          </v-row>
          <v-card-text class="pa-0">
            <HeaderFilters class="pt-3" @refreshWarehouse="getRequest" :disable_all="disableFilters"
              :filter_default="defaultFilters" />
            <v-stepper flat v-model="page">
              <v-stepper-items>
                <v-stepper-content v-for="currentPage in steps" :key="`${currentPage}-content`" :step="currentPage"
                  class="pa-0">
                  <v-card flat v-show="page === 1">
                    <v-card outlined class="mt-2 pt-2">
                      <v-card-text class="pa-0">
                        <v-data-table :headers="headerTransfer" :search="search" :items="requestFilter" locale="es-do"
                          no-data-text="No se encontraron solicitudes con los filtros aplicados">
                          <template v-slot:top>
                            <v-toolbar flat>
                              <v-row>
                                <v-col cols="2" align="center">
                                  <span :style="`font-size:15px`">
                                    Solicitudes: {{ requestFilter.length }}
                                  </span>
                                </v-col>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-col cols="4" align="center">
                                  <v-text-field class="text-xs-center" v-model="search" append-icon="search"
                                    label="Buscar" single-line hide-details>
                                  </v-text-field>
                                </v-col>
                                <v-col cols="5" align="end">
                                  <v-row justify="end">
                                    <v-col cols="5" align="end">
                                      <v-autocomplete v-model="statusFilter" :items="itemStatus" color="cyanText"
                                        label="Estatus" outlined dense hide-details item-value="id" item-text="name">
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col cols="1" align="end">
                                      <v-btn color="success" class="mr-2 pa-1" outlined @click="newRequest([])">
                                        <v-icon>add</v-icon> Nueva
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-toolbar>
                          </template>
                          <template v-slot:[`item.status_description`]="{ item }">
                            <v-chip dark :color="time_line_config(1, item.status_id).color">
                              {{ item.status_description }}
                              <v-icon dark small class="pl-2">
                                {{
                                  time_line_config(2, item.status_id).icon
                                }}</v-icon>
                            </v-chip>
                          </template>
                          <template v-slot:[`item.options`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="primary" @click="viewDetail(item, false, 1)"
                                  large>
                                  read_more
                                </v-icon>
                              </template>
                              <span>Ver detalles</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="light-blue darken-2"
                                  @click="viewDetail(item, true, 2)" class="pl-2">
                                  difference
                                </v-icon>
                              </template>
                              <span>Copiar (Crear nueva a partir de esta)</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" class="pl-3" color="error" v-if="item.status_id <= 2"
                                  @click="openCinfirm(item, 3)">
                                  cancel
                                </v-icon>
                              </template>
                              <span>Cancelar esta solicitud</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" class="pl-3" color="orange lighten-2"
                                  v-if="item.status_id === 1" @click="viewDetail(item, true, 1)" large>
                                  edit_note
                                </v-icon>
                              </template>
                              <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" class="pl-3" color="success" v-if="item.status_id === 1"
                                  @click="openCinfirm(item, 2)">
                                  send
                                </v-icon>
                              </template>
                              <span>Enviar</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-card>
                  <v-card flat v-show="page === 2">
                    <RequestDetails @exitDetail="detailClose" @save_request="save" :item="itemRequest"
                      :time_line_status="requestTimeLine" :item_list="articlesDetail" :can_edit="canEdit"
                      :location="filters.location" :all_applicants="allApplicants" :warehouse="filters.warehouse" />
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <DialogConfirm :showDialog="dialogConfirm" :confirm_settings="confirmSettings" @closeDialog="sendOrCancel" />
  </v-container>
</template>

<script>
import HeaderFilters from "@/components/Shop/HeaderFilters.vue";
import RequestDetails from "@/components/Shop/RequestDetails.vue";
import DialogConfirm from "@/components/DialogConfirm.vue";
import moment from "moment";
export default {
  data: () => ({
    search: "",
    page: 1,
    steps: 2,
    dialogDetail: false,
    dialogReception: false,
    requestList: [],
    requestTimeLine: [],
    articlesDetail: [],
    canEdit: false,
    headerTransfer: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      { text: "Creacion", value: "requested_at" },
      { text: "Almacen", value: "warehouse_name" },
      { text: "Departamento", value: "department_name" },
      { text: "Proyecto", value: "project_name" },
      { text: "Solicitante", value: "requested_by" },
      { text: "Estatus", value: "status_description" },
      { text: "", value: "options", sortable: false },
    ],
    itemsFilters: {},
    itemRequest: { id: null },
    filters: {
      department_id: 0,
      location: { catalogue_id: "", id: "" },
      business: "",
      warehouse: { id: "", name: "" },
      dateStart: null,
      dateEnd: null,
    },
    itemStatus: [],
    statusFilter: 0,
    refreshReceived: false,
    defaultFilters: [],
    confirmSettings: {
      btn_affirmative: "",
      btn_negative: "",
      icon: "",
      size: "",
      title: "",
    },
    dialogConfirm: false,
    tempRequest: { id: null, status_id: null },
    allApplicants: [],
  }),
  components: {
    HeaderFilters,
    RequestDetails,
    DialogConfirm,
  },
  created() {
    this.getInitialData();
  },
  computed: {
    requestFilter() {
      if (this.statusFilter == 0) {
        return this.requestList;
      }
      return this.requestList.filter(
        (element) => element.status_id == this.statusFilter
      );
    },
    disableFilters() {
      return this.page == 1 ? false : true;
    },
  },
  watch: {},
  methods: {
    async getInitialData() {
      const request = await this.api_Get("shopping/request_status");
      this.itemStatus = await request.json();
      this.itemStatus.unshift({ id: 0, name: "Todo" });
      const requestApplicants = await this.api_Get("shopping/applicants");
      const data = await requestApplicants.json();
      if (requestApplicants.status == 200) {
        this.allApplicants = data;
      }
    },
    async getRequest(filters) {
      this.requestList = [];
      this.filters = filters;
      if (
        this.filters.warehouse == undefined ||
        this.filters.warehouse.id == null
      ) {
        this.$toast.warning("Escoja un almacen!");
        return;
      }
      if (this.filters.location.id == null) {
        return;
      }
      function filterDepartment(filtros) {
        if (
          filtros.department_id == null ||
          filtros.department_id == undefined ||
          filtros.department_id == 0
        ) {
          return " ";
        }
        return " AND ITEM_REQUESTS.DEPARTMENT_ID=" + filtros.department_id;
      }
      function filterProject(filtros) {
        if (
          filtros.project_id == null ||
          filtros.project_id == undefined ||
          filtros.project_id == 0
        ) {
          return " ";
        }
        return " AND ITEM_REQUESTS.PROJECT_ID=" + filtros.project_id;
      }
      let filtros = ` WHERE WAREHOUSE_ID=${this.filters.warehouse.id}  
                            AND ITEM_REQUESTS.LOCATION_ID=${this.filters.location.id
        }
                            AND REQUESTED_AT BETWEEN '${this.filters.dateStart
        }' 
                            AND '${this.filters.dateEnd}' 
                            ${filterDepartment(filters)}
                            ${filterProject(filters)}
                            ORDER BY ID DESC`;
      const request = await this.api_Post("shopping/requestList", {
        filters: filtros,
      });
      const data = await request.json();
      if (request.status == 200) {
        this.requestList = data;
        for (let key in this.requestList) {
          this.requestList[key].requested_at = moment(
            this.requestList[key].requested_at
          ).format("DD/MM/YYYY h:mm a");
        }
        this.page = 1;
      } else {
        this.$toast.error(data.error);
      }
    },
    async viewDetail(item_request, is_edit, type) {
      const request = await this.api_Get(
        "shopping/request_detail/" + item_request.id
      );
      const data = await request.json();
      if (request.status == 200) {
        let articles = [];
        for (let item of data) {
          let image = "image-not-aviable.jpg";
          if (
            item.item_image_url != "" &&
            item.item_image_url != null &&
            item.item_image_url != "null" &&
            item.item_image_url != undefined
          ) {
            image = await this.get_storage("items_images", item.item_image_url);
          }
          articles.push({
            item_id: item.item_id,
            item_name: item.item_name,
            requested_quantity: parseFloat(item.quantity).toFixed(2),
            image_url: item.item_image_url,
            item_image: image,
          });
        }
        if (type == 2) {
          //copiar y crear nueva
          this.refreshReceived = false;
          this.newRequest(articles);
          return;
        }
        this.articlesDetail = articles;
        this.canEdit = is_edit;
        this.requestTimeLine = item_request.request_history;
        this.itemRequest = item_request;
        this.page = 2;
      } else {
        this.$toast.error("Error al consultar el detalle!." + data.error);
      }
    },
    newRequest(items) {
      if (
        this.filters.warehouse == undefined ||
        this.filters.warehouse.id == null
      ) {
        this.$toast.warning("Escoja un almacen!");
        return;
      }
      if (
        this.filters.department_id == null ||
        (this.filters.department_id == 0 && this.filters.project.id == 0)
      ) {
        this.$toast.warning("Escoja un departamento o un proyecto!");
        return;
      }
      this.articlesDetail = items;
      this.canEdit = true;
      this.itemRequest = { id: null, status_id: 1 };
      this.requestTimeLine = [{ name: "Borrador", status_id: 1 }];
      this.page = 2;
    },
    async save(type) {
      let data;
      try {
        const request = await this.api_Post("shopping/save_request", {
          request_id: type.request_id,
          business_unit_id: this.filters.business,
          location_id: this.filters.location.id,
          department_id: this.filters.department_id,
          warehouse_id: this.filters.warehouse.id,
          project_id: this.filters.project.id,
          user_id: this.$store.state.auth.user.user.user_id,
          request_by: type.applicant,
          status_id: type.saveType,
          items: this.articlesDetail,
        });
        data = await request.json();
        if (request.status == 200) {
          this.$toast.success("Guardado correctamente!");
          this.getRequest(this.filters);
        } else {
          this.$toast.error("Error al registrar la solicutud!." + data.error);
        }
      } catch (error) { }
    },
    detailClose() {
      this.page = 1;
    },
    openCinfirm(itemRequest, type) {
      this.confirmSettings.title = `Seguro que desea ${type == 2 ? "enviar" : "cancelar"
        } esta solicitud?`;
      this.confirmSettings.icon = type == 2 ? "send" : "cancel";
      this.confirmSettings.btn_affirmative =
        type == 2 ? "Si, enviar!" : "Si, cancelar solicitud";
      this.confirmSettings.btn_negative = "No, volver";
      this.confirmSettings.size = "45%";
      this.tempRequest = { id: itemRequest.id, status_id: type };
      this.dialogConfirm = true;
    },
    async sendOrCancel(confirm) {
      if (confirm == false) {
        this.dialogConfirm = false;
        return;
      }
      let data = null;
      try {
        const request = await this.api_Post("shopping/send_cancel_request", {
          request_id: this.tempRequest.id,
          status_id: this.tempRequest.status_id,
          user_id: this.$store.state.auth.user.user.user_id,
        });
        data = await request.json();
        if (request.status == 200) {
          this.$toast.success("Solicitud cancelada correctamente!");
          this.dialogConfirm = false;
          this.getRequest(this.filters);
        } else {
          this.$toast.error("Error al registrar la solicutud!." + data.error);
        }
      } catch (error) {
        this.$toast.error(data.error);
      }
    },
  },
};
</script>
