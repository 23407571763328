<template>
  <v-dialog v-model="show_dialog" fullscreen persistent>
    <v-card outlined color="#F4F5FB">
      <v-card-title>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" small @click="showBar = !showBar" v-click-outside="onClickOutside"
              color="primary" outlined class="mr-3">
              <v-icon>{{ showBar == false ? "menu" : "menu_open" }}</v-icon>
            </v-btn>
          </template>
          <span>{{ showBar == false ? "Ver menu" : "Ocultar menu" }}</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn v-if="item.showButtonBack" small @click="$emit('closeDialog', true)" color="primary" outlined class="mr-3">
          <v-icon>keyboard_backspace</v-icon>
          Volver</v-btn>
        <span :style="`color:#757575;font-size:20px`">{{ dialogTitle }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('closeDialog', false)">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-divider class="mx-2" inset></v-divider>
        <NavigationButonsTop :showBar="showBar" />
        <v-container fluid>
          <v-row class="mt-2">
            <v-col cols="12" md="7" class="pt-0 mt-0 pb-0">
              <v-card width="100%" min-height="350" outlined>
                <v-card-title class="pb-0 mb-0">
                  <v-row class="pb-0 mb-0">
                    <v-col cols="12" md="5" class="pb-0 mb-0">
                      <span class="pb-0 mb-0">
                        Lista de articulos a solicitar</span>
                    </v-col>
                    <v-col cols="12" md="3" align="end" class="pb-0 mb-0">
                      <v-autocomplete v-model="locationFrom" :items="$store.state.auth.locations.filter(
                        (element) => element.bus_id == location.bus_id
                      )
                        " color="cyanText" label="Location a solicitar" prepend-inner-icon="my_location" outlined
                        required dense class="pb-0 mb-0" hide-details :disabled="!can_edit">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" align="end" class="pb-0 mb-0">
                      <v-autocomplete v-model="warehouseFrom" :items="warehousesForLocations" color="cyanText"
                        label="Almacen a solicitar" prepend-inner-icon="business" outlined required dense item-value="id"
                        item-text="name" class="pb-0 mb-0" hide-details :disabled="!can_edit"></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider class="ma-3"></v-divider>
                <v-container fluid class="pt-0">
                  <v-data-table :headers="headersArticle" :search="search" :items="item_list">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <span>Total: {{ item_list.length }}</span>
                        <v-spacer></v-spacer>
                        <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar"
                          single-line hide-details>
                        </v-text-field>
                        <v-spacer></v-spacer>
                        <v-divider class="mx-3" inset vertical></v-divider>
                        <v-btn color="success" class="mr-2" outlined v-if="can_edit" @click="openDialogAddItem()">
                          Agregar
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.item_image`]="{ item }">
                      <v-avatar size="40">
                        <v-img :src="item.item_image"></v-img>
                      </v-avatar>
                    </template>
                    <template v-slot:[`item.requested_quantity`]="{ item }">
                      <v-text-field class="text-xs-center" v-model="item.requested_quantity" dense type="number" min="1"
                        :readonly="!can_edit">
                      </v-text-field>
                    </template>
                    <template v-slot:[`item.remove`]="{ item }">
                      <v-icon color="error" @click="removeItem(item)" v-if="can_edit">cancel</v-icon>
                    </template>
                  </v-data-table>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="save(2)" v-if="can_edit">
                    <v-icon class="pr-1">send</v-icon>Guardar y enviar
                  </v-btn>
                  <v-btn color="success" text @click="save(1)" v-if="can_edit">
                    <v-icon class="pl-1">save</v-icon>Guardar como borrador
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" class="pt-0 mt-0 pb-0">
              <v-card width="100%" min-height="350" outlined>
                <v-card-title class="pb-0 d-flex justify-center">Historico</v-card-title>
                <v-divider class="ma-4"></v-divider>
                <v-container fluid>
                  <template>
                    <v-timeline>
                      <v-timeline-item v-for="item in time_line_status" small :key="item.warehouse_approval_type_id"
                        :color="time_line_config(1, item.warehouse_approval_type_id)
                            .color
                          " class="pb-0" :icon="time_line_config(1, item.warehouse_approval_type_id)
      .icon
    ">
                        <template v-slot:opposite>
                          <span>{{ item.use_fullname }}</span>
                        </template>
                        <v-card outlined>
                          <v-card-title class="d-flex justify-center align-center pt-0 pb-0" :class="time_line_config(
                            1,
                            item.warehouse_approval_type_id
                          ).color
                            ">
                            <span style="font-size: 18px; color: #ffffff">
                              {{ dateFormated(item.created_at) }}</span>
                          </v-card-title>
                          <v-card-text>
                            <span class="d-flex justify-center align-center">
                              {{ item.name }}</span>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </template>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-dialog width="50%" v-model="dialogAddItem">
        <dialog_Articles @selected-item="addItem" :removeItemSelected="false" :showWarehouse="false" :data="{
          location: location.id,
          business: location.bus_id,
          warehouse: warehouse.id,
          catalogue: location.catalogue_id,
        }" @close-item="dialogAddItem = false" />
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import dialog_Articles from "@/components/Inventory/CatalogeForAdjustment.vue";
import NavigationButonsTop from "@/components/Inventory/NavigationButonsTop.vue";
export default {
  data: () => ({
    search: "",
    showBar: false,
    headersArticle: [
      {
        align: "start",
        value: "item_image",
      },
      { text: "ID", value: "item_id" },
      { text: "Articulo", value: "item_name" },
      {
        text: "Cantidad solicitada",
        value: "requested_quantity",
        width: "20%",
        align: "center",
      },
      {
        text: "Cantidad recibida",
        value: "received_quantity",
        align: "center",
      },
      { text: "", value: "remove" },
    ],
    dialogAddItem: false,
    locationFrom: null,
    warehouseFrom: null,
    itemsWarehouse: [],
  }),
  name: "ItemsRequestDetails",
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    can_edit: {
      type: Boolean,
      default: false,
    },
    time_line_status: {
      type: Array,
      default() {
        return [{ name: "Borrador", warehouse_approval_type_id: 1 }];
      },
    },
    item_list: {
      type: Array,
      default() {
        return [];
      },
    },
    item: {
      type: Object,
      default() {
        return {
          status: null,
          location_from: null,
          warehouse_from: null,
        };
      },
    },
    location: {
      type: Object,
      default() {
        return {
          id: null,
          bus_id: null,
          catalogue_id: null,
        };
      },
    },
    warehouse: {
      type: Object,
      default() {
        return {
          id: null,
        };
      },
    },
  },
  created() {
    this.initialized();
  },
  computed: {
    dialogTitle() {
      if (this.item.id == null) {
        return "Nueva solicitud de transferencia";
      }
      if (parseInt(this.item.status_id) < 3) {
        return "Solicitud de transferencia #" + this.item.id;
      }
      return "Transferencia #" + this.item.id;
    },
    warehousesForLocations() {
      let warehouses = this.itemsWarehouse.filter(
        (element) =>
          element.location_id == this.locationFrom &&
          element.id != this.warehouse.id
      );
      if (warehouses.length == 0) {
        this.warehouseFrom = 0;
        return [{ id: 0, name: "Escoja un location" }];
      }
      return warehouses;
    },
  },
  watch: {
    item(val) {
      if (val.id != null) {
        this.locationFrom = val.from_location_id;
        this.warehouseFrom = val.from_warehouse_id;
      } else {
        this.locationFrom = null;
        this.warehouseFrom = null;
      }
    },
  },
  methods: {
    async initialized() {
      const warehouses = await this.api_Get("inventory/allWarehouses");
      this.itemsWarehouse = await warehouses.json();
    },
    dateFormated(date) {
      return moment(date).format("DD/MM/YYYY h:mm a");
    },
    openDialogAddItem() {
      this.dialogAddItem = true;
    },
    addItem(item) {
      let existArticle = this.item_list.find(
        (element) => element.item_id == item.item_id
      );
      if (existArticle != undefined) {
        this.$toast.warning("Este articulo ya se encuentra en la lista!");
        return;
      }
      this.item_list.push({
        item_id: item.item_id,
        item_name: item.item_name,
        requested_quantity: 1,
        received_quantity: 0,
        item_image: item.item_image,
      });
      this.dialogAddItem = false;
    },
    removeItem(item) {
      let index = this.item_list.indexOf(item);
      this.item_list.splice(index, 1);
    },
    save(type) {
      this.$emit("save_request", {
        transfer_id: this.item.id,
        saveType: type, //1=borrador,2=pendiente
        locationFrom: this.locationFrom,
        warehouseFrom: this.warehouseFrom,
      });
    },
    onClickOutside() {
      this.showBar = false;
    },
  },
  components: {
    dialog_Articles,
    NavigationButonsTop,
  },
};
</script>
