<template>
  <v-flex>
    <v-row class="mt-2" justify="center">
      <v-col cols="12" md="12">
        <v-container>
          <v-row justify="center">
            <span style="font-size: 34px; color: #616161">Catálogo de cuentas</span>
          </v-row>
          <v-row justify="center">
            <span style="font-size: 16px; color: #757575">Configura y ordena tu catálogo de cuentas personalizado</span>
          </v-row>
          <v-col cols="12" md="8" offset-md="2">
            <v-treeview return-object class="mt-5" activatable :items="items">
              <template slot="label" slot-scope="{ item }">
                <span class="primary--text font-weight-bold">{{ item.code }} -
                </span>
                <span>{{ item.name }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon color="success" v-text="`payments`"></v-icon>
              </template>
              <template slot="append" slot-scope="{ item }">
                <v-tooltip>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon @click="ViewAccount(item)"><v-icon
                        color="primary">visibility</v-icon></v-btn>
                  </template>
                </v-tooltip>

                <v-tooltip>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon @click="editItem(item)"><v-icon
                        color="orange">edit</v-icon></v-btn>
                  </template>
                </v-tooltip>
                <v-tooltip>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon @click="addAccount(item)"><v-icon
                        color="success">add</v-icon></v-btn>
                  </template>
                </v-tooltip>
              </template>
            </v-treeview>
          </v-col>
        </v-container>
      </v-col></v-row>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5 primary--text">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="dialog = false">close</v-icon>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row class="pa-4 pb-4">
              <span><b>Pertenece a {{ this.seletecName }}</b></span>
            </v-row>
            <v-row class="mt-4" no-gutters>
              <v-col cols="12" md="12">
                <v-text-field outlined v-model="editedItem.name" label="Nombre cuenta"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-text-field outlined v-model="editedItem.code" label="Codigo de la cuenta"></v-text-field></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-textarea outlined label="Descripcion" v-model="editedItem.note"></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-autocomplete v-model="selectedCategoryDgii" :items="categoryDgii" color="cyanText" return-object
                label="Dgii" outlined required></v-autocomplete>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="mt-n10">
          <v-row no-gutters justify="center">
            <v-spacer></v-spacer>
            <v-btn outlined text> Cancelar </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="save" dark color="black"> Guardar </v-btn>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
export default {
  data: () => ({
    selectedCategoryDgii: "",
    categoryDgii: [],
    seletecName: "",
    dialog: false,
    items: [],
    itemsBussines: [],
    itemsLocations: [],
    itemsLocations2: [],
    itemsDepartment: [],
    itemSaved: [],
    ategoryDgii: [],
    newItem: [],
    itemsRoutes: [],
    editedItem: [],
    catalogue_id: null,
    editedIndex: -1,
    filters: { business: "", location: null, department_id: "", routes: "" },
    editedIndex: -1,
  }),
  created() {
    this.getData();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Crear cuenta contable "
        : "Editar cuenta contable ";
    },
  },
  watch: {
    // dialog(val) {
    //   val || this.close();
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
  },
  methods: {
    async getData() {
      let resultCategoryDgii = await this.api_Get("accounting/category_dgii");
      const dataCategoryDgii = await resultCategoryDgii.json();
      for (let item of dataCategoryDgii) {
        this.categoryDgii.push({ text: item.name, value: item.id });
      }
      this.CategoryDgii = dataCategoryDgii;

      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      const dataTypeAccounts = await resultTypeAccounts.json();

      let child = [];
      for (let items of dataTypeAccounts) {
        child.push({
          id: items.id,
          name: items.name,
          parent: items.parent_id,
          code: items.code,
          note: items.note,
          account_type_id: items.account_type_id,
          dgii_category_id: items.dgii_category_id,
        });
      }

      const nest = (items, id = null, link = "parent") =>
        items
          .filter((item) => item[link] === id)
          .map((item) => ({ ...item, children: nest(items, item.id) }));

      let result = nest(child);

      this.items = result;
    },
    async save() {
      if (this.editedItem.name == "") {
        this.$toast.info("Digita el nombre de la cuenta!");
        return;
      }

      if (this.editedItem.code === ``) {
        this.$toast.info("Digita el codigo de la cuenta!");
        return;
      }

      if (this.selectedCategoryDgii === "") {
        this.$toast.info("Digita el codigo de la dgii!");
        return;
      }
      if (this.editedIndex > -1) {
        const responseRequest = await this.api_Post(
          "accounting/accounting_account",
          {
            chart_of_account_id: this.editedItem.id,
            account_type_id: this.editedItem.account_type_id,
            parent_id: this.editedItem.parent,
            dgii_category_id: this.editedItem.dgii_category_id,
            code: this.editedItem.code,
            name: this.editedItem.name,
            note: this.editedItem.note,
          }
        );

        if (responseRequest.status == 200) {
          this.getData();
          this.$toast.success("cuenta actualizada con exito!");
        } else {
          this.$toast.error("error al crear la cuenta!");
        }
      } else {
        const responseRequest = await this.api_Post(
          "accounting/accounting_account",
          {
            chart_of_account_id: null,
            account_type_id: this.itemSaved.account_type_id,
            parent_id: this.itemSaved.id,
            dgii_category_id: this.selectedCategoryDgii.value,
            code: this.editedItem.code,
            name: this.editedItem.name,
            note: this.editedItem.note,
          }
        );

        if (responseRequest.status == 200) {
          this.getData();
          this.$toast.success("cuenta creadad con exito!");
        } else {
          this.$toast.error("error al crear la cuenta!");
        }
      }
      this.close();
    },
    ViewAccount(item) {
      this.$router.push({
        name: "ViewEntryDiaryBook",
        params: { id: item.id },
      });
    },
    updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
      itemsDestino.splice(0, itemsDestino.length);

      for (let item of itemsOrigen) {
        if (item.bus_id === condicion) {
          itemsDestino.push({
            value: item.value,
            text: item.text,
            routes: item.routes,
          });
        }
      }
      this.filters.location = this.itemsLocations2[0].value;

      return itemsDestino;
    },
    editItem(item) {
      this.selectedCategoryDgii = this.editedItem.dgii_category_id;
      this.editedIndex = 0;
      this.editedItem = Object.assign({}, item);

      this.seletecName = this.editedItem.code + " - " + this.editedItem.name;

      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    addAccount(item) {
      this.editedItem = [];
      this.itemSaved = [];
      this.selectedCategoryDgii = "";
      this.editedIndex = -1;
      let data = Object.assign({}, item);
      this.itemSaved = data;
      this.seletecName = data.name + " - " + data.code;
      this.editedItem.name = "";
      this.editedItem.code = "";
      this.dialog = true;
    },
  },
};
</script>

<style></style>
