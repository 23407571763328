<template>
  <v-container style="background-color: white" fluid>
    <v-scroll-x-transition>
      <v-card flat v-if="!show_new_calendar">
        <v-row no-gutters class="pb-0 mb-0" justify="center">
          <v-col cols="12" md="6" justify="center" align="center">
            <span style="font-size: 30px; color: #616161">
              <v-icon large class="pr-1 pb-2">edit_calendar</v-icon>
              Calendario</span
            >
          </v-col>
        </v-row>
        <v-card outlined>
          <v-data-table
            dense :items-per-page="12"
            height="62vh" hide-default-footer
            :headers="headers" fixed-header 
            :items="items_calendar"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Periodos</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-col cols="2">
                  <v-autocomplete
                    v-model="calendar_selected"
                    :items="all_calendars"
                    item-text="name" @change="select_calendar()"
                    item-value="id" return-object
                    label="Calendarios"
                    outlined dense hide-details
                    ></v-autocomplete>
                  </v-col>            
                <v-spacer></v-spacer>
                <v-col cols="4">
                <v-text-field
                  class="text-xs-center"
                  v-model="search"
                  append-icon="search"
                  label="Buscar"
                  single-line dense
                  hide-details outlined
                >
                </v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn text color="success" @click="show_new_calendar = true" v-show="!pending_save"
                ><v-icon>add</v-icon> Nuevo calendario</v-btn>
                <v-btn text color="success" @click="add_calendar" v-show="pending_save" :loading="loading"
                ><v-icon>save</v-icon> Guardar</v-btn>
                <v-divider vertical></v-divider>
                <v-menu>
                  <template v-slot:activator="{ attrs, on }">
                      <v-btn color="#0D47A1" fab text v-bind="attrs" v-on="on" :disabled="pending_save"
                          class="pl-1 pr-0 font-weight-bold" :loading="loading">
                          <v-icon dark> settings </v-icon>
                      </v-btn>
                  </template>
                  <v-list>
                      <v-list-item>
                          <v-list-item-title>
                              <v-btn text @click="set_new_calendar_to_edit()">
                                  <v-icon color="primary">edit_calendar</v-icon>
                                  Editar datos del calendario
                              </v-btn>
                          </v-list-item-title>
                      </v-list-item>
                      <v-list-item  v-if="edit_calendar">
                          <v-list-item-title>
                              <v-btn text @click="edit_calendar=false">
                                  <v-icon color="secondary">edit_note</v-icon>
                                  Editar periodos
                              </v-btn>
                          </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="!edit_calendar">
                          <v-list-item-title>
                              <v-btn text @click="update_calendar">
                                  <v-icon color="success">event_available</v-icon>
                                  Guardar cambios
                              </v-btn>
                          </v-list-item-title>
                      </v-list-item>
                  </v-list>
              </v-menu>
              </v-toolbar>
            </template>
            <template v-slot:[`item.month`]="{ item }">
                 <span>{{ get_month(item) }}</span>
            </template>
            <template v-slot:[`item.date_from`]="{ item }">
                  <v-text-field
                    v-model="item.date_from"
                    plain dense readonly
                    hide-details
                    type="date"
                    mask="##/##/####"
                    :rules="date_rule"
                  ></v-text-field>
            </template>
            <template v-slot:[`item.date_to`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.date_to"
                    v-bind="attrs" v-on="on"
                    plain dense hide-details
                    :min="item.date_from"
                    :max="get_max_date_to" :readonly="edit_calendar"                  
                    type="date" @input="set_date_from(item)"
                    mask="##/##/####"
                    :rules="date_rule"
                  ></v-text-field>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-scroll-x-transition>
    <v-scroll-x-transition>
      <v-card flat v-if="show_new_calendar">
        <v-container class="pt-0">
          <v-row no-gutters class="pb-0 mb-0" justify="center">
            <!-- <v-col cols="12" md="1" align="start">
              <v-btn color="error" dense outlined small><v-icon>arrow_back</v-icon> Volver</v-btn>
            </v-col> -->
          <v-col cols="12" md="6" justify="center" align="center">
            <span style="font-size: 20px; color: #616161">Nuevo calendario</span
            >
          </v-col>
        </v-row>
          <v-row justify="center">
            <v-col cols="4" class="pb-0">
              <v-text-field
                v-model="new_calendar.name"
                label="Descripcion"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pb-0">
              <v-text-field
                v-model="new_calendar.date_from"
                label="Fecha inicio"
                type="date"
                outlined
                dense
                mask="##/##/####"
                :rules="date_rule"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pb-0">
              <v-text-field
                v-model="new_calendar.date_to"
                label="Fecha final"
                type="date"
                outlined
                dense
                mask="##/##/####"
                :rules="date_rule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row jutify="center">
            <v-col cols="12" md="12">
              <DataIterator
              v-if="show_new_calendar"
                :all_items="business_units"
                :items_selected="business_selected"
                :title="setTitle"
              />
            </v-col>
          </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12" md="3">
                  <v-autocomplete
                    v-model="calendar_copy"
                    :items="all_calendars"
                    item-text="name"
                    item-value="id" return-object
                    label="Calendarios"
                    outlined dense hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2" align="start">
                    <v-btn outlined color="primary" @click="copy_calendar" :disabled="!edit_calendar || all_calendars.length==0">
                      Copiar
                    </v-btn>
                </v-col>
                   <v-divider vertical></v-divider>
                   <v-col cols="12" md="3" align="end">
                    <v-btn color="success" @click="continue_edit_calendar()" outlined v-if="!edit_calendar">
                      Continuar
                    </v-btn>
                    <v-btn color="primary" @click="generate_calendar" outlined  v-if="edit_calendar">
                      Por defecto
                    </v-btn>
                   </v-col>
                   <v-col cols="12" md="2" align="end">
                    <v-btn color="error" @click="edit_calendar=true,show_new_calendar=false" outlined>
                      Cancelar
                    </v-btn>
                   </v-col>
              </v-row>
        </v-container>
      </v-card>
    </v-scroll-x-transition>
  </v-container>
</template>
<script>
import moment from "moment";
import DataIterator from "@/components/base/DataIterator";
export default {
  data: () => ({
    loading:false,
    edit_calendar:true,
    show_new_calendar: false,
    pending_save:false,
    search: null,
    headers: [
      {
        text: "#",
        align: "start",
        value: "accounting_period_id",
        width: "5%",
      },
      {
        text: "Periodo",
        align: "start",
        value: "period_name",
        width: "20%",
      },
      {
        text: "Mes",
        align: "start",
        value: "month",
        width: "10%",
      },
      {
        text: "Fecha inicio",
        value: "date_from",
        align: "center",
        width: "10%",
      },
      { text: "Fecha final", value: "date_to", align: "center",width: "10%", },
    ],
    items_calendar: [],
    all_calendars:[],
    items_periods: [],
    calendar_selected:null,
    calendar_copy:null,
    new_calendar: {
      name: null,
      date_from: null,
      date_to: null,
    },
    date_rule: [
      (v) => !!v || "Campo obligatorio!",
      (v) => !/^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}$/.test(v) || "Fecha no valida",
    ],
    setTitle: {
      all: "Unidades",
      selected: "Seleccionadas",
    },
    business_units: [],
    business_selected: [],
  }),
  components: {
    DataIterator,
  },
 // created() {},
  mounted() {
    this.initialized();
  },
  computed: {
    get_max_date_to(){
      return  moment(this.calendar_selected.date_to).format('YYYY-MM-DD');
    }
  },
 // watch: {},
  methods: {
    async initialized() {
      this.get_calendars();
      this.get_periods();
      this.business_units = await this.g_bussines();
      for (let key in this.business_units) {
        this.business_units[key].name =
          this.business_units[key].description_long;
      }
    },
    async get_calendars() {
      const calendars = await this.api_Get("accounting/get_calendars");
      this.all_calendars = await calendars.json();
      if(this.all_calendars.length==0){
        return
      }
      this.calendar_selected=this.all_calendars[0];
      this.items_calendar=this.calendar_selected.periods;
    },
    select_calendar(){
      //CAMBIA AL CALENDARIO SELECCIONADO
      this.items_calendar=this.calendar_selected.periods;
    },
    async get_periods() {
      const periods = await this.api_Get("accounting/accounting_periods");
      this.items_periods = await periods.json();
    },
    generate_calendar() {
      this.items_calendar = [];
      //TOMA EL INICIO DEL CALENDARIO Y VA GENERANDO LOS PERIODOS SUMANDO 1 MES
      let start_month = moment(this.new_calendar.date_from);
      for (let item of this.items_periods) {
        this.items_calendar.push({
          accounting_calendar_id:0,
          accounting_period_id: item.id,
          period_name: item.name,
          date_from: start_month.format("YYYY-MM-DD"),
          date_to: moment(start_month).endOf("month").format("YYYY-MM-DD"),
        });
        start_month.add(1,'month')
        start_month=start_month.startOf("month");
      }
      this.all_calendars.unshift({id:0,name:this.new_calendar.name,date_from:this.new_calendar.date_from,periods:this.items_calendar});
      //LO ENVIA AL CALENDARIO SELECCIONADO
      this.calendar_selected=this.all_calendars[0];
      this.show_new_calendar=false;
      this.pending_save=true;      
      this.edit_calendar=false;
    },
    set_date_from(item){  
      //CUANDO SE MODIFICA LA FECHA FINAL DE UN PERIODO ASIGNA LA MISMA FECHA +1 DIA A LA FECHA INICIAL DEL SIGUIENTE PERIODO  
      let index =this.items_calendar.indexOf(item);
      if(this.items_calendar.length==index+1){
        return
      }
      let set_date=moment(this.items_calendar[index].date_to);
      this.items_calendar[index+1].date_from=set_date.add(1, 'day').format('YYYY-MM-DD');
    },
   async add_calendar (){  
    //PREPARA LA DATA PARA GUARDAR EL CALENDARIO  
        let data ={
            accounting_calendar_id:null,
            name:this.new_calendar.name,
            date_from:this.new_calendar.date_from,
            date_to:this.new_calendar.date_to,
            is_closed:true,
            periods:this.items_calendar,
            business_units:[]
        }
        for(let item of this.business_selected){
          data.business_units.push({business_unit_id:item,is_closed:true})
        }
        await this.save_calendar(data);
        this.get_calendars();
        this.get_periods();
    },
    update_calendar(){
      //PREPARA LA DATA PARA ACTUALIZAR EL CALENDARIO
        let data ={
            accounting_calendar_id:this.calendar_selected.id,
            name:this.calendar_selected.name,
            date_from:this.calendar_selected.date_from,
            date_to:this.calendar_selected.date_to,
            is_closed:true,
            periods:this.calendar_selected.periods,
            business_units:this.calendar_selected.business_units
        }
        this.save_calendar(data);
    },
    async save_calendar(data){
      //ENVIA A LA BASE DE DATOS 
      try {
        this.loading=true;
        const response = await this.api_Post("accounting/add_or_update_calendar",data);
          if (response.status == 200) {
            //const datauser = await responseUser.json();
            this.$toast.success('Calendario registrado correctamente!');
            this.pending_save=false;
          }
  
      } catch (error) {
        console.error(error.message);
        this.$toast.error(error.message)
      }
      this.loading=false;
      this.edit_calendar=true;
    },
    copy_calendar(){
      //FALTA SETEAR DATOS AL CALENDARIO SELECCIONADO
      this.items_calendar=this.calendar_copy.periods;
      this.show_new_calendar=false;
      this.pending_save=true;
    },
    get_month(period){
      //COMPUTA LA DESCRIPCION DE LOS MESES
      moment.locale('es');
      let start_calendar = this.all_calendars.find((element)=>element.id===period.accounting_calendar_id);
      start_calendar=moment(start_calendar.date_from);
      if(period.accounting_period_id===1){
        return start_calendar.format('MMMM').toUpperCase()
      }
      start_calendar.add(period.accounting_period_id-1,'month');
      return start_calendar.format('MMMM').toUpperCase()
    },
    set_new_calendar_to_edit(){   
      //ENVIA LOS DATOS DEL CALENDARIO SELECCIONADO A LA VENTANA DE MODIFICACION
      this.new_calendar.name=this.calendar_selected.name;
      this.new_calendar.date_from=moment(this.calendar_selected.date_from).format('YYYY-MM-DD');
      this.new_calendar.date_to=moment(this.calendar_selected.date_to).format('YYYY-MM-DD');
      for(let item of this.calendar_selected.business_units){
        this.business_selected.push(item.business_unit_id)
      }
      this.edit_calendar=false;
      this.show_new_calendar = true;
    },
    continue_edit_calendar(){
      ///SALE DEL LA PANTALLA DE EDICION PARA MOSTRAR LOS PERIODOS Y SER EVALUADOS PARA GUARDAR
      this.calendar_selected.business_units=[];
      for(let item of this.business_selected){
        let exist = this.calendar_selected.business_units.find((element)=>element.business_unit_id==item);
        if(!exist){
          this.calendar_selected.business_units.push({business_unit_id:item,is_closed:true})
        }
        }
      this.calendar_selected.name=this.new_calendar.name;
      this.calendar_selected.date_from=this.new_calendar.date_from;
      this.calendar_selected.date_to=this.new_calendar.date_to;
      this.show_new_calendar=false;
    }
  },
};
</script>
