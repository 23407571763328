import Restaurant from "@/views/Restaurant/Restaurant.vue";
import TipCategories from "@/views/Restaurant/TipCategories.vue";
import Menu from "@/views/Restaurant/Menu.vue"
import CenterProduction from "@/views/Restaurant/CenterProduction.vue"
import PlaceTables from "@/views/Restaurant/PlaceTables.vue"

const RestRoute = {
  path: "/restaurant",
  name: "Restaurant",
  component: Restaurant,

  children: [
    {
      path: "type_categories",
      name: "TipCategories",
      component: TipCategories,
      meta: {
        SHOP: true,
      },
    },
    {
      path: "menu",
      name: "Menu",
      component: Menu,
      meta: {
        SHOP: true,
      },
    },
    {
      path: "center_production",
      name: "CenterProduction",
      component: CenterProduction,
      meta: {
        SHOP: true,
      },
    },
    {
      path: "places_tables",
      name: "PlaceTables",
      component: PlaceTables,
      meta: {
        SHOP: true,
      },
    }
  ],
}


export default RestRoute
