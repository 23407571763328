<template>
  <v-container fluid>
    <v-layout align-start>
      <v-flex>
        <v-card flat>
          <v-row no-gutters justify="center">
            <span style="font-size: 34px; color: #616161"><v-icon large class="pr-1 pb-2">rule</v-icon>Inventario</span>
          </v-row>
          <v-card-text>
            <HeaderFilters v-if="showHeader" @refreshWarehouse="getArticlesInWarehouse"
              :filter_default="defaultFilters" />
            <v-card outlined class="mt-2 pt-0">
              <v-card-text class="pa-0">
                <v-data-table :headers="headerArticle" :search="search" :items="getInventory">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Articulos</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <span>{{ articles.length }}</span>
                      <v-spacer></v-spacer>
                      <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar"
                        single-line hide-details>
                      </v-text-field>
                      <v-spacer></v-spacer>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" class="pb-2" color="primary"
                            @click="dialogFilters = true">filter_alt</v-icon>
                        </template>
                        <span>Filtros</span>
                      </v-tooltip>
                      <v-divider class="mx-3" inset vertical></v-divider>
                      <v-checkbox class="mt-4" v-model="showAll" label="Ver Articulos sin existencia">
                      </v-checkbox>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.item_image`]="{ item }">
                    <v-avatar size="40">
                      <v-img :src="item.item_image"></v-img>
                    </v-avatar>
                  </template>
                  <template v-slot:[`item.transform`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="primary" @click="transform(item)">
                          transform
                        </v-icon>
                      </template>
                      <span>Transformar articulo</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.show`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="blue" @click="viewHistory(item)">
                          pending_actions
                        </v-icon>
                      </template>
                      <span>Ver historico de este articulo</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <ArticleFilters @returnFilters="filtrar" @closeDialog="dialogFilters = false" :showDialog="dialogFilters" />

    <v-dialog v-model="dialogHistory" max-width="65%">
      <v-card>
        <v-card-title>
          <v-icon>pending_actions</v-icon>
          <span class="headline pr-2">Historico del articulo: {{ historyName }}</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ }">
              <v-btn icon @click="dialogHistory = false">
                <v-icon>clear</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table :headers="headerHistory" :search="searchHistory" :items="itemsHistory" height="60vh" fixed-footer
            fixed-header>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Registros</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <span>{{ itemsHistory.length }}</span>
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="searchHistory" append-icon="search" label="Buscar"
                  single-line hide-details>
                </v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.invoice_code`]="{ item }">
              <v-hover v-slot="{ hover }">
                <span @click="viewOrigin(1, item.invoice_code, '')"
                  class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }">
                  {{ item.invoice_code }}</span>
              </v-hover>
            </template>
            <template v-slot:[`item.adjustment_id`]="{ item }">
              <v-hover v-slot="{ hover }">
                <span @click="viewOrigin(2, item.adjustment_id, item.adjustment[0])"
                  class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }">
                  {{ item.adjustment_id }}</span>
              </v-hover>
            </template>
            <template v-slot:[`item.transfer_id`]="{ item }">
              <v-hover v-slot="{ hover }">
                <span @click="viewOrigin(3, item.transfer_id, item.transfer[0])"
                  class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }">
                  {{ item.transfer_id }}</span>
              </v-hover>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ArticleTransform v-if="dialogTransform" :showDialog="dialogTransform" :article="itemTransform"
      @closeDialog="closeTransform" />
  </v-container>
</template>
<script>
import HeaderFilters from "@/components/Inventory/HeaderFilters.vue";
import ArticleFilters from "@/components/Inventory/ArticleFilters.vue";
import ArticleTransform from "@/components/Inventory/ArticleTransform.vue";
import moment from "moment";
export default {
  data: () => ({
    dialogTransform: false,
    filters: {
      location: { catalogue_id: "", id: "" },
      business: "",
      warehouse: {
        id: null,
      },
    },
    defaultFilters: [],
    computeFilters: {
      unit: 0,
      item_type: 0,
      item_category: 0,
      account_category: 0,
      tax_type: 0,
      inventory_product: 0,
      negative_sale: 0,
    },
    dialogFilters: false,
    dialogAdjunct: false,
    dialogHistory: false,
    newQuantity: 0,
    articles: [],
    search: "",
    searchHistory: "",
    headerArticle: [
      {
        text: "",
        align: "start",
        value: "item_image",
        width: "5%",
      },
      { text: "ID", value: "item_id" },
      { text: "Articulo", value: "item_name" },
      { text: "Unidad de medida", value: "unit_name" },
      { text: "Tipo", value: "item_type_name" },
      { text: "Disponible", value: "quantity" },
      { text: "Transformar", value: "transform", sortable: false },
      { text: "Historico", value: "show", sortable: false },
    ],
    headerHistory: [
      { text: "Fecha", value: "effective_date" },
      { text: "Cantidad", value: "quantity" },
      { text: "Factura", value: "invoice_code", align: "center" },
      { text: "Ajuste", value: "adjustment_id", align: "center" },
      { text: "Transferencia", value: "transfer_id", align: "center" },
    ],
    itemsHistory: [],
    showAll: true,
    articleSelected: { item_name: "", available: 0 },
    historyName: "",
    itemTransform: {
      article_id: null,
      business_unit_id: null,
      location_id: null,
      warehouse_id: null,
    },
    showHeader: false,
  }),
  components: {
    HeaderFilters,
    ArticleFilters,
    ArticleTransform,
  },
  created() { },
  computed: {
    //computa los articulos en la tabla segun los filtros
    getInventory() {
      let articles = [];
      for (let item of this.articles) {
        if (this.validateFilter(item) == true) {
          continue;
        }
        let available = item.item_in_warehouses.filter(
          (element) => element.warehouse_id == this.filters.warehouse.id
        );
        let quantity_available = 0;
        if (available.length == 0) {
          continue;
        }
        if (available.length > 0) {
          quantity_available = available[0].quantity;
        }
        if (this.showAll == false && quantity_available == 0) {
          continue;
        }
        articles.push({
          item_id: item.item_id,
          item_name: item.item_name,
          unit_name: item.unit_name,
          item_type_name: item.item_type_name,
          quantity: quantity_available,
          inventory_product: item.inventory_product,
          negative_sale: item.negative_sale,
          item_image: item.item_image,
        });
      }
      return articles;
    },
  },
  watch: {},
  mounted() {
    if (this.$route.params.filtersDefault == undefined) {
      this.showHeader = true;
    }
    this.getArticlesInWarehouse();
  },
  methods: {
    async getArticlesInWarehouse(filters) {
      if (this.$route.params.filtersDefault != undefined) {
        this.filters = Object.assign({}, this.$route.params.filtersDefault);
        this.defaultFilters = [];
        this.defaultFilters.push(
          Object.assign({}, this.$route.params.filtersDefault)
        );
        this.$route.params.filtersDefault = undefined;
        if (
          this.filters.warehouse == undefined ||
          this.filters.warehouse.id == null
        ) {
          return;
        }
      } else {
        this.filters = filters;
        if (this.filters == undefined) {
          return;
        }
        if (
          this.filters.warehouse == undefined ||
          this.filters.warehouse.id == null
        ) {
          this.$toast.warning("Escoja un almacen!");
          return;
        }
      }

      const request = await this.api_Post("inventory/inventory_value", {
        date: this.filters.dateEnd,
        business_unit_id: this.filters.location.bus_id,
        location_id: this.filters.location.id,
      });
      const data = await request.json();
      if (request.status == 200) {
        let items = data;
        for (let item of items) {
          item.item_image = "image-not-aviable.jpg";
          if (
            item.item_image_url != "" &&
            item.item_image_url != null &&
            item.item_image_url != "null" &&
            item.item_image_url != undefined
          ) {
            item.item_image = "image-not-aviable.jpg";
            // item.item_image = await this.get_storage(
            //   "items_images",
            //   item.item_image_url
            // );
          }
        }
        this.articles = items;
      } else {
        this.$toast.error(data.error);
      }
      this.showHeader = true;
    },
    filtrar(filtros) {
      this.computeFilters = filtros;
      this.dialogFilters = false;
    },
    validateFilter(item) {
      if (
        this.computeFilters.account_category != 0 &&
        item.account_category_id != this.computeFilters.account_category
      ) {
        return true;
      }
      if (
        this.computeFilters.item_category != 0 &&
        item.item_category_id != this.computeFilters.item_category
      ) {
        return true;
      }
      if (
        this.computeFilters.item_type != 0 &&
        item.item_type_id != this.computeFilters.item_type
      ) {
        return true;
      }
      if (
        this.computeFilters.tax_type != 0 &&
        item.tax_type_id != this.computeFilters.tax_type
      ) {
        return true;
      }
      if (
        this.computeFilters.unit != 0 &&
        item.unit_id != this.computeFilters.unit
      ) {
        return true;
      }
      if (
        this.computeFilters.negative_sale == 1 &&
        item.negative_sale == false
      ) {
        return true;
      }
      if (
        this.computeFilters.negative_sale == 2 &&
        item.negative_sale == true
      ) {
        return true;
      }
      if (
        this.computeFilters.inventory_product == 1 &&
        item.inventory_product == false
      ) {
        return true;
      }
      if (
        this.computeFilters.inventory_product == 2 &&
        item.inventory_product == true
      ) {
        return true;
      }

      return false;
    },
    async viewHistory(itemArticle) {
      this.historyName = itemArticle.item_name;
      this.itemsHistory = [];
      const request = await this.api_Post("inventory/article_history", {
        item_id: itemArticle.item_id,
        warehouse_id: this.filters.warehouse.id,
      });
      const data = await request.json();
      if (request.status == 200) {
        for (let item of data) {
          this.itemsHistory.push({
            quantity: parseFloat(item.quantity).toFixed(2),
            effective_date: moment(item.effective_date).format(
              "DD/MM/YYYY HH:mm"
            ),
            invoice_code: item.invoice_code == null ? "-" : item.invoice_code,
            transfer_id: item.transfer_id == null ? "-" : item.transfer_id,
            transfer: item.transfer,
            adjustment_id:
              item.adjustment_id == null ? "-" : item.adjustment_id,
            adjustment: item.adjustment,
          });
        }
        this.dialogHistory = true;
      } else {
        this.$toast.error(data.error);
      }
    },
    viewOrigin(origin, code, item) {
      if (code == "-") {
        return;
      }
      if (origin == 1) {
        let route = this.$router.resolve({
          name: "ViewInvoice",
          params: { id: btoa(code) },
        });
        window.open(route.href);
      }
      if (origin == 2) {
        //go to adjustment
        this.$router.push({
          name: "Adjustments",
          params: {
            id: code,
            filtersDefault: this.filters,
            user: item.use_fullname,
            effective_date: item.effective_date,
          },
        });
      }
      if (origin == 3) {
        //go to transfer
        this.$router.push({
          name: "TransferRequest",
          params: {
            type: 1,
            id: code,
            filtersDefault: this.filters,
            user: item.use_fullname,
            effective_date: item.effective_date,
          },
        });
      }
    },
    transform(article) {
      if (article.quantity == 0 && article.negative_sale == false) {
        this.$toast.warning(
          "El articulo no cuenta con existencia para transformar!"
        );
        return;
      }
      this.itemTransform = {
        article_id: article.item_id,
        business_unit_id: this.filters.business,
        location_id: this.filters.location.id,
        warehouse_id: this.filters.warehouse.id,
        quantity_available: article.quantity,
        inventory_product: article.inventory_product,
        negative_sale: article.negative_sale,
      };
      this.dialogTransform = true;
    },
    closeTransform(value) {
      if (value == true) {
        this.getArticlesInWarehouse(this.filters);
      }
      this.dialogTransform = false;
    },
  },
};
</script>
