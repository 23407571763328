var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"myFont",attrs:{"id":"inspire"}},[(_vm.loggedIn.authStatus)?_c('v-navigation-drawer',{attrs:{"hide-overlay-scroll":true,"mini-variant-width":_vm.mini ? 65 : 120,"app":"","permanent":"","expand-on-hover":_vm.mini,"mini-variant":_vm.mini},on:{"click":function($event){_vm.mini = false},"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}}},[_c('div',{},[_c('v-img',{attrs:{"transition":"scroll-x-transition","max-height":64,"src":require("@/assets/imgbar2.jpg")}})],1),_c('div',[_c('perfect-scrollbar',{staticClass:"scrollnavbar"},[_c('v-divider'),[_c('v-list-item',{attrs:{"to":{
            name: 'Home',
          }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-home-outline")])],1),_c('v-list-item-title',[_vm._v("Inicio")])],1)],(_vm.request_access(3).isActive)?_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-group',{attrs:{"active-class":"deep-primary--text text--accent-4 "},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-archive-outline")])],1),_c('v-list-item-title',[_vm._v("Inventario")])]},proxy:true}],null,false,1779061494)},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-group',{attrs:{"active-class":"primary--text text--accent-5 "},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-icon',{staticClass:"mx-3 mr-1 ml-1"},[_vm._v("widgets")]),_c('v-list-item-title',{attrs:{"title":"Gestion de producto"}},[_vm._v("Gestion de productos")])]},proxy:true}],null,false,1135186231)},[[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'Articles',
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3',attrs:{"small":""}},[_vm._v("local_mall")]),_c('v-list-item-subtitle',[_vm._v("Productos y servicios")])],1)],[[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                      name: 'ArticleConversions',
                    }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3',attrs:{"small":""}},[_vm._v("transform")]),_c('v-list-item-subtitle',[_vm._v("Conversiones")])],1)],_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'InventoryManagement',
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3',attrs:{"small":""}},[_vm._v("rule")]),_c('v-list-item-subtitle',[_vm._v("Inventario")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'Adjustments',
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3',attrs:{"small":""}},[_vm._v("autorenew")]),_c('v-list-item-subtitle',[_vm._v("Ajustes")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'TransferRequest',
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3',attrs:{"small":""}},[_vm._v("sync_alt")]),_c('v-list-item-subtitle',[_vm._v("Transferencias")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'Approvals',
                    params: {
                      type: '1',
                    },
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3',attrs:{"small":""}},[_vm._v("task_alt")]),_c('v-list-item-subtitle',[_vm._v("Aprobaciones")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'TransferReceivedt',
                    params: {
                      type: '2',
                    },
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3',attrs:{"small":""}},[_vm._v("archive")]),_c('v-list-item-subtitle',[_vm._v("Recepcion")])],1)]],2)],1),_c('v-list-item',{attrs:{"to":{
              name: 'Assets',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("loyalty")]),_c('v-list-item-subtitle',[_vm._v("Activos fijos")])],1),_c('v-list-item',{attrs:{"to":{
              name: 'Warehouse',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("store")]),_c('v-list-item-subtitle',[_vm._v("Almacenes")])],1),_c('v-list-item',{attrs:{"to":{
              name: 'Category',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("category")]),_c('v-list-item-subtitle',[_vm._v("Categorias")])],1),_c('v-list-item',{attrs:{"to":{
              name: 'Catalogue',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("view_list")]),_c('v-list-item-subtitle',[_vm._v("Catalogos")])],1),_c('v-list-item',{attrs:{"to":{
              name: 'Menu',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("restaurant_menu")]),_c('v-list-item-subtitle',[_vm._v("Menu")])],1),_c('v-list-item',{attrs:{"to":{
              name: 'Variant',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("checklist_rtl")]),_c('v-list-item-subtitle',[_vm._v("Variantes")])],1)],1)],1):_vm._e(),(_vm.request_access(2).isActive)?_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-group',{attrs:{"active-class":"deep-primary--text text--accent-4"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-hand-coin-outline")])],1),_c('v-list-item-title',[_vm._v("Ingresos")])]},proxy:true}],null,false,473516242)},[_c('v-list-item',{attrs:{"to":{
              name: 'InvoiceSales',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("receipt")]),_c('v-list-item-subtitle',[_vm._v("Factura de venta")])],1),_c('v-list-item',{attrs:{"to":{
              name: 'CreditNotes',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("receipt")]),_c('v-list-item-subtitle',[_vm._v("Notas de credito")])],1),_c('v-list-item',{attrs:{"to":{ name: 'Prepaid' }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("paid")]),_c('v-list-item-subtitle',[_vm._v("Anticipo")])],1)],1)],1):_vm._e(),(_vm.request_access(5).isActive)?_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-group',{attrs:{"active-class":"deep-primary--text text--accent-4"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("add_card")])],1),_c('v-list-item-title',[_vm._v("Cuenta por cobrar")])]},proxy:true}],null,false,1084467439)},[_c('v-list-item',{attrs:{"to":{
              name: 'PaymentsReceived',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("attach_money")]),_c('v-list-item-subtitle',[_vm._v("Cobros")])],1),_c('v-list-item',{attrs:{"to":{
              name: 'PaymentsReceived',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("account_balance_wallet")]),_c('v-list-item-subtitle',[_vm._v("Deposito directo")])],1)],1)],1):_vm._e(),_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.request_access(1).isActive),expression:"request_access(1).isActive"}],attrs:{"nav":"","dense":""}},[_c('v-list-group',{attrs:{"active-class":"deep-primary--text text--accent-4"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-cart-outline")])],1),_c('v-list-item-title',[_vm._v("Compras")])]},proxy:true}],null,false,3214879642)},[((_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===17|| (_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===19)?_c('v-list-item',{attrs:{"to":{
              name: 'PurchaseRequest',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("add_shopping_cart")]),_c('v-list-item-subtitle',[_vm._v("Solicitudes de compra")])],1):_vm._e(),((_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===17|| (_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===20)?_c('v-list-item',{attrs:{"to":{
              name: 'Quotes',
            }}},[_c('v-icon',{staticClass:"mx-5"},[_vm._v("edit_note")]),_c('v-list-item-subtitle',[_vm._v("Cotizar")])],1):_vm._e(),((_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===17|| (_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===20)?_c('v-list-item',{attrs:{"to":{
              name: 'QuotesGroup',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("list")]),_c('v-list-item-subtitle',[_vm._v("Cotizaciones")])],1):_vm._e(),((_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===17|| (_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===20)?_c('v-list-item',{attrs:{"to":{
              name: 'PurchaseOrder',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("shopping_bag")]),_c('v-list-item-subtitle',[_vm._v("Ordenes de compra")])],1):_vm._e(),((_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===17|| (_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===21 || (_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===22)?_c('v-list-item',{attrs:{"to":{
              name: 'Approved',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("task_alt")]),_c('v-list-item-subtitle',[_vm._v("Aprobaciones compra")])],1):_vm._e(),((_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===17|| (_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===23)?_c('v-list-item',{attrs:{"to":{
              name: 'Receptions',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("archive")]),_c('v-list-item-subtitle',[_vm._v("Recepcion ordenes")])],1):_vm._e(),((_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===17|| (_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===24)?_c('v-list-item',{attrs:{"to":{
              name: 'DebitNotes',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("receipt")]),_c('v-list-item-subtitle',[_vm._v("Notas de debito")])],1):_vm._e(),((_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===17|| (_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===24)?_c('v-list-item',{attrs:{"to":{
              name: 'InvoiceShop',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("receipt")]),_c('v-list-item-subtitle',[_vm._v("Factura de provedores")])],1):_vm._e(),((_vm.request_access(1).data.length>0? _vm.request_access(1).data[0].access.acces_profile:0)===17)?_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-group',{attrs:{"active-class":"deep-primary--text text--accent-4"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("shopping_cart")]),_c('v-list-item-title',[_vm._v("Mantenimientos")])]},proxy:true}],null,false,3442136651)},[[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'Applicants',
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("assignment_ind")]),_c('v-list-item-subtitle',[_vm._v("Solicitantes")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'Approvers',
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("group")]),_c('v-list-item-subtitle',[_vm._v("Aprobadores")])],1)]],2)],1):_vm._e()],1)],1),(_vm.request_access(6).isActive)?_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-group',{attrs:{"active-class":"deep-primary--text text--accent-4"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("add_card")])],1),_c('v-list-item-title',[_vm._v("Cuenta por pagar")])]},proxy:true}],null,false,2585694533)},[_c('v-list-item',{attrs:{"to":{
              name: 'PaymentsCxp',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("add_shopping_cart")]),_c('v-list-item-subtitle',[_vm._v("Pagos")])],1),_c('v-list-item',{attrs:{"to":{
              name: 'TransactionsCxP',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("sync_alt")]),_c('v-list-item-subtitle',[_vm._v("Transaciones")])],1)],1)],1):_vm._e(),(_vm.request_access(8).isActive)?_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-group',{attrs:{"active-class":"deep-primary--text text--accent-4"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("warehouse")])],1),_c('v-list-item-title',[_vm._v("Restaurant")])]},proxy:true}],null,false,3589124711)},[_c('v-list-item',{attrs:{"to":{
              name: 'TipCategories',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("category")]),_c('v-list-item-subtitle',[_vm._v("Tipo categorias")])],1),_c('v-list-item',{attrs:{"to":{
              name: 'CenterProduction',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("production_quantity_limits")]),_c('v-list-item-subtitle',[_vm._v("Centros producion")])],1),_c('v-list-item',{attrs:{"to":{
              name: 'PlaceTables',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("table_chart")]),_c('v-list-item-subtitle',[_vm._v("Areas y mesas")])],1)],1)],1):_vm._e(),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-group',{attrs:{"active-class":"deep-primary--text text--accent-4 "},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-account-group-outline")])],1),_c('v-list-item-title',[_vm._v("Contactos")])]},proxy:true}],null,false,982054784)},[_c('v-list-item',{attrs:{"to":{
              name: 'Contacts',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("groups")]),_c('v-list-item-subtitle',[_vm._v("Todos")])],1),[_c('v-list-item',{attrs:{"to":{
                name: 'Clients',
              }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("supervisor_account")]),_c('v-list-item-subtitle',[_vm._v("Clientes")])],1)],[_c('v-list-item',{attrs:{"to":{
                name: 'Suppliers',
              }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("engineering")]),_c('v-list-item-subtitle',[_vm._v("Suplidores")])],1)]],2)],1),(_vm.request_access(4).isActive)?_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-group',{attrs:{"active-class":"deep-primary--text text--accent-4"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{class:_vm.mini ? 'mr-2' : 'mr-2'},[_c('v-icon',[_vm._v("mdi-calculator-variant-outline")])],1),_c('v-list-item-title',[_vm._v("Contabilidad")])]},proxy:true}],null,false,451952687)},[_c('v-list-item',{attrs:{"to":{
              name: 'Accounts',
            }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("account_balance_wallet")]),_c('v-list-item-subtitle',[_vm._v("Catálogo de cuentas")])],1),[_c('v-list-item',{attrs:{"to":{
                name: 'EntryDaily',
              }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("history_edu")]),_c('v-list-item-subtitle',[_vm._v("Entradas de diario")])],1)],[_c('v-list-item',{attrs:{"to":{
                name: 'DiaryBook',
              }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("menu_book")]),_c('v-list-item-subtitle',[_vm._v("Libro diario")])],1)],[_c('v-list-item',{attrs:{"to":{
                name: 'CategoryAccounts',
              }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-5'},[_vm._v("category")]),_c('v-list-item-subtitle',[_vm._v("Categoria de cuentas")])],1)],_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-group',{attrs:{"active-class":"deep-primary--text text--accent-4"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("mdi-calculator-variant-outline")]),_c('v-list-item-title',[_vm._v("Mantenimientos")])]},proxy:true}],null,false,3355479298)},[[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'VouchersVue',
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("menu")]),_c('v-list-item-subtitle',[_vm._v(" Comprobantes")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'Withholdings',
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("front_hand")]),_c('v-list-item-subtitle',[_vm._v("Retenciones")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'Discounts',
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("local_atm")]),_c('v-list-item-subtitle',[_vm._v("Descuentos")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'Itbis',
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("volunteer_activism")]),_c('v-list-item-subtitle',[_vm._v("Itbis")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'CategoriesDGII',
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("category")]),_c('v-list-item-subtitle',[_vm._v("Categorias DGII")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                    name: 'ProjectAccounting',
                  }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("home")]),_c('v-list-item-subtitle',[_vm._v("Categorias")])],1)]],2)],1)],2)],1):_vm._e(),(_vm.$store.state.auth.user.user.permition_level == 99)?_c('v-list',[_c('v-list-group',{attrs:{"active-class":"deep-primary--text text--accent-4"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-cog-outline")])],1),_c('v-list-item-title',[_vm._v("Mantenimientos")])]},proxy:true}],null,false,2181527831)},[[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                name: 'User',
              }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("people")]),_c('v-list-item-subtitle',[_vm._v(" Usuarios")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                name: 'BussinesUnit',
              }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("corporate_fare")]),_c('v-list-item-subtitle',[_vm._v(" Unidad de negocios")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                name: 'GroupMoney',
              }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("monetization_on")]),_c('v-list-item-subtitle',[_vm._v(" Grupo de moneda")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                name: 'ChangeMoney',
              }}},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("currency_exchange")]),_c('v-list-item-subtitle',[_vm._v(" Cambio de moneda")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                name: 'Projects',
              }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("mdi-home-city-outline")])],1),_c('v-list-item-title',[_vm._v("Proyectos")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                name: 'Boxes',
              }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("point_of_sale")])],1),_c('v-list-item-title',[_vm._v("Cajas")])],1)],[_c('v-list-item',{class:_vm.mini ? '' : 'mx-1',attrs:{"to":{
                name: 'Calendar',
              }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{class:_vm.mini ? '' : 'mx-3'},[_vm._v("edit_calendar")])],1),_c('v-list-item-title',[_vm._v("Calendario")])],1)]],2)],1):_vm._e(),[_c('v-list-item',{attrs:{"to":{
            name: 'UserPerfil',
          }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-smart-card-outline")])],1),_c('v-list-item-title',[_vm._v("Mi perfil")])],1)],_c('v-list-item',{on:{"click":function($event){return _vm.salir()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" logout ")])],1),_c('v-list-item-title',[_vm._v("Cerrar sesión")])],1)],2)],1)]):_vm._e(),(_vm.loggedIn.authStatus)?_c('v-app-bar',{staticClass:"d-print-none",attrs:{"color":"primary","flat":"","app":""}},[_c('v-app-bar-nav-icon',{staticClass:"mx-2",on:{"click":function($event){_vm.mini = !_vm.mini}}},[_c('v-icon',{attrs:{"color":"white "}},[_vm._v("view_headline")])],1),_c('v-toolbar-title',[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" BILL BOSS")])]),_c('v-spacer'),_c('v-col',{attrs:{"md":"2"}}),(_vm.loggedIn)?_c('span',{staticStyle:{"color":"white"}},[_c('span',{staticClass:"mx-2",staticStyle:{"color":"white"}},[_vm._v(" Hola, "+_vm._s(_vm.$store.state.auth.user.user.fullname.split(" ")[0])+" ")])]):_vm._e(),_c('v-avatar',{key:_vm.$vuetify.theme.themes.light.colorbarra,staticClass:"mx-2",attrs:{"title":"Ver perfil","size":"45"}},[_c('v-img',{key:_vm.$vuetify.theme.themes.light.colorbarra,attrs:{"src":_vm.image_profile}})],1)],1):_vm._e(),_c('v-main',{style:(_vm.loggedIn.authStatus == true ? 'background-color: #f6f6f6' : '')},[_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[_c('v-container',{staticClass:"page-wrapper",attrs:{"fluid":""}},[_c('div',{class:_vm.loggedIn.authStatus == false ? '' : 'maxWidth'},[_c('router-view')],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }