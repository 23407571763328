<template>
  <v-flex>
    <div>
      <v-row justify="end">
        <v-btn icon dark @click="close">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-row>
    </div>
    <div v-if="!loadingApp">
      <div v-if="!loading">
        <v-row justify="center" class="mt-n16">
          <v-icon class="mx-2" size="35px">receipt_long</v-icon>
          <span style="font-size: 30px; color: #616161"
            >{{ formTitle }} {{ invoice_number }}
          </span>
        </v-row>
        <v-row class="mt-0" justify="center">
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete
              :disabled="type === 3 || type === 4"
              outlined
              dense
              v-model="filters.business"
              :items="itemsBussines"
              label="Unidad de negocio"
              color="cyanText"
              :readonly="disableInput"
              @change="loadLogo"
              return-object
              prepend-inner-icon="corporate_fare"
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete
              :disabled="type === 3 || type === 4"
              outlined
              dense
              v-model="filters.location"
              :items="
                filters.business != null
                  ? itemsLocations.filter(
                      (element) => element.bus_id == filters.business.value
                    )
                  : []
              "
              return-object
              :readonly="disableInput"
              @change="updateWarehouse"
              color="cyanText"
              label="Location"
              prepend-inner-icon="corporate_fare"
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete
              :disabled="type === 3 || type === 4"
              :readonly="disableInput"
              outlined
              dense
              v-model="filters.department_id"
              :items="itemsDepartment"
              return-object
              color="cyanText"
              label="Departamento"
              prepend-inner-icon="corporate_fare"
              required
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters justify="center">
          <v-col cols="12" md="12" lg="12">
            <div class="folded_corner">
              <v-card elevation="0">
                <v-card-text>
                  <v-row class="mt-1" justify="center">
                    <v-col cols="12" md="3">
                      <v-row dense justify="center" class="ml-4 mt-n3" no-gutters>
                        <span>Moneda</span>
                      </v-row>
                      <v-row dense no-gutters justify="center">
                        <v-btn-toggle
                          v-model="seleted_currencies"
                          class="rounded-lg mr-2"
                          mandatory
                          color="primary"
                        >
                          <v-btn
                            :disabled="in_invoice_id != null || type == 3 || type == 4"
                            v-for="(item, i) of currencies"
                            :key="i"
                            :value="item.id"
                            small
                          >
                            {{ item.code }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-row>
                      <v-row dense no-gutters class="mb-n9 mt-1" justify="center">
                        <v-col cols="8">
                          <v-text-field
                            :disabled="in_invoice_id != null || type == 3 || type == 4"
                            v-if="seleted_currencies"
                            v-model="currency_rate"
                            outlined
                            dense
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-row>
                          <v-img
                            contain
                            width="100px"
                            height="100px"
                            :src="locationImage"
                          ></v-img>
                        </v-row>
                        <v-row>
                          <SearchReceptions
                            v-if="type === 1"
                            @selected-item="viewReceptions($event)"
                          />
                          <SearchInvoice
                            v-if="type === 3 || type === 4"
                            @selected-item="viewInvoiceSelected($event)"
                            :dialogSearchInvoice="dialogSearchInvoice"
                          />
                        </v-row>
                        <v-row class="mt-2 ml-2" justify="center" v-if="type === 1">
                          <v-col cols="8">
                            <v-text-field
                              dense
                              prefix="#"
                              outlined
                              v-model="supplier_invoice_number"
                              label="factura suplidor"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row
                          class="mt-n5 ml-2"
                          justify="center"
                          v-if="type === 1 && tipComprobante"
                        >
                          <v-col cols="8">
                            <v-text-field
                              v-if="tipComprobante.id === 0"
                              dense
                              prefix="#"
                              outlined
                              v-model="supplier_voucher_edit"
                              label="Comprobante suplidor"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row v-if="selected_invoices.length != 0" justify="end">
                          <span class="ml-15"
                            >Factura: #{{ selected_invoices.invoice_number }}</span
                          >
                        </v-row>
                        <v-row v-if="selected_invoices.length != 0" justify="end">
                          <span class="ml-15 mb-5"
                            >NFC: #{{ selected_invoices.invoice_ncf }}</span
                          >
                        </v-row>
                        <v-row v-if="receptions.length != 0" justify="end">
                          <span class="ml-15"
                            >Recepción:
                            {{ filLeft(receptions.entry_id.toString(), 12, "0") }}</span
                          >
                        </v-row>
                        <v-row v-if="receptions.length != 0" justify="end">
                          <span class="ml-15"
                            >Orden:
                            {{ filLeft(receptions.order_id.toString(), 12, "0") }}</span
                          >
                        </v-row>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row justify="center">
                        <span
                          v-if="filters.business != null"
                          style="font-size: 20px; color: #616161"
                          >{{ filters.business.text }}</span
                        >
                      </v-row>

                      <v-row justify="center">
                        <span
                          v-if="filters.location != null"
                          style="font-size: 14px; color: #616161"
                          >{{ filters.location.text }}</span
                        >
                      </v-row>
                      <v-row justify="center">
                        <span style="font-size: 14px; color: #616161"> </span>
                      </v-row>
                      <v-row justify="center">
                        <span style="font-size: 14px; color: #616161"></span>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-row dense no-gutters justify="start">
                        <v-col cols="12">
                          <v-autocomplete
                            :readonly="type === 3"
                            class="mr-9"
                            outlined
                            dense
                            v-model="tipComprobante"
                            :items="allNfc"
                            item-text="name"
                            item-value="id"
                            label="Tipo NCF"
                            return-object
                            color="cyanText"
                            required
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row dense class="mt-n2" no-gutters justify="start">
                        <v-col cols="12">
                          <v-autocomplete
                            v-show="type === 1 || type === 2"
                            class="mr-9"
                            outlined
                            dense
                            ref="tipFac"
                            v-model="selected_invoice_categories"
                            :items="invoice_categories"
                            item-text="name"
                            item-value="id"
                            label="Tipo de factura"
                            color="cyanText"
                            required
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-divider class="mr-10 mt-1 mb-1" inset></v-divider>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" md="6">
                      <v-row no-gutters justify="center">
                        <v-col cols="12" md="7">
                          <v-text-field
                            :value="selectedEntity != null ? selectedEntity.name : ''"
                            dense
                            outlined
                            readonly
                            label="Cliente"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <searchClients
                            :types_clients="type"
                            @selected-item="selectEntity($event)"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="mt-n4" no-gutters justify="center">
                        <v-col cols="12" md="10">
                          <v-text-field
                            :value="
                              selectedEntity != null ? selectedEntity.document_id : ''
                            "
                            dense
                            outlined
                            readonly
                            label="RNC o Cédula"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <div v-if="type === 1 || type === 2">
                        <v-row
                          v-if="prepaids.length > 0"
                          class="mt-n4"
                          no-gutters
                          justify="center"
                        >
                          <v-col cols="12" md="10">
                            <v-row>
                              <v-btn
                                @click="dialogPrepaid = true"
                                outlined
                                color="primary"
                                class="mt-3"
                                >Agregar anticipo</v-btn
                              >
                              <!-- <v-checkbox class="ml-3" label="Agregar anticipo"></v-checkbox> -->
                              <span class="mx-4 mt-5 font-weight-bold green--text"
                                >Disponible ${{ totalPrepaid(prepaids) }}
                                <template v-if="seleted_currencies != null">
                                  {{
                                    currencies.filter(
                                      (element) => element.id == seleted_currencies
                                    )[0].code
                                  }}
                                </template></span
                              >
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>

                      <v-row
                        v-if="
                          this.selectedEntity.entity_branches &&
                          this.selectedEntity.entity_branches.length != 0
                        "
                        class="mt-n4"
                        no-gutters
                        justify="center"
                      >
                        <v-col cols="12" md="10">
                          <v-autocomplete
                            :disabled="type === 3 || type === 4"
                            dense
                            outlined
                            v-model="selectedBranche"
                            :items="selectedEntity.entity_branches"
                            item-value="id"
                            item-text="name"
                            color="cyanText"
                            label="Sucursal "
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row justify="end">
                        <v-col cols="12" md="6" lg="7">
                          <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="10"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :disabled="type === 3 || type === 4"
                                class="mx-8"
                                dense
                                outlined
                                v-model="date"
                                label="Fecha"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date"
                              @input="menu = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row class="mt-n10" justify="end">
                        <v-col cols="12" md="6" lg="7">
                          <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="10"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :disabled="type === 3 || type === 4"
                                class="mx-8"
                                dense
                                outlined
                                v-model="date"
                                label="Fecha contable"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date"
                              @input="menu = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>

                      <v-row no-gutters class="mt-n6" justify="end">
                        <v-col cols="12" md="6" lg="7">
                          <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="10"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :disabled="type === 3 || type === 4"
                                class="mx-8"
                                dense
                                outlined
                                v-model="date3"
                                label="fecha Vencimiento"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date3"
                              @input="menu3 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mt-n4 mr-8" justify="end">
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            :disabled="type === 3 || type === 4"
                            dense
                            outlined
                            v-model="selectedCreditDays"
                            :items="credit_days"
                            item-text="name"
                            item-value="id"
                            color="cyanText"
                            label="Plazo de pago cliente"
                            prepend-inner-icon="mdi-card-account-details-outline"
                            required
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-card-text>
                    <v-container>
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        dense
                        :search="searchProduct"
                        hide-default-footer
                        :items-per-page="items.length"
                      >
                        <template v-slot:[`item.id`]="{ item }">
                          <span>{{ item.id }}</span>
                        </template>
                        <template v-slot:[`item.item_name`]="{ item }">
                          <span>{{ item.item_name }}</span>
                        </template>
                        <template v-slot:[`item.reference`]="{ item }">
                          <v-text-field
                            :readonly="disableInput"
                            hide-details
                            v-model="item.reference"
                            dense
                          >
                          </v-text-field>
                        </template>
                        <template v-slot:[`item.unit`]="{ item }">
                          <span>{{ item.unit }}</span>
                        </template>

                        <template v-slot:[`item.discount`]="{ item }">
                          <v-text-field
                            :readonly="disableInput"
                            prefix="%"
                            v-model="item.discount"
                            dense
                            hide-details
                          >
                          </v-text-field>
                        </template>

                        <template v-slot:[`item.itbis`]="{ item }">
                          <v-select
                            outlined
                            dense
                            :readonly="disableInput"
                            v-model="item.itbis"
                            :items="itbis"
                            item-text="name"
                            item-value="id"
                            label="itbis"
                            return-object
                            hide-details
                            single-line
                          ></v-select>
                        </template>
                        <template v-slot:[`item.quantity`]="{ item }">
                          <v-text-field
                            outlined
                            :readonly="disableInput"
                            v-model="item.quantity"
                            type="number"
                            :min="1"
                            :value="1"
                            dense
                            hide-details
                          >
                          </v-text-field>
                        </template>
                        <template v-slot:[`item.amount`]="{ item }">
                          <v-text-field
                            outlined
                            prefix="$"
                            :value="
                              type === 3 || type === 4
                                ? item.amount
                                : item.amount * currency_rate_selected
                            "
                            dense
                            hide-details
                          >
                          </v-text-field>
                        </template>
                        <template v-slot:[`item.quantity_return`]="{ item }">
                          <v-text-field
                            outlined
                            v-model="item.quantity_return"
                            :max="item.quantity"
                            type="number"
                            :min="1"
                            :value="item.quantity"
                            dense
                            hide-details
                          >
                          </v-text-field>
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                          <span v-if="item.itbis">
                            {{
                              currencyFormat(
                                type === 3 || type === 4
                                  ? (parseFloat(item.amount) *
                                      parseFloat(item.quantity_return) +
                                      item.itbis.value *
                                        parseFloat(item.amount) *
                                        parseFloat(item.quantity_return)) *
                                      currency_rate_selected
                                  : (parseFloat(item.amount) * parseFloat(item.quantity) +
                                      item.itbis.value *
                                        parseFloat(item.amount) *
                                        parseFloat(item.quantity)) *
                                      currency_rate_selected
                              )
                            }}
                          </span>
                          <span v-else> - </span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-row>
                            <ArticleAccount
                              :items="items"
                              :type="type"
                              :currency_rate_selected="currency_rate"
                              :item="item"
                            />

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  @click="deleteProducts(item)"
                                  color="red"
                                  class="mt-n2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  close
                                </v-icon>
                              </template>
                              <span>Eliminar</span>
                            </v-tooltip>
                          </v-row>
                        </template>
                        <template v-slot:footer>
                          <div>
                            <Footer
                              :type="type"
                              :disableInput="disableInput"
                              :selected_withholdings="selected_withholdings"
                              @dialogWithholdings="dialogWithholdings = true"
                              @showDialogPayments="showDialogPayments()"
                              @showItems="showItems"
                              @showVouchers="showVouchers"
                              @delete_withholdings="delete_withholdings($event)"
                              @deletePayment="deletePayment()"
                              :selectedPayments="selectedPayments"
                              :nota="nota"
                              :totales="totales"
                              :seleted_currencies="seleted_currencies"
                              :currencies="currencies"
                            />
                          </div>
                        </template>
                      </v-data-table>
                    </v-container>
                  </v-card-text>
                  <v-row class="mt-0 mb-4" align="center" justify="space-around">
                    <v-spacer></v-spacer>
                    <v-btn @click="close" class="mx-3" outlined> Cancelar </v-btn>
                    <v-btn @click="save" color="primary"> Guardar </v-btn>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row justify="center">
          <v-col cols="12" md="10">
            <v-skeleton-loader
              type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
            >
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>Cagando...</div>
    <v-dialog width="50%" v-model="dialogWithholdings">
      <v-card class="rounded-xl" width="100%" height="100%">
        <v-toolbar flat dark color="transparent">
          <span class="font-weight-semibold" style="color: black; font-size: 2vh"
            >Agregar retencion</span
          >
          <v-spacer></v-spacer>
          <v-btn @click="dialogWithholdings = false" icon dark>
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-row justify="center">
            <v-col cols="6">
              <v-autocomplete
                outlined
                dense
                v-model="selected_withholdings_push"
                :items="withholdings"
                item-text="name"
                item-value="id"
                label="RETENCIONES"
                return-object
                color="cyanText"
                required
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-btn @click="add_withholdings" outlined>Agregar</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="50%" v-model="dialogPrepaid">
      <v-card class="rounded-xl" width="100%" height="100%">
        <v-toolbar flat dark color="transparent">
          <span class="font-weight-semibold" style="color: black; font-size: 2vh"
            >Agregar anticipo</span
          >
          <v-spacer></v-spacer>
          <v-btn @click="dialogPrepaid = false" icon dark>
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-row justify="center" v-if="dialogPrepaid">
            <v-col
              cols="12"
              v-for="item of prepaids.filter((element) => element.consumed == false)"
            >
              <v-checkbox
                class="ml-3"
                :label="item.amount + ` ` + item.currency"
                v-model="item.selected"
              ></v-checkbox>

              <!-- <v-text-field type="number"
                :max="prepaids.reduce((total, item) => total + parseFloat(item.amount * currency_rate_selected), 0)"
                outlined dense v-model="amount_prepaid" :items="withholdings" label="Total" color="cyanText"
                required></v-text-field> -->
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn @click="dialogPrepaid = false" outlined>Cancelar</v-btn>
            <v-btn @click="dialogPrepaid = false" color="primary">Guardar</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="50%" v-model="dialogPayments">
      <payments
        @close-payments="dialogPayments = false"
        :amount="parseFloat(totales.total_amount)"
        @selected-payments="selectPayment($event)"
      />
    </v-dialog>
    <v-dialog v-if="filters.location != null" width="80%" v-model="dialogItems">
      <articles_catalogue
        v-if="refresArticles"
        :type="type"
        :removeItemSelected="false"
        :currencie_rate="currency_rate"
        @selected-item="addItem($event)"
        :data="{
          location: filters.location.id,
          business: filters.business.id,
          warehouse: filters.warehouse_id,
          catalogue: filters.location.catalogue_id,
        }"
        @close-item="dialogItems = false"
      />
    </v-dialog>
    <v-dialog v-model="dialogVouchers" width="70%">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer> <v-icon @click="dialogVouchers = false">close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selectedVouchers"
            :headers="headersVouchers"
            :items="itemsVouchers"
            item-key="invoice_id"
            show-select
            dense
            :search="searchVouchers"
            :items-per-page="itemsVouchers.length"
          >
            <template v-slot:top>
              <v-toolbar class="elevation-0 rounded-lg">
                <v-toolbar-title>Vouchers</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  label="Buscar..."
                  class="mt-6"
                  v-model="searchVouchers"
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain color="error">Cancelar</v-btn>
          <v-btn outlined color="primary">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import moment from "moment";
import searchClients from "./SearchClientOrSupplier.vue";
import SearchInvoice from "./SearchInvoice.vue";
import payments from "./Payments.vue";
import articles_catalogue from "../Inventory/ArticlesWarehouse.vue";
import SearchReceptions from "@/components/Shop/SearchReceptions.vue";
import ArticleAccount from "./ArticleAccount.vue";
import Footer from "./Footer.vue";
export default {
  components: {
    Footer,
    searchClients,
    payments,
    articles_catalogue,
    SearchReceptions,
    SearchInvoice,
    ArticleAccount,
  },
  data: () => ({
    selectedVouchers: [],
    prepaids: [],
    amount_prepaid: 0,
    dialogPrepaid: false,
    loading: false,
    in_invoice_id: null,
    invoice_number: "",
    dialogSearchInvoice: false,
    date: moment().format("YYYY-MM-DD"),
    date2: moment().startOf("month").format("YYYY-MM-DD"),
    date3: moment().startOf("month").format("YYYY-MM-DD"),
    request_id: null,
    currency_rate: 0,
    tab: null,
    searchVouchers: "",
    itemsVouchers: [],
    headersVouchers: [
      //{ text: "Entidad", value: "entity", width: "12%" },
      { text: "Factura", value: "invoice_number" },
      { text: "Cliente", value: "entity_name" },
      { text: "Total", value: "total_amount" },

      { text: "Actions", value: "actions", width: "5%" },
    ],
    headers_account: [
      //{ text: "Entidad", value: "entity", width: "12%" },
      { text: "Cuenta contable", value: "cuentaContable", width: "16%" },
      { text: "Categoria dgii", value: "categoryDgii", width: "12%" },
      { text: "Descripcion", value: "descripcion" },
      { text: "Departamento", value: "department_id", width: "12%" },
      { text: "Categoria", value: "diary_book_project_id", width: "12%" },
      { text: "Debito", value: "debito", width: "15%" },
      { text: "Credito", value: "credito", width: "15%" },
      { text: "Actions", value: "actions", width: "5%" },
    ],
    categoryDgii: [],
    selectedBranche: null,
    dialogCb: false,
    supplier_voucher_edit: "",

    receptions: [],
    order_id: null,
    invoice_parent_id: null,
    supplier_invoice_number: "",
    order_entry_id: null,
    refresArticles: true,
    selectedCreditDays: null,
    dialogWithholdings: false,
    dialogSearchReceipt: false,

    selected_withholdings_push: null,
    selectedCaja: null,
    tipComprobante: null,
    dialogVouchers: false,
    searchProduct: "",
    menu: false,
    menu2: false,
    menu3: false,
    loadData: false,
    loadDataRequest: false,
    dialogPayments: false,
    dialogSearch: false,
    dialogItems: false,
    selectedEntity: {
      entity_id: null,
      document_id: null,
      entity_name: null,
    },
    dateAccount: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    disableInput: false,
    loadingApp: false,
    personApplicant: "",
    commentText: "",
    itemRequest: [],
    ncfTypes: [],
    currencies: [],
    seleted_currencies: null,
    itemsBussines: [],
    itemsLocations: [],
    itemsLocations2: [],
    itemsDepartment: [],
    withholdings: [],
    locationImage: null,
    selected_withholdings: [],
    selected_invoice_categories: null,
    invoice_categories: [],
    itemsRoutes: [],
    editedItem: [],
    nota: "",
    items: [],
    boxes: [],
    //itbis: [],
    accounts: [],
    itemsWarehouse: [],
    credit_days: [],
    selectedPayments: [],
    catalogue_id: null,
    editedIndex: -1,
    filters: {
      business: null,
      location: null,
      department_id: null,
      warehouse_id: null,
    },
    listEditItem: [],
    listProducts: [],
    headerlistEditItem: [],
    selected_invoices: [],
    dataLocations: [],

    commentHeader: "",
  }),
  mounted() {
    // this.clear();
    this.$nextTick(() => {
      this.getData();
    });
  },
  beforeDestroy() {
    // this.clear();
  },

  watch: {
    seleted_currencies(val, newVal) {
      // if(newVal!=)

      if (this.currencies.length > 0) {
        // this.seleted_currencies=1
        let curr = this.currencies.filter((element) => element.id === val);
        this.currency_rate = curr.length > 0 ? curr[0].value : null;
      }
    },
    selectedCreditDays(val, newVal) {
      for (let item of this.credit_days) {
        if (item.value == newVal) {
          this.date2 = moment().add(item.days, "days").format("YYYY-MM-DD");
        }
      }
    },
    computedData: {
      handler(val) {
        if (val.invoice_id != null) {
          this.invoice_number = val.invoice_id === 0 ? null : val.invoice_number;
          this.new_data = val.invoice_details;
          this.in_invoice_id = val.invoice_id === 0 ? null : val.invoice_id;
          this.selected_invoice_categories = val.invoice_category_id;
          this.type = val.invoice_type_id;

          this.getEntity(val.entity_id);
          let businees = this.itemsBussines.filter(
            (element) => element.id === val.business_unit_id
          );

          this.filters.business = businees[0];

          let location = this.itemsLocations.filter(
            (element) => element.value === val.location_id
          );
          console.log(location);
          this.filters.location = location[0];

          let department = this.itemsDepartment.filter(
            (element) => element.id === val.department_id
          );

          this.filters.department_id = department[0];

          this.date = moment(val.emission_date).format("YYYY-MM-DD");
          this.date2 = moment(val.expire_at).format("YYYY-MM-DD");
          this.date3 = moment(val.emission_date).format("YYYY-MM-DD");

          if (val.ncf_invoice_id === null) {
            this.tipComprobante = { id: 0, name: "SIN COMPROBANTE" };
          } else {
            this.tipComprobante = val.ncf_invoice_id;
          }
          //this.addRetencion(val.withholdings)

          this.currency_rate = val.currency_rate;
          let details = [];
          let it = null;

          for (let item of val.invoice_details) {
            it = this.itbis.filter((element) => element.id === item.tax_id);

            let acc = [];
            console.log(item);
            for (var accounts of item.invoice_chart_of_accounts) {
              acc.push({
                cuentaContable: accounts.chart_of_account_id,
                categoryDgii: null,
                entry_type_id: accounts.entry_type_id,
                department_id: null,
                diary_book_category_id: null,
                diary_book_sub_category_id: null,
                amount: accounts.amount * this.currency_rate_selected,
              });
            }
            // console.log(item)

            details.push({
              item_id: item.item_id,
              id: item.item_id,
              name: item.item_name,
              item_name: item.item_name,
              reference: "",
              amount: item.price / val.currency_rate,
              unit: item.unit_name,
              discount: "",
              itbis: it[0],
              quantity: 1,
              quantity_return: 0,
              total: item.price,
              item_chart_of_account_id: item.item_chart_of_account_id,
              accountable: false,
              accounts_edited: acc,
              warehouse_id: item.warehouse_id,
              actions: "",
              discount: 0,
            });
            // console.log(it)
            // details.push({
            //    id: item.item_id,
            //    item_name: item.item_name,
            //     unit: 1,
            //      itbis: it[0],
            //       quantity: item.quantity,
            //        amount: item.price / val.currency_rate })
          }
          this.items = details;

          //         this.$nextTick(() => {

          // });

          // console.log(this.items)
          // let data = {
          //   in_invoice_id: null,
          //   in_category_id: this.selected_invoice_categories,
          //   in_type_id: this.type,
          //   in_voucher_type_id: this.tipComprobante.id,
          //   in_business_unit_id: this.filters.business.value,
          //   in_location_id: this.filters.location.value,
          //   in_department_id:
          //     this.filters.department_id != null
          //       ? this.filters.department_id.value
          //       : null,
          //   in_entity_id: this.selectedEntity.entity_id,
          //   in_responsable_entity_id: null,
          //   in_entity_branch_id: this.selectedBranche,
          //   in_created_by: this.$store.state.auth.user.user.user_id,
          //   in_effective_date: this.date + " " + moment().format("HH:mm:ss"),
          //   in_account_date: this.date3 + " " + moment().format("HH:mm:ss"),
          //   in_expire_at: this.date2,
          //   in_currency_id: currencies_selected.id,
          //   in_currency_rate: this.currency_rate,
          //   in_note: this.nota,
          //   in_box_entry_id: null,
          //   in_order_entry_id: this.order_entry_id,
          //   in_ncf_invoice_id: null,
          //   in_discount: 0,
          //   in_tip: this.type,
          //   in_invoice_vouchers: [],
          //   in_payments: payment_seletecd,
          //   in_entity_payments: [],
          //   in_withholdings: withholding_selected,
          //   in_supplier_details: [],
          //   in_items: items_selected,
          //   is_bussiness: true,
          // };
        }
      },
      immediate: true,
    },
  },
  props: {
    itbis: {
      type: Array,
    },
    type: {
      type: Number,
    },
    editOrCopy: {
      type: Object,
    },
  },
  computed: {
    computedData() {
      return this.editOrCopy;
    },
    currency_rate_selected() {
      return parseFloat(this.currency_rate);
    },
    headers() {
      let data = [
        //{ text: "Otros", value: "cb", width:"6%", align: "start" },
        { text: "ID", value: "id", width: "3%", align: "start" },
        {
          text: "Producto/servicio",
          align: "start",
          sortable: false,
          width: "20%",
          value: "item_name",
        },
        //{ text: "Referencia", value: "reference",    align: "start" },
        { text: "Unidad", value: "unit", align: "start" },

        // { text: "	Desc %", value: "discount", width: "10%", align: "start" },
        //{ text: "Cuenta", value: "account",  align: "start" },
        { text: "Impuesto", value: "itbis", width: "10%", align: "start" },
        { text: "Cantidad", value: "quantity", width: "10%", align: "start" },
        { text: "Precio", value: "amount", width: "15%", align: "start" },
        {
          text: "Cantidad a devolver",
          value: "quantity_return",
          align: " d-none",
        },
        { text: "Total", value: "total", align: "start" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,

          align: "start",
        },
      ];
      if (this.type === 3 || this.type === 4) {
        data[8].align = "start";
        data[8].active = true;
      }
      return data;
    },
    formTitle() {
      let title = "Editar ";
      if (this.in_invoice_id == null) {
        title = "Nueva ";
      }
      if (this.type === 2) {
        return title + "factura venta";
      }
      if (this.type === 1) {
        return title + "factura compra";
      }
      if (this.type === 3) {
        return title + "nota credito";
      }
      if (this.type === 4) {
        return title + "nota debito";
      }
    },
    totCont() {
      let totalCredito = 0;
      let totalDebito = 0;
      if (
        this.items[this.editedIndex].accountable === true &&
        this.items[this.editedIndex].accounts_edited.length > 0
      ) {
        this.items[this.editedIndex].accounts_edited.map(function (objeto) {
          let debito = parseFloat(objeto.debito || 0);
          let credito = parseFloat(objeto.credito || 0);
          totalDebito += debito; // Acumular el valor de débito
          totalCredito += credito; // Acumular el valor de crédito
          return { debito, credito };
        });
      }
      return { totalDebito, totalCredito };
    },
    totales() {
      let tot_itbis = 0;
      let total_amount = 0;
      let tot_subtotal = 0;
      let currencies_selected = 0;

      if (this.items != []) {
        for (let item of this.items) {
          if (item.amount > 0) {
            let tot_item = item.amount * this.calculeTot(item);
            let itbis = 0;
            if (this.tipComprobante) {
              if (this.tipComprobante.id === 8 || this.tipComprobante.id === 9) {
                tot_itbis += 0;
              } else {
                itbis =
                  parseFloat(tot_item) *
                  parseFloat(item.itbis != undefined ? item.itbis.value : 0);
                tot_itbis += parseFloat(itbis);
              }
            } else {
              tot_itbis += 0;
            }

            total_amount += parseFloat(tot_item) + parseFloat(itbis);
            tot_subtotal += parseFloat(tot_item);
          }
        }
      }
      if (this.seleted_currencies === null || this.seleted_currencies === undefined) {
        // console.log(this.seleted_currencies)
      } else {
        currencies_selected = this.currencies.find(
          (element) => element.id == this.seleted_currencies
        );
        // console.log(currencies_selected)
      }

      return {
        tot_currency: currencies_selected,
        tot_itbis: this.currencyFormat(tot_itbis * this.currency_rate_selected),
        tot_amount: this.currencyFormat(total_amount * this.currency_rate_selected),
        tot_sub: this.currencyFormat(tot_subtotal * this.currency_rate_selected),
        total_itbis: this.currencyFormat(
          tot_itbis.toFixed(2) * this.currency_rate_selected
        ),
        //total_amount: total_amount.toFixed(2),
        total_amount: total_amount * this.currency_rate_selected,
        // total_sub: tot_subtotal.toFixed(2),
        total_sub: tot_subtotal * this.currency_rate_selected,
      };
    },
    allNfc() {
      let nfc = [];
      if (this.type === 1) {
        nfc = this.ncfTypes.filter((element) => element.id === 5 || element.id === 0);
      } else {
        nfc = this.ncfTypes;
      }

      return nfc;
    },
  },
  updated: function () {
    // let business = this.itemsBussines.filter(
    //   (element) => element.id == this.$store.state.app.filters.business
    // )[0];
    // this.filters.business = business;
    // let location = this.itemsLocations.filter(
    //   (element) => element.id == this.$store.state.app.filters.location.value
    // )[0];
    // this.filters.location = location
    // console.log('updated: Los datos se han actualizado.');
  },
  methods: {
    totalPrepaid(data) {
      const selected_currency_code = this.currencies.filter(
        (element) => element.id == this.seleted_currencies
      )[0].code;

      const totalAmount = data.reduce((total, item) => {
        const currency = item.currency;
        const amount = parseFloat(item.amount);

        if (currency === selected_currency_code) {
          // La moneda del elemento es la misma que la moneda seleccionada, no es necesario realizar conversión.
          return total + amount;
        } else if (selected_currency_code === "DOP" && currency === "USD") {
          // Convertir de USD a DOP y sumar al total en DOP

          return total + amount * this.currency_rate_selected;
        } else if (selected_currency_code === "HTG" && currency === "USD") {
          // Convertir de HTG a USD y sumar al total en DOP

          return total + amount * this.currency_rate_selected;
        } else if (selected_currency_code === "USD" && currency === "DOP") {
          // Convertir de DOP a USD y sumar al total en USD

          return (
            total + amount / this.currencies.filter((element) => element.id == 1)[0].value
          );
        } else {
          return total; // Otras monedas se ignoran
        }
      }, 0);

      return this.currencyFormat(totalAmount.toFixed(2));
    },
    calculeTot(item) {
      if (this.type === 3 || this.type === 4) {
        return item.quantity_return;
      } else {
        return item.quantity;
      }
    },
    getCurrency(data) {
      // console.log(data)
      if (data.id == 1) {
        return this.currencyFormat(this.totales.total_amount / data.value);
      }
      if (data.id == 2) {
        return this.currencyFormat(this.totales.total_amount * data.value);
      }
      if (data.id == 3) {
        return this.currencyFormat(this.totales.total_amount);
      }
    },
    async getEntity(id) {
      const response = await this.api_Post("accounting/search_entity_by_id", {
        id: id,
      });
      if (response.status == 200) {
        let client = await response.json();

        //  console.log(data);
        //this.clients = data;
        this.selectEntity(client[0]);
      }
    },
    async loadLogo() {
      //   this.items = [];
      this.refresArticles = false;

      if (this.filters.business.value == 0) {
        this.currencies = [];
      } else {
        const resultCurrencies = await this.api_Post("accounting/currenciesBusiness", {
          business: this.filters.business.id,
          default_currency_id: 3,
          effective_date: this.dateAccount,
        });
        this.currencies = await resultCurrencies.json();
      }

      if (this.currencies.length > 0) {
        this.seleted_currencies = 1;
      }

      this.currency_rate = this.currencies.filter(
        (element) => element.id === this.seleted_currencies
      )[0].value;
      //  console.log(this.filters.business.image_storage);
      // if (this.filters.business.value != 0) {
      //   if (this.filters.business.image_storage != null) {
      //     this.locationImage = await this.get_storage(
      //       "logo_bussines_units",
      //       this.filters.business.image_storage
      //     );
      //   } else {
      //     this.locationImage = null;
      //   }
      // }
      this.refresArticles = true;
    },
    async viewReceptions(item) {
      this.receptions = item;
      this.disableInput = true;
      let businees = this.itemsBussines.filter(
        (element) => element.id === item.business_unit_id
      );
      this.filters.business = businees[0];
      let location = this.itemsLocations.filter(
        (element) => element.id === item.location_id
      );

      this.filters.location = location[0];
      this.filters.department_id = {
        text: item.department_names[0].department_name.department_id,
        value: item.department_id,
      };

      this.order_entry_id = item.entry_id;
      this.selectedEntity.document_id = item.rnc;
      this.selectedEntity.name = item.entity_name;
      this.selectedEntity.entity_id = item.entity_id;
      this.selected_invoice_categories = this.invoice_categories[1].id;

      this.tipComprobante = item.nfc_type_id;
      this.items = item.order_details;
      //   this.items = [];

      for (let items of item.order_item_entries) {
        let itbis = this.itbis.filter((element) => element.id === items.tax_type_id);

        this.items.push({
          id: items.item_id,
          item_name: items.item_name,
          name: items.item_name,
          unit: items.unit_id,
          reference: items.item_note,
          quantity: items.quantity,

          itbis: itbis[0],
          account: items.item_chart_of_account_id,
          item_chart_of_account_id: items.item_chart_of_account_id,
          discount: 0,
          amount: parseFloat(items.price).toFixed(2),
          price: parseFloat(items.price).toFixed(2),
        });
      }
      let dataCurrencies = await this.api_Post("accounting/currenciesBusiness", {
        business: this.filters.business.id,
        default_currency_id: 3,
        effective_date: this.dateAccount,
      });
      this.currencies = await dataCurrencies.json();
    },
    async viewInvoiceSelected(item) {
      this.selected_invoices = item;

      this.disableInput = true;

      this.invoice_parent_id = item.invoice_id;
      let businees = this.itemsBussines.filter(
        (element) => element.id === item.business_unit_id
      );
      this.filters.business = businees[0];
      let location = this.itemsLocations.filter(
        (element) => element.id === item.location_id
      );

      this.filters.location = location[0];
      //this.filters.department_id={text:item.d    console.log( this.invoice_categories)

      //  this.order_entry_id=item.entry_id
      this.selectedEntity.document_id = item.rnc;
      this.selectedEntity.name = item.entity_name;
      this.selectedEntity.entity_id = item.entity_id;
      this.selected_invoice_categories = this.invoice_categories[1].id;

      this.items = item.invoice_details;
      this.items = [];
      let it = null;
      for (let items of item.invoice_details) {
        let itbis = this.itbis.filter((element) => element.id === items.tax_id);
        it = this.itbis.filter((element) => element.id === item.tax_id);
        // this.items.push({
        //   id: items.item_id,
        //   item_name: items.item_name,
        //   name: items.item_name,
        //   unit: items.unit_id,
        //   reference: items.item_note,
        //   quantity: parseFloat(items.quantity),
        //   quantity_return: 1,
        //   itbis: itbis[0],
        //   account: items.chart_of_account_id,
        //   item_chart_of_account_id: items.chart_of_account_id,
        //   discount: 0,
        //   amount: parseFloat(items.price).toFixed(2),
        //   price: parseFloat(items.price).toFixed(2),
        // });
        let acc = [];
        for (var accounts of items.invoice_chart_of_accounts) {
          acc.push({
            cuentaContable: accounts.chart_of_account_id,
            categoryDgii: null,
            entry_type_id: accounts.entry_type_id,
            department_id: null,
            diary_book_category_id: null,
            diary_book_sub_category_id: null,
            amount: accounts.amount * this.currency_rate_selected,
          });
        }
        // console.log(item)

        this.items.push({
          item_id: items.item_id,
          id: items.item_id,
          name: items.item_name,
          item_name: items.item_name,
          reference: "",
          amount: items.price,
          unit: items.unit_name,
          discount: "",
          itbis: it[0],
          quantity: parseFloat(items.quantity),
          quantity_return: 1,
          total: items.price,
          amount: parseFloat(items.price).toFixed(2),
          item_chart_of_account_id: items.item_chart_of_account_id,
          accountable: false,
          accounts_edited: acc,
          warehouse_id: items.warehouse_id,
          actions: "",
          discount: 0,
        });
      }

      let dataCurrencies = await this.api_Post("accounting/currenciesBusiness", {
        business: this.filters.business.id,
        default_currency_id: 3,
        effective_date: item.emission_date,
      });

      this.currencies = await dataCurrencies.json();
    },
    async getData() {
      this.loadingApp = true;
      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      const dataTypeAccounts = await resultTypeAccounts.json();
      for (let item of dataTypeAccounts) {
        this.accounts.push({
          text: item.name,
          value: item.id,
          id: item.id,
          dgii_category_id: item.dgii_category_id,
        });
      }

      let locations = await this.api_Get("humanResource/locations");
      const data_locations = await locations.json();
      for (let item of data_locations) {
        // console.log(item);
        for (let item2 of this.$store.state.auth.locations) {
          // console.log(item2);
          if (item2.id == item.id) {
            this.itemsLocations.push({
              value: item.id,
              text: item.desc_loc_long,
              id: item.id,
              bus_id: item.bus_id,
              desc_bus_short: item.desc_bus_short,
              desc_bus_long: item.desc_bus_long,
              desc_loc_short: item.desc_loc_short,
              desc_loc_long: item.desc_loc_long,
              city_id: item.city_id,
              city_description: item.city_description,
              location_adress: item.location_adress,
              status_id: item.status_id,
              catalogue_id: item.catalogue_id,
              catalogue_name: item.catalogue_name,
              rnl: item.rnl,
              representative_name: item.representative_name,
              sta_description: item.sta_description,
              employee_representative_id: item.employee_representative_id,
            });
          }
        }
      }
      let resultBoxes = await this.api_Get("income/boxes");
      this.boxes = await resultBoxes.json();
      // let resultItbis = await this.api_Get("accounting/taxes");
      // this.itbis = await resultItbis.json();
      var resultWithholdings = await this.api_Get("accounting/withholdings");
      this.withholdings = await resultWithholdings.json();
      // console.log(this.withholdings)
      let resultCategories = await this.api_Get("income/invoice_categories");
      this.invoice_categories = await resultCategories.json();
      this.selected_invoice_categories = this.invoice_categories[1].id;

      let resultTypesNcf = await this.api_Get("accounting/nfc_types");
      this.ncfTypes = await resultTypesNcf.json();
      this.ncfTypes.unshift({ id: 0, name: "SIN COMPROBANTE" });

      let resultCreditDays = await this.api_Get("accounting/credit_days");
      this.credit_days = await resultCreditDays.json();

      this.itemsBussines = Object.assign([], this.$store.state.auth.bussines_units);

      // for (let item of dataTypeAccounts) {
      //   this.accounts.push({
      //     text: item.name,
      //     value: item.id,
      //     dgii_category_id: item.dgii_category_id,
      //   });
      // }

      this.itemsBussines.unshift({ text: "SELECCIONA LA UNIDAD", value: "0" });
      //this.filters.business = this.itemsBussines[0].value;
      let business = this.itemsBussines.filter(
        (element) => element.id == this.$store.state.app.filters.business
      )[0];

      this.filters.business = business;

      this.itemsLocations = this.$store.state.auth.locations;

      this.itemsLocations2 = this.updateCombo(
        this.itemsLocations,
        this.itemsLocations2,
        this.filters.business,
        "location"
      );

      let location = this.itemsLocations.filter(
        (element) => element.id == this.$store.state.app.filters.location.value
      )[0];
      this.filters.location = location;

      this.itemsDepartment = await this.g_departments();

      //this.currencies = await resultCurrencies.json();
      if (this.type === 3) {
        this.tipComprobante = this.ncfTypes.filter((element) => element.id === 4)[0];
      }
      if (this.type === 4) {
        this.tipComprobante = this.ncfTypes.filter((element) => element.id === 3)[0];
      }
      this.loadLogo();
      this.loadingApp = false;
    },
    addRetencion(items) {
      // console.log(this.withholdings)
      // for(var w of items)
      //     {
      //      // console.log(w.withholding_id)
      //       let reten=this.withholdings.filter((element)=>element.id==w.withholding_id)
      //     //  console.log(this.withholdings)
      //      // console.log(reten)
      //       //this.selected_withholdings.push({})
      //     }
    },
    addItem(item) {
      console.log(item);
      let itbis = this.itbis.find((element) => element.id == item.tax_type_id);

      for (let it = 0; it < this.items.length; it++) {
        if (item.item_id == this.items[it].id) {
          this.items[it].quantity++;
        }
      }
      if (this.items.find((element) => element.id == item.item_id) === undefined) {
        let acc = [];
        let item_chart_of_account_id = this.accounts.filter(
          (element) =>
            element.id ==
            item.account_category_chart_of_accounts[0].item_chart_of_account_id
        )[0];
        let inventory_chart_of_account_id = this.accounts.filter(
          (element) =>
            element.id ==
            item.account_category_chart_of_accounts[0].inventory_chart_of_account_id
        )[0];
        let cost_chart_of_account_id = this.accounts.filter(
          (element) =>
            element.id ==
            item.account_category_chart_of_accounts[0].cost_chart_of_account_id
        )[0];
        // console.log(item)

        acc.push({
          cuentaContable: item_chart_of_account_id,
          categoryDgii: null,
          entry_type_id:
            item.account_category_chart_of_accounts[0]
              .item_chart_of_account_sale_entry_type_id,
          department_id: null,
          diary_book_category_id: null,
          diary_book_sub_category_id: null,
          amount: item.price * this.currency_rate_selected,
        });
        acc.push({
          cuentaContable: inventory_chart_of_account_id,
          categoryDgii: null,
          entry_type_id:
            item.account_category_chart_of_accounts[0]
              .inventory_chart_of_account_sale_entry_type_id,
          department_id: null,
          diary_book_category_id: null,
          diary_book_sub_category_id: null,
          amount: item.price * this.currency_rate_selected,
        });
        acc.push({
          cuentaContable: cost_chart_of_account_id,
          categoryDgii: null,
          entry_type_id:
            item.account_category_chart_of_accounts[0]
              .cost_chart_of_account_sale_entry_type_id,
          department_id: null,
          diary_book_category_id: null,
          diary_book_sub_category_id: null,
          amount: item.price * this.currency_rate_selected,
        });

        this.items.push({
          item_id: item.item_id,
          id: item.item_id,
          name: item.item_name,
          item_name: item.item_name,
          reference: "",
          amount: this.type == 1 ? item.cost_price : item.sale_price,
          unit: item.unit_name,
          discount: "",
          itbis: itbis,
          quantity: 1,
          quantity_return: 0,
          total: this.type == 1 ? item.cost_price : item.sale_price,
          item_chart_of_account_id: item.item_chart_of_account_id,
          accountable: false,
          accounts_edited: acc,
          warehouse_id: item.warehouse_discount.id,
          actions: "",
          discount: 0,
        });
      }

      // this.$toast.success("Agregado");
    },
    async showVouchers() {
      this.itemsVouchers = [];
      //console.log(this.filters)
      // this.itemsVouchers=
      if (this.filters.business == null) {
        this.$toast.warning("Debes elegir una unidad de negocio!");
        return;
      }

      if (this.filters.location == null) {
        this.$toast.warning("Debes elegir una location!");
        return;
      }
      const response = await this.api_Post("income/search_vouchers", {
        business_unit: this.filters.business.id,
      });
      if (response.status == 200) {
        let data = await response.json();

        this.itemsVouchers = data;
        // console.log(data)
      }
      this.dialogVouchers = true;
    },
    showItems() {
      if (this.filters.business == null) {
        this.$toast.warning("Debes elegir una unidad de negocio!");
        return;
      }

      if (this.filters.location == null) {
        this.$toast.warning("Debes elegir una location!");
        return;
      }
      if (this.filters.location.catalogue_id == null) {
        this.$toast.warning("Este location no tiene un catalogo de articulo!");
        return;
      }

      this.dialogItems = true;
      // this.items.push({
      //   id: "",
      //   name: "",
      //   reference: "",
      //   amount: "",
      //   discount: "",
      //   itbis: "",
      //   quantity: "",
      //   total: "",
      //   actions: "",
      // });
    },
    updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
      itemsDestino.splice(0, itemsDestino.length);

      for (let item of itemsOrigen) {
        if (item.bus_id === condicion) {
          itemsDestino.push({
            value: item.value,
            text: item.text,
            routes: item.routes,
          });
        }
      }

      //this.filters.location = this.itemsLocations2[0].value;

      return itemsDestino;
    },
    showDialogPayments() {
      if (this.items.length != []) {
        if (this.totales.total_amount > 0) {
          this.dialogPayments = true;
        } else {
          this.$toast.warning("Debes completar los items!");
        }
      } else {
        this.$toast.warning("Debes agregar items para agregar el pago!");
      }
    },

    async updateWarehouse() {
      this.items = [];
      this.refresArticles = false;
      const warehouses = await this.api_Get(
        "inventory/warehouseForLocation/" + this.filters.location.id
      );
      this.itemsWarehouse = await warehouses.json();
      this.filters.warehouse_id = this.itemsWarehouse[0].id;
      this.refresArticles = true;
    },
    add_withholdings() {
      if (this.selected_withholdings_push == null) {
        this.$toast.warning("Debes seleccionar una retención");
        return;
      }
      this.selected_withholdings.push(this.selected_withholdings_push);

      this.dialogWithholdings = false;
    },
    save() {
      let currencies_selected = this.currencies.find(
        (element) => element.id == this.seleted_currencies
      );

      let payment_seletecd = [];
      let items_selected = [];
      let withholding_selected = [];
      let entity_payments = [];
      if (this.selected_invoice_categories == null) {
        this.$toast.warning("Selecciona el tipo de factura");
        this.$nextTick(this.$refs["tipFac"].focus());
        return;
      }
      if (this.tipComprobante == null) {
        this.$toast.warning("Selecciona el tipo de comprobante");

        return;
      }
      if (this.items != []) {
        for (let item of this.items) {
          if (this.filters.business == null) {
            this.$toast.warning("Debes elegir una unidad de negocio !");
            return;
          }
          if (this.filters.location == null) {
            this.$toast.warning("Debes elegir una location !");
            return;
          }
          if (item.quantity == 0) {
            this.$toast.warning(
              "la cantidad del item " + item.name + " debe ser mayor que 0"
            );
            return;
          }
          if (item.amount == 0) {
            this.$toast.warning(
              "la precio del item " + item.name + " debe ser mayor que 0"
            );
            return;
          }
          if (item.itbis == null) {
            this.$toast.warning("debes elegir un itbis para " + item.name + " ");
            return;
          }
          let accounts = [];
          if (item.accountable) {
            if (item.accounts_edited.length > 0) {
              for (let acc of item.accounts_edited) {
                let categories = [];
                let subCategories = [];
                if (acc.diary_book_category_id) {
                  categories.push({ category_id: acc.diary_book_category_id });
                }
                if (acc.diary_book_sub_category_id) {
                  subCategories.push({
                    category_id: acc.diary_book_sub_category_id,
                  });
                }

                accounts.push({
                  chart_of_account_id: acc.cuentaContable.id,
                  entry_type_id: acc.entry_type_id,
                  account_type_id: acc.debito > 0 ? 1 : 2,
                  location_id: null,
                  dgii_category_id: acc.categoryDgii,
                  department_id: acc.department_id,
                  categories: categories,
                  subcategories: subCategories,
                  amount: parseFloat(acc.amount),
                });
              }
            }
          }
          //   console.log(item)
          items_selected.push({
            item_id: item.id,
            item_description: item.item_description,
            quantity: parseFloat(this.type === 3 ? item.quantity_return : item.quantity),
            tax_id: item.itbis.id,
            price: item.amount * this.currency_rate,
            warehouse_id: item.warehouse_id,
            item_chart_of_account_id: item.item_chart_of_account_id,
            discount: 0,
            accounts: accounts,
          });
          /// console.log(items_selected)
        }
      }

      if (this.selected_withholdings != []) {
        for (let item of this.selected_withholdings) {
          withholding_selected.push({
            withholding_id: item.id,
          });
        }
      }

      if (this.selectedPayments != []) {
        for (let item of this.selectedPayments) {
          payment_seletecd.push({
            invoice_payment_type_id: item.id,
            entity_id: this.selectedEntity.entity_id,
            payment_amount: item.amount,
            currency_id: currencies_selected.id,
          });
        }
      }
      let vouchers = [];
      if (this.selectedVouchers != []) {
        for (let item of this.selectedVouchers) {
          vouchers.push({
            invoice_id: item.invoice_id,
          });
        }
      }
      if (this.prepaids != []) {
        for (var item of this.prepaids) {
          console.log(item);
          if (item.selected == true) {
            entity_payments.push({ entity_payment_id: item.id });
          }
        }
      }

      let data = {
        in_invoice_id: this.in_invoice_id,
        in_category_id: this.selected_invoice_categories,
        in_type_id: this.type,
        in_voucher_type_id: this.tipComprobante.id,
        in_business_unit_id: this.filters.business.value,
        in_location_id: this.filters.location.value,

        in_department_id:
          this.filters.department_id != null ? this.filters.department_id.value : null,
        in_entity_id: this.selectedEntity.entity_id,
        in_responsable_entity_id: null,
        in_entity_branch_id: this.selectedBranche,
        in_created_by: this.$store.state.auth.user.user.user_id,
        in_effective_date: this.date + " " + moment().format("HH:mm:ss"),
        in_account_date: this.date3 + " " + moment().format("HH:mm:ss"),
        in_expire_at: this.date2,
        in_currency_id: currencies_selected.id,
        in_currency_rate: this.currency_rate,
        in_note: this.nota,
        in_box_entry_id: null,
        in_order_entry_id: this.order_entry_id,
        in_ncf_invoice_id: null,
        in_discount: 0,
        in_tip: this.type,
        in_invoice_vouchers: vouchers,
        in_payments: payment_seletecd,
        in_entity_payments: entity_payments,
        in_withholdings: withholding_selected,
        in_supplier_details: [],
        in_items: items_selected,
        is_bussiness: true,
        in_installments: null,
      };

      this.$emit("save-invoice", data);
    },
    async consultPayments(id, business) {
      this.payments = [];
      const request = await this.api_Post("income/entity_payments", {
        filters:
          " WHERE ENTITY_PAYMENTS.ENTITY_ID=" +
          id +
          " AND ENTITY_PAYMENTS.business_unit_id=" +
          business,
      });
      if (request.status == 200) {
        const data = await request.json();
        return data;
      }
    },
    async selectEntity(item) {
      this.prepaids = [];
      this.selectedEntity = item;

      let prepaid = await this.consultPayments(
        item.entity_id,
        this.filters.business.value
      );
      if (prepaid.length > 0) {
        this.prepaids = prepaid;
      }

      let branches = [];
      if (this.selectedEntity.entity_branches != null) {
        for (let item of this.selectedEntity.entity_branches) {
          branches.push(item.supplier);
        }
      }
      this.selectedEntity.entity_branches = branches;
      if (this.type === 1) {
        this.tipComprobante = this.ncfTypes.filter(
          (element) => element.id === item.supplier_details[0].supplier.nfc_type_id
        )[0];
      }
      if (this.type === 2) {
        this.tipComprobante = this.ncfTypes.filter(
          (element) => element.id === item.customer_details[0].customer.nfc_type_id
        )[0];
      }

      for (let item of this.credit_days) {
        if (item.value == this.selectedCreditDays) {
          this.date2 = moment().add(item.days, "days").format("YYYY-MM-DD");
          this.selectedCreditDays = item.id;
        }

        //this.selectedCreditDays=this.credit_days
      }

      this.selected_withholdings = [];
      if (this.type == 1) {
        if (this.selectedEntity.supplier_details[0].supplier.withholding_id != null) {
          for (let item of this.withholdings) {
            if (
              item.id == this.selectedEntity.supplier_details[0].supplier.withholding_id
            )
              this.selected_withholdings.push(item);
          }
        }
      } else {
        if (this.selectedEntity.customer_details[0].customer.withholding_id != null) {
          for (let item of this.withholdings) {
            if (
              item.id == this.selectedEntity.customer_details[0].customer.withholding_id
            )
              this.selected_withholdings.push(item);
          }
        }
      }

      this.dialogSearch = false;
    },
    selectPayment(item) {
      this.selectedPayments = item;
      this.dialogPayments = false;
    },

    deleteProducts(item) {
      let editIndex = this.items.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.items.splice(editIndex, 1);
    },
    delete_withholdings(item) {
      let editIndex = this.selected_withholdings.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.selected_withholdings.splice(editIndex, 1);
    },
    deletePayment(item) {
      let editIndex = this.selectedPayments.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.selectedPayments.splice(editIndex, 1);
    },
    clear() {
      this.in_invoice_id = null;
      this.prepaids = [];
      this.invoice_number = "";
      this.selected_invoices = [];
      this.selectedBranche = null;
      this.receptions = [];
      this.request_id = null;
      this.tab = null;
      this.refresArticles = true;
      this.selectedCreditDays = null;
      this.dialogWithholdings = false;
      this.dialogSearchReceipt = false;
      //this.withholdings = [];
      this.selected_withholdings_push = null;
      this.selectedCaja = null;
      this.tipComprobante = null;
      this.searchProduct = "";
      this.menu = false;
      this.menu2 = false;
      this.loadData = false;
      this.loadDataRequest = false;
      this.dialogPayments = false;
      this.dialogSearch = false;
      this.dialogItems = false;
      this.selectedEntity = {
        entity_id: null,
        document_id: null,
        entity_name: null,
      };
      // this.items=[]
      this.disableInput = false;
      this.personApplicant = "";
      this.commentText = "";
      this.itemRequest = [];
      // this.ncfTypes = [];
      // this.currencies = [];
      this.seleted_currencies = null;

      this.withholdings = [];
      this.locationImage = null;
      this.selected_withholdings = [];
      this.selected_invoice_categories = null;
      //this.invoice_categories = [];
      this.itemsRoutes = [];
      this.editedItem = [];
      this.nota = "";
      //   this.items = [];

      // this.itbis = [];
      //  this.accounts = [];
      this.itemsWarehouse = [];

      this.selectedPayments = [];
      this.catalogue_id = null;
      this.editedIndex = -1;
      // this.filters = {
      //   business: null,
      //   location: null,
      //   department_id: null,
      //   warehouse_id: null,
      // };
      this.listEditItem = [];
      this.listProducts = [];
      this.headerlistEditItem = [];
    },
    close() {
      this.clear();
      this.items = [];
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped>
.mobile {
  color: #333;
}

@media screen and (max-width: 768px) {
  .mobile table.v-table tr {
    max-width: 100%;
    position: relative;
    display: block;
  }

  .mobile table.v-table tr:nth-child(odd) {
    border-left: 6px solid deeppink;
  }

  .mobile table.v-table tr:nth-child(even) {
    border-left: 6px solid cyan;
  }

  .mobile table.v-table tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }

  .mobile table.v-table tr td ul li:before {
    content: attr(data-label);
    padding-right: 0.5em;
    text-align: left;
    display: block;
    color: #999;
  }

  .v-datatable__actions__select {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }

  .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }
}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-item {
  padding: 5px;
  width: 50%;
  height: 40px;
  font-weight: bold;
}

.folded-paper:before {
  content: "";
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 30px 30px 0;
  border-style: solid;
  border-color: rgb(74, 92, 67) #fff;
  transition: 0.3s;
  border-radius: 0 0 0 4px;
}

.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}

.folded_corner {
  position: relative;
  width: 100%;

  height: auto;
  border-radius: 5px;

  /* background: #58a; */
  background: linear-gradient(to left bottom, transparent 50%, rgba(194, 193, 193, 0.4) 0)
      no-repeat 100% 0 / 2em 2em,
    linear-gradient(-135deg, transparent 1.5em, rgb(255, 255, 255) 0);

  /* -webkit-filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
  /* filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
}

/* .folded_corner::before {
  content: " ";
  position: absolute;
  top: 2em;
  right: 0;
  background: linear-gradient(
      to left bottom,
      transparent 50%,
      rgba(0, 0, 0, 0.1) 0
    )
    100% 0 no-repeat;
  width: 2em;
  height: 2em;
  transform: rotate(180deg);
} */
</style>
