<template>
  <v-container>
    <v-row justify="center" v-if="!loading">
      <v-col v-if="ventas_diarias.series.length > 0" cols="12" md="12" class="mt-2 mb-2">
        <LineChart :title_header="'Ventas diaras'" :data="ventas_diarias" />
      </v-col>
      <v-row justify="center" v-else>
        <v-col cols="12" md="10">
          <v-skeleton-loader type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions">
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import LineChart from "@/components/Charts/Line.vue";
export default {
  components: {
    LineChart,
  },
  data: () => ({
    loading: false,
    ventas_diarias: { series: [], label: [] },
  }),
  props: {
    filters: {
      type: Object,
      default: function () {
        return {
          business: null,
          location: null,
          department_id: null,
        };
      },
    },
  },
  watch: {
    filters: {
      handler: function (nuevoValor, viejoValor) {
        // Lógica para manejar el cambio del objeto filters
        this.updateData(nuevoValor);
      },
      deep: true, // Para obserlet cambios profundos en el objeto filters
      immediate: true, // Para ejecutar el watch inmediatamente al crear el componente
    },
  },
  methods: {
    async updateData(filter) {
      this.loading = true;

      let resultVentasDays = await this.api_Post("income/dashboard_daily", {
        invoice: 2,
        business: filter.business,
      });
      let ventasDays = await resultVentasDays.json();

      let ventas_diarias_label = [];
      let ventas_diarias_series = [];

      for (let item of ventasDays) {
        ventas_diarias_label.push(item.day_year);
        ventas_diarias_series.push(item.total_sales);
      }

      this.ventas_diarias.label = ventas_diarias_label;
      this.ventas_diarias.series = ventas_diarias_series;

      this.loading = false;
    },
  },
};
</script>

<style></style>
