<template>
  <v-container>
    <v-card elevation="0">
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" md="12"><v-row justify="center" class="mt-1 ml-5">
              <v-btn small @click="beforeRoute" color="primary" outlined><v-icon>keyboard_backspace</v-icon>
                Volver</v-btn>
              <v-spacer></v-spacer>
              <v-icon class="ml-1 mr-2" size="35px">group</v-icon>

              <span v-if="loading == false" style="font-size: 3vh" class="font-weight-semibold">{{ data_entity.name
              }}</span>
              <v-btn-toggle class="rounded-lg ml-4" mandatory color="primary">
                <v-btn small> Activado </v-btn>
                <v-btn small> Desactivado </v-btn>
              </v-btn-toggle>
              <v-spacer></v-spacer>
            </v-row>
            <v-row justify="center">
              <v-container>
                <v-row justify="center">
                  <v-col cols="6">
                    <search-bussines-locations :type="false" />
                  </v-col>
                </v-row>
                <v-row no-gutters justify="start">
                  <v-card outlined elevation="1" width="100%" height="100px">
                    <v-layout fill-height align-center justify-center>
                      <v-col cols="2">
                        <v-row justify="center">
                          <span style="font-size: 0.9vw">Cuentas por cobrar
                          </span></v-row>
                        <br />
                        <v-row justify="center">
                          <span class="text-center" style="font-size: 1.2vw; color: #e85e48"
                            v-for="(item, i) of getHeader('porCobrar')" :key="i">
                            {{ currencyFormat(item.total_amount) }}
                            {{ item.currency_code }}
                            <br />
                          </span>
                        </v-row>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="2">
                        <v-row justify="center">
                          <span style="font-size: 0.9vw">Anticipos recibidos
                          </span></v-row>
                        <br />
                        <v-row justify="center">
                          <span class="font-weight-normal" style="font-size: 1.2vw; color: #e85e48">USD 0.00
                          </span></v-row>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="2">
                        <v-row justify="center">
                          <span style="font-size: 0.9vw">Anticipos entregados
                          </span></v-row>
                        <br />
                        <v-row justify="center">
                          <span class="font-weight-normal" style="font-size: 1.2vw; color: #e85e48">USD 0.00
                          </span></v-row></v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="2">
                        <v-row justify="center">
                          <span style="font-size: 0.9vw">Cuentas por pagar
                          </span></v-row>
                        <br />
                        <v-row justify="center">
                          <span class="text-center" style="font-size: 1.2vw; color: #e85e48"
                            v-for="(item, i) of getHeader('porPagar')" :key="i">
                            {{ currencyFormat(item.total_amount) }}
                            {{ item.currency_code }}
                            <br />
                          </span>
                        </v-row>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="2">
                        <v-row justify="center">
                          <span style="font-size: 0.9vw">Notas debito PA
                          </span></v-row>
                        <br />
                        <v-row justify="center">
                          <span class="font-weight-normal" style="font-size: 1.2vw; color: #e85e48">USD
                            {{ currencyFormat(9000) }}
                          </span></v-row></v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="2">
                        <v-row justify="center">
                          <span style="font-size: 0.9vw">Notas crédito PA
                          </span></v-row>
                        <br />
                        <v-row justify="center">
                          <span class="font-weight-normal" style="font-size: 1.2vw; color: #e85e48">USD
                            {{ currencyFormat(200) }}
                          </span></v-row></v-col>
                    </v-layout>
                  </v-card>
                </v-row>

                <v-row>
                  <v-col cols="12" md="8"><v-card class="rounded-lg" min-height="735px">
                      <v-container class="mx-4">
                        <v-row class="mt-2">
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field dense outlined color="cyanText" label="Tipo de identificación"
                              :value="data_entity.document_id_type_name" readonly></v-text-field>
                          </v-col>
                          <v-col sm="5" md="4">
                            <v-text-field :value="data_entity.document_id" readonly dense outlined></v-text-field>
                          </v-col>
                          <v-col sm="2" md="2"> </v-col>
                        </v-row>
                        <v-row dense no-gutters>
                          <v-col cols="12" md="8">
                            <v-text-field :value="data_entity.name" label="Nombre/Razón social" readonly dense
                              outlined></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-n4" dense>
                          <span style="font-size: 2vh" class="font-weight-bold pa-3">Direcciones / contactos</span>
                        </v-row>
                        <v-row no-gutters dense>
                          <v-col cols="12" md="8">
                            <v-data-table v-if="data_entity.entity_contact_json != null" :headers="headersContacts"
                              :items="data_entity.entity_contact_json" hide-default-footer class="elevation-0">
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="4"><v-card class="rounded-lg" min-height="735px">
                      <v-container>
                        <v-row class="mt-2 mb-2" justify="center">
                          <v-card class="rounded-lg" width="80%" height="60px" outlined>
                            <v-row justify="center">
                              <v-col cols="12" sm="5" md="5">
                                <v-checkbox :value="data_entity.is_customer" label="Cliente" color="primary" readonly
                                  hide-details></v-checkbox>
                              </v-col>
                              <v-col cols="12" sm="5" md="5">
                                <v-checkbox :value="data_entity.is_supplier" label="Suplidor" color="primary" readonly
                                  hide-details></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-row>
                        <v-row v-if="data_entity.is_supplier" justify="center">
                          <v-btn @click="viewArticles" class="mt-2 mb-4" outlined
                            color="primary"><v-icon>add</v-icon>Articulos suplidor
                          </v-btn>
                        </v-row>
                        <v-row justify="center">
                          <span v-if="data_entity.is_customer"> Cliente</span>
                        </v-row>
                        <v-row v-if="data_entity.is_customer" justify="center">
                          <v-col cols="10">
                            <v-text-field :value="selectedNfcCustomers.name" dense outlined item-text="name"
                              color="cyanText" label="Tipo de NCF Cliente" required readonly></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters v-if="data_entity.is_customer" justify="center">
                          <v-col cols="10">
                            <v-text-field :value="selectedCreditCustomers.name" dense outlined color="cyanText"
                              label="Plazo de pago cliente " required readonly></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters v-if="data_entity.is_customer" justify="center">
                          <v-col cols="10">
                            <v-text-field v-if="selectedRetencionesClientes != null" dense outlined
                              :value="selectedRetencionesClientes.name" color="cyanText" label="Retenciones cliente"
                              required readonly></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="mb-3" justify="center">
                          <span v-if="data_entity.is_supplier"> Suplidor</span>
                        </v-row>
                        <v-row no-gutters v-if="data_entity.is_supplier" justify="center">
                          <v-col cols="10">
                            <v-text-field :value="selectedNfcSuppliers.name" dense outlined color="cyanText"
                              label="Tipo de NCF Suplidor" required readonly></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row no-gutters v-if="data_entity.is_supplier" justify="center">
                          <v-col cols="10">
                            <v-text-field :value="selectedCreditSuppliers.name" dense outlined color="cyanText"
                              label="Plazo de pago suplidor" required readonly></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters v-if="data_entity.is_supplier" justify="center">
                          <v-col cols="10">
                            <v-text-field :value="selectedRetencionesSuplidor.name" dense outlined color="cyanText"
                              label="Retenciones suplidor" required readonly></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters v-if="data_entity.is_supplier" justify="center">
                          <v-col cols="10"> </v-col>
                        </v-row>
                        <v-row v-if="data_entity.is_customer || data_entity.is_supplier
                          " class="pb-3" no-gutters justify="center">
                          <span style="font-size: 22px; color: #616161">Configuración contabilidad
                          </span>
                        </v-row>

                        <v-row no-gutters justify="center">
                          <v-col cols="10">
                            <v-text-field v-show="data_entity.is_customer == true" dense
                              :value="selectedAccountClientes.text" color="cyanText" label="Cuenta por cobrar" outlined
                              readonly></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row no-gutters justify="center">
                          <v-col cols="10">
                            <v-text-field v-if="data_entity.is_supplier == true" dense
                              :value="selectedAccountSuplidor.text" color="cyanText" label="Cuenta por pagar" outlined
                              readonly></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-container>
                    <v-card class="rounded-lg" outlined elevation="1" width="100%" height="auto">
                      <v-col cols="12">
                        <v-row>
                          <v-tabs @change="loadMenu" v-model="tabSelected" background-color="transparent" color="primary">
                            <v-tab v-for="item in items" :key="item">
                              {{ item }}
                            </v-tab>
                          </v-tabs>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-skeleton-loader height="45vh" v-if="loading == true"
                              type="table-heading, list-item-two-line, image, table-tfoot"></v-skeleton-loader>
                            <v-data-table v-if="loading == false" :headers="headers" :items="data" :search="search"
                              sort-by="calories" class="elevation-0" height="60vh">
                              <template v-slot:top>
                                <v-toolbar flat>
                                  <v-row justify="center">
                                    <v-col cols="12" md="5">
                                      <v-text-field v-model="search" dense outlined label="Buscar...">
                                      </v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-divider class="mx-4" inset vertical></v-divider>
                                </v-toolbar>
                              </template>
                              <template v-slot:[`item.order_id`]="{ item }">
                                <v-hover v-slot="{ hover }">
                                  <span @click="showOrder(item.order_id, false)"
                                    class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                                      color: hover ? 'red' : 'black',
                                      cursor: hover ? 'pointer' : '',
                                      'text-decoration': hover
                                        ? 'underline'
                                        : '',
                                    }">
                                    {{
                                      filLeft(item.order_id.toString(), 12, "0")
                                    }}</span>
                                </v-hover>
                              </template>
                              <template v-slot:[`item.invoice_number`]="{ item }">
                                <v-hover v-slot="{ hover }">
                                  <span @click="showInvoice(item)"
                                    class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                                      color: hover ? 'red' : 'black',
                                      cursor: hover ? 'pointer' : '',
                                      'text-decoration': hover
                                        ? 'underline'
                                        : '',
                                    }">
                                    {{ item.invoice_number }}</span>
                                </v-hover>
                              </template>
                              <template v-slot:[`item.date`]="{ item }">
                                <span>
                                  {{ getDate(item.emission_date) }}
                                </span>
                              </template>
                              <template v-slot:[`item.expire_at`]="{ item }">
                                <span v-if="item.expire_at != null">
                                  <span :class="getDateColor(item)">
                                    {{ getDate(item.expire_at) }}
                                  </span>
                                </span>
                              </template>
                              <template v-slot:[`item.total_amount`]="{ item }">
                                <span>
                                  {{ item.currency_code }}
                                  {{ currencyFormat(item.total_amount) }}
                                </span>
                              </template>
                              <template v-slot:[`item.total_paid_amount`]="{ item }">
                                <span>
                                  {{ item.currency_code }}
                                  {{ currencyFormat(item.total_paid_amount) }}
                                </span>
                              </template>
                              <template v-slot:[`item.cobrar`]="{ item }">
                                <span>
                                  {{ item.currency_code }}
                                  {{
                                    currencyFormat(
                                      item.total_amount - item.total_paid_amount
                                    )
                                  }}
                                </span>
                              </template>
                              <template v-slot:[`item.status`]="{ item }">
                                <v-chip outlined small class="white--text" :color="time_line_orders(item.last_status_id).color
                                  ">
                                  {{ item.last_status_name }}
                                </v-chip>
                              </template>
                              <template v-slot:[`item.status_invoice`]="{ item }">
                                <v-chip outlined small class="white--text" :color="time_line_orders(item.status_id).color
                                  ">
                                  {{ item.sta_description }}
                                </v-chip>
                              </template>

                              <template v-slot:no-data>
                                <v-row justify="center" class="pt-10">
                                  <v-icon size="80px">token</v-icon>
                                </v-row>
                                <v-row justify="center" class="pb-6">
                                  No hay datos
                                </v-row>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-card></v-container>
                </v-row>
              </v-container></v-row></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({
    tabSelected: "",
    dialog: false,
    dialogDelete: false,
    headers: [],
    data: [],
    search: "",
    editedIndex: -1,
    editedItem: [],
    defaultItem: [],
    tab: null,
    items: [
      "Facturas ventas",
      "Facturas compras",
      "Transacciones",
      "Cotizaciones",
      "Conduces",
      "Ordenes de compra",
    ],
    headersContacts: [
      {
        text: "Tipo",
        align: "start",
        sortable: false,
        value: "description_type",
        width: "20%",
      },
      { text: "Descripcion", value: "description" },
    ],
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    suplidor: false,
    cliente: false,
    id_entity: null,
    data_entity: [],
    documents: [],
    loading: false,
    ncfTypes: [],
    selectedNfcCustomers: "",
    selectedNfcSuppliers: "",
    selectedCreditCustomers: "",
    selectedCreditSuppliers: "",
    selectedRetencionesClientes: "",
    selectedRetencionesSuplidor: "",
    selectedAccountClientes: "",
    selectedAccountSuplidor: "",
    accounts: [],
    invoice_sales: [],
    invoice_shop: [],
    provincies: [],
    credit_days: [],
    withholdings: [],
  }),

  created() {
    this.initialize();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    getDate(item) {
      return moment(item).format("DD/MM/YYYY");
    },
    getDateColor(item) {
      const now = moment();
      const end = moment(item.expire_at);
      if (item.total_amount - item.total_paid_amount < 1) {
        return "red--text";
      }

      // moment(dateTime).isAfter(new Date());
      if (
        moment(now.format("YYYY/MM/DD")).isAfter(
          moment(end.format("YYYY/MM/DD"))
        )
      ) {
        return "red--text";
      }
    },
    async loadMenu() {
      if (this.tabSelected == 0) {
        this.loading = true;
        this.data = [];
        this.headers = [
          {
            text: "Factura",
            align: "start",

            value: "invoice_number",
          },
          { text: "Unidad negocio", value: "business_unit_description" },
          { text: "Location", value: "location_description" },
          { text: "Cliente", value: "entity_name" },
          { text: "Total", value: "total_amount", align: "end" },
          { text: "Status", value: "status_invoice" },

          //{ text: "Actions", value: "actions", sortable: false },
        ];
        let filters = "AND ENTITY_ID=" + this.id_entity;
        let resultInvoices = await this.api_Post("income/invoice_sales", {
          filters: filters,
        });

        this.data = await resultInvoices.json();

        this.loading = false;
      }
      if (this.tabSelected == 1) {
        this.loading = true;
        this.data = [];
        this.headers = [
          {
            text: "Numero",
            align: "start",

            value: "invoice_number",
          },
          { text: "Cliente", value: "entity_name" },

          { text: "Creacion", value: "date" },
          { text: "Vencimineto", value: "expire_at" },
          { text: "Total", value: "total_amount" },
          { text: "Cobrado", value: "total_paid_amount" },
          { text: "Por cobrar", value: "cobrar" },
          { text: "Estado", value: "status_invoice" },
          // { text: "Acciones", value: "actions", sortable: false },
        ];
        let resultData = await this.api_Get(
          "income/get_invoice_shop_entity/" + this.id_entity
        );
        let data = await resultData.json();

        this.data = data;
        this.loading = false;
      }
      if (this.tabSelected == 2) {
        this.loading = true;
        this.data = [];
        this.headers = [
          {
            text: "Numero",
            align: "start",

            value: "invoice_number",
          },
          { text: "Cliente", value: "entity_name" },

          { text: "Creacion", value: "date" },
          { text: "Vencimineto", value: "expire_at" },
          { text: "Total", value: "total_amount" },
          { text: "Cobrado", value: "total_paid_amount" },
          { text: "Por cobrar", value: "cobrar" },
          // { text: "Estado", value: "status" },
          { text: "Acciones", value: "actions", sortable: false },
        ];
        let resultData = await this.api_Get(
          "income/get_invoice_shop_entity/" + this.id_entity
        );
        let data = await resultData.json();

        this.data = data;
        this.loading = false;
      }
      if (this.tabSelected == 3) {
        this.loading = true;
        this.data = [];
        this.headers = [
          {
            text: "Numero",
            align: "start",

            value: "invoice_number",
          },
          { text: "Cliente", value: "entity_name" },

          { text: "Creacion", value: "date" },
          { text: "Vencimineto", value: "expire_at" },
          { text: "Total", value: "total_amount" },
          { text: "Cobrado", value: "total_paid_amount" },
          { text: "Por cobrar", value: "cobrar" },
          { text: "Estado", value: "status" },
          { text: "Acciones", value: "actions", sortable: false },
        ];
        this.loading = false;
      }
      if (this.tabSelected == 4) {
        this.data = [];
        this.headers = [
          {
            text: "Numero",
            align: "start",

            value: "invoice_number",
          },
          { text: "Cliente", value: "entity_name" },

          { text: "Creacion", value: "date" },
          { text: "Vencimineto", value: "expire_at" },
          { text: "Total", value: "total_amount" },
          { text: "Cobrado", value: "total_paid_amount" },
          { text: "Por cobrar", value: "cobrar" },
          { text: "Estado", value: "status" },
          { text: "Acciones", value: "actions", sortable: false },
        ];
        this.loading = false;
      }
      if (this.tabSelected == 5) {
        this.data = [];
        this.headers = [
          {
            text: "Orden",
            align: "start",

            value: "order_id",
          },
          { text: "Unidad negocio", value: "business_unit_description_long" },
          { text: "Location", value: "location_description_long" },
          { text: "Suplidor", value: "entity_name" },
          { text: "Status", value: "status" },
        ];
        let filters = "AND ENTITY_ID=" + this.id_entity;
        let resultOrders = await this.api_Post("shopping/orders", {
          filters: filters,
        });

        this.data = await resultOrders.json();

        this.loading = false;
      }
    },
    getHeader(type) {
      let result = [];

      if (type == "porPagar") {
        this.invoice_shop.reduce(function (res, value) {
          if (!res[value.currency_code]) {
            res[value.currency_code] = {
              currency_code: value.currency_code,
              total_amount: 0,
            };
            result.push(res[value.currency_code]);
          }

          res[value.currency_code].total_amount += parseFloat(
            value.total_amount - value.total_paid_amount
          );
          return res;
        }, {});
      }
      if (type == "porCobrar") {
        this.invoice_sales.reduce(function (res, value) {
          if (!res[value.currency_code]) {
            res[value.currency_code] = {
              currency_code: value.currency_code,
              total_amount: 0,
            };
            result.push(res[value.currency_code]);
          }

          res[value.currency_code].total_amount += parseFloat(
            value.total_amount - value.total_paid_amount
          );
          return res;
        }, {});
      }

      return result;
    },
    viewArticles() {
      this.$router.push({
        name: "CatalogueSupplier",
        params: { id: this.id_entity },
      });
    },
    async loadData() {
      this.loading = true;
      let resultData = await this.api_Get("entity/entity_id/" + this.id_entity);
      let data = await resultData.json();
      this.data_entity = data[0];

      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      const dataTypeAccounts = await resultTypeAccounts.json();
      for (let item of dataTypeAccounts) {
        this.accounts.push({
          text: item.name,
          value: item.id,
          dgii_category_id: item.dgii_category_id,
        });
      }
      // let reslutprovincies = await this.api_Get("accounting/provincies");
      // this.provincies = await reslutprovincies.json();
      let resultDocuments = await this.api_Get("accounting/documents");

      this.documents = await resultDocuments.json();

      let resultCreditDays = await this.api_Get("accounting/credit_days");
      let credit_days = await resultCreditDays.json();

      let resultTypesNcf = await this.api_Get("accounting/nfc_types");
      let ncfTypes = await resultTypesNcf.json();

      let resultWithholdings = await this.api_Get("accounting/withholdings");
      let withholdings = await resultWithholdings.json();

      if (this.data_entity.is_customer) {
        if (
          this.data_entity.customer_details[0].customer.credit_day_id != null
        ) {
          this.selectedCreditCustomers = credit_days.find(
            (element) =>
              element.id ==
              this.data_entity.customer_details[0].customer.credit_day_id
          );
        }

        if (this.data_entity.customer_details[0].customer.nfc_type_id != null) {
          this.selectedNfcCustomers = ncfTypes.find(
            (element) =>
              element.id ==
              this.data_entity.customer_details[0].customer.nfc_type_id
          );
        }
        if (
          this.data_entity.customer_details[0].customer.withholding_id != null
        ) {
          this.selectedRetencionesClientes = withholdings.find(
            (element) =>
              element.id ==
              this.data_entity.customer_details[0].customer.withholding_id
          );
        }
        if (
          this.data_entity.customer_details[0].customer.account_payable_id !=
          null
        ) {
          this.selectedAccountClientes = this.accounts.find(
            (element) =>
              element.value ==
              this.data_entity.customer_details[0].customer.account_payable_id
          );
        }
      }

      if (this.data_entity.is_supplier) {
        if (
          this.data_entity.supplier_details[0].supplier.account_receivable_id !=
          null
        ) {
          this.selectedAccountSuplidor = this.accounts.find(
            (element) =>
              element.value ==
              this.data_entity.supplier_details[0].supplier
                .account_receivable_id
          );
        }

        if (
          this.data_entity.supplier_details[0].supplier.withholding_id != null
        ) {
          this.selectedRetencionesSuplidor = withholdings.find(
            (element) =>
              element.id ==
              this.data_entity.supplier_details[0].supplier.withholding_id
          );
        }

        if (this.data_entity.supplier_details[0].supplier.nfc_type_id != null) {
          this.selectedNfcSuppliers = ncfTypes.find(
            (element) =>
              element.id ==
              this.data_entity.supplier_details[0].supplier.nfc_type_id
          );
        }

        if (
          this.data_entity.supplier_details[0].supplier.credit_day_id != null
        ) {
          this.selectedCreditSuppliers = credit_days.find(
            (element) =>
              element.id ==
              this.data_entity.supplier_details[0].supplier.credit_day_id
          );
        }
        // this.selectedNfcSuppliers = ncfTypes.find(
        //   (element) =>
        //     element.id ==
        //     this.data_entity.supplier_details[0].supplier.nfc_type_id
        // );
        // this.selectedCreditSuppliers = credit_days.find(
        //   (element) =>
        //     element.id ==
        //     this.data_entity.supplier_details[0].supplier.credit_days
        // );
      }
      this.loadCredits();
      this.loading = false;
    },
    async loadCredits() {
      let data = {
        entity_id: this.id_entity,
        busines_unit: undefined,
      };
      let responseRequest = await this.api_Post(
        "transactions/invoice_sales",
        data
      );

      if (responseRequest.status == 200) {
        let data = await responseRequest.json();
        this.invoice_sales = data;
      }
      let responseRequest2 = await this.api_Post(
        "transactions/invoice_shop",
        data
      );

      if (responseRequest2.status == 200) {
        let data2 = await responseRequest2.json();
        this.invoice_shop = data2;
      }
    },
    initialize() {
      this.id_entity = atob(this.$route.params.id);
      this.loadData();
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style></style>
