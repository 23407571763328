<template>
  <v-card class="rounded-xl" width="100%" height="100%">
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <v-data-table
            v-if="loading"
            id="tabla"
            :headers="headers"
            :items="items"
            item-key="item_id"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat id="toolbar">
                <v-toolbar-title>Items</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  class="text-xs-center"
                  v-model="search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                >
                </v-text-field>
                <v-spacer></v-spacer>
                <v-icon @click="$emit('close-item')">close</v-icon>
              </v-toolbar>
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <v-avatar size="40">
                <v-img :src="item.image"></v-img>
              </v-avatar>
            </template>
            <template v-if="showWarehouse" v-slot:[`item.warehouse`]="{ item }">
              <v-autocomplete
                v-if="item.warehouse_discount != null"
                v-model="item.warehouse_discount"
                :items="getWarehouse(item)"
                color="cyanText"
                label="Almacen"
                item-value="id"
                item-text="name"
                outlined
                required
                dense
                hide-details
                single-line
                return-object
              >
              </v-autocomplete>
              <span v-else>--</span>
              <!-- <v-row no-gutters dense justify="center">
                <span>{{ item }}</span>
              </v-row> -->
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              {{ item.warehouse_discount.quantity }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span>{{
                currencyFormat(
                  type == 1 ? item.cost_price : item.sale_price * currencie_rate
                )
              }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    large
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="selectItem(item)"
                  >
                    add
                  </v-icon>
                </template>
                <span>Agregar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({
    items: [],
    loading: false,
    search: "",
    itemsWarehouse: [],
    headers: [
      {
        text: "ID",
        align: "start",
        value: "item_id",
        width: "5%",
      },
      { text: "Imagen", value: "image", width: "10%" },
      { text: "Descripción", value: "item_name" },
      { text: "Unidad", value: "unit_name", width: "10%", align: "center" },
      { text: "Categoría", value: "item_categorie_name", width: "10%" },
      { text: "Almacén", value: "warehouse", align: "center" },

      { text: "Cantidad", value: "quantity", width: "8%", align: "center" },
      { text: "Precio", value: "price", width: "10%", align: "end" },
      {
        text: "Categoría de cuenta",
        value: "account_categorie_name",
        align: " d-none",
      },
      { text: "Acciones", value: "actions", sortable: false, width: "8%" },
    ],

    editedIndex: -1,
    editedItem: [],
  }),
  props: {
    data: {
      type: Object,
    },
    type: {
      type: Number,
    },
    showWarehouse: {
      type: Boolean,
      default: true,
    },
    removeItemSelected: {
      type: Boolean,
      default: true,
    },
    currencie_rate: {
      type: String,
      default: 1,
    },
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    selectItem(item) {
      this.$emit("selected-item", item);

      if (this.removeItemSelected == true) {
        let editIndex = this.items.indexOf(item);
        this.items.splice(editIndex, 1);
      }
    },
    getWarehouse(items) {
      let result = [];

      for (let ware of items.item_in_warehouses) {
        for (let itWare of this.itemsWarehouse) {
          if (ware.warehouse_id == itWare.id) {
            result.push({
              id: ware.warehouse_id,
              name: itWare.name,
              quantity: ware.quantity,
            });
          }
        }
      }

      return result;
    },
    async getImage(item) {
      let url = "";
      if (item.item_image_url != null) {
        url = await this.get_storage("items_images", item.item_image_url);
      }

      return url;
    },
    getIndexWarehouse(item) {
      let a = 0;
      for (let itWare of this.itemsWarehouse) {
        if (itWare.id == item) {
          return a;
        }
        a++;
      }
    },
    async init() {
      this.loading = false;
      if (this.showWarehouse == false) {
        this.headers[5].align = " d-none";
      }
      // let resultCatalogues = await this.api_Get(
      //   "inventory/catalogues/details/" + this.warehouse_id
      // );
      // let data = await resultCatalogues.json();
      const warehouses = await this.api_Get(
        "inventory/warehouseForLocation/" + this.data.location
      );
      this.itemsWarehouse = await warehouses.json();

      const inventory_value = await this.api_Post("inventory/inventory_value", {
        date: moment().format("YYYY-MM-DD"),

        business_unit_id: this.data.business,
        location_id: this.data.location,
      });
      const data = await inventory_value.json();

      if (inventory_value.status == 200) {
        for (let item of data) {
          item.warehouse_discount = this.getWarehouse(item)[0];
          if (
            item.item_image_url == "" ||
            item.item_image_url == null ||
            item.item_image_url == "null" ||
            item.item_image_url == undefined
          ) {
            item.image = "image-not-aviable.jpg";
          } else {
            item.image = "image-not-aviable.jpg";
            // item.image = await this.get_storage(
            //   "items_images",
            //   item.item_image_url
            // );
          }
        }
        this.items = data;
      } else {
        this.$toast.error(data.error);
      }

      this.loading = true;
    },
  },
};
</script>

<style></style>
