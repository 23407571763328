<template>
  <v-flex>
    <v-card class="rounded-xl" width="100%" height="100%">
      <v-toolbar flat dark color="transparent">
        <span class="font-weight-semibold" style="color: black; font-size: 2vh">Pagar factura</span>
        <v-spacer></v-spacer>
        <v-btn @click="close" icon dark>
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> </v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-3" justify="center">
          <span class="font-weight-normal" style="color: #616161; font-size: 2.4vh">TOTAL
          </span>
          <span class="font-weight-normal mx-2" style="color: black; font-size: 2.8vh">
            RD$ {{ currencyFormat(amount) }}</span>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-divider class="mx-2 mt-2">Metodo de pago</v-divider>
          </v-col>
        </v-row>

        <v-item-group v-if="typePayment == null" mandatory>
          <v-container>
            <v-row>
              <v-col v-for="item in newPayments" :key="item.id" cols="12" md="4">
                <v-item v-slot="{ active, toggle }">
                  <v-card outlined class="align-center fill-height" height="10.5vw" width="90%"
                    @click="active, selectType(item)">
                    <v-container fill-height>
                      <v-row dense no-gutters align="center" justify="center">
                        <v-col cols="12" md="12" align="center">
                          <v-icon color="primary" size="4vw">{{
                            getIcon(item.id)
                          }}</v-icon>
                        </v-col>
                      </v-row>
                      <v-row dense no-gutters style="margin-top: -20px" align="center" justify="center">
                        <v-col cols="12" md="12" align="center">
                          <span style="color: #616161; font-size: 0.8vw" class="font-weight-bold flex-grow-1 text-center">
                            {{ item.name }}
                          </span>
                        </v-col>
                      </v-row></v-container>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
        <!-- <v-btn-toggle v-model="toggle_exclusive" borderless group mandatory>
            <v-btn v-for="item of newPayments" :key="item.id">
              {{ item.name }}
            </v-btn>
          </v-btn-toggle> -->
      </v-card-text>

      <v-card-text v-if="typePayment != null">
        <v-card flat>
          <v-row class="mr-0" justify="end">
            <v-btn color="primary" text @click="typePayment = null">Cambiar metodo</v-btn>
          </v-row>

          <v-row>
            <v-card-text v-if="typePayment == 1">
              <v-row justify="center">
                <v-col cols="12" md="11">
                  <v-row>
                    <span>POR PAGAR</span>
                    <v-divider class="mt-2 mx-2"></v-divider>
                    <span>RD${{ amount }}</span>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-container>
                  <v-col cols="12" md="4">
                    <v-text-field v-on:keyup.enter="sendPayments" ref="amountCash" v-model="selectedCash" outlined dense
                      autofocus :label="getLabelSelected()">
                    </v-text-field>
                  </v-col>
                </v-container>
              </v-row>

              <v-row class="mt-2">
                <v-divider class="mt-2 mx-2"></v-divider><span>OPCIONES RÁPIDAS</span>
                <v-divider class="mt-2 mx-2"></v-divider>
              </v-row>
              <v-row>
                <v-container>
                  <v-row justify="center" class="mt-1">
                    <v-btn @click="selectedCash = amount" class="mx-3" outlined>
                      RD {{ amount }}
                    </v-btn>
                    <v-btn @click="
                      selectedCash = (
                        parseFloat(amount) +
                        parseFloat(amount) * 0.1
                      ).toFixed(2)
                      " class="mx-3" outlined>
                      RD
                      {{
                        (parseFloat(amount) + parseFloat(amount) * 0.1).toFixed(
                          2
                        )
                      }}
                    </v-btn>
                    <v-btn @click="
                    selectedCash = (
                      parseFloat(amount) +
                      parseFloat(amount) * 0.2
                    ).toFixed(2)
                      " class="mx-3" outlined>
                      RD
                      {{
                        (parseFloat(amount) + parseFloat(amount) * 0.2).toFixed(
                          2
                        )
                      }}</v-btn>
                  </v-row>
                </v-container>
              </v-row>
            </v-card-text>
            <v-card-text v-if="typePayment == 2">
              <v-row justify="center">
                <v-col cols="12" md="11">
                  <v-row>
                    <span>POR PAGAR</span>
                    <v-divider class="mt-2 mx-2"></v-divider>
                    <span>RD${{ amount }}</span>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-container>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="selectedCash" v-on:keyup.enter="sendPayments" outlined dense ref="amountCash"
                      autofocus :label="getLabelSelected()">
                    </v-text-field>
                  </v-col>
                </v-container>
              </v-row>

              <v-row class="mt-2">
                <v-divider class="mt-2 mx-2"></v-divider><span>Tipo de tarjera</span>
                <v-divider class="mt-2 mx-2"></v-divider>
              </v-row>
              <v-row>
                <v-container>
                  <v-row justify="center" class="mt-1">
                    <v-btn-toggle class="rounded-lg mr-2" mandatory color="primary">
                      <v-btn small> Debito </v-btn>
                      <v-btn small> Credito </v-btn>
                    </v-btn-toggle>
                  </v-row>
                </v-container>
              </v-row>
            </v-card-text>

            <v-card-text v-if="typePayment == 3">
              <v-row justify="center">
                <v-col cols="12" md="11">
                  <v-row>
                    <span>POR PAGAR</span>
                    <v-divider class="mt-2 mx-2"></v-divider>
                    <span>RD${{ amount }}</span>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-container>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="selectedCash" v-on:keyup.enter="sendPayments" outlined ref="amountCash" dense
                      autofocus :label="getLabelSelected()">
                    </v-text-field>
                  </v-col>
                </v-container>
              </v-row>
            </v-card-text>
            <v-card-text v-if="typePayment == 4">
              <v-row justify="center">
                <v-col cols="12" md="11">
                  <v-row>
                    <span>POR PAGAR</span>
                    <v-divider class="mt-2 mx-2"></v-divider>
                    <span>RD${{ amount }}</span>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-container>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="selectedCash" v-on:keyup.enter="sendPayments" outlined dense ref="amountCash"
                      autofocus :label="getLabelSelected()">
                    </v-text-field>
                  </v-col>
                </v-container>
              </v-row>
            </v-card-text>
          </v-row>
          <v-row justify="center">
            <v-data-table v-if="typePayment == newPayments.length" hide-default-footer hide-default-header id="tabla"
              dense :headers="headers" :items="payments" :search="search">
              <template v-slot:top> </template>

              <template v-slot:[`item.amount`]="{ item }">
                <v-text-field class="mt-4" type="number" min="0" outlined v-model="item.amount">
                </v-text-field>
              </template>
              <template v-slot:footer>
                <v-row justify="center">
                  <v-card style="margin-top: 10px; margin-bottom: 10px" elevation="0" width="30%" height="70px" outlined>
                    <v-layout fill-height align-center>
                      <v-col cols="12" md="6">
                        <v-row justify="center">
                          <span class="font-weight-bold"> Total </span>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-row justify="center">
                          <span class="font-weight-normal">{{ total }} </span>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="1"></v-col>
                    </v-layout>
                  </v-card>
                </v-row>
              </template>
            </v-data-table>
          </v-row> </v-card></v-card-text>
      <v-divider class="mt-3"></v-divider>
      <v-card-actions>
        <v-row no-gutters justify="center">
          <v-btn @click="close" class="mx-2" plain>cancelar</v-btn>
          <v-btn v-if="typePayment != null" @click="sendPayments" outlined>
            <v-icon>add</v-icon>
            Agregar pago
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>
<script>
export default {
  data: () => ({
    selectedCash: null,
    payments: [],
    newPayments: [],
    search: "",
    typePayment: null,
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Descripcion", value: "name" },
      { text: "Monto", value: "amount", width: "15%" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    editedIndex: -1,
    editedItem: [],
  }),
  props: {
    amount: {
      type: Number,
    },
  },
  computed: {
    total() {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      let total = 0;

      for (let item of this.payments) {
        if (item.amount > 0) {
          total += parseFloat(item.amount);
        }
      }

      return formatter.format(total.toFixed(2));
    },

    formTitle() {
      return this.editedIndex === -1 ? "Nuevo contacto" : "Editar contacto";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    // console.log(this.type);
    // if (this.type == 2) {
    //   this.cliente = true;
    // }
    // if (this.type == 3) {
    //   this.suplidor = true;
    // }
    this.initialize();
  },

  methods: {
    async getData() {
      let resultTypePayments = await this.api_Get("income/payments_types");
      let payment = [];
      for (let item of await resultTypePayments.json()) {
        this.payments.push({ id: item.id, name: item.name, amount: 0 });
        payment.push({ id: item.id, name: item.name, amount: 0 });
      }
      // this.payments = payment;
      let newPayment = [];
      newPayment = payment;
      newPayment.push({
        id: this.payments.length + 1,
        name: "Combinado",
        amount: 0,
      }); //this.newPayments = payment;
      this.newPayments = newPayment;
    },
    selectType(item) {
      this.typePayment = item.id;
    },
    getLabelSelected() {
      if (this.typePayment == 1) {
        return "Efectivo";
      }
      if (this.typePayment == 2) {
        return "Tarjeta";
      }
      if (this.typePayment == 3) {
        return "Cheque";
      }
      if (this.typePayment == 4) {
        return "Transferencia/Deposito";
      }
    },
    getIcon(icon) {
      if (icon == 1) {
        return "payments";
      }
      if (icon == 2) {
        return "credit_card";
      }
      if (icon == 3) {
        return "mail";
      }
      if (icon == 4) {
        return "account_balance";
      }
      if (icon == 5) {
        return "mdi-account-credit-card-outline";
      }
    },
    async initialize() {
      this.getData();
    },
    sendPayments() {
      let data = [];
      if (this.typePayment != null) {
        // if (this.selectedCash < 1) {
        //   this.$toast.warning("El monto deber ser mayor a cero!");
        //   this.$nextTick(this.$refs["amountCash"].focus());
        //   return;
        // }
      }
      if (this.typePayment == this.newPayments.length) {
        data = this.payments.filter((element) => element.amount > 0);
      } else {
        let newData = this.payments.find(
          (element) => element.id == this.typePayment
        );

        data.push({
          id: newData.id,
          name: newData.name,
          amount: this.selectedCash,
        });
      }

      // console.log(data);
      this.typePayment == null;
      this.$emit("selected-payments", data);
    },
    deleteItemConfirm() {
      this.entitys.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.selectedCash = null;
      this.typePayment = null;
      this.$emit("close-payments");
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style></style>
