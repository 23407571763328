<template>
    <v-container>
        <div>
            <h3 class="text-center ">Recepciones</h3>
        </div>
        <apexchart width="500" type="bar" :options="options" :series="series"></apexchart>

    </v-container>
</template>
  
<script>

export default {
   
    data() {
        return {

            options: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {
                    categories: ['Octubre', 'Septiembre', 'Noviembre', 'Diciembre', 'Enero', 'Febrero', 'Marzo']
                }
            },
            series: [{
                name: 'series-1',
                data: [30, 40, 68, 49, 60, 70, 91]
            }]
        }
    }
}
</script>
  
<style></style>