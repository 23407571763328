<template>
  <v-dialog v-model="showDialog" :max-width="size" persistent>
    <v-card>
      <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red darken-1" text @click="action(false)">{{ ButtonTitles.negative }}</v-btn>
        <v-btn color="success" text @click="action(true)">{{ ButtonTitles.positive }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({

  }),
  name: "DialogConfirm",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: 'Seguro que desea realizar esta accion?',
    },
    size: {
      type: String,
      default: "35%",
    },
    ButtonTitles: {
      type: Object,
      default() {
        return { positive: 'Si, continuar!', negative: 'No, cancelar!' }
      }
    },
  },
  methods: {
    action(value) {
      this.$emit('closeDialog', value);
    }
  },
  components: {},
};
</script>
