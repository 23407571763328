<template>
    <v-container fluid>
        <v-layout align-start>
            <v-flex>
                <v-card flat>
                    <v-row no-gutters justify="center">
                        <span style="font-size: 34px; color: #616161"><v-icon large
                                class="pr-1 pb-2">task_alt</v-icon>Aprobaciones</span>
                    </v-row>
                    <v-card-text>
                        <HeaderFilters @refreshWarehouse="getTransfers" />
                        <v-card outlined class="mt-2 pt-2">
                            <v-card-text class="pa-0">
                                <v-data-table :headers="headerTransfer" :search="search" :items="transferItemFilter">
                                    <template v-slot:top>
                                        <v-toolbar flat>
                                            <v-toolbar-title>Transferencias</v-toolbar-title>
                                            <v-divider class="mx-4" inset vertical></v-divider>
                                            <span>{{ transferItemFilter.length }}</span>
                                            <v-spacer></v-spacer>
                                            <v-text-field class="text-xs-center" v-model="search" append-icon="search"
                                                label="Buscar" single-line hide-details>
                                            </v-text-field>
                                            <v-spacer></v-spacer>
                                            <v-divider class="mx-3" inset vertical></v-divider>
                                            <v-autocomplete v-model="statusFilter" :items="itemStatus" color="cyanText"
                                                label="Estatus" outlined dense hide-details item-value="id"
                                                item-text="name">
                                            </v-autocomplete>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.options`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" class="pl-3" color="success"
                                                    v-if="item.status_id === 2" @click="approve(item, 3)">
                                                    done
                                                </v-icon>
                                            </template>
                                            <span>Aprobar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" class="pl-3" color="error"
                                                    v-if="item.status_id === 2" @click="approve(item, 5)">
                                                    disabled_by_default
                                                </v-icon>
                                            </template>
                                            <span>Rechazar</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import HeaderFilters from "@/components/Inventory/HeaderFilters.vue";
export default {
    data: () => ({
        search: '',
        dialogDetail: false,
        transfers: [],
        requestTimeLine: [],
        articlesDetail: [],
        canEdit: false,
        headerTransfer: [
            {
                text: "ID",
                align: "start",
                value: "id",
            },
            { text: "Fecha", value: "created_at" },
            { text: "Solicitante", value: "use_fullname" },
            { text: "Location solicitante", value: "to_location" },
            { text: "Almacen solicitante", value: "warehouse_to" },
            { text: "Estatus", value: "status_description" },
            { text: "", value: "options", sortable: false },
        ],
        itemsFilters: {},
        itemRequest: { id: null },
        filters: {
            location: {}, warehouse: {}
        },
        itemStatus: [{ id: 2, name: 'Pendientes' }, { id: 3, name: 'Aprobadas' }, { id: 5, name: 'Rechazadas' }],
        statusFilter: 2,
    }),
    components: {
        HeaderFilters,
    },
    created() {
    },
    computed: {
        transferItemFilter() {
            if (this.statusFilter == 0) {
                return this.transfers;
            }
            if (this.statusFilter == 3) {
                return this.transfers.filter((element) => element.status_id == 3 || element.status_id == 4)
            }
            return this.transfers.filter((element) => element.status_id == this.statusFilter)
        }
    },
    watch: {

    },
    methods: {
        async getTransfers(filters) {
            this.transfers = [];
            this.filters = filters;
            if (filters.warehouse == undefined || filters.warehouse.id == null) {
                this.$toast.warning("Escoja un almacen!");
                return;
            }
            if (filters.location.id == null) {
                return;
            }
            let filtros = `WHERE FROM_WAREHOUSE_ID=${filters.warehouse.id}  AND FROM_LOCATION_ID=${filters.location.id}
		        AND CREATED_AT BETWEEN '${filters.dateStart}' AND '${filters.dateEnd}' ORDER BY CREATED_AT DESC`;

            const request = await this.api_Post("inventory/transfers", {
                filters: filtros
            });
            const data = await request.json();
            if (request.status == 200) {
                this.transfers = data;
            } else {
                this.$toast.error(data.error);
            }
        },
        async viewDetail(item_request, is_edit, is_send) {
            const request = await this.api_Get("inventory/transfersDetail/" + item_request.id);
            const data = await request.json();
            if (request.status == 200) {
                this.articlesDetail = data;
                if (is_send == true) {
                    this.save({
                        transfer_id: item_request.id,
                        saveType: 2,
                        locationFrom: item_request.from_location_id,
                        warehouseFrom: item_request.from_warehouse_id
                    })
                } else {
                    this.canEdit = is_edit;
                    this.requestTimeLine = item_request.time_line_status;
                    this.itemRequest = item_request;
                    this.dialogDetail = true;
                }

            } else {
                this.$toast.error('Error al consultar el detalle!.' + data.error);
            }

        },
        async approve(item, approval_type) {
            try {
                const request = await this.api_Post("inventory/transfers_approve", {
                    warehouse_transfer_id: item.id,
                    warehouse_approval_type_id: approval_type,
                    user_id: this.$store.state.auth.user.user.user_id
                });
                const data = await request.json();
                if (request.status == 200) {
                    this.$toast.success(approval_type == 3 ? 'Aprobado correctamente!' : 'Solicitud rechazada!');
                    this.getTransfers(this.filters);
                } else {
                    this.$toast.error('Error!.' + data.error);
                }
            } catch (error) {

            }
        },
    }
}
</script>