<template>
  <v-layout align-start>
    <v-flex>
      <v-card outlined>
        <v-row no-gutters justify="center">
          <span style="font-size: 20px; padding-top: 1rem"> CAJAS </span>
        </v-row>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="3" md="2" class="pt-0 pb-0">
              <v-autocomplete dense v-model="filters.business" :items="$store.state.auth.bussines_units"
                label="Unidad de negocio" color="cyanText" prepend-inner-icon="corporate_fare" outlined
                required></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="2" class="pt-0 pb-0">
              <v-autocomplete dense v-model="filters.location" :items="$store.state.auth.locations.filter(
                (element) => element.bus_id == filters.business
              )
                " color="cyanText" label="Location" prepend-inner-icon="my_location" outlined required clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="2" class="pt-0 pb-0">
              <v-autocomplete dense v-model="filters.department_id" :items="itemsDepartment" return-object
                color="cyanText" label="Departamento" prepend-inner-icon="business" outlined required clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="2" class="pt-0 pb-0">
              <v-autocomplete dense v-model="filters.chart_of_account" :items="chartOfAccount" color="cyanText"
                return-object label="Cuenta contable" outlined required clearable></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="1" md="1" class="pt-0 pb-0">
              <v-row class="pt-2" justify="center" no-gutters>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" class="mr-2" size="40px" v-bind="attrs" v-on="on" @click="loadBoxes()"
                      :loading="loadingFilter">
                      search
                    </v-icon>
                  </template>
                  <span>Buscar cajas con estos parametros</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
          <v-card outlined class="mt-4 pt-3">
            <v-card-text>
              <v-data-table :headers="headersBox" :search="search" :items="boxes">
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Cajas</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <span>{{ boxes.length }}</span>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" single-line
                      hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-divider class="mx-3" inset vertical></v-divider>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="success" class="mr-2" v-bind="attrs" v-on="on" outlined @click="addNewBox()">
                          Nueva caja
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>
                      <span>Crear nueva caja</span>
                    </v-tooltip>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editBox(item)">
                        edit
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.show`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="primary" @click="showBox(item)">
                        read_more
                      </v-icon>
                    </template>
                    <span>Historico</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-dialog v-model="dialogNewBox" max-width="50%">
          <v-card outlined>
            <v-card-title class="mb-3">
              <span>{{ titleDialogBox }}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogNewBox = false">
                <v-icon>clear</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row justify="center">
                  <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                    <v-text-field class="text-xs-center" v-model="newBox.box_name" append-icon="shopping_cart"
                      label="Nombre" outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                    <v-autocomplete @change="getMoneyBusiness" v-model="newBox.business_unit_id"
                      :items="$store.state.auth.bussines_units" label="Unidad de negocio" color="cyanText" outlined
                      required>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                    <v-autocomplete v-model="newBox.location_id" :items="$store.state.auth.locations.filter(
                      (element) => element.bus_id == newBox.business_unit_id
                    )
                      " color="cyanText" label="Location" outlined required>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                    <v-autocomplete v-model="newBox.currencies" :items="currencies" item-text="code" multiple
                      return-object color="cyanText" label="Monedas" outlined required>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                    <v-autocomplete v-model="newBox.pay_methods" :items="payments" item-text="name" multiple return-object
                      color="cyanText" label="Tipo de pagos" outlined required>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                    <v-autocomplete v-model="newBox.vouchers" :items="vouchers" item-text="name"
                      :multiple="isMultipleVouchers" return-object color="cyanText" label="Comprobantes" outlined
                      required>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                    <v-autocomplete v-model="newBox.chart_of_account_id" :items="chartOfAccount" color="cyanText"
                      label="Cuenta contable" outlined required>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                    <v-autocomplete v-model="newBox.payment_plan" :items="payment_plans" item-text="name" item-id="id"
                      color="cyanText" label="Cantidad de cuotas" outlined required>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                    <v-text-field class="text-xs-center" v-model="newBox.pin" label="Pin" outlined></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
               
                  </v-col>


                  <!-- <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                                        <v-text-field class="text-xs-center" v-model="lastBoxUpdate"
                                            label="Ultima actualizacion" outlined readonly></v-text-field>
                                    </v-col> -->
                </v-row>
                <v-row>
                <v-checkbox v-model="printer_setting.red" label="RED"
                        @change="printer_setting.red == true ? printer_setting.usb = false : ''" hide-details></v-checkbox>
                      <v-checkbox v-model="printer_setting.usb" label="USB"
                        @change="printer_setting.usb == true ? printer_setting.red = false : ''" hide-details></v-checkbox>
              </v-row>
                <v-row>
                  


                  <v-col  v-if="printer_setting.usb===true"  cols="12" sm="3" md="6" class="pt-2 pb-0">
              <v-text-field dense class="text-xs-center" v-model="printer_setting.name" label="Nombre" outlined></v-text-field>
            </v-col>

                  <v-col v-if="printer_setting.red===true" cols="12" sm="3" md="4" class="pt-0 pb-0">
              <v-text-field dense  class="text-xs-center" v-model="printer_setting.ip_printer" label="IP PRINTER" outlined></v-text-field>
            </v-col>

                  <v-col v-if="printer_setting.red===true"  cols="12" sm="3" md="4" class="pt-0 pb-0">
              <v-text-field dense class="text-xs-center" v-model="printer_setting.ip_server" label="IP SERVER" outlined></v-text-field>
            </v-col>

                  <v-col v-if="printer_setting.red===true" cols="12" sm="3" md="4" class="pt-0 pb-0">
              <v-text-field dense  class="text-xs-center" v-model="printer_setting.puerto_server" label="PORT" outlined></v-text-field>
            </v-col>
              </v-row>
            
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="updateBox()" :loading="loadingSave">
                {{ is_new_box == true ? "Guardar" : "Actualizar" }}
              </v-btn>
              <v-btn color="error" text @click="dialogNewBox = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>

      <v-dialog v-model="showDialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent>
        <v-card flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="showDialog = false">
                Cerrar <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-stepper flat v-model="page">
            <v-stepper-items>
              <v-stepper-content v-for="nPage in steps" :key="`${nPage}-content`" :step="nPage">
                <v-card v-show="page === 1" outlined width="100%">
                  <v-card-text>
                    <v-data-table :headers="headersEntries" :search="searchEntries" :items="boxEntries"
                      :loading="loadingEntries">
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>Entradas</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <span>{{ boxEntries.length }}</span>
                          <v-spacer></v-spacer>
                          <v-text-field class="text-xs-center" v-model="searchEntries" append-icon="search" label="Buscar"
                            single-line hide-details>
                          </v-text-field>
                          <v-spacer></v-spacer>
                          <v-menu :v-model="menuStartEntries == true" close-on-content-click :nudge-right="40"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="startEntryFormatDMY" label="Desde" class="text-xs-center mt-2"
                                append-icon="today" color="cyanText" readonly v-bind="attrs" v-on="on"
                                outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="filterEntries.start_date" @input="loadEntries()"></v-date-picker>
                          </v-menu>
                          <v-menu :v-model="menuEndEntries == true" close-on-content-click :nudge-right="40"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="endEntryFormatDMY" class="text-xs-center mt-2 ml-1" label="Hasta"
                                append-icon="insert_invitation" color="cyanText" readonly v-bind="attrs" v-on="on"
                                outlined>
                              </v-text-field>
                            </template>
                            <v-date-picker v-model="filterEntries.end_date" @input="loadEntries()"></v-date-picker>
                          </v-menu>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.viewEntries`]="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="loadInvoices(item)">
                              read_more
                            </v-icon>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
                <v-card v-show="page === 2" flat>
                  <v-card-text>
                    <v-card flat width="100%" class="pb-0 mb-0">
                      <v-row>
                        <v-col align-self="start" cols="12" md="2" v-for="total in payments"
                          :key="total.currency_code + total.payment_type">
                          <v-text-field v-model="total.total_amount" class="text-sm-center" :label="total.payment_type +
                            ' (' +
                            total.currency_code +
                            ')'
                            " color="cyanText" readonly outlined></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card outlined class="mt-1">
                      <v-data-table :headers="headersInvoices" :search="searchInvoices" :items="invoices"
                        :loading="loadingInvoices">
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-toolbar-title>
                              <v-btn text color="error" @click="page = 1">
                                <v-icon>arrow_back</v-icon> Volver
                              </v-btn>
                            </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-text-field class="text-xs-center" v-model="searchEntries" append-icon="search"
                              label="Buscar" single-line hide-details>
                            </v-text-field>
                            <v-spacer></v-spacer>
                            <span>{{ invoices.length }}</span>
                            <v-divider class="mx-3" inset vertical></v-divider>
                            <span>Facturas</span>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.viewInvoice`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editBox(item)">
                                read_more
                              </v-icon>
                            </template>
                            <span>Editar</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:[`item.invoice_number`]="{ item }">
                          <v-hover v-slot="{ hover }">
                            <span @click="showInvoice(item)"
                              class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                                color: hover ? 'red' : 'black',
                                cursor: hover ? 'pointer' : '',
                                'text-decoration': hover ? 'underline' : '',
                              }">
                              {{ item.invoice_number }}</span>
                          </v-hover>
                        </template>
                      </v-data-table>
                    </v-card>
                    <!-- <v-card outlined class="mt-3">
                                            <v-data-table :headers="headersPayments" :items="payments">
                                            </v-data-table>
                                        </v-card> -->
                  </v-card-text>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>
<script>
import moment from "moment";
export default {
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    time: null,
    test: null,
    modal2: false,
    modal1: false,
    steps: 2,
    page: 1,
    filters: {
      business: "",
      location: "",
      department_id: "",
      chart_of_account: "",
    },
    newBox: {
      box_id: "",
      business_unit_id: "",
      location_id: "",
      chart_of_account_id: "",
      department_id: "",
      box_name: "",
      updated_at: "",
      vouchers: [],
      currencies: [],
      pay_methods: [],
      payment_plan: 1,
      printer_id: "",
    },
    boxes: [],
    search: "",
    itemsDepartment: [],
    chartOfAccount: [],
    vouchers: [],
    currencies: [],
    payments: [],
    dialogConfirm: false,
    titleDialogBox: "Nueva caja",
    is_new_box: false,
    titleDialogConfirm: "Seguro que desea realizar esta acción?",
    dialogNewBox: false,
    loadingSave: false,
    loadingFilter: false,
    headersBox: [
      {
        text: "# Caja",
        align: "start",
        value: "box_id",
      },
      { text: "Nombre", value: "box_name" },
      { text: "Location", value: "location_description" },
      { text: "Departamento", value: "department" },
      { text: "Cuenta", value: "chart_of_account_name" },
      { text: "Fecha de creacion", value: "created_at" },
      { text: "Creado por", value: "created_by_fullname" },
      { text: "", value: "actions", sortable: false },
      { text: "", value: "show", sortable: false },
    ],
    isMultipleVouchers: true,
    headersEntries: [
      {
        text: "Entrada",
        align: "start",
        value: "box_entry_id",
      },
      { text: "Usuario", value: "use_fullname" },
      { text: "Apertura", value: "open_at" },
      { text: "Monto apertura", value: "open_amount" },
      { text: "Cierre", value: "close_at" },
      { text: "Monto cierre", value: "close_amount" },
      { text: "Estado", value: "status" },
      { text: "", value: "viewEntries", sortable: false },
    ],
    payments: [],
    headersPayments: [
      {
        text: "Tipo",
        align: "start",
        value: "payment_type",
      },
      { text: "Moneda", value: "currency_code" },
      { text: "Total", value: "total_amount" },
    ],
    headersInvoices: [
      {
        text: "ID",
        align: "start",
        value: "invoice_number",
      },
      { text: "Cliente", value: "entity_name" },
      { text: "RNC", value: "entity_document_id" },
      { text: "Fecha", value: "emission_date" },
      { text: "Monto", value: "total_amount" },
      { text: "Taxes", value: "taxes_amount" },
      { text: "NCF", value: "invoice_ncf" },
      { text: "Moneda", value: "currency_code" },
      { text: "", value: "viewEntries", sortable: false },
    ],
    invoices: [],
    payment_plans: [],
    loadingInvoices: false,
    searchInvoices: "",
    searchEntries: "",
    boxEntries: [],
    showDialog: false,
    dialogTitle: "-",
    menuStartEntries: false,
    menuEndEntries: false,
    loadingEntries: false,
    filterEntries: {
      box_id: "",
      start_date: moment(moment().format("YYYY-MM-DD"))
        .subtract(1, "months")
        .format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    },
    printer_setting: {
      "usb": false,
      "red": true,
      "name": "",
      "ip_printer": "",
      "ip_server": "",
      "puerto_server": 0,
      "enabled": false
    }
  }),
  components: {},
  created() {
    this.getData();
  },
  computed: {
    lastBoxUpdate() {
      return moment(this.newBox.updated_at).format("DD/MM/YYYY");
    },
    startEntryFormatDMY() {
      return moment(this.filterEntries.start_date).format("DD/MM/YYYY");
    },
    endEntryFormatDMY() {
      return moment(this.filterEntries.end_date).format("DD/MM/YYYY");
    },
  },
  watch: {
    "newBox.vouchers": {
      deep: true,
      handler(newValue, oldValue) {
        if (this.isMultipleVouchers == false) {
          if (newValue != undefined) {
            const filteredVouchers = newValue.filter(
              (voucher) => voucher.id === 0
            );
            if (filteredVouchers.length > 0) {
              this.isMultipleVouchers = false;
            } else {
              this.isMultipleVouchers = true;
            }
          }
        }
      },
    },
  },
  methods: {
    async getData() {
      for (let i = 1; i <= 20; i++) {
        this.payment_plans.push({ id: i, name: i });
      }

      this.filters.business = this.$store.state.auth.bussines_units[0].value;
      this.loadBoxes();
      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      const dataTypeAccounts = await resultTypeAccounts.json();
      for (let item of dataTypeAccounts) {
        this.chartOfAccount.push({
          text: item.name,
          value: item.id,
          dgii_category_id: item.dgii_category_id,
        });
      }
      this.itemsDepartment = await this.g_departments();

      let resultNcfTypes = await this.api_Get("accounting/nfc_types");
      this.vouchers = await resultNcfTypes.json();
      this.vouchers.unshift({
        id: 0,
        code: "0",
        invoice_voucher_serie_id: 0,
        name: "SIN COMPROBANTE",
      });

      let resultPayments = await this.api_Get("income/payments_types");
      this.payments = await resultPayments.json();
    },
    async loadBoxes() {
      this.loadingFilter = true;
      this.boxes = [];
      let filtros = " WHERE BUSINESS_UNIT_ID=" + this.filters.business;
      filtros += Number.isInteger(this.filters.location)
        ? " AND LOCATION_ID=" + this.filters.location
        : " ";
      //  filtros += Number.isInteger(this.filters.department_id.value) ? (' AND department_id=' + this.filters.department_id.value) : (' ');
      //   filtros += Number.isInteger(this.filters.chart_of_account.value) ? (" AND chart_of_account_CODE='" + this.filters.chart_of_account.value + "'") : (' ');
      try {
        const boxesRequest = await this.api_Post("income/boxes", {
          filters: filtros,
        });
        const boxesData = await boxesRequest.json();

        if (boxesRequest.status != 200) {
          this.$toast.error(boxesData.error);
        }
        if (boxesData.length === 0) {
          this.$toast.warning("No se econtraron caja");
        }
        this.boxes = boxesData;
        // for (let item of boxesData) {

        //     this.boxes.push({
        //         "box_id": item.box_id,
        //         "box_name": item.box_name,
        //         "business_unit_id": item.business_unit_id,
        //         "business_unit_description": item.business_unit_description,
        //         "location_id": item.location_id,
        //         "location_description": item.location_description,
        //         "department_id": item.department_id,
        //        // "department": item.department_names[0].department_names_description,
        //         "chart_of_account_id": item.chart_of_account_id,
        //         "chart_of_account_code": item.chart_of_account_code,
        //         "chart_of_account_name": item.chart_of_account_name + '-' + item.chart_of_account_code,
        //         "created_at": moment(item.created_at).format('DD/MM/YYYY HH:MM a'),
        //         "created_by": item.created_by,
        //         "created_by_fullname": item.created_by_fullname,
        //         "updated_at": item.updated_at,
        //         "updated_by": item.updated_by,
        //         "updated_by_fullname": item.updated_by_fullname,
        //         "currencies":item.currencies,
        //         "pay_methods":item.pay_methods,
        //         "vouchers":item.vouchers
        //     })
        // }
      } catch (error) {
        console.log(error);
      }
      this.loadingFilter = false;
    },
    async getMoneyBusiness() {
      let resultTypeCurrencies = await this.api_Post(
        "accounting/currenciesBusiness",
        {
          business: this.newBox.business_unit_id,
          default_currency_id: 3,
          effective_date: this.date,
        }
      );
      this.currencies = await resultTypeCurrencies.json();
      this.newBox.currencies = this.currencies.filter((element) =>
        this.newBox.currencies.some((currency) => currency.id === element.id)
      );
    },
    editBox(item) {
      this.newBox.business_unit_id = item.business_unit_id;
      this.getMoneyBusiness();
      this.is_new_box = false;
      this.titleDialogBox =
        "Editar " + item.box_name + " (Codigo:" + item.box_id + ")";
      this.newBox = Object.assign({}, item);
      this.printer_setting =  this.newBox.printer_setting
      this.dialogNewBox = true;
    },
    async updateBox() {
      this.loadingSave = true;

      let box_currencies = [];
      let box_pay_methods = [];
      let box_vouchers = [];
      for (let item of this.newBox.currencies) {
        box_currencies.push({
          currency_id: item.id,
        });
      }
      for (let item of this.newBox.pay_methods) {
        box_pay_methods.push({
          pay_method_id: item.id,
        });
      }

      for (let item of this.newBox.vouchers) {
        box_vouchers.push({
          invoice_voucher_type_id: item.id,
        });
      }
      let p =  this.printer_setting 
      try {
        let data={
          box_id: this.newBox.box_id,
          business_unit_id: this.newBox.business_unit_id,
          location_id: this.newBox.location_id,
          department_id: null,
          chart_of_account_id: this.newBox.chart_of_account_id,
          user_id: this.$store.state.auth.user.user.user_id,
          box_name: this.newBox.box_name,
          payment_plan: this.newBox.payment_plan,
          printer_id: this.newBox.printer_id,
          box_vouchers: box_vouchers,
          box_pay_methods: box_pay_methods,
          box_currencies: box_currencies,
          pin: this.newBox.pin,
          printer_setting: p
        }
        console.log(data)
        const newBox = await this.api_Post("income/add_or_update_box", data);
        if (newBox.status === 200) {
          this.$toast.success(
            "Caja " + this.is_new_box == true
              ? "registrada"
              : "actualizada" + " correctamente!"
          );
          this.loadBoxes();
          this.dialogNewBox = false;
        }
      } catch (error) {
        this.$toast.error(error);
        console.log(error);
      }
      this.loadingSave = false;
    },
    addNewBox() {
      this.is_new_box = true;
      this.titleDialogBox = "Crear nueva caja";
      this.newBox = {
        box_id: null,
        business_unit_id: "",
        location_id: "",
        chart_of_account_id: "",
        department_id: "",
        box_name: "",
        updated_at: "",
      };
      this.dialogNewBox = true;
    },
    showBox(item) {
      this.loadingEntries = true;
      this.filterEntries.box_id = item.box_id;
      this.dialogTitle =
        "Historico de " + item.box_name + " (" + item.box_id + ")";
      this.loadEntries(1);
      this.showDialog = true;
      this.loadingEntries = false;
    },
    async loadEntries(origin) {
      this.loadingEntries = true;
      this.boxEntries = [];
      try {
        const result = await this.api_Post("income/boxEntries", {
          box_id: this.filterEntries.box_id,
          filters:
            " AND   OPEN_AT>='" +
            this.filterEntries.start_date +
            "'" +
            (origin != 1
              ? " AND CLOSE_AT<='" + this.filterEntries.end_date + "'"
              : ""),
        });
        if (result.status == 200) {
          const data = await result.json();
          for (let item of data) {
            this.boxEntries.push({
              box_entry_id: item.box_entry_id,
              box_id: item.box_id,
              user_id: item.user_id,
              use_fullname: item.use_fullname,
              open_at: moment(item.open_at).format("DD/MM/YYYY"),
              close_at:
                item.close_at == null
                  ? "Pendiente"
                  : moment(item.close_at).format("DD/MM/YYYY"),
              open_amount: this.currencyFormat(item.open_amount),
              close_amount: this.currencyFormat(item.close_amount),
              boxe_entry_type_id: item.boxe_entry_type_id,
              status: item.status,
            });
          }
        }
      } catch (error) {
        this.$toast.error(error);
        console.log(error);
      }
      this.loadingEntries = false;
    },
    async loadInvoices(item) {
      this.loadingInvoices = true;
      this.invoices = [];
      try {
        const result = await this.api_Post("income/invoicesFilter", {
          filters: " ",
        });
        if (result.status == 200) {
          const data = await result.json();
          this.invoices = data;
          const resultPayments = await this.api_Get(
            "income/boxPaymentByEntries/" + item.box_entry_id
          );
          const dataPayments = await resultPayments.json();
          this.payments = dataPayments;

          for (let key in this.invoices) {
            this.invoices[key].emission_date = moment(
              this.invoices[key].emission_date
            ).format("DD/MM/YYYY HH:mm:ss");
            this.invoices[key].total_amount = this.currencyFormat(
              this.invoices[key].total_amount
            );
            this.invoices[key].taxes_amount = this.currencyFormat(
              this.invoices[key].taxes_amount
            );
          }
        }
      } catch (error) {
        this.$toast.error(error);
        console.log(error);
      }
      this.loadingInvoices = false;
      this.page = 2;
    },
    showInvoice(item) {
      this.$router.push({
        name: "ViewInvoice",
        params: { id: item.invoice_number },
      });

      //  window.open(routeUrl .href, '_blank');
      // let routeUrl = this.$router.resolve({
      //           path: "/ViewInvoice",
      //           query: {
      //             id: item.invoice_number
      //             }
      //     });
      //     window.open(routeUrl .href, '_blank');
    },
  },
};
</script>
