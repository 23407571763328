<template>
  <v-flex>
    <v-row justify="center" no-gutters>
      <v-col cols="12" md="12"><v-row justify="start" class="mb-3 mt-1 ml-5"> </v-row>
        <v-container v-if="!loading">
          <v-row>
            <span class="mb-4 ml-4 mt-0" style="font-size: 24px; color: #616161">Recepcion de compra #{{
              filLeft(data_receptions[0].entry_id.toString(), 12, "0")
            }}
            </span>
          </v-row>
          <v-row no-gutters class="mr-2" justify="start">
            <v-btn @click="beforeRoute" small outlined class="mr-2 rounded text-capitalize" tile
              color="primary"><v-icon>keyboard_backspace</v-icon> Volver</v-btn>
            <!-- <v-icon class="mx-2" size="35px">receipt_long</v-icon> -->
          </v-row>
          <v-row no-gutters justify="start"> </v-row>
          <v-row class="mt-4" no-gutters justify="center">
            <div class="folded_corner mt-5">
              <v-row class="mt-8" justify="center"> </v-row>
              <v-row justify="center">
                <span class="mt-0" style="font-size: 24px; color: #616161">Recepcion #{{
                  filLeft(data_receptions[0].entry_id.toString(), 12, "0")
                }}
                </span>
              </v-row>
              <v-row justify="center">
                <span class="mt-0" style="font-size: 20px; color: #616161">Orden #{{
                  filLeft(data_receptions[0].order_id.toString(), 12, "0")
                }}
                </span>
              </v-row>
              <v-row justify="center">
                <span class="mt-2" style="font-size: 18px; color: #616161">Almacen
                  {{ filLeft(data_receptions[0].warehouses_name, 12, "0") }}
                </span>
              </v-row>

              <v-row justify="center" class="mt-7">
                <v-col cols="12" md="11">
                  <v-container class="">
                    <v-data-table :headers="headers" dense :items="data_receptions[0].order_item_entries"
                      class="elevation-0 rounded-lg" hide-default-footer>
                    </v-data-table>
                  </v-container>
                </v-col>
              </v-row>

              <v-row justify="center" class="mb-10 mt-8">
                <span>Elaborado por {{ data_receptions[0].user_fullname }}</span>
              </v-row>
            </div>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-flex>
</template>

<script>
import moment from "moment";

import momentTimezone from "moment-timezone";
export default {
  data: () => ({
    dialog: false,
    loading: false,
    search: "",

    selectedAttached: "",
    loadingAttachment: false,
    dialogAttached: false,
    data_receptions: [],

    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "item_id",
      },
      {
        text: "Producto/servicio",
        align: "start",
        sortable: false,
        value: "item_name",
      },
      {
        text: "Unidad",
        align: "start",
        sortable: false,
        value: "unit_name",
      },
      // { text: "Referencia", value: "item_description" },
      { text: "Cantidad", value: "quantity", align: "end" },
    ],
  }),

  computed: {
    total() {
      let tot = 0;
      for (let item of this.data_receptions[0].order_details) {
        tot = tot + item.price * item.quantity;
      }
      return tot;
    },
  },

  created() {
    this.getData();
  },
  methods: {
    dateFormated(date) {
      //const dates = moment(date).format('DD/MM/YYYY h:mm a');
      return momentTimezone(date)
        .tz("America/Santo_Domingo")
        .format("DD/MM/YYYY h:mm a");
    },

    async getData() {
      this.loading = true;
      this.order_id = atob(this.$route.params.order_id);
      let id_receptions = atob(this.$route.params.id);

      let filters = " AND entry_id=" + id_receptions;
      const responseRequest = await this.api_Post(
        "shopping/search_entry_order",
        {
          order_id: this.order_id,
          filters: filters,
        }
      );
      if (responseRequest.status == 200) {
        this.data_receptions = await responseRequest.json();
      } else {
        this.$toast.error("error al cargar la factura!");
      }

      this.loading = false;
    },
    getDate(item) {
      return moment(item).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.folded_corner {
  position: relative;
  width: 100%;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  height: auto;
  border-radius: 5px;
  height: auto;
  /* background: #58a; */
  background: linear-gradient(to left bottom,
      transparent 50%,
      rgba(194, 193, 193, 0.4) 0) no-repeat 100% 0 / 2em 2em,
    linear-gradient(-135deg, transparent 1.5em, rgb(255, 255, 255) 0);

  /* -webkit-filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
  /* filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
}
</style>
