var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',[_c('v-btn',{staticClass:"mt-4",attrs:{"small":"","color":"primary","outlined":""},on:{"click":function($event){_vm.dialogSearchReceipt = true}}},[_c('v-icon',[_vm._v("search")]),_vm._v("Buscar recepcion ")],1),_c('v-dialog',{attrs:{"persitent":"","width":"70%"},model:{value:(_vm.dialogSearchReceipt),callback:function ($$v) {_vm.dialogSearchReceipt=$$v},expression:"dialogSearchReceipt"}},[_c('v-card',{attrs:{"width":"100%","height":"100%"}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogSearchReceipt = false}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-close")])],1),_c('v-toolbar-title')],1),_c('v-card-text',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-data-table',{attrs:{"id":"tabla","headers":_vm.headers,"items":_vm.receptions,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","id":"toolbar"}},[_c('v-toolbar-title',[_vm._v("Recepciones")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"text-xs-center",attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:`item.entry_id`,fn:function({ item }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('span',{staticClass:"primary--text font-weight-normal text-truncate font-weight-semibold",style:({
                  color: hover ? 'red' : 'black',
                  cursor: hover ? 'pointer' : '',
                  'text-decoration': hover ? 'underline' : '',
                })},[_vm._v(" "+_vm._s(_vm.filLeft(item.entry_id.toString(), 12, "0")))])]}}],null,true)})]}},{key:`item.order_id`,fn:function({ item }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('span',{staticClass:"primary--text font-weight-normal text-truncate font-weight-semibold",style:({
                  color: hover ? 'red' : 'black',
                  cursor: hover ? 'pointer' : '',
                  'text-decoration': hover ? 'underline' : '',
                })},[_vm._v(" "+_vm._s(_vm.filLeft(item.order_id.toString(), 12, "0")))])]}}],null,true)})]}},{key:`item.entity_name`,fn:function({ item }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('span',{staticClass:"primary--text font-weight-normal text-truncate font-weight-semibold",staticStyle:{"font-size":"14px"},style:({
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }),on:{"click":function($event){return _vm.viewEntity(item.entity_id)}}},[_vm._v(_vm._s(item.entity_name))])]}}],null,true)})]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{staticClass:"white--text",attrs:{"outlined":"","small":"","color":_vm.time_line_orders(item.last_status_id).color}},[_vm._v(" "+_vm._s(item.last_status_name)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"pt-10",attrs:{"justify":"center"}},[_c('v-icon',{attrs:{"size":"80px"}},[_vm._v("token")])],1),_c('v-row',{staticClass:"pb-6",attrs:{"justify":"center"}},[_vm._v(" No hay datos ")])]},proxy:true},{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"large":"","color":"blue"},on:{"click":function($event){return _vm.selectItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" done ")])]}}],null,true)},[_c('span',[_vm._v("Selecionar")])])]}}],null,true)})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }