<template>
  <v-app-bar flat color="primary" class="d-print-none" app>
    <v-app-bar-nav-icon>
      <v-icon color="white">mdi-view-headline</v-icon>
    </v-app-bar-nav-icon>

    <v-app-bar-title>
      <span style="color: white"> FINNANCE CONNECTED </span>
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <v-col md="2"> </v-col>

    <span style="color: white"> Hola, </span>
    <v-avatar class="ml-2" color="info">
      <v-icon icon="mdi-account-circle"></v-icon>
    </v-avatar>
  </v-app-bar>
</template>

<script>
import logo from "../assets/logo.svg";

export default {
  name: "HelloWorld",

  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
    importantLinks: [
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com",
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs",
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify",
      },
    ],
    logo,
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com",
      },
      {
        text: "Roadmap",
        href: "https://vuetifyjs.com/introduction/roadmap/",
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
      },
    ],
  }),
};
</script>
