<template>
  <v-container>
    <v-row justify="center" class="mb-3">
      <v-icon class="mx-2" size="35px">sync_alt</v-icon>

      <span style="font-size: 34px; color: #616161">Transaciones CxP</span>
    </v-row>

    <v-row justify="center" class="mt-1">
      <v-col cols="12" md="12">
        <v-row justify="center">
          <search-header :show_department="false" :show_location="false" :show_accounts="true" :select_week="2"
            @dataFilters="get_transactions($event)">
          </search-header>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-n6">
      <v-col cols="12">
        <v-row no-gutters justify="start">
          <v-card outlined elevation="1" width="100%" height="100px">
            <v-layout fill-height align-center justify-center>
              <v-col cols="2.4">
                <v-row justify="center">
                  <span class="subtitle-1">Cantidad Transaciones</span></v-row>
                <br />
                <v-row justify="center">
                  <span class="font-weight-bold" style="font-size: 24px; color: #58586a">{{ all_transactions.length }}
                  </span></v-row>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="2.4">
                <v-row justify="center">
                  <span class="subtitle-1">Por pagar</span></v-row>
                <br />
                <v-row justify="center">
                  <span class="font-weight-bold" style="font-size: 24px; color: #e85e48">
                    {{
                      currencyFormat(
                        data_invoices.reduce(
                          (sum, value) =>
                            sum +
                            parseFloat(value.total_amount) -
                            parseFloat(
                              value.total_paid_amount === null
                                ? 0
                                : value.total_paid_amount
                            ),
                          0
                        )
                      )
                    }}
                  </span></v-row>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="2.4">
                <v-row justify="center">
                  <span class="subtitle-1">Transito</span></v-row>
                <br />
                <v-row justify="center">
                  <span class="font-weight-bold" style="font-size: 24px; color: #e85e48">
                    {{
                      currencyFormat(
                        clients.reduce(
                          (sum, value) =>
                            sum + parseFloat(value.total_credit_amount),
                          0
                        )
                      )
                    }}
                  </span></v-row></v-col>
              <v-divider vertical></v-divider>
              <v-col cols="2.4">
                <v-row justify="center">
                  <span class="subtitle-1">Pagadas </span>
                </v-row>
                <br />
                <v-row justify="center">
                  <span class="font-weight-bold" style="font-size: 24px; color: #00c6b8">
                    {{
                      currencyFormat(
                        data_invoices.reduce(
                          (sum, value) =>
                            sum +
                            parseFloat(
                              value.total_paid_amount === null
                                ? 0
                                : value.total_paid_amount
                            ),
                          0
                        )
                      )
                    }}
                  </span></v-row></v-col>
            </v-layout>
          </v-card>
        </v-row>
        <v-row v-if="loading" justify="center">
          <v-col cols="12" md="10">
            <v-skeleton-loader type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions">
            </v-skeleton-loader>
          </v-col>
        </v-row>

        <v-data-table v-else :search="search" :headers="headersTransactions" :items="all_transactions" dense
          class="elevation-0 mt-2">
          <template v-slot:top>
            <v-toolbar class="elevation-0 rounded-lg">
              <v-toolbar-title>Transaciones</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
              <v-spacer></v-spacer>
              <v-btn @click="dialog = true" small class="mx-2" color="primary" outlined>
                <v-icon class="mx-1">payments</v-icon> Nueva transacion
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:[`item.payment_transaction_id`]="{ item }">
            <v-hover v-slot="{ hover }">
              <span @click="showTransactions(item.payment_transaction_id, 2)"
                class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                  color: hover ? 'red' : 'black',
                  cursor: hover ? 'pointer' : '',
                  'text-decoration': hover ? 'underline' : '',
                }">
                {{ filLeft(item.payment_transaction_id.toString(), 12, "0") }}
              </span>
            </v-hover>
          </template>

          <template v-slot:[`item.payment_type_name`]="{ item }">
            <v-icon class="mx-1">{{
              getIconPayments(item.invoice_payment_type_id)
            }}</v-icon>
            {{ item.payment_type_name }}
          </template>
          <template v-slot:[`item.bank_account_description_long`]="{ item }">
            <div class="d-flex align-center justify-start my-1">
              <v-img contain width="30" height="30" class="ml-n5" :src="imgBank(20)"></v-img>
              <span class="ml-n2">{{
                item.bank_account_description_long
              }}</span>
            </div>
          </template>
          <template v-slot:[`item.payment_transaction_amount`]="{ item }">
            <span style="font-size: 12px">
              {{ currencyFormat(item.payment_transaction_amount) }}
              {{ item.currency_code }}</span>
          </template>
          <template v-slot:[`item.status_id`]="{ item }">
            <v-chip text-color="white" small :color="getColor(item.status_id)">
              {{ item.status_name }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="item.status_id == 1" @click="(dialogCancelPayment = true), (selectPayments = item)" color="red"
              class="mx-2 mt-n2">
              cancel
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog transition="slide-x-transition" v-model="dialog" fullscreen>
      <v-card style="background-color: #f4f5fa">
        <v-toolbar flat dark color="transparent">
          <v-btn @click="dialog = false" icon dark>
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row justify="center" class="mt-n12" v-if="dialog">
            <span class="text-h4"> Nueva transacion </span>
          </v-row>
          <v-row justify="center">
            <search-header :show_department="false" :show_location="false" :show_accounts="true" :select_week="2"
              :show_payments="true" :show_weeks="false" @dataFilters="pendingTrasactions($event, 1)" />
          </v-row>

          <v-row class="mt-n5" justify="center">
            <v-col cols="12" md="3">
              <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="10" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined v-model="date" label="Fecha" prepend-inner-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined v-model="date2" label="Fecha contable" prepend-inner-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mt-n8" justify="center">
            <v-col cols="12">
              <v-data-table v-if="!loading" v-model="selected" item-key="invoice_payment_id" dense show-select
                :search="search" :headers="gruposTransactions" :items="transactions" class="elevation-0">
                <template v-slot:top>
                  <v-toolbar class="elevation-0 rounded-lg">
                    <v-toolbar-title>Pagos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn @click="goTransation()" small class="mx-2" color="primary" outlined>
                      <v-icon class="mx-1">payments</v-icon> Realizar transacion
                    </v-btn>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.invoice_payment_id`]="{ item }">
                  <v-hover v-slot="{ hover }">
                    <span @click="showPayments(item.invoice_payment_id)"
                      class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                        color: hover ? 'red' : 'black',
                        cursor: hover ? 'pointer' : '',
                        'text-decoration': hover ? 'underline' : '',
                      }">
                      {{ filLeft(item.invoice_payment_id.toString(), 12, "0") }}
                    </span>
                  </v-hover>
                </template>

                <template v-slot:[`item.entity`]="{ item }">
                  <template v-for="entity of groupByEntityId(item.payments_details)">
                    <span :style="{ color: colorRandom() }" :key="entity.entity_id">
                      {{ entity[0].entity_name }} <br /></span>
                  </template>
                </template>

                <template v-slot:[`item.total_amount`]="{ item }">
                  <span style="font-size: 12px">
                    {{ currencyFormat(item.total_amount) }}
                    {{ item.currency_code }}</span>
                </template>
                <template v-slot:[`item.status_id`]="{ item }">
                  <v-chip text-color="white" small :color="getColor(item.status_id)">
                    {{ item.status_name }}
                  </v-chip>
                </template>
                <!-- <template v-slot:[`item.actions`]="{ item }">
                                <v-icon v-if="item.status_id == 1"
                                    @click="(dialogCancelPayment = true), (selectPayments = item)" color="red"
                                    class="mx-2 mt-n2">
                                    cancel
                                </v-icon>
                            </template> -->
              </v-data-table>
              <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCancelPayment" transition="dialog-top-transition" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>Anular pago</v-toolbar>
        <v-card-text class="text-center">
          <div class="text-h5 pa-5 text-center">
            Seguro que deseas eliminar este pago?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="dialogCancelPayment = false">Volver</v-btn>
          <v-btn outlined>Anular pago</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
//import Filters from "@/components/FilterBussinesLocations";
import viewInvoices from "@/components/Transactions/ViewInvoicesCxC.vue";
import CheckOutCxC from "@/components/Transactions/CheckOutCxC.vue";
import moment from "moment";
export default {
  components: { viewInvoices, CheckOutCxC },
  data: () => ({
    date: moment().format("YYYY-MM-DD"),
    date2: moment().format("YYYY-MM-DD"),
    menu: false,
    menu2: false,
    loading: false,
    lasData: null,
    dialogCancelPayment: false,
    type_modal: 0,
    e1: 1,
    types_form: 0,
    laoded: true,
    dialog: false,
    search: "",
    clients: [],
    data_invoices: [],
    data_invoices_pay: [],
    selected: [],
    hasData: false,

    transactions: [],
    all_transactions: [],
    headersTransactions: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "payment_transaction_id",
      },
      {
        text: "Unidad negocio",
        value: "business_unit_description",
      },
      {
        text: "Tipo pago",
        value: "payment_type_name",
      },
      {
        text: "Banco",
        value: "bank_account_description_long",
      },
      {
        text: "Usuario",
        value: "payment_by_name",
      },
      {
        text: "Monto total",
        align: "end",
        value: "payment_transaction_amount",
      },
      {
        text: "Status",
        value: "status_id",
      },
      // { text: "Actions", value: "actions", sortable: false },
    ],
    gruposTransactions: [
      {
        text: "Grupo",
        align: "start",
        sortable: false,
        value: "invoice_payment_id",
      },
      {
        text: "Suplidor",
        value: "entity",
        width: "20%",
      },
      {
        text: "Tipo pago",
        value: "payment_type_name",
      },
      {
        text: "Usuario",
        value: "payment_by_name",
      },
      {
        text: "Monto total",
        align: "end",
        value: "total_amount",
      },
      {
        text: "Status",
        value: "status_id",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    selectPayments: null,
    lastData: null,
    selectedClients: [],
    editedIndex: -1,
    editedItem: [],
  }),

  computed: {},

  watch: {
    async types_form(val) {
      this.changeBussines(this.lastData);
    },
  },

  created() {
    // this.initialize();
  },

  methods: {
    getColor(color) {
      if (color === 1) {
        return "orange";
      }
      if (color === 2) {
        return "green";
      }
      if (color === 3) {
        return "red";
      }
      if (color === 4) {
        return "red";
      }

      return "grey";
    },
    imgBank(bank) {
      return this.getIconBank(bank);
    },
    groupByEntityId(array) {
      if (array === null) {
        return [];
      }
      const grouped = {};
      array.forEach((item) => {
        const entityId = item.entity_id;
        if (!grouped[entityId]) {
          grouped[entityId] = [];
        }
        grouped[entityId].push(item);
      });
      return grouped;
    },
    colorRandom() {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);

      // Crear cadena de color en formato hexadecimal
      let colorHex = "#" + r.toString(16) + g.toString(16) + b.toString(16);

      return colorHex;
    },

    async pendingTrasactions(data, type) {
      this.loading = true;
      if (data.accountBank != null) {
        this.lasData = data;
        let filter = "";
        if (type === 1) {
          filter = "where invoice_type_id=1 and status_id=1";
          filter +=
            data.accountBank != null
              ? ` and bank_account_id=${data.accountBank}`
              : ``;
          filter +=
            data.payments_id != null
              ? ` and payment_type_id=${data.payments_id}`
              : ``;
        }
        if (type === 2) {
          filter = ` where invoice_type_id=1 and status_id=3`;
        }
        let resultpayments = await this.api_Post(
          "transactions/viewAllPayemnts",
          {
            filters: filter,
          }
        );
        if (resultpayments.status == 200) {
          this.transactions = [];
          this.transactions = await resultpayments.json();
        }
      }
      this.loading = false;
    },
    async get_transactions(data) {
      this.loading = true;

      let filter = ` where payment_date between '${data.dateStart}' and '${data.dateEnd}' `;
      filter +=
        data.business != null && data.business != 0
          ? ` and business_unit_id=${data.business}`
          : ``;
      filter +=
        data.accountBank != null
          ? ` and bank_account_id=${data.accountBank}`
          : ``;
      filter +=
        data.payments_id != null
          ? ` and payment_type_id=${data.payments_id}`
          : ``;

      let resultTransactions = await this.api_Post(
        "transactions/viewAllTransactions",
        {
          filters: filter,
        }
      );
      if (resultTransactions.status == 200) {
        this.all_transactions = [];
        this.all_transactions = await resultTransactions.json();
      }

      this.loading = false;
    },

    getDate(item) {
      return moment(item).format("DD/MM/YYYY");
    },

    async goTransation() {
      if (this.lasData != null) {
        if (this.lasData.business === null) {
          this.$toast.warning("Debes elegir una unidad de negocio!");
          return;
        }
        if (this.lasData.payments_id === null) {
          this.$toast.warning("Debes elegir un metodo de pago!");
          return;
        }
        if (this.lasData.accountBank === null) {
          this.$toast.warning("Debes elegir una cuenta de banco!");
          return;
        }
      }
      if (this.selected.length === 0) {
        this.$toast.warning("Debes elegir un grupo");
        return;
      }
      let payments = [];
      for (let item of this.selected) {
        payments.push({ invoice_payment_id: item.invoice_payment_id });
      }

      let data = {
        in_payment_transaction_id: null,
        in_user_id: this.$store.state.auth.user.user.user_id,
        in_business_unit_id: this.lasData.business,
        in_bank_account_id: this.lasData.accountBank,
        in_invoice_payment_type_id: this.lasData.payments_id,
        in_payment_date: this.date,
        in_accounting_date: this.date2,
        in_status_id: 1,
        in_transaction_details: payments,
      };

      const responseRequest = await this.api_Post(
        "transactions/generate_transactions",
        data
      );
      if (responseRequest.status == 200) {
        let data = await responseRequest.json();

        this.$toast.success("agregado con exito!");
        this.dialog = false;
      } else {
        this.$toast.error("error al registrar la transacion!");
      }
    },
  },
};
</script>
