<template>
  <router-view />
</template>
<script>
export default {
  methods: {
    go() {
      this.$router.push({ name: `Request` });
    },
  },
};
</script>
