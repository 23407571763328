<template>
    <v-row justify="center">

        <!-- <v-col cols="12" md="7" class="mt-2 mb-2">
           
        <LineChart :title_header="'Gastos / Compras'" :data="compras" />
      </v-col> -->
        <v-col cols="12" md="12" class="mt-2 mb-2">

            <DonutChart :title_header="'Ordenes de compra '" :data="orders" />
        </v-col>
    </v-row>
</template>
  
<script>
import PieChart from "@/components/Charts/Pie.vue";
import DonutChart from "@/components/Charts/DonutChart.vue";
import LineChart from "@/components/Charts/Line.vue";
export default {
    components: {
        PieChart,
        LineChart,DonutChart
    },
    props: {
        orders: {
            type: Object,
        },

    },


}
</script>
  
<style></style>