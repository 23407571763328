<template>
  <v-container fluid>
    <v-layout align-start>
      <v-flex>
        <v-row justify="center">
          <v-col cols="10">
            <v-row justify="start">
              <span class="mx-3 mt-2" style="font-size: 34px; color: #616161">
                Unidades de negocio</span
              >
            </v-row>
            <v-row justify="start" class="mt-n1 mx-1">
              <span style="font-size: 16px; color: #757575"
                >Crea y editas las unidades de negocio
              </span>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="bussinessUnit"
              :search="search"
              class="elevation-1 mt-6"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Unidades de Negocios</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    class="text-xs-center"
                    v-model="search"
                    append-icon="search"
                    label="Buscar"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" fullscreen>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        outlined
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="clearData"
                      >
                        <v-icon>add</v-icon>
                        Nueva Unidad
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar dark color="primary">
                        <v-btn icon dark @click="dialog = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn dark text @click="dialog = false"> Save </v-btn>
                        </v-toolbar-items>
                      </v-toolbar>

                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="8">
                            <v-container class="mt-8" fluid>
                              <v-row>
                                <v-col cols="12" md="4">
                                  <v-select
                                    v-model="newBussiness.company_id"
                                    :items="itemsCompany"
                                    label="Company"
                                    item-text="description_long"
                                    item-value="id"
                                    prepend-icon="business"
                                    required
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="5">
                                  <v-text-field
                                    v-model="newBussiness.description_long"
                                    label="Nombre largo"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                  <v-text-field
                                    v-model="newBussiness.description_short"
                                    label="Nombre corto"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="newBussiness.rnc"
                                    label="RNC"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="newBussiness.rnl"
                                    label="RNL"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-card flat>
                                <v-card-title>
                                  <v-row>
                                    <v-col cols="12" md="2">
                                      <v-autocomplete
                                        :items="itemsBanks"
                                        v-model="newAccount.account_bank_id"
                                        item-text="bank_description_long"
                                        item-value="bank_id"
                                        label="Banco"
                                        required
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                      <v-autocomplete
                                        :items="itemsAccountypes"
                                        v-model="newAccount.account_type_id"
                                        item-text="description"
                                        item-value="id"
                                        label="Tipo de cuenta"
                                        required
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                      <v-text-field
                                        v-model="newAccount.account_name"
                                        label="Nombre de cuenta"
                                        autocomplete="off"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                      <v-text-field
                                        v-model="newAccount.check_digit"
                                        type="number"
                                        label="Numero de cuenta"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                      <v-autocomplete
                                        :items="itemsCurrencies"
                                        v-model="newAccount.currencie_id"
                                        item-text="description"
                                        item-value="id"
                                        label="Moneda"
                                        required
                                      >
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                      <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        small
                                        color="primary"
                                        @click="addAccount()"
                                      >
                                        <v-icon dark> add </v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-card-title>
                                <v-divider class="mx-5"></v-divider>
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th class="text-left">Banco</th>
                                        <th class="text-left">Nombre de cuenta</th>
                                        <th class="text-left">Check digit</th>
                                        <th class="text-left">Tipo de cuenta</th>
                                        <th class="text-left">Moneda</th>
                                        <th class="text-left">Estatus</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in bussiness_account"
                                        :key="item.id"
                                      >
                                        <td>
                                          {{ item.bank_description_long }}
                                        </td>
                                        <td>
                                          {{ item.account_name }}
                                        </td>
                                        <td>
                                          <v-text-field
                                            v-model="item.account_check_digit"
                                            readonly
                                            required
                                          ></v-text-field>
                                        </td>
                                        <td>
                                          {{ item.account_type_description }}
                                        </td>
                                        <td>
                                          {{ item.currency_description }}
                                        </td>
                                        <td>
                                          {{
                                            item.status_id === 1 ? "Activa" : "Inactiva"
                                          }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card>
                            </v-container>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-card class="mt-8" width="100%" height="auto" outlined>
                              <v-hover v-slot="{ hover }">
                                <v-card
                                  flat
                                  height="50vh"
                                  @click="takeImage()"
                                  :class="hover ? 'grey lighten-3' : 'grey lighten-4'"
                                >
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col cols="12" md="12" align="center">
                                        <v-file-input
                                          style="opacity: 0"
                                          name="upload"
                                          id="fileInput"
                                          v-model="url_image"
                                          @change="onFileChange"
                                          accept="image/png, image/jpeg, image/jpg"
                                        ></v-file-input>
                                        <v-icon
                                          v-if="image_storage == null"
                                          color="grey lighten-2"
                                          x-large
                                          >add_a_photo</v-icon
                                        >
                                        <v-img
                                          v-else
                                          contain
                                          :src="image_storage"
                                          width="100%"
                                          height="40vh"
                                        >
                                        </v-img>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card>
                              </v-hover>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-card-actions>
                        <v-row justify="center">
                          <v-btn color="red darken-1" text @click="close">
                            Cancelar
                          </v-btn>
                          <v-btn outlined color="primary" @click="save"> Guardar </v-btn>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="headline"
                        >Seguro que desea eliminar esta unidad?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" text @click="deleteItemConfirm"
                          >Confirmar</v-btn
                        >
                        <v-btn color="red darken-1" text @click="closeDelete"
                          >Cancelar</v-btn
                        >

                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="accent"
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      edit
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="teal lighten-2"
                      class="mr-2"
                      @click="ars(item)"
                    >
                      verified_user
                    </v-icon>
                  </template>
                  <span>Ars</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="error"
                      @click="deleteItem(item)"
                    >
                      delete_forever
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <v-row justify="center" class="pt-10">
                  <v-icon size="80px">token</v-icon>
                </v-row>
                <v-row justify="center" class="pb-6"> No hay datos </v-row>
              </template>
            </v-data-table>
          </v-col>
          <v-dialog v-model="dialogArs" max-width="50%">
            <v-card>
              <v-card-title>
                <span class="headline">Planes </span>
              </v-card-title>
              <v-card-text>
                <v-card flat>
                  <!-- <v-toolbar dark color="primary">
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>Settings</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn dark text @click="dialog = false"> Save </v-btn>
                  </v-toolbar-items>
                </v-toolbar> -->
                  <v-card-title>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          :items="itemsArs"
                          v-model="newArsPlans.id"
                          @change="loadPlans"
                          label="ARS"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          :items="itemsPlans"
                          v-model="newArsPlans.ars_plan_id"
                          :loading="loadingPlans"
                          label="Plan"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                          v-model="newArsPlans.amount"
                          label="Costo"
                          type="number"
                          autocomplete="off"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          small
                          color="success"
                          @click="addArsPlan()"
                        >
                          <v-icon dark> add </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider class="mx-5"></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Ars</th>
                          <th class="text-left">Plan</th>
                          <th class="text-left">Monto</th>
                          <th class="text-left" v-show="false">Estado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in arsPlans" :key="item.ars_plan_id">
                          <td>
                            {{ item.ars }}
                          </td>
                          <td>
                            {{ item.plan }}
                          </td>
                          <td>
                            {{ item.amount }}
                          </td>

                          <td v-show="false">
                            <v-switch v-model="item.status" inset></v-switch>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialogArs = false"
                  >Cancelar</v-btn
                >
                <v-btn color="primary" outlined @click="saveArs">Guardar</v-btn>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card> </v-dialog
        ></v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const token = localStorage.getItem("Token_let");
const cabecera = {
  "Content-Type": "application/json",
  Authorization: "bearer " + token,
};
export default {
  data: () => ({
    dialog: false,
    dialogArs: false,
    dialogImage: false,
    loadingPlans: false,
    itemsBanks: [],
    image_storage: null,
    dialogDelete: false,
    headers: [
      {
        text: "Codigo",
        align: "start",
        value: "id",
      },
      { text: "Compania", value: "description_company" },
      { text: "Unidad de Negocio", value: "description_long" },
      { text: "Nombre Corto", value: "description_short" },
      { text: "RNC", value: "rnc" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    newAccount: {
      id: "",
      account_type_id: "",
      account_type_description: "",
      account_bank_id: "",
      bank_description_short: "",
      bank_description_long: "",
      account_name: "",
      currencie_id: "",
      check_digit: "",
      status_id: "",
    },
    arsPlans: [
      {
        id: 0,
        ars: "",
        ars_plan_id: 0,
        plan: "",
        business_unit_id: 0,
        amount: "0.00",
      },
    ],
    newArsPlans: {
      id: "",
      ars_plan_id: "",
      amount: "",
    },
    unidad: 0,
    search: "",
    url_image: "",
    itemsPlans: [{ value: 0, text: "Escoja una ars" }],
    itemsCompany: [],
    itemsCurrencies: [],
    itemsAccountypes: [],
    bussinessUnit: [],
    newBussiness: {
      id: "",
      company_id: 1,
      description_company: "",
      rnc: "",
      description_short: "",
      description_long: "",
      status_id: "",
      storage_image: "",
    },
    itemsArs: [],
    bussiness_account: [],
    editedIndex: -1,
    editedItem: {
      description: "",
    },
    defaultItem: {
      descripcion: "",
    },
  }),
  components: {
    // Barra
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva unidad" : "Editar unidad";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    takeImage() {
      //this.fileInput.click();
      document.getElementById("fileInput").click();

      //this.this.dialogImage = true;
    },
    async upImage() {
      try {
        const fileInput = document.getElementById("fileInput");
        let record = await this.upload_images("logo_bussines_units", fileInput);

        return record;
      } catch (e) {
        this.$toast.error("error " + e.message);
      }
      this.dialogImage = false;
    },
    async initialize() {
      this.bussinessUnit = await this.g_bussines();
      this.itemsCompany = await this.g_companies();
      this.itemsBanks = await this.g_Bancos();
      this.itemsAccountypes = await this.g_type_account();
      this.itemsCurrencies = await this.g_currencies();
      this.itemsArs = await this.g_ars();
    },
    onFileChange() {
      this.image_storage = URL.createObjectURL(this.url_image);
    },
    addAccount() {
      if (this.newAccount.account_bank_id == "") {
        this.$toast.warning("Escoja el banco!");
        return;
      }
      if (this.newAccount.check_digit == "") {
        this.$toast.warning("Indique la cuenta de banco!");
        return;
      }
      if (this.newAccount.account_name == "") {
        this.$toast.warning("Indique el nombre de la cuenta!");
        return;
      }
      if (this.newAccount.account_type_id == "") {
        this.$toast.warning("Indique el tipo de cuenta!");
        return;
      }
      let banc_text = this.itemsBanks.find(
        (element) => element.bank_id == this.newAccount.account_bank_id
      );
      let tipeAcon_text = this.itemsAccountypes.find(
        (element) => element.id == this.newAccount.account_type_id
      );
      let currencie_text = this.itemsCurrencies.find(
        (element) => element.id == this.newAccount.currencie_id
      );
      if (
        parseInt(banc_text.bank_digits) != parseInt(this.newAccount.check_digit.length)
      ) {
        this.$toast.warning("La cantidad de digitos debe ser " + banc_text.bank_digits);
        return;
      }
      this.bussiness_account.push({
        account_type_id: this.newAccount.account_type_id,
        account_type_description: tipeAcon_text.description,
        account_bank_id: this.newAccount.account_bank_id,
        bank_description_long: banc_text.bank_description_long,
        account_name: this.newAccount.account_name,
        currency_description: currencie_text.description,
        currency_id: this.newAccount.currencie_id,
        account_check_digit: this.newAccount.check_digit,
        status_id: 0,
      });

      this.newAccount = {
        account_type_id: "",
        account_bank_id: "",
        bank_description_long: "",
        check_digit: "",
        status_id: 0,
      };
      this.Validate_edit_account = true;
    },
    async editItem(item) {
      this.editedIndex = this.bussinessUnit.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (this.editedItem.image_storage != null) {
        this.image_storage = await this.get_storage(
          "logo_bussines_units",
          this.editedItem.image_storage
        );
        console.log(this.editedItem.image_storage);
      }

      this.newBussiness = this.bussinessUnit[this.editedIndex];
      this.bussiness_account = this.newBussiness.business_unit_accounts;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.bussinessUnit.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let id_let = this.bussinessUnit[this.editedIndex].id;
      //alert(id);
      const requestOptions = {
        method: "DELETE",
        headers: cabecera,
      };
      fetch(
        this.url_api + "humanResource/bussinessUnit" + id_let,
        requestOptions
      ).then((response) => response.json());
      this.bussinessUnit.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async createAccont(data) {
      const requestOptionsAcount = {
        method: "POST",
        headers: cabecera,
        body: JSON.stringify(data),
      };
      const responseAcount = await fetch(
        this.url_api + "humanResource/accounts",
        requestOptionsAcount
      );
      const dataAcount = await responseAcount.json();
      return dataAcount[0].id;
    },
    async joinAccountBussineUnit(account_id_let, currency_id_let, business_unit_id_let) {
      const requestOptionsAcount = {
        method: "POST",
        headers: cabecera,
        body: JSON.stringify({
          account_id: account_id_let,
          currency_id: currency_id_let,
          business_unit_id: business_unit_id_let,
        }),
      };
      const responseAcount = await fetch(
        this.url_api + "humanResource/bussinessUnit/account",
        requestOptionsAcount
      );
      const dataAcount = await responseAcount.json();

      return dataAcount;
    },
    async save() {
      let a = 0;
      let imageUrl = "";
      for (let file of fileInput.files) {
        a++;
      }
      if (a > 0) {
        imageUrl = await this.upImage();
      }

      // upImage
      if (this.editedIndex > -1) {
        //Modificar
        this.newBussiness.image_storage = imageUrl;
        console.log(this.newBussiness.image_storage);
        try {
          const response = this.api_Put("humanResource/bussinessUnit", this.newBussiness);

          const data = await response;

          if (data.status == 200) {
            this.$toast.success("Registro realizado con exito!");
            //const data = await response.json();
            // console.log(data);
          } else {
            this.$toast.error("data.error");
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        //  Crear nuevo

        this.newBussiness.image_storage = imageUrl;
        const request = await this.api_Post(
          "humanResource/bussinessUnit",
          this.newBussiness
        );
        const data = await request.json();

        if (request.status == 200) {
          this.$toast.success("Registro realizado con exito!");
        } else {
          this.$toast.error("data.error");
        }
      }
      //end
      let datos = [];
      for (let item of this.bussiness_account) {
        datos.push({
          account_type_id: item.account_type_id,
          bank_id: item.account_bank_id,
          account_name: item.account_name,
          currency_id: item.currency_id,
          check_digit: item.account_check_digit,
          status_id: item.status_id,
        });
      }

      for (let item of datos) {
        if (item.status_id == 0) {
          let retorno = await this.createAccont(item);
          await this.joinAccountBussineUnit(
            retorno,
            item.currency_id,
            this.newBussiness.id
          );
        }
      }

      this.close();
    },
    clearData() {
      this.newBussiness = {
        id: "",
        company_id: 1,
        description_company: "",
        rnc: "",
        description_short: "",
        description_long: "",
        status_id: "",
      };
      this.bussiness_account.splice(0, this.bussiness_account.length);
    },
    async ars(item) {
      const result = await this.api_Get("humanResource/arsBusinessUnits/" + item.id);
      const data = await result.json();
      this.arsPlans = data;
      this.dialogArs = true;
      this.unidad = item.id;
    },
    async loadPlans() {
      this.loadingPlans = true;
      const result = await this.api_Get("humanResource/arsPlans/" + this.newArsPlans.id);
      const data = await result.json();

      this.itemsPlans = data;
      this.loadingPlans = false;
    },
    addArsPlan() {
      if (this.newArsPlans.id == "") {
        this.warningNotification("Debe escoger la ars!");
        return;
      }
      if (this.newArsPlans.ars_plan_id == "" || this.newArsPlans.ars_plan_id == 0) {
        this.warningNotification("Debe escoger el plan!");
        return;
      }
      if (this.newArsPlans.amount == "") {
        this.warningNotification("Debe indicar el precio del plan!");
        return;
      }
      let ars = this.itemsArs.find((element) => element.value == this.newArsPlans.id);
      let plan = this.itemsPlans.find(
        (element) => element.value == this.newArsPlans.ars_plan_id
      );

      this.arsPlans.push({
        ars_plan_id: this.newArsPlans.ars_plan_id,
        amount: this.newArsPlans.amount,
        business_unit_id: this.unidad,
        ars: ars.text,
        plan: plan.text,
        status_id: 1,
        status: true,
      });
      this.clearAddArs();
    },
    async saveArs() {
      const result = await this.api_Post("payroll/arsPlansInBusinessUnits", {
        plans_data: this.arsPlans,
      });
      if (result.status == 200) {
        this.$toast.success("Se guardo correctamente");
        this.dialogArs = false;
        this.clearAddArs();
      } else {
        this.$toast.error("Error al guardar!");
      }
    },
    clearAddArs() {
      this.newArsPlans.id = "";
      this.newArsPlans.ars_plan_id = "";
      this.newArsPlans.amount = "";
    },
  },
};
</script>
