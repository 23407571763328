<template>
  <v-container>
    <!-- <v-row> <invoice /> </v-row> -->
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="center" class="mt-4 mx-1 mb-2">
          <v-icon class="mx-2" size="35px">receipt_long</v-icon>

          <span style="font-size: 34px; color: #616161">Ordenes de compra</span>
        </v-row>

        <v-row justify="center" class="mb-4">
          <v-col cols="12" md="10">
            <v-row justify="center">
              <search-header @dataFilters="changeBussines($event)" />
            </v-row>
          </v-col>
        </v-row>
        <v-card class="rounded-lg">
          <v-data-table :search="search" :headers="headers" :items="orders" class="elevation-0">
            <template v-slot:top>
              <v-toolbar class="elevation-0 rounded-lg">
                <v-toolbar-title>Ordenes</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
                <v-spacer></v-spacer>
                <v-col cols="2">
                  <v-autocomplete class="mt-6" dense outlined v-model="selectedStatus" @change="showStatus" return-object
                    :items="order_status" item-text="name" item-value="value" color="cyanText" label="Status"
                    required></v-autocomplete>
                </v-col>

                <v-dialog transition="slide-x-transition" v-model="dialog" fullscreen>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="" v-bind="attrs" v-on="on">
                      <v-icon small>add</v-icon>
                      Nueva Orden
                    </v-btn>
                  </template>
                  <v-card style="background-color: #f4f5fa">
                    <v-toolbar flat dark color="transparent">
                      <v-btn icon dark @click="close">
                        <v-icon color="black">mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title> </v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-container>
                      <v-row justify="center">
                        <v-col cols="12" md="11">
                          <Order :data_order="[]" @save-order="save($event)" />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-card-text>
                      <v-row> </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.order_id`]="{ item }">
              <v-hover v-slot="{ hover }">
                <span @click="showOrder(item.order_id, false)"
                  class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }">
                  {{ filLeft(item.order_id.toString(), 12, "0") }}</span>
              </v-hover>
            </template>

            <template v-slot:[`item.entity_name`]="{ item }">
              <v-hover v-slot="{ hover }">
                <span @click="viewEntity(item.entity_id)" style="font-size: 14px"
                  class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }">{{ item.entity_name }}</span></v-hover>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip outlined small class="white--text" :color="time_line_orders(item.last_status_id).color">
                {{ item.last_status_name }}
              </v-chip>
            </template>
            <template v-slot:no-data>
              <v-row justify="center" class="pt-10">
                <v-icon size="80px">token</v-icon>
              </v-row>
              <v-row justify="center" class="pb-6"> No hay datos </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Order from "@/components/Shop/Order.vue";
export default {
  components: { Order },
  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    suplidor: false,
    cliente: false,
    disabledName: false,
    plazoPagoSuplidor: null,
    plazoPagoCliente: null,
    documents: [],
    rncValid: false,
    selectedStatus: "",
    accounts: [],
    order_status: [],
    orders: [],
    cxc: "",
    cxp: "",
    last_consult: "",
    selectedDocument: "",
    selectedtypesNcfCliente: "",
    selectedtypesNcfSuplidor: "",
    selectedProvincies: "",
    headers: [
      {
        text: "Orden",
        align: "start",

        value: "order_id",
      },
      { text: "Unidad negocio", value: "business_unit_description_long" },
      { text: "Location", value: "location_description_long" },
      { text: "Suplidor", value: "entity_name" },
      { text: "Status", value: "status" },

      // { text: "Total", value: "total_amount", align: "end" },
      // { text: "Status", value: "sta_description" },

      //{ text: "Actions", value: "actions", sortable: false },
    ],

    editedIndex: -1,
    editedItem: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva orden" : "Editar orden";
    },
  },

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    showStatus() {


      this.getData(this.last_consult);

    },
    changeBussines(data) {


      if (data.business == 0) {
        this.getData({ business: this.$store.state.auth.user.acess_business_unit_finance, location: null, dateStart: data.dateStart, dateEnd: data.dateEnd });
      }
      else {
        this.getData(data);
      }



    },
    async getData(data) {

      this.loading = true
      let filters = " "
      filters += data.business == null ? " " : " AND  BUSINESS_UNIT_ID in (" + data.business + ") "
      filters += data.location == null || data.location == 0 ? " " : " AND  LOCATION_ID IN (" + data.location + ")"
      filters += data.department_id == null || data.department_id == 0 ? "" : " AND DEPARTMENT_ID =" + data.department_id + ""
      filters +=
        " AND REQUESTED_AT::DATE BETWEEN '" +
        data.dateStart +
        "' AND '" +
        data.dateEnd +
        "' ";
      filters += this.selectedStatus.id == undefined ? "" : "AND last_status_id in  (" + this.selectedStatus.id + ") "
      this.last_consult = {
        business: data.business,
        location: data.location,
        department_id: data.department_id,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd
      }
      let resultOrders = await this.api_Post("shopping/orders", { filters: filters });

      this.orders = await resultOrders.json();
      this.loading = false
    },
    async initialize() {
      let result_orders = await this.api_Get("shopping/order_status");
      this.order_status = await result_orders.json();
      this.order_status.unshift({ value: 0, name: "Todos" });

      this.selectedStatus = this.order_status[0].value;
      // this.getData();
    },

    editItem(item) {
      this.editedIndex = this.entitys.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.entitys.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.entitys.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save(item) {
      const responseRequest = await this.api_Post("shopping/order_register", item);

      if (responseRequest.status == 200) {
        this.getData();

        let data = await responseRequest.json();

        // this.$router.push({
        //     name: "ViewInvoice",
        //     params: { id: data[0].invoice_number },
        // });

        // this.$toast.success("agregado con exito!");
        this.dialog = false;
      } else {
        this.$toast.error("error al registrar la factura!");
      }
    },
  },
};
</script>

<style></style>
