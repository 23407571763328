var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expand-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBar),expression:"showBar"}],staticClass:"overflow-hidden",attrs:{"outlined":""}},[_c('v-bottom-navigation',{attrs:{"input-value":_vm.showBar}},[_c('v-btn',{attrs:{"to":{ name: 'Articles' }}},[_c('span',[_vm._v("Productos y servicios")]),_c('v-icon',[_vm._v("local_mall")])],1),_c('v-btn',{attrs:{"to":{ name: 'InventoryManagement' }}},[_c('span',[_vm._v("Inventario")]),_c('v-icon',[_vm._v("rule")])],1),_c('v-btn',{attrs:{"to":{
        name: 'Adjustments',
      }}},[_c('span',[_vm._v("Ajustes")]),_c('v-icon',[_vm._v("autorenew")])],1),_c('v-btn',{attrs:{"to":{
        name: 'TransferRequest',
        params: {
          type: '1',
        },
      }}},[_c('span',[_vm._v("Transferencias")]),_c('v-icon',[_vm._v("sync_alt")])],1),_c('v-btn',{attrs:{"to":{
        name: 'Approvals',
      }}},[_c('span',[_vm._v("Aprobaciones")]),_c('v-icon',[_vm._v("task_alt")])],1),_c('v-btn',{attrs:{"to":{
        name: 'TransferReceivedt',
        params: {
          type: '2',
        },
      }}},[_c('span',[_vm._v("Recepcion")]),_c('v-icon',[_vm._v("archive")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }