<template>
    <v-card width="100%"  outlined> 
        <apexchart height="250%"     type="pie" :options="chartOptions" :series="series"></apexchart>
    </v-card>
</template>
<script>
export default {
    data() {
    return {
      series: [],
      chartOptions: {
        title: {
                text: "",
                align: "center",
            },
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
  
  }
    
    
  }, props: {
        title_header: {
            type: String
        },
        data: {
            type: Object,
        },
       
    },

    watch: {
        data: {
            handler: function (nuevoValor, viejoValor) {
                // Lógica para manejar el cambio del objeto filters
            
                 this.chartOptions.labels = nuevoValor.label
                 this.series = nuevoValor.series
                this.chartOptions.title.text=this.title_header
            },
            deep: true, // Para obserlet cambios profundos en el objeto filters
            immediate: true // Para ejecutar el watch inmediatamente al crear el componente
        }
    },

};
</script>