<template>
  <v-dialog v-model="showDialog" max-width="45%" persistent>
    <v-card>
      <v-card-title>
        <v-icon>filter_alt</v-icon>
        <span class="headline">Filtros</span>
        <v-spacer></v-spacer>
        <v-menu bottom left>
          <template v-slot:activator="{ }">
            <v-btn icon @click="close()">
              <v-icon>clear</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-autocomplete v-model="filters.item_type" :items="itemTypes" item-value="id" item-text="name"
              color="cyanText" label="Tipo de producto" outlined required dense clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="filters.unit" :items="units" item-value="id" item-text="name" color="cyanText"
              label="Unidad de medida" outlined required dense clearable>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-autocomplete v-model="filters.item_category" :items="categorys" item-value="id" item-text="name"
              color="cyanText" label="Categoria" outlined required dense clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="filters.account_category" :items="accountCategorys" item-value="id" item-text="name"
              color="cyanText" label="Categoria de cuenta" outlined required dense clearable>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-autocomplete v-model="filters.tax_type" :items="taxes" item-value="id" item-text="name" color="cyanText"
              label="Tipo de impuesto" outlined required dense clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete v-model="filters.inventory_product" :items="[
              { value: 0, text: 'AMBOS' },
              { value: 1, text: 'SI' },
              { value: 2, text: 'NO' },
            ]" color="cyanText" label="Inventariable" outlined required dense>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete v-model="filters.negative_sale" :items="[
              { value: 0, text: 'AMBOS' },
              { value: 1, text: 'SI' },
              { value: 2, text: 'NO' },
            ]" color="cyanText" label="Valores negativos" outlined required dense>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="filtrar">
          <v-icon> search </v-icon>
          Buscar
        </v-btn>
        <v-btn color="orange accent-3" text @click="reset">
          <v-icon> restart_alt </v-icon>
          Restablecer filtros
        </v-btn>
        <v-btn color="red darken-1" text @click="close()">
          <v-icon> clear </v-icon>
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    articles: [],
    itemTypes: [],
    taxes: [],
    categorys: [],
    accountCategorys: [],
    units: [],
    filters: {
      unit: 0,
      item_type: 0,
      item_category: 0,
      account_category: 0,
      tax_type: 0,
      inventory_product: 0,
      negative_sale: 0,
    },
  }),
  name: "ArticleFilters",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.initialize();
  },
  computed: {},
  watch: {},
  methods: {
    async initialize() {
      //----------------UNIDADES--------------------
      const resultUnits = await this.api_Get("inventory/units");
      this.units = await resultUnits.json();
      this.units.unshift({ id: 0, name: "TODO" });
      //----------------ITEM TYPES--------------------
      const resultItemTypes = await this.api_Get("inventory/itemTypes");
      this.itemTypes = await resultItemTypes.json();
      this.itemTypes.unshift({ id: 0, name: "TODO" });
      //----------------TAXES-------------------------
      const taxesRequest = await this.api_Get("accounting/taxes");
      this.taxes = await taxesRequest.json();
      this.taxes.unshift({ id: 0, name: "TODO" });
      //----------------CATEGORYS-------------------------
      const categoryRequest = await this.api_Get("inventory/categories");
      this.categorys = await categoryRequest.json();
      this.categorys.unshift({ id: 0, name: "TODO" });
      //----------------ACCOUNT CATEGORYS-------------------------
      const resultAccounts = await this.api_Get(
        "accounting/account_categories"
      );
      this.accountCategorys = await resultAccounts.json();
      this.accountCategorys.unshift({ id: 0, name: "TODO" });
      this.filters.inventory_product = 0;
      this.filters.negative_sale = 0;
    },
    async getArticles(filters) {
      //----------------ARTICULOS--------------------
      const resultArticles = await this.api_Post("inventory/articles", filters);
      this.articles = await resultArticles.json();
    },
    filtrar() {
      this.$emit("returnFilters", this.filters);
    },
    close() {
      this.$emit("closeDialog", false);
    },
    reset() {
      this.filters = {
        unit: 0,
        item_type: 0,
        item_category: 0,
        account_category: 0,
        tax_type: 0,
        inventory_product: 0,
        negative_sale: 0,
      };
    },
  },
  components: {},
};
</script>
