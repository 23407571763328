<template>
  <v-dialog v-model="showDialog" max-width="65%" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-menu bottom left>
          <template v-slot:activator="{ }">
            <v-btn icon @click="close(false)">
              <v-icon>clear</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters justify="center" class="mt-2">
          <span style="font-size: 18px; color: #616161">Transformar en</span>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-autocomplete v-model="newConversion.article_to" :items="transformations" color="cyanText" label="Articulo"
              outlined dense hide-details item-value="item_to_id" item-text="item_to_name" return-object
              @change="updateQuantity">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete v-model="newConversion.article_to.unit_to_id" :items="units" item-value="id" item-text="name"
              color="cyanText" label="Unidad de medida" outlined required dense readonly>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="newConversion.article_to.loss_percentage" outlined dense hide-details
              label="Porcentaje de perdida" type="number" :min="0" readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-text-field v-model="newConversion.cantity" outlined dense hide-details :label="maxArticle.title"
              type="number" :min="1" :max="maxArticle.value"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="newConversion.cantity_result" outlined dense hide-details label="Cantidad resultante"
              readonly></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="save" :loading="loading">
          <v-icon> transform </v-icon>
          Transformar
        </v-btn>
        <v-btn color="red darken-1" text @click="close(false)">
          <v-icon> clear </v-icon>
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    items: [{ item_id: null, iten_name: "" }],
    units: [],
    conversions: [],
    newConversion: {
      article_to: {
        item_to_id: null,
        item_to_name: "",
        unit_to_id: null,
        item_cantity_to: 0,
      },
      cantity: 1,
      cantity_result: 0,
    },
  }),
  name: "ArticleTransform",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    article: {
      type: Object,
      default() {
        return {
          article_id: null,
          business_unit_id: null,
          location_id: null,
          warehouse_id: null,
          quantity_available: 0,
          inventory_product: true,
          negative_sale: false,
        };
      },
    },
  },
  mounted() {
    this.initialize();
  },
  computed: {
    title() {
      let item = this.items.find(
        (element) => element.item_id == this.article.article_id
      );
      return `TRANSFORMAR ${item == undefined ? "articulo no encontrado" : item.item_name
        }`;
    },
    transformations() {
      let transformations_items = this.conversions.filter(
        (element) => element.item_from_id == this.article.article_id
      );
      return transformations_items;
    },
    maxArticle() {
      let max = { title: "Cantidad a transformar", value: 999 };
      if (this.article.negative_sale == false) {
        max.title += " - Maximo: " + this.article.quantity_available;
        max.value = this.article.quantity_available;
      }
      return max;
    },
  },
  watch: {
    "newConversion.cantity": function (newValue, oldValue) {
      if (newValue == null || newValue == "") {
        return;
      }
      this.updateQuantity();
    },
  },
  methods: {
    async initialize() {
      //----------------ARTICULOS--------------------
      const requestItems = await this.api_Post("inventory/catalog_items", {
        business_unit_id: this.article.business_unit_id,
      });
      this.items = await requestItems.json();
      //----------------TRANSFORMASIONES--------------------
      const requestConversions = await this.api_Get(
        "inventory/conversions/" + this.article.business_unit_id
      );
      this.conversions = await requestConversions.json();
      //----------------UNIDADES--------------------
      const resultUnits = await this.api_Get("inventory/units");
      this.units = await resultUnits.json();

      if (this.transformations.length == 0) {
        this.$toast.info(
          "Este articulo no tiene plantillas de transformacion registradas!"
        );
      }
    },
    updateQuantity() {
      if (this.newConversion.cantity < 1) {
        this.$toast.info("La cantidad a transformar no puede ser menor que 1!");
        this.newConversion.cantity = 1;
        return;
      }
      if (this.newConversion.cantity > this.maxArticle.value) {
        this.$toast.info("La cantidad excede el disponible de este articulo!");
        this.newConversion.cantity = this.maxArticle.value;
        return;
      }
      this.newConversion.cantity_result =
        parseFloat(this.newConversion.cantity) *
        parseFloat(this.newConversion.article_to.item_cantity_to);
    },
    async save() {
      try {
        if (this.newConversion.cantity_result == 0) {
          this.$toast.warning("Indique el articulo a transformar!");
          return;
        }
        this.loading = true;
        const request = await this.api_Post("inventory/do_transformation", {
          created_by: this.$store.state.auth.user.user.user_id,
          warehouse_id: this.article.warehouse_id,
          business_unit_id: this.article.business_unit_id,
          location_id: this.article.location_id,
          item_from_id: this.article.article_id,
          item_from_quantity: this.newConversion.cantity,
          item_to_id: this.newConversion.article_to.item_to_id,
          item_to_quantity: this.newConversion.cantity_result,
        });
        const data = await request.json();
        if (request.status == 200) {
          this.$toast.success("Registrado correctamente!");
          this.close(true);
        } else {
          this.$toast.error(data.error);
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
      }
      this.loading = false;
    },
    close(value) {
      this.$emit("closeDialog", value);
    },
  },
  components: {},
};
</script>
