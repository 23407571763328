<template>
  <v-container style="background-color: white" fluid>
    <v-row no-gutters class="pb-0 mb-0">
      <v-col cols="12" md="3" justify="start">
        <v-btn
          class="mx-6"
          color="error"
          outlined
          @click="clearUser(), (step = 1)"
          v-show="step == 2"
        >
          Cerrar
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" justify="center" align="center">
        <span style="font-size: 30px; color: #616161">
          <v-icon large class="pr-1 pb-2">manage_accounts</v-icon>
          Usuarios</span
        >
      </v-col>
      <v-col cols="12" md="3">
        <v-row no-gutters justify="end"> </v-row>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-card elevation="0" color="transparent" outlined v-show="step == 1">
        <v-card-text class="pt-0">
          <v-data-table
            dense
            :headers="headers"
            :items="users"
            :search="search"
            class="elevation-0 mt-6"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Usuarios</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>

                <v-spacer></v-spacer>
                <v-text-field
                  class="text-xs-center"
                  v-model="search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                >
                </v-text-field>
                <v-spacer></v-spacer>
                <v-btn text color="success" @click="clearUser(), (step = 2)"
                  ><v-icon>add</v-icon> Nuevo</v-btn
                >
              </v-toolbar>
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <v-avatar size="40">
                <v-img :src="item.image"></v-img>
              </v-avatar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    large
                    v-bind="attrs"
                    v-on="on"
                    medium
                    color="accent"
                    class="mr-2"
                    @click="editItem(item)"
                    >edit</v-icon
                  >
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-row justify="center" class="pt-10">
                <v-icon size="80px">token</v-icon>
              </v-row>
              <v-row justify="center" class="pb-6"> No hay datos </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-expand-transition>
    <v-expand-transition>
      <v-card flat v-if="step == 2">
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="12" md="8" class="pb-0 mb-0">
                <v-card outlined class="pt-0 mt-0">
                  <v-card-text>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" sm="12" md="3" class="pt-0 mt-0 pb-0">
                          <!-- <v-text-field class="text-xs-center" :rules="codeRule" v-model="user.employee_code"
                            label="Codigo empleado" :disabled="hasCode" outlined append-icon="search"
                            @click:append="validate_code()" @keyup.enter="validate_code()" maxlength="6"
                            :loading="loadSearch" dense>
                          </v-text-field> -->
                        </v-col>
                        <v-col cols="12" md="5" class="pt-0 mt-0 pb-0">
                          <v-text-field
                            class="text-xs-center"
                            v-model="user.fullname"
                            label="Nombre completo"
                            outlined
                            dense
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                          <v-text-field
                            class="text-xs-center"
                            v-model="user.username"
                            :error-messages="errorsUsername"
                            label="Nombre de usuario (Username)"
                            outlined
                            dense
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" class="pt-0 mt-0 pb-0">
                          <v-text-field
                            class="text-xs-center"
                            v-model="user.email"
                            label="Correo electronico"
                            dense
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 mt-0 pb-0">
                          <v-text-field
                            class="text-xs-center"
                            v-model="user.use_telephone"
                            label="Telefono"
                            dense
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="end">
                        <!-- <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                          <v-text-field class="text-xs-center" v-model="user.password" label="Contraseña"
                            :rules="passRule" autocomplete="off" :type="showPassword1 ? 'text' : 'password'"
                            :append-icon="showPassword1 ? 'visibility' : 'visibility_off'"
                            @click:append="showPassword1 = !showPassword1" outlined dense>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                          <v-text-field class="text-xs-center" label="Repita la contraseña" v-model="user.password2"
                            autocomplete="off" :rules="passRule" :type="showPassword2 ? 'text' : 'password'"
                            :append-icon="showPassword2 ? 'visibility' : 'visibility_off'"
                            @click:append="showPassword2 = !showPassword2" outlined dense>
                          </v-text-field>
                        </v-col> -->

                        <!-- <v-col cols="12" md="2" class="pt-1" align="center">
                          <v-btn text color="primary" @click="generatePassword">Generar <v-icon
                              class="pb-1">lock</v-icon></v-btn>
                        </v-col> -->
                        <v-col cols="12" md="2" class="pt-1" align="end">
                          <v-switch
                            label="Activo"
                            v-model="user.status"
                            @change="(dialogChangeStatus = true), (is_delete = false)"
                            class="mt-0 mb-0 pb-0"
                            color="primary"
                          >
                          </v-switch>
                        </v-col>
                      </v-row>
                      <v-row v-if="user.id == null" justify="center">
                        <v-col cols="12" md="2" class="mt-n6 mb-4">
                          <v-btn @click="loaduserlod" class="text-xs-center" outlined>
                            <v-icon> search </v-icon>
                            Buscar usuario
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-card outlined>
                        <v-row>
                          <v-col cols="12" md="12" class="pt-3 mb-3">
                            <template>
                              <v-tabs
                                v-model="tab"
                                align-with-title
                                class="d-flex justify-center"
                              >
                                <v-tabs-slider color="primary"></v-tabs-slider>
                                <template v-for="(item, i) in accesType">
                                  <v-tab v-if="item.auth == true" :key="i">
                                    <span>
                                      {{ item.description }}
                                    </span>
                                  </v-tab>
                                </template>
                              </v-tabs>
                            </template>
                            <v-tabs-items v-model="tab" class="pt-3">
                              <template v-for="(item, index) of accesType">
                                <v-tab-item
                                  href="#tab-0"
                                  v-if="item.auth == true"
                                  :key="index"
                                >
                                  <v-container fluid>
                                    <v-card flat>
                                      <v-row justify="center">
                                        <v-col
                                          cols="12"
                                          md="2"
                                          class="pt-0 mt-0"
                                          v-for="item in getPermitionList(item.id)"
                                          :key="item.id"
                                          align="center"
                                        >
                                          <v-chip
                                            class="ma-1"
                                            :color="
                                              validatePerSel(index, item.id) == true
                                                ? 'success'
                                                : 'primary'
                                            "
                                            label
                                            outlined
                                            @click="accesType[index].selected = item.id"
                                          >
                                            {{ item.description }}
                                            <v-icon
                                              color="success"
                                              small
                                              v-show="validatePerSel(index, item.id)"
                                              >task_alt</v-icon
                                            >
                                          </v-chip>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </v-container>
                                </v-tab-item>
                              </template>
                            </v-tabs-items>
                          </v-col>
                        </v-row>
                      </v-card>

                      <v-row class="mt-4">
                        <DataIterator
                          v-if="!loadingModal"
                          :all_items="constAllLocations"
                          :items_selected="locationAccess"
                          :title="{
                            all: 'Locations',
                            selected: 'Locations con accesos',
                          }"
                        />
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4" class="pb-0 mb-0">
                <v-card width="100%" outlined>
                  <v-card flat class="mb-4" outlined>
                    <v-img contain :src="user.image" height="250"> </v-img>
                  </v-card>
                  <v-card flat class="pt-2">
                    <v-container fluid class="pa-2">
                      <v-row no-gutters>
                        <v-col
                          v-for="(item, i) in accesType"
                          :key="item.id"
                          cols="12"
                          md="6"
                        >
                          <v-switch
                            :label="item.description"
                            v-model="accesType[i].auth"
                            class="mt-0 mb-0 pb-0"
                            color="primary"
                          >
                          </v-switch>
                        </v-col>
                      </v-row>

                      <v-row v-if="editedIndex != -1" justify="center">
                        <v-btn
                          :disabled="!hasCode"
                          outlined
                          color="primary"
                          @click="dialogConfirm = true"
                        >
                          Reiniciar Contraseña <v-icon small>lock_reset</v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-card>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" outlined @click="save">
                      {{ user.id == null ? "Guardar" : "Actualizar" }}
                      <v-icon small>check</v-icon>
                    </v-btn>
                    <!-- <v-btn color="error" :disabled="!hasCode" @click="(dialogChangeStatus = true), (is_delete = true)">
                      Eliminar usuario <v-icon small>delete_forever</v-icon>
                    </v-btn> -->
                    <v-btn outlined color="error" @click="step = 1">
                      Cancelar <v-icon small>undo</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-expand-transition>
    <DialogConfirm
      :showDialog="dialogConfirm"
      :dialogTitle="'Seguro que desea reiniciar la contraseña a este usuario?'"
      @closeDialog="resetPassword"
      size="45%"
      :ButtonTitles="{ positive: 'Si, reiniciar!', negative: 'No, Cancelar!' }"
    />
    <v-dialog v-model="dialogBucarUsuario" width="1000px">
      <v-card width="1000px">
        <v-container>
          <v-spacer></v-spacer>
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-text-field
                class="text-xs-center"
                v-model="search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
          <v-data-table
            :headers="headerUserOld"
            :items="userold"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-row align="center">
                <v-tooltip>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="selectedUserOld(item)"
                    >
                      Selecionar
                      <v-icon>done </v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </v-row>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import DialogConfirm from "@/components/base/DialogConfirm.vue";
import DataIterator from "@/components/base/DataIterator";
export default {
  components: {
    DataIterator,
    DialogConfirm,
  },
  data() {
    return {
      dialogConfirm: false,
      hasCode: false,
      stickyCompras: false,
      stickyPlanAhorros: false,
      stickyNomina: false,
      selectFile: undefined,
      dialogBucarUsuario: false,
      foto: undefined,
      itemGestHuman: "",
      itemNomina: "",
      itemPlanAhorros: "",
      itemsBussinesUnits: [],
      search: "",
      dialog: false,
      loadingModal: false,
      users: [],
      errorsUsername: [],
      step: 1,
      codeRule: [(v) => /^([0-9])*$/.test(v) || "Codigo no valido!"],
      passRule: [
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,10}[^'\s]/.test(
            v
          ) || "Contraseña no valida",
      ],
      loadSearch: false,
      showPassword1: false,
      showPassword2: false,
      accesProfile: [],
      accesType: [],
      BusinesUnit: [],
      itemBusinesUnit: [],
      initItemBusinesUnit: [],
      itemsLocations: [],
      constAllLocations: [],
      Locations: [],
      locationAccess: [],
      initLocations: [],
      acessCompras: [],
      tab: null,

      user: {
        employee_code: null,
        employee_id: null,
        id: null,
        fullname: null,
        username: null,
        password: null,
        password2: null,
        confirmpassword: null,
        email: null,
        use_telephone: null,
        compra: false,
        venta: false,
        contabilidad: false,
        inventario: false,
        image: "",
        type_access: [],
        user_business_units: [],
        location_access: [],
        status: true,
      },
      passwordRules: [
        (v) => !!v || "Contraseña es requerida",
        (v) => (v && v.length >= 5) || "Minimo 5 caracteres",
      ],

      editedIndex: -1,
      userold: [],
      headerUserOld: [
        { text: "Nombre", value: "use_fullname" },
        { text: "Usuario", value: "use_username" },
        { text: "Departamento", value: "dep_description" },
        { text: "Email", value: "use_email" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headers: [
        {
          text: "Codigo",
          align: "start",
          value: "use_id",
          width: "6%",
        },
        { text: "Imagen", value: "image", width: "6%" },
        { text: "Nombre", value: "use_fullname" },
        { text: "Usuario", value: "use_username" },
        { text: "Departamento", value: "dep_description" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      disablecampos: false,
      type_acces: [
        { value: null, name: "Ventas" },
        { value: null, name: "Inventario" },
        { value: null, name: "Compras" },
        { value: null, name: "Contabilidad" },
      ],
      json_user: [
        {
          business_units: [],
          locations: [],

          access_profile: {
            ventas: { name: "Ventas", auth: false, access: [] },
            compras: { name: "Compras", auth: false, access: [] },
            inventario: { name: "Inventario", auth: false, access: [] },
            contabilidad: { name: "Contabilidad", auth: false, access: [] },
          },
        },
      ],
      load_json_user: [],
    };
  },
  mounted() {
    this.initialize();
  },
  created() {
    //  this.initialize();
  },
  watch: {
    "user.username"(v) {
      if (this.editedIndex == -1) {
        this.checkUserExistence(v);
      }
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Usuario" : "Editar Usuario";
    },
  },
  methods: {
    async initialize() {
      // console.log(this.$store.state.auth.acces_finance);
      this.load_json_user = this.json_user;
      this.BusinesUnit = [];
      this.Locations = [];
      this.itemsBussinesUnits = [];
      this.itemsLocations = [];
      const responseAcessProfile = await this.api_Get("auth/accessProfiles");
      const dataAcessProfile = await responseAcessProfile.json();
      this.accesProfile = dataAcessProfile;
      const responseAcessType = await this.api_Get("auth/accessType");
      const dataAcessType = await responseAcessType.json();

      for (let item of dataAcessType) {
        this.accesType.push({
          id: item.id,
          description: item.description,
          auth: false,
          selected: null,
        });
      }

      this.getUsers();
      let resultLocations = await this.api_Get("humanResource/locations");
      let dataLocations = await resultLocations.json();
      for (let item of dataLocations) {
        // let exist = this.constAllLocations.find((element) => element.id == item.id);
        // if (exist != undefined) {
        //   continue;
        // }
        this.constAllLocations.push({
          name: item.desc_bus_long + "(" + item.desc_loc_short + ")",
          id: item.id,
          business_id: item.bus_id,
        });
      }
    },
    async getUsers() {
      const responseUsers = await this.api_Get("auth/users");

      const data = await responseUsers.json();
      for (var item of data) {
        if (item.image_storage != null) {
          //  item.image = await this.getImage(item.image_storage);

          item.image = "image-not-aviable.jpg";
        } else {
          item.image = "image-not-aviable.jpg";
        }

        //
      }
      this.users = data;
    },
    async setTypeAccess() {
      // console.log(   this.accesType.length!=0)
      if (this.accesType.length != 0) {
        if (
          this.editedItem.type_access != undefined ||
          this.editedItem.type_access != null
        ) {
          for (let item of this.editedItem.type_access) {
            console.log(item.access.type_access);
            if (item.access.type_access == 1) {
              if (item.access.status == true) {
                this.accesType[0].auth = true;
                const data = this.user.type_access.filter(
                  (element) => element.access.type_access === 1
                );

                this.accesType[0].selected = data[0].access.acces_profile;
              } else {
                this.accesType[0].auth = false;
              }
            }
            if (item.access.type_access == 2) {
              if (item.access.status == true) {
                this.accesType[1].auth = true;
                const data = this.user.type_access.filter(
                  (element) => element.access.type_access === 2
                );
                this.accesType[1].selected = data[0].access.acces_profile;
              } else {
                this.accesType[1].auth = false;
              }
            }
            if (item.access.type_access == 3) {
              if (item.access.status == true) {
                this.accesType[2].auth = true;
                const data = this.user.type_access.filter(
                  (element) => element.access.type_access === 3
                );
                this.accesType[2].selected = data[0].access.acces_profile;
              } else {
                this.accesType[2].auth = false;
              }
            }
            if (item.access.type_access == 4) {
              if (item.access.status == true) {
                this.accesType[3].auth = true;
                const data = this.user.type_access.filter(
                  (element) => element.access.type_access === 4
                );
                this.accesType[3].selected = data[0].access.acces_profile;
              } else {
                this.accesType[3].auth = false;
              }
            }
            if (item.access.type_access == 5) {
              if (item.access.status == true) {
                this.accesType[4].auth = true;
                const data = this.user.type_access.filter(
                  (element) => element.access.type_access === 5
                );
                this.accesType[4].selected = data[0].access.acces_profile;
              } else {
                this.accesType[4].auth = false;
              }
            }
            if (item.access.type_access == 6) {
              if (item.access.status == true) {
                this.accesType[5].auth = true;
                const data = this.user.type_access.filter(
                  (element) => element.access.type_access === 6
                );
                this.accesType[5].selected = data[0].access.acces_profile;
              } else {
                this.accesType[5].auth = false;
              }
            }
            if (item.access.type_access == 7) {
              if (item.access.status == true) {
                this.accesType[6].auth = true;
                const data = this.user.type_access.filter(
                  (element) => element.access.type_access === 7
                );
                this.accesType[6].selected = data[0].access.acces_profile;
              } else {
                this.accesType[6].auth = false;
              }
            }
            if (item.access.type_access == 8) {
              if (item.access.status == true) {
                this.accesType[7].auth = true;
                const data = this.user.type_access.filter(
                  (element) => element.access.type_access === 8
                );
                console.log(data);
                if (data.length > 0) {
                  this.accesType[7].selected = data[0].access.acces_profile;
                }
              } else {
                this.accesType[7].auth = false;
              }
            }
          }
        }
      }
    },
    async editItem(item) {
      this.loadingModal = true;
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.user.id = this.editedItem.use_id;
      this.user.fullname = this.editedItem.use_fullname;
      this.user.username = this.editedItem.use_username;
      this.user.email = this.editedItem.use_email;
      this.user.use_telephone = this.editedItem.use_telephone;
      this.user.image = "image-not-aviable.jpg";
      this.user.type_access = this.editedItem.type_access;
      this.user.location_access =
        item.acess_finance == undefined ? [] : item.acess_finance[0].locations;

      this.locationAccess =
        this.editedItem.acess_finance == undefined ? [] : this.editedItem.acess_finance;
      await this.setTypeAccess();
      this.hasCode = true;
      this.step = 2;
      this.loadingModal = false;
      //this.dialog = true;
    },
    async checkUserExistence(v) {
      if (this.user.id === null) {
        if (v != null) {
          if (v.length >= 4) {
            const responseUser = await this.api_Post("auth/checkUsers", { username: v });
            if (responseUser.status == 200) {
              const datauser = await responseUser.json();
              if (datauser.length != 0) {
                this.errorsUsername = ["El usuario ya existe"];
                return false;
              } else {
                this.errorsUsername = [];
                return true;
              }
            } else {
              this.$toast.error("error ");
            }
          }
        }
      }
    },
    clearUser() {
      this.locationAccess = [];
      (this.user = {
        employee_code: null,
        employee_id: null,
        id: null,
        fullname: null,
        username: null,
        password: null,
        password2: null,
        confirmpassword: null,
        email: null,
        use_telephone: null,
        compra: false,
        venta: false,
        contabilidad: false,
        inventario: false,
        image: "",
        rc_access_perfil_id: null,
        hr_access_perfil_id: null,
        nm_access_perfil_id: null,
        ph_access_perfil_id: null,
        user_business_units: [],
        status: true,
      }),
        (this.editedIndex = -1);
      for (let item of this.accesType) {
        item.auth = false;
        item.selected = null;
      }

      this.hasCode = false;
      // this.getImage("0");
    },
    async getImage(image) {
      let img = "";
      if (image != null) {
        try {
          img = await this.get_storage("image_users", image);
        } catch (err) {
          console.log(err);
        }
      } else {
        img = await this.get_storage("image_users", "vcxkjvgcwx6lun4");
      }
      return img;
    },
    getPermitionList(type) {
      let data = this.accesProfile.filter((element) => element.type_acess_id == type);

      return data;
    },

    setPermition(type, permition) {
      if (type == "compras") {
        this.load_json_user[0].access_profile.compras.access[0] = [];
        this.load_json_user[0].access_profile.compras.access[0].push(permition);
      }
      if (type == "gh") {
        return permition == this.user.hr_access_perfil_id;
      }
      if (type == "nm") {
        return permition == this.user.nm_access_perfil_id;
      }
      if (type == "ph") {
        return permition == this.user.ph_access_perfil_id;
      }
    },
    validatePerSel(type, permition) {
      return permition == this.accesType[type].selected;
    },

    generatePassword() {
      let pass = "";
      let str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";
      for (let i = 1; i <= 12; i++) {
        let char = Math.floor(Math.random() * str.length + 1);

        pass += str.charAt(char);
      }
      pass += "@";
      this.user.password = pass;
      this.user.password2 = pass;
      return pass;
    },
    async save() {
      if (this.user.username == "") {
        this.$toast.warning("Ingresa un nombre ");
        return;
      }

      if (this.user.fullname == "") {
        this.$toast.warning("Ingresa un usuario ");
        return;
      }
      if ((this.disablecampos = false)) {
        if (this.user.password == "") {
          this.$toast.warning("Ingresa una contraseña ");
          return;
        }
        if ((this.user.password = !this.user.confirmpassword)) {
          this.$toast.warning("La contraseña no coinciden");
          return;
        }
      }

      let accesTypes = [];
      console.log("antes");
      for (let item of this.accesType) {
        if (item.auth === true && item.selected === null) {
          this.$toast.warning(
            "Para el modulo de ( " + item.description + " ) seleciona un perfil"
          );
          return;
        }
        if (item.selected != null) {
          accesTypes.push({
            type_access: item.id,
            acces_profile: item.selected,
            status: item.auth,
          });
        }
      }

      if (this.locationAccess.length === 0) {
        this.$toast.warning("Debes seleccionar al menos un location");
        return;
      }
      let data = {
        id: this.user.id,
        username: this.user.username,
        fullname: this.user.fullname,
        password: this.user.password,
        employee_id: this.user.employee_id,
        status: this.user.status == true ? 1 : 2,
        use_telephone: this.user.use_telephone,
        email: this.user.email,
        type_acces: accesTypes,
        locations: this.locationAccess,
      };
      const responseUser = await this.api_Post("auth/users", data);

      if (responseUser.status == 200) {
        this.step = 1;
        this.getUsers();
        this.$toast.success("Usuario creado con exito");
      } else {
        this.$toast.error("error ");
      }
      // for (let item of this.dataAcessType) {
      //  console.log(item)
      // }
      // this.load_json_user = [];
      // this.load_json_user = this.json_user;

      // if (this.editedIndex > -1) {
      //   if (this.user.use_fullname == "") {
      //     this.$toast.warning("Ingresa un nombre ");

      //     return;
      //   }

      //   if (this.user.use_fullname == "") {
      //     this.$toast.warning("Ingresa un usuario ");

      //     return;
      //   }

      //   this.load_json_user[0].business_units = [];
      //   this.load_json_user[0].locations = [];
      //   for (let key in this.BusinesUnit) {
      //     if (this.BusinesUnit[key].active == false) {
      //       this.load_json_user[0].business_units.push(this.BusinesUnit[key].value);
      //     }
      //   }

      //   for (let key in this.Locations) {
      //     if (this.Locations[key].selected == true) {
      //       this.load_json_user[0].locations.push(this.Locations[key].value);
      //     }
      //   }

      //   for (let item of this.acessCompras) {
      //     if (item.active == true) {
      //       this.load_json_user[0].access_profile.compras.access.push(item.id);
      //     }
      //   }

      //   const responseUser = await this.api_Put("auth/users", this.user);
      //   if (responseUser.status == 200) {
      //     const responseAcess = await this.api_Put("auth/systemAccess", {
      //       use_id: this.user.use_id,
      //       json: this.load_json_user,
      //     });

      //     const dataAcees = await responseAcess.json();

      //     this.$toast.success("Usuario actualizado con exito!");
      //     this.clearDialog();
      //   } else {
      //     this.$toast.error("error!");
      //   }
      // } else {
      //   if (this.user.use_fullname == "") {
      //     this.$toast.warning("Ingresa un nombre ");

      //     return;
      //   }

      //   if (this.user.use_fullname == "") {
      //     this.$toast.warning("Ingresa un usuario ");

      //     return;
      //   }
      //   if ((this.disablecampos = false)) {
      //     if (this.user.password == "") {
      //       this.$toast.warning("Ingresa una contraseña ");

      //       return;
      //     }
      //     if ((this.user.password = !this.user.confirmpassword)) {
      //       this.$toast.warning("La contraseña no coinciden");
      //       return;
      //     }
      //   }

      //   this.load_json_user[0].business_units = [];
      //   this.load_json_user[0].locations = [];
      //   for (let key in this.BusinesUnit) {
      //     if (this.BusinesUnit[key].active == false) {
      //       this.load_json_user[0].business_units.push(this.BusinesUnit[key].value);
      //     }
      //   }

      //   for (let key in this.Locations) {
      //     if (this.Locations[key].selected == true) {
      //       this.load_json_user[0].locations.push(this.Locations[key].value);
      //     }
      //   }

      //   for (let item of this.acessCompras) {
      //     if (item.active == true) {
      //       this.load_json_user[0].access_profile.compras = item.id;
      //     }
      //   }

      //   if (this.stickyCompras == true) {
      //     this.load_json_user[0].access_profile.compra = true;
      //     if (this.load_json_user[0].access_profile.compras.length < 1) {
      //       this.warningNotification("Debes Selecionar un perfil de compras!");
      //       return;
      //     }
      //   }
      //   //Crear nuevo

      //   if ((this.disablecampos = true)) {
      //     const responseAcess = await this.api_Post("auth/systemAccess", {
      //       user_id: this.user.use_id,
      //       json: this.load_json_user,
      //     });
      //     if (responseAcess.status == 200) {
      //       this.clearDialog();
      //       this.$toast.success("Usuario creado con exito");
      //     } else {
      //       this.$toast.error("error usuario existe");
      //     }
      //   } else {
      //     const responseUser = await this.api_Post("auth/users", this.user);
      //     if (responseUser.status == 200) {
      //       const datauser = await responseUser.json();
      //       let userid = datauser[0].use_id;

      //       const responseAcess = await this.api_Post("auth/systemAccess", {
      //         user_id: userid,
      //         json: this.load_json_user,
      //       });

      //       const dataAcees = await responseAcess.json();

      //       this.clearDialog();
      //       this.$toast.success("Usuario creado con exito");
      //     } else {
      //       this.$toast.error("error usuario existe");
      //     }
      //   }
      // }
    },

    validar_email(email) {
      let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email) ? true : false;
    },
    async validate_code() {
      if (this.user.employee_code == "") {
        this.warningNotification("El codigo no puede estar vacio!");
        return;
      }
      try {
        this.loadSearch = true;
        const result = await this.api_Post("humanResource/employees/all", {
          filters:
            " where employee_code='" +
            this.clearEmployeeCodeToFilter(this.user.employee_code) +
            "'",
        });
        const data = await result.json();
        if (result.status == 200) {
          if ((await data.length) == 0) {
            this.$toast.info("No se encontraron resultados!");
            this.loadSearch = false;
            return;
          }
          if (parseInt(data[0].employee_status_id) >= 4) {
            this.$toast.warning("Este empleado no se encuentr activo!");
            this.loadSearch = false;
            return;
          }

          this.user.employee_id = data[0].employee_id;
          this.user.employee_code = data[0].employee_code;
          this.user.fullname = data[0].employee_fullname;

          const email = data[0].contacts.filter(
            (element) => element.contact_type_id == 6
          );
          const phone = data[0].contacts.filter(
            (element) => element.contact_type_id == 3
          );

          this.user.email = email.length == 0 ? "" : email[0].contacts_description;
          this.user.use_telephone =
            phone.length == 0 ? "" : phone[0].contacts_description;

          this.hasCode = true;
          // console.log(data[0].contacts)
          // console.log(data[0].contacts.filter(element=>element.contact_type_id==6))
        } else {
          this.$toast.error(data.error);
        }
      } catch (error) {
        this.$toast.error("Error: " + error);
      }
      this.loadSearch = false;
    },
    async selectedUserOld(item) {
      console.log(item);
      this.editedIndex = 1;
      this.user.use_id = item.use_id;
      this.user.use_fullname = item.use_fullname;
      this.user.use_username = item.use_username;
      this.user.use_phone = item.use_movil;
      this.user.id = item.use_id;
      this.user.fullname = item.use_fullname;
      this.user.username = item.use_username;
      this.user.email = item.use_email;
      // this.user.password = item.use_password;
      //this.user.confirmpassword = item.use_password;
      this.user.use_email = item.use_email;

      this.disablecampos = true;
      this.dialogBucarUsuario = false;
    },
    async loaduserlod() {
      this.dialogBucarUsuario = true;
      const response = await this.api_Get("auth/usersold");

      const data = await response.json();

      this.userold = data;
    },
    async resetPassword(item) {
      let emailVerfication = this.validar_email(this.user.email);

      if (emailVerfication == true) {
        let datos = {
          id: this.user.id,
          username: this.user.username,
          email: this.user.email,
          name: this.user.fullname,
        };

        const response = await this.api_Post("auth/resetPassword", datos);

        if (response.status == 200) {
          this.$toast.success("Se ha enviado la nueva password!");
        } else {
          this.$toast.warning("Error!");
        }
      } else {
        this.$toast.warning("Este usuario no tiene correo eletronico!");
      }
      this.dialogConfirm = false;
    },
  },
};
</script>
