// import Vue from "vue";
// import Vuex from "vuex";
// import Auth from "./Auth";
 import createPersistedState from "vuex-persistedstate";
// Vue.use(Vuex);

// export default new Vuex.Store({
//   state: {},
//   getters: {},
//   mutations: {},
//   actions: {},
//   modules: {
//     Auth,
//   },
//   plugins: [createPersistedState()],
// });
import Vue from 'vue'
import Vuex from 'vuex'
//import getters from './getters'

Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  let moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  moduleName = moduleName.substring(moduleName.indexOf('/') + 1)
  
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  modules,
  plugins: [createPersistedState()],
  //getters
})

export default store
