<template>
    <v-card width="100%" outlined>
  
        <apexchart  width="100%" height="250%" type="line" :options="options" :series="series"></apexchart>
    </v-card>
</template>
<script>
export default {
    data: () => ({
        options: {
            title: {
                text: "",
                align: "center",
            },
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            }, stroke: {
              curve: 'straight'
            },  grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return value;
                    }
                },
            },
            xaxis: {
                categories: [],

            },
            plotOptions: {
    bar: {
      distributed: false
    }
  }
        },
        series: [
            {
                name: "series-1",
                data: [],
            },
        ],

    }),
    props: {
        title_header: {
            type: String
        },
        data: {
            type: Object,
        },
       
        colors: {
            type: Boolean,
            default:false
        },
       
    },

    watch: {
        data: {
            handler: function (nuevoValor, viejoValor) {
                // Lógica para manejar el cambio del objeto filters
            
                 this.options.xaxis.categories = nuevoValor.label
                 this.series[0].data = nuevoValor.series
                 this.options.title.text=this.title_header
                 this.options.plotOptions.bar.distributed=this.coloes
            },
            deep: true, // Para obserlet cambios profundos en el objeto filters
            immediate: true // Para ejecutar el watch inmediatamente al crear el componente
        }
    },

};
</script>