
import Accounting from "@/views/Accounting/Accounting.vue";
import Accounts from "@/views/Accounting/Accounts.vue";
import EntryDaily from "@/views/Accounting/EntryDairy.vue";
import DiaryBook from "@/views/Accounting/DiaryBook.vue";
import CategoryAccounts from "@/views/Accounting/CategoryAccounts.vue";
import ViewEntryDiaryBook from "@/components/Accounting/ViewEntryDiaryBook.vue";
 const accounting=  {
    path: "/accounting",
    name: "Accounting",
    component: Accounting,
    meta: {
      ACCOUNTING: true,
    },
    children: [
      {
        path: "accounts",
        name: "Accounts",
        component: Accounts,
        meta: {
          ACCOUNTING: true,
        },
      },
      {
        path: "diary_book",
        name: "DiaryBook",
        component: DiaryBook,
        meta: {
          ACCOUNTING: true,
        },
      },
      {
        path: "view_account/:id",
        name: "ViewEntryDiaryBook",
        component: ViewEntryDiaryBook,
        meta: {
          ACCOUNTING: true,
        },
      },

      {
        path: "categories",
        name: "CategoryAccounts",
        component: CategoryAccounts,
        meta: {
          ACCOUNTING: true,
        },
      },

      {
        path: "entry_daily",
        name: "EntryDaily",
        component: EntryDaily,
        meta: {
          ACCOUNTING: true,
        },
      },
    //   {
    //     path: "Prepaid",
    //     name: "Prepaid",
    //     component: Prepaid,
    //     meta: {
    //       ACCOUNTING: true,
    //     },
    //   },
      // {
      //   path: "view_invoice/:id?",
      //   name: "ViewInvoice",
      //   component: ViewInvoice,
      //   meta: {
      //     SHOP: true,
      //   },
      // },

      // {
      //   path: "download_invoice/:id?",
      //   name: "DownloadInvoice",
      //   component: DownloadInvoice,
      //   meta: {
      //     SHOP: true,
      //   },
      // },
    ],
  }
  export default accounting

