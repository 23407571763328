<template>
  <v-flex>
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="center" class="mt-3 px-6">
          <span style="font-size: 30px; color: #616161"><v-icon large class="pr-1 pb-2">local_mall</v-icon>Productos y
            servicios</span>
        </v-row>
        <v-row justify="center" class="mt-n1 px-6 mb-2">
          <span style="font-size: 16px; color: #757575">Crea, edita y administra cada detalle de los productos y
            servicios.
          </span>
        </v-row>
        <article_filters @newArticle="clearArticle()" @getItemToEdit="editArticle" :dial="false"
          :showButonNewArticle="true" :typeView="false" v-if="updateArticle" />
      </v-col>
    </v-row>

    <v-dialog v-model="dialogArticle" fullscreen persistent>
      <v-card outlined color="#F4F5FB">
        <v-card-title>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small @click="showBar = !showBar" v-click-outside="onClickOutside"
                color="primary" class="mr-3">
                <v-icon>{{ showBar == false ? "menu" : "menu_open" }}</v-icon>
              </v-btn>
            </template>
            <span>{{ showBar == false ? "Ver menu" : "Ocultar menu" }}</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <span :style="`color:#757575;font-size:20px`">{{ dialogTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogArticle = false">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-divider class="mx-2" inset></v-divider>
          <NavigationButonsTop :showBar="showBar" />
          <v-container>
            <v-row justify="center">
              <v-col cols="12" md="8" class="pb-0 mb-0">
                <v-card outlined class="pt-0 mt-0">
                  <v-card-text>
                    <v-container fluid>
                      <v-row justify="center">
                        <v-col cols="12" md="2" class="pt-0 mt-0" v-for="item in itemTypes" :key="item.id">
                          <v-chip class="ma-1" :color="showCheckItemType(item) == true
                              ? 'success'
                              : 'primary'
                            " @click="typeSelected(item)" label outlined :disabled="is_item_edit">
                            {{ item.name }}
                            <v-icon color="success" small v-show="showCheckItemType(item)">task_alt</v-icon>
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="8" class="pt-0 mt-0 pb-0">
                          <v-text-field class="text-xs-center" v-model="newItem.name" label="Nombre" dense outlined>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                          <v-autocomplete v-model="newItem.item_category" :items="categorys" item-value="id"
                            item-text="name" return-object color="cyanText" label="Categoria" outlined required dense>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" md="4" class="pt-0 mt-0 pb-0">
                          <v-autocomplete v-model="newItem.unit" :items="units" item-value="id" item-text="name"
                            return-object color="cyanText" label="Unidad de medida" outlined required dense>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                          <v-autocomplete v-model="newItem.account_category" :items="accountCategorys" item-value="id"
                            item-text="name" return-object color="cyanText" label="Cuenta contable" outlined required
                            dense>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4" class="pt-0 mt-0 pb-0">
                          <v-autocomplete v-model="newItem.tax_type" :items="taxes" item-value="id" item-text="name"
                            return-object color="cyanText" label="Tipo de impuesto" outlined required dense>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12" class="pt-0 mt-0 pb-0">
                          <v-text-field class="text-xs-center" v-model="newItem.note" label="Nota" dense outlined>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="12" sm="10" md="10" class="pt-0 ml-4">
                          <template>
                            <v-tabs v-model="tab" align-with-title class="d-flex justify-center">
                              <v-tabs-slider color="primary"></v-tabs-slider>
                              <v-tab>{{ letiant_title }}</v-tab>
                              <v-tab @click="getAltitemRecipes" v-if="letiant_title != 'letiante'">Recetas</v-tab>
                              <v-tab @click="getAlternatives" :loading="loadingAlternatives">Alternativas</v-tab>
                            </v-tabs>
                          </template>
                          <v-tabs-items v-model="tab">
                            <!-- letiantes----------------------------------------------------------- -->
                            <v-tab-item href="#tab-0">
                              <v-container fluid>
                                <v-card outlined>
                                  <v-simple-table v-if="letiant_title == 'letiantes'">
                                    <template v-slot:default>
                                      <thead></thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <template>
                                              <v-list class="pa-0">
                                                <v-list-item @click="openDialogletiants()" width="100%">
                                                  <v-list-item-title class="d-flex justify-center align-center">
                                                    <v-icon class="pr-2">settings</v-icon>
                                                    Configurar letiantes
                                                  </v-list-item-title>
                                                </v-list-item>
                                              </v-list>
                                            </template>
                                          </td>
                                        </tr>
                                        <tr v-for="item in convinaciones" :key="item.id">
                                          <td>
                                            <v-icon color="grey lighten-1" large @click="addPhoto(item)">
                                              add_a_photo
                                            </v-icon>
                                            <span class="ml-3 mt-3">{{
                                              item.name
                                            }}</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                  <v-simple-table v-if="letiant_title == 'letiante'">
                                    <template v-slot:default>
                                      <thead></thead>
                                      <tbody>
                                        <tr v-for="item in newItem.item_attributes" :key="item.letiants.letiant_id">
                                          <td class="d-flex justify-center align-center">
                                            <span class="ml-3 mt-3">{{
                                              item.letiants.letiant_name +
                                              ": " +
                                              item.letiants
                                                .letiant_attribute_name
                                            }}</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                </v-card>
                              </v-container>
                            </v-tab-item>
                            <!-- Recetas----------------------------------------------------------- -->
                            <v-tab-item href="#tab-1" v-if="letiant_title != 'letiante'">
                              <v-container fluid>
                                <v-card outlined>
                                  <template>
                                    <v-list class="pa-0">
                                      <v-list-item @click="openDialogToSelectItem(1)" width="100%">
                                        <v-list-item-title class="d-flex justify-center align-center">
                                          <v-icon class="pr-2" color="success">add</v-icon>
                                          Agregar receta
                                        </v-list-item-title>
                                      </v-list-item>
                                    </v-list>
                                  </template>
                                  <v-simple-table outlined>
                                    <template v-slot:default>
                                      <thead>
                                        <tr>
                                          <th class="text-center">ID</th>
                                          <th class="text-center">
                                            Descripcion
                                          </th>
                                          <th class="text-center">Cantidad</th>
                                          <th class="text-center">Nota</th>
                                          <th class="text-center">Remover</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="item in item_in_recipes" :key="item.item_id">
                                          <td>
                                            <span class="d-flex justify-center align-center">{{ item.item_id }}</span>
                                          </td>
                                          <td>
                                            <span class="d-flex justify-center align-center">{{ item.item_name }}</span>
                                          </td>
                                          <td width="10%">
                                            <v-text-field type="number" min="1" class="text-xs-center"
                                              v-model="item.quantity" dense>
                                            </v-text-field>
                                          </td>
                                          <td width="15%">
                                            <v-tooltip top>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-bind="attrs" v-on="on" class="text-xs-center"
                                                  v-model="item.item_note" dense>
                                                </v-text-field>
                                              </template>
                                              <span>{{ item.item_note }}</span>
                                            </v-tooltip>
                                          </td>
                                          <td>
                                            <v-icon class="d-flex justify-center align-center" color="error"
                                              @click="removeRecipes(item)">cancel</v-icon>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                </v-card>
                              </v-container>
                            </v-tab-item>
                            <!-- Alternativas----------------------------------------------------------- -->
                            <v-tab-item href="#tab-2">
                              <v-container fluid>
                                <v-card outlined>
                                  <template>
                                    <v-list class="pa-0">
                                      <v-list-item @click="openDialogToSelectItem(2)" width="100%">
                                        <v-list-item-title class="d-flex justify-center align-center">
                                          <v-icon class="pr-2" color="success">add</v-icon>
                                          Agregar alternativa
                                        </v-list-item-title>
                                      </v-list-item>
                                    </v-list>
                                  </template>
                                  <v-simple-table outlined>
                                    <template v-slot:default>
                                      <thead>
                                        <tr>
                                          <th class="text-center">Codigo</th>
                                          <th class="text-center">
                                            Descripcion
                                          </th>
                                          <th class="text-center">Nota</th>
                                          <th class="text-center">Remover</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="item in item_in_alternatives" :key="item.item_id">
                                          <td>
                                            <span class="d-flex justify-center align-center">{{ item.item_id }}</span>
                                          </td>
                                          <td>
                                            <span class="d-flex justify-center align-center">{{ item.item_name }}</span>
                                          </td>
                                          <td width="15%">
                                            <v-tooltip top>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-bind="attrs" v-on="on" class="text-xs-center"
                                                  v-model="item.item_note" dense>
                                                </v-text-field>
                                              </template>
                                              <span>{{ item.item_note }}</span>
                                            </v-tooltip>
                                          </td>
                                          <td>
                                            <v-icon class="d-flex justify-center align-center" color="error"
                                              @click="removeAlternative(item)">cancel</v-icon>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>
                                </v-card>
                              </v-container>
                            </v-tab-item>
                          </v-tabs-items>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4" class="pb-0 mb-0">
                <v-card width="100%" height="250" outlined>
                  <v-hover v-slot="{ hover }">
                    <v-card flat height="200" :class="hover ? 'grey lighten-3' : 'grey lighten-4'">
                      <v-img contain :src="item_image" height="200">
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col cols="12" md="2" align="center">
                              <v-file-input v-show="item_image == null ? true : hover" v-model="url_image"
                                @change="takeImage" name="upload" id="fileInput" accept="image/png, image/jpeg, image/jpg"
                                :prepend-icon="item_image == null
                                    ? 'add_a_photo'
                                    : 'cameraswitch'
                                  " hide-details hide-input hide-spin-buttons></v-file-input>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-img>
                      <!-- <v-icon color="grey lighten-2" x-large>add_a_photo</v-icon> -->
                      <!-- <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col cols="12" md="2" align="center">
                              <v-file-input
                              v-model="url_image"
                              @change="takeImage"
                              name="upload"
                              id="fileInput"
                              accept="image/png, image/jpeg, image/jpg"
                              prepend-icon="add_a_photo"
                              hide-details
                              hide-input
                              hide-spin-buttons
                            ></v-file-input>
                          </v-col>
                        </v-row>
                      </v-container> -->
                    </v-card>
                  </v-hover>
                  <v-card flat>
                    <v-container fluid>
                      <span :style="`color:#757575;font-size:18px`" large>{{
                        textNomber
                      }}</span>
                      <br />
                      <br />
                      <span :style="`color:#757575;font-size:18px`" large>letiantes: {{ convinaciones.length }}
                      </span>
                      <v-switch v-model="newItem.inventory_product" label="Inventariable"></v-switch>
                      <v-switch v-model="newItem.negative_sale" label="Venta en negativo" class="mt-0 mb-0 pb-0">
                      </v-switch>
                      <v-switch v-model="newItem.editable" label="Editable" class="mt-0 mb-0 pb-0">
                      </v-switch>
                      <v-list-item class="pa-0">
                        <v-list-item-content>
                          <v-list-item-title v-for="item in accountDetails" :key="item.description">
                            <span :style="`color:#757575;font-size:18px`" large>
                              {{ firtsLetterMinRestUp(item.description) }}
                            </span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-container>
                  </v-card>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="saveArticle">
                      Guardar
                    </v-btn>
                    <v-btn color="error" text @click="dialogArticle = false">
                      Cancelar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogletiants" max-width="60%">
      <v-card outlined>
        <v-card-title class="mb-3">
          <span>Configuracion de letiantes</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogletiants = false">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="5">
                <v-card outlined>
                  <v-container fluid>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead></thead>
                        <tbody>
                          <tr v-for="item in letiants" :key="item.id" @click="selectletiant(item)" :class="item.selected == true ? 'blue-grey lighten-5' : ''
                            ">
                            <td>{{ item.name }}</td>
                            <td>
                              <v-switch v-model="item.include"></v-switch>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" md="7">
                <v-card color="gray" outlined>
                  <v-container fluid>
                    <v-data-table v-model="subSelected" :items="subletiantes" :headers="subletiantsHeader"
                      hide-default-footer hide-default-header :single-select="singleSelect" item-key="sequence"
                      @item-selected="selectSubletiant" show-select>
                    </v-data-table>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="generarConvinaciones()">
            Guardar
          </v-btn>
          <v-btn color="error" text @click="dialogletiants = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAddAlternative" max-width="55%" persistent>
      <v-card>
        <v-card-title>
          <v-icon>add</v-icon>
          <span class="headline">Escoger articulos a agregar</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ }">
              <v-btn icon @click="dialogAddAlternative = false">
                <v-icon>clear</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <article_filters @getItemToEdit="addItemSelected" :dial="false" icon="done" name="Agregar"
              startFilter=" WHERE ITEM_TYPE_ID<>2 " />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialogAddAlternative = false">
            <v-icon> done </v-icon>
            Aceptar
          </v-btn>
          <v-btn color="red darken-1" text @click="dialogAddAlternative = false">
            <v-icon> clear </v-icon>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>
<script>
import article_filters from "@/components/Inventory/ArticleSelectionList.vue";
import NavigationButonsTop from "@/components/Inventory/NavigationButonsTop.vue";
export default {
  data() {
    return {
      subSelected: [],
      singleSelect: false,
      dialogAddAlternative: false,
      dialogArticle: false,
      dialogletiants: false,
      letiants: [],
      subletiantsHeader: [{ text: "", value: "name" }],
      subletiantes: [],
      convinaciones: [],
      newItem: {
        item_id: null,
        name: "",
        unit: "",
        item_type: "",
        item_category: "",
        account_category: "",
        tax_type: "",
        inventory_product: true,
        negative_sale: false,
        editable: false,
        note: "",
        image_url: null,
      },
      item_in_alternatives: [],
      item_in_recipes: [],
      units: [],
      itemTypes: [],
      categorys: [],
      accountCategorys: [],
      taxes: [],
      tab: null,
      is_item_edit: false,
      dialog_is_alternative: false,
      loadingAlternatives: false,
      item_image: null,
      url_image: null,
      updateArticle: true,
      changeImage: false,
      showBar: false,
    };
  },
  created() {
    this.getData();
  },
  components: {
    article_filters,
    NavigationButonsTop,
  },
  computed: {
    dialogTitle() {
      return this.is_item_edit == true
        ? this.firtsLetterMinRestUp("Editar " + this.newItem.item_type.name) +
        " #" +
        this.newItem.item_id
        : "Nuevo Producto/Servicio";
    },
    textNomber() {
      if (this.newItem.name == "") {
        return this.firtsLetterMinRestUp(
          this.newItem.item_type.name + " sin nombre"
        );
      } else {
        return this.firtsLetterMinRestUp(this.newItem.name + " ");
      }
    },
    accountDetails() {
      if (this.newItem.account_category == "") {
        return "";
      }
      let details = [
        {
          description:
            "Cuenta: " +
            this.newItem.account_category.item_chart_of_account_name,
        },
        {
          description:
            "Cuenta de inventario: " +
            this.newItem.account_category.inventory_chart_of_account_name,
        },
        {
          description:
            "Cuenta de costos: " +
            this.newItem.account_category.cost_chart_of_account_name,
        },
      ];
      return details;
    },
    letiant_title() {
      if (this.newItem.item_type == "") {
        return "letiantes";
      }
      return this.newItem.item_type.id == 5 ? "letiante" : "letiantes";
    },
  },
  methods: {
    async getData() {
      //----------------UNIDADES--------------------
      const resultUnits = await this.api_Get("inventory/units");
      this.units = await resultUnits.json();
      //----------------ITEM TYPES--------------------
      const resultItemTypes = await this.api_Get("inventory/itemTypes");
      this.itemTypes = await resultItemTypes.json();
      this.newItem.item_type = this.itemTypes[0];
      //----------------TAXES-------------------------
      const taxesRequest = await this.api_Get("accounting/taxes");
      this.taxes = await taxesRequest.json();
      this.newItem.tax_type = this.taxes[0];
      //----------------CATEGORYS-------------------------
      const categoryRequest = await this.api_Get("inventory/categories");
      this.categorys = await categoryRequest.json();
      //----------------ACCOUNT CATEGORYS-------------------------
      const resultAccounts = await this.api_Get(
        "accounting/account_categories"
      );
      this.accountCategorys = await resultAccounts.json();
      //----------------letIANTES-----------------------------------
      // let result = await this.api_Get("inventory/letiants");
      // const data = await result.json();
      // for (let item of data) {
      //   this.letiants.push({
      //     id: item.id,
      //     name: item.name,
      //     subletiantes: item.letiants_details,
      //     selected: false,
      //   });
      // }
    },
    selectSubletiant(subElement) {
      for (let keyletiant in this.letiants) {
        for (let keySubletiant in this.letiants[keyletiant].subletiantes) {
          if (
            this.letiants[keyletiant].subletiantes[keySubletiant].sequence ==
            subElement.item.sequence
          ) {
            this.letiants[keyletiant].subletiantes[keySubletiant].selected =
              subElement.value;
          }
        }
      }
    },
    selectletiant(letiante) {
      let indice = this.letiants.indexOf(letiante);
      for (let key in this.letiants) {
        this.letiants[key].selected = indice == key ? true : false;
      }
      this.subletiantes = letiante.subletiantes;
      this.subSelected = [];
      for (let keyletiant in this.letiants) {
        for (let keySubletiant in this.letiants[keyletiant].subletiantes) {
          if (
            this.letiants[keyletiant].subletiantes[keySubletiant].selected ==
            true &&
            this.letiants[keyletiant].id == letiante.id
          ) {
            this.subSelected.push(
              this.letiants[keyletiant].subletiantes[keySubletiant]
            );
          }
        }
      }
    },
    generarConvinaciones() {
      this.convinaciones = [];

      //apaga los switch de las letiantes que no se hayan incluido subletaintes
      for (let key in this.letiants) {
        if (this.letiants[key].include == true) {
          let countSb = this.letiants[key].subletiantes.filter(
            (element) => element.selected == true
          );
          if (countSb.length == 0) {
            this.letiants[key].include = false;
          }
        }
      }
      let countsletiants = this.letiants.filter(
        (element) => element.include == true
      );
      if (countsletiants.length == 0) {
        this.$toast.warning("Debe agregar al menos una letiante!");
        return;
      }
      let arrayCantXsubletiant = [];
      for (let item of countsletiants) {
        let countSb = item.subletiantes.filter(
          (element) => element.selected == true
        );
        arrayCantXsubletiant.push(countSb.length);
      }

      const cantidaTotalConvinaciones = arrayCantXsubletiant.reduce(
        (p, c) => p * c
      );
      for (let subA of countsletiants[0].subletiantes) {
        if (subA.selected == true) {
          for (
            let setInitletiant = 1;
            setInitletiant <=
            cantidaTotalConvinaciones / arrayCantXsubletiant[0];
            setInitletiant++
          ) {
            this.convinaciones.push({
              name: this.newItem.name + " / " + subA.name,
              letiantSet: [subA.sequence],
            });
          }
        }
      }
      countsletiants = countsletiants.splice(1);
      for (let letiantsToAdd of countsletiants) {
        let arraySubletiant = [];
        for (let subletiantAdd of letiantsToAdd.subletiantes) {
          if (subletiantAdd.selected == true) {
            arraySubletiant.push(subletiantAdd);
          }
        }
        this.addSubletiantConvinations(arraySubletiant);
      }
      this.dialogletiants = false;
    },
    addSubletiantConvinations(arraySubletiant) {
      let keySubletiante = 1;
      for (let keyConvinations in this.convinaciones) {
        if (keySubletiante > arraySubletiant.length) {
          keySubletiante = 1;
        }
        this.convinaciones[keyConvinations].name +=
          " / " + arraySubletiant[keySubletiante - 1].name;
        this.convinaciones[keyConvinations].letiantSet.push(
          arraySubletiant[keySubletiante - 1].sequence
        );
        keySubletiante++;
      }
    },
    addPhoto(item) { },
    takeImage() {
      if (this.url_image == null || this.url_image == undefined) {
        return;
      }
      this.item_image = URL.createObjectURL(this.url_image);
      this.changeImage = true;
    },
    openDialogletiants() {
      if (this.newItem.name == "") {
        this.$toast.warning("Indique el nombre del producto!");
        return;
      }
      this.dialogletiants = true;
    },

    editArticle(item) {
      this.is_item_edit = true;
      this.changeImage = false;
      this.newItem = {
        name: item.item_name,
        unit: this.units.find((element) => element.id == item.unit_id),
        item_type: this.itemTypes.find(
          (element) => element.id == item.item_type_id
        ),
        item_category: this.categorys.find(
          (element) => element.id == item.item_category_id
        ),
        account_category: this.accountCategorys.find(
          (element) => element.id == item.account_category_id
        ),
        tax_type: this.taxes.find((element) => element.id == item.tax_type_id),
        inventory_product: item.inventory_product,
        negative_sale: item.negative_sale,
        editable: item.editable,
        note: item.item_note,
        image_url: item.item_image_url,
        item_id: item.item_id,
        active: item.active,
        item_attributes: item.item_attributes,
      };
      this.getImage(item.item_image_url);
      this.tab = 0;
      this.dialogArticle = true;
    },
    async getImage(imageId) {
      if (imageId == null || imageId == "" || imageId == undefined) {
        this.item_image = null;
        this.newItem.image_url = null;
        return;
      }
      this.item_image = await this.get_storage("items_images", imageId);
    },
    async saveArticle() {
      if (this.newItem.name == "") {
        this.$toast.warning("Indique el nombre del articulo!");
        return;
      }
      if (this.newItem.item_category.id == undefined) {
        this.$toast.warning("Indique la categoria del articulo!");
        return;
      }

      if (this.newItem.account_category.id == undefined) {
        this.$toast.warning("Indique la categoria de cuenta del articulo!");
        return;
      }
      if (this.newItem.unit.id == undefined) {
        this.$toast.warning("Indique la unidad de medida del articulo!");
        return;
      }
      this.updateArticle = false;
      console.log(this.newItem)
   
      let record = {  };
      if (this.changeImage == true) {
        const fileInput = document.getElementById("fileInput");
   
        record = await this.upload_images("items_images", fileInput);
      
      }
      console.log(record)
      let articleData = {
        item_id: this.newItem.item_id,
        unit_id: this.newItem.unit.id,
        item_type_id: this.newItem.item_type.id,
        name: this.newItem.name,
        note: this.newItem.note,
        image_url: record.id,
        image_name:record.documents,
        image_json:record,
        letiants: this.newItem.item_type.id == 5 ? null : this.convinaciones,
        tax_type_id: this.newItem.tax_type.id,
        item_in_recipes: null,
        item_in_alternatives: null,
        inventory_product: this.newItem.inventory_product,
        negative_sale: this.newItem.negative_sale,
        editable: this.newItem.editable,
        account_category_id: this.newItem.account_category.id,
        item_category_id: this.newItem.item_category.id,
      };
      console.log(articleData);
      if (this.item_in_alternatives.length > 0) {
        articleData.item_in_alternatives = [];

        for (let item of this.item_in_alternatives) {
          articleData.item_in_alternatives.push({
            child_item_id: item.item_id,
            note: item.item_note,
          });
        }
      }
      if (this.item_in_recipes.length > 0) {
        articleData.item_in_recipes = [];

        for (let item of this.item_in_recipes) {
          articleData.item_in_recipes.push({
            child_item_id: item.item_id,
            note: item.item_note,
            quantity: item.quantity,
          });
        }
      }
      // if (this.newItem.image_url != null && this.newItem.image_url != "null") {
      //   await this.delete_file_storage("items_images", this.newItem.image_url);
      // }
      try {
        const request = await this.api_Post(
          "inventory/addOrUpdateItem",
          articleData
        );
        const data = await request.json();
        if (request.status == 200) {
          this.$toast.success("Registro realizado con exito!");
          this.dialogArticle = false;
        } else {
          this.$toast.error(data.error);
          await this.delete_file_storage("items_images", record.id);
        }
      } catch (error) { }
      this.updateArticle = true;
    },
    typeSelected(item) {
      if (this.is_item_edit == true) {
        return;
      }
      this.newItem.item_type = item;
    },
    showCheckItemType(type) {
      if (type.id == this.newItem.item_type.id) {
        return true;
      } else {
        return false;
      }
    },
    clearArticle() {
      this.newItem = {
        item_id: null,
        name: "",
        unit: "",
        item_type: this.itemTypes[0],
        item_category: "",
        account_category: "",
        tax_type: this.taxes[0],
        inventory_product: true,
        negative_sale: false,
        editable: false,
        note: "",
        image_url: null,
      };
      this.item_in_alternatives = [];
      this.item_in_recipes = [];
      this.convinaciones = [];
      this.tab = 0;
      this.is_item_edit = false;
      this.item_image = null;
      this.dialogArticle = true;
      this.changeImage = false;
    },
    addItemSelected(item) {
      if (this.dialog_is_alternative == true) {
        let is_selected = this.item_in_alternatives.find(
          (element) => element.item_id == item.item_id
        );
        if (is_selected != undefined) {
          this.$toast.warning(
            "Este articulo ya fue agregado como alternativa!"
          );
          return;
        }
        this.item_in_alternatives.push(item);
      } else {
        let is_selected = this.item_in_recipes.find(
          (element) => element.item_id == item.item_id
        );
        if (is_selected != undefined) {
          this.$toast.warning("Este articulo ya fue agregado como receta!");
          return;
        }

        this.item_in_recipes.push({
          item_id: item.item_id,
          item_name: item.item_name,
          item_note: item.item_note,
          quantity: 1,
        });
      }

      this.$toast.info(item.item_name + " agregado!");
    },
    removeAlternative(item) {
      let indice = this.item_in_alternatives.indexOf(item);
      this.item_in_alternatives.splice(indice, 1);
    },
    removeRecipes(item) {
      let indice = this.item_in_recipes.indexOf(item);
      this.item_in_recipes.splice(indice, 1);
    },
    async getAlternatives() {
      this.loadingAlternatives = true;
      try {
        const request = await this.api_Get(
          "inventory/item_alternative/" + this.newItem.item_id
        );
        if (request.status == 200) {
          const data = await request.json();
          this.item_in_alternatives = data;
        }
      } catch (error) {
        this.$toast.error(error);
      }
      this.loadingAlternatives = false;
    },
    async getAltitemRecipes() {
      try {
        const request = await this.api_Get(
          "inventory/item_recipes/" + this.newItem.item_id
        );
        if (request.status == 200) {
          const data = await request.json();
          this.item_in_recipes = data;
        }
      } catch (error) {
        this.$toast.error(error);
      }
    },
    openDialogToSelectItem(type) {
      if (type == 1) {
        this.dialog_is_alternative = false;
      } else {
        this.dialog_is_alternative = true;
      }

      this.dialogAddAlternative = true;
    },
    onClickOutside() {
      this.showBar = false;
    },
  },
};
</script>
<style scoped>
#fondo {
  background-image: linear-gradient(135deg,
      #e9f6ff,
      #fff2f9,
      #efe9f7,
      #f2d8f1,
      #a950a1,
      #ff7cae);
  background-size: 500%;
}
</style>
