<template>
  <v-container class="pa-3 mt-4">
    <v-row justify="center" v-if="!loading">
      <v-row align="center" justify="center">
        <v-col cols="12" md="12">
          <v-row align="center" justify="center" class="mt-3">
            <v-col cols="12" sm="6" lg="3" v-for="item in indicadores" :key="item.id">
              <StatsCard :color="item.color" :icon="item.icon" :title="item.title" :value="item.amount" sub-icon="home"
                sub-text="" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- <v-col cols="12" md="12" class="mt-2 mb-2">
        <Multiline :title_header="'Gastos / Compras'" :data="compras"  />
      </v-col> -->

      <v-col v-if="ventas.series.length > 0" cols="12" md="8" class="mt-2 mb-2">
        <BarChart :title_header="'Ingresos '" :data="ventas" :colors="false" />
      </v-col>
      <v-col v-if="ventas.series.length > 0" cols="12" md="4" class="mt-2 mb-2">
        <PieChart :title_header="'Ingresos '" :data="ventas_location" />
      </v-col>
      <v-col v-if="ventas_diarias.series.length > 0" cols="12" md="12" class="mt-2 mb-2">
        <LineChart :title_header="'Ventas diaras'" :data="ventas_diarias" />
      </v-col>
      <v-col cols="12" md="8" class="mt-2 mb-2">
        <BarChart :title_header="'Gastos / Compras'" :data="compras" />
      </v-col>
      <v-col v-if="compra_location.series.length > 0" cols="12" md="4" class="mt-2 mb-2">
        <PieChart :title_header="'Gastos '" :data="compra_location" />
      </v-col>
      <v-col cols="12">
        <v-card outlined>
          <apexchart height="170%" type="bar" :options="optionsInOut" :series="seriesInOut"></apexchart>
        </v-card>

      </v-col>
    </v-row>
    <v-row justify="center" v-else>
      <v-col cols="12" md="10">
        <v-skeleton-loader type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions">
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DonutChart from "@/components/Charts/DonutChart.vue";
import PieChart from "@/components/Charts/Pie.vue";
import StatsCard from "@/components/base/StatsCard.vue";
import BarChart from "@/components/Charts/Bar.vue";
import Multiline from "@/components/Charts/Multiline.vue";
import LineChart from "@/components/Charts/Line.vue";
import moment from 'moment';
export default {
  data: () => ({
    //     total de venta y compra
    // cuenta por cobrar y pagar cambiando de unida de negocios
    loading: false,
    indicadores: [],
    ventas_location: { series: [], label: [] },
    compra_location: { series: [], label: [] },
    ventas_diarias: { series: [], label: [] },
    ventas: { series: [], label: [] },
    compras: { series: [], label: [] },
    optionsInOut: {
      title: {
        text: 'Ingresos vs Gastos',
        align: 'center'
      },
      chart: {
        id: 'InOut'
      },
      colors: ['#00BFA5', '#F50057'],
      xaxis: {
        categories: []
      }
    },
    seriesInOut: [{
      name: "Ingresos",
      data: []
    },
    {
      name: "Gastos",
      data: []
    }
    ],
  }),
  components: {
    PieChart,
    DonutChart,
    StatsCard,
    BarChart,
    Multiline,
    LineChart
  },
  props: {
    filters: {
      type: Object,
      default: function () {
        return {
          business: null,
          location: null,
          department_id: null,
        };
      },
    },
  },
  watch: {
    filters: {
      handler: function (nuevoValor, viejoValor) {
        // Lógica para manejar el cambio del objeto filters
        this.updateData(nuevoValor);
      },
      deep: true, // Para obserlet cambios profundos en el objeto filters
      immediate: true, // Para ejecutar el watch inmediatamente al crear el componente
    },
  },

  created() { },
  methods: {
    async updateData(filter) {
      this.loading = true;


      //this.optionsInOut.xaxis.categories = []


      let resultInvoices = await this.api_Post("income/sales_and_shop", {
        business: filter.business,
      });
      let data = await resultInvoices.json();

      this.indicadores = []
      for (let item of data) {
        if (item.invoice_type_id === 1) {
          this.indicadores.push({
            id: 1,
            title: "Compras",
            amount: "RD$ " + this.currencyFormat(item.total),
            color: "#00897B",
            icon: "shopping_cart",
          });
        }
        if (item.invoice_type_id === 2) {
          this.indicadores.push({
            id: 2,
            title: "Ventas",
            amount: "RD$ " + this.currencyFormat(item.total),
            color: "#42A5F5",
            icon: "sell",
          });
        }
      }
      let resultCxP = await this.api_Post("income/pending_balance", {
        invoice: 1,
        business: filter.business,
      });
      let cxp = await resultCxP.json();
      let resultCxC = await this.api_Post("income/pending_balance", {
        invoice: 2,
        business: filter.business,
      });
      let cxc = await resultCxC.json();
      if (cxc.length > 0) {
        this.indicadores.push({
          id: 2,
          title: "Cuentas por cobrar",
          amount: "RD$ " + this.currencyFormat(cxc[0].sum),
          color: "#FEB019",
          icon: "account_balance_wallet",
        });
      }
      if (cxp.length > 0) {
        this.indicadores.push({
          id: 2,
          title: "Cuentas por pagar",
          amount: "RD$ " + this.currencyFormat(cxp[0].sum),
          color: "#E53935",
          icon: "attach_money",
        });
      }
      let resultComprasMonth = await this.api_Post("income/dashboard_montly", {
        invoice: 1,
        business: filter.business,
      });
      let comprasMonth = await resultComprasMonth.json();

      let resultVentasMonth = await this.api_Post("income/dashboard_montly", {
        invoice: 2,
        business: filter.business,
      });
      let ventasMonth = await resultVentasMonth.json();
      let resultVentasDays = await this.api_Post("income/dashboard_daily", {
        invoice: 2,
        business: filter.business,
      });
      let ventasDays = await resultVentasDays.json();

      let resultVentaLocations = await this.api_Post("income/dashboard_location", {
        invoice: 2,
        business: filter.business,
      });
      let ventasLocations = await resultVentaLocations.json();
      let resultCompraLocations = await this.api_Post("income/dashboard_location", {
        invoice: 1,
        business: filter.business,
      });
      let comprasLocations = await resultCompraLocations.json();
      let resultMotnhVsMonth = await this.api_Post("income/dashboard_months_vs_last_moth", {
        business: filter.business,
      });
      let MonthVsMonths = await resultMotnhVsMonth.json();
      this.optionsInOut.xaxis.categories = []
      this.seriesInOut[0].data = []
      this.seriesInOut[1].data = []
      for (let item of MonthVsMonths) {
        this.optionsInOut.xaxis.categories.push(item.nombre_mes)
        this.seriesInOut[0].data.push(item.tipos.ingresos)
        this.seriesInOut[1].data.push(item.tipos.compras)
      }


      let ventas_label = [];
      let ventas_series = [];
      let ventas_location_label = [];
      let ventas_location_series = [];
      let compra_location_label = [];
      let compra_location_series = [];
      let ventas_diarias_label = [];
      let ventas_diarias_series = [];
      let compras_label = [];
      let compras_series = [];
      for (let item of ventasMonth) {
        ventas_label.push(item.month_year);
        ventas_series.push(item.total_sales);
      }
      for (let item of comprasMonth) {
        compras_label.push(item.month_year);
        compras_series.push(item.total_sales);
      }
      for (let item of ventasDays) {
        ventas_diarias_label.push(item.day_year);
        ventas_diarias_series.push(item.total_sales);
      }
      for (let item of ventasLocations) {
        ventas_location_label.push(item.description_short);
        ventas_location_series.push(parseFloat(item.total_amount_sum));
      }
      for (let item of comprasLocations) {
        compra_location_label.push(item.description_short);
        compra_location_series.push(parseFloat(item.total_amount_sum));
      }
      this.ventas_diarias.label = ventas_diarias_label;
      this.ventas_diarias.series = ventas_diarias_series;
      this.ventas_location.label = ventas_location_label;
      this.ventas_location.series = ventas_location_series;
      this.compra_location.label = compra_location_label;
      this.compra_location.series = compra_location_series;
      this.ventas.label = ventas_label;
      this.ventas.series = ventas_series;
      this.compras.label = compras_label;
      this.compras.series = compras_series;
      this.loading = false;
    },
  },
};
</script>

<style></style>
