<template>
  <v-container>
    <v-row justify="center">
      <h2>Cotizaciones</h2>
    </v-row>
    <v-row justify="center">
      <v-col cols="6">
        <v-row justify="center"> </v-row>
        <v-row justify="center">
          <div id="chart">
            <apexchart
              type="pie"
              width="380"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row justify="center">
          <div id="chart">
            <apexchart
              type="radialBar"
              height="200"
              :options="chartOptions2"
              :series="[67]"
            ></apexchart>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      series: [44, 55, 13, 43, 22],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [" Solicitados", " Cotizados", "Pendientes  ", "Rechazados", "Comprado"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      chartOptions2: {
        height: 350,
        type: "radialBar",
        labels: ["Progreso"],  stroke: {
    lineCap: "round",
  },  plotOptions: {
    radialBar: {
      hollow: {
        margin: 15,
        size: "70%"
      },
     
      dataLabels: {
        showOn: "always",
        name: {
          offsetY: -10,
          show: true,
          color: "#888",
          fontSize: "13px"
        },
        value: {
          color: "#111",
          fontSize: "30px",
          show: true
        }
      }
    }
  },

      },
 
   
    };
  },
};
</script>

<style></style>
