<template>
    <v-container fluid>
        <v-layout align-start>
            <v-flex>
                <v-card flat>
                    <v-row no-gutters justify="center">
                        <span style="font-size: 34px; color: #616161">
                            <v-icon large class="pr-1 pb-2">{{titleType.icon}}</v-icon>
                            {{titleType.title}}</span>
                    </v-row>
                    <v-card-text>
                        <HeaderFilters @refreshWarehouse="getTransfers" :filter_default="defaultFilters"/>
                        <v-card outlined class="mt-2 pt-2">
                            <v-card-text class="pa-0">
                                <v-data-table :headers="headerTransfer" :search="search" :items="transferItemFilter">
                                    <template v-slot:top>
                                        <v-toolbar flat>
                                            <v-row>
                                                <v-col cols="2" align="center">
                                                <span :style="`font-size:15px`">
                                                    {{
                                                     ($route.params.type==1?'Transferencias: ':'Recepciones: ')+ transferItemFilter.length
                                                    }}
                                                    </span>
                                                </v-col>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <v-col cols="4" align="center">
                                                    <v-text-field class="text-xs-center" v-model="search"
                                                        append-icon="search" label="Buscar" single-line hide-details>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="5" align="end">
                                                    <v-row justify="end">
                                                        <v-col cols="5" align="end">
                                                            <v-autocomplete v-model="statusFilter" :items="statusType" color="cyanText"
                                                                label="Estatus" outlined dense hide-details
                                                                item-value="id" item-text="name">
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="1" align="end" v-if="$route.params.type==1">
                                                            <v-btn color="success" class="mr-2 pa-1" outlined
                                                                @click="newRequest()">
                                                                <v-icon>add</v-icon> Nueva
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-toolbar>
                                    </template>
                                    <!-- <v-toolbar-title>Transferencias</v-toolbar-title>
                                            <v-divider class="mx-4" inset vertical></v-divider>
                                            <span>{{ transfers.length }}</span>
                                            <v-spacer></v-spacer>
                                            <v-text-field class="text-xs-center" v-model="search" append-icon="search"
                                                label="Buscar" single-line hide-details>
                                            </v-text-field>
                                            <v-spacer></v-spacer>
                                            <v-autocomplete :items="itemStatus" color="cyanText" label="Estatus"
                                                outlined dense hide-details item-value="id" item-text="name">
                                            </v-autocomplete>
                                            <v-divider class="mx-3" inset vertical></v-divider>
                                            <v-btn color="success" class="mr-2 pa-1" outlined @click="newRequest()">
                                                <v-icon>add</v-icon> Nueva
                                            </v-btn> -->

                                    <template v-slot:[`item.status_description`]="{ item }">
                                        <v-chip dark :color="time_line_config(1,item.status_id).color">{{ item.status_description }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:[`item.options`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" color="primary"
                                                    @click="viewDetail(item, false, 1)" large>
                                                    read_more
                                                </v-icon>
                                            </template>
                                            <span>Ver detalles</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" class="pl-3" color="success"
                                                    v-if="item.status_id === 1" @click="viewDetail(item, false, 2)">
                                                    send
                                                </v-icon>
                                            </template>
                                            <span>Enviar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" class="pl-3" color="success"
                                                    v-if="item.status_id === 1" @click="viewDetail(item, true, 1)">
                                                    edit
                                                </v-icon>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" class="pl-3" color="success"
                                                    v-if="item.status_id === 3" @click="viewDetail(item, true, 3)">
                                                    system_update_alt
                                                </v-icon>
                                            </template>
                                            <span>Recibir</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <ItemsRequestDetails 
            :show_dialog="dialogDetail"
            @closeDialog="valueClose" 
            @save_request="save"
            :item="itemRequest" 
            :time_line_status="requestTimeLine" 
            :item_list="articlesDetail" 
            :can_edit="canEdit"
            :location="filters.location" 
            :warehouse="filters.warehouse" />
        <ReceptionItems 
            :show_dialog="dialogReception" 
            @closeDialog="dialogReception=false"
            @save_received="saveReceived"
            :item="itemRequest" 
            :item_list="articlesDetail" 
            v-if="refreshReceived" />
    </v-container>
</template>

<script>
import HeaderFilters from "@/components/Inventory/HeaderFilters.vue";
import ItemsRequestDetails from "@/components/Inventory/ItemsRequestDetails.vue";
import ReceptionItems from "@/components/Inventory/ReceptionItems.vue";
export default {
    data: () => ({
        search: '',
        dialogDetail: false,
        dialogReception:false,
        transfers: [],
        requestTimeLine: [],
        articlesDetail: [],
        canEdit: false,
        headerTransfer: [
            {
                text: "ID",
                align: "start",
                value: "id",
            },
            { text: "Fecha", value: "created_at" },
            { text: "Solicitante", value: "use_fullname" },
            { text: "Location solicitado", value: "location_from" },
            { text: "Almacen solicitado", value: "warehause_from" },
            { text: "Estatus", value: "status_description" },
            { text: "", value: "options", sortable: false },
        ],
        itemsFilters: {},
        itemRequest: { id: null },
        filters: {
            location: { catalogue_id: "", id: "" },
            business: "",
            warehouse: { id: "", name: "" },
            dateStart:null,
            dateEnd:null
        },
        itemStatus: [],
        statusFilter:0,
        refreshReceived:false,
        defaultFilters:[],
    }),
    components: {
        HeaderFilters,
        ItemsRequestDetails,
        ReceptionItems
    },
    created() {
        this.getStatus();
    },
    computed: {
        transferItemFilter(){
            if(this.statusFilter==0 && this.$route.params.type==2){
                return this.transfers.filter((element)=> element.status_id==3||element.status_id==4)
            }   
            if(this.statusFilter==0){
                return this.transfers;
            }   
            return this.transfers.filter((element)=> element.status_id==this.statusFilter)
        },
        titleType(){
            return {
                title:this.$route.params.type==1?'Transferencias':'Recepciones',
                icon: this.$route.params.type==1?'sync_alt':'archive'  
            };
        },
        statusType(){
           if(this.$route.params.type==undefined){
            this.$route.params.type=1;
           }
            if(this.$route.params.type==2){
                return [{id:0,name:'Todo'},{id:3,name:'En espera de recepción'},{id:4,name:'Recibido'}]
            }
           return this.itemStatus;
        }
    },
    watch: {
        statusType(val){
//si es transferencias cargara por defecto las solicitudes pendientes, si es recepcion cargara por defecto las pendientes de recepcion
           this.statusFilter=val.length==6?2:3;
        }
    },
    methods: {
        async getStatus() {
            const request = await this.api_Get("inventory/approval_types");
            this.itemStatus = await request.json();
            this.itemStatus.unshift({id:0,name:'Todo'});
        },
        async getTransfers(filters) {
            this.transfers = [];
            if(this.$route.params.id!=undefined){
                this.filters=this.$route.params.filtersDefault;
                this.defaultFilters=[this.$route.params.filtersDefault];
                if (this.filters.warehouse == undefined || this.filters.warehouse.id == null) {
                    return;
                }
                this.statusFilter=4;
            }else{
                this.filters = filters;
                if (this.filters.warehouse == undefined || this.filters.warehouse.id == null) {
                    this.$toast.warning("Escoja un almacen!");
                    return;
                }
            }
            if (this.filters.location.id == null) {
                return;
            }
        
            let filtros = `WHERE TO_WAREHOUSE_ID=${this.filters.warehouse.id}  AND TO_LOCATION_ID=${this.filters.location.id}
		        AND CREATED_AT BETWEEN '${this.filters.dateStart}' AND '${this.filters.dateEnd}' ORDER BY CREATED_AT DESC`;

            const request = await this.api_Post("inventory/transfers", {
                filters: filtros
            });
            const data = await request.json();
            if(this.$route.params.id!=undefined){
                this.viewDetail(data.find((element)=>element.id==parseInt(this.$route.params.id)),false,4);
            }
            if (request.status == 200) {
                this.transfers = data;
            } else {
                this.$toast.error(data.error);
            }
        },
        async viewDetail(item_request, is_edit, type) {
            const request = await this.api_Get("inventory/transfersDetail/" + item_request.id);
            const data = await request.json();
            if (request.status == 200) {
                let articles=[];
                for(let item of data){
                    let image = "image-not-aviable.jpg";
                        if(item.item_image_url != "" &&item.item_image_url != null &&item.item_image_url != "null" &&item.item_image_url != undefined
                        ){
                            image = await this.get_storage("items_images",item.image_url);
                        }    
                    articles.push({
                        item_id: item.item_id,
                        item_name: item.item_name,
                        requested_quantity:item.requested_quantity,
                        received_quantity:item.received_quantity,
                        image_url:item.image_url,
                        image:image
                    })
                }
                this.articlesDetail = articles;
                if (type == 2) {
                    this.save({
                        transfer_id: item_request.id,
                        saveType: type,
                        locationFrom: item_request.from_location_id,
                        warehouseFrom: item_request.from_warehouse_id
                    })
                }else if (type == 3) {
                    this.refreshReceived=false;
                   this.itemRequest = item_request;
                   this.refreshReceived=true;
                   this.dialogReception=true;
                }  else {
                    this.canEdit = is_edit;
                    this.requestTimeLine = item_request.time_line_status;
                    this.itemRequest = item_request;
                    this.itemRequest.showButtonBack = this.$route.params.id!=undefined?true:false;
                    this.dialogDetail = true;
                }

            } else {
                this.$toast.error('Error al consultar el detalle!.' + data.error);
            }

        },
        newRequest() {
            if (this.filters.location.catalogue_id == null) {
                this.$toast.warning('Este location no cuenta con un catalogo registrado!');
            }
            this.articlesDetail = [];
            this.canEdit = true;
            this.itemRequest = { id: null };
            this.requestTimeLine = [{ name: 'Borrador', warehouse_approval_type_id: 1 }];
            this.dialogDetail = true;
        },
        async save(type) {
            try {
                const request = await this.api_Post("inventory/save_transfer", {
                    transfer_id: type.transfer_id,
                    location_from: type.locationFrom,
                    location_to: this.filters.location.id,
                    warehouse_from: type.warehouseFrom,
                    warehouse_to: this.filters.warehouse.id,
                    user_id: this.$store.state.auth.user.user.user_id,
                    approval_type_id: type.saveType,
                    items: this.articlesDetail
                });
                const data = await request.json();
                if (request.status == 200) {
                    this.$toast.success('Guardado correctamente!');
                    this.getTransfers(this.filters);
                    this.dialogDetail = false;
                } else {
                    this.$toast.error('Error al registrar la solicutud!.' + data.error);
                }
            } catch (error) {

            }
        },
        async saveReceived(item_transfer_id) {
            let itemsDetail=[];
           for(let item of this.articlesDetail){
                itemsDetail.push({
                    item_id:item.item_id,
                    received_quantity:(parseFloat(item.received_quantity)>parseFloat(item.requested_quantity)?item.requested_quantity:item.received_quantity)
                })
           }         
            try {
                const request = await this.api_Post("inventory/save_received", {
                    transfer_id:item_transfer_id,
                    user_id:this.$store.state.auth.user.user.user_id,
                    items:itemsDetail,
                });
                const data = await request.json();
                if (request.status == 200) {
                    this.$toast.success('Recepcion realizada con exito!');
                    this.getTransfers(this.filters);
                    this.dialogReception = false;
                } else {
                    this.$toast.error('Error al registrar la recepcion!.' + data.error);
                }
            } catch (error) {

            }
        },
        valueClose(item){
            if(item==true){
                this.$router.push({
                name: "InventoryManagement",
                params: { 
                    filtersDefault:this.filters
                },
             });
            }else{
                this.dialogDetail=false;
                this.$route.params.id=undefined;
            }

    }
    }
}
</script>