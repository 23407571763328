<template>
  <v-card style="background-color: #fafafa">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-card elevation="0" style="background-color: #fafafa">
          <v-toolbar flat dark color="white">
            <v-btn icon color="black" @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <span style="font-size: 28px; color: #616161">Entrada de diario {{ entryDiary.diary_book_code }}
            </span>
            <v-btn @click="disable = !disable" style="margin-left: 50px" outlined color="primary">
              <v-icon color="">edit</v-icon>
              Editar
            </v-btn>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
          <!-- <v-card-title>
                    <span class="text-h5">Entrada de diario {{ entryDiary.diary_book_code }}</span>
                  </v-card-title> -->
          <v-row class="mt-2" justify="center">
            <v-spacer> </v-spacer>
            <!-- <span style="font-size: 28px; color: #616161"
              >Entrada de diario {{ entryDiary.diary_book_code }}
            </span> -->
            <v-spacer> </v-spacer>

            <v-spacer> </v-spacer>
          </v-row>
          <v-row class="pt-2" justify="center">
            <v-col cols="12" sm="3" md="3" class="pt-0 pb-0">
              <v-autocomplete dense outlined :readonly="disable" v-model="filters.business" :items="itemsBussines"
                label="Unidad de negocio" color="cyanText" @change="
                  itemsLocations2 = updateCombo(
                    itemsLocations,
                    itemsLocations2,
                    filters.business,
                    'location'
                  )
                  " prepend-inner-icon="corporate_fare" required></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
              <v-autocomplete dense outlined :readonly="disable" v-model="filters.location" :items="itemsLocations2"
                color="cyanText" label="Location" prepend-inner-icon="my_location" required></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="">
            <v-col cols="12" md="12">
              <v-container fluid>
                <v-col cols="12">
                  <v-data-table :headers="headers" :items="newEntryDairy" class="elevation-0 pr-6 mt-n10"
                    hide-default-footer>
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Asientos</v-toolbar-title>
                        <v-col class="mt-6" cols="2">
                          <v-menu class="mt-6" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field :readonly="disable" dense outlined v-model="date" label="Fecha efectiva"
                                prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col class="mt-6" cols="3">
                          <v-text-field :readonly="disable" dense outlined :value="getDate(entryDiary.created_date)"
                            label="Fecha creacion" prepend-inner-icon="mdi-calendar"></v-text-field>
                        </v-col>
                        <v-col class="mt-6" cols="3">
                          <v-autocomplete dense v-model="entity_id" :items="entity" :search-input.sync="entity_name"
                            color="cyanText" return-object label="Entidad" outlined
                            @keydown="getEntitys"></v-autocomplete>
                        </v-col>
                        <v-col class="mt-6" cols="3">
                          <v-autocomplete dense v-model="currency_id" :items="currencies" color="cyanText" return-object
                            label="Moneda" outlined required></v-autocomplete></v-col>
                        <v-divider class="mx-4" inset vertical></v-divider>

                        <v-spacer></v-spacer>
                        <v-btn v-show="disable == false" @click="addLine()" color="primary">
                          <v-icon color="white">add</v-icon> Agregar linea
                        </v-btn>
                      </v-toolbar>
                    </template>

                    <template v-slot:end>
                      <v-text-field label="Suplidor" outlined></v-text-field>
                    </template>
                    <template v-slot:[`item.entity`]="{ item }">
                      <span style="opacity: 0" v-if="item.entry_type_id == 1">{{ (item.debito = item.amount) }}
                      </span>
                      <span style="opacity: 0" v-if="item.entry_type_id == 2">{{ (item.credito = item.amount) }}
                      </span>
                    </template>

                    <template v-slot:[`item.cuentaContable`]="{ item }">
                      <v-autocomplete dense :readonly="disable == true" v-model="item.chart_of_account_id"
                        :items="accounts" @change="
                          item.dgii_category_id =
                          item.cuentaContable.dgii_category_id
                          " color="cyanText" label="Cuenta contable" outlined required></v-autocomplete>
                    </template>
                    <template v-slot:[`item.categoryDgii`]="{ item }">
                      <v-autocomplete dense :readonly="disable == true" v-model="item.dgii_category_id"
                        :items="categoryDgii" color="cyanText" label="Categoria Dgii" outlined required></v-autocomplete>
                    </template>
                    <template v-slot:[`item.descripcion`]="{ item }">
                      <v-textarea dense :readonly="disable == true" rows="2" row-height="10" v-model="item.note"
                        label="Descripcion" outlined></v-textarea>
                    </template>

                    <template v-slot:[`item.department_id`]="{ item }">
                      <v-autocomplete dense outlined v-model="item.department_id" :items="itemsDepartment"
                        color="cyanText" label="Departamento" prepend-inner-icon="business" required></v-autocomplete>
                    </template>
                    <template v-slot:[`item.diary_book_category_id`]="{ item }">
                                  <v-autocomplete dense outlined v-model="item.diary_book_category_id" :items="categories.filter((element) => element.parent_id === null)
                                    " color="cyanText" label="Categoría" item-text="name" item-value="id" prepend-inner-icon="business"
                                    required></v-autocomplete>

                                </template>
                                <template v-slot:[`item.diary_book_sub_category_id`]="{ item }">
                                  <v-autocomplete dense outlined v-model="item.diary_book_sub_category_id" :items="categories.filter(
                                    (element) =>
                                      element.parent_id === item.diary_book_category_id
                                  )
                                    " color="cyanText" label="Sub-Categoría" item-text="name" item-value="id"
                                    prepend-inner-icon="business" required></v-autocomplete>
                                </template>
                    <template v-slot:[`item.debito`]="{ item }">
                      <v-text-field dense v-model="item.debito" :readonly="item.credito != '' || disable == true"
                        label="Debito" type="number" min="0" outlined></v-text-field>
                    </template>
                    <template v-slot:[`item.credito`]="{ item }">
                      <v-text-field dense :readonly="item.debito != '' || disable == true" v-model="item.credito"
                        type="number" label="Credito" min="0" outlined></v-text-field>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon v-show="disable == false" @click="deleteItem(item)" color="black">close</v-icon>
                    </template>
                    <template v-slot:footer>
                      <v-row class="mt-n6" justify="center">
                        <v-col cols="12" md="6">
                          <v-row justify="center">
                            <v-textarea :readonly="disable == true" style="
                                margin-top: 35px;
                                margin-bottom: 30px;
                                margin-left: 20px;
                              " v-model="note" rows="3" row-height="70" outlined label="Observaciónes"
                              prepend-inner-icon="mdi-calendar"></v-textarea>
                          </v-row>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-row justify="end">
                            <v-card style="
                                margin-right: 150px;
                                margin-top: 30px;
                                margin-bottom: 30px;
                              " elevation="0" width="350px" height="100px" outlined>
                              <v-layout fill-height align-center>
                                <v-col cols="12" md="6" align="center">
                                  <v-row justify="center">
                                    <span> Debito </span>
                                  </v-row>
                                  <v-row justify="center">
                                    <span>
                                      RD {{ debitoAndCredito.debito }}
                                    </span>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" md="6" align="center">
                                  <v-row justify="center">
                                    <span> Credito </span>
                                  </v-row>
                                  <v-row justify="center">
                                    <span>
                                      RD {{ debitoAndCredito.credito }}
                                    </span>
                                  </v-row>
                                </v-col>
                              </v-layout>
                            </v-card>
                          </v-row>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-col>

                <v-row style="margin-right: 175px; margin-top: -40px" align="end" justify="space-around">
                  <v-spacer></v-spacer>
                  <v-btn v-show="disable == false" @click="closeDialog" style="margin-left: 50px" outlined
                    color="primary">
                    <v-icon>close</v-icon>
                    Cancelar
                  </v-btn>
                  <v-btn v-show="disable == false" style="margin-left: 50px" @click="save" color="primary">
                    <v-icon>save</v-icon>
                    Guardar
                  </v-btn>
                </v-row>
                <v-row justify="center">
                  Usuario: {{ entryDiary.user_fullname }}
                </v-row>

                <v-row justify="center">
                  <v-container>
                    <v-row class="mb-10" justify="center">
                      <span>Historico</span>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="12">
                        <v-data-table :headers="headersHistories" :items="entry_histories" class="elevation-0 pr-6 mt-n10"
                          hide-default-footer>
                          <template v-slot:[`item.cuentaContable`]="{ item }">
                            <v-autocomplete dense readonly v-model="item.chart_of_account_id" :items="accounts" @change="
                              item.dgii_category_id =
                              item.cuentaContable.dgii_category_id
                              " color="cyanText" label="Cuenta contable" outlined required></v-autocomplete>
                          </template>
                          <template v-slot:[`item.categoryDgii`]="{ item }">
                            <v-autocomplete dense readonly v-model="item.dgii_category_id" :items="categoryDgii"
                              color="cyanText" label="Categoria Dgii" outlined required></v-autocomplete>
                          </template>
                          <template v-slot:[`item.descripcion`]="{ item }">
                            <v-textarea dense readonly rows="2" row-height="10" v-model="item.note" label="Descripcion"
                              outlined></v-textarea>
                          </template>
                          <template v-slot:[`item.department_id`]="{ item }">
                            <v-autocomplete dense outlined v-model="item.department_id" :items="itemsDepartment"
                              color="cyanText" label="Departamento" prepend-inner-icon="business"
                              required></v-autocomplete>
                          </template>
                     
                          <template v-slot:[`item.debito`]="{ item }">
                            <v-text-field dense :value="item.entry_type_id === 1 ? item.amount : ''
                              " readonly label="Debito" type="number" min="0" outlined></v-text-field>
                          </template>
                          <template v-slot:[`item.credito`]="{ item }">
                            <v-text-field dense readonly :value="item.entry_type_id === 2 ? item.amount : ''
                              " type="number" label="Credito" min="0" outlined></v-text-field>
                          </template>
                          <template v-slot:[`item.diary_book_category_id`]="{ item }">
                                  <v-autocomplete dense outlined v-model="item.diary_book_category_id" :items="categories.filter((element) => element.parent_id === null)
                                    " color="cyanText" label="Categoría" item-text="name" item-value="id" prepend-inner-icon="business"
                                    required></v-autocomplete>

                                </template>
                                <template v-slot:[`item.diary_book_sub_category_id`]="{ item }">
                                  <v-autocomplete dense outlined v-model="item.diary_book_sub_category_id" :items="categories.filter(
                                    (element) =>
                                      element.parent_id === item.diary_book_category_id
                                  )
                                    " color="cyanText" label="Sub-Categoría" item-text="name" item-value="id"
                                    prepend-inner-icon="business" required></v-autocomplete>
                                </template>
                          <template v-slot:[`item.user`]="{ item }">
                            <span>{{ item.created_by }}</span>
                          </template>
                          <template v-slot:[`item.date`]="{ item }">
                            <span>{{ getDate(item.updated_at) }}</span>
                          </template>

                          <!-- <template v-slot:footer>
                      <v-row class="mt-n6" justify="center">
                        <v-col cols="12" md="6">
                          <v-row justify="center">
                            <v-textarea
                              :readonly="disable == true"
                              style="
                                margin-top: 35px;
                                margin-bottom: 30px;
                                margin-left: 20px;
                              "
                              v-model="note"
                              rows="3"
                              row-height="70"
                              outlined
                              label="Observaciónes"
                              prepend-inner-icon="mdi-calendar"
                            ></v-textarea>
                          </v-row>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-row justify="end">
                            <v-card
                              style="
                                margin-right: 150px;
                                margin-top: 30px;
                                margin-bottom: 30px;
                              "
                              elevation="0"
                              width="350px"
                              height="100px"
                              outlined
                            >
                              <v-layout fill-height align-center>
                                <v-col cols="12" md="6" align="center">
                                  <v-row justify="center">
                                    <span> Debito </span>
                                  </v-row>
                                  <v-row justify="center">
                                    <span>
                                      RD {{ debitoAndCredito.debito }}
                                    </span>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" md="6" align="center">
                                  <v-row justify="center">
                                    <span> Credito </span>
                                  </v-row>
                                  <v-row justify="center">
                                    <span>
                                      RD {{ debitoAndCredito.credito }}
                                    </span>
                                  </v-row>
                                </v-col>
                              </v-layout>
                            </v-card>
                          </v-row>
                        </v-col>
                      </v-row>
                    </template> -->
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({
    disable: true,
    accounts: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu2: false,
    selectedCategoryDgii: "",
    categoryDgii: [],
    seletecName: "",
    entity_id: null,
    currency_id: "",
    items: [],
    entity_name: "",
    itemsBussines: [],
    itemsLocations: [],
    itemsLocations2: [],
    itemsDepartment: [],
    note: "",
    entity: [],
    customers: [],
    itemSaved: [],
    categoryDgii: [],
    newItem: [],
    dataDiary: [],
    itemsRoutes: [],
    currencies: [],
    editedItem: [],
    entry_histories: [],
    selectedCurrencies: "",
    catalogue_id: null,
    editedIndex: -1,
    filters: { business: "", location: null, department_id: null, routes: "" },
    editedIndex: -1,
    dialog: false,
    dialogDelete: false,
    categories: [],
    headers: [
      //{ text: "Entidad", value: "entity", width: "12%" },
      { text: "Cuenta contable", value: "cuentaContable", width: "16%" },
      { text: "Categoria dgii", value: "categoryDgii", width: "12%" },
      { text: "Descripcion", value: "descripcion" },
      { text: "Departamento", value: "department_id", width: "12%" },
      { text: "Categoría", value: "diary_book_category_id", width: "12%" },
      {
        text: "Sub Categoría",
        value: "diary_book_sub_category_id",
        width: "12%",
      },
      { text: "Debito", value: "debito", width: "17%" },
      { text: "Credito", value: "credito", width: "17%" },
      { text: "Actions", value: "actions", width: "5%" },
    ],
    headersHistories: [
      //{ text: "Entidad", value: "entity", width: "12%" },
      { text: "Cuenta contable", value: "cuentaContable", width: "16%" },
      { text: "Categoria dgii", value: "categoryDgii", width: "12%" },
      { text: "Descripcion", value: "descripcion", width: "12%" },
      { text: "Departamento", value: "department_id", width: "12%" },
      { text: "Categoría", value: "diary_book_category_id", width: "12%" },
      {
        text: "Sub Categoría",
        value: "diary_book_sub_category_id",
        width: "12%",
      },
      { text: "Debito", value: "debito", width: "10%" },
      { text: "Credito", value: "credito", width: "10%" },
      { text: "Usuario", value: "user", width: "5%" },
      { text: "Fecha", value: "date", width: "10%" },
    ],

    newEntryDairy: [],
  }),

  created() {
    this.getData();
  },
  props: {
    entryDiary: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Crear cuenta contable "
        : "Editar cuenta contable ";
    },
    debitoAndCredito() {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      let debito = 0;
      let credito = 0;
      this.newEntryDairy.forEach((element) => {
        if (element.debito > 0) {
          debito += parseFloat(element.debito);
        }
      });

      this.newEntryDairy.forEach((element) => {
        if (element.credito > 0) {
          credito += parseFloat(element.credito);
        }
      });

      return {
        debito: formatter.format(debito.toFixed(2)),
        credito: formatter.format(credito.toFixed(2)),
      };
    },
  },
  watch: {
    entryDiary: function (newVal, oldVal) {
      this.currency_id = this.entryDiary.currency_id;
      this.$forceUpdate();
    },
  },

  methods: {
    async getEntitys() {
      this.entity = [];
      // let resultentity = await this.api_Get("accounting/entity");
      // for (let item of await resultentity.json()) {
      //   this.entity.push({ text: item.name, value: item.id });
      // }
      let typ = "";

      const responseRequest = await this.api_Post("accounting/search_entity", {
        name: this.entity_name,
        type: typ,
      });

      if (responseRequest.ok) {
        let data = await responseRequest.json();
        for (let item of data) {
          this.entity.push({ text: item.name, value: item.entity_id });
        }
      }
    },
    getDate(date) {
      let newDate = moment(date).format("YYYY-MM-DD HH:MM:SS A").toString();
      return newDate;
    },
    loadCategory(item) {
      return item.dgii_category_id;
    },
    async getData() {
      let resultTypeCategories = await this.api_Get(
        "accounting/categories_diary_book"
      );
      this.categories = await resultTypeCategories.json();
      let responseDetails = await this.api_Post("accounting/diary_histories", {
        diary_book_id: this.entryDiary.diary_book_id,
      });
      if (responseDetails.status == 200) {
        let data = await responseDetails.json();
        this.entry_histories = data;
      } else {
        this.$toast.error("error ");
      }

      this.newEntryDairy = this.entryDiary.diary_book_details;
      // console.log(this.entryDiary);
      this.itemsBussines = Object.assign(
        [],
        this.$store.state.auth.bussines_units
      );

      //combo locations----------------------------------------------

      this.itemsLocations = Object.assign([], this.$store.state.auth.locations);

      this.filters.business = this.itemsBussines[0].value;

      // let resultCustomers = await this.api_Get("accounting/customers");
      //   for (let item of await resultCustomers.json()) {
      //     this.customers.push({ text: item.name, value: item.id });
      //   }
      this.itemsDepartment = await this.g_departments();
      let resultEntity = await this.api_Get("accounting/entity");

      for (let item of await resultEntity.json()) {
        this.entity.push({ text: item.name, value: item.id });
      }

      let resultCurrencies = await this.api_Get("accounting/currencies");

      for (let item of await resultCurrencies.json()) {
        this.currencies.push({ text: item.description, value: item.id });
      }
      this.currency_id = this.entryDiary.currency_id;
      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      const dataTypeAccounts = await resultTypeAccounts.json();
      for (let item of dataTypeAccounts) {
        this.accounts.push({
          text: item.name,
          value: item.id,
          dgii_category_id: item.dgii_category_id,
        });
      }
      let resultCategoryDgii = await this.api_Get("accounting/category_dgii");
      const dataCategoryDgii = await resultCategoryDgii.json();
      for (let item of dataCategoryDgii) {
        this.categoryDgii.push({ text: item.name, value: item.id });
      }
      this.CategoryDgii = dataCategoryDgii;
      let resultEntryDiary = await this.api_Get("accounting/diary_entry");
      this.dataDiary = await resultEntryDiary.json();

      this.filters.business = this.entryDiary.business_unit_id;
      this.filters.location = this.entryDiary.location_id;
      this.filters.department_id = this.entryDiary.department_id;
      this.itemsLocations2 = this.updateCombo(
        this.itemsLocations,
        this.itemsLocations2,
        this.filters.business,
        "location"
      );

      this.filters.location = this.itemsLocations2.filter(
        (element) => element.value === this.entryDiary.location_id
      )[0].value;
    },
    showItem(item) { },
    addLine() {
  
      this.newEntryDairy.push({
        idSuplidor: "",
        idCliente: "",
        cuentaContable: "",
        categoryDgii: "",
        descripcion: "",
        debito: "",
        credito: "",
      });
    },
    async save() {
      let json = [];

      for (let item of this.newEntryDairy) {
        let type = 0;
        let amount = 0;
        if (item.debito == "") {
          type = 2;
          amount = item.credito;
        } else if (item.credito == "") {
          type = 1;
          amount = item.debito;
        }

        json.push({
          chart_of_account_id: item.chart_of_account_id,
          // supplier_id: item.idSuplidor == "" ? null : item.idSuplidor.value,
          // customer_id: item.idCliente == "" ? null : item.idCliente.value,

          dgii_category_id: item.categoryDgii,

          department_id: item.department_id,
          diary_book_project_id: item.diary_book_project_id,
          entry_type_id: type,
          amount: amount,
          note: item.descripcion,
        });
      }

      if (this.debitoAndCredito.debito === this.debitoAndCredito.credito) {
        const responseRequest = await this.api_Post("accounting/diary_entry", {
          diary_book_id: this.entryDiary.diary_book_id,
          user_id: this.$store.state.auth.user.user.user_id,
          effective_date: this.date,
          bussiness_units: this.filters.business,
          location: this.filters.location == 0 ? null : this.filters.location,

          currency_id: this.currency_id,
          entity_id: this.entryDiary.entity_id,
          note: this.note,
          details: json,
        });
        if (responseRequest.status == 200) {
          this.getData();
          this.closeDialog();
          this.$toast.success("entrada agregada con exito!");
        } else {
          this.$toast.error("error al agregar la cuenta!");
        }
      } else {
        this.$toast.warning("El debito debe ser igual al credito");
        return;
      }
    },
    closeDialog() {
      this.$emit("close");
    },
    updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
      itemsDestino.splice(0, itemsDestino.length);

      for (let item of itemsOrigen) {
        if (item.bus_id === condicion) {
          itemsDestino.push({
            value: item.value,
            text: item.text,
            routes: item.routes,
          });
        }
      }
      //itemsDestino.unshift({ text: "Todas", value: 0 });
      this.filters.location = this.itemsLocations2[0].value;

      return itemsDestino;
    },
    deleteItem(item) {
      this.editedIndex = this.newEntryDairy.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.newEntryDairy.splice(this.editedIndex, 1);
    },

    editItem(item) {
      // console.log(item);
      this.selectedCategoryDgii = this.editedItem.dgii_category_id;
      this.editedIndex = 0;
      this.editedItem = Object.assign({}, item);
      this.seletecName = this.editedItem.name;
      this.selectedCategoryDgii;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    addAccount(item) {
      this.editedItem = [];
      this.itemSaved = [];
      this.selectedCategoryDgii = "";
      this.editedIndex = -1;
      let data = Object.assign({}, item);
      this.itemSaved = data;
      this.seletecName = data.name;
      this.editedItem.name = "";
      this.editedItem.code = "";
      this.dialog = true;
    },
  },
};
</script>

<style></style>
