//Settings
import User from "@/views/Maintenance/User.vue";
import Withholdings from "@/views/Maintenance/Withholdings.vue";
import Discounts from "@/views/Maintenance/Discounts.vue";
import Applicants from "@/views/Maintenance/Applicants.vue";
import DepartmentsAccount from "@/views/Maintenance/DepartmentsAccount.vue";
import Boxes from "@/views/Maintenance/Boxes.vue";
import Calendar from "@/views/Maintenance/Calendar.vue";
import Assets from "@/views/Inventory/Assets.vue";
import UserPerfil from "@/views/Maintenance/UserPerfil.vue";
import BussinesUnit from "@/views/Maintenance/BussinesUnit.vue";
import NoAccess from "@/views/NoAcces.vue";
import ChangeMoney from "@/views/Maintenance/ChangeMoney.vue";
import GroupMoney from "@/views/Maintenance/GroupMoney.vue";
import  Maintenance from "@/views/Maintenance/Maintenance.vue"
import CategoriesDGII from "@/views/Maintenance/CategoriesDGII.vue"
import ProjectAccounting from "@/views/Maintenance/ProjectAccounting.vue"
import VouchersVue from "@/views/Maintenance/Vouchers.vue";
import Itbis from "@/views/Maintenance/Itbis.vue";

 const maintenance=  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    meta: {
   
    },
    children: [
        {
            path: "assets",
            name: "Assets",
            component: Assets,
            meta: {
              SHOP: true,
            },
          },
        {
            path: "itbis",
            name: "Itbis",
            component: Itbis,
            meta: {
              SHOP: true,
            },
          },
          {
            path: "user_profile",
            name: "UserPerfil",
            component: UserPerfil,
            meta: {
              libre: true,
            },
          },
          {
            path: "bussines_unit",
            name: "BussinesUnit",
            component: BussinesUnit,
            meta: {
              LOGGED: true,
            },
          },
          {
            path: "exchange_money",
            name: "ChangeMoney",
            component: ChangeMoney,
            meta: {
              LOGGED: true,
            },
          }, 
          {
            path: "categories_dgii",
            name: "CategoriesDGII",
            component: CategoriesDGII,
            meta: {
              LOGGED: true,
            },
          }, 
          {
            path: "projects",
            name: "ProjectAccounting",
            component: ProjectAccounting,
            meta: {
              LOGGED: true,
            },
          }, 
          {
            path: "group_money",
            name: "GroupMoney",
            component: GroupMoney,
            meta: {
              LOGGED: true,
            },
          }, 
           {
            path: "user",
            name: "User",
            component: User,
            meta: {
              libre: true,
            },
          },
          {
            path: "withholdings",
            name: "Withholdings",
            component: Withholdings,
            meta: {
              SHOP: true,
            },
          },
          {
            path: "Discounts",
            name: "Discounts",
            component: Discounts,
            meta: {
              SHOP: true,
            },
          },
          {
            path: "applicants",
            name: "Applicants",
            component: Applicants,
            meta: {
              SHOP: true,
            },
          },
          {
            path: "departments_account",
            name: "DepartmentsAccount",
            component: DepartmentsAccount,
            meta: {
              SHOP: true,
            },
          },
          {
            path: "voucher_types",
            name: "VouchersVue",
            component: VouchersVue,
            meta: {
              SHOP: true,
            },
          },
          {
            path: "boxes",
            name: "Boxes",
            component: Boxes,
            meta: {
              SHOP: true,
            },
          },
          {
            path: "Calendar",
            name: "Calendar",
            component: Calendar,
            meta: {
              SHOP: true,
            },
          },
    ],
  }
  export default maintenance


