<template>
  <v-flex>

  

    <v-stepper v-model="e1">
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card outlined elevation="1" width="100%" height="140px">
            <v-layout fill-height align-center justify-center>
              <v-col cols="2.4">
                <v-row justify="center">
                  <span class="subtitle-1">Cantidad Facturas</span></v-row>
                <br />
                <v-row justify="center">
                  <span class="font-weight-bold" style="font-size: 24px; color: #58586a">
                    {{ data_invoices.length }}
                    <!-- {{
                            clients.reduce((sum, value) => (sum + parseFloat(value.invoice_quantity)), 0) }} -->
                  </span></v-row>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="2.4">
                <v-row justify="center">
                  <span v-if="type == true" class="subtitle-1">Por cobrar</span>
                  <span v-else class="subtitle-1">Por pagar</span></v-row>
                <br />
                <v-row justify="center">
                  <span class="font-weight-bold text-center" style="font-size: 24px; color: #e85e48">
                    <span class="text-center" v-for="(item, i) of getHeader('porCobrar')" :key="i">
                      {{ currencyFormat(item.total_amount) }}
                      {{ item.currency_code }}
                      <br />
                    </span> </span></v-row>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="2.4">
                <v-row justify="center">
                  <span class="subtitle-1">Notas de crédito </span></v-row>
                <br />
                <v-row justify="center">
                  <span class="font-weight-bold" style="font-size: 24px; color: #e85e48">
                    {{ currencyFormat(0) }}
                  </span></v-row></v-col>
              <v-divider vertical></v-divider>
              <v-col cols="2.4">
                <v-row justify="center">
                  <span v-if="type == true" class="subtitle-1"> Cobrado </span>
                  <span v-else class="subtitle-1"> Pagado</span>
                </v-row>
                <br />
                <v-row justify="center">
                  <span class="font-weight-bold text-center" style="font-size: 24px; color: #00c6b8">
                    <span class="text-center" v-for="(item, i) of getHeader('paid_amount')" :key="i">
                      {{ currencyFormat(item.total_amount) }}
                      {{ item.currency_code }}
                      <br />
                    </span>
                  </span> </v-row></v-col>
            </v-layout>
          </v-card>
          <v-row class="mt-4">
            <v-col cols="12">
              <v-data-table :value="selected" @item-selected="enterSelect($event, 1)"
                @toggle-select-all="enterSelect($event, 2)" v-model="selected" show-select :search="search"
                :headers="headers" :items="allInvoices" :items-per-page="100" class="elevation-0" 
                item-key="invoice_number">
                <template v-slot:top>
                  <v-toolbar class="elevation-0 rounded-lg">
                    <v-toolbar-title>Facturas </v-toolbar-title>
        
                    <v-spacer></v-spacer>
                    <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-checkbox @change="pending_pay?isAll=false:isAll=true" v-model="pending_pay" class="mt-2 mx-2"  :label="'Por pagar'"></v-checkbox>
                    <v-checkbox @change="isAll?pending_pay=false:pending_pay=true"  v-model="isAll" class="mt-2 mx-2"  :label="'Todas'"></v-checkbox>
                    <span class="mx-5 success--text"> Total seleccionado:  <b>$  {{ currencyFormat( selected.reduce((total, item) => total + parseFloat(item.paid), 0)) }}</b></span>

<!-- 
                    <v-btn-toggle v-model="seleted_currencies" class="rounded-lg mr-2" mandatory color="primary">
                      <v-btn v-for="(item, i) of currencies" :key="i" :value="item.id" small>
                        {{ item.code }}
                      </v-btn>
                    </v-btn-toggle> -->

                    <v-btn @click="dialog=true" v-if="pending_pay" color="primary">Distribuir montos</v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.invoice_number`]="{ item }">
                  <v-hover v-slot="{ hover }">
                    <span @click="showInvoice(item, true)"
                      class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                        color: hover ? 'red' : 'black',
                        cursor: hover ? 'pointer' : '',
                        'text-decoration': hover ? 'underline' : '',
                      }">
                      {{ item.invoice_number }}</span>
                  </v-hover>
                </template>
                <template v-slot:[`item.total_amount`]="{ item }">
                  <span>
                    {{ currencyFormat(item.total_amount) }}
                    {{ item.currency_code }}</span>
                </template>
                <template v-slot:[`item.total_pending_amount`]="{ item }">
                  <span style="color: red">
                    {{
                      currencyFormat(item.total_amount - item.total_paid_amount)
                    }}
                    {{ item.currency_code }}</span>
                </template>
                <template v-slot:[`item.total_credit_amount`]="{ item }">
                  <span>
                    {{ currencyFormat(item.total_credit_amount) }}
                    {{ item.currency_code }}</span>
                </template>
                <template v-slot:[`item.total_paid_amount`]="{ item }">
                  <span style="color: green">
                    {{ currencyFormat(item.total_paid_amount) }}
                    {{ item.currency_code }}</span>
                </template>
                <template v-slot:[`item.paid`]="{ item }">
                  <v-text-field :disabled="item.total_amount - item.total_paid_amount < 1"
                    :max="item.total_amount - item.total_paid_amount" v-model="item.paid" value="0"
                    prefix="$"></v-text-field>
                </template>
                <template v-slot:[`item.expire_at`]="{ item }">
                  <span v-if="item.expire_at != null">
                    <span :class="getDateColor(item)">
                      {{ getDate(item.expire_at) }}
                    </span>
                  </span>
                </template>
                <template v-slot:[`item.status_id`]="{ item }">
                  <v-chip small outlined color="success" v-if="item.total_amount - item.total_paid_amount < 1">
                    <span v-if="type == true">Pagado </span>
                    <span v-else>Pagado </span>
                  </v-chip>
                  <v-chip small outlined color="error" v-else>
                    <span v-if="type == true">Por cobrar </span>
                    <span v-else>Por Pagar </span>
                  </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon @click="viewInvoices(item)" color="success" class="mx-2 mt-n2" v-bind="attrs" v-on="on">
                        payments
                      </v-icon>
                    </template>
                    <span>Agregar pago</span>
                  </v-tooltip>
                </template>
    
                <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize"> Reset </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-row v-if="e1 == 2" justify="center">
            <CheckOutCxCVue @close="$emit('close')" v-if="!loaded" :data="invoiceSelected"
              :type="type === true ? 1 : 2" />
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-row class="mt-4" justify="center">
      <v-card elevation="0" color="transparent">
        <v-card-actions>
          <v-btn @click="back" outlined>
            <v-icon v-if="e1 == 1">cancel</v-icon>
            <v-icon v-else>keyboard_backspace </v-icon>{{ e1 == 1 ? "Cancelar" : "Volver" }}
          </v-btn>
          <v-btn v-if="e1 == 1" @click="next" color="primary" outlined>
            <v-icon>arrow_right_alt</v-icon>
            {{ e1 == 1 ? "Siguiente" : "Guardar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-dialog v-model="dialog" max-width="500px">
                  <v-card width="500px">
                    <v-row justify="end"><v-btn class="mx-2 mt-2" @click="dialog=false" icon dark >
                                                <v-icon color="black">mdi-close</v-icon>
                                            </v-btn> </v-row>
                    <v-card-title> <span  class="text-h5">Distribuir monto</span> </v-card-title>
                    <v-card-text>      <v-text-field label="Monto" v-model="amount_disturb"></v-text-field> </v-card-text>
              
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      
                      <v-btn color="blue darken-1" outlined @click="ditribuirMontos">Distribuir</v-btn>
                    
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
  </v-flex>
</template>

<script>
import moment from "moment";

import CheckOutCxCVue from "./CheckOutCxC.vue";
export default {
  components: { CheckOutCxCVue },
  data: () => ({
    loaded: false,
    dialog:false,
    pending_pay: true,
    isAll: false,
    selected: [],
    invoiceSelected: [],
    search: "",
    amount_disturb:0,
    e1: 1,
    currencies: [],
    seleted_currencies: null,
    headers: [
      { text: "Factura", value: "invoice_number", align: "start" },
      { text: "Vencimineto", value: "expire_at" },
      { text: "Monto", value: "total_amount" },
      // { text: "Cant facturas", value: "invoice_quantity", align: "end" },
      { text: "Total Pagado", value: "total_paid_amount", align: "end" },
      { text: "Notas de credito", value: "total_credit_amount", align: "end" },

      {
        text: "Balance pendiente",
        value: "total_pending_amount",
        align: "end",
      },
      { text: "Abono", value: "paid", align: "center", width: "12%" },
      { text: "Status", value: "status_id", align: "center", width: "12%" },

      // { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  props: {
    data_invoices: {
      type: Array,
      default: [],
    },
    type: {
      type: Boolean,
    },
    filters: {
      type: Object
    }
  },
  created() {

    this.getData();
  },
  // watch: {
  //   selected: {
  //     handler(newVal) {
  //       newVal.forEach(invoice => {
  //         console.log(invoice.paid)
  //         let isPresent = this.data_invoices.some(item => item.invoice_id === invoice.invoice_id);
  //         if(isPresent)
  //         {
  //           console.log(invoice.invoice_id)
  //           rghis.
  //           invoice.paid=0
  //         }
  //         // for(var i of this.selected)
  //         // {
  //         //   if(i.invoice_id!=invoice.invoice_id)
  //         // {
  //         //   i.paid=0
  //         // }
  //         // }

  //       //  this.selected = this.selected.filter(item => item.invoice_id !== invoice.invoice_id);

  //       });
  //     },
  //     deep: true
  //   }
  // },
  computed: {
    allInvoices()
    {
      let invoices=[]
      if(this.pending_pay)
      {
       /// invoices=  this.data_invoices.forEach((element)=>element.total_pending_amount>0)
        for(var item of this.data_invoices)
        { 
          if(item.total_amount - item.total_paid_amount>0)
          {
            invoices.push(item)
          }
        }
    
      }
      else
      {
        invoices=this.data_invoices
      }
      return invoices
    }
  },
  methods: {
    async getData() {
      const resultCurrencies = await this.api_Post(
        "accounting/currenciesBusiness",
        {
          business: this.filters.business,
          default_currency_id: 3,
          effective_date: '2023-11-01'
        }
      );
      this.currencies = await resultCurrencies.json();
    },
    back() {
      this.loaded = true;
      this.loaded = false;

      if (this.e1 == 1) {
        this.$emit("close");
      }
      if (this.e1 == 2) {
        this.e1 = 1;
      }
    },
    ditribuirMontos(){
   
     let facturasOrdenadas= this.allInvoices.sort(this.compararPorFecha)
      this.pagarFacturas(this.amount_disturb, facturasOrdenadas)
      this.dialog=false
    },
    pagarFacturas(montoDisponible, facturas) {
      for (let factura of facturas) {
        factura.paid = null
      }
      this.selected=[]
    for (let factura of facturas) {
 
        if (montoDisponible > 0) {
            const saldoPendiente = factura.total_amount - (factura.total_paid_amount || 0);

            if (saldoPendiente > 0) {
              
                if (montoDisponible >= saldoPendiente) {
                  this.selected.push(factura)
                    // Paga la factura completa
                    factura.paid = (parseFloat( factura.total_amount)).toFixed(2);
                    montoDisponible -= saldoPendiente;
                } else {
                  this.selected.push(factura)
                    // Paga parcialmente la factura
                    factura.paid= (parseFloat(factura.total_paid_amount || 0) + montoDisponible).toFixed(2);
                    montoDisponible = 0;
                }
            }
        } else {
            break; // No hay más dinero disponible, sal del bucle
        }
    }

    return facturas;
},
     compararPorFecha(a, b) {
    const fechaA = new Date(a.emission_date);
    const fechaB = new Date(b.emission_date);

    if (fechaA < fechaB) {
        return -1;
    }
    if (fechaA > fechaB) {
        return 1;
    }
    return 0;
},
    enterSelect(item, type) {

      if (type == 1) {
        let isPresent = this.selected.some(selectedInv => selectedInv.invoice_id === item.item.invoice_id);
        if (isPresent) {

          //  console.log(isPresent)
          let index = this.selected.indexOf(item.item);
          this.selected.splice(index, 1)
          if (!isPresent) {
            invoice.paid = null
          }
          this.data_invoices.forEach(inver => {

            if (inver.invoice_id === item.item.invoice_id) {

              inver.paid = null
            }
          });
        }
        else {
          let inv = item.item.invoice_id

          let total = item.item.total_amount;

          // console.log(isPresent);
          this.data_invoices.forEach(ninv => {

            if (ninv.invoice_id === inv) {
              //   console.log(numberi)
              // console.log(ninv.invoice_number)
              ninv.paid = total
            }
          });

        }



      }
      else if (type == 2) {
        console.log(item.value)
        if (item.value == true) {
          this.data_invoices.forEach(ninv => {


            ninv.paid = ninv.total_amount

          });
        }
       
        if (item.value == false) {
    
          this.data_invoices.forEach(ninv => {


            ninv.paid = null

          });
        }
      }

    },
    next() {
      this.loaded = true;
      this.loaded = false;
      if (this.e1 == 1) {
        this.invoiceSelected = [];
        for (let item of this.data_invoices) {
          if (parseFloat(item.paid) > 0) {
            this.invoiceSelected.push(item);
          }
        }
        if (this.invoiceSelected.length > 0) {
          this.e1 = 2;
        } else {
          this.$toast.warning("Debes asignarle un monto");
        }
      }
    },
    formatNumber(number) {
      let n = parseFloat(number).toFixed(2);
      // Formato del número usando toLocaleString
      return parseFloat(n).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    unformatNumber(number) {
      // Elimina la coma y los puntos para que se pueda guardar en el modelo.
      return number.replace(/,/g, "").replace(/\./g, "");
    },
    getDate(item) {
      return moment(item).format("DD/MM/YYYY");
    },
    getHeader(type) {
      let result = [];

      if (type == "paid_amount") {
        this.data_invoices.reduce(function (res, value) {
          if (!res[value.currency_code]) {
            res[value.currency_code] = {
              currency_code: value.currency_code,
              total_amount: 0,
            };
            result.push(res[value.currency_code]);
          }

          res[value.currency_code].total_amount += parseFloat(
            value.total_paid_amount == null ? 0 : value.total_paid_amount
          );
          return res;
        }, {});
      }
      if (type == "porCobrar") {
        this.data_invoices.reduce(function (res, value) {
          if (!res[value.currency_code]) {
            res[value.currency_code] = {
              currency_code: value.currency_code,
              total_amount: 0,
            };
            result.push(res[value.currency_code]);
          }

          res[value.currency_code].total_amount += parseFloat(
            value.total_amount - value.total_paid_amount
          );
          return res;
        }, {});
      }

      return result;
    },
    getDateColor(item) {
      const now = moment();
      const end = moment(item.expire_at);
      if (item.total_amount - item.total_paid_amount < 1) {
        return;
      }

      // moment(dateTime).isAfter(new Date());
      if (
        moment(now.format("YYYY/MM/DD")).isAfter(
          moment(end.format("YYYY/MM/DD"))
        )
      ) {
        return "red--text";
      }
    },
  },
};
</script>

<style scoped></style>
