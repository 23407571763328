import Vue from "vue";
import Router from "vue-router";
import moment from "moment";
import store from "@/store";
import jwt_decode from "jwt-decode";

import Home from "@/views/Home.vue";

import Login from "@/views/Auth/Login.vue";
import AuthView from "@/views/Auth/AuthView.vue"
import ErrorPage from "@/views/ErrorPage.vue";
//PROJECTS
import RouterProject from "@/views/Projects/RouterProject.vue";
import Projects from "@/views/Projects/Projects.vue";
//SHOP
import shop from "./routes/shop"
//ACCOUNTING
import accounting from "./routes/accounting"
//ENTITYS
import entity from "./routes/entity";
//INCOME SALES
import invoices from "./routes/invoice";
//CXC
import cxc from "./routes/cxc";
//CXP
import cxp from "./routes/cxp";
//INVENTORY
import inventory from "./routes/invetory"
//Maintenance
import maintenance from "./routes/maintenance"
//restaurant
import restaurant from "./routes/restaurant"
Vue.use(Router);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      LOGGED: true,
    },
  },
  {
    path: "/auth",
    name: "AuthView",
    component: AuthView,
    meta: {
      libre: true,
    },
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          libre: true,
        },
      },

      ,
    ],
  },
  {
    path: '*', 
    component: ErrorPage,
    name: "ErrorPage",
    libre: true,
  },
  //PROJECT
  {
    path: "/project",
    name: "RouterProject",
    component: RouterProject,
    meta: {
      LOGGED: true,
    },
    children: [
      {
        path: "projects",
        name: "Projects",
        component: Projects,
        meta: {
          LOGGED: true,
        },
      },

      ,
    ],
  },
  maintenance,
  //SHOP
  shop,
  //ENTITYS Entity
  entity,
  //TRANSACTIONS CXC
  cxc,
  //TRANSACTIONS CXP
  cxp,
  //invoices
  invoices,
  //ACCOUNTING
  accounting,
  //INVENTORY
  inventory,
  //RESTAURANT
  restaurant

];

const router = new Router({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(localStorage.getItem("token"));
  const loggedIn = store.state.auth.authStatus;
  var ventas = false;
  var compras = false;
  var contabilidad = false;
  var inventario = false;
  var cxc=false
  var cxp=false
  var rest=false
  if (loggedIn) {
  
    if(store.state.auth.user.type_access!=null)
    {
      ventas = store.state.auth.user.type_access.some(
      (element) => element.access.type_access == 2 && element.access.status == true
    );
    compras = store.state.auth.user.type_access.some(
      (element) => element.access.type_access == 1 && element.access.status == true
    );
    contabilidad = store.state.auth.user.type_access.some(
      (element) => element.access.type_access == 4 && element.access.status == true
    );
    inventario = store.state.auth.user.type_access.some(
      (element) => element.access.type_access == 3 && element.access.status == true
    );
    cxc = store.state.auth.user.type_access.some(
      (element) => element.access.type_access == 5 && element.access.status == true
    );
    cxp = store.state.auth.user.type_access.some(
      (element) => element.access.type_access == 6&& element.access.status == true
    );
    rest = store.state.auth.user.type_access.some(
      (element) => element.access.type_access == 7&& element.access.status == true
    );

    }
   
  }

      if (to.matched.some((record) => record.meta.libre)) {
        next();
      }
      else if (loggedIn === true&&to.matched.some((record) => record.meta.LOGGED)) {
      next();
    }

    else if ( ventas === true && to.matched.some((record) => record.meta.SALES)) {
     
      next();
    }
    else  if (compras === true&&to.matched.some((record) => record.meta.SHOP)) {
      
      next();
    }

    else  if (inventario === true&&to.matched.some((record) => record.meta.IVENTORY)) {
      next();
    }
 
    else   if (contabilidad === true&&to.matched.some((record) => record.meta.ACCOUNTING)) {
      next();
    }
  
    else  if (cxc === true&&to.matched.some((record) => record.meta.CXC)) {
      next();
    }
  
    else  if (cxp == true&&to.matched.some((record) => record.meta.CXP)) {
      next();
    }
    else  if (rest == true&&to.matched.some((record) => record.meta.REST)) {
      next();
    }
    else {
      next({
        name: "Login",
      });
    }

  var statusToken = moment(
    jwt_decode(localStorage.getItem("token")).exp
  ).isBefore(moment().unix());
    

  if (store.state.auth.authStatus == true) {
    if (localStorage.getItem("token") == null) {
      store.dispatch("auth/logOut");
    }
    if (statusToken == true) {
      store.dispatch("auth/logOut");
    }
  }
});
// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/*'];
//   const authRequired = !publicPages.includes(to.path);

//   const loggedIn = store.state.auth.authStatus;

//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
  
//   var statusToken = moment(
//     jwt_decode(localStorage.getItem("token")).exp
//   ).isBefore(moment().unix());
    

//   if (store.state.auth.authStatus == true) {
//     if (localStorage.getItem("token") == null) {
//       store.dispatch("Auth/logOut");
//     }
//     if (statusToken == true) {
//       store.dispatch("Auth/logOut");
//     }
//   }
// });
export default router;
