<template>
  <v-flex>
    <v-btn v-if="dial" @click="dialog = true" outlined> Agregar item <v-icon>add</v-icon> </v-btn>
    <v-container v-if="!dial">
      <v-card outlined>
        <v-data-table :headers="headerArticle" :search="search" :items="articles">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Articulos</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <span>{{ articles.length }}</span>
              <v-spacer></v-spacer>
              <v-text-field class="text-xs-center" v-model="search" @change="searchingWithLike" append-icon="search"
                :label="labelSearch" :color="colorSearch" hide-details>
              </v-text-field>
              <v-spacer></v-spacer>
              <v-icon color="primary" @click="dialogFilters = true">filter_alt</v-icon>
              <v-icon class="mx-2" color="primary" @click="dialog = false">close</v-icon>
              <v-divider class="mx-3" inset vertical></v-divider>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" @click="newArticle()" class="mr-2" v-bind="attrs" v-on="on"
                    v-show="showButonNewArticle">
                    <v-icon>add</v-icon> Nuevo articulo
                  </v-btn>
                </template>
                <span>Registrar nuevo pago articulo</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editArticle(item)">
                  {{ icon }}
                </v-icon>
              </template>
              <span>{{ name }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-dialog height="auto" v-model="dialog">
      <v-card outlined>
        <v-data-table :headers="headerArticle" :search="search" :items="articles">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Articulos</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <span>{{ articles.length }}</span>
              <v-spacer></v-spacer>
              <v-text-field class="text-xs-center" v-model="search" @change="searchingWithLike" append-icon="search"
                :label="labelSearch" :color="colorSearch" hide-details>
              </v-text-field>
              <v-spacer></v-spacer>
              <v-icon color="primary" @click="dialogFilters = true">filter_alt</v-icon>
              <v-icon class="mx-2" color="primary" @click="dialog = false">close</v-icon>
              <v-divider class="mx-3" inset vertical></v-divider>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" @click="newArticle()" class="mr-2" v-bind="attrs" v-on="on"
                    v-show="showButonNewArticle">
                    <v-icon>add</v-icon> Nuevo articulo
                  </v-btn>
                </template>
                <span>Registrar nuevo pago articulo</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editArticle(item)">
                  {{ icon }}
                </v-icon>
              </template>
              <span>{{ name }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFilters" max-width="45%" persistent>
      <v-card>
        <v-card-title>
          <v-icon>filter_alt</v-icon>
          <span class="headline">Filtros</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ }">
              <v-btn icon @click="dialogFilters = false">
                <v-icon>clear</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-autocomplete v-model="filters.item_type" :items="itemTypes" item-value="id" item-text="name"
                color="cyanText" label="Tipo de producto" outlined required dense clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete v-model="filters.unit" :items="units" item-value="id" item-text="name" color="cyanText"
                label="Unidad de medida" outlined required dense clearable>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-autocomplete v-model="filters.item_category" :items="categorys" item-value="id" item-text="name"
                color="cyanText" label="Categoria" outlined required dense clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete v-model="filters.account_category" :items="accountCategorys" item-value="id"
                item-text="name" color="cyanText" label="Categoria de cuenta" outlined required dense clearable>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-autocomplete v-model="filters.tax_type" :items="taxes" item-value="id" item-text="name" color="cyanText"
                label="Tipo de impuesto" outlined required dense clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete v-model="filters.inventory_product" :items="[
                { value: 1, text: 'AMBOS' },
                { value: 2, text: 'SI' },
                { value: 3, text: 'NO' },
              ]" color="cyanText" label="Inventariable" outlined required dense>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete v-model="filters.negative_sale" :items="[
                { value: 1, text: 'AMBOS' },
                { value: 2, text: 'SI' },
                { value: 3, text: 'NO' },
              ]" color="cyanText" label="Valores negativos" outlined required dense>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="success" text @click="filtrar">
                <v-icon> search </v-icon>
                Buscar
              </v-btn>
            </template>
            <span>Aplicar seleccion</span>
          </v-tooltip>
          <v-btn color="red darken-1" text @click="dialogFilters = false">
            <v-icon> clear </v-icon>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialogFilters: false,
    articles: [],
    itemTypes: [],
    taxes: [],
    categorys: [],
    accountCategorys: [],
    units: [],
    filters: {
      unit: "",
      item_type: "",
      item_category: "",
      account_category: "",
      tax_type: "",
      inventory_product: "",
      negative_sale: "",
    },
    search: "",
    headerArticle: [
      {
        text: "ID",
        align: "start",
        value: "item_id",
      },
      { text: "Descripcion", value: "item_name" },
      { text: "Unidad de medida", value: "unit_name" },
      { text: "Tipo", value: "item_type_name", align: " d-none" },
      { text: "Categoria", value: "item_categorie_name", align: " d-none" },
      {
        text: "Categoria de cuenta",
        value: "account_categorie_name",
        align: " d-none",
      },
      { text: "Acciones", value: "actions", sortable: false },
    ],
  }),
  name: "ItemSelectionList",
  props: {
    items_catalogue: {
      type: Boolean,
      default: false,
    },
    catalogues_id: {
      type: Number,
      default: 0
    },
    dial: {
      type: Boolean,
      default: true
    },
    //Determina si se muestra el boton de crear articulo
    showButonNewArticle: {
      type: Boolean,
      default: false,
    },
    //Recibe un filtro para cargar por defecto la lista de articulos
    startFilter: {
      type: String,
      default: "",
    },
    //icono que se mostrara para seleccionar el articulo
    icon: {
      type: String,
      default: "edit",
    },
    //tooltip del icono
    name: {
      type: String,
      default: "Editar",
    },
    //Determina si se muestran todas las columnas, si es falso se ocultan el tipo y las categorias
    typeView: {
      type: Boolean,
      default: true,
    },
    //Determina si suscara al escribir en el buscador con un like
    searchWithLike: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    colorSearch() {
      if (this.searchWithLike == true) {
        return this.search.length > 3 ? "success" : "error";
      }
      return "primary";
    },
    labelSearch() {
      if (this.searchWithLike == true && this.search.length > 3) {
        return "Precione enter para buscar";
      }
      return "";
    },
  },
  watch: {
    catalogues_id: {
      handler: function (nuevoValor, viejoValor) {
        if (nuevoValor != 0) {
          this.getArticlesByCatalogue(this.catalogues_id)
        }
      },
      deep: true, // Para obserlet cambios profundos en el objeto filters
      immediate: true // Para ejecutar el watch inmediatamente al crear el componente
    }
  },
  methods: {
    async initialize() {
      if (this.items_catalogue === true) {
        if (this.catalogues_id != 0) {
          this.getArticlesByCatalogue(this.catalogues_id)
        }
      }

      else {

        if (this.searchWithLike == false) {
          this.getArticles({ filters: this.startFilter });
        }
      }

      //----------------UNIDADES--------------------
      const resultUnits = await this.api_Get("inventory/units");
      this.units = await resultUnits.json();
      //----------------ITEM TYPES--------------------
      const resultItemTypes = await this.api_Get("inventory/itemTypes");
      this.itemTypes = await resultItemTypes.json();
      //----------------TAXES-------------------------
      const taxesRequest = await this.api_Get("accounting/taxes");
      this.taxes = await taxesRequest.json();
      //----------------CATEGORYS-------------------------
      const categoryRequest = await this.api_Get("inventory/categories");
      this.categorys = await categoryRequest.json();
      //----------------ACCOUNT CATEGORYS-------------------------
      const resultAccounts = await this.api_Get(
        "accounting/account_categories"
      );
      this.accountCategorys = await resultAccounts.json();

      if (this.typeView == false) {
        this.headerArticle[3].align = "center";
        this.headerArticle[4].align = "center";
        this.headerArticle[5].align = "center";
      }
      this.filters.inventory_product = 1;
      this.filters.negative_sale = 1;
    },
    async getArticles(filters) {
      //----------------ARTICULOS--------------------
      const resultArticles = await this.api_Post("inventory/articles", filters);
      this.articles = await resultArticles.json();
    },
    async getArticlesByCatalogue(id) {
      //----------------ARTICULOS--------------------
      let resultCatalogues = await this.api_Get(
        "inventory/catalogues/details/" + id
      );
      this.articles = await resultCatalogues.json();
    },
    searchingWithLike() {
      if (this.search.length > 3) {
        this.getArticles({
          filters: ` WHERE ITEM_NAME ILIKE '%${this.search}%' `,
        });
      }
    },
    filtrar() {
      let filtros = " WHERE ";

      filtros += Number.isInteger(this.filters.unit)
        ? ` UNIT_ID=${this.filters.unit} AND  `
        : ` `;
      filtros += Number.isInteger(this.filters.item_type)
        ? ` ITEM_TYPE_ID=${this.filters.item_type} AND  `
        : ` `;
      filtros += Number.isInteger(this.filters.item_category)
        ? ` ITEM_CATEGORY_ID=${this.filters.item_category} AND  `
        : ` `;
      filtros += Number.isInteger(this.filters.account_category)
        ? ` UNIT_ID=${this.filters.account_category} AND  `
        : ` `;
      filtros += Number.isInteger(this.filters.tax_type)
        ? ` TAX_TYPE_ID=${this.filters.tax_type} AND  `
        : ` `;
      filtros +=
        this.filters.inventory_product != 1
          ? ` INVENTORY_PRODUCT=${this.getType(
            this.filters.inventory_product
          )} AND  `
          : ` `;
      filtros +=
        this.filters.negative_sale != 1
          ? ` NEGATIVE_SALE=${this.getType(this.filters.negative_sale)} AND  `
          : ` `;

      if (filtros.trim() == "WHERE") {
        filtros = "";
      }
      this.getArticles({ filters: filtros.slice(0, -4) });
    },
    getType(id) {
      if (id == 2) {
        return true;
      } else if (id == 3) {
        return false;
      }
    },
    newArticle() {
      this.$emit("newArticle", true);
    },
    editArticle(item) {
      this.$emit("getItemToEdit", item);
    },
  },
  components: {},
};
</script>
