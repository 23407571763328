<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <form action="">
          <v-row class="mt-2 mb-2" justify="center">
            <span style="font-size: 28px; color: #616161">Libro diario</span>
          </v-row>
          <search-header @dataFilters="searchLoaded($event)" :selected_week="1" :show_category="true" />
          <v-row justify="center">
            <v-col cols="12" md="12" aling="center">
              <v-data-table v-if="loading == false" :headers="headers" :items="dataDiary" class="elevation-1 rounded-lg">
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Asientos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.diary_book_code`]="{ item }">
                  <v-hover v-slot="{ hover }">
                    <span @click="showItem(item, true)"
                      class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                        color: hover ? 'red' : 'black',
                        cursor: hover ? 'pointer' : '',
                        'text-decoration': hover ? 'underline' : '',
                      }">
                      {{ item.diary_book_code }}</span>
                  </v-hover>
                </template>
                <template v-slot:[`item.created_date`]="{ item }">
                  <span class="font-weight-normal subtitle-3">
                    {{ getDate(item.created_date) }}</span>
                </template>
                <template v-slot:[`item.accounts`]="{ item }">
                  <span v-for="(items, i) of item.diary_book_details" :key="i">
                    <span class="primary--text">{{ items.chart_of_account_code }}
                    </span>
                    - {{ items.chart_of_account_name }} <br /></span>
                </template>

                <template v-slot:[`item.tercero`]="{ item }">
                  <v-hover v-slot="{ hover }">
                    <span @click="viewEntity(item.entity_id, true)" style="font-size: 14px"
                      class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                        color: hover ? 'red' : 'black',
                        cursor: hover ? 'pointer' : '',
                        'text-decoration': hover ? 'underline' : '',
                      }">{{ item.entity_name }}</span></v-hover>
                </template>
                <template v-slot:[`item.debito`]="{ item }">
                  <span v-for="(items, i) of item.diary_book_details" :key="i">
                    <template v-if="items.entry_type_id == 1">
                      {{ item.currency_code }}
                      {{
                        items.amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </template>
                    <br /></span>
                </template>

                <template v-slot:[`item.credito`]="{ item }">
                  <span v-for="(items, i) of item.diary_book_details" :key="i">
                    <template v-if="items.entry_type_id == 2">
                      {{ item.currency_code }}
                      {{
                        items.amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </template>
                    <br /></span>
                </template>
                <template slot="body.append">
                  <!-- <tr class="mt-2 mb-2">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th
                      class="font-weight-bold"
                      style="color: #607d8b; font-size: 24px"
                    >
                      TOTAL
                    </th>
               
                    <th
                      style="font-size: 20px"
                      class="font-weight-bold text-right success--text"
                    >
                      {{ currencyFormat(debitoAndCredito.debito) }}
                    </th>
                    <th
                      class="font-weight-bold text-right error--text"
                      style="font-size: 20px"
                    >
                      {{ currencyFormat(debitoAndCredito.credito) }}
                    </th>
                  </tr> -->
                  <tr class="mt-2 mb-2" v-for="(item, i) of debitoAndCredito.arrayCurrency" :key="i">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th class="font-weight-bold" style="color: #607d8b; font-size: 18px">
                      TOTAL {{ item.currency }}
                    </th>

                    <th style="font-size: 16px" class="font-weight-bold text-right success--text">
                      {{ currencyFormat(item.debito) }} {{ item.currency }}
                    </th>
                    <th class="font-weight-bold text-right error--text" style="font-size: 16px">
                      {{ currencyFormat(item.credito) }} {{ item.currency }}
                    </th>
                  </tr>
                </template>
              </v-data-table>

              <v-skeleton-loader v-else v-bind="attrs"
                type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
                height="500px"></v-skeleton-loader>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <v-card>
        <ViewEntryDiary :entryDiary="selectedItem" @close="dialog = false" :key="selectedItem.diary_book_id">
        </ViewEntryDiary>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import ViewEntryDiary from "../../components/Accounting/ViewEntryDiary";
//import SearchHeader from "../../components/SearchHeader.vue";
export default {
  data: () => ({
    attrs: {
      class: "mb-12 mt-12",
      boilerplate: true,
      elevation: 2,
    },
    loading: false,
    showEntry: false,

    menu: false,
    menu2: false,
    selectedCategoryDgii: "",
    categoryDgii: [],
    seletecName: "",
    dialog: false,
    items: [],
    itemsBussines: [],
    itemsLocations: [],
    itemsLocations2: [],
    itemsDepartment: [],
    itemSaved: [],
    ategoryDgii: [],
    newItem: [],
    itemsRoutes: [],
    editedItem: [],
    dataDiary: [],
    catalogue_id: null,
    editedIndex: -1,
    filters: {
      business: null,
      location: null,
      department_id: null,
      routes: "",
    },
    editedIndex: -1,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Asiento",
        align: "start",
        sortable: false,
        value: "diary_book_code",
      },
      { text: "Numero", value: "diary_book_number" },
      {
        text: "Unidad de negocio",
        value: "business_unit_description",
        width: "15%",
        align: "center",
      },
      { text: "Fecha", value: "created_date", width: "10%", align: "center" },
      { text: "Entidad", value: "tercero" },

      { text: "Cuenta contable", value: "accounts" },
      { text: "Debito", value: "debito", align: "end" },
      { text: "Credito", value: "credito", align: "end" },
    ],
    rangeSelected: "",
    rangeTime: [
      { text: "Hoy", value: "1" },
      { text: "Este semana", value: "2" },
      { text: "Este mes", value: "3" },

      { text: "Este año", value: "4" },
    ],
    selectedItem: [],
  }),
  created() {
    this.getData();
  },
  components: { ViewEntryDiary },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Crear cuenta contable "
        : "Editar cuenta contable ";
    },
    debitoAndCredito() {
      let debito = 0;
      let credito = 0;
      let arrayDebito = [];
      let arrayCurrency = [];
      let deb = 0;
      let cre = 0;
      for (let item of this.dataDiary) {
        if (item.diary_book_details != null) {
          item.diary_book_details.forEach((element) => {
            if (element.entry_type_id == 1) {
              debito += parseFloat(element.amount);
              deb = parseFloat(element.amount);
            }
          });

          item.diary_book_details.forEach((element) => {
            if (element.entry_type_id == 2) {
              credito += parseFloat(element.amount);
              cre = parseFloat(element.amount);
            }
          });

          arrayCurrency.push({
            debito: deb,
            credito: cre,
            currency: item.currency_code,
          });
        }
      }

      let resultArray = [];

      arrayCurrency.reduce(function (res, value) {
        if (!res[value.currency]) {
          res[value.currency] = {
            currency: value.currency,
            debito: value.debito,
            credito: value.credito,
          };
          resultArray.push(res[value.currency]);
        }
        res[value.currency].debito += value.debito;
        res[value.currency].credito += value.credito;
        return res;
      }, {});

      return {
        debito: debito,
        credito: credito,
        arrayCurrency: resultArray,
      };
    },
  },
  watch: {

  },
  methods: {
    showItem(item) {

      if (item.diary_book_type_id == 1 || item.diary_book_type_id == 3) {
        this.showInvoice(item, true)
        // this.$router.push({
        //   name: "ViewInvoice",
        //   params: { id: item.invoice_number },
        // });
      } else {
        this.dialog = false;
        this.showEntry = false;
        this.selectedItem = item;
        this.showEntry = true;
        this.dialog = true;
      }
    },
    getDate(date) {
      let newDate = moment(date).format("YYYY-MM-DD HH:MM:SS A").toString();
      return newDate;
    },


    async searchLoaded(item) {
  
      this.loading = true;

      let filters = ''
      filters += item.business != 0 ? "and business_unit_id in(" + item.business + ")" : ''
      filters += item.location != 0 ? " and location_id in (" + item.location + ")" : ''
      filters += item.dateStart != 0 ? " and effective_date>= " + `'${item.dateStart}'` : ''
      filters += item.dateEnd != 0 ? " and effective_date<= " + `'${item.dateEnd}'` : ''
     

      let cat = {"categories": []};
      let sub = {"subcategories": []};
      if(item.category!=null)
      {
        cat.categories.push({"category_id": item.category})
      }
      if(item.sub_category!=null)
      { 
        sub.subcategories.push({"category_id": item.sub_category})
      }

      filters += item.category != null ? ` and diary_book_details::jsonb @>'[${JSON.stringify( cat)}, ${JSON.stringify( sub)}]'` : ''

      const responseRequest = await this.api_Post("accounting/diary_book", {
        filtros: filters,
      });
      let dataDiarys = await responseRequest.json();
      this.dataDiary = dataDiarys;

      this.loading = false;
    },
    async getData() {
      this.loading = true;
      this.itemsBussines = Object.assign(
        [],
        this.$store.state.auth.bussines_units
      );
      this.rangeSelected = this.rangeTime[2];
      //combo locations----------------------------------------------
      this.itemsLocations = Object.assign([], this.$store.state.auth.locations);

      this.filters.business = this.itemsBussines[0].value;

      this.itemsBussines.unshift({ text: "Todas", value: 0 });

      this.itemsDepartment = await this.g_departments();
      this.itemsDepartment.unshift({ text: "Todos", value: 0 });
      this.filters.department_id = this.itemsDepartment[0].value;
      this.itemsLocations2.unshift({ text: "Todas", value: 0 });
      this.filters.location = this.itemsLocations2[0].value;
      let resultCategoryDgii = await this.api_Get("accounting/category_dgii");

      const dataCategoryDgii = await resultCategoryDgii.json();
      // let resultDiary = await this.api_Get("accounting/diary_book");
      // let dataDiarys = await resultDiary.json();
      // this.dataDiary = dataDiarys;

      for (let item of dataCategoryDgii) {
        this.categoryDgii.push({ text: item.name, value: item.id });
      }
      this.CategoryDgii = dataCategoryDgii;

      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      const dataTypeAccounts = await resultTypeAccounts.json();

      let child = [];
      for (let items of dataTypeAccounts) {
        child.push({
          id: items.id,
          name: items.name,
          parent: items.parent_id,
          code: items.code,
          note: items.note,
          account_type_id: items.account_type_id,
          dgii_category_id: items.dgii_category_id,
        });
      }

      const nest = (items, id = null, link = "parent") =>
        items
          .filter((item) => item[link] === id)
          .map((item) => ({ ...item, children: nest(items, item.id) }));

      let result = nest(child);

      this.items = result;
      this.loading = false;
    },
    async save() {
      //  console.log(this.selectedCategoryDgii);
      if (this.editedItem.name == "") {
        this.$toast.info("Digita el nombre de la cuenta!");
        return;
      }

      if (this.editedItem.code === ``) {
        this.$toast.info("Digita el codigo de la cuenta!");
        return;
      }

      if (this.selectedCategoryDgii == "") {
        this.$toast.info("Digita el codigo de la dgii!");
        return;
      }
      if (this.editedIndex > -1) {
        const responseRequest = await this.api_Post(
          "accounting/accounting_account",
          {
            chart_of_account_id: this.editedItem.id,
            account_type_id: this.editedItem.account_type_id,
            parent_id: this.editedItem.parent,
            dgii_category_id: this.selectedCategoryDgii.value,
            code: this.editedItem.code,
            name: this.editedItem.name,
            note: this.editedItem.note,
          }
        );

        if (responseRequest.status == 200) {
          this.getData();
          this.$toast.success("cuenta actualizada con exito!");
        } else {
          this.$toast.error("error al crear la cuenta!");
        }
      } else {
        const responseRequest = await this.api_Post(
          "accounting/accounting_account",
          {
            chart_of_account_id: null,
            account_type_id: this.itemSaved.account_type_id,
            parent_id: this.itemSaved.id,
            dgii_category_id: this.selectedCategoryDgii.value,
            code: this.editedItem.code,
            name: this.editedItem.name,
            note: this.editedItem.note,
          }
        );

        if (responseRequest.status == 200) {
          this.getData();
          this.$toast.success("cuenta creadad con exito!");
        } else {
          this.$toast.error("error al crear la cuenta!");
        }
      }
      this.close();
    },
    updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
      itemsDestino.splice(0, itemsDestino.length);

      for (let item of itemsOrigen) {
        if (item.bus_id === condicion) {
          itemsDestino.push({
            value: item.value,
            text: item.text,
            routes: item.routes,
          });
        }
      }
      itemsDestino.unshift({ text: "Todas", value: 0 });
      this.filters.location = this.itemsLocations2[0].value;

      return itemsDestino;
    },
    editItem(item) {
      // console.log(item);
      this.selectedCategoryDgii = this.editedItem.dgii_category_id;
      this.editedIndex = 0;
      this.editedItem = Object.assign({}, item);
      this.seletecName = this.editedItem.name;
      this.selectedCategoryDgii;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    addAccount(item) {
      this.editedItem = [];
      this.itemSaved = [];
      this.selectedCategoryDgii = "";
      this.editedIndex = -1;
      let data = Object.assign({}, item);
      this.itemSaved = data;
      this.seletecName = data.name;
      this.editedItem.name = "";
      this.editedItem.code = "";
      this.dialog = true;
    },
  },
};
</script>

<style></style>
