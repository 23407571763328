
import CxP from "@/views/CxP/CxP.vue";
import PaymentsCxp from "@/views/CxP/Payments.vue";
import TransactionsCxP from "@/views/CxP/Transactions.vue"
import ViewPayments from "@/views/CxP/ViewPayments.vue"
import ViewTransactionsCxP from "@/views/CxP/ViewTransactionsCxP.vue"
const cxpRoute=  {
    path: "/cxp",
    name: "cxp",
    component: CxP,
    meta: {
      CXP: true,
    },
    children: [
      {
        path: "transactions_cxp",
        name: "TransactionsCxP",
        component: TransactionsCxP,
        meta: {
          CXP: true,
        },
      },
      {
        path: "payments_cxp",
        name: "PaymentsCxp",
        component: PaymentsCxp,
        meta: {
          CXP: true,
        },
      },
      {
        path: "view_payments/:id?",
        name: "ViewPaymentsCxP",
        component: ViewPayments,
        meta: {
          CXP: true,
        },
      },
      {
        path: "view_transactions_cxp/:id?",
        name: "ViewTransactionsCxP",
        component: ViewTransactionsCxP,
        meta: {
          CXP: true,
        },
      },
      // {
      //   path: "view_transactions/:id?",
      //   name: "ViewTransactions",
      //   component: ViewTransactions,
      //   meta: {
      //     SHOP: true,
      //   },
      // },
    ],
  }


export default cxpRoute
