<template>
  <v-flex>
    <v-btn :disabled="types_clients === 3 || types_clients === 4" @click="dialogSearch = true" class="ml-2"
      color="transparent">
      <v-icon size="25px">search</v-icon>
      Buscar
    </v-btn>
    <v-dialog width="60%" v-model="dialogSearch">
      <v-card width="100%" height="100%">
        <v-toolbar flat dark color="transparent">
          <v-spacer></v-spacer>
          <v-btn @click="$emit('close'),dialogSearch=false" icon dark>
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> </v-toolbar-title>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <v-card elevation="0">
            <v-data-table id="tabla" :headers="headers" :items="clients" :search="search" style="height: 70vh">
              <template v-slot:top>
                <v-toolbar flat id="toolbar">
                  <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" single-line
                    hide-details>
                  </v-text-field>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon large color="blue" class="mr-2" v-bind="attrs" v-on="on" @click="selectItem(item)">
                      done
                    </v-icon>
                  </template>
                  <span>Selecionar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card></v-card-text>
      </v-card>
    </v-dialog>
  </v-flex>
</template>
<script>
export default {
  data: () => ({
    clients: [],
    search: "",
    dialogSearch: false,
    headers: [
      {
        text: "Nombre/Razon social",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Identificacion", value: "document_id" },
      { text: "Telefono", value: "phone_number" },
      { text: "Email", value: "email" },

      { text: "Actions", value: "actions", sortable: false },
    ],

    editedIndex: -1,
    editedItem: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo contacto" : "Editar contacto";
    },
  },
  props: {
    types_clients: {
      type: Number,
    },
  },
  watch: {
    search(val, newVal) {
      if (val.length >= 2) {
        this.searchClient(val);
      } else {
        this.clients = [];
      }
    },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    formTitle() {
      return this.types_clients === 1 ? "Suplidores" : "Clientes";
    },
  },
  created() {
    // console.log(this.type);
    // if (this.type == 2) {
    //   this.cliente = true;
    // }
    // if (this.type == 3) {
    //   this.suplidor = true;
    // }
    this.initialize();
  },

  methods: {
    async getData() {
      let resultEntity = await this.api_Get("accounting/entity_all");

      this.entitys = await resultEntity.json();
    },
    async searchClient(val, type) {
      let typ =
        this.types_clients == 2
          ? "AND IS_CUSTOMER=true"
          : "AND IS_SUPPLIER=true";

      const responseRequest = await this.api_Post("accounting/search_entity", {
        name: val,
        type: typ,
      });
      if (responseRequest.status == 200) {
        let data = await responseRequest.json();
        //  console.log(data);
        this.clients = data;
      } else {
        this.$toast.error("error al agregar!");
      }
    },
    async initialize() {
      // this.getData();
    },
    selectItem(item) {
      // console.log(item);
      this.$emit("selected-item", item);
      this.dialogSearch = false;
    },
    deleteItemConfirm() {
      this.entitys.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style></style>
