<template>
  <v-layout align-start>
    <v-flex>
      <v-container>
        <v-row justify="center">
          <span class="mx-3 mt-3" style="font-size: 34px; color: #616161">Solicitantes</span>
        </v-row>
        <v-row justify="center" class="mt-n1 mx-2">
          <span style="font-size: 16px; color: #757575">Lista de empleados con la capacidad de hacer solicitudes de
            compra,
            segun los locations definidos.
          </span>
        </v-row>
        <!-- <v-row justify="center">
          <v-col cols="12" md="8">
            <search-header :show_weeks="false" :show_department="false" @dataFilters="updateFilter" />
          </v-col>
        </v-row> -->
        <v-card class="mt-5" outlined>
          <v-card-text>
            <v-card flat class="mt-0 pt-0">
              <v-card-text class="mt-0 pt-0">
                <v-data-table :headers="headers" :search="search" :items="allApplicants">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Solicitantes</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <span>{{ applicantsFilter.length }}</span>
                      <v-spacer></v-spacer>
                      <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar"
                        single-line hide-details>
                      </v-text-field>
                      <v-spacer></v-spacer>
                      <v-divider class="mx-3" inset vertical></v-divider>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" class="mr-2" v-bind="attrs" v-on="on" outlined @click="newApplicant()">
                            Nuevo
                            <v-icon>add</v-icon>
                          </v-btn>
                        </template>
                        <span>Agregar nuevo solicitante</span>
                      </v-tooltip>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.edit`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" medium color="primary" @click="edditApplicant(item)">
                          edit
                        </v-icon>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-dialog v-model="dialogEdit" width="75%">
            <v-card outlined>
              <v-card-title class="mb-3">
                <span>Editar solicitante</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogEdit = false">
                  <v-icon>clear</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row justify="center">
                    <v-col cols="12" sm="3" md="3" class="pa-0 pr-1">
                      <v-text-field class="text-xs-center" :rules="codeRule" v-model="editApplicant.employee_code"
                        label="Codigo" outlined append-icon="search" @click:append="validate_code()"
                        @keyup.enter="validate_code()" :max="6" :loading="loadSearch" :readonly="!canEdit" dense>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="6" class="pa-0">
                      <v-text-field class="text-xs-center" v-model="editApplicant.employee_fullname" label="Nombre"
                        outlined readonly dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" sm="3" md="3" class="pa-0 pr-1 mb-2">
                      <v-text-field class="text-xs-center" v-model="editApplicant.employee_data[0].department"
                        label="Departamento" outlined readonly dense>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="4" class="pa-0 mb-2">
                      <v-text-field class="text-xs-center" v-model="editApplicant.employee_data[0].job_title"
                        label="Puesto" outlined readonly dense>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2" class="mb-2 pt-0" align="center">
                      <v-btn outlined color="success" @click="save()" :loading="loadingSave">Guardar</v-btn>
                    </v-col>
                  </v-row>
                  <DataIterator :all_items="setAllItems" :items_selected="setItemSelected" :title="setTitle" />
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-container>
    </v-flex>
  </v-layout>
</template>
<script>
import DataIterator from "../../components/Shop/DataIterator";
export default {
  data: () => ({
    codeRule: [(v) => /^([0-9])*$/.test(v) || "Codigo no valido!"],
    setTitle: {
      all: "Todos los locations",
      selected: "Locations seleccionados",
    },
    search: "",
    filters: {
      business: null,
      location: null,
    },
    allApplicants: [],
    headers: [
      {
        text: "Codigo",
        align: "start",
        value: "id",
      },
      { text: "Solicitante", value: "employee_fullname" },
      { text: "Departamento", value: "employee_data[0].department" },
      { text: "Puesto", value: "employee_data[0].job_title" },
      { text: "Editar", value: "edit", sortable: false },
    ],
    dialogEdit: false,
    editApplicant: {
      id: null,
      employee_code: null,
      employee_fullname: null,
      employee_data: [
        {
          department: null,
          job_title: null,
          employee_status_id: null,
        },
      ],
      status_id: null,
      access: {
        is_buyer: null,
        locations: [],
      },
    },
    setAllItems: [],
    setItemSelected: [],
    canEdit: true,
    is_new: false,
    loadSearch: false,
    loadingSave: false,
  }),
  components: { DataIterator },
  created() {
    this.initialized();
  },
  computed: {
    applicantsFilter() {
      return this.allApplicants.filter((element) =>
        element.access.locations.includes(this.filters.location)
      );
    },
  },
  watch: {},
  methods: {
    async initialized() {
      this.setLocations();
      this.getData()
    },
    async setLocations() {
      let data = await this.g_locations();

      for (let item of data) {
        this.setAllItems.push({ id: item.value, name: item.text });
      }
    },
    async getData() {
      const request = await this.api_Get("shopping/applicants");
      const data = await request.json();

      if (request.status == 200) {
        this.allApplicants = data;
      }
    },
    edditApplicant(item) {
      this.setItemSelected = item.access.locations;
      this.editApplicant = item;
      this.is_new = false;
      this.canEdit = false;
      this.dialogEdit = true;
    },

    async save() {
      if (this.editApplicant.id == null) {
        this.$toast.warning("Debe escoger un empleado!");
        return;
      }
      if (this.is_new == true && this.setItemSelected.length == 0) {
        this.$toast.warning("Debe agregar al menos 1 location!");
        return;
      }
      this.loadingSave = true;
      try {
        const result = await this.api_Post("shopping/save_applicants", {
          employee_id: this.editApplicant.id,
          access:
            this.setItemSelected.length > 0
              ? {
                is_buyer: true,
                locations: this.setItemSelected,
              }
              : null,
        });
        const data = await result.json();
        if (result.status == 200) {
          this.getData();
          this.$toast.success(
            this.is_new == true
              ? "Registrado correctamente"
              : "Editado correctamente!"
          );
          this.dialogEdit = false;
        } else {
          this.$toast.error(data.error);
        }
      } catch (error) {
        this.$toast.error("Error: " + error);
      }
      this.loadingSave = false;
    },
    newApplicant() {
      this.canEdit = true;
      this.editApplicant.id = null;
      this.editApplicant.employee_code = "";
      this.editApplicant.employee_fullname = null;
      this.editApplicant.employee_data[0].department = null;
      this.editApplicant.employee_data[0].job_title = null;
      this.setItemSelected = [];
      this.is_new = true;
      this.dialogEdit = true;
    },

    async updateFilter(event) {
      console.log(event)
      this.filters.business = event.business;
      this.filters.location = event.location;
      this.getData();
    },
    async validate_code() {
      if (this.editApplicant.employee_code == "") {
        this.$toast.warning("El codigo no puede estar vacio!");
        return;
      }
      try {
        this.loadSearch = true;
        const result = await this.api_Post("shopping/validate_applicant", {
          employee_code: this.filLeft(this.editApplicant.employee_code, 6, "0"),
        });
        const data = await result.json();
        if (result.status == 200) {
          if ((await data.length) == 0) {
            this.$toast.info("No se encontraron resultados!");
            this.loadSearch = false;
            this.editApplicant.id = null;
            this.editApplicant.employee_fullname = null;
            this.editApplicant.employee_data[0].department = null;
            this.editApplicant.employee_data[0].job_title = null;
            return;
          }
          if (parseInt(data[0].employee_status_id) >= 4) {
            this.$toast.warning("Este empleado no se encuentr activo!");
            return;
          }

          if (data[0].access != null) {
            this.$toast.info("Este empleado ya se encuentra registrado!");
            this.setItemSelected = data[0].access.locations;
          }
          this.editApplicant.id = data[0].employee_id;
          this.editApplicant.employee_fullname = data[0].employee_fullname;
          this.editApplicant.employee_data[0].department =
            data[0].department_names[0].department_names_description;
          this.editApplicant.employee_data[0].job_title =
            data[0].job_title_names[0].job_title_name_description;
        } else {
          this.$toast.error(data.error);
        }
      } catch (error) {
        this.$toast.error("Error: " + error);
      }
      this.loadSearch = false;
    },
  },
};
</script>
