<template>
  <v-container>
    <v-row class="pt-2" justify="center">
      <v-col v-show="show_weeks" cols="12" sm="2" md="2" class="pt-0 pb-0">
        <v-autocomplete
          dense
          outlined
          v-model="rangeSelected"
          :items="rangeTime"
          label="Rango"
          color="cyanText"
          prepend-inner-icon="mdi-calendar"
          @change="updateRange(rangeSelected)"
          required
        ></v-autocomplete>
      </v-col>
      <v-col
        v-show="rangeSelected == 7"
        cols="12"
        sm="2"
        md="2"
        class="pt-0 pb-0"
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="10"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="dateFormatDMY"
              label="Desde"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        v-show="rangeSelected == 7"
        cols="12"
        sm="2"
        md="2"
        class="pt-0 pb-0"
      >
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="date2FormatDMY"
              label="Hasta"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
        <v-autocomplete
          dense
          outlined
          v-model="business_selected"
          :items="itemsBussines"
          label="Unidad de negocio"
          color="cyanText"
          @change="
            (itemsLocations2 = updateCombo(
              itemsLocations,
              itemsLocations2,
              business_selected,
              'location'
            )),
              updateAccounBanks(),
              updateData()
          "
          prepend-inner-icon="corporate_fare"
          required
        ></v-autocomplete>
      </v-col>
      <v-col v-show="show_location" cols="12" sm="2" md="2" class="pt-0 pb-0">
        <v-autocomplete
          dense
          outlined
          v-model="location_selected"
          :items="itemsLocations2"
          @change="updateData()"
          return-object
          color="cyanText"
          label="Location"
          prepend-inner-icon="my_location"
          required
        ></v-autocomplete>
      </v-col>
      <v-col v-show="show_department" cols="12" sm="2" md="2" class="pt-0 pb-0">
        <v-autocomplete
          dense
          outlined
          v-model="$store.state.app.filters.department_id"
          :items="itemsDepartment"
          color="cyanText"
          label="Departamento"
          prepend-inner-icon="business"
          @change="updateData()"
          required
        ></v-autocomplete>
      </v-col>
      <v-col v-show="show_accounts" cols="12" sm="3" md="3" class="pt-0 pb-0">
        <v-autocomplete
          dense
          outlined
          v-model="filters.accountBank"
          :items="bussinessAccount"
          item-text="text"
          item-value="account_id"
          color="cyanText"
          label="Cuenta de banco"
          prepend-inner-icon="account_balance"
          @change="updateData()"
          required
        ></v-autocomplete>
      </v-col>
      <v-col v-show="show_payments" cols="12" sm="3" md="3" class="pt-0 pb-0">
        <v-autocomplete
          dense
          outlined
          v-model="filters.payments_id"
          :items="payments"
          label="Metodo de pago"
          color="cyanText"
          prepend-inner-icon="attach_money"
          item-text="name"
          item-value="id"
          required
          @change="updateData()"
        >
          <template #item="{ item }">
            <v-icon class="mx-1">{{ getIconPayments(item.id) }}</v-icon>
            {{ item.name }}
          </template></v-autocomplete
        >
      </v-col>
      <v-col v-show="show_category" cols="12" sm="3" md="2" class="pt-0 pb-0">
        <v-autocomplete
          dense
          outlined
          v-model="filters.category"
          :items="categories.filter((element) => element.parent_id === null)"
          label="Categoría"
          color="cyanText"
          item-text="name"
          item-value="id"
          required
          @change="updateData()"
        >
         </v-autocomplete
        >
      </v-col>
      <v-col v-show="show_category" cols="12" sm="3" md="2" class="pt-0 pb-0">
        <v-autocomplete
          dense
          outlined
          v-model="filters.sub_category"
   :items="categories.filter(
                    (element) =>
                      element.parent_id === filters.category
                  )
                    " 
          label="Sub categoría"
          color="cyanText"
      
          item-text="name"
          item-value="id"
          required
          @change="updateData()"
        >
          </v-autocomplete
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
//import ViewEntryDiary from "../../components/Accounting/ViewEntryDiary";
export default {
  data: () => ({
    showEntry: false,
    date: moment().startOf("month").format("YYYY-MM-DD"),
    date2: moment().endOf("month").format("YYYY-MM-DD"),
    menu: false,
    menu2: false,
    selectedCategoryDgii: "",
    categoryDgii: [],
    seletecName: "",
    dialog: false,
    payments: [],

    items: [],
    itemsBussines: [],
    bussinessAccount: [],
    itemsLocations: [],
    itemsLocations2: [],
    itemsDepartment: [],
    categories:[],
    itemSaved: [],
    ategoryDgii: [],
    newItem: [],
    itemsRoutes: [],
    editedItem: [],
    dataDiary: [],

    catalogue_id: null,
    editedIndex: -1,
    filters: {
      business: null,
      location: null,
      department_id: null,
      accountBank: null,
      payments_id: null,
      category:null,
      sub_category:null,
      dateStart: moment().startOf("month").format("YYYY-MM-DD"),
      dateEnd: moment().endOf("month").format("YYYY-MM-DD"),
    },
    editedIndex: -1,
    dialog: false,
    dialogDelete: false,

    rangeSelected: "",
    rangeTime: [
      { text: "Hoy", value: "1" },
      { text: "Este semana", value: "2" },
      { text: "Siguiente semana", value: "3" },
      { text: "Este mes", value: "4" },
      { text: "Ultimos 3 meses", value: "5" },
      { text: "Este año", value: "6" },
      { text: "Personalizado", value: "7" },
    ],
    selectedItem: [],
  }),
  props: {
    show_department: {
      type: Boolean,
      default: true,
    },
    show_location: {
      type: Boolean,
      default: true,
    },
    show_weeks: {
      type: Boolean,
      default: true,
    },
    show_accounts: {
      type: Boolean,
      default: false,
    },
    show_payments: {
      type: Boolean,
      default: false,
    },
    selected_week: {
      type: Number,
      default: 4,
    },
    show_category:{
      type: Boolean,
      default: false,
    }
  },
  created() {
    this.getData();
  },

  computed: {
    business_selected: {
      get() {
        return this.$store.state.app.filters.business;
      },
      set(value) {
        this.$store.commit("setBusiness", value);
      },
    },
    location_selected: {
      get() {
        return this.$store.state.app.filters.location;
      },
      set(value) {
        this.$store.commit("setLocation", value);
      },
    },
    dateFormatDMY() {
      return moment(this.date).format("DD/MM/YYYY");
    },
    date2FormatDMY() {
      return moment(this.date2).format("DD/MM/YYYY");
    },
  },
  watch: {
    date(val, newVal) {
      //console.log(val + " " + newVal);
      this.filters.dateStart = val;
      if (this.rangeSelected == 7) {
        this.updateData();
      }
    },
    date2(val, newVal) {
      // console.log(val + " " + newVal);
      this.filters.dateEnd = val;
      if (this.rangeSelected == 7) {
        this.updateData();
      }
    },
  },
  methods: {
    getDate(date) {
      let newDate = moment(date).format("YYYY-MM-DD HH:MM:SS A").toString();
      return newDate;
    },
    updateRange(item) {
      if (item == 1) {
        this.date = moment().format("YYYY-MM-DD");
        this.date2 = moment().format("YYYY-MM-DD");
      }
      if (item == 2) {
        this.date = moment()
          .startOf("week")
          .add("days", 1)
          .format("YYYY-MM-DD");
        this.date2 = moment().endOf("week").add("days", 1).format("YYYY-MM-DD");
      }
      if (item == 3) {
        this.date = moment()
          .startOf("week")
          .add("days", 8)
          .format("YYYY-MM-DD");
        this.date2 = moment().endOf("week").add("days", 8).format("YYYY-MM-DD");
      }

      if (item == 4) {
        this.date = moment().startOf("month").format("YYYY-MM-DD");
        this.date2 = moment().endOf("month").format("YYYY-MM-DD");
      }

      if (item == 5) {
        // = moment().startOf("month").format("YYYY-MM-DD");
        const fechaEspecifica = moment();
        const fechaHace3Meses = fechaEspecifica.clone().subtract(3, "months");
        this.date = fechaHace3Meses.format("YYYY-MM-DD");
        this.date2 = moment().endOf("month").format("YYYY-MM-DD");
      }

      if (item == 6) {
        this.date = moment().startOf("year").format("YYYY-MM-DD");
        this.date2 = moment().endOf("year").format("YYYY-MM-DD");
      }
      this.filters.dateStart = this.date;
      this.filters.dateEnd = this.date2;

      if (this.rangeSelected != 7) {
        this.updateData();
      }
      // this.updateData();
    },
    async getBusinessAccount() {
      if (this.business_selected != 0) {
        let filter = "where ";
        this.business_selected != 0
          ? (filter += "business_unit_id=" + this.business_selected)
          : (filter += "");
        let resultpayments = await this.api_Post(
          "humanResource/bussinessAccount",
          {
            filters: filter,
          }
        );
        if (resultpayments.status == 200) {
          this.bussinessAccount = [];
          this.bussinessAccount = await resultpayments.json();
          this.bussinessAccount.forEach(function (account) {
            account.text =
              account.account_number + " (" + account.bank_name + ")";
          });
        }
      }
    },
    updateAccounBanks() {
      this.filters.accountBank = null;
      if (this.show_accounts === true) {
        this.getBusinessAccount();
      } else {
        this.bussinessAccount = [];
      }
    },
    async updateData() {
      let filtro = "   WHERE  ";
      this.filters.business != 0
        ? (filtro += "business_unit_id=" + this.filters.business + " AND ")
        : (filtro += "");
      this.filters.location != 0
        ? (filtro += "location_id=" + this.filters.location + " AND ")
        : (filtro += "");
      this.filters.department_id != 0
        ? (filtro += "department_id=" + this.filters.department_id + " AND ")
        : (filtro += "");
      filtro +=
        " CREATED_DATE::DATE BETWEEN '" +
        this.date +
        "' and '" +
        this.date2 +
        "' ";
      if (this.business_selected === null) {
        this.filters.business = 0;
      }
      if (this.location_selected === null) {
        this.filters.location = 0;
      }
      if (this.filters.department_id === null) {
        this.filters.department_id = 0;
      }
    
      this.$emit("updateSearch", filtro);
      this.filters.business=this.business_selected
      this.filters.location=this.location_selected.value
      this.$emit("dataFilters", this.filters);
    },
    async getData() {
      this.itemsBussines = Object.assign(
        [],
        this.$store.state.auth.bussines_units
      );
      let resultTypeCategories = await this.api_Get(
        "accounting/categories_diary_book"
      );
      this.categories = await resultTypeCategories.json();
  
      //this.categories.unshift({id:null,name:"Todas",parent_id:null})
      const data = await this.api_Get("income/payments_types");
      this.payments = await data.json();
      this.itemsBussines.unshift({ text: "Todas", value: 0 });
      let week = this.rangeTime.filter(
        (element) => element.value == this.selected_week
      );
      this.rangeSelected = week[0];
      this.updateRange(this.rangeSelected.value);
      this.itemsLocations = Object.assign([], this.$store.state.auth.locations);

      //this.filters.business = this.itemsBussines[0].value;
      this.itemsDepartment = await this.g_departments();
      this.itemsDepartment.unshift({ text: "Todos", value: 0 });
      //  this.filters.department_id = this.itemsDepartment[0].value;
      this.itemsLocations2.unshift({ text: "Todas", value: 0 });

      if (this.business_selected != 0) {
        this.itemsLocations2 = this.updateCombo(
          this.itemsLocations,
          this.itemsLocations2,
          this.business_selected,
          "location"
        );

        this.updateAccounBanks(), this.updateData();
      }

      //  this.filters.location = this.itemsLocations2[0].value;
      // this.updateData();
    },

    updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
      itemsDestino.splice(0, itemsDestino.length);

      for (let item of itemsOrigen) {
        if (item.bus_id === condicion) {
          itemsDestino.push({
            value: item.value,
            text: item.text,
            routes: item.routes,
          });
        }
      }
      itemsDestino.unshift({ text: "Todas", value: 0 });
      if (this.location_selected == null) {
        this.location_selected = this.itemsLocations2[0].value;
      }

      return itemsDestino;
    },
  },
};
</script>

<style></style>
