<template>
  <v-flex>
    <v-row justify="center" class="mt-n16">
      <v-icon class="mx-2" size="35px">receipt_long</v-icon>
      <span style="font-size: 34px; color: #616161">{{ title }}</span>
    </v-row>
    <v-row class="mt-0" justify="center">
      <v-col cols="12" sm="3" md="3">
        <v-autocomplete outlined dense v-model="filters.business" :items="itemsBussines" label="Unidad de negocio"
          color="cyanText" :disabled="allDisabled" @change="loadLogo" return-object prepend-inner-icon="corporate_fare"
          required></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <v-autocomplete outlined dense v-model="filters.location" :items="filters.business != null
            ? itemsLocations.filter(
              (element) => element.bus_id == filters.business.value
            )
            : []
          " return-object :disabled="allDisabled" @change="updateWarehouse" color="cyanText" label="Location"
          prepend-inner-icon="corporate_fare" required></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-autocomplete v-model="filters.warehouse_id" :disabled="allDisabled" :items="itemsWarehouse" color="cyanText"
          label="Almacen" item-value="id" item-text="name" prepend-inner-icon="business" outlined required dense>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="3" md="2">
        <v-autocomplete outlined dense v-model="filters.department_id" :disabled="allDisabled" :items="itemsDepartment"
          return-object color="cyanText" label="Departamento" prepend-inner-icon="corporate_fare"
          required></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-autocomplete v-model="filters.project" :items="projectFilter" :disabled="allDisabled" label="Proyecto"
          color="cyanText" return-object item-value="id" item-text="name" prepend-inner-icon="folder_open" outlined dense
          required>
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row no-gutters justify="center">
      <!-- <v-card class="folded_corner" style="" width="90%" min-height="700px"> -->
      <div class="folded_corner">
        <v-row class="mt-3" justify="center">
          <v-col cols="12" md="3">
            <v-row justify="center">
              <v-img contain width="100px" height="100px" :src="locationImage"></v-img>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row justify="center">
              <span v-if="filters.business != null" style="font-size: 20px; color: #616161">{{ filters.business.text
              }}</span>
            </v-row>

            <v-row justify="center">
              <span v-if="filters.location != null" style="font-size: 14px; color: #616161">{{ filters.location.text
              }}</span>
            </v-row>
            <v-row justify="center">
              <span style="font-size: 14px; color: #616161">
                <!-- gro@gmail.com -->
              </span>
            </v-row>
            <v-row justify="center">
              <span style="font-size: 14px; color: #616161">
                <!-- 829-449-6091 -->
              </span>
            </v-row>
          </v-col>
          <v-col cols="12" md="3"> </v-col>
        </v-row>
        <v-row>
          <v-divider class="mr-10 mt-2 mb-4" inset></v-divider>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-row no-gutters justify="center">
              <v-col cols="12" md="7">
                <v-text-field :value="selectedEntity != null ? selectedEntity.name : ''" dense outlined readonly
                  label="Suplidor">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <searchClients v-if="!allDisabled"  @close="dialogSearch = false" :types_clients="1" @selected-item="selectItem($event)" />
              </v-col>
            </v-row>
            <v-row class="mt-n4" no-gutters justify="center">
              <v-col cols="12" md="10">
                <v-text-field :value="selectedEntity != null ? selectedEntity.document_id : ''
                  " dense outlined readonly label="RNC o Cedula">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n4" no-gutters justify="center">
              <v-col cols="12" md="10">
                <v-text-field :value="selectedEntity != null ? selectedEntity.phone_number : ''
                  " dense outlined readonly label="Telefono">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-4" no-gutters justify="center">
              <v-col cols="12" md="10">
                <v-text-field v-if="is_reception" v-model="invoice_supplier" dense outlined label="Factura suplidor">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row justify="end"> </v-row>
          </v-col>
        </v-row>
        <v-card-text>
          <v-container>
            <v-data-table :headers="headers" :items="items" dense :search="searchProduct" hide-default-footer
              :items-per-page="items.length">
              <template v-slot:[`item.id`]="{ item }">
                <span style="font-size: 0.8vw">{{ item.id }}</span>
              </template>
              <template v-slot:[`item.item_name`]="{ item }">
                <span style="font-size: 0.8vw">{{ item.name }}</span>
              </template>
              <template v-slot:[`item.reference`]="{ item }">
                <v-text-field :readonly="is_reception" hide-details style="font-size: 0.8vw" v-model="item.reference"
                  dense>
                </v-text-field>
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                <v-text-field :readonly="is_reception" style="font-size: 0.8vw" prefix="$" v-model.number="item.amount"
                  dense hide-details>
                </v-text-field>
              </template>
              <template v-slot:[`item.account`]="{ item }">
                <v-select :readonly="is_reception" style="font-size: 0.8vw" v-model="item.item_chart_of_account_id"
                  :items="accounts" item-text="name" item-value="id" label="itbis" return-object hide-details
                  single-line></v-select>
              </template>
              <template v-slot:[`item.discount`]="{ item }">
                <v-text-field :readonly="is_reception" style="font-size: 0.8vw" prefix="%" v-model="item.discount" dense
                  hide-details>
                </v-text-field>
              </template>
              <template v-slot:[`item.itbis`]="{ item }">
                <v-select :readonly="is_reception" style="font-size: 0.8vw" v-model="item.itbis" :items="itbis"
                  item-text="name" item-value="id" label="itbis" return-object hide-details single-line></v-select>
              </template>
              <template v-slot:[`item.quantity`]="{ item }">
                <v-text-field :readonly="is_reception" style="font-size: 0.8vw" v-model="item.quantity" type="number"
                  :min="1" :value="1" dense hide-details>
                </v-text-field>
              </template>
              <template v-slot:[`item.quantityReceipt`]="{ item }">
                <v-text-field style="font-size: 0.8vw" v-model="item.quantityReceipt" type="number" :min="0"
                  :max="item.quantity - item.entry_quantity" dense hide-details>
                </v-text-field>
              </template>
              <template v-slot:[`item.total`]="{ item }">
                <span style="font-size: 0.8vw">{{
                  currencyFormat(item.quantity * item.amount)
                }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-if="is_reception == false" @click="deleteProducts(item)" color="red" class="mx-2 mt-n2"
                      v-bind="attrs" v-on="on">
                      close
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
              <template v-slot:footer>
                <v-row justify="start">
                  <v-btn v-if="!is_reception" small @click="showItems" color="primary" class="mx-8 mt-4 mb-10" outlined>
                    <v-icon>add</v-icon>Agregar item
                  </v-btn>
                </v-row>

                <v-col v-if="selected_withholdings != []" cols="12" md="8">
                  <!-- <v-row class="mt-5" v-for="(item, i) of selected_withholdings" :key="i"
                                          justify="start" no-gutters>
                                          <span style="font-size: 20px; color: grey" class="mt-1">Retencion -
                                          </span>
                                          <v-col cols="12" md="4">
                                              <v-text-field class="mx-2" dense outlined :value="item.name" readonly>
                                              </v-text-field>
                                          </v-col>
                                          <v-col cols="12" md="2">
                                              <v-text-field class="mx-2" v-model="item.percent" type="number" outlined
                                                  readonly dense>
                                              </v-text-field>
                                          </v-col>
                                          <v-col cols="12" md="1">
                                              <v-row no-gutters>
                                                  <v-icon @click="delete_withholdings(item)" class="mt-2"
                                                      color="black">close</v-icon>
                                              </v-row>
                                          </v-col>
                                      </v-row> -->
                </v-col>

                <v-row class="mt-6" justify="start">
                  <v-col cols="12" md="5">
                    <v-textarea v-model="nota" class="pa-2" outlined label="Nota"></v-textarea>
                  </v-col>
                </v-row>
                <v-row class="mb-4" justify="end" style="margin-right: 0.2vh; margin-top: -160px">
                  <v-card style="margin-top: 5px; margin-bottom: 20px" elevation="0" width="20%" height="auto" outlined>
                    <v-layout fill-height align-center>
                      <v-col cols="12" md="6">
                        <v-row justify="end">
                          <span style="font-size: 1vw" class="font-weight-bold">
                            Subtotal
                          </span>
                        </v-row>
                        <v-row justify="end">
                          <!-- <span style="font-size: 1vw" class="font-weight-bold">
                                                          Descuento
                                                      </span> -->
                        </v-row>
                        <v-row justify="end">
                          <!-- <span style="font-size: 1vw" class="font-weight-bold">
                                                          Itbis
                                                      </span> -->
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-row justify="end">
                          <span style="font-size: 1vw" class="font-weight-normal">{{ totales.tot_sub }}</span>
                        </v-row>
                        <v-row justify="end">
                          <!-- <span style="font-size: 1vw" class="font-weight-normal">0.00
                                                      </span> -->
                        </v-row>
                        <v-row justify="end">
                          <!-- <span style="font-size: 1vw" class="font-weight-normal">{{
                                                          totales.tot_itbis
                                                      }}
                                                      </span> -->
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="1"></v-col>
                    </v-layout>
                    <v-divider class="mt-4" style="background-color: grey"></v-divider>

                    <v-layout fill-height align-center>
                      <v-spacer></v-spacer>
                      <span style="font-size: 1.2em" class="font-weight-bold">Total</span>
                      <v-spacer></v-spacer>

                      <span style="font-size: 1.2em" class="font-weight-bold">{{
                        totales.tot_amount
                      }}</span>
                    </v-layout>
                  </v-card>
                </v-row>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-row class="mt-0 mb-7" justify="center">
          <!-- <v-btn @click="close" class="mx-3" outlined> Cancelar </v-btn> -->

          <v-btn v-if="is_reception" @click="save(3)" color="success" class="mx-2">
            Guardar recepcion <v-icon class="mx-1">warehouse</v-icon></v-btn>

          <v-btn v-if="!is_reception" @click="save(1)" color="primary" class="mx-2">
            Guardar <v-icon>save</v-icon></v-btn>
          <v-btn v-if="!is_reception" @click="save(2)" color="success">
            Guardar y enviar <v-icon>send</v-icon></v-btn>
        </v-row>
      </div>
      <!-- </v-card> -->
    </v-row>

   
    <v-dialog width="50%" v-model="dialogWithholdings">
      <v-card class="rounded-xl" width="100%" height="100%">
        <v-toolbar flat dark color="transparent">
          <span class="font-weight-semibold" style="color: black; font-size: 2vh">Agregar retencion</span>
          <v-spacer></v-spacer>
          <v-btn @click="dialogWithholdings = false" icon dark>
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-row justify="center">
            <v-col cols="6">
              <v-autocomplete outlined dense v-model="selected_withholdings_push" :items="withholdings" item-text="name"
                item-value="id" label="RETENCIONES" return-object color="cyanText" required></v-autocomplete>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-btn @click="add_withholdings" outlined>Agregar</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="filters.location != null" width="80%" v-model="dialogItems">
      <articles_catalogue v-if="refresArticles" :removeItemSelected="false" @selected-item="addItem($event)" :data="{
        location: filters.location.id,
        business: filters.business.value,
        warehouse: filters.warehouse_id,
        catalogue: filters.location.catalogue_id,
      }" @close-item="dialogItems = false" />
    </v-dialog>
  </v-flex>
</template>

<script>
import moment from "moment";
import searchClients from "../Income/SearchClientOrSupplier.vue";

import articles_catalogue from "../Inventory/ArticlesWarehouse.vue";
export default {
  components: { searchClients, articles_catalogue },
  data: () => ({
    allDisabled: false,
    date: moment().startOf("month").format("YYYY-MM-DD"),
    date2: moment().startOf("month").format("YYYY-MM-DD"),
    request_id: null,
    tab: null,
    invoice_supplier: "",
    refresArticles: true,
    selectedCreditDays: null,
    dialogWithholdings: false,
    withholdings: [],
    selected_withholdings_push: null,
    selectedCaja: null,
    tipComprobante: null,
    searchProduct: "",
    menu: false,
    menu2: false,
    loadData: false,
    loadDataRequest: false,
    dialogPayments: false,
    dialogSearch: false,
    dialogItems: false,
    selectedEntity: {},
    disableInput: false,
    personApplicant: "",
    commentText: "",
    itemRequest: [],
    ncfTypes: [],
    currencies: [],
    seleted_currencies: null,
    itemsBussines: [],
    itemsLocations: [],
    itemsLocations2: [],
    itemsDepartment: [],
    withholdings: [],
    locationImage: null,
    selected_withholdings: [],
    selected_invoice_categories: null,
    invoice_categories: [],
    itemsRoutes: [],
    editedItem: [],
    nota: "",
    items: [],
    boxes: [],
    itbis: [],
    accounts: [],
    allProjects: [],
    itemsWarehouse: [],
    credit_days: [],
    selectedPayments: [],
    catalogue_id: null,
    editedIndex: -1,
    filters: {
      business: null,
      location: null,
      department_id: null,
      warehouse_id: null,
      project: null,
    },
    listEditItem: [],
    listProducts: [],
    headerlistEditItem: [],
    order_id: null,
    request_id: null,
    quotation_id: null,
    dataLocations: [],

    commentHeader: "",
  }),
  props: {
    data_order: {
      type: Array,
      default: [],
    },
    is_reception: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    await this.getData();

    if (this.data_order.length == 0) {
      this.allDisabled = false;
    } else {
      this.filters.warehouse_id = this.data_order[0].warehouse_id;

      this.allDisabled = true;

      this.filters.business = {
        value: this.data_order[0].business_unit_id,
        text: this.data_order[0].business_unit_description_long,
      };

      this.filters.location = {
        id: this.data_order[0].location_id,
        value: this.data_order[0].location_id,
        text: this.data_order[0].location_description_long,
        catalogue_id: this.data_order[0].catalogue_id,
      };
      await this.updateWarehouse();
      this.filters.department_id = { value: this.data_order[0].department_id };

      this.selectedEntity.entity_id = this.data_order[0].entity_id;

      this.selectedEntity.name = this.data_order[0].entity_name;
      this.selectedEntity.document_id = this.data_order[0].rnc;
      this.order_id = this.data_order[0].order_id;
      this.request_id = this.data_order[0].request_id;
      this.quotation_id = this.data_order[0].quotation_id;
      this.filters.project = {
        id: this.data_order[0].project_id,
        name: this.data_order[0].project_name,
      };

      let data = [];
      for (let item of this.data_order[0].order_details) {
        data.push({
          id: item.item_id,
          name: item.item_name,
          reference: "",
          amount: item.price.toFixed(2),
          unit: item.unit_name,
          discount: "",
          itbis: 0,
          quantity: item.quantity,
          quantityReceipt:
            parseFloat(item.quantity) - parseFloat(item.entry_quantity),
          entry_quantity: item.entry_quantity,
          total: parseFloat(item.price) * parseFloat(item.quantity),
          item_chart_of_account_id: item.item_chart_of_account_id,
          warehouse_id: item.warehouse_id,
          actions: "",
        });
      }

      this.items = data;
    }
  },

  watch: {
    "items.quantityReceipt"(newValue) { },
  },

  computed: {
    selected() {
      return this.items;
      // let a =this.items.map(form => form)
      // console.log(a)
      // return  a
    },
    headers() {
      let data = [
        { text: "ID", value: "id", width: "3%", align: "start" },
        {
          text: "Producto/servicio",
          align: "start",
          sortable: false,
          value: "item_name",
          width: "20%",
        },
        {
          text: "Referencia",
          value: "reference",
          width: "12%",
          align: " d-none",
          require: false,
          active: false,
        },
        {
          text: "Unidad",
          value: "unit",
          width: "10%",
          align: "start",
          require: false,
          active: true,
        },
        {
          text: "Precio",
          value: "amount",
          width: "10%",
          align: "start",
          require: false,
          active: true,
        },

        {
          text: "Cuenta",
          value: "account",
          width: "10%",
          align: " d-none",
          require: false,
          active: false,
        },

        { text: "Cantidad", value: "quantity", width: "8%", align: "start" },

        {
          text: "Total",
          value: "total",
          width: "10%",
          align: "center",
          require: false,
          active: true,
        },

        {
          text: "Cantidad Recibida",
          value: "quantityReceipt",
          width: "10%",
          align: " d-none",
          require: false,
          active: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "3%",
          align: " d-none",
          require: false,
          active: false,
        },
      ];
      if (this.is_reception == true) {
        data[8].align = "start";
        data[8].active = true;
      } else {
        data[9].align = "start";
        data[9].active = true;
      }
      // data[2].align = 'center'
      // data[2].active = true;
      return data;
    },
    title() {
      if (this.data_order.length == 0) {
        return "Nueva orden";
      } else {
        if (this.is_reception == true) {
          return (
            "Recepcion orden #" +
            this.filLeft(this.data_order[0].order_id.toString(), 12, "0")
          );
        }
        return (
          "Editar orden #" +
          this.filLeft(this.data_order[0].order_id.toString(), 12, "0")
        );
      }
    },
    projectFilter() {
      if (this.filters.location != null) {
        let itemsProjects = this.allProjects.filter(
          (element) =>
            element.location_id == this.filters.location.id &&
            element.finished == false
        );
        itemsProjects.unshift({ name: "Todos", id: 0 });
        return itemsProjects;
      }
    },
    totales() {
      let tot_itbis = 0;
      let total_amount = 0;
      let tot_subtotal = 0;
      if (this.items != []) {
        for (let item of this.items) {
          if (item.amount > 0) {
            let tot_item = item.amount * item.quantity;
            let itbis = 0;
            tot_itbis += itbis;
            total_amount += tot_item + itbis;
            tot_subtotal += tot_item;
          }
        }
      }

      return {
        tot_itbis: this.currencyFormat(tot_itbis),
        tot_amount: this.currencyFormat(total_amount),
        tot_sub: this.currencyFormat(tot_subtotal),
        total_itbis: tot_itbis.toFixed(2),
        total_amount: total_amount.toFixed(2),
        total_sub: tot_subtotal.toFixed(2),
      };
    },
  },
  methods: {
    handleInput(e) {
      console.log(e);
    },
    async loadLogo() {
      this.items = [];
      this.refresArticles = false;
      //  console.log(this.filters.business.image_storage);
      if (this.filters.business.value != 0) {
        if (this.filters.business.image_storage != null) {
          this.locationImage = await this.get_storage(
            "logo_bussines_units",
            this.filters.business.image_storage
          );
        } else {
          this.locationImage = null;
        }
      }
      this.refresArticles = true;
    },
    async getData() {
      let locations = await this.api_Get("humanResource/locations");
      const data_locations = await locations.json();
      for (let item of data_locations) {
        // console.log(item);
        for (let item2 of this.$store.state.auth.locations) {
          // console.log(item2);
          if (item2.id == item.id) {
            this.itemsLocations.push({
              value: item.id,
              text: item.desc_loc_long,
              id: item.id,
              bus_id: item.bus_id,
              desc_bus_short: item.desc_bus_short,
              desc_bus_long: item.desc_bus_long,
              desc_loc_short: item.desc_loc_short,
              desc_loc_long: item.desc_loc_long,
              city_id: item.city_id,
              city_description: item.city_description,
              location_adress: item.location_adress,
              status_id: item.status_id,
              catalogue_id: item.catalogue_id,
              catalogue_name: item.catalogue_name,
              rnl: item.rnl,
              representative_name: item.representative_name,
              sta_description: item.sta_description,
              employee_representative_id: item.employee_representative_id,
            });
          }
        }
      }
      let resultBoxes = await this.api_Get("income/boxes");
      this.boxes = await resultBoxes.json();

      let resultWithholdings = await this.api_Get("accounting/withholdings");
      this.withholdings = await resultWithholdings.json();
      let resultCategories = await this.api_Get("income/invoice_categories");
      this.invoice_categories = await resultCategories.json();

      let resultTypesNcf = await this.api_Get("accounting/nfc_types");
      this.ncfTypes = await resultTypesNcf.json();

      let resultCreditDays = await this.api_Get("accounting/credit_days");
      this.credit_days = await resultCreditDays.json();

      let resultItbis = await this.api_Get("accounting/taxes");
      this.itbis = await resultItbis.json();
    
      const resultCurrencies = await this.api_Get("accounting/currencies");
      this.currencies = await resultCurrencies.json();
      this.itemsBussines = Object.assign(
        [],
        this.$store.state.auth.bussines_units
      );
      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      this.accounts = await resultTypeAccounts.json();
      this.itemsBussines.unshift({ text: "SELECIONA LA UNIDAD", value: "0" });
      this.filters.business = this.itemsBussines[0].value;
      //this.itemsLocations = this.$store.state.auth.locations;
      const requestProjects = await this.api_Get("project/projects");
      this.allProjects = await requestProjects.json();
      this.itemsLocations2 = this.updateCombo(
        this.itemsLocations,
        this.itemsLocations2,
        this.filters.business,
        "location"
      );
      this.itemsDepartment = await this.g_departments();
    },
    addItem(item) {
      let itbis = this.itbis.find((element) => element.id == item.tax_type_id);

      for (let it = 0; it < this.items.length; it++) {
        if (item.item_id == this.items[it].id) {
          this.items[it].quantity++;
        }
      }
      if (
        this.items.find((element) => element.id == item.item_id) === undefined
      ) {
        this.items.push({
          id: item.item_id,
          name: item.item_name,
          reference: "",
          amount: item.price,
          unit: item.unit_name,
          discount: "",
          itbis: itbis,
          quantity: 1,
          quantityReceipt: 0,
          total: item.price + item.price * 1,
          item_chart_of_account_id: item.item_chart_of_account_id,
          warehouse_id: item.warehouse_id,
          actions: "",
        });
      }
      this.$toast.success("Agregado");
    },
    showItems() {
      if (this.filters.business == null) {
        this.$toast.warning("Debes elegir una unidad de negocio!");
        return;
      }

      if (this.filters.location == null) {
        this.$toast.warning("Debes elegir una location!");
        return;
      }
      if (this.filters.location.catalogue_id == null) {
        this.$toast.warning("Este location no tiene un catalogo de articulo!");
        return;
      }

      this.dialogItems = true;
      // this.items.push({
      //   id: "",
      //   name: "",
      //   reference: "",
      //   amount: "",
      //   discount: "",
      //   itbis: "",
      //   quantity: "",
      //   total: "",
      //   actions: "",
      // });
    },
    updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
      itemsDestino.splice(0, itemsDestino.length);

      for (let item of itemsOrigen) {
        if (item.bus_id === condicion) {
          itemsDestino.push({
            value: item.value,
            text: item.text,
            routes: item.routes,
          });
        }
      }

      //this.filters.location = this.itemsLocations2[0].value;

      return itemsDestino;
    },
    showDialogPayments() {
      if (this.items.length != []) {
        if (this.totales.total_amount > 0) {
          this.dialogPayments = true;
        } else {
          this.$toast.warning("Debes completar los items!");
        }
      } else {
        this.$toast.warning("Debes agregar items para agregar el pago!");
      }
    },

    async updateWarehouse() {
      this.items = [];
      this.refresArticles = false;

      const warehouses = await this.api_Get(
        "inventory/warehouseForLocation/" + this.filters.location.value
      );
      this.itemsWarehouse = await warehouses.json();
      this.filters.warehouse_id = this.itemsWarehouse[0].id;
      this.refresArticles = true;
    },
    add_withholdings() {
      if (this.selected_withholdings_push == null) {
        this.$toast.warning("Debes selecionar una retencion");
        return;
      }
      this.selected_withholdings.push(this.selected_withholdings_push);

      this.dialogWithholdings = false;
    },
    saveReceptions() { },
    save(id) {
      let items_selected = [];
      let order_details = [];

      if (this.items != []) {
        for (let item of this.items) {
          //i++;

          if (this.filters.business == null) {
            this.$toast.warning("Debes elegir una unidad de negocio !");
            return;
          }
          if (this.filters.location == null) {
            this.$toast.warning("Debes elegir una location !");
            return;
          }
          if (item.quantity == 0) {
            this.$toast.warning(
              "la cantidad del item " + item.name + " debe ser mayor que 0"
            );
            return;
          }
          if (item.amount == 0) {
            this.$toast.warning(
              "la precio del item " + item.name + " debe ser mayor que 0"
            );
            return;
          }

          items_selected.push({
            item_id: item.id,
            item_description: item.reference,
            quantity: parseFloat(item.quantity),
            tax_id: item.itbis.id,
            price: item.amount,
            warehouse_id: item.warehouse_id,
            chart_of_account_id: item.item_chart_of_account_id,
          });

          if (item.quantityReceipt > 0) {
            order_details.push({
              item_id: item.id,
              item_description: item.reference,
              quantity: parseFloat(item.quantityReceipt),
              tax_id: item.itbis.id,
              price: item.amount,
              warehouse_id: item.warehouse_id,
              chart_of_account_id: item.item_chart_of_account_id,
            });
          }
        }
      }
      if (this.is_reception == true) {
        if (order_details.length == 0) {
          this.$toast.warning("Debes poner la cantidad minima en 1 articulos");
          return;
        }
      }

      let data = {
        type: id,
        order_id: this.order_id,
        request_id: this.request_id,
        quotation_id: this.quotation_id,
        business_unit_id: this.filters.business.value,
        department_id:
          this.filters.department_id != null
            ? this.filters.department_id.value
            : null,
        location_id: this.filters.location.value,
        created_by: this.$store.state.auth.user.user.user_id,
        entity_id: this.selectedEntity.entity_id,
        total_amount: this.totales.total_amount,
        note: this.nota,
        invoice_supplier: this.invoice_supplier,
        order_details: order_details,
        invoice_details: items_selected,
        project: this.filters.project.id,
        warehouse: this.filters.warehouse_id,
        status:
          id == 1
            ? null
            : `'${JSON.stringify([{ order_status_id: 2 }]).toString()}'`,
      };

      this.$emit("save-order", data);
    },
    selectItem(item) {
      this.selectedEntity = item;
      this.tipComprobante =
        this.selectedEntity.customer_details == null
          ? null
          : this.selectedEntity.customer_details[0].customer.nfc_type_id;
      this.selectedCreditDays =
        this.selectedEntity.customer_details == null
          ? null
          : this.selectedEntity.customer_details[0].customer.credit_days;

      this.dialogSearch = false;
    },
    selectPayment(item) {
      this.selectedPayments = item;
      this.dialogPayments = false;
    },
    deleteProducts(item) {
      let editIndex = this.items.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.items.splice(editIndex, 1);
    },
    delete_withholdings(item) {
      let editIndex = this.selected_withholdings.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.selected_withholdings.splice(editIndex, 1);
    },
    deleteItem(item) {
      let editIndex = this.selectedPayments.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.selectedPayments.splice(editIndex, 1);
    },
    close() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped>
.mobile {
  color: #333;
}

@media screen and (max-width: 768px) {
  .mobile table.v-table tr {
    max-width: 100%;
    position: relative;
    display: block;
  }

  .mobile table.v-table tr:nth-child(odd) {
    border-left: 6px solid deeppink;
  }

  .mobile table.v-table tr:nth-child(even) {
    border-left: 6px solid cyan;
  }

  .mobile table.v-table tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }

  .mobile table.v-table tr td ul li:before {
    content: attr(data-label);
    padding-right: 0.5em;
    text-align: left;
    display: block;
    color: #999;
  }

  .v-datatable__actions__select {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }

  .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }
}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-item {
  padding: 5px;
  width: 50%;
  height: 40px;
  font-weight: bold;
}

.folded-paper:before {
  content: "";
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 30px 30px 0;
  border-style: solid;
  border-color: rgb(74, 92, 67) #fff;
  transition: 0.3s;
  border-radius: 0 0 0 4px;
}

.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.folded_corner {
  position: relative;
  width: 100%;

  height: auto;
  border-radius: 5px;

  /* background: #58a; */
  background: linear-gradient(to left bottom,
      transparent 50%,
      rgba(194, 193, 193, 0.4) 0) no-repeat 100% 0 / 2em 2em,
    linear-gradient(-135deg, transparent 1.5em, rgb(255, 255, 255) 0);

  /* -webkit-filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
  /* filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
}

/* .folded_corner::before {
  content: " ";
  position: absolute;
  top: 2em;
  right: 0;
  background: linear-gradient(
      to left bottom,
      transparent 50%,
      rgba(0, 0, 0, 0.1) 0
    )
    100% 0 no-repeat;
  width: 2em;
  height: 2em;
  transform: rotate(180deg);
} */
</style>
