<template>
  <v-container>
    <v-card elevation="0">
      <v-card-text><v-row justify="start" class="mt-1 ml-5">
          <v-btn small @click="beforeRoute" color="primary" outlined><v-icon>keyboard_backspace</v-icon> Volver</v-btn>
        </v-row>
        <v-row no-gutters justify="center">
          <v-col cols="12" md="12">
            <v-container>
              <v-row no-gutters justify="start">
                <v-card-title class="align-start">
                  <span style="font-size: 3vh" class="font-weight-semibold">{{
                    data_warehouse.name
                  }}</span>
                </v-card-title>
              </v-row>
              <v-row class="mt-n8" no-gutters justify="start">
                <v-card-title class="align-start">
                  <span style="font-size: 2vh" class="font-weight-semibold">{{ data_warehouse.bussines_unit_description }}
                    ,
                    {{ data_warehouse.location_description_long }}
                  </span>
                </v-card-title>
              </v-row>
              <v-row no-gutters class="mt-n3 mx-2" justify="start">
                <v-btn-toggle class="rounded-lg mr-2" mandatory color="primary">
                  <v-btn small> Activado </v-btn>
                  <v-btn small> Desactivado </v-btn>
                </v-btn-toggle>
              </v-row>

              <v-row>
                <v-container>
                  <v-data-table :headers="headerArticle" :search="search" :items="getInventory">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Activos</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <span>{{ getInventory.length }}</span>
                        <v-spacer></v-spacer>
                        <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar"
                          single-line hide-details>
                        </v-text-field>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.item_image`]="{ item }">
                      <v-avatar size="40">
                        <v-img :src="item.item_image"></v-img>
                      </v-avatar>
                    </template>
                    <template v-slot:[`item.quantity`]="{ item }">
                      <v-chip dark :color="item.min > item.quantity ? 'red' : 'green'">{{ item.quantity }}</v-chip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editAsset(item)">
                            edit
                          </v-icon>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                    </template>
                    <!-- <template v-slot:[`item.show`]="{ item }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="primary"
                            @click="ajustar(item)"
                            large
                          >
                            swap_vertical_circle
                          </v-icon>
                        </template>
                        <span>Ajustar</span>
                      </v-tooltip>
                    </template> -->
                  </v-data-table>
                </v-container>
              </v-row>
            </v-container>
          </v-col></v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ViewArticleVue from "./ViewArticles.vue";
import moment from "moment";
export default {
  components: { ViewArticleVue },
  data: () => ({
    headers: [],
    data: [],
    search: "",
    id_warehouse: null,
    data_warehouse: [],
    articles: [],
    loading: false,
    headerArticle: [
      {
        text: "ID",
        align: "start",
        value: "item_id",
        width: "7%",
      },

      { text: "Image", value: "item_image", width: "10%" },
      { text: "Articulo", value: "item_name" },
      { text: "Tipo", value: "item_type_name" },
      { text: "Precio", value: "price" },
      { text: "Cantidad", value: "quantity" },
      { text: "", value: "show", sortable: false },
    ],
  }),

  created() {
    this.initialize();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    getInventory() {
      let articles = [];

      for (let item of this.articles) {
        let available = item.item_in_warehouses.filter(
          (element) => element.warehouse_id == this.id_warehouse
        );
        let quantity_available = 0;
        if (available.length == 0) {
          continue;
        }

        if (available.length > 0) {
          quantity_available = available[0].quantity;
        }
        articles.push({
          item_id: item.item_id,
          item_name: item.item_name,
          item_image: item.item_image,
          unit_name: item.unit_name,
          price: item.price,
          min: item.min,
          item_type_name: item.item_type_name,
          quantity: quantity_available,
        });
      }

      return articles;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      let resultData = await this.api_Get(
        "inventory/warehouse/" + this.id_warehouse
      );
      let data = await resultData.json();
      this.data_warehouse = data[0];

      this.getArticlesInWarehouse();
      this.loading = false;
    },
    async getArticlesInWarehouse() {
      const request = await this.api_Post("inventory/inventory_value", {
        date: moment().format("YYYY-MM-DD"),
        business_unit_id: this.data_warehouse.business_units_id,
        location_id: this.data_warehouse.location_id,
      });
      let items = await request.json();
      if (request.status == 200) {
        for (let item of items) {
          if (
            item.item_image_url == "" ||
            item.item_image_url == null ||
            item.item_image_url == "null" ||
            item.item_image_url == undefined
          ) {
            item.item_image = "image-not-aviable.jpg";
          } else {
            item.item_image = "image-not-aviable.jpg";
            // item.item_image = await this.get_storage(
            //   "items_images",
            //   item.item_image_url
            // );
          }
        }

        this.articles = items;
      } else {
        this.$toast.error(data.error);
      }
    },
    initialize() {
      this.id_warehouse = this.$route.params.id;
      this.loadData();
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style></style>
