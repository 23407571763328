<template>
  <v-container>
    <form action="">
      <v-row class="mt-2 mb-2" justify="center">
        <span style="font-size: 28px; color: #616161">Libro diario</span>
      </v-row>
      <SearchHeader @updateSearch="searchLoaded($event)" />
      <v-row justify="center">
        <v-col cols="12" md="12" aling="center">
          <v-data-table v-if="loading == false" :headers="headers" :items="dataDiary" sort-by="calories"
            class="elevation-1 rounded-lg">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Asientos</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.diary_book_code`]="{ item }">
              <v-hover v-slot="{ hover }">
                <span @click="showItem(item)" class="primary--text font-weight-normal text-truncate font-weight-semibold"
                  :style="{
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }">
                  {{ item.diary_book_code }}</span>
              </v-hover>
            </template>
            <template v-slot:[`item.created_date`]="{ item }">
              <span class="font-weight-normal subtitle-3">
                {{ getDate(item.created_date) }}</span>
            </template>
            <template v-slot:[`item.accounts`]="{ item }">
              <span v-for="(items, i) of item.diary_book_details" :key="i">
                {{ items.chart_of_account_code }} -
                {{ items.chart_of_account_name }} <br /></span>
            </template>

            <template v-slot:[`item.tercero`]="{ item }">
              <v-hover v-slot="{ hover }">
                <span @click="viewEntity(item.entity_id)" style="font-size: 14px"
                  class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }">{{ item.entity_name }}</span></v-hover>
            </template>
            <template v-slot:[`item.debito`]="{ item }">
              <template v-if="item.entry_type_id == 1">
                {{ item.currency_code }}
                {{ item.amount }}
              </template>
              <br />
            </template>

            <template v-slot:[`item.credito`]="{ item }">
              <template v-if="item.entry_type_id == 2">
                {{ item.currency_code }}
                {{ item.amount }}
              </template>
              <br />
            </template>
            <template slot="body.append">
              <tr class="mt-2 mb-2">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th class="font-weight-bold" style="color: #607d8b; font-size: 24px">
                  TOTAL
                </th>
                <!-- <th class="title">{{ sumField("calories") }}</th>
                              <th class="title">{{ sumField("fat") }}</th> -->
                <th style="color: #607d8b; font-size: 20px" class="font-weight-bold text-right">
                  {{ currencyFormat(debitoAndCredito.debito) }}
                </th>
                <th class="font-weight-bold text-right" style="color: #607d8b; font-size: 20px">
                  {{ currencyFormat(debitoAndCredito.credito) }}
                </th>
              </tr>
            </template>
            <!-- <template v-slot:footer>
              <v-row class="mt-n3" justify="end" style="margin-right: 0.5em">
                <v-card
                  style="margin-top: 30px; margin-bottom: 30px"
                  elevation="0"
                  width="20%"
                  height="70px"
                  outlined
                >
                  <v-layout fill-height align-center>
                    <v-col cols="12" md="6">
                      <v-row justify="center">
                        <span class="font-weight-bold"> Total Debito </span>
                      </v-row>
                      <v-row justify="center">
                        <span> RD{{ debitoAndCredito.debito }} </span>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row justify="center">
                        <span class="font-weight-bold">Total Credito </span>
                      </v-row>
                      <v-row justify="center">
                        <span> RD{{ debitoAndCredito.credito }}</span>
                      </v-row>
                    </v-col>
                  </v-layout>
                </v-card>
              </v-row>
            </template> -->
            <!-- <template v-slot:[`item.user`]="{ item }">
              <span style="font-size: 14px" class="font-weight-normal">
                {{ item.user_fullname }}
              </span>
            </template> -->

            <!-- <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template> -->
          </v-data-table>

          <v-skeleton-loader v-else v-bind="attrs"
            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
            height="500px"></v-skeleton-loader>
        </v-col>
      </v-row>
    </form>

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <v-card>
        <ViewEntryDiary :entryDiary="selectedItem" @close="dialog = false" :key="selectedItem.diary_book_id">
        </ViewEntryDiary>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import ViewEntryDiary from "../../components/Accounting/ViewEntryDiary";
import SearchHeader from "../../components/SearchHeader.vue";
export default {
  data: () => ({
    attrs: {
      class: "mb-12 mt-12",
      boilerplate: true,
      elevation: 2,
    },
    id_account: null,
    loading: false,
    showEntry: false,
    date: moment().startOf("month").format("YYYY-MM-DD"),
    date2: moment().endOf("month").format("YYYY-MM-DD"),
    menu: false,
    menu2: false,
    selectedCategoryDgii: "",
    categoryDgii: [],
    seletecName: "",
    dialog: false,
    items: [],
    itemsBussines: [],
    itemsLocations: [],
    itemsLocations2: [],
    itemsDepartment: [],
    itemSaved: [],
    ategoryDgii: [],
    newItem: [],
    itemsRoutes: [],
    editedItem: [],
    dataDiary: [],
    catalogue_id: null,
    editedIndex: -1,
    filters: {
      business: null,
      location: null,
      department_id: null,
      routes: "",
    },
    editedIndex: -1,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Asiento",
        align: "start",
        sortable: false,
        value: "diary_book_code",
      },
      { text: "Numero", value: "diary_book_number" },
      {
        text: "Unidad de negocio",
        value: "business_unit_description",
        width: "15%",
        align: "center",
      },
      { text: "Fecha", value: "created_date", width: "10%", align: "center" },
      { text: "Tercero", value: "tercero" },

      { text: "Cuenta contable", value: "chart_of_account_name" },
      { text: "Debito", value: "debito", align: "end" },
      { text: "Credito", value: "credito", align: "end" },
    ],
    rangeSelected: "",
    rangeTime: [
      { text: "Hoy", value: "1" },
      { text: "Este semana", value: "2" },
      { text: "Este mes", value: "3" },

      { text: "Este año", value: "4" },
    ],
    selectedItem: [],
  }),
  props: {
    entry: {
      type: Object,
      require: true,
    },
  },
  created() {
    this.getData();
  },
  components: { ViewEntryDiary, SearchHeader },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Crear cuenta contable "
        : "Editar cuenta contable ";
    },
    debitoAndCredito() {
      let debito = 0;
      let credito = 0;
      for (let item of this.dataDiary) {
        if (item.entry_type_id == 1) {
          debito += parseFloat(item.amount);
        }

        if (item.entry_type_id == 2) {
          credito += parseFloat(item.amount);
        }
      }

      return {
        debito: debito.toFixed(2),
        credito: credito.toFixed(2),
      };
    },
  },
  watch: {
    date(val, newVal) {
      this.searchLoaded();
    },
    date2(val, newVal) {
      this.searchLoaded();
    },
  },
  methods: {
    showItem(item) {
      if (item.diary_book_type_id == 1) {
        this.$router.push({
          name: "ViewInvoice",
          params: { id: item.invoice_number },
        });
      } else {
        this.dialog = false;
        this.showEntry = false;
        this.selectedItem = item;
        this.showEntry = true;
        this.dialog = true;
      }
    },
    getDate(date) {
      let newDate = moment(date).format("YYYY-MM-DD HH:MM:SS A").toString();
      return newDate;
    },
    updateRange(item) {
      if (item == 1) {
        this.date = moment().format("YYYY-MM-DD");
        this.date2 = moment().format("YYYY-MM-DD");
      }
      if (item == 3) {
        this.date = moment().startOf("month").format("YYYY-MM-DD");
        this.date2 = moment().endOf("month").format("YYYY-MM-DD");
      }
      if (item == 2) {
        this.date = moment().startOf("week").format("YYYY-MM-DD");
        this.date2 = moment().endOf("week").format("YYYY-MM-DD");
      }

      if (item == 4) {
        this.date = moment().startOf("year").format("YYYY-MM-DD");
        this.date2 = moment().endOf("year").format("YYYY-MM-DD");
      }

      // this.searchLoaded();
    },

    async searchLoaded(filtro) {
      this.loading = true;
      const responseRequest = await this.api_Post("accounting/diary_book", {
        filtros: filtro,
      });
      let dataDiarys = await responseRequest.json();
      this.dataDiary = dataDiarys;

      this.loading = false;
    },
    async getData() {
      this.id_account = this.$route.params.id;
      this.loading = true;
      this.itemsBussines = Object.assign(
        [],
        this.$store.state.auth.bussines_units
      );
      this.rangeSelected = this.rangeTime[2];
      //combo locations----------------------------------------------
      this.itemsLocations = this.$store.state.auth.locations;

      this.filters.business = this.itemsBussines[0].value;

      //  this.itemsBussines.unshift({ text: "Todas", value: 0 });

      this.itemsDepartment = await this.g_departments();
      this.itemsDepartment.unshift({ text: "Todos", value: 0 });
      this.filters.department_id = this.itemsDepartment[0].value;
      this.itemsLocations2.unshift({ text: "Todas", value: 0 });
      this.filters.location = this.itemsLocations2[0].value;
      let resultCategoryDgii = await this.api_Get("accounting/category_dgii");

      const dataCategoryDgii = await resultCategoryDgii.json();
      //   let resultDiary = await this.api_Get("accounting/diary_book");
      //   let dataDiarys = await resultDiary.json();
      const responseDiary = await this.api_Post("accounting/account_entry", {
        id_account: this.id_account,
      });

      let dataDiarys = await responseDiary.json();

      this.dataDiary = dataDiarys;

      for (let item of dataCategoryDgii) {
        this.categoryDgii.push({ text: item.name, value: item.id });
      }
      this.CategoryDgii = dataCategoryDgii;

      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      const dataTypeAccounts = await resultTypeAccounts.json();

      let child = [];
      for (let items of dataTypeAccounts) {
        //console.log(items.id);
        // if (this.id_account == items.id) {
        child.push({
          id: items.id,
          name: items.name,
          parent: items.parent_id,
          code: items.code,
          note: items.note,
          account_type_id: items.account_type_id,
          dgii_category_id: items.dgii_category_id,
        });
        //  }
      }

      const nest = (items, id = null, link = "parent") =>
        items
          .filter((item) => item[link] === id)
          .map((item) => ({ ...item, children: nest(items, item.id) }));

      let result = nest(child);
      //console.log(child);

      this.items = result;
      this.loading = false;
    },
    async save() {
      //  console.log(this.selectedCategoryDgii);
      if (this.editedItem.name == "") {
        this.$toast.warning("Digita el nombre de la cuenta!");
        return;
      }

      if (this.editedItem.code === ``) {
        this.$toast.warning("Digita el codigo de la cuenta!");
        return;
      }

      if (this.selectedCategoryDgii == "") {
        this.$toast.warning("Digita el codigo de la dgii!");
        return;
      }
      if (this.editedIndex > -1) {
        const responseRequest = await this.api_Post(
          "accounting/accounting_account",
          {
            chart_of_account_id: this.editedItem.id,
            account_type_id: this.editedItem.account_type_id,
            parent_id: this.editedItem.parent,
            dgii_category_id: this.selectedCategoryDgii.value,
            code: this.editedItem.code,
            name: this.editedItem.name,
            note: this.editedItem.note,
          }
        );

        if (responseRequest.status == 200) {
          this.getData();
          this.$toast.success("cuenta actualizada con exito!");
        } else {
          this.$toast.error("error al crear la cuenta!");
        }
      } else {
        const responseRequest = await this.api_Post(
          "accounting/accounting_account",
          {
            chart_of_account_id: null,
            account_type_id: this.itemSaved.account_type_id,
            parent_id: this.itemSaved.id,
            dgii_category_id: this.selectedCategoryDgii.value,
            code: this.editedItem.code,
            name: this.editedItem.name,
            note: this.editedItem.note,
          }
        );

        if (responseRequest.status == 200) {
          this.getData();
          this.$toast.success("cuenta creadad con exito!");
        } else {
          this.$toast.error("error al crear la cuenta!");
        }
      }
      this.close();
    },
    updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
      itemsDestino.splice(0, itemsDestino.length);

      for (let item of itemsOrigen) {
        if (item.bus_id === condicion) {
          itemsDestino.push({
            value: item.value,
            text: item.text,
            routes: item.routes,
          });
        }
      }
      // itemsDestino.unshift({ text: "Todas", value: 0 });
      this.filters.location = this.itemsLocations2[0].value;

      return itemsDestino;
    },
    editItem(item) {
      // console.log(item);
      this.selectedCategoryDgii = this.editedItem.dgii_category_id;
      this.editedIndex = 0;
      this.editedItem = Object.assign({}, item);
      this.seletecName = this.editedItem.name;
      this.selectedCategoryDgii;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    addAccount(item) {
      this.editedItem = [];
      this.itemSaved = [];
      this.selectedCategoryDgii = "";
      this.editedIndex = -1;
      let data = Object.assign({}, item);
      this.itemSaved = data;
      this.seletecName = data.name;
      this.editedItem.name = "";
      this.editedItem.code = "";
      this.dialog = true;
    },
  },
};
</script>

<style></style>
