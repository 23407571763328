import Entity from "@/views/Contacts/Entity.vue";
import Contacts from "@/views/Contacts/Contacts.vue";
import Clients from "@/views/Contacts/Clients.vue";
import Suppliers from "@/views/Contacts/Suppliers.vue";
import ViewEntitys from "@/components/Contacts/ViewContacts.vue";
import CatalogueSupplier from "@/components/Contacts/CatalogueSupplier.vue";
const EntityRoute=  {
    path: "/entitys",
    name: "Entity",
    component: Entity,
    meta: {
      LOGGED: true,
    },
    children: [
      {
        path: "all_contact",
        name: "Contacts",
        component: Contacts,
        meta: {
          LOGGED: true,
        },
      },
      {
        path: "clients",
        name: "Clients",
        component: Clients,
        meta: {
          LOGGED: true,
        },
      },
      {
        path: "suppliers",
        name: "Suppliers",
        component: Suppliers,
        meta: {
          LOGGED: true,
        },
      },
      {
        path: "catalogue_supplier/:id",
        name: "CatalogueSupplier",
        component: CatalogueSupplier,
        meta: {
          LOGGED: true,
        },
      },

      {
        path: "view/:id",
        name: "ViewEntitys",
        component: ViewEntitys,
        meta: {
          LOGGED: true,
        },
      },

      ,
    ],
  }

export default EntityRoute