<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-row class="mt-2" justify="center">
          <span style="font-size: 28px; color: #616161">Entradas de diario
          </span>
        </v-row>
        <v-row class="pt-2" justify="center">
          <search-header @dataFilters="searchLoaded($event)" :selected_week="4" />
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="12">
            <v-data-table v-if="loading == false" :headers="headersDataDiary" :items="dataDiary" sort-by="calories"
              class="elevation-1">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Entradas</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" fullscreen>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn outined color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                        <v-icon>add</v-icon>
                        Nueva entrada de diario
                      </v-btn>
                    </template>
                    <v-card style="background-color: #fafafa">
                      <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="closeDialog">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Nueva entrada de diario
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <!-- <v-card-title>
                    <span class="text-h5">Nueva entrada de diario</span>
                  </v-card-title> -->
                      <v-row class="mt-2" justify="center">
                        <span style="font-size: 28px; color: #616161">Nueva entradas de diario
                        </span>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="12" sm="3" md="2" class="pt-0 pb-0">

                          <v-autocomplete dense outlined v-model="filters.business" :items="itemsBussines"
                            label="Unidad de negocio" color="cyanText" @change="
                              itemsLocations2 = updateCombo(
                                itemsLocations,
                                itemsLocations2,
                                filters.business,
                                'location'
                              )
                              " prepend-inner-icon="corporate_fare" required></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
                          <v-autocomplete dense outlined v-model="filters.location" :items="itemsLocations2"
                            color="cyanText" label="Location" prepend-inner-icon="my_location" required></v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
                 
                        </v-col> -->
                      </v-row>
                      <v-row class="mt-2">
                        <v-col cols="12" md="12">
                          <v-container fluid>
                            <v-col cols="12">
                              <v-data-table :headers="headers" :items="newEntryDairy" class="elevation-0 pr-6"
                                hide-default-footer>
                                <template v-slot:top>
                                  <v-toolbar flat>
                                    <v-toolbar-title>Asientos</v-toolbar-title>
                                    <v-col class="mt-6" cols="2">
                                      <v-menu class="mt-6" v-model="menu2" :close-on-content-click="false"
                                        :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field dense outlined v-model="date" label="Fecha"
                                            prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                                            v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                    <v-col class="mt-6" cols="2">
                                      <v-autocomplete dense v-model="entity_id" :items="entity"
                                        :search-input.sync="entity_name" color="cyanText" return-object label="Entidad"
                                        outlined @keydown="getEntitys"></v-autocomplete>
                                    </v-col>
                                    <v-col class="mt-6" cols="3">
                                      <v-autocomplete dense v-model="currency_id" :items="currencies" color="cyanText"
                                        return-object label="Moneda" outlined required></v-autocomplete></v-col>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-spacer></v-spacer>
                                    <v-btn outlined @click="addLine()" color="primary">
                                      <v-icon>add</v-icon> Agregar linea
                                    </v-btn>
                                  </v-toolbar>
                                </template>

                                <template v-slot:[`item.cuentaContable`]="{ item }">
                                  <v-autocomplete dense v-model="item.cuentaContable" :items="accounts" @change="
                                    item.categoryDgii =
                                    item.cuentaContable.dgii_category_id
                                    " color="cyanText" return-object label="Cuenta contable" outlined
                                    required></v-autocomplete>
                                </template>

                                <template v-slot:[`item.categoryDgii`]="{ item }">
                                  <v-autocomplete dense v-model="item.categoryDgii" :items="categoryDgii" color="cyanText"
                                    label="Categoria Dgii" outlined required></v-autocomplete>
                                </template>
                                <template v-slot:[`item.descripcion`]="{ item }">
                                  <v-textarea dense rows="2" row-height="10" v-model="item.descripcion"
                                    label="Descripcion" outlined></v-textarea>
                                </template>

                                <template v-slot:[`item.department_id`]="{ item }">
                                  <v-autocomplete dense outlined v-model="item.department_id" :items="itemsDepartment"
                                    color="cyanText" label="Departamento" prepend-inner-icon="business"
                                    required></v-autocomplete>
                                </template>
                                <template v-slot:[`item.diary_book_category_id`]="{ item }">
                                  <v-autocomplete dense outlined v-model="item.diary_book_category_id" :items="categories.filter((element) => element.parent_id === null)
                                    " color="cyanText" label="Categoría" item-text="name" item-value="id" prepend-inner-icon="business"
                                    required></v-autocomplete>

                                </template>
                                <template v-slot:[`item.diary_book_sub_category_id`]="{ item }">
                                  <v-autocomplete dense outlined v-model="item.diary_book_sub_category_id" :items="categories.filter(
                                    (element) =>
                                      element.parent_id === item.diary_book_category_id
                                  )
                                    " color="cyanText" label="Sub-Categoría" item-text="name" item-value="id"
                                    prepend-inner-icon="business" required></v-autocomplete>
                                </template>
                                <template v-slot:[`item.debito`]="{ item }">
                                  <v-text-field dense v-model="item.debito" :disabled="item.credito != ''" label="Debito"
                                    type="number" min="0" outlined></v-text-field>
                                </template>
                                <template v-slot:[`item.credito`]="{ item }">
                                  <v-text-field dense :disabled="item.debito != ''" v-model="item.credito" type="number"
                                    label="Credito" min="0" outlined></v-text-field>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                  <v-icon @click="deleteItem(item)" color="black">close</v-icon>
                                </template>
                                <template v-slot:footer>
                                  <v-row justify="center">
                                    <v-col cols="12" md="6">
                                      <v-row justify="end">
                                        <v-textarea style="
                                            margin-top: 35px;
                                            margin-bottom: 30px;
                                          " v-model="note" rows="3" row-height="70" outlined
                                          label="Observaciónes"></v-textarea>
                                      </v-row>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                      <v-row justify="end">
                                        <v-card style="
                                            margin-right: 150px;
                                            margin-top: 30px;
                                            margin-bottom: 30px;
                                          " elevation="0" width="350px" height="100px" outlined>
                                          <v-layout fill-height align-center>
                                            <v-col cols="12" md="6" align="center">
                                              <v-row justify="center">
                                                <span> Debito </span>
                                              </v-row>
                                              <v-row justify="center">
                                                <span>
                                                  RD
                                                  {{ NewDebitoAndCredito.debito }}
                                                </span>
                                              </v-row>
                                            </v-col>
                                            <v-col cols="12" md="6" align="center">
                                              <v-row justify="center">
                                                <span> Credito </span>
                                              </v-row>
                                              <v-row justify="center">
                                                <span>
                                                  RD
                                                  {{ NewDebitoAndCredito.credito }}
                                                </span>
                                              </v-row>
                                            </v-col>
                                          </v-layout>
                                        </v-card>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </template>
                              </v-data-table>
                            </v-col>

                            <v-row style="margin-right: 175px; margin-top: 10px" align="end" justify="space-around">
                              <v-spacer></v-spacer>
                              <v-btn @click="closeDialog" style="margin-left: 50px" outlined color="primary">
                                <v-icon>close</v-icon>
                                Cancelar
                              </v-btn>
                              <v-btn style="margin-left: 50px" @click="save" color="primary">
                                <v-icon>save</v-icon>
                                Guardar
                              </v-btn>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.diary_book_code`]="{ item }">
                <v-hover v-slot="{ hover }">
                  <span @click="showItem(item)" class="primary--text font-weight-normal" :style="{
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }">
                    {{ item.diary_book_code }}</span>
                </v-hover>
              </template>
              <template v-slot:[`item.entity_name`]="{ item }">
                <v-hover v-slot="{ hover }">
                  <span @click="viewEntity(item.entity_id)" style="font-size: 14px"
                    class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                      color: hover ? 'red' : 'black',
                      cursor: hover ? 'pointer' : '',
                      'text-decoration': hover ? 'underline' : '',
                    }">{{ item.entity_name }}</span></v-hover>
              </template>
              <template v-slot:[`item.created_date`]="{ item }">
                <span> {{ getDate(item.created_date) }}</span>
              </template>
              <template v-slot:[`item.accounts`]="{ item }">
                <span v-for="(items, i) of item.diary_book_details" :key="i">
                  {{ items.chart_of_account_code }} -
                  {{ items.chart_of_account_name }} <br /></span>
              </template>
              <template v-slot:[`item.debito`]="{ item }">
                <span v-for="(items, i) of item.diary_book_details" :key="i">
                  <template v-if="items.entry_type_id == 1">
                    RD$
                    {{
                      items.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    }}
                  </template>
                  <br /></span>
              </template>
              <template v-slot:[`item.credito`]="{ item }">
                <span v-for="(items, i) of item.diary_book_details" :key="i">
                  <template v-if="items.entry_type_id == 2">
                    RD$
                    {{
                      items.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    }}
                  </template>
                  <br /></span>
              </template>
              <template v-slot:[`item.user`]="{ item }">
                <span>
                  {{ item.user_fullname }}
                </span>
              </template>
              <template slot="body.append">
                <tr class="mt-2 mb-2">
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>

                  <th class="font-weight-bold" style="color: #607d8b; font-size: 24px">
                    TOTAL
                  </th>
                  <!-- <th class="title">{{ sumField("calories") }}</th>
                              <th class="title">{{ sumField("fat") }}</th> -->
                  <th style="color: #607d8b; font-size: 20px" class="font-weight-bold text-right">
                    {{ currencyFormat(debitoAndCredito.debito) }}
                  </th>
                  <th class="font-weight-bold text-right" style="color: #607d8b; font-size: 20px">
                    {{ currencyFormat(debitoAndCredito.credito) }}
                  </th>
                </tr>
              </template>
              <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="primary" @click="viewEntry(item)">visibility</v-icon>
          </template> -->
            </v-data-table>
            <v-skeleton-loader v-else v-bind="attrs"
              type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
              height="500px"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogView" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <v-card>
        <ViewEntryDiary :entryDiary="selectedItem" @close="dialogView = false" :key="selectedItem.diary_book_id">
        </ViewEntryDiary>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import ViewEntryDiary from "../../components/Accounting/ViewEntryDiary.vue";

export default {
  data: () => ({
    attrs: {
      class: "mb-12 mt-12",
      boilerplate: true,
      elevation: 2,
    },
    loading: false,
    entity_name: "",
    entity_id: null,
    accounts: [],
    projects: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu2: false,
    selectedCategoryDgii: "",
    categoryDgii: [],
    seletecName: "",
    dialogView: false,
    items: [],
    itemsBussines: [],
    itemsLocations: [],
    itemsLocations2: [],
    itemsDepartment: [],
    note: "",
    currency_id: "",
    selectedItem: [],
    entity: [],
    currencies: [],

    selectedCurrencies: "",
    customers: [],
    itemSaved: [],
    categoryDgii: [],
    newItem: [],
    dataDiary: [],
    itemsRoutes: [],
    editedItem: [],
    catalogue_id: null,
    editedIndex: -1,
    filters: { business: "", location: null, department_id: null, routes: "" },
    editedIndex: -1,
    categories: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Cuenta contable", value: "cuentaContable" },
      { text: "Categoria dgii", value: "categoryDgii", width: "12%" },
      { text: "Descripcion", value: "descripcion" },
      { text: "Departamento", value: "department_id", width: "12%" },
      { text: "Categoría", value: "diary_book_category_id", width: "12%" },
      {
        text: "Sub Categoría",
        value: "diary_book_sub_category_id",
        width: "12%",
      },
      { text: "Debito", value: "debito", align: "end" },
      { text: "Credito", value: "credito", align: "end" },
      { text: "Actions", value: "actions", width: "5%" },
    ],
    headersDataDiary: [
      {
        text: "Asiento",
        align: "start",
        sortable: false,
        value: "diary_book_code",
        width: "14%",
      },
      {
        text: "Unidad de negocio",
        value: "business_unit_description",
        width: "15%",
      },
      { text: "Fecha", value: "created_date", width: "10%" },
      { text: "Entidad", value: "entity_name" },

      { text: "Cuenta contable", value: "accounts" },

      { text: "Debito", value: "debito", align: "end" },
      { text: "Credito", value: "credito", align: "end" },
    ],
    newEntryDairy: [],
  }),

  created() {
    this.getData();
  },
  components: { ViewEntryDiary },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Crear cuenta contable "
        : "Editar cuenta contable ";
    },
    debitoAndCredito() {
      let debito = 0;
      let credito = 0;
      for (let item of this.dataDiary) {
        if (item.diary_book_details != null) {
          item.diary_book_details.forEach((element) => {
            if (element.entry_type_id == 1) {
              debito += parseFloat(element.amount);
            }
          });

          item.diary_book_details.forEach((element) => {
            if (element.entry_type_id == 2) {
              credito += parseFloat(element.amount);
            }
          });
        }
      }

      return {
        debito: debito.toFixed(2),
        credito: credito.toFixed(2),
      };
    },
    NewDebitoAndCredito() {
      let debito = 0;
      let credito = 0;
      this.newEntryDairy.forEach((element) => {
        if (element.debito > 0) {
          debito += parseFloat(element.debito);
        }


      });
      this.newEntryDairy.forEach((element) => {
        if (element.credito > 0) {
          credito += parseFloat(element.credito);
        }
      });
      return {
        debito: debito.toFixed(2),
        credito: credito.toFixed(2),
      };
    }
  },
  watch: {

    // dialog(val) {
    //   val || this.close();
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
  },
  methods: {
    getDate(date) {
      let newDate = moment(date).format("YYYY-MM-DD HH:MM:SS A").toString();
      return newDate;
    },
    loadCategory(item) {
      return item.dgii_category_id;
    },
    async searchLoaded(item) {


      this.loading = true;

      let filters = 'AND DIARY_BOOK_TYPE_ID = 2  '
      filters += item.business != 0 ? "and business_unit_id in(" + item.business + ")" : ''
      filters += item.location != 0 ? " and location_id in (" + item.location + ")" : ''
      filters += item.dateStart != 0 ? " and effective_date>= " + `'${item.dateStart}'` : ''
      filters += item.dateEnd != 0 ? " and effective_date<= " + `'${item.dateEnd}'` : ''

      const responseRequest = await this.api_Post("accounting/diary_book", {
        filtros: filters,
      });
      let dataDiarys = await responseRequest.json();
      this.dataDiary = dataDiarys;

      this.loading = false;
    },
    async getEntitys() {

      this.entity = []
      // let resultentity = await this.api_Get("accounting/entity");
      // for (let item of await resultentity.json()) {
      //   this.entity.push({ text: item.name, value: item.id });
      // }
      let typ = ''

      const responseRequest = await this.api_Post('accounting/search_entity', {
        name: this.entity_name,
        type: typ
      })

      if (responseRequest.ok) {
        let data = await responseRequest.json()
        for (let item of data) {
          this.entity.push({ text: item.name, value: item.entity_id });
        }
      }
    },
    async getData() {
      this.loading = true;
      this.itemsBussines = Object.assign(
        [],
        this.$store.state.auth.bussines_units
      );
      this.itemsBussines.unshift({ text: "Todas", value: 0 });
      this.itemsLocations = Object.assign([], this.$store.state.auth.locations);
      console.log(this.$store.state.app.filters.business)
      this.filters.business = this.$store.state.app.filters.business
      this.itemsLocations2 = this.updateCombo(
        this.itemsLocations,
        this.itemsLocations2,
        this.filters.business,
        "location"
      );
      let resultTypeCategories = await this.api_Get(
        "accounting/categories_diary_book"
      );
      this.categories = await resultTypeCategories.json();
      this.itemsLocations2.unshift({ text: "Todas", value: 0 });
      this.filters.location = this.$store.state.app.filters.location

      // let resultentity = await this.api_Get("accounting/entity");
      // for (let item of await resultentity.json()) {
      //   this.entity.push({ text: item.name, value: item.id });
      // }
      let resultCurrencies = await this.api_Get("accounting/currencies");

      for (let item of await resultCurrencies.json()) {
        this.currencies.push({ text: item.description, value: item.id });
      }
   
      this.itemsDepartment = await this.g_departments();
      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      const dataTypeAccounts = await resultTypeAccounts.json();
      for (let item of dataTypeAccounts) {
        this.accounts.push({
          text: item.name,
          value: item.id,
          dgii_category_id: item.dgii_category_id,
        });
      }
      let resultCategoryDgii = await this.api_Get("accounting/category_dgii");
      const dataCategoryDgii = await resultCategoryDgii.json();
      for (let item of dataCategoryDgii) {
        this.categoryDgii.push({ text: item.name, value: item.id });
      }
      this.CategoryDgii = dataCategoryDgii;
      //this.searchLoaded("Where", 1);

      this.newEntryDairy.push({
        cuentaContable: "",
        categoryDgii: "",
        descripcion: "",
        diary_book_sub_category_id: null,
        diary_book_category_id: null,
        debito: "",
        credito: "",
      });
      this.newEntryDairy.push({
        cuentaContable: "",
        categoryDgii: "",
        descripcion: "",
        diary_book_sub_category_id: null,
        diary_book_category_id: null,
        debito: "",
        credito: "",
      });
      this.loading = false;
    },
    showItem(item) {
      this.dialogView = false;

      this.selectedItem = item;
      console.log(this.selectedItem)
      let data = []
      for (let item of this.selectedItem.diary_book_details) {

        data.push({
          amount: item.amount,
          chart_of_account_code: item.chart_of_account_code,
          chart_of_account_id: item.chart_of_account_id,
          chart_of_account_name: item.chart_of_account_name,
          credito: item.entry_type_id == 1 ? '' : item.amount,
          debito: item.entry_type_id == 2 ? '' : item.amount,
          dgii_categorie_name: item.dgii_categorie_name,
          dgii_category_id: item.dgii_category_id,
          diary_book_code: item.diary_book_code,
          diary_book_id: item.diary_book_id,
          entry_type_id: item.entry_type_id,
          entry_type_name: item.entry_type_name,
          department_id: item.department_id,
          diary_book_project_id: item.diary_book_project_id,
          note: item.note,
        })
      }

      this.selectedItem.diary_book_details = data
      this.dialogView = true;
    },
    addLine() {

      this.newEntryDairy.push({
        cuentaContable: "",
        categoryDgii: "",
        descripcion: "",
        diary_book_sub_category_id: null,
        diary_book_category_id: null,
        debito: "",
        credito: "",
      });
    },
    async save() {
      let json = [];
      if (this.filters.business == 0) {
        this.$toast.info("Debes elegir una unidad de negocio");
        return;
      }
      if (this.entity_id == null) {
        this.$toast.info("Debes elegir un entidad");
        return;
      }

      for (let item of this.newEntryDairy) {
        let type = 0;
        let amount = 0;
        if (item.debito == "") {
          type = 2;
          amount = item.credito;
        } else if (item.credito == "") {
          type = 1;
          amount = item.debito;
        }

        if (item.cuentaContable == "") {
          this.$toast.info("Debes elegir la cuentas contables");
          return;
        }
        let categories = []
        let sub_categories = []
        if (item.diary_book_category_id != null) {
          categories.push({ category_id: item.diary_book_category_id })
        }
        if (item.diary_book_sub_category_id != null) {
          sub_categories.push({ category_id: item.diary_book_sub_category_id })
        }
        // categories.push({category_id:item.diary_book_category_id})
        // sub_categories.push({category_id:item.diary_book_sub_category_id})
        json.push({
          chart_of_account_id: item.cuentaContable.value,
          dgii_category_id: item.categoryDgii,
          department_id: item.department_id,
          diary_book_project_id: item.diary_book_project_id,
          categories: categories,
          subcategories: sub_categories,
          entry_type_id: type,
          amount: amount,
          note: item.descripcion,
        });
      }
      console.log(this.filters.location)

      if (this.debitoAndCredito.debito === this.debitoAndCredito.credito) {
        const responseRequest = await this.api_Post("accounting/diary_entry", {
          diary_book_id: null,
          user_id: this.$store.state.auth.user.user.user_id,
          effective_date: this.date,
          bussiness_units: this.filters.business,

          location: this.filters.location == 0 ? null : this.filters.location,

          note: this.note,
          currency_id: this.currency_id.value,
          entity_id: this.entity_id.value,
          details: json,
        });
        if (responseRequest.status == 200) {
          this.getData();
          this.closeDialog();
          this.$toast.success("entrada agregada con exito!");
        } else {
          this.$toast.error("error al agregar la cuenta!");
        }
      } else {
        this.$toast.info("El debito debe ser igual al credito");
        return;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.newEntryDairy = [];
    },
    updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
      itemsDestino.splice(0, itemsDestino.length);

      for (let item of itemsOrigen) {
        if (item.bus_id === condicion) {
          itemsDestino.push({
            value: item.value,
            text: item.text,
            routes: item.routes,
          });
        }
      }
      itemsDestino.unshift({ text: "Todas", value: 0 });
      this.filters.location = this.itemsLocations2[0].value;

      return itemsDestino;
    },
    deleteItem(item) {
      this.editedIndex = this.newEntryDairy.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.newEntryDairy.splice(this.editedIndex, 1);
    },

    editItem(item) {
      // console.log(item);
      this.selectedCategoryDgii = this.editedItem.dgii_category_id;
      this.editedIndex = 0;
      this.editedItem = Object.assign({}, item);

      this.seletecName = this.editedItem.name;
      this.selectedCategoryDgii;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    addAccount(item) {
      this.editedItem = [];
      this.itemSaved = [];
      this.selectedCategoryDgii = "";
      this.editedIndex = -1;
      let data = Object.assign({}, item);
      this.itemSaved = data;
      this.seletecName = data.name;
      this.editedItem.name = "";
      this.editedItem.code = "";
      this.dialog = true;
    },
  },
};
</script>

<style></style>
