
import * as helper from "@/helpers/helpers";
const state = {
  user: {},
  authStatus: false,
  token: null,
  acess_locations: [],
  acess_business_untis: [],
  bussines_units: [],
  locations: [],
  warehouse: [],

};

const getters = {
  getToken: state => state.token,
  user: (state) => state.user,
  isAuth: (state) => !!state.token,
  authStatus: (state) => state.authStatus,
};
const actions = {
  async login({ dispatch }, userData) {
    let dataLogin = [];
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
      };
      const url =process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL_PRODUCTION:process.env.VUE_APP_API_URL_DEV
   
      const response = await fetch(
        url + "auth/signIn",
        requestOptions
      );

      let dataBussinesUnit = [];
      let dataLocations = [];

      if (response.status == 200) {
        dataLogin = await response.json();
      
        console.log(dataLogin)
        //get all bussines unit
        let bussines_unit = await helper.api_Get("humanResource/bussines_unit");
        const data_bussines_unit = await bussines_unit.json();


        let locations = await helper.api_Get("humanResource/locations");
        const data_locations = await locations.json();

       
        for (let business of data_bussines_unit) {
          for (let access_business of dataLogin.acess_business_unit_finance) {
            if (access_business == business.id) {
              dataBussinesUnit.push({
                text: business.description_long,
                value: business.id,
                id: business.id,
                company_id: business.company_id,
                description_company: business.description_company,
                description_short: business.description_short,
                rnc: business.rnc,
                rnl: business.rnl,
                description_long: business.description_long,
                status_id: business.status_id,
                currency_id:business.currency_id,
                currency_description:business.currency_description,
                currency_code:business.currency_code,
                business_unit_accounts: business.business_unit_accounts,
                catalogue_id: business.catalogue_id,
                image_storage: business.image_storage,
              });
            }
          }
        }
        console.log(data_locations)
        for (let item of data_locations) {
        
          for (let item2 of dataLogin.acess_locations) {
            if (item2 == item.id) {
              
              dataLocations.push({
                value: item.id,
                text: item.desc_loc_long,
                id: item.id,
                bus_id: item.bus_id,
                desc_bus_short: item.desc_bus_short,
                desc_bus_long: item.desc_bus_long,
                desc_loc_short: item.desc_loc_short,
                desc_loc_long: item.desc_loc_long,
                city_id: item.city_id,
                city_description: item.city_description,
                location_adress: item.location_adress,
                status_id: item.status_id,
                catalogue_id: item.catalogue_id,
                catalogue_name: item.catalogue_name,
                rnl: item.rnl,
                representative_name: item.representative_name,
                sta_description: item.sta_description,
                employee_representative_id: item.employee_representative_id,
              });
            }
          }
        }

        const warehouses = await helper.api_Get("inventory/allWarehouses");
        let dataWarehouse = await warehouses.json();

        let dataNew = {
          data: dataLogin,
          dataBussines: dataBussinesUnit,
          dataLocations: dataLocations,
          warehouse: dataWarehouse,
        };

        await dispatch("setAuthUserData", dataNew);
        return true
        // this.$router.push({ name: "Home" });
      } else {
        return false

      }

    } catch (err) {
      console.log(err)
      return false

    }
  },

  async setAuthUserData({ commit }, payload) {

    commit("SET_TOKEN", payload.data.token);
    commit("LOGIN_USER", {
      user: payload.data,
      bussines_units: payload.dataBussines,
      locations: payload.dataLocations,
      warehouse: payload.warehouse,
    });
  },
  logOut({ commit }) {
    commit("LOGOUT_USER");
  },
};
const mutations = {
  LOGIN_USER(state, payload) {

    state.user = payload.user;
    state.authStatus = true;
    state.bussines_units = payload.bussines_units;
    state.locations = payload.locations;
    state.warehouse = payload.warehouse;
    state.acess_business_untis = payload.user.acess_business_untis
    state.acess_locations = payload.user.acess_locations
    state.type_access = payload.type_access


    // router.push({ name: "Home" });
    // location.reload();


  },
  SET_TOKEN(state, payload) {
    localStorage.setItem("token", payload);

    state.token = payload;
  },
  LOGOUT_USER(state) {

    state.user = {},
      state.authStatus = false
    state.access = [];
    state.token = null;
    state.acess_business_untis = []
    state.acess_locations = []
    state.location = [];
    state.bussines_units = [];
    state.warehouse = [];
    localStorage.setItem("token", null)
    location.reload();
  },
};


export default {
  state,
  getters,
  actions,
  mutations,

  namespaced: true,
};
