<template>
  <v-app id="inspire" class="myFont">
    <!-- <v-app style="background-color: #f9fafd" id="inspire"> -->

    <v-navigation-drawer v-if="loggedIn.authStatus" :hide-overlay-scroll="true" :mini-variant-width="mini ? 65 : 120" app
      permanent @click="mini = false" :expand-on-hover="mini" :mini-variant.sync="mini">
      <div class="">
        <v-img transition="scroll-x-transition" :max-height="64" src="@/assets/imgbar2.jpg"> </v-img>
        <!-- <Logo/> -->
      </div>
      <div>

        <perfect-scrollbar class="scrollnavbar">

          <v-divider></v-divider>
          <template>
            <v-list-item :to="{
              name: 'Home',
            }">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-home-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Inicio</v-list-item-title>
            </v-list-item>


          </template>
          <v-list v-if="request_access(3).isActive" nav dense>

            <v-list-group active-class="deep-primary--text text--accent-4 ">
              <template v-slot:activator>
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-archive-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Inventario</v-list-item-title>
              </template>

              <v-list nav dense>
                <v-list-group active-class="primary--text text--accent-5 ">
                  <template v-slot:activator>
                    <v-icon class="mx-3 mr-1 ml-1">widgets</v-icon>
                    <v-list-item-title title="Gestion de producto">Gestion de productos</v-list-item-title>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'Articles',
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'" small>local_mall</v-icon>
                      <v-list-item-subtitle>Productos y servicios</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                  <template>
                    <template>
                      <v-list-item :to="{
                        name: 'ArticleConversions',
                      }" :class="mini ? '' : 'mx-1'">
                        <v-icon :class="mini ? '' : 'mx-3'" small>transform</v-icon>
                        <v-list-item-subtitle>Conversiones</v-list-item-subtitle>
                      </v-list-item>
                    </template>
                    <v-list-item :to="{
                      name: 'InventoryManagement',
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'" small>rule</v-icon>
                      <v-list-item-subtitle>Inventario</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'Adjustments',
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'" small>autorenew</v-icon>
                      <v-list-item-subtitle>Ajustes</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'TransferRequest',
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'" small>sync_alt</v-icon>
                      <v-list-item-subtitle>Transferencias</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'Approvals',
                      params: {
                        type: '1',
                      },
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'" small>task_alt</v-icon>
                      <v-list-item-subtitle>Aprobaciones</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'TransferReceivedt',
                      params: {
                        type: '2',
                      },
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'" small>archive</v-icon>
                      <v-list-item-subtitle>Recepcion</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                </v-list-group>
              </v-list>
              <v-list-item :to="{
                name: 'Assets',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">loyalty</v-icon>

                <v-list-item-subtitle>Activos fijos</v-list-item-subtitle>
              </v-list-item>
              <!-- 
          <v-list-item :to="{
            name: 'InventoryManagement',
          }">
            <v-icon  :class="mini ? '' : 'mx-5'">inventory</v-icon>
            <v-list-item-subtitle>Valor del inventario</v-list-item-subtitle>
          </v-list-item> -->

              <!-- <v-list-item :to="{
            name: 'Adjustments',
          }">
            <v-icon  :class="mini ? '' : 'mx-5'">settings</v-icon>
            <v-list-item-subtitle>Ajuste del inventario</v-list-item-subtitle>
          </v-list-item> -->
              <v-list-item :to="{
                name: 'Warehouse',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">store</v-icon>

                <v-list-item-subtitle>Almacenes</v-list-item-subtitle>
              </v-list-item>

              <v-list-item :to="{
                name: 'Category',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">category</v-icon>

                <v-list-item-subtitle>Categorias</v-list-item-subtitle>
              </v-list-item>
              <v-list-item :to="{
                name: 'Catalogue',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">view_list</v-icon>

                <v-list-item-subtitle>Catalogos</v-list-item-subtitle>
              </v-list-item>
              <v-list-item :to="{
                name: 'Menu',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">restaurant_menu</v-icon>

                <v-list-item-subtitle>Menu</v-list-item-subtitle>
              </v-list-item>
              <v-list-item :to="{
                name: 'Variant',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">checklist_rtl</v-icon>

                <v-list-item-subtitle>Variantes</v-list-item-subtitle>
              </v-list-item>
            </v-list-group>
          </v-list>

          <v-list v-if="request_access(2).isActive" nav dense>
            <v-list-group active-class="deep-primary--text text--accent-4">
              <template v-slot:activator>
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-hand-coin-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Ingresos</v-list-item-title>
              </template>

              <v-list-item :to="{
                name: 'InvoiceSales',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">receipt</v-icon>

                <v-list-item-subtitle>Factura de venta</v-list-item-subtitle>
              </v-list-item>
              <v-list-item :to="{
                name: 'CreditNotes',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">receipt</v-icon>

                <v-list-item-subtitle>Notas de credito</v-list-item-subtitle>
              </v-list-item>
              <!-- <v-list-item>
            <v-icon  :class="mini ? '' : 'mx-5'">receipt</v-icon>
            <v-list-item-subtitle>Factura recurrentes</v-list-item-subtitle>
          </v-list-item> -->
              <!-- <v-list-item
            :to="{
              name: 'PaymentsReceived',
            }"
          >
            <v-icon :class="mini ? '' : 'mx-5'">payments</v-icon>

            <v-list-item-subtitle>Pagos recibido</v-list-item-subtitle>
          </v-list-item>
          
          <v-list-item>
            <v-icon :class="mini ? '' : 'mx-5'">receipt</v-icon>

            <v-list-item-subtitle>Nota de credito</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-icon :class="mini ? '' : 'mx-5'">receipt</v-icon>

            <v-list-item-subtitle>Cotizaciones</v-list-item-subtitle>
          </v-list-item> -->
              <!-- <v-list-item :to="{ name: 'Boxes' }">
            <v-icon :class="mini ? '' : 'mx-5'">shopping_cart</v-icon>
            <v-list-item-subtitle>Cajas</v-list-item-subtitle>
          </v-list-item> -->
              <v-list-item :to="{ name: 'Prepaid' }">
                <v-icon :class="mini ? '' : 'mx-5'">paid</v-icon>
                <v-list-item-subtitle>Anticipo</v-list-item-subtitle>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-list v-if="request_access(5).isActive" nav dense>
            <v-list-group active-class="deep-primary--text text--accent-4">
              <template v-slot:activator>
                <v-list-item-icon class="mr-2">
                  <v-icon>add_card</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Cuenta por cobrar</v-list-item-title>
              </template>
              <v-list-item :to="{
                name: 'PaymentsReceived',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">attach_money</v-icon>

                <v-list-item-subtitle>Cobros</v-list-item-subtitle>
              </v-list-item>
              <v-list-item :to="{
                name: 'PaymentsReceived',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">account_balance_wallet</v-icon>

                <v-list-item-subtitle>Deposito directo</v-list-item-subtitle>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-list v-show="request_access(1).isActive" nav dense>

 
            <v-list-group active-class="deep-primary--text text--accent-4">
              <template v-slot:activator>
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-cart-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Compras</v-list-item-title>
              </template>
             
              <v-list-item v-if="(request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===17|| (request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===19"  :to="{
                name: 'PurchaseRequest',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">add_shopping_cart</v-icon>
                <v-list-item-subtitle>Solicitudes de compra</v-list-item-subtitle>
              </v-list-item>

              <v-list-item v-if="(request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===17|| (request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===20"  :to="{
                name: 'Quotes',
              }">
                <v-icon class="mx-5">edit_note</v-icon>
                <v-list-item-subtitle>Cotizar</v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="(request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===17|| (request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===20"  :to="{
                name: 'QuotesGroup',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">list</v-icon>

                <v-list-item-subtitle>Cotizaciones</v-list-item-subtitle>
              </v-list-item>

              <v-list-item v-if="(request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===17|| (request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===20"  :to="{
                name: 'PurchaseOrder',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">shopping_bag</v-icon>
                <v-list-item-subtitle>Ordenes de compra</v-list-item-subtitle>
              </v-list-item>

              <v-list-item v-if="(request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===17|| (request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===21 || (request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===22"  :to="{
                name: 'Approved',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">task_alt</v-icon>
                <v-list-item-subtitle>Aprobaciones compra</v-list-item-subtitle>
              </v-list-item>

              <v-list-item
              v-if="(request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===17|| (request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===23" 
              :to="{
                name: 'Receptions',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">archive</v-icon>
                <v-list-item-subtitle>Recepcion ordenes</v-list-item-subtitle>
              </v-list-item>

              <v-list-item v-if="(request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===17|| (request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===24"  :to="{
                name: 'DebitNotes',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">receipt</v-icon>

                <v-list-item-subtitle>Notas de debito</v-list-item-subtitle>
              </v-list-item>

              <v-list-item v-if="(request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===17|| (request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===24"  :to="{
                name: 'InvoiceShop',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">receipt</v-icon>

                <v-list-item-subtitle>Factura de provedores</v-list-item-subtitle>
              </v-list-item>
              <v-list v-if="(request_access(1).data.length>0? request_access(1).data[0].access.acces_profile:0)===17"  nav dense>
                <v-list-group active-class="deep-primary--text text--accent-4">
                  <template v-slot:activator>
                    <v-icon :class="mini ? '' : 'mx-3'">shopping_cart</v-icon>
                    <v-list-item-title>Mantenimientos</v-list-item-title>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'Applicants',
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'">assignment_ind</v-icon>
                      <v-list-item-subtitle>Solicitantes</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'Approvers',
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'">group</v-icon>
                      <v-list-item-subtitle>Aprobadores</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                </v-list-group>
              </v-list>
            </v-list-group>
          </v-list>
          <v-list v-if="request_access(6).isActive" nav dense>
            <v-list-group active-class="deep-primary--text text--accent-4">
              <template v-slot:activator>
                <v-list-item-icon class="mr-2">
                  <v-icon>add_card</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Cuenta por pagar</v-list-item-title>
              </template>
              <v-list-item :to="{
                name: 'PaymentsCxp',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">add_shopping_cart</v-icon>

                <v-list-item-subtitle>Pagos</v-list-item-subtitle>
              </v-list-item>
              <v-list-item :to="{
                name: 'TransactionsCxP',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">sync_alt</v-icon>

                <v-list-item-subtitle>Transaciones</v-list-item-subtitle>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-list v-if="request_access(8).isActive" nav dense>
            <v-list-group active-class="deep-primary--text text--accent-4">
              <template v-slot:activator>
                <v-list-item-icon class="mr-2">
                  <v-icon>warehouse</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Restaurant</v-list-item-title>
              </template>
              <v-list-item :to="{
                name: 'TipCategories',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">category</v-icon>

                <v-list-item-subtitle>Tipo categorias</v-list-item-subtitle>
              </v-list-item>
           
              <v-list-item :to="{
                name: 'CenterProduction',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">production_quantity_limits</v-icon>

                <v-list-item-subtitle>Centros producion</v-list-item-subtitle>
              </v-list-item>
              <v-list-item :to="{
                name: 'PlaceTables',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">table_chart</v-icon>

                <v-list-item-subtitle>Areas y mesas</v-list-item-subtitle>
              </v-list-item>

            </v-list-group>
          </v-list>
          <v-list nav dense>
            <v-list-group active-class="deep-primary--text text--accent-4 ">
              <template v-slot:activator>
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-account-group-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Contactos</v-list-item-title>
              </template>

              <v-list-item :to="{
                name: 'Contacts',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">groups</v-icon>

                <v-list-item-subtitle>Todos</v-list-item-subtitle>
              </v-list-item>

              <template>
                <v-list-item :to="{
                  name: 'Clients',
                }">
                  <v-icon :class="mini ? '' : 'mx-5'">supervisor_account</v-icon>
                  <v-list-item-subtitle>Clientes</v-list-item-subtitle>
                </v-list-item>
              </template>

              <template>
                <v-list-item :to="{
                  name: 'Suppliers',
                }">
                  <v-icon :class="mini ? '' : 'mx-5'">engineering</v-icon>
                  <v-list-item-subtitle>Suplidores</v-list-item-subtitle>
                </v-list-item>
              </template>
            </v-list-group>
          </v-list>
          <v-list v-if="request_access(4).isActive" nav dense>
            <v-list-group active-class="deep-primary--text text--accent-4">
              <template v-slot:activator>
                <v-list-item-icon :class="mini ? 'mr-2' : 'mr-2'">
                  <v-icon>mdi-calculator-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Contabilidad</v-list-item-title>
              </template>

              <v-list-item :to="{
                name: 'Accounts',
              }">
                <v-icon :class="mini ? '' : 'mx-5'">account_balance_wallet</v-icon>

                <v-list-item-subtitle>Catálogo de cuentas</v-list-item-subtitle>
              </v-list-item>
              <template>
                <v-list-item :to="{
                  name: 'EntryDaily',
                }">
                  <v-icon :class="mini ? '' : 'mx-5'">history_edu</v-icon>
                  <v-list-item-subtitle>Entradas de diario</v-list-item-subtitle>
                </v-list-item>
              </template>
              <template>
                <v-list-item :to="{
                  name: 'DiaryBook',
                }">
                  <v-icon :class="mini ? '' : 'mx-5'">menu_book</v-icon>
                  <v-list-item-subtitle>Libro diario</v-list-item-subtitle>
                </v-list-item>
              </template>
              <template>
                <v-list-item :to="{
                  name: 'CategoryAccounts',
                }">
                  <v-icon :class="mini ? '' : 'mx-5'">category</v-icon>
                  <v-list-item-subtitle>Categoria de cuentas</v-list-item-subtitle>
                </v-list-item>
              </template>
              <v-list nav dense>
                <v-list-group active-class="deep-primary--text text--accent-4">
                  <template v-slot:activator>
                    <v-icon :class="mini ? '' : 'mx-3'">mdi-calculator-variant-outline</v-icon>
                    <v-list-item-title>Mantenimientos</v-list-item-title>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'VouchersVue',
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'">menu</v-icon>
                      <v-list-item-subtitle> Comprobantes</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'Withholdings',
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'">front_hand</v-icon>
                      <v-list-item-subtitle>Retenciones</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'Discounts',
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'">local_atm</v-icon>
                      <v-list-item-subtitle>Descuentos</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'Itbis',
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'">volunteer_activism</v-icon>
                      <v-list-item-subtitle>Itbis</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'CategoriesDGII',
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'">category</v-icon>
                      <v-list-item-subtitle>Categorias DGII</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                  <template>
                    <v-list-item :to="{
                      name: 'ProjectAccounting',
                    }" :class="mini ? '' : 'mx-1'">
                      <v-icon :class="mini ? '' : 'mx-3'">home</v-icon>
                      <v-list-item-subtitle>Categorias</v-list-item-subtitle>
                    </v-list-item>
                  </template>
                </v-list-group>
              </v-list>
            </v-list-group>
          </v-list>
          <v-list v-if="$store.state.auth.user.user.permition_level == 99">
            <v-list-group active-class="deep-primary--text text--accent-4">
              <template v-slot:activator>
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Mantenimientos</v-list-item-title>
              </template>
              <template>
                <v-list-item :to="{
                  name: 'User',
                }" :class="mini ? '' : 'mx-1'">
                  <v-icon :class="mini ? '' : 'mx-3'">people</v-icon>
                  <v-list-item-subtitle> Usuarios</v-list-item-subtitle>
                </v-list-item>
              </template>
              <template>
                <v-list-item :to="{
                  name: 'BussinesUnit',
                }" :class="mini ? '' : 'mx-1'">
                  <v-icon :class="mini ? '' : 'mx-3'">corporate_fare</v-icon>
                  <v-list-item-subtitle> Unidad de negocios</v-list-item-subtitle>
                </v-list-item>
              </template>

              <template>
                <v-list-item :to="{
                  name: 'GroupMoney',
                }" :class="mini ? '' : 'mx-1'">
                  <v-icon :class="mini ? '' : 'mx-3'">monetization_on</v-icon>
                  <v-list-item-subtitle> Grupo de moneda</v-list-item-subtitle>
                </v-list-item>
              </template>
              <template>
                <v-list-item :to="{
                  name: 'ChangeMoney',
                }" :class="mini ? '' : 'mx-1'">
                  <v-icon :class="mini ? '' : 'mx-3'">currency_exchange</v-icon>
                  <v-list-item-subtitle> Cambio de moneda</v-list-item-subtitle>
                </v-list-item>
              </template>
              <!-- <template>
                <v-list-item :to="{
                  name: 'Budget',
                }" :class="mini ? '' : 'mx-1'">
                  <v-icon :class="mini ? '' : 'mx-3'">money</v-icon>
                  <v-list-item-subtitle>Presupuesto</v-list-item-subtitle>
                </v-list-item>
              </template> -->
              <template>
                <v-list-item :to="{
                  name: 'Projects',
                }" :class="mini ? '' : 'mx-1'">
                  <v-list-item-icon class="mr-2">
                    <v-icon :class="mini ? '' : 'mx-3'">mdi-home-city-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Proyectos</v-list-item-title>
                </v-list-item>
              </template>
              <template>
                <v-list-item :to="{
                  name: 'Boxes',
                }" :class="mini ? '' : 'mx-1'">
                  <v-list-item-icon class="mr-2">
                    <v-icon :class="mini ? '' : 'mx-3'">point_of_sale</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Cajas</v-list-item-title>
                </v-list-item>
              </template>
              <template>
                <v-list-item :to="{
                  name: 'Calendar',
                }" :class="mini ? '' : 'mx-1'">
                  <v-list-item-icon class="mr-2">
                    <v-icon :class="mini ? '' : 'mx-3'">edit_calendar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Calendario</v-list-item-title>
                </v-list-item>
              </template>



            </v-list-group>
          </v-list>

          <template>
            <v-list-item :to="{
              name: 'UserPerfil',
            }">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-smart-card-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Mi perfil</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item @click="salir()">
            <v-list-item-icon>
              <v-icon> logout </v-icon>
            </v-list-item-icon>

            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>

        </perfect-scrollbar>
      </div>
    </v-navigation-drawer>

    <v-app-bar color="primary" flat v-if="loggedIn.authStatus" class="d-print-none" app>
      <v-app-bar-nav-icon class="mx-2" @click="mini = !mini">
        <v-icon color="white ">view_headline</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>
        <span style="color: white"> BILL BOSS</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col md="2">
        <!-- <v-text-field label="Buscar ..." 
            class="pt-5"
            filled  prepend-inner-icon="search" 
            dense solo flat background-color="indigo lighten-1" rounded ></v-text-field> -->
      </v-col>

      <span v-if="loggedIn" style="color: white">
        <span class="mx-2" style="color: white">
          Hola, {{ $store.state.auth.user.user.fullname.split(" ")[0] }}
        </span>
      </span>

      <v-avatar title="Ver perfil" size="45" class="mx-2" :key="$vuetify.theme.themes.light.colorbarra">
        <v-img :key="$vuetify.theme.themes.light.colorbarra" :src="image_profile"></v-img>
      </v-avatar>
    </v-app-bar>


    <v-main :style="loggedIn.authStatus == true ? 'background-color: #f6f6f6' : ''">
      <!-- <div class="app-content-container boxed-container pa-6"> -->

      <v-slide-y-transition mode="out-in">

        <v-container fluid class="page-wrapper">
          <div :class="loggedIn.authStatus == false ? '' : 'maxWidth'">
            <router-view />

          </div>

        </v-container>
      </v-slide-y-transition>
      <!-- </div> -->
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import NavDrawerVue from "./components/NavDrawer.vue";
import Logo from "@/assets/Logo/Logo.vue"
import * as helper from "@/helpers/helpers";
//import { a } from "./helpers/helpers";
export default {
  name: "App",

  components: {
    NavBar,
    NavDrawerVue,
    Logo
  },

  data() {
    return {
      mini: true,
      open: ["Users"],
      image_profile: "",
      admins: [
        ["Management", "account-multiple-outline"],
        ["Settings", "cog-outline"],
      ],
      request: [["Solicitud", "edit_note", "Request"]],
      orders: [["Orden", "shop", "Request"]],
      items: [
        { title: "Home", icon: "home-city" },
        { title: "My Account", icon: "account" },
        { title: "Users", icon: "account-group-outline" },
      ],
      rail: true,
      drawer: false,
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    loggedIn() {
      //  console.log(this.$store.state)

      return this.$store.state.auth;
    },
    store() {
      return this.$store;
    },
  },
  methods: {
    async initialize() {
      let locations = await helper.api_Get("humanResource/locations");
      console.log(await locations.json())
      if (this.$store.state.auth.authStatus == true) {
        if (this.$store.state.auth.user.user.image_storage != null) {
          this.image_profile = await this.get_storage(
            "image_users",
            this.$store.state.auth.user.user.image_storage
          );

        }

      }

    },
    request_access(type) {
      //  return false
      if (this.$store.state.auth.authStatus == true) {
        var data = []

        if (this.$store.state.auth.user.type_access != null) {
          data = this.$store.state.auth.user.type_access.filter(
            (element) => element.access.type_access == type && element.access.status == true
          );

        }

        if (data.length != 0) {

          return { isActive: true, data: data };
        } else {
          return { isActive: false, data: data };
        }
      }
    },
    salir() {
      this.$store.dispatch("auth/logOut");
    },
    getImageUrl() {
      return new URL(`./assets/imgbar2.jpg`, import.meta.url).href;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  min-height: calc(100vh - 100px);
  padding: 24px;
  padding-bottom: 30px;

  @media screen and (max-width:600px) {
    padding: 10px;
  }
}

.maxWidth {
  max-width: 1400px;
  margin: 0 auto;
}

.scrollnavbar {
  height: calc(100vh - 80px);

  .userbottom {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }

  .smallCap {
    padding: 3px 12px !important;
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: 24px;
    color: rgb(var(--v-theme-textPrimary));

    &:first-child {
      margin-top: 0 !important;
    }
  }

  /*General Menu css*/
  .v-list-group__items .v-list-item,
  .v-list-item {
    border-radius: 5;
    // padding-inline-start: calc(12px + var(--indent-padding) / 10) !important;

    margin: 0 0 2px;

    &:hover {
      color: rgb(var(--v-theme-primary));
    }

    .v-list-item__prepend {
      margin-inline-end: 13px;
    }

    .v-list-item__append {
      font-size: 0.875rem;

      .v-icon {
        margin-inline-start: 13px;
      }
    }

    .v-list-item-title {
      font-size: 0.875rem;
    }
  }

  .v-list {
    color: rgb(var(--v-theme-textSecondary));

    >.v-list-item.v-list-item--active,
    .v-list-item--active>.v-list-item__overlay {
      background: rgb(var(--v-theme-primary));
      color: white !important;
    }

    >.v-list-group {
      position: relative;

      >.v-list-item--active,
      >.v-list-item--active:hover {
        background: rgb(var(--v-theme-primary));
        color: white;
      }

      .v-list-group__items .v-list-item.v-list-item--active,
      .v-list-group__items .v-list-item.v-list-item--active>.v-list-item__overlay {
        background: transparent;
        color: rgb(var(--v-theme-primary));
      }
    }
  }
}

.v-navigation-drawer__content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.v-navigation-drawer--mini-variant,
.v-navigation-drawer {
  overflow: visible !important;

}

.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.myFont {
  font-family: "Roboto", sans-serif;
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

// tr:nth-child(even) {
//   background-color: #bed4f08c;
// }
</style>