<template>
  <v-dialog v-model="showDialog" max-width="45%" persistent>
    <v-card>
      <v-card-title>
        <v-icon>settings</v-icon>
        <span class="headline">Ajustes</span>
        <v-spacer></v-spacer>
        <v-menu bottom left>
          <template v-slot:activator="{ }">
            <v-btn icon @click="close()">
              <v-icon>clear</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="3" v-for="item in header_table" :key="item.value" class="pb-0 pt-0">
              <v-checkbox :label="item.text" :disabled="item.require" v-model="item.active"
                @change="valueCheck(item)"></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({}),
  name: "TableSettings",
  props: {
    header_table: {
      type: Array,
      default() {
        return [];
      },
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  created() { },
  computed: {},
  watch: {},
  methods: {
    valueCheck(value) {
      let index = this.header_table.indexOf(value);
      this.header_table[index].align =
        value.active == true ? "center" : " d-none";
    },
    close() {
      this.$emit("exit", false);
    },
  },
  components: {},
};
</script>
