<template>
  <v-container fluid>
    <v-layout align-start>
      <v-flex>
        <v-card flat>
          <v-row no-gutters justify="center">
            <span style="font-size: 34px; color: #616161"><v-icon large
                class="pr-1 pb-2">transform</v-icon>Conversiones</span>
          </v-row>
          <v-row justify="center" class="mt-n1 px-6 mb-2">
            <span style="font-size: 16px; color: #757575">Convierte un articulo en otro
            </span>
          </v-row>
          <v-card-text>
            <!-- LISTA DE CONVERSIONES -->
            <v-card outlined class="mt-2 pt-0">
              <v-card-text class="pa-0">
                <v-data-table :headers="headerConversions" :search="search" :items="conversions" locale="es-do"
                  no-data-text="No se encontraron conversiones en esta unidad">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Conversiones</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <span>{{ conversions.length }}</span>
                      <v-spacer></v-spacer>
                      <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar"
                        single-line hide-details>
                      </v-text-field>
                      <v-spacer></v-spacer>
                      <v-col cols="12" md="3">
                        <v-autocomplete v-model="business_selected" :items="$store.state.auth.bussines_units"
                          label="Unidad de negocio" color="cyanText" prepend-inner-icon="corporate_fare" outlined dense
                          return-object required hide-details @change="getConversions(), updateItems()"></v-autocomplete>
                      </v-col>
                      <v-divider class="mx-3" inset vertical></v-divider>
                      <v-btn color="success" outlined @click="clearConversion">
                        <v-icon>add</v-icon>
                        Nueva conversion
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.show`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="blue" @click="showConversion(item)">
                          read_more
                        </v-icon>
                      </template>
                      <span>Editar conversion</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <!-- EDICION DE CONVERSIONES -->
            <v-dialog v-model="dialogConversions" max-width="55%">
              <v-card>
                <v-card-title>
                  <span class="headline">{{
                    is_edit == true ? "Editar " : "Nueva "
                  }}
                    conversion</span>
                  <v-spacer></v-spacer>
                  <v-menu bottom left>
                    <template v-slot:activator="{}">
                      <v-btn icon @click="dialogConversions = false">
                        <v-icon>clear</v-icon>
                      </v-btn>
                    </template>
                  </v-menu>
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters justify="center">
                    <span style="font-size: 18px; color: #616161">Articulo origen</span>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" md="3">
                      <v-autocomplete v-model="newConversion.item_from" :items="items" color="cyanText" label="Articulo"
                        outlined dense hide-details item-value="item_id" item-text="item_name" return-object
                        :disabled="is_edit">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-autocomplete v-model="newConversion.unit_id_from" :items="units" :disabled="is_edit"
                        item-value="id" item-text="name" color="cyanText" label="Unidad de medida" outlined required
                        dense>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="newConversion.loss_percentage" outlined dense hide-details
                        label="Porcentaje de perdida" type="number" :min="0"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="my-3"></v-divider>
                  <v-row no-gutters justify="center">
                    <v-icon large>arrow_downward</v-icon>
                  </v-row>
                  <v-row no-gutters justify="center">
                    <span style="font-size: 18px; color: #616161">Articulo destino</span>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" md="3">
                      <v-autocomplete v-model="newConversion.item_id_to" :disabled="is_edit" :items="items.filter(
                        (element) =>
                          element.item_id != newConversion.item_from
                      )
                        " return-object color="cyanText" label="Articulo" outlined dense hide-details
                        item-value="item_id" item-text="item_name">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-autocomplete v-model="newConversion.unit_id_to" :items="units" :disabled="is_edit"
                        item-value="id" item-text="name" color="cyanText" label="Unidad de medida" outlined required
                        dense>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="newConversion.quantity_to" outlined dense hide-details label="Cantidad"
                        type="number" :min="1"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="my-3"></v-divider>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" outlined @click="save">
                    <v-icon> save </v-icon>
                    {{ is_edit == true ? "Actualizar" : "Guardar" }}
                  </v-btn>
                  <v-btn color="error" outlined @click="dialogConversions = false">
                    <v-icon> cancel </v-icon>
                    Cancelar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    is_edit: true,
    dialogFilters: false,
    dialogConversions: false,
    conversions: [],
    items: [],
    units: [],
    newConversion: {
      item_from: {
        item_id: null,
        name: "",
      },
      unit_id_from: null,
      quantity_from: 1,
      item_id_to: {
        item_id: null,
        name: "",
      },
      unit_id_to: null,
      quantity_to: 0,
      loss_percentage: 0,
    },
    bussines_unit: null,
    search: "",
    headerConversions: [
      { text: "ID from", value: "item_from_id" },
      { text: "Del articulo", value: "item_from_name" },
      { text: "Unidad", value: "unit_from_name" },
      { text: "Cantidad", value: "item_cantity_from" },
      { text: "ID to", value: "item_to_id" },
      { text: "Al articulo", value: "item_to_name" },
      { text: "Unidad", value: "unit_to_name" },
      { text: "Cantidad", value: "item_cantity_to" },
      { text: "Editar", value: "show", sortable: false },
    ],
  }),
  components: {},
  created() { },
  mounted() {
    //this.bussines_unit = this.$store.state.auth.bussines_units[0];
    this.initialized();
  },
  computed: {
    business_selected: {
      get() {
        return this.$store.state.app.filters.business;
      },
      set(value) {
        this.$store.commit("setBusiness", value);
      },
    },
    location_selected: {
      get() {
        return this.$store.state.app.filters.location;
      },
      set(value) {
        this.$store.commit("setLocation", value);
      },
    },
  },
  watch: {},
  methods: {
    async initialized() {
      this.getConversions();
      //----------------UNIDADES--------------------
      const resultUnits = await this.api_Get("inventory/units");
      this.units = await resultUnits.json();
      //---------------------------------------------
      this.updateItems();
    },
    async updateItems() {
      const request = await this.api_Post("inventory/catalog_items", {
        business_unit_id: this.business_selected,
      });
      this.items = await request.json();
    },
    async getConversions() {
      const request = await this.api_Get(
        "inventory/conversions/" + this.business_selected
      );
      this.conversions = await request.json();
    },
    showConversion(conversion) {
      this.newConversion = {
        item_from: {
          item_id: conversion.item_from_id,
          name: "",
        },
        unit_id_from: conversion.unit_from_id,
        quantity_from: 1,
        item_id_to: {
          item_id: conversion.item_to_id,
          name: "",
        },
        unit_id_to: conversion.unit_to_id,
        quantity_to: conversion.item_cantity_to,
        loss_percentage: conversion.loss_percentage,
      };
      this.is_edit = true;
      this.dialogConversions = true;
    },
    clearConversion() {
      this.newConversion = {
        item_from: {
          item_id: null,
          name: "",
        },
        unit_id_from: null,
        quantity_from: 1,
        item_id_to: {
          item_id: null,
          name: "",
        },
        unit_id_to: null,
        quantity_to: 0,
        loss_percentage: 0,
      };
      this.is_edit = false;
      this.dialogConversions = true;
    },
    async save() {
      if (this.newConversion.item_from.item_id == null) {
        this.$toast.warning("Debe indicar el articulo de origen!");
        return;
      }
      if (this.newConversion.unit_id_from == null) {
        this.$toast.warning(
          "Indique la unidad de medida del articulo de origen!"
        );
        return;
      }
      if (this.newConversion.item_id_to.item_id == null) {
        this.$toast.warning("Debe indicar el articulo de destino!");
        return;
      }
      if (this.newConversion.unit_id_to == null) {
        this.$toast.warning(
          "Indique la unidad de medida del articulo de destino!"
        );
        return;
      }
      if (
        this.newConversion.quantity_to == null ||
        this.newConversion.quantity_to <= 0
      ) {
        this.$toast.warning("Debe indicar una cantidad valida!");
        return;
      }
      if (
        this.newConversion.loss_percentage == null ||
        this.newConversion.loss_percentage < 0
      ) {
        this.$toast.warning("Debe indicar un porcentaje de perdida valido!");
        return;
      }
      let saveType = "save_conversion_item";
      if (this.is_edit == true) {
        saveType = "update_conversion_item";
      }
      const request = await this.api_Post("inventory/" + saveType, {
        item_from_id: this.newConversion.item_from.item_id,
        unit_from_id: this.newConversion.unit_id_from,
        item_cantity_from: this.newConversion.quantity_from,
        item_to_id: this.newConversion.item_id_to.item_id,
        unit_to_id: this.newConversion.unit_id_to,
        item_cantity_to: this.newConversion.quantity_to,
        loss_percentage: this.newConversion.loss_percentage,
        business_unit_id: this.business_selected,
      });
      if (request.status != 200) {
        const data = await request.json();
        this.$toast.error(data.error);
        return;
      }
      this.$toast.success(
        (this.is_edit == true ? "Editado" : "Registrado") + " correctamente!"
      );
      this.getConversions();
      this.dialogConversions = false;
    },
  },
};
</script>
