<template>
  <v-flex>
    <v-row class="mt-2" justify="center">
      <v-col cols="12" md="12">
        <v-container>
          <v-row justify="center">
            <span class="mx-4" style="font-size: 34px; color: #616161">letiantes</span>
          </v-row>
          <v-row justify="center" class="mt-n1">
            <span class="mx-4" style="font-size: 16px; color: #757575">Crea y gestiona las letiables para identificar un
              producto que
              tiene características diferentes
            </span>
          </v-row>
          <v-row justify="center">
            <v-col cols="12">
              <v-data-table :headers="headers" :items="data" sort-by="calories" class="elevation-1">
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>letaiantes</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="700px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                          <v-icon> add </v-icon>
                          Nueva letiante
                        </v-btn>
                      </template>
                      <v-card height="auto">
                        <v-card-title class="text-center">
                          <span class="text-h5 text-center">{{
                            formTitle
                          }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row justify="center">
                              <v-col cols="12" md="4">
                                <v-text-field v-model="editedItem.name" label="Nombre" outlined dense></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-row no-gutters>
                                  <div v-for="(item, i) of itemsletiant" :key="i">
                                    <v-text-field v-on:keyup.enter="addOpcion" v-model="item.opcion" label="Opcion"
                                      outlined dense append-outer-icon="close"
                                      @click:append-outer="deleteOpcion(item)"></v-text-field>
                                  </div>
                                </v-row>
                                <v-container>
                                  <v-row>
                                    <span>Presione ENTER para agregar opciones
                                    </span>
                                  </v-row>
                                  <v-row>
                                    <v-btn text @click="addOpcion" small><v-icon>add</v-icon> Nueva opcion</v-btn>
                                  </v-row>
                                </v-container>
                              </v-col>
                              <!-- <v-col cols="12" md="1">
                              
                              </v-col> -->
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions class="m">
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="close">
                            Cancelar
                          </v-btn>
                          <v-btn outlined color="blue darken-1" @click="save">
                            Guardar
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="600px">
                      <v-card>
                        <v-card-title class="text-h5">Estas seguro que desea eliminar esta
                          letiante?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.options`]="{ item }">
                  <span v-if="item.letiants_details != []">
                    <span v-for="(items, i) of item.letiants_details" :key="i">
                      {{ items.name }} /
                    </span>
                  </span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
                <template v-slot:no-data>
                  <v-row justify="center" class="pt-10">
                    <v-icon size="80px">token</v-icon>
                  </v-row>
                  <v-row justify="center" class="pb-6"> No hay datos </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col></v-row>
  </v-flex>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    itemsletiant: [],
    headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Opciones", value: "options" },

      { text: "Actions", value: "actions", sortable: false },
    ],
    data: [],
    editedIndex: -1,
    editedItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva letiante" : "Editar letiante";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    addOpcion() {
      this.itemsletiant.push({ opcion: "" });
    },
    async initialize() {
      this.itemsletiant.push({ opcion: "" });
      let resultletiants = await this.api_Get("inventory/letiants");

      this.data = await resultletiants.json();
    },

    editItem(item) {
      this.itemsletiant = [];
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      let i = 0;
      for (let item of this.editedItem.letiants_details) {
        this.itemsletiant.push({ id: i, opcion: item.name });
        i++;
      }
      //  console.log(this.editedItem);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteOpcion(item) {
      if (this.itemsletiant.length == 1) {
        return;
      }
      let index = this.itemsletiant.indexOf(item);

      this.itemsletiant.splice(index, 1);
    },
    deleteItemConfirm() {
      this.data.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      let letiants = [];
      for (let item of this.itemsletiant) {
        letiants.push({ letiant_attribute_name: item.opcion });
      }

      if (this.editedIndex > -1) {
        let data = {
          id: this.editedItem.id,
          name: this.editedItem.name,
          letiants: letiants,
        };
      } else {
        let data = {
          id: null,
          name: this.editedItem.name,
          letiants: letiants,
        };
      }
      const responseRequest = await this.api_Post("inventory/letiants", data);

      if (responseRequest.status == 200) {
        this.initialize();

        // this.$toast.success("agregado con exito!");
        this.dialog = false;
      } else {
        this.$toast.error("error al agregar!");
      }

      this.close();
    },
  },
};
</script>
