<template>
  <v-card class="rounded-xl" width="100%" height="100%">
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <v-data-table
            id="tabla"
            :headers="headers"
            :items="articles"
            item-key="item_id"
            :search="search"
            :items-per-page="9"
            height="65vh"
            fixed-footer
            fixed-header
          >
            <template v-slot:top>
              <v-toolbar flat id="toolbar">
                <v-toolbar-title>Items</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  class="text-xs-center"
                  v-model="search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                >
                </v-text-field>
                <v-spacer></v-spacer>
                <v-icon @click="$emit('close-item')">close</v-icon>
              </v-toolbar>
            </template>
            <template v-slot:[`item.item_image`]="{ item }">
              <v-avatar size="40">
                <v-img :src="item.item_image"></v-img>
              </v-avatar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    large
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="selectItem(item)"
                  >
                    add
                  </v-icon>
                </template>
                <span>Agregar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({
    articles: [],
    search: "",
    headers: [
      { text: "Imagen", align: "start", value: "item_image", width: "10%" },
      { text: "ID", value: "item_id" },
      { text: "Descripcion", value: "item_name" },
      { text: "Unidad", value: "unit_name", width: "10%", align: "center" },
      { text: "Categoria", value: "item_categorie_name", width: "13%" },

      { text: "Cantidad", value: "quantity", width: "13%", align: "center" },
      { text: "Precio", value: "price", width: "10%", align: "end" },
      {
        text: "Categoria de cuenta",
        value: "account_categorie_name",
        align: " d-none",
      },
      { text: "Acciones", value: "actions", sortable: false, width: "8%" },
    ],
  }),
  props: {
    data: {
      type: Object,
    },
  },
  computed: {},
  mounted() {
    this.init();
  },
  created() {},
  methods: {
    selectItem(item) {
      this.$emit("selected-item", item);
    },
    async getImage(item_image_url) {
      let url = "image-not-aviable.jpg";
      if (
        item_image_url != null &&
        item_image_url != "" &&
        item_image_url != "null" &&
        item_image_url != undefined
      ) {
        // url = await this.get_storage("items_images", item_image_url);
      }
      return url;
    },
    async init() {
      const inventory_value = await this.api_Post("inventory/inventory_value", {
        date: moment().format("YYYY-MM-DD"),
        business_unit_id: this.data.business,
        location_id: this.data.location,
      });
      const data = await inventory_value.json();

      let articles = [];

      for (let item of data) {
        let available = item.item_in_warehouses.filter(
          (element) => element.warehouse_id == this.data.warehouse
          //&& element.id == this.data.catalogue
        );
        let quantity_available = 0;
        if (available.length > 0) {
          quantity_available = available[0].quantity;
        }
        articles.push({
          item_id: item.item_id,
          item_name: item.item_name,
          unit_name: item.unit_name,
          item_categorie_name: item.item_categorie_name,
          quantity: quantity_available,
          price: item.price,
          item_image: await this.getImage(item.item_image_url),
        });
      }
      this.articles = articles;
    },
  },
};
</script>

<style></style>
