<template>
  <v-container>
    <v-row justify="center" class="mb-3">
      <v-icon class="mx-2" size="35px">receipt_long</v-icon>

      <span style="font-size: 34px; color: #616161">Cuentas por pagar</span>
    </v-row>

    <v-stepper v-model="type_modal">
      <v-stepper-items>
        <v-stepper-content step="0">
          <v-btn-toggle v-model="types_form" tile color="primary" group>
            <v-btn> Pagos multiples </v-btn>
            <v-btn> Pagos por suplidor </v-btn>
            <v-btn> Pagos realizados </v-btn>
          </v-btn-toggle>

          <v-stepper v-model="types_form">
            <v-row justify="center" class="mt-4">
              <v-col cols="12" md="10">
                <v-row justify="center">
                  <search-header :show_department="false" :select_week="2" @dataFilters="changeBussines($event)" />
                </v-row>
              </v-col>
            </v-row>

            <v-stepper-items>
              <v-stepper-content step="0">
                <v-row justify="center">
                  <v-col cols="12">
                    <v-row v-if="hasData === true" no-gutters justify="start">
                      <v-card outlined elevation="1" width="100%" height="100px">
                        <v-layout fill-height align-center justify-center>
                          <v-col cols="2.4">
                            <v-row justify="center">
                              <span class="subtitle-1">Cantidad Facturas</span></v-row>
                            <br />
                            <v-row justify="center">
                              <span class="font-weight-bold" style="font-size: 24px; color: #58586a">{{
                                data_invoices.length }}
                              </span></v-row>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="2.4">
                            <v-row justify="center">
                              <span class="subtitle-1">Por pagar</span></v-row>
                            <br />
                            <v-row justify="center">
                              <span class="font-weight-bold" style="font-size: 24px; color: #e85e48">
                                {{
                                  currencyFormat(
                                    data_invoices.reduce(
                                      (sum, value) =>
                                        sum +
                                        parseFloat(value.total_amount) -
                                        parseFloat(
                                          value.total_paid_amount === null
                                            ? 0
                                            : value.total_paid_amount
                                        ),
                                      0
                                    )
                                  )
                                }}
                              </span></v-row>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="2.4">
                            <v-row justify="center">
                              <span class="subtitle-1">Notas de crédito
                              </span></v-row>
                            <br />
                            <v-row justify="center">
                              <span class="font-weight-bold" style="font-size: 24px; color: #e85e48">
                                {{
                                  currencyFormat(
                                    clients.reduce(
                                      (sum, value) =>
                                        sum +
                                        parseFloat(value.total_credit_amount),
                                      0
                                    )
                                  )
                                }}
                              </span></v-row></v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="2.4">
                            <v-row justify="center">
                              <span class="subtitle-1">Pagado </span>
                            </v-row>
                            <br />
                            <v-row justify="center">
                              <span class="font-weight-bold" style="font-size: 24px; color: #00c6b8">
                                {{
                                  currencyFormat(
                                    data_invoices.reduce(
                                      (sum, value) =>
                                        sum +
                                        parseFloat(
                                          value.total_paid_amount === null
                                            ? 0
                                            : value.total_paid_amount
                                        ),
                                      0
                                    )
                                  )
                                }}
                              </span></v-row></v-col>
                        </v-layout>
                      </v-card>
                    </v-row>
                    <!-- <CheckOutCxC :data="data_invoices" /> -->
                    <v-card class="rounded-lg mt-1">
                      <v-data-table v-if="!laoded" dense show-select v-model="selected" :search="search"
                        :headers="headersInvoices" :items="data_invoices" :items-per-page="10" class="elevation-0"
                        item-key="invoice_number">
                        <template v-slot:top>
                          <v-toolbar class="elevation-0 rounded-lg">
                            <v-toolbar-title>Facturas </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
                            <v-spacer></v-spacer>
                            <v-btn small class="mx-2" color="primary" outlined>
                              <v-icon class="mx-1">print</v-icon> Impimir previo
                            </v-btn>
                            <v-btn @click="handlePay" small class="mx-2" color="primary" outlined>
                              <v-icon class="mx-1">payments</v-icon> Hacer pago
                            </v-btn>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.invoice_number`]="{ item }">
                          <v-hover v-slot="{ hover }">
                            <span @click="showInvoice(item, true)"
                              class="primary--text font-weight-normal text-truncate font-weight-semibold"
                              style="font-size: 12px" :style="{
                                color: hover ? 'red' : 'black',
                                cursor: hover ? 'pointer' : '',
                                'text-decoration': hover ? 'underline' : '',
                              }">
                              {{ item.invoice_number }}</span>
                          </v-hover>
                        </template>
                        <template v-slot:[`item.entity_name`]="{ item }">
                          <span style="font-size: 12px">{{
                            item.entity_name
                          }}</span>
                        </template>
                        <template v-slot:[`item.total_amount`]="{ item }">
                          <span style="font-size: 12px">
                            {{ currencyFormat(item.total_amount) }}
                            {{ item.currency_code }}</span>
                        </template>
                        <template v-slot:[`item.total_pending_amount`]="{ item }">
                          <span style="color: red">
                            {{
                              currencyFormat(
                                item.total_amount - item.total_paid_amount
                              )
                            }}
                            {{ item.currency_code }}</span>
                        </template>
                        <template v-slot:[`item.total_credit_amount`]="{ item }">
                          <span>
                            {{ currencyFormat(item.total_credit_amount) }}
                            {{ item.currency_code }}</span>
                        </template>
                        <template v-slot:[`item.total_paid_amount`]="{ item }">
                          <span style="color: green; font-size: 12px">
                            {{ currencyFormat(item.total_paid_amount) }}
                            {{ item.currency_code }}</span>
                        </template>
                        <template v-slot:[`item.paid`]="{ item }">
                          <v-text-field :disabled="item.total_amount - item.total_paid_amount == 0
                            " :max="item.total_amount - item.total_paid_amount" v-model="item.paid" value="0"
                            prefix="$"></v-text-field>
                        </template>
                        <template v-slot:[`item.expire_at`]="{ item }">
                          <span v-if="item.expire_at != null">
                            <span style="font-size: 12px" :class="getDateColor(item)">
                              {{ getDate(item.expire_at) }}
                            </span>
                          </span>
                        </template>
                        <template v-slot:[`item.status_id`]="{ item }">
                          <v-chip small outlined color="success" v-if="item.total_amount - item.total_paid_amount == 0
                            ">
                            <span>Pagado </span>
                          </v-chip>
                          <v-chip small outlined color="error" v-else>
                            <span>Por Pagar </span>
                          </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon @click="viewInvoices(item)" color="success" class="mx-2 mt-n2" v-bind="attrs"
                                v-on="on">
                                payments
                              </v-icon>
                            </template>
                            <span>Agregar pago</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:no-data>
                          <v-row justify="center" class="pt-10">
                            <v-icon size="80px">token</v-icon>
                          </v-row>
                          <v-row justify="center" class="pb-6">
                            No hay datos
                          </v-row>
                        </template>
                      </v-data-table>
                      <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
                    </v-card>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="1">
                <v-row justify="center">
                  <v-col cols="12">
                    <v-row v-if="hasData === true" no-gutters justify="start">
                      <v-card outlined elevation="1" width="100%" height="100px">
                        <v-layout fill-height align-center justify-center>
                          <v-col cols="2.4">
                            <v-row justify="center">
                              <span class="subtitle-1">Cantidad Facturas</span></v-row>
                            <br />
                            <v-row justify="center">
                              <span class="font-weight-bold" style="font-size: 24px; color: #58586a">{{
                                clients.reduce(
                                  (sum, value) =>
                                    sum + parseFloat(value.invoice_quantity),
                                  0
                                )
                              }}
                              </span></v-row>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="2.4">
                            <v-row justify="center">
                              <span class="subtitle-1">Por pagar</span></v-row>
                            <br />
                            <v-row justify="center">
                              <span class="font-weight-bold" style="font-size: 24px; color: #e85e48">
                                {{
                                  currencyFormat(
                                    clients.reduce(
                                      (sum, value) =>
                                        sum +
                                        parseFloat(value.total_pending_amount),
                                      0
                                    )
                                  )
                                }}
                              </span></v-row>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="2.4">
                            <v-row justify="center">
                              <span class="subtitle-1">Notas de crédito
                              </span></v-row>
                            <br />
                            <v-row justify="center">
                              <span class="font-weight-bold" style="font-size: 24px; color: #e85e48">
                                {{
                                  currencyFormat(
                                    clients.reduce(
                                      (sum, value) =>
                                        sum +
                                        parseFloat(value.total_credit_amount),
                                      0
                                    )
                                  )
                                }}
                              </span></v-row></v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="2.4">
                            <v-row justify="center">
                              <span class="subtitle-1">Pagado </span>
                            </v-row>
                            <br />
                            <v-row justify="center">
                              <span class="font-weight-bold" style="font-size: 24px; color: #00c6b8">
                                {{
                                  currencyFormat(
                                    clients.reduce(
                                      (sum, value) =>
                                        sum +
                                        parseFloat(value.total_paid_amount),
                                      0
                                    )
                                  )
                                }}
                              </span></v-row></v-col>
                        </v-layout>
                      </v-card>
                    </v-row>
                    <v-card class="rounded-lg mt-4">
                      <v-data-table v-if="!laoded" :search="search" :headers="headers" :items="clients"
                        class="elevation-0">
                        <template v-slot:top>
                          <v-toolbar class="elevation-0 rounded-lg">
                            <v-toolbar-title>Clientes</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
                            <v-spacer></v-spacer>
                          </v-toolbar>
                        </template>

                        <template v-slot:[`item.total_pending_amount`]="{ item }">
                          <!-- <v-chip class="ma-2" text-color="white" color="red" outlined > -->
                          <span style="color: red">
                            {{
                              currencyFormat(item.total_pending_amount)
                            }}</span>
                          <!-- </v-chip> -->
                        </template>
                        <template v-slot:[`item.total_credit_amount`]="{ item }">
                          <span>
                            {{ currencyFormat(item.total_credit_amount) }}</span>
                        </template>
                        <template v-slot:[`item.total_paid_amount`]="{ item }">
                          <span style="color: green">
                            {{ currencyFormat(item.total_paid_amount) }}</span>
                        </template>

                        <template v-slot:[`item.entity_name`]="{ item }">
                          <v-hover v-slot="{ hover }">
                            <span @click="viewEntity(item.entity_id, true)" style="font-size: 14px"
                              class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                                color: hover ? 'red' : 'black',
                                cursor: hover ? 'pointer' : '',
                                'text-decoration': hover ? 'underline' : '',
                              }">{{ item.entity_name }}</span></v-hover>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon @click="viewInvoices(item)" color="success" class="mx-2 mt-n2" v-bind="attrs"
                                v-on="on">
                                payments
                              </v-icon>
                            </template>
                            <span>Agregar pago</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:no-data>
                          <v-row justify="center" class="pt-10">
                            <v-icon size="80px">token</v-icon>
                          </v-row>
                          <v-row justify="center" class="pb-6">
                            No hay datos
                          </v-row>
                        </template>
                      </v-data-table>
                      <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
                    </v-card>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-row v-if="loading" justify="center">
                  <v-col cols="12" md="10">
                    <v-skeleton-loader
                      type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions">
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <v-data-table v-else dense :search="search" :headers="headersTransactions" :items="transactions"
                  class="elevation-0">
                  <template v-slot:top>
                    <v-toolbar class="elevation-0 rounded-lg">
                      <v-toolbar-title>Pagos</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.invoice_payment_id`]="{ item }">
                    <v-hover v-slot="{ hover }">
                      <span @click="showPayments(item.invoice_payment_id, 2)"
                        class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                          color: hover ? 'red' : 'black',
                          cursor: hover ? 'pointer' : '',
                          'text-decoration': hover ? 'underline' : '',
                        }">
                        {{
                          filLeft(item.invoice_payment_id.toString(), 12, "0")
                        }}
                      </span>
                    </v-hover>
                  </template>

                  <template v-slot:[`item.entity`]="{ item }">
                    <template v-for="entity of groupByEntityId(item.payments_details)">
                      <span :style="{ color: colorRandom() }" :key="entity.entity_id">
                        {{ entity[0].entity_name }} <br /></span>
                    </template>
                  </template>

                  <template v-slot:[`item.total_amount`]="{ item }">
                    <span style="font-size: 12px">
                      {{ currencyFormat(item.total_amount) }}
                      {{ item.currency_code }}</span>
                  </template>
                  <template v-slot:[`item.status_id`]="{ item }">
                    <v-chip small :color="getColor(item.status_id)">
                      {{ item.status_name }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon v-if="item.status_id == 1" @click="
                      (dialogCancelPayment = true), (selectPayments = item)
                      " color="red" class="mx-2 mt-n2">
                      cancel
                    </v-icon>
                  </template>
                </v-data-table>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-stepper-content>
        <v-stepper-content step="1">
          <v-row justify="start">
            <v-col cols="12" md="3">
              <v-btn class="mx-6" color="error" outlined @click="type_modal = 0" v-show="type_modal == 1">
                <v-icon>arrow_back</v-icon> Volver
              </v-btn>
            </v-col>
          </v-row>

          <CheckOutCxC @close="refeshData" v-if="type_modal === 1" :data="data_invoices_pay" :type="1" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog transition="slide-x-transition" v-model="dialog" fullscreen>
      <v-card style="background-color: #f4f5fa">
        <v-toolbar flat dark color="transparent">
          <!-- <v-btn @click="dialog=false" icon dark >
                                                <v-icon color="black">mdi-close</v-icon>
                                            </v-btn> -->
          <!-- <v-toolbar-title v-if="dialog">Cuentas por cobrar ( {{ selectedClients[0].entity_name }} ) </v-toolbar-title>
                                            <v-spacer></v-spacer> -->
        </v-toolbar>
        <v-container>
          <v-row justify="center" class="mt-n12" v-if="dialog">
            <span class="text-h4">
              Cuentas por pagar ( {{ selectedClients[0].entity_name }} )
            </span>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="11">
              <viewInvoices v-if="!laoded" @close="reset()" :type="true" :data_invoices="selectedClients" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCancelPayment" transition="dialog-top-transition" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>Anular pago</v-toolbar>
        <v-card-text class="text-center">
          <div class="text-h5 pa-5 text-center">
            Seguro que deseas eliminar este pago?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="dialogCancelPayment = false">Volver</v-btn>
          <v-btn outlined @click="cancelPayment">Anular pago</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
//import Filters from "@/components/FilterBussinesLocations";
import viewInvoices from "@/components/Transactions/ViewInvoicesCxC.vue";
import CheckOutCxC from "@/components/Transactions/CheckOutCxC.vue";
import moment from "moment";
export default {
  components: { viewInvoices, CheckOutCxC },
  data: () => ({
    loading: false,
    dialogCancelPayment: false,
    type_modal: 0,
    e1: 1,
    types_form: 0,
    laoded: true,
    dialog: false,
    search: "",
    clients: [],
    data_invoices: [],
    data_invoices_pay: [],
    selected: [],
    hasData: false,
    headers: [
      { text: "Cliente", value: "entity_name", align: "start" },
      { text: "Cant facturas", value: "invoice_quantity", align: "end" },
      {
        text: "Balance pendiente",
        value: "total_pending_amount",
        align: "end",
      },
      { text: "Notas de credito", value: "total_credit_amount", align: "end" },
      { text: "Total Pagado", value: "total_paid_amount", align: "end" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersInvoices: [
      { text: "Factura", value: "invoice_number", align: "start" },
      {
        text: "Factura suplidor",
        value: "supplier_invoice_number",
        align: "start",
      },
      { text: "Suplidor", value: "entity_name", align: "start" },
      { text: "Vencimineto", value: "expire_at" },
      { text: "Monto", value: "total_amount" },
      // { text: "Cant facturas", value: "invoice_quantity", align: "end" },
      { text: "Total Pagado", value: "total_paid_amount", align: "end" },
      // { text: "Notas de credito", value: "total_credit_amount", align: "end" },

      {
        text: "Balance pendiente",
        value: "total_pending_amount",
        align: "end",
      },
      // { text: "Abono", value: "paid", align: "center", width: "12%" },
      // { text: "Status", value: "status_id", align: "center", width: "12%" },
    ],

    transactions: [],
    headersTransactions: [
      {
        text: "Grupo pago",
        align: "start",
        sortable: false,
        value: "invoice_payment_id",
      },
      {
        text: "Suplidor",
        value: "entity",
        width: "20%",
      },
      {
        text: "Usuario",
        value: "payment_by_name",
      },
      {
        text: "Monto total",
        align: "end",
        value: "total_amount",
      },
      {
        text: "Status",
        value: "status_id",
      },
      { text: "Actions", value: "actions", sortable: false, width: "5%" },
    ],
    selectPayments: null,
    lastData: null,
    selectedClients: [],
    editedIndex: -1,
    editedItem: [],
  }),

  computed: {},

  watch: {
    async types_form(val) {
      this.changeBussines(this.lastData);
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    handlePay() {
      if (this.selected.length > 0) {
        this.data_invoices_pay = [];
        for (let item of this.selected) {
          this.data_invoices_pay.push({
            invoice_id: item.invoice_id,
            business_unit_id: item.business_unit_id,
            business_unit_description: item.business_unit_description,
            business_unit_rnc: item.business_unit_rnc,
            department_id: item.department_id,
            location_id: item.location_id,
            location_description: item.location_description,
            created_by: item.created_by,
            user_fullname: item.user_fullname,
            entity_id: item.entity_id,
            entity_name: item.entity_name,
            entity_contact_json: item.entity_contact_json,
            document_id_type_name: item.document_id_type_name,
            entity_document_id: item.entity_document_id,
            entity_email: item.entity_email,
            box_entry_id: item.box_entry_id,
            emission_date: item.emission_date,
            expire_at: item.expire_at,
            currency_id: item.currency_id,
            currency_name: item.currency_name,
            currency_code: item.currency_code,
            invoice_type_id: item.invoice_type_id,
            invoice_type_name: item.invoice_type_name,
            invoice_number: item.invoice_number,
            invoice_ncf: item.invoice_ncf,
            subtotal_amount: item.subtotal_amount,
            taxes_amount: item.taxes_amount,
            total_amount: item.total_amount,
            paid: (
              parseFloat(item.total_amount) -
              parseFloat(
                item.total_paid_amount === null ? 0 : item.total_paid_amount
              )
            ).toFixed(2),
            order_entry_id: item.order_entry_id,
            supplier_invoice_number: item.supplier_invoice_number,
            amount_formated: item.amount_formated,
            total_paid_amount: item.total_paid_amount,
            note: item.note,
            status_id: item.status_id,
            sta_description: item.sta_description,
            storage_id: item.storage_id,
            storage_json: item.storage_json,
            currency_rate: item.currency_rate,
            invoice_category_id: item.invoice_category_id,
          });
        }

        this.type_modal = 1;
      } else {
        this.$toast.warning("Debes selecioar una factura");
      }
    },
    getColor(color) {
      if (color === 1) {
        return "orange";
      }
      if (color === 2) {
        return "blue";
      }
      if (color === 3) {
        return "green";
      }

      return "grey";
    },
    groupByEntityId(array) {
      if (array === null) {
        return [];
      }
      const grouped = {};
      array.forEach((item) => {
        const entityId = item.entity_id;
        if (!grouped[entityId]) {
          grouped[entityId] = [];
        }
        grouped[entityId].push(item);
      });
      return grouped;
    },
    colorRandom() {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);

      // Crear cadena de color en formato hexadecimal
      let colorHex = "#" + r.toString(16) + g.toString(16) + b.toString(16);

      return colorHex;
    },
    refeshData() {
      this.getData(this.lastData);
      this.type_modal = 0;
    },
    async getData(data) {
      // this.clients=[]

      this.laoded = true;

      let filter = "";
      filter +=
        data.business == 0 ? "" : " AND business_unit_id=" + data.business;
      filter += data.location == 0 ? "" : " AND location_id=" + data.location;
      filter +=
        " AND EXPIRE_AT::DATE BETWEEN '" +
        data.dateStart +
        "' AND '" +
        data.dateEnd +
        "' ";

      let responseRequest = await this.api_Post("transactions/invoice_shop", {
        filters: filter,
      });

      if (responseRequest.status == 200) {
        this.data_invoices = await responseRequest.json();
      }
      this.laoded = false;
    },
    async getDataSupplier(data) {
      this.laoded = true;
      let filter = "WHERE invoice_type_id=1 ";
      filter +=
        data.business == 0 ? "" : " AND business_unit_id=" + data.business;
      filter += data.location === 0 ? "" : " AND location_id=" + data.location;
      let resultclients = await this.api_Post(
        "transactions/suppliers_pending_balances",
        { filters: filter }
      );
      if (resultclients.status == 200) {
        this.clients = [];
        this.clients = await resultclients.json();
      }

      this.laoded = false;
    },
    getDate(item) {
      return moment(item).format("DD/MM/YYYY");
    },
    async getPayments(datas) {
      this.loading = true;
      let filter = " where invoice_type_id=1 ";
      let data = {};
      if (datas != undefined) {
        data = datas;
      } else {
        data = this.lastData;
      }

      if (data != null) {
        filter +=
          data.business === 0 ? "" : "AND business_unit_id=" + data.business;
        filter +=
          data.location === 0 ? "" : " AND location_id=" + data.location;
      }

      let resultpayments = await this.api_Post("transactions/viewAllPayemnts", {
        filters: filter,
      });
      if (resultpayments.status == 200) {
        this.transactions = [];
        this.transactions = await resultpayments.json();
      }
      this.loading = false;
    },
    async cancelPayment() {
      let result = await this.api_Post("transactions/cancel_payment", {
        id_payments: this.selectPayments.invoice_payment_id,
      });
      if (result.status == 200) {
        this.$toast.success("Pago anulado");
        this.dialogCancelPayment = false;
        this.getPayments();
      }
    },
    async reset() {
      this.laoded = true;
      this.laoded = false;
      await this.changeBussines(this.lastData);
      this.dialog = false;
    },
    getDateColor(item) {
      return "red--text";
    },
    async changeBussines(data) {
      this.hasData = true;
      if (this.types_form === 0) {
        await this.getData(data);
      }
      if (this.types_form === 1) {
        await this.getDataSupplier(data);
      }
      if (this.types_form === 2) {
        await this.getPayments(data);
      }

      this.lastData = data;
    },
    async viewInvoices(item) {
      this.laoded = true;
      let data = {
        entity_id: item.entity_id,
        busines_unit: item.business_unit_id,
      };

      let responseRequest = await this.api_Post(
        "transactions/invoice_supplier",
        data
      );

      if (responseRequest.status == 200) {
        this.selectedClients = await responseRequest.json();

        this.dialog = true;
      }
      this.laoded = false;
      //console.log(item)
    },
    async initialize() { },

    editItem(item) {
      this.editedIndex = this.entitys.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.entitys.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.entitys.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
