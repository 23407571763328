<template>
  <v-flex>
    <v-row v-if="!loading" justify="center" no-gutters>
      <v-col cols="12" md="12">
        <v-container v-if="data_invoice != null">
          <v-row no-gutters class="" justify="start">
            <!-- <v-icon class="mx-2" size="35px">receipt_long</v-icon> -->
            <span class="mx-2 mt-1" style="font-size: 34px; color: #616161">
              {{ formTitle }} {{ data_invoice[0].invoice_number }}
            </span>
          </v-row>
          <v-row no-gutters class="mb-3 mx-2" justify="start">
            <!-- <v-icon class="mx-2" size="35px">receipt_long</v-icon> -->
            <v-btn @click="beforeRoute" small outlined class="mr-2 rounded text-capitalize" tile color="primary">
              <v-icon left>keyboard_backspace</v-icon>
              Volver
            </v-btn>
            <v-btn @click="downloadInvoice" small outlined class="mx-2 rounded text-capitalize" tile color="primary">
              <v-icon left> print </v-icon>
              Imprimir
            </v-btn>
            <!-- <v-btn
              small
              outlined
              class="mx-2 rounded text-capitalize"
              tile
              color="primary"
            >
              <v-icon left> picture_as_pdf </v-icon>
              Descargar
            </v-btn> -->
            <v-btn v-if="data_invoice[0].clients_amount -
              data_invoice[0].total_paid_amount >
              0
              " small outlined class="mx-2 rounded text-capitalize" tile color="primary">
              <v-icon left> payments </v-icon>
              Agregar pago
            </v-btn>
            <v-btn @click="dialogCancelInvoice = true" v-if="data_invoice[0].status_id == 1" small outlined
              class="mx-2 rounded text-capitalize" tile color="primary">
              <v-icon left> cancel </v-icon>
              Anular factura
            </v-btn>
            <v-spacer></v-spacer>
            <div v-if="data_invoice[0].invoice_type_id !=5" >
              <v-chip v-if="data_invoice[0].recorded" small outlined color="success">
              Contabilizada
            </v-chip>
            <v-chip v-else small outlined color="warning">
              Sin contabilizar
            </v-chip>
      
            <v-btn v-if="!data_invoice[0].recorded" @click="dialogAccountingInvoice = true" small outlined class="mx-2 rounded text-capitalize" tile
              color="success">
              <v-icon left> thumb_up </v-icon>
              Contabilizar
            </v-btn>
            <v-btn v-else @click="dialogCancelAccountingInvoice = true" small outlined class="mx-2 rounded text-capitalize" tile
              color="red">
              <v-icon left> thumb_down </v-icon>
              descontabilizar
            </v-btn>
            </div>
          
          </v-row>
          <v-row v-if="data_invoice[0].invoice_type_id === 1 ||
            data_invoice[0].invoice_type_id === 2
            " no-gutters justify="start">
            <v-card outlined elevation="1" width="100%" height="100px">
              <v-layout fill-height align-center justify-center>
                <v-col cols="2.4">
                  <v-row justify="center">
                    <span class="subtitle-1">Valor total</span></v-row>
                  <br />
                  <v-row justify="center">
                    <span class="font-weight-bold" style="font-size: 24px; color: #58586a">
                      {{ currencyFormat(data_invoice[0].total_amount) }}
                      {{ data_invoice[0].currency_code }}
                    </span></v-row>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="2.4">
                  <v-row justify="center">
                    <span class="subtitle-1">Retenido</span></v-row>
                  <br />
                  <v-row justify="center">
                    <span class="font-weight-bold" style="font-size: 24px; color: #e85e48">
                      0.00 {{ data_invoice[0].currency_code }}
                    </span></v-row>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="2.4">
                  <v-row justify="center">
                    <span class="subtitle-1">Notas de crédito </span></v-row>
                  <br />
                  <v-row justify="center">
                    <span class="font-weight-bold" style="font-size: 24px; color: #e85e48">
                      0.00 {{ data_invoice[0].currency_code }}
                    </span></v-row></v-col>
                <v-divider vertical></v-divider>
                <v-col cols="2.4">
                  <v-row justify="center">
                    <span class="subtitle-1" v-if="data_invoice[0].invoice_type_id == 2">Cobrado
                    </span>
                    <span class="subtitle-1" v-if="data_invoice[0].invoice_type_id == 1">Pagado
                    </span>
                  </v-row>
                  <br />
                  <v-row justify="center">
                    <span class="font-weight-bold" style="font-size: 24px; color: #00c6b8">
                      {{ currencyFormat(data_invoice[0].total_paid_amount) }}
                      {{ data_invoice[0].currency_code }}
                    </span></v-row></v-col>
                <v-divider vertical></v-divider>
                <v-col cols="2.4">
                  <v-row justify="center">
                    <span class="subtitle-1" v-if="data_invoice[0].invoice_type_id == 2">Por cobrar
                    </span>
                    <span class="subtitle-1" v-if="data_invoice[0].invoice_type_id == 1">Por pagar
                    </span>
                  </v-row>
                  <br />
                  <v-row justify="center">
                    <span class="font-weight-bold" style="font-size: 24px; color: #e85e48">
                      {{
                        currencyFormat(
                          data_invoice[0].total_amount -
                          data_invoice[0].total_paid_amount
                        )
                      }}
                      {{ data_invoice[0].currency_code }}
                    </span></v-row></v-col>
              </v-layout>

              <!-- <v-row justify="center" class="mt-10">
            <v-col cols="2">
              <v-row justify="center"> <span>Valor total</span></v-row></v-col
            >
            <v-divider vertical></v-divider>
            <v-col cols="2">
              <v-row justify="center"> <span>Valor total</span></v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="2">
              <v-row justify="center"> <span>Valor total</span></v-row></v-col
            >
            <v-divider vertical></v-divider>
            <v-col cols="2">
              <v-row justify="center"> <span>Valor total</span></v-row></v-col
            >
            <v-divider vertical></v-divider>
            <v-col cols="2">
              <v-row justify="center"> <span>Valor total</span></v-row></v-col
            >
          </v-row> -->
            </v-card>
          </v-row>
          <v-row class="mt-4" no-gutters justify="center">
            <!-- <v-card class="folded_corner" style="" width="90%" min-height="700px"> -->
            <div class="folded_corner mt-5">
              <v-row class="mt-4" justify="center">
                <v-col cols="2" md="3">
                  <v-row justify="center">
                    <v-img contain width="100px" height="100px" :src="locationImage"></v-img>
                  </v-row>
                  <v-row justify="end">
                    <span @click="
                      viewReception(
                        data_invoice[0].order_id,
                        data_invoice[0].order_entry_id
                      )
                      " v-if="data_invoice[0].order_entry_id != null"
                      style="text-decoration: underline; cursor: pointer" class="primary--text">Recepción #{{
                        filLeft(
                          data_invoice[0].order_entry_id.toString(),
                          12,
                          "0"
                        )
                      }}</span>
                  </v-row>
                </v-col>
                <v-col cols="2" md="6">
                  <v-row justify="center">
                    <span class="font-weight-bold" style="font-size: 20px; color: #616161">{{
                      data_invoice[0].business_unit_description }}</span>
                  </v-row>

                  <v-row justify="center">
                    <span style="font-size: 16px; color: #616161">{{
                      data_invoice[0].location_description
                    }}</span>
                  </v-row>

                  <v-row justify="center">
                    <span style="font-size: 16px; color: #616161">RNC {{ data_invoice[0].business_unit_rnc }}</span>
                  </v-row>
                  <v-row justify="center">
                    <span style="font-size: 14px; color: #616161"> </span>
                  </v-row>
                  <v-row justify="center">
                    <span style="font-size: 14px; color: #616161"></span>
                  </v-row>
                </v-col>
                <v-col cols="2" md="3">
                  <v-row justify="center" class="mt-4">
                    <span class="font-weight-bold ml-16"> NCF: </span>
                    <span> {{ data_invoice[0].invoice_ncf }}</span>
                  </v-row>
                  <v-row justify="center" v-if="data_invoice[0].ncf_invoice_code != null">
                    <span class="font-weight-bold"> NCF AFECTADO: </span>
                    <span style="color: red">
                      {{ data_invoice[0].ncf_invoice_code }}</span>
                  </v-row>
                  <v-row no-gutters justify="start"> </v-row>
                </v-col>
              </v-row>
              <!-- <v-row>
            <v-divider class="mr-10 mt-2 mb-4" inset></v-divider>
          </v-row> -->
              <v-row justify="center">
                <v-col cols="2" md="6">
                  <span class="ml-16">Cliente</span>
                  <span @click="viewEntity(data_invoice[0].entity_id)" style="text-decoration: underline; cursor: pointer"
                    class="mx-4 font-weight-bold">{{ data_invoice[0].entity_name }}</span>
                </v-col>
                <v-col cols="2" md="6">
                  <span class="mr-12 float-right">Creacion
                    <b>{{ getDate(data_invoice[0].emission_date) }}</b>
                  </span>
                </v-col>
                <v-divider inset class="mr-8"></v-divider>
              </v-row>
              <v-row justify="center">
                <v-col cols="2" md="6">
                  <span class="ml-16">{{
                    data_invoice[0].document_id_type_name
                  }}</span>
                  <span class="mx-4 font-weight-bold">{{
                    data_invoice[0].entity_document_id
                  }}</span>
                </v-col>
                <v-col cols="2" md="6">
                  <span class="mr-12 float-right">Vencimiento <b>{{ getDate(data_invoice[0].expire_at) }}</b>
                  </span>
                </v-col>
                <v-divider inset class="mr-8"></v-divider>
              </v-row>

              <v-row justify="center">
                <v-col cols="2" md="6">
                  <span class="ml-16">Teléfono</span>
                  <span class="mx-4 font-weight-bold">{{
                    data_invoice[0].entity_phone_number
                  }}</span>
                </v-col>
                <v-col cols="2" md="6">
                  <!-- <span class="mr-12 float-right"
                    >Plazo de pago
                    <b>{{ getDate(data_invoice[0].emission_date) }}</b>
                  </span> -->
                </v-col>
                <v-divider inset class="mr-8"></v-divider>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="11">
                  <v-container class="">
                    <v-data-table :headers="headers" dense :items="data_invoice[0].invoice_details"   :items-per-page="4000"
                      class="elevation-0 rounded-lg" hide-default-footer>
                      <!-- <template v-slot:header="{ props: { headers } }">
                        <thead>
                          <tr>
                            <th
                              style="background-color: #f9fafd"
                              v-for="(h, i) in headers"
                              :class="h.class"
                              :key="i"
                            >
                              <span>{{ h.text }}</span>
                            </th>
                          </tr>
                        </thead>
                      </template> -->
                      <template v-slot:[`item.price`]="{ item }">
                        <span>
                          {{ currencyFormat(item.price) }}
                          {{ data_invoice[0].currency_code }}
                        </span>
                      </template>
                      <template v-slot:[`item.itbis`]="{ item }">
                        <span>
                          {{ currencyFormat(item.taxes * item.quantity) }}
                          {{ data_invoice[0].currency_code }}</span>
                      </template>
                      <template v-slot:[`item.total`]="{ item }">
                        <span>
                          {{
                            currencyFormat(
                              item.price_with_taxes * item.quantity
                            )
                          }}
                          {{ data_invoice[0].currency_code }}</span>
                      </template>
                      <template v-slot:footer>
                        <v-row class="mt-14" justify="start">
                          <v-col cols="12" md="8">
                            <v-divider inset></v-divider>
                            <v-row class="mt-2" justify="center">
                              <span class="font-weight-bold text-center">
                                ELABORADO POR
                              </span>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row class="mt-n15" justify="end" style="margin-right: 0.5em">
                          <v-card style="margin-top: 30px; margin-bottom: 30px" elevation="0" width="30%" height="70px">
                            <v-layout fill-height align-center>
                              <v-col cols="12" md="6">
                                <v-row justify="start">
                                  <span class="font-weight-bold">
                                    Subtotal
                                  </span>
                                </v-row>
                                <v-row justify="start">
                                  <span class="font-weight-bold">
                                    Descuento
                                  </span>
                                </v-row>
                                <v-row justify="start">
                                  <span class="font-weight-bold"> Itbis </span>
                                </v-row>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-row justify="end">
                                  <span class="font-weight-normal">
                                    {{
                                      currencyFormat(
                                        data_invoice[0].subtotal_amount
                                      )
                                    }}
                                    {{ data_invoice[0].currency_code }}
                                  </span>
                                </v-row>
                                <v-row justify="end">
                                  <span class="font-weight-normal">
                                    0.00 {{ data_invoice[0].currency_code }}
                                  </span>
                                </v-row>
                                <v-row justify="end">
                                  <span class="font-weight-normal">
                                    {{
                                      currencyFormat(
                                        data_invoice[0].taxes_amount
                                      )
                                    }}
                                    {{ data_invoice[0].currency_code }}
                                  </span>
                                </v-row>
                              </v-col>
                              <v-col cols="12" md="1"></v-col>
                            </v-layout>
                            <v-divider class="mt-2"></v-divider>
                            <v-row class="mt-2">
                              <v-col cols="12" md="2">
                                <v-row justify="start">
                                  <span style="font-size: 3vh; color: #58595f" class="font-weight-bold ml-2 mt-1">
                                    Total
                                  </span>
                                </v-row>
                              </v-col>
                              <v-col cols="12" md="10">
                                <v-row justify="end" class="mt-1">
                                  <v-spacer></v-spacer>
                                  <span style="font-size: 2vh; color: #58595f" class="mx-1 font-weight-normal">
                                    {{
                                      currencyFormat(
                                        data_invoice[0].total_amount
                                      )
                                    }}</span>
                                  <span style="font-size: 2vh; color: #58595f" class="font-weight-bold">
                                    {{ data_invoice[0].currency_code }}
                                  </span>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-row>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-col>
              </v-row>

              <v-row style="margin-bottom: 130px" justify="start" class="mt-4 mx-12">
                <v-col cols="12">
                  <v-row>
                    <span class="font-weight-bold">Notas</span>
                  </v-row>

                  <v-row>
                    <span> {{ data_invoice[0].note }} </span>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <!-- </v-card> -->
          </v-row>

          <v-row class="mt-8" no-gutters justify="center">
            <v-col cols="12" md="12"><v-card height="auto">
                <v-tabs v-model="tab" background-color="grey lighten-5 ">
                  <v-tab v-for="(item, i) in items" :key="i">
                    <span class="text-capitalize"> {{ item }}</span>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <v-row class="mt-2 mb-2" justify="center">
                      <v-col cols="12" md="11">
                        <!-- <v-row justify="center"> -->
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr style="background-color: #f9fafd">
                                <th class="text-left">Numero pago</th>
                                <th class="text-left">Fecha</th>

                                <th class="text-left">Metodo de pago</th>
                                <th class="text-left">Monto</th>
                                <th class="text-left">Status</th>
                                <!-- <th class="text-left">Pagada por</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in data_invoice[0].invoice_payments" :key="item.id">
                                <td>
                                  <v-hover v-slot="{ hover }">
                                    <span @click="
                                      showTransactions(
                                        item.invoice_payment_id
                                      )
                                      " class="primary--text font-weight-normal text-truncate font-weight-semibold"
                                      :style="{
                                        color: hover ? 'red' : 'black',
                                        cursor: hover ? 'pointer' : '',
                                        'text-decoration': hover
                                          ? 'underline'
                                          : '',
                                      }">
                                      {{
                                        filLeft(
                                          item.invoice_payment_id.toString(),
                                          12,
                                          "0"
                                        )
                                      }}
                                    </span>
                                  </v-hover>
                                </td>
                                <td>
                                  {{ getDate(item.payment_date) }}
                                </td>
                                <td>
                                  <v-icon color="success">{{
                                    getIconPayments(item.payment_type_id)
                                  }}</v-icon>
                                  {{ item.payment_type_name }}
                                </td>
                                <td>
                                  {{ item.currency_code }}
                                  {{ currencyFormat(item.payment_amount) }}
                                </td>
                                <td>
                                  <v-chip small outlined color="success">
                                    Pagado
                                  </v-chip>
                                  <!-- <v-chip small outlined color="red" v-else>
                                Anulado
                                </v-chip> -->
                                </td>
                                <!-- <td>{{ item.payment_by }}</td> -->
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <!-- </v-row> -->
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row class="mt-0" justify="center">
                      <v-col cols="12" md="11">
                        <v-data-table :headers="headersContabilidad" :items="data_invoice[0].invoice_entry_book"
                          class="elevation-0 rounded-lg mt4 mb-4" hide-default-footer :items-per-page="100" style="">
                          <template v-slot:[`item.chart_of_account_name`]="{ item }">
                            <span class="primary--text">
                              {{ item.chart_of_account_code }}
                            </span>
                            -
                            <span>
                              {{ item.chart_of_account_name }}
                            </span>
                          </template>

                          <template v-slot:[`item.debito`]="{ item }">
                            <span v-if="item.entry_type_id == 1">
                              {{ currencyFormat(item.amount) }}
                            </span>
                          </template>

                          <template v-slot:[`item.credito`]="{ item }">
                            <span v-if="item.entry_type_id == 2">
                              {{ currencyFormat(item.amount) }}</span>
                          </template>

                          <template slot="body.append">
                            <tr class="mt-2 mb-2">
                              <th></th>
                              <th class="font-weight-bold" style="color: #616161; font-size: 20px">
                                TOTAL
                              </th>
                              <!-- <th class="title">{{ sumField("calories") }}</th>
                              <th class="title">{{ sumField("fat") }}</th> -->
                              <th style="font-size: 18px" class="font-weight-normal text-right success--text">
                                {{ data_invoice[0].currency_code }}

                                {{ currencyFormat(totalContabilidad.debito) }}
                              </th>
                              <th class="font-weight-normal text-right error--text"
                                style="color: #616161; font-size: 18px">
                                {{ data_invoice[0].currency_code }}
                                {{ currencyFormat(totalContabilidad.credito) }}
                              </th>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row class="mt-0" justify="center">
                      <v-col cols="12" md="11">
                        <v-data-table :headers="headersInventario" :items="data_invoice[0].item_in_warehouse_details"
                          class="elevation-0 rounded-lg mt4 mb-4" hide-default-footer style="">
                          <template v-slot:[`item.effective_date`]="{ item }">
                        <span>
                          {{ getDate(item.effective_date) }}
            
                        </span>
                      </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-8" no-gutters justify="center">
            <v-col cols="12" md="6"><v-card class="mr-6" height="200px">
                <v-layout fill-height align-center>
                  <v-row no-gutters justify="start" class="mx-4">
                    <span class="mr-1">Creacion
                      <b>{{ getDate(data_invoice[0].emission_date) }}</b>
                    </span>
                  </v-row>
                  <v-divider inset class="mr-1"></v-divider>
                </v-layout>
                <!-- <v-row no-gutters>
              <span class="mr-1"
                >Creacion <b>{{ getDate(data_invoice[0].emission_date) }}</b>
              </span>
              <v-divider inset class="mr-1"></v-divider>
            </v-row> -->
              </v-card>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="6">
              <v-card class="ml-6" min-height="200px">
                <!-- <v-card-title>Archivos adjuntos </v-card-title> -->

                <v-layout :fill-height="data_invoice[0].storage_json == null ? true : false
                  " align-center>
                  <v-col cols="12">
                    <v-row>
                      <v-card width="100%" v-if="data_invoice[0].storage_json == null" @click="takeImage" style="
                          cursor: pointer;
                          border: 0.5px solid rgb(0, 0, 0);
                          border-radius: 6px;
                          background-color: #fafafa;
                        " class="mx-12 mt-6" outlined>
                        <v-row no-gutters justify="center">
                          <v-card-title class="headline">Archivos adjuntos
                          </v-card-title>
                        </v-row>
                        <v-row class="mb-4" no-gutters justify="center">
                          <v-icon style="transform: rotate(30deg)" size="60px">
                            attach_file
                          </v-icon>
                        </v-row>
                      </v-card>

                      <v-col v-else cols="12">
                        <v-row justify="center">
                          <span class="text-center ma-4">Archivo ajuntos</span>
                        </v-row>

                        <v-data-table dense hide-default-footer :headers="headersFiles" :items="itemsFiles"
                          item-key="name" class="elevation-0">
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon @click="viewAddtached(item)" v-on="on">
                                  visibility
                                </v-icon>
                              </template>
                              <span>Ver</span>
                            </v-tooltip>
                          </template>
                          <template v-slot:footer>
                            <v-container>
                              <v-row justify="center">
                                <v-btn @click="takeImage" small outlined>
                                  <v-icon size="40px" color="primary">add</v-icon>
                                  Agregar archivos
                                </v-btn>
                              </v-row></v-container>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-layout>
              </v-card>
            </v-col>
            <v-file-input v-show="false" style="opacity: 0" name="upload" id="fileInput"
              @change="onFileChange"></v-file-input>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-else>..loading</v-row>
    <v-dialog v-model="dialogAttached" width="60%">
      <div style="
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          padding-top: 25px;
          height: 0;
        ">
        <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" :src="selectedAttached"
          frameborder="0">
        </iframe>
      </div>
    </v-dialog>
    <v-dialog v-model="dialogCancelInvoice" transition="dialog-top-transition" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>Anular factura</v-toolbar>
        <v-card-text class="text-center">
          <div class="text-h5 pa-5 text-center">
            Seguro que deseas eliminar esta factura?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="dialogCancelInvoice = false">Volver</v-btn>
          <v-btn outlined @click="cancelInvoice">Anular factura</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAccountingInvoice" transition="dialog-top-transition" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>Contabilizar</v-toolbar>
        <v-card-text class="text-center">
          <div class="text-h5 pa-5 text-center">
            Seguro que deseas contabilizar esta factura?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="dialogAccountingInvoice = false">Volver</v-btn>
          <v-btn color="primary" outlined @click="accountingInvoice">Contabilizar factura</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCancelAccountingInvoice" transition="dialog-top-transition" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>Contabilizar</v-toolbar>
        <v-card-text class="text-center">
          <div class="text-h5 pa-5 text-center">
            Seguro que deseas anular la contabilidad de esta factura?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="dialogCancelAccountingInvoice = false">Volver</v-btn>
          <v-btn color="primary" outlined @click="cancelAccountingInvoice">descontabilizar factura</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    hasFiles: false,
    dialogCancelInvoice: false,
    dialogAccountingInvoice: false,
    dialogCancelAccountingInvoice:false,
    search: "",
    loading:false,
    selectedAttached: "",
    loadingAttachment: false,
    dialogAttached: false,
    date: moment().startOf("month").format("YYYY-MM-DD"),
    date2: moment().startOf("month").format("YYYY-MM-DD"),
    id_invoice: null,
    storage_invoice: "",
    data_invoice: null,
    detailsItems: [],
    tab: null,
    paymentsInvoice: [],
    locationImage: null,
    storage_json: [],
  
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    headersContabilidad: [
      {
        text: "Asiento",
        align: "start",
        sortable: false,
        value: "diary_book_code",
      },
      { text: "Cuenta", value: "chart_of_account_name" },

      { text: "	Debito", value: "debito", align: "right" },
      { text: "	Credito", value: "credito", align: "right" },
    ],
    headersInventario: [
    { text: "	Item", value: "item_name",  },
  
      { text: "Cantidad", value: "quantity" },
      { text: "	Almacén", value: "warehouse_name" },

      { text: "	Tipo", value: "entry_type_name"},
      {
        text: "Fecha",
        align: "start",
        sortable: false,
        value: "effective_date",
      },
    ],

    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "item_id",
      },
      {
        text: "Producto/servicio",
        align: "start",
        sortable: false,
        value: "item_name",
      },
      { text: "Referencia", value: "item_description" },
      { text: "Precio", value: "price", align: "end", width: "15%" },
      { text: "	Desc %", value: "", align: "end" },
      { text: "	Impuesto", value: "itbis", align: "end" },
      { text: "	Cantidad", value: "quantity", align: "end" },
      { text: "	Total", value: "total", align: "end" },
    ],
    itemsFiles: [],
    headersFiles: [
      {
        text: "Descripcion",
        align: "start",
        sortable: false,
        value: "description",
      },

      { text: "	Action", value: "actions", align: "end" },
    ],
    commentHeader: "",
  }),
  computed: {
    items() {
      let data=[]
      data.push("Pagos recibido")
      // if(this.data_invoice[0].recorded)
      // {
        data.push("Contabilidad")
      //}
      data.push("Inventario")
      data.push("Vouchers")
    return  data
    },
    formTitle() {
      if (this.data_invoice[0].invoice_type_id === 2) {
        return "Factura venta";
      }
      if (this.data_invoice[0].invoice_type_id === 1) {
        return "Factura compra";
      }
      if (this.data_invoice[0].invoice_type_id === 3) {
        return "Nota credito";
      }
      if (this.data_invoice[0].invoice_type_id === 4) {
        return "Nota debito";
      }
      if (this.data_invoice[0].invoice_type_id === 5) {
        return "Voucher";
      }
    },
    totalContabilidad() {
      let debito = 0;
      let credito = 0;

      if (this.data_invoice[0].invoice_entry_book != null) {
        for (let item of this.data_invoice[0].invoice_entry_book) {
          if (item.entry_type_id == 1) {
            debito += item.amount;
          }
          if (item.entry_type_id == 2) {
            credito += item.amount;
          }
        }
      }

      return { debito: debito.toFixed(2), credito: credito.toFixed(2) };
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async loadLogo() {
      let result = Object.assign(
        [],
        this.$store.state.auth.bussines_units
      ).filter(
        (element) => element.value == this.data_invoice[0].business_unit_id
      );

      if (result[0].image_storage != null) {
        if (result[0].image_storage != "undefined") {
          this.locationImage = await this.get_storage(
            "logo_bussines_units",
            result[0].image_storage
          );
        }
      } else {
        this.locationImage = null;
      }
    },
    async getData() {
      this.loading = true;
      this.id_invoice = atob(this.$route.params.id);

      const responseRequest = await this.api_Post("income/search_invoice_id", {
        number: this.id_invoice,
      });
      if (responseRequest.status == 200) {
        this.data_invoice = await responseRequest.json();
 
        console.log(this.data_invoice[0].item_in_warehouse_details)
        this.loadLogo();
        this.itemsFiles = this.data_invoice[0].storage_json;

        if (this.data_invoice[0].storage_json == null) {
        } else {
          this.storage_json = this.data_invoice[0].storage_json;
        }
      } else {
        this.$toast.error("error al cargar la factura!");
      }

      this.loading = false;
    },
    viewReception(id, order) {
      // this.$router.push({
      //     name: "ViewReceptions",
      //     params: { order_id: this.data_order[0].order_id, id: id },
      // });
    },
    async cancelInvoice() {
      let result = await this.api_Post("income/cancel_invoice", {
        invoice_id: this.data_invoice[0].invoice_id,
      });
      if (result.status == 200) {
        this.$toast.success("Factura anulada");
        this.dialogCancelInvoice = false;
        this.getData();
      } else {
        this.$toast.error("Error al anular la factura");
      }
    },
    async accountingInvoice() {
      let result = await this.api_Post("income/accounting_invoice", {
        invoice_id: this.data_invoice[0].invoice_id,
      });
      if (result.status == 200) {
        this.getData();
        this.$toast.success("Factura contabilizada");
        this.dialogAccountingInvoice = false;
     
      } else {
        this.$toast.error("Error al contabilizar la factura");
      }
    },
    async cancelAccountingInvoice() {
      let result = await this.api_Post("income/accounting_invoice_cancel", {
        invoice_id: this.data_invoice[0].invoice_id,
      });
      if (result.status == 200) {
        this.$toast.success("Factura descontabilizada");
        this.dialogCancelAccountingInvoice = false;
        this.getData();
      } else {
        this.$toast.error("Error al descontabilizar la factura");
      }
    },
    getDate(item) {
      return moment(item).format("DD/MM/YYYY");
    },
    async viewAddtached(item) {
      this.loadingAttachment = true;
      this.dialogAttached = true;

      try {
        this.selectedAttached = await this.get_storage(
          "attached_invoices",
          item.record
        );
      } catch (err) {
        console.log(err);
      }

      this.loadingAttachment = false;
    },
    async onFileChange() {
      try {
        const fileInput = document.getElementById("fileInput");
        let record = await this.upload_images("attached_invoices", fileInput);

        this.storage_json.push({
          description: "File " + (this.storage_json.length + 1).toString(),
          record: record.id,
        });

        const responseRequest = await this.api_Post(
          "income/attaches_invoices",
          {
            id: this.data_invoice[0].invoice_id,
            json: this.storage_json,
          }
        );
        if (responseRequest.status == 200) {
          this.$toast.success("Guardado!");
          this.getData();
        } else {
          this.$toast.error("error al cargar!");
        }
      } catch (e) {
        this.$toast.error("error " + e.message);
      }
    },

    takeImage() {
      //this.fileInput.click();
      document.getElementById("fileInput").click();

      //this.this.dialogImage = true;
    },
    downloadInvoice() {
      this.$router.push({
        name: "DownloadInvoice",
        params: { id: this.data_invoice[0].invoice_number },
      });
    },
    selectItem(item) {
      this.selectedEntity = item;
      this.dialogSearch = false;
    },
    selectPayment(item) {
      this.selectedPayments = item;
      this.dialogPayments = false;
    },
    deleteItem(item) {
      let editIndex = this.selectedPayments.indexOf(item);

      this.selectedPayments.splice(editIndex, 1);
    },
  },
};
</script>

<style scoped>
.folded-paper:before {
  content: "";
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 30px 30px 0;
  border-style: solid;
  border-color: rgb(74, 92, 67) #fff;
  transition: 0.3s;
  border-radius: 0 0 0 4px;
}

.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.folded_corner {
  position: relative;
  width: 100%;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  height: auto;
  border-radius: 5px;

  /* background: #58a; */
  background: linear-gradient(to left bottom,
      transparent 50%,
      rgba(194, 193, 193, 0.4) 0) no-repeat 100% 0 / 2em 2em,
    linear-gradient(-135deg, transparent 1.5em, rgb(255, 255, 255) 0);

  /* -webkit-filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
  /* filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
}

/* .folded_corner::before {
  content: " ";
  position: absolute;
  top: 2em;
  right: 0;
  background: linear-gradient(
      to left bottom,
      transparent 50%,
      rgba(0, 0, 0, 0.1) 0
    )
    100% 0 no-repeat;
  width: 2em;
  height: 2em;
  transform: rotate(180deg);
} */
.some-other-style {
  background: blue;
}

.my-header-style {
  color: #6f8fb9 !important;
}

.containerIframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
