<template>
    <div>

        <v-row justify="center" class="mt-2 mb-2">
            <v-icon class="mx-2 " size="35">currency_exchange</v-icon> <span style="font-size: 20px;">Grupo de moneda</span>
        </v-row>

        <v-row justify="center">
            <v-col cols="12">
                <v-data-table :headers="headers" :items="money_group" sort-by="calories" class="elevation-1">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Monedas</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="1000px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn outlined color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                        <v-icon>add</v-icon> Nuevo grupo
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title primary-title class="justify-center">
                                        <div>
                                            <span class="text-h5 ">{{ formTitle }}</span>
                                        </div>

                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>

                                            <v-row justify="center">

                                                <v-col cols="12" sm="6" md="3">
                                                    <v-text-field dense outlined v-model="editedItem.name"
                                                        label="Numbre del grupo"></v-text-field>
                                                </v-col>

                                            </v-row>
                                            <v-row class="mt-4">
                                                <DataIterator :all_items="itemsBussines"
                                                    :items_selected="editedItem.business_unit_ids" :title="{
                                                        all: 'Unidades de negocio',
                                                        selected: 'Unidades selecionadas',
                                                    }" />
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions class="justify-center">

                                        <v-btn color="red darken-1" text @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="blue darken-1" outlined @click="save">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.effective_date`]="{ item }">
                        <span>
                            {{ getDate(item.effective_date) }}
                        </span>
                    </template>
                    <template v-slot:[`item.default_currency_code`]="{ item }">

                        <span> <country-flag :country='getFlag(item.default_currency_id)' size='small' />
                            {{ item.default_currency_description }}
                        </span> <v-icon>arrow_right_alt</v-icon>
                    </template>
                    <template v-slot:[`item.foreing_currency_code`]="{ item }">

                        <span> <country-flag :country='getFlag(item.foreing_currency_id)' size='small' />
                            {{ item.foreing_currency_description }}
                        </span> <v-icon>arrow_right_alt</v-icon>
                    </template>
                    <template v-slot:[`item.value`]="{ item }">
                        <span>
                            <country-flag :country='getFlag(item.foreing_currency_id)' size='small' /> {{ item.value }} {{
                                item.foreing_currency_code }}
                        </span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" medium color="accent" class="mr-2"
                                    @click="editItem(item)">edit</v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>

                    </template>
                </v-data-table>
            </v-col>

        </v-row>

    </div>
</template>

<script>

import DataIterator from "@/components/base/DataIterator";
export default {
    data: () => ({

        dialog: false,

        loading: false,
        dialogDelete: false,
        itemsBussines: [],
        businedSelected: [],
        headers: [
            { text: 'Id', value: 'id' },
            {
                text: 'Nombre del grupo',
                align: 'start',
                sortable: false,
                value: 'name',
            },


            { text: 'Action', value: 'actions' },
        ],
        money_group: [],

        editedIndex: -1,
        editedItem: {
            id: null,
            name: "",
            business_unit_ids: []
        },
        defaultItem: {
            id: null,
            name: "",
            business_unit_ids: []
        },
    }),
    components: { DataIterator },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo grupo' : 'Editar grupo'
        }, dateFormatDMY() {
            return moment(this.editedItem.effective_date).format("DD/MM/YYYY");
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        async initialize() {
            let business = Object.assign(
                [],
                this.$store.state.auth.bussines_units
            );
            for (let item of business) {
                this.itemsBussines.push({ id: item.id, name: item.description_long })
            }

            this.loading = true

            let resultGroup = await this.api_Get("transactions/group_money");

            this.money_group = await resultGroup.json();
            this.loading = false
        },



        getDate(item) {
            return moment(item).format("DD/MM/YYYY");
        },

        editItem(item) {
            this.editedIndex = this.money_group.indexOf(item)
            this.editedItem = Object.assign({}, item)

            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.money_group.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },



        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        async save() {
            if (this.editedItem.business_unit_ids.length == 0) {
                this.$toast.warning("agrega unidades de negocio")
                return
            }

            if (this.editedItem.name == "") {
                this.$toast.warning("Elige un nombre para el grupo")
                return
            }


            try {
                let business = []
                for (let business_id of this.editedItem.business_unit_ids) {

                    business.push({ business_unit_id: business_id })
                }

                let datas = {
                    id: this.editedItem.id,
                    name: this.editedItem.name,
                    business_unit_ids: business
                }

                const response = this.api_Post(
                    "transactions/save_group",
                    datas
                );

                const data = await response;

                if (data.status == 200) {
                    this.$toast.success("Registro con exito!");
                    this.initialize()
                } else {
                    this.$toast.error("data.error");
                }
            } catch (err) {
                console.log(err);
            }
            this.close()
        },
    },
}
</script>

<style></style>