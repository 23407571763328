<template>
  <v-layout align-start>
    <v-flex>
      <v-card outlined>
        <v-row no-gutters justify="center">
          <span style="font-size: 20px; padding-top: 1rem">
            TIPOS DE CATEGORIAS
          </span>
        </v-row>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="3" md="3" class="pt-0 pb-0">
              <v-autocomplete dense v-model="filters.business" :items="$store.state.auth.bussines_units"
                label="Unidad de negocio" color="cyanText" prepend-inner-icon="corporate_fare" outlined
                required></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="pt-0 pb-0">
              <v-autocomplete dense v-model="filters.location" :items="$store.state.auth.locations.filter(
                (element) => element.bus_id == filters.business
              )
                " color="cyanText" label="Location" prepend-inner-icon="my_location" outlined required clearable>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="1" md="1" class="pt-0 pb-0">
              <v-row class="pt-2" justify="center" no-gutters>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" class="mr-2" size="40px" v-bind="attrs" v-on="on" @click="loadTypes()"
                      :loading="loadingFilter">
                      search
                    </v-icon>
                  </template>
                  <span>Buscar tipos con estos parametros</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
          <v-card outlined class="mt-4 pt-3">
            <v-card-text>
              <v-data-table :headers="headerTypes" :search="search" :items="types">
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>TIPOS</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <span>{{ types.length }}</span>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" single-line
                      hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-divider class="mx-3" inset vertical></v-divider>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="success" class="mr-2" v-bind="attrs" v-on="on" outlined @click="dialogNewType = true">
                          Nuevo tipo
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>
                      <span>Crear nuevo tipo</span>
                    </v-tooltip>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editType(item)">
                        edit
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mx-1" v-bind="attrs" v-on="on" medium color="green" @click="editDetails(item)">
                        add
                      </v-icon>
                    </template>
                    <span>Agregar {{ item.name }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.show`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="primary" @click="showBox(item)">
                        read_more
                      </v-icon>
                    </template>
                    <span>Historico</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-dialog v-model="dialogNewType" max-width="50%">
          <v-card outlined>
            <v-card-title class="mb-3">
              <span>{{ titleTipo }}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogNewType = false">
                <v-icon>clear</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row justify="center">
                  <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                    <v-text-field class="text-xs-center" v-model="newType.in_syde_type_name" label="Nombre"
                      outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                    <v-autocomplete v-model="newType.business_unit_id" :items="$store.state.auth.bussines_units"
                      label="Unidad de negocio" color="cyanText" outlined required>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                    <v-autocomplete v-model="newType.in_location_id" :items="$store.state.auth.locations.filter(
                      (element) =>
                        element.bus_id == newType.business_unit_id
                    )
                      " color="cyanText" label="Location" outlined required>
                    </v-autocomplete>
                  </v-col>

                  <!-- <v-col cols="12" sm="3" md="6" class="pt-0 pb-0">
                                      <v-text-field class="text-xs-center" v-model="lastBoxUpdate"
                                          label="Ultima actualizacion" outlined readonly></v-text-field>
                                  </v-col> -->
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="save()" :loading="loadingSave">
                {{ is_new_type == true ? "Guardar" : "Actualizar" }}
              </v-btn>
              <v-btn color="error" text @click="dialogNewType = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="DialogDetails" fullscreen>
          <v-card v-if="SelectedDetails" outlined>
            <v-card-title class="mb-3">
              <span>Detalles de {{ SelectedDetails.name }}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="DialogDetails = false">
                <v-icon>clear</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table :headers="headerDetails" :search="search" :items="DetailsType">
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>TIPOS</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <span>{{ DetailsType.length }}</span>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" single-line
                      hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-divider class="mx-3" inset vertical></v-divider>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="success" class="mr-2" v-bind="attrs" v-on="on" outlined @click="showAddDetails">
                          Nuevo detalle
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>
                      <span>Crear nuevo tipo</span>
                    </v-tooltip>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editDetailsType(item)">
                        edit
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mx-1" v-bind="attrs" v-on="on" medium color="red" @click="deleteSide(item)">
                        delete
                      </v-icon>
                    </template>
                    <span>Eliminar {{ item.name }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.show`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="primary" @click="showBox(item)">
                        read_more
                      </v-icon>
                    </template>
                    <span>Historico</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn color="success" text @click="save()" :loading="loadingSave">
                              {{ is_new_type == true ? 'Guardar' : 'Actualizar' }}
                          </v-btn>
                          <v-btn color="error" text @click="DialogDetails = false">
                              Cancelar
                          </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="DialogAddDetails" width="60%">
          <v-card outlined height="50vh">
            <v-card-title class="mb-3">
              <span>Nuevo detalle</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="DialogAddDetails = false">
                <v-icon>clear</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-text-field v-model="newDetail.in_name" label="Nombre" color="cyanText" outlined></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="newDetail.in_price" label="Precio" color="cyanText" outlined></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete v-model="newDetail.in_item_id" :items="articles" label="Articulo" color="cyanText"
                    outlined item-value="item_id" item-text="item_name" required></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="saveDetails()" :loading="loadingSave">
                {{ "Guardar" }}
              </v-btn>
              <v-btn color="error" text @click="DialogAddDetails = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import moment from "moment";
export default {
  data: () => ({
    filters: {
      business: "",
      location: "",
      department_id: "",
      chart_of_account: "",
    },
    newType: {
      in_location_id: null,
      in_side_type_id: null,
      in_syde_type_name: "",
    },
    newDetail: {
      in_side_id: null,
      in_side_type_id: null,
      in_name: null,
      in_price: null,
      in_item_id: null,
    },
    types: [],
    search: "",

    dialogConfirm: false,
    titleTipo: "Nuevo tipo",
    is_new_type: false,
    titleDialogConfirm: "Seguro que desea realizar esta acción?",
    dialogNewType: false,
    loadingSave: false,
    loadingFilter: false,
    headerTypes: [
      {
        text: "Id",
        align: "start",
        value: "id",
      },
      { text: "Nombre", value: "name" },
      { text: "Unidad de negocio", value: "business_unit_description_long" },
      { text: "Location", value: "location_description_long" },
      { text: "Location", value: "location_description_long" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headerDetails: [
      {
        text: "Id",
        align: "start",
        value: "id",
      },
      { text: "Nombre", value: "name" },
      { text: "Tipo", value: "type_name" },
      { text: "Item", value: "item_name" },
      { text: "Precio", value: "price" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    articles: [],
    DetailsType: [],
    SelectedDetails: null,
    loadingInvoices: false,
    DialogDetails: false,
    DialogAddDetails: false,
    dialogTitle: "-",
  }),
  components: {},
  created() {
    this.getData();
  },

  watch: {},
  methods: {
    async getData() { },
    async loadTypes() {
      this.loadingFilter = true;
      this.types = [];
      let filtros = " WHERE BUSINESS_UNIT_ID=" + this.filters.business;
      filtros += Number.isInteger(this.filters.location)
        ? " AND LOCATION_ID=" + this.filters.location
        : " ";

      try {
        const boxesRequest = await this.api_Post("restaurant/all_side_type", {
          filters: filtros,
        });
        const typeData = await boxesRequest.json();

        if (boxesRequest.status != 200) {
          this.$toast.error(typeData.error);
        }
        if (typeData.length === 0) {
          this.$toast.warning("No se econtraron caja");
        }
        this.types = typeData;
      } catch (error) {
        console.log(error);
      }
      this.loadingFilter = false;
    },

    showAddDetails() {
      this.newDetail.in_side_id = null;
      //  this.newDetail.in_side_type_id = null;
      this.newDetail.in_name = null;
      this.newDetail.in_price = null;
      this.newDetail.in_item_id = null;

      this.DialogAddDetails = true;
    },
    editDetailsType(item) {
      this.newDetail.in_side_id = item.id;
      this.newDetail.in_side_type_id = item.side_type_id;
      this.newDetail.in_name = item.name;
      this.newDetail.in_price = item.price;
      this.newDetail.in_item_id = item.item_id;
      this.DialogAddDetails = true;
    },
    editType(item) {

      this.newType.business_unit_id = item.business_unit_id;
      this.newType.in_location_id = item.location_id;
      this.newType.in_syde_type_name = item.name;
      this.newType.in_side_type_id = item.id;
      this.is_new_type = false;
      this.titleTipo = "Editar ";

      //   this.newType = Object.assign({}, item);

      this.dialogNewType = true;
    },
    async getGetails(id) {
      let filters = `where SIDE_TYPE_ID=${id}`
      const detail_sides = await this.api_Post("restaurant/view_side", {
        filters: filters,
      });
      this.DetailsType = await detail_sides.json();
    },
    async editDetails(item) {
      this.SelectedDetails = item;
      this.newDetail.in_side_type_id = item.id;
      this.articles = [];
      this.DialogDetails = false;

      this.getGetails(item.id);
      const all_articles = await this.api_Post("inventory/inventory_value", {
        date: moment().format("YYYY-MM-DD"),
        business_unit_id: item.business_unit_id,
        location_id: item.location_id,
      });
      this.articles = await all_articles.json();

      this.DialogDetails = true;
    },
    async save() {
      this.loadingSave = true;

      try {
        let data = {
          in_location_id: this.newType.in_location_id,
          in_side_type_id:
            this.newType.in_side_type_id === undefined
              ? null
              : this.newType.in_side_type_id,
          in_syde_type_name: this.newType.in_syde_type_name,
        };
        const newType = await this.api_Post("restaurant/side_type", data);
        if (newType.status === 200) {
          this.$toast.success("Guardado");
          //this.loadTypes();
          this.dialogNewType = false;
        }
      } catch (error) {
        this.$toast.error(error);
        console.log(error);
      }
      this.loadingSave = false;
    },
    async saveDetails() {
      this.loadingSave = true;

      try {
        let data = {
          // in_location_id,
          in_side_id: this.newDetail.in_side_id,
          in_side_type_id: this.newDetail.in_side_type_id,
          in_item_id: this.newDetail.in_item_id,
          in_name: this.newDetail.in_name,
          in_price: this.newDetail.in_price === null ? 0 : this.newDetail.in_price
        };
        const newType = await this.api_Post("restaurant/add_side", data);
        if (newType.status === 200) {
          this.$toast.success("Guardado");
          //this.loadTypes();
          this.getGetails(this.newDetail.in_side_type_id);
          this.DialogAddDetails = false;
        }
      } catch (error) {
        this.$toast.error(error);
        console.log(error);
      }
      this.loadingSave = false;
    },



    async deleteSide(item) {

      const request = await this.api_Delete(
        "restaurant/side/" + item.id
      );
      if (request.status == 200) {
        await this.getGetails(item.side_type_id);
        this.$toast.success("eliminado!");

        this.DialogAddDetails = false
      }
    },

  },
};
</script>
