<template>
  <v-container fluid class="pa-0">
    <v-responsive :aspect-ratio="16 / 9">
      <v-row class="mt-2">
        <v-col cols="12" md="7" class="pt-0 mt-0">
          <v-card width="100%" outlined min-height="420">
            <v-card-title class="pb-0 mb-0">
              <v-row class="pb-0 mb-0">
                <v-col cols="12" md="2" xs="6" sm="6" class="pb-0 mb-0 pr-0 pl-0" align="center">
                  <v-btn outlined dense @click="exit" color="primary" x-small>
                    <v-icon>arrow_back</v-icon> Volver
                  </v-btn>
                </v-col>
                <v-col cols="12" xs="6" md="7" sm="6" class="pb-0 mb-0 pr-0 pl-0" align="start">
                  <span style="font-size: 18px; color: #424242" class="pb-0 mb-0">
                    {{ historyTitle }}
                  </span>
                </v-col>
                <v-col cols="12" xs="12" md="3" sm="12" class="pb-0 mb-0 pr-1 pl-0" align="end">
                  <v-autocomplete :items="applicantsFilter" v-model="item.requested_by_id" color="cyanText"
                    label="Solicitante" outlined dense hide-details item-value="id" item-text="employee_fullname"
                    :readonly="!can_edit">
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider class="ma-3"></v-divider>
            <v-container fluid class="pt-0">
              <v-data-table :headers="headersArticle" :search="search" :items="item_list">
                <template v-slot:top>
                  <v-toolbar flat>
                    <span>Total: {{ item_list.length }}</span>
                    <v-spacer></v-spacer>
                    <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" single-line
                      hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mr-2" outlined v-if="can_edit" @click="openDialogAddItem()">
                      Agregar
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.item_image`]="{ item }">
                  <v-avatar size="40">
                    <v-img :src="item.item_image"></v-img>
                  </v-avatar>
                </template>
                <template v-slot:[`item.requested_quantity`]="{ item }">
                  <v-text-field class="text-xs-center" v-model="item.requested_quantity" dense type="number" min="1"
                    :readonly="!can_edit">
                  </v-text-field>
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  <span>{{
                    item.price != undefined
                    ? item.price * item.requested_quantity
                    : "No disponible"
                  }}</span>
                </template>
                <template v-slot:[`item.remove`]="{ item }">
                  <v-icon color="error" @click="removeItem(item)" v-if="can_edit">cancel</v-icon>
                </template>
              </v-data-table>
            </v-container>
            <v-divider class="ma-0"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="save(2)" v-if="can_edit">
                <v-icon class="pr-1">send</v-icon>Enviar
              </v-btn>
              <v-btn color="success" text @click="save(1)" v-if="can_edit">
                <v-icon class="pl-1">save</v-icon>Guardar borrador
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="5" class="pt-0 mt-0 pl-0">
          <v-card width="100%" outlined>
            <v-card-title style="font-size: 18px; color: #424242" class="pb-0 d-flex justify-center">
              Historico solicitud #{{ item.id }}</v-card-title>
            <v-divider class="ma-4"></v-divider>
            <v-responsive :aspect-ratio="16 / 9">
              <v-container fluid>
                <template>
                  <v-timeline>
                    <v-timeline-item v-for="item in time_line_status" small :key="item.item_request_status_id" :color="time_line_config(2, item.item_request_status_id).color
                      " :icon="time_line_config(2, item.item_request_status_id).icon
    " class="pb-0">
                      <template v-slot:opposite>
                        <span>{{ item.use_fullname }}</span>
                      </template>
                      <v-card outlined>
                        <v-card-title class="d-flex justify-center align-center pt-0 pb-0" :class="time_line_config(2, item.item_request_status_id)
                            .color
                          ">
                          <span style="font-size: 18px; color: #ffffff">
                            {{ dateFormated(item.created_at) }}</span>
                        </v-card-title>
                        <v-card-text>
                          <span class="d-flex justify-center align-center">
                            {{ item.name }}</span>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </template>
              </v-container>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog width="50%" v-model="dialogAddItem">
        <dialog_Articles @selected-item="addItem" :removeItemSelected="false" :showWarehouse="false" :data="{
          location: location.id,
          business: location.bus_id,
          warehouse: warehouse.id,
          catalogue: location.catalogue_id,
        }" @close-item="dialogAddItem = false" />
      </v-dialog>
    </v-responsive>
  </v-container>
</template>

<script>
import moment from "moment";
import dialog_Articles from "@/components/Inventory/CatalogeForAdjustment.vue";
export default {
  data: () => ({
    search: "",
    headersArticle: [
      {
        align: "start",
        value: "item_image",
      },
      { text: "ID", value: "item_id" },
      { text: "Articulo", value: "item_name" },
      { text: "Precio estimado", value: "price" },
      {
        text: "Cantidad solicitada",
        value: "requested_quantity",
        width: "20%",
        align: "center",
      },
      { text: "Total estimado", value: "amount" },
      { text: "", value: "remove" },
    ],
    dialogAddItem: false,
    locationFrom: null,
    warehouseFrom: null,
  }),
  name: "ItemsRequestDetails",
  props: {
    can_edit: {
      type: Boolean,
      default: false,
    },
    time_line_status: {
      type: Array,
      default() {
        return [{ name: "Borrador", item_request_status_id: 1 }];
      },
    },
    item_list: {
      type: Array,
      default() {
        return [];
      },
    },
    item: {
      type: Object,
      default() {
        return {
          status: null,
          location_from: null,
          warehouse_from: null,
        };
      },
    },
    location: {
      type: Object,
      default() {
        return {
          id: null,
          bus_id: null,
          catalogue_id: null,
        };
      },
    },
    warehouse: {
      type: Object,
      default() {
        return {
          id: null,
        };
      },
    },
    all_applicants: {
      type: Array,
      default() {
        return [
          {
            id: null,
            employee_fullname: null,
            access: {
              locations: [],
            },
          },
        ];
      },
    },
  },
  created() {
    //Este componente recibe la lista de articulos y el time line de su historico, dependiendo el estatus permitira editar
  },
  computed: {
    historyTitle() {
      return (
        "Lista de articulos " +
        (parseInt(this.item.status_id) == 1 ? "a solicitar" : "Solicitados")
      );
    },
    applicantsFilter() {
      let applicants = this.all_applicants.filter((element) =>
        element.access.locations.includes(this.location.id)
      );
      if (applicants.length == 0) {
        applicants.push({
          employee_fullname: "Este location no tiene un solicitante registrado",
          id: 0,
        });
      }
      return applicants;
    },
  },
  watch: {},
  methods: {
    dateFormated(date) {
      return moment(date).format("DD/MM/YYYY h:mm a");
    },
    openDialogAddItem() {
      this.dialogAddItem = true;
    },
    addItem(item) {
      let existArticle = this.item_list.find(
        (element) => element.item_id == item.item_id
      );
      if (existArticle != undefined) {
        this.$toast.warning("Este articulo ya se encuentra en la lista!");
        return;
      }
      this.item_list.push({
        item_id: item.item_id,
        item_name: item.item_name,
        requested_quantity: 1,
        price: item.price,
        item_image: item.item_image,
      });
      this.dialogAddItem = false;
    },
    removeItem(item) {
      let index = this.item_list.indexOf(item);
      this.item_list.splice(index, 1);
    },
    save(type) {
      if (this.item_list.length == 0) {
        this.$toast.warning(
          "Debe agregar al menos un articulo a la solicitud!"
        );
        return;
      }
      for (let item of this.item_list) {
        if (parseInt(item.requested_quantity) < 1) {
          this.$toast.warning(`No es posible solicitar 0: ${item.item_name}!`);
          return;
        }
      }
      if (this.item.requested_by_id == 0) {
        this.$toast.warning("Debe escoger un solicitante valido!");
        return;
      }

      this.$emit("save_request", {
        request_id: this.item.id,
        saveType: type, //1=borrador,2=pendiente
        applicant: this.item.requested_by_id,
      });
    },
    exit() {
      this.$emit("exitDetail", true);
    },
  },
  components: {
    dialog_Articles,
  },
};
</script>
