<template>
  <v-flex>
    <v-row justify="center" no-gutters>
      <v-col cols="12" md="10">
        <v-row no-gutters class="mt-3 mx-2" justify="start">
          <v-btn @click="downloadInvoice" small outlined class="mx-2 rounded text-capitalize" tile color="primary">
            <v-icon left> print </v-icon>
            Imprimir
          </v-btn>
        </v-row>
        <v-container v-if="data_transactions != null" fluid>
   
          <v-row v-for="entity of data_transactions_entity" :key="entity.entity_id" class="mt-1" no-gutters
            justify="center">
            <div class="folded_corner mt-5">
              <v-row justify="center">
                <span class="mt-12" style="font-size: 24px; color: #616161">{{ entity[0].business_unit_description }}
                </span>
              </v-row>

              <v-row justify="center" class="mt-14">
                <v-col cols="12" md="1"> </v-col>
                <v-col cols="12" md="5">
                  <span class="ml">Cliente</span>
                  <span style="text-decoration: underline" class="mx-4 font-weight-bold">{{ entity[0].entity_name
                  }}</span>
                </v-col>
                <v-col cols="12" md="5">
                  <v-row justify="end">
                    <span class="mr-3 float-right">Fecha
                      <b>{{ getDate(data_transactions[0].payment_date) }}</b>
                    </span>
                  </v-row>
                </v-col>
                <v-col cols="12" md="1"> </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="1"> </v-col>
                <v-col cols="12" md="5">
                  <span class="">{{ entity[0].document_id_type_name }}</span>
                  <span class="mx-4 font-weight-bold">{{
                    entity[0].entity_document_id
                  }}</span>
                </v-col>
                <v-col cols="12" md="5">
                  <v-row justify="end">
                    <span class="">Pago</span>
                    <span class="mx-4 font-weight-bold">
                      #{{
                        filLeft(entity[0].payment_sequence.toString(), 12, "0")
                      }}
                    </span>
                  </v-row>
                </v-col>
                <v-col cols="12" md="1"> </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="1"> </v-col>
                <v-col cols="12" md="5">
                  <span class="">Origen:
                    <b style="text-decoration: underline">
                      Cta.
                      {{
                        data_transactions[0].bank_account_description_short
                      }}</b>
                    {{ data_transactions[0].bank_account_description_long }}
                  </span>
                  <br />
                  <span class="">Destino:
                    <b style="text-decoration: underline">
                      Cta. {{ entity[0].supplier_account }}</b></span>
                  {{ entity[0].bank_description_long }}
                </v-col>
                <v-col cols="12" md="5">
                  <v-row justify="end">
                    <span class="">Tipo de pago</span>
                    <span class="mx-4 font-weight-bold">{{
                      data_transactions[0].payment_type_name
                    }}</span>
                  </v-row>
                </v-col>

                <v-col cols="12" md="1"> </v-col>
              </v-row>

              <v-row justify="center" class="mt-15">
                <v-col cols="12" md="11">
                  <v-container class="">
                    <v-data-table :headers="headers" dense :items="entity" class="elevation-0 rounded-lg"
                      hide-default-footer>
                      <template v-slot:[`item.invoice_number`]="{ item }">
                        <v-hover v-slot="{ hover }">
                          <span @click="showInvoice(item, true)"
                            class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                              color: hover ? 'red' : 'black',
                              cursor: hover ? 'pointer' : '',
                              'text-decoration': hover ? 'underline' : '',
                            }">
                            {{ item.invoice_number }}</span>
                        </v-hover>
                        - <span>{{ item.supplier_invoice_number }}</span>
                      </template>
                      <template v-slot:[`item.total_amount`]="{ item }">
                        <span>
                          {{ currencyFormat(item.total_amount) }}
                          {{ item.currency_code }}</span>
                      </template>
                      <template v-slot:[`item.total_pending_amount`]="{ item }">
                        <span style="color: red">
                          {{
                            currencyFormat(
                              item.total_amount - item.total_paid_amount
                            )
                          }}
                          {{ item.currency_code }}
                        </span>
                      </template>
                      <template v-slot:[`item.total_paid_amount`]="{ item }">
                        <span style="color: green">
                          {{ currencyFormat(item.payment_amount) }}
                          {{ item.currency_code }}</span>
                      </template>
                      <template v-slot:footer>
                        <v-row style="margin-top: 200px" justify="start">
                          <v-col cols="12" md="8">
                            <v-divider inset></v-divider>
                            <v-row class="mt-2" justify="center">
                              <span class="font-weight-bold text-center">
                                Elaborado por
                                {{ data_transactions[0].payment_by_name }}
                              </span>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-row justify="end">
                              <span style="font-size: 2.5vh; color: #58595f" class="font-weight-bold ml-2 mt-1">
                                Total
                                {{
                                  currencyFormat(
                                    entity.reduce(
                                      (sum, value) =>
                                        sum +
                                        parseFloat(value.total_paid_amount),
                                      0
                                    )
                                  )
                                }}
                                {{ data_transactions[0].currency_code }}
                              </span>
                            </v-row>
                          </v-col>
                        </v-row>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-col>
              </v-row>

              <v-row justify="center" class="mb-10 mt-8">
        
              </v-row>
            </div>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-flex>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    data_transactions: null,
    data_transactions_entity:[],
    headers: [
      {
        text: "Factura",
        align: "start",
        sortable: false,
        value: "invoice_number",
      },
      {
        text: "Monto total",
        align: "end",

        value: "total_amount",
      },
      {
        text: "Balance pendiente",
        align: "end",

        value: "total_pending_amount",
      },
      // { text: "Referencia", value: "item_description" },
      { text: "Total pagado", value: "total_paid_amount", align: "end" },
    ],
  }),
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;

      this.id_transactions = this.$route.params.id;
  
      const responseRequest = await this.api_Post("transactions/viewPayments", {
        id: this.id_transactions,
      });
      if (responseRequest.status == 200) {
        this.data_transactions = await responseRequest.json();
        console.log(this.data_transactions )
        //this.data_transactions_entity =   this.data_transactions
        this.data_transactions_entity = this.groupByEntityId(
          this.data_transactions[0].payments_details
        );
      } else {
        this.$toast.error("error al cargar el pago!");
      }

      this.loading = false;
    },
    groupByEntityId(array) {
      const grouped = {};
      if(array!=null)

      {

      
      array.forEach((item) => {
        const entityId = item.entity_id;
        if (!grouped[entityId]) {
          grouped[entityId] = [];
        }
        grouped[entityId].push(item);
      });
    }
      return grouped;
    },

    getDate(item) {
      return moment(item).format("DD/MM/YYYY");
    },

    downloadInvoice() {
      this.$router.push({
        name: "DownloadInvoice",
        params: { id: this.data_transactions[0].invoice_number },
      });
    },
    selectItem(item) {
      this.selectedEntity = item;
      this.dialogSearch = false;
    },
    selectPayment(item) {
      this.selectedPayments = item;
      this.dialogPayments = false;
    },
    deleteItem(item) {
      let editIndex = this.selectedPayments.indexOf(item);

      this.selectedPayments.splice(editIndex, 1);
    },
  },
};
</script>

<style scoped>
.folded-paper:before {
  content: "";
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 30px 30px 0;
  border-style: solid;
  border-color: rgb(74, 92, 67) #fff;
  transition: 0.3s;
  border-radius: 0 0 0 4px;
}

.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.folded_corner {
  position: relative;
  width: 100%;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  height: auto;
  border-radius: 5px;

  /* background: #58a; */
  background: linear-gradient(to left bottom,
      transparent 50%,
      rgba(194, 193, 193, 0.4) 0) no-repeat 100% 0 / 2em 2em,
    linear-gradient(-135deg, transparent 1.5em, rgb(255, 255, 255) 0);

  /* -webkit-filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
  /* filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
}

/* .folded_corner::before {
    content: " ";
    position: absolute;
    top: 2em;
    right: 0;
    background: linear-gradient(
        to left bottom,
        transparent 50%,
        rgba(0, 0, 0, 0.1) 0
      )
      100% 0 no-repeat;
    width: 2em;
    height: 2em;
    transform: rotate(180deg);
  } */
.some-other-style {
  background: blue;
}

.my-header-style {
  color: #6f8fb9 !important;
}

.containerIframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
