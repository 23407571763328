
import Cxc from "@/views/CxC/Cxc.vue";
import PaymentsReceived from "@/views/CxC/Payments.vue";
import ViewPayments from "@/views/CxC/ViewPayments.vue"
const cxcRoute=    {
    path: "/cxc",
    name: "cxc",
    component: Cxc,
    meta: {
      CXC: true,
    },
    children: [
      {
        path: "payments_cxc",
        name: "PaymentsReceived",
        component: PaymentsReceived,
        meta: {
          CXC: true,
        },
      },
      {
        path: "view_payments/:id?",
        name: "ViewPaymentsCxC",
        component: ViewPayments,
        meta: {
          CXC: true,
        },
      },
    ],
  }


export default cxcRoute
