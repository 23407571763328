<template>
  <v-container fluid>
    <v-layout align-start>
      <v-flex>
        <v-card flat class="pa-0">
          <v-row no-gutters justify="center">
            <span style="font-size: 34px; color: #616161">
              <v-icon large class="pr-1 pb-2">receipt_long</v-icon>
              {{ title }}
            </span>
          </v-row>
          <!-- <v-row no-gutters justify="center">
            <span style="font-size: 20px; padding-top: 1rem">
           
            </span>
          </v-row> -->
          <v-card-text class="pa-0">
            <v-card outlined class="mt-4 pt-3">
              <v-card-text>
                <v-data-table :headers="headerAdvances" :search="search" :items="payments">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Pagos</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <span>{{ payments.length }}</span>
                      <v-spacer></v-spacer>
                      <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar"
                        single-line hide-details>
                      </v-text-field>
                      <v-spacer></v-spacer>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <searchClients types_clients="2" @close="dialogSearch = false" @selected-item="selectItem($event)" />
                        </template>
                        <span>Buscar cliente</span>
                      </v-tooltip>
                      <v-divider class="mx-3" inset vertical></v-divider>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="success" class="mr-2" v-bind="attrs" v-on="on" outlined @click="addPayment">
                            Nuevo anticipo
                            <v-icon>add</v-icon>
                          </v-btn>
                        </template>
                        <span>Registrar nuevo pago anticipado</span>
                      </v-tooltip>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.anular`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="error" v-show="!item.consumed"
                          @click="canelAdvance(item)">
                          cancel
                        </v-icon>
                      </template>
                      <span>Anular anticipo</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <Advance :showDialog="dialogAdvance" @closeDialog="confirmClose" :entity="entity" />
    <v-dialog width="70%" v-model="dialogSearch">
      <searchClients @close="dialogSearch = false" @selected-item="selectItem($event)" />
    </v-dialog>
  </v-container>
</template>

<script>
import Advance from "@/components/Advance.vue";
import searchClients from "@/components/Income/SearchClientOrSupplier.vue";
export default {
  data: () => ({
    dialogAdvance: false,
    payments: [],
    search: "",
    headerAdvances: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      { text: "Location", value: "location_long" },
      { text: "Departamento", value: "department" },
      { text: "Monto", value: "amount" },
      { text: "Fecha", value: "payment_date", width: "8%" },
      { text: "Metodo", value: "entity_type" },
      { text: "Moneda", value: "currency" },
      { text: "Realizado por", value: "use_fullname", width: "12%" },
      { text: "Tipo", value: "payment_type" },
      { text: "Estado", value: "status", width: "10%" },
      { text: "Anular", value: "anular" },
    ],
    dialogSearch: false,
    entity: {
      entity_id: "",
      document_id: "",
      name: "",
      email: "",
      phone_number: "",
    },
  }),
  components: {
    Advance,
    searchClients,
  },
  computed: {
    title() {
      return (
        "Pagos anticipados" +
        (this.entity.entity_id == "" ? " " : " de " + this.entity.name)
      );
    },
  },
  methods: {
    selectItem(item) {
      this.entity.entity_id = item.entity_id;
      this.entity.document_id = item.document_id;
      this.entity.name = item.name;
      this.entity.email = item.email;
      this.entity.phone_number = item.phone_number;
      this.dialogSearch = false;
      this.consultPayments();
    },
    async consultPayments() {
      this.payments = [];
      const request = await this.api_Post("income/entity_payments", {
        filters: " WHERE ENTITY_PAYMENTS.ENTITY_ID=" + this.entity.entity_id,
      });
      if (request.status == 200) {
        const data = await request.json();
        if (data.length == 0) {
          this.$toast.warning("No se encontraron pagos a este cliente!");
          return;
        }

        this.payments = data;
      }
    },
    addPayment() {
      if (this.entity.entity_id == "") {
        this.$toast.warning("Debe escoger un cliente!");
        return;
      }
      this.dialogAdvance = true;
    },
    confirmClose(value) {
      //  console.log(value)
      if (value == true) {
        this.consultPayments();
      }
      this.dialogAdvance = false;
    },
    async canelAdvance(item) {
      try {
        const request = await this.api_Delete(
          "income/entityPayment/" + item.id
        );
        if (request.status == 200) {
          this.$toast.success("Anticipo eliminado!");
          this.consultPayments();
        }
      } catch (error) {
        //   console.log(error)
      }
    },
  },
};
</script>
