<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-row justify="start" class="mt-4">
        <v-icon class="mx-5" size="35px">group</v-icon>

        <span v-if="type == 1" style="font-size: 34px; color: #616161">Clientes / Suplidores</span>

        <span v-if="type == 2" style="font-size: 34px; color: #616161">Clientes
        </span>

        <span v-if="type == 3" style="font-size: 34px; color: #616161">Suplidores
        </span>
      </v-row>

      <v-row justify="start">
        <span class="mx-5" style="font-size: 16px; color: #757575">Crea tus clientes, proveedores y demás contactos para
          asociarlos en
          tus documentos.
        </span>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="entityData" :loading="loading" sort-by="calories"
            class="elevation-1 rounded-lg">
            <template v-slot:[`item.name`]="{ item }">
              <v-hover v-slot="{ hover }">
                <span @click="viewEntity(item.entity_id)" style="font-size: 14px"
                  class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }">{{ item.name }}</span></v-hover>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  <span v-if="type == 1"> Nuevo Contacto</span>
                  <span v-if="type == 2"> Nuevo Cliente</span>
                  <span v-if="type == 3"> Nuevo Suplidor</span></v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" single-line
                  hide-details>
                </v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" fullscreen>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                      <v-icon small>add</v-icon>
                      <span v-if="type == 1"> Nuevo Contacto</span>
                      <span v-if="type == 2"> Nuevo Cliente</span>
                      <span v-if="type == 3"> Nuevo Suplidor</span>
                    </v-btn>
                  </template>
                  <v-card style="background-color: #f4f5fa">
                    <v-toolbar flat dark color="transparent">
                      <v-spacer></v-spacer>
                      <v-btn icon dark @click="close">
                        <v-icon color="black">mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <v-card-text class="mt-n14">
                      <v-row justify="center">
                        <v-col cols="12" md="8">
                          <v-row justify="center">
                            <v-container>
                              <v-card-title class="align-start">
                                <v-icon class="ml-1 mr-2" size="35px">group</v-icon>

                                <span style="font-size: 24px" class="font-weight-semibold ml-1">{{ formTitle }}</span>
                              </v-card-title>
                              <v-card-subtitle class="mb-1 mt-n4 ml-1">
                                <span class="me-1">Crea tus clientes, proveedores y demás
                                  contactos para asociarlos en tus
                                  documentos.</span>
                              </v-card-subtitle>

                              <v-row>
                                <v-col cols="12" md="8"><v-card class="rounded-lg" min-height="750px">
                                    <v-container class="mx-4">
                                      <v-row class="mt-2">
                                        <v-col cols="12" sm="4" md="4">
                                          <v-autocomplete dense outlined v-model="selectedDocument" :items="documents"
                                            color="cyanText" label="Tipo de identificación"
                                            prepend-inner-icon="mdi-card-account-details-outline"
                                            required></v-autocomplete>
                                        </v-col>
                                        <v-col sm="5" md="4">
                                          <v-text-field :error-messages="rncValid == true &&
                                            selectedDocument.value == 1
                                            ? 'El rnc debe ser valido'
                                            : ''
                                            " v-model="editedItem.document_id" :label="selectedDocument == '' ? 'número' : selectedDocument + ' número' " dense outlined></v-text-field>
                                        </v-col>
                                        <v-col sm="2" md="2">
                                          <v-btn @click="searchRnc" color="primary" v-if="selectedDocument == 1">
                                            <v-icon size="25px">search</v-icon>
                                            Consultar
                                            {{ selectedDocument.text }}
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                      <v-row no-gutters>
                                        <v-col cols="12" sm="6" md="8">
                                          <v-text-field :disabled="disabledName" dense outlined v-model="editedItem.name"
                                            label="Nombre/Razón social"></v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-divider class="mr-4"></v-divider>

                                      <v-row class="mt-2" dense>
                                        <span style="font-size: 2vh" class="font-weight-bold pa-2">Sucursales</span>
                                      </v-row>
                                      <v-row dense>
                                        <v-col cols="12" md="5">
                                          <v-text-field v-model="descriptionSucursal" dense outlined
                                            label="Nombre sucursal"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                          <v-text-field v-model="addressSucursal" dense outlined
                                            label="Dirección sucursal"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="1">
                                          <v-btn @click="addSucursal" small color="primary" outlined fab>
                                            <v-icon>add</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                      <v-row no-gutters dense>
                                        <v-col cols="12" md="10">
                                          <v-data-table v-if="itemsSucursal != [] ||
                                            itemsSucursal != null ||
                                            itemsSucursal != ''
                                            " :headers="headersSucursal" :items="itemsSucursal" hide-default-footer
                                            class="elevation-0">
                                            <template v-slot:[`item.actions`]="{ item }">
                                              <v-tooltip top>
                                                <template v-slot:activator="{
                                                  on,
                                                  attrs,
                                                }">
                                                  <v-icon color="error" class="mr-2" v-bind="attrs" v-on="on" @click="
                                                    deleteItemContact(item)
                                                    ">
                                                    close
                                                  </v-icon>
                                                </template>
                                                <span>Eliminar</span>
                                              </v-tooltip>
                                            </template>
                                          </v-data-table>
                                        </v-col>
                                      </v-row>

                                      <v-divider class="mr-4"></v-divider>
                                      <v-row class="mt-2" dense>
                                        <span style="font-size: 2vh" class="font-weight-bold pa-2">Direcciones /
                                          contactos</span>
                                      </v-row>
                                      <v-row dense>
                                        <v-col cols="12" sm="4" md="3">
                                          <v-autocomplete dense outlined v-model="selectedTypes" :items="typeContacts"
                                            return-object item-text="description" item-value="id" color="cyanText"
                                            label="Tipo" required></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <v-text-field v-model="typeDescription" dense outlined :label="selectedTypes != null
                                            ? selectedTypes.text
                                            : null
                                            "></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="1">
                                          <v-btn @click="addContacts" small color="primary" outlined fab>
                                            <v-icon>add</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                      <v-row no-gutters dense>
                                        <v-col cols="12" md="10">
                                          <v-data-table v-if="itemContacts != [] ||
                                            itemContacts != null ||
                                            itemContacts != ''
                                            " :headers="headersContacts" :items="itemContacts" hide-default-footer
                                            class="elevation-0">
                                            <template v-slot:[`item.actions`]="{ item }">
                                              <v-tooltip top>
                                                <template v-slot:activator="{
                                                  on,
                                                  attrs,
                                                }">
                                                  <v-icon color="error" class="mr-2" v-bind="attrs" v-on="on" @click="
                                                    deleteItemContact(item)
                                                    ">
                                                    close
                                                  </v-icon>
                                                </template>
                                                <span>Eliminar</span>
                                              </v-tooltip>
                                            </template>
                                          </v-data-table>
                                        </v-col>
                                      </v-row>
                                      <v-divider class="mr-4"></v-divider>
                                      <v-row class="" dense>
                                        <span style="font-size: 2vh" class="font-weight-bold pa-2">Cuentas de
                                          bancos</span>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12" md="3">
                                          <v-autocomplete :items="itemsBanks" v-model="newAccount.account_bank_id" dense
                                            outlined item-text="bank_description_long" item-value="bank_id" label="Banco"
                                            required></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                          <v-autocomplete :items="itemsAccountypes" v-model="newAccount.account_type_id"
                                            dense outlined item-text="description" item-value="id" label="Tipo de cuenta"
                                            required></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                          <v-text-field dense outlined v-model="newAccount.check_digit"
                                            label="Numero cuenta"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="1">
                                          <v-btn @click="addAccount" small color="primary" outlined fab>
                                            <v-icon>add</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                      <v-row no-gutters dense>
                                        <v-col cols="12" md="10">
                                          <v-data-table v-if="bussiness_account != [] ||
                                            bussiness_account != null ||
                                            bussiness_account != ''
                                            " :headers="headersAccount" :items="bussiness_account" hide-default-footer
                                            class="elevation-0">
                                            <template v-slot:[`item.actions`]="{ item }">
                                              <v-tooltip top>
                                                <template v-slot:activator="{
                                                  on,
                                                  attrs,
                                                }">
                                                  <v-icon color="error" class="mr-2" v-bind="attrs" v-on="on" @click="
                                                    deleteItemAccount(item)
                                                    ">
                                                    close
                                                  </v-icon>
                                                </template>
                                                <span>Eliminar</span>
                                              </v-tooltip>
                                            </template>
                                            <template v-slot:[`item.is_default`]="{
                                              item,
                                            }">
                                              <v-checkbox @change="
                                                handleDefaultChange(item)
                                                " v-model="item.is_default"></v-checkbox>
                                            </template>
                                          </v-data-table>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card>
                                </v-col>

                                <v-col cols="12" md="4"><v-card class="rounded-lg" min-height="750px">
                                    <v-container>
                                      <v-row class="mt-2 mb-2" justify="center">
                                        <v-card class="rounded-lg" width="80%" height="60px" outlined>
                                          <v-row justify="center">
                                            <v-col cols="12" sm="5" md="5">
                                              <v-checkbox v-if="type == 2 || type == 1" v-model="cliente" label="Cliente"
                                                color="primary" hide-details></v-checkbox>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5">
                                              <v-checkbox v-model="suplidor" v-if="type == 3 || type == 1"
                                                label="Suplidor" color="primary" hide-details></v-checkbox>
                                            </v-col>
                                          </v-row>
                                        </v-card>
                                      </v-row>
                                      <v-row dense no-gutters justify="center" class="mb-1 mt-n1">
                                        <span>Moneda por defecto </span>
                                      </v-row>
                                      <v-row dense no-gutters justify="center" class="mb-1 mt-n1">
                                        <v-btn-toggle v-model="seleted_currencies" class="rounded-lg mr-2" mandatory
                                          color="primary">
                                          <v-btn v-for="(item, i) of currencies" :key="i" :value="item.id" small>
                                            {{ item.code }}
                                          </v-btn>
                                        </v-btn-toggle>
                                      </v-row>
                                      <v-row justify="center">
                                        <span v-if="cliente"> Cliente</span>
                                      </v-row>
                                      <v-row v-if="cliente" justify="center">
                                        <v-col cols="10">
                                          <v-autocomplete dense outlined v-model="selectedtypesNcfCliente"
                                            :items="ncfTypes" color="cyanText" label="Tipo de NCF Cliente

" required></v-autocomplete>
                                        </v-col>
                                      </v-row>
                                      <v-row no-gutters v-if="cliente" justify="center">
                                        <v-col cols="10">
                                          <v-autocomplete dense outlined v-model="selectedCreditDaysCliente"
                                            :items="credit_days" color="cyanText" label="Plazo de pago cliente 

" required></v-autocomplete>
                                        </v-col>
                                      </v-row>
                                      <v-row no-gutters v-if="cliente" justify="center">
                                        <v-col cols="10">
                                          <v-autocomplete dense outlined v-model="selectedWithholdingsCliente
                                            " item-text="name" item-value="id" :items="withholdings" color="cyanText"
                                            label="Retenciones cliente" required></v-autocomplete>
                                        </v-col>
                                      </v-row>

                                      <v-row no-gutters class="mb-3" justify="center">
                                        <span v-if="suplidor"> Suplidor</span>
                                      </v-row>
                                      <v-row no-gutters v-if="suplidor" justify="center">
                                        <v-col cols="10">
                                          <v-autocomplete dense outlined v-model="selectedtypesNcfSuplidor"
                                            :items="ncfTypes" color="cyanText" label="Tipo de NCF Suplidor

" required></v-autocomplete>
                                        </v-col>
                                      </v-row>

                                      <v-row no-gutters v-if="suplidor" justify="center">
                                        <v-col cols="10">
                                          <v-autocomplete dense outlined v-model="selectedCreditDaysSuplidor"
                                            :items="credit_days" color="cyanText" label="Plazo de pago suplidor"
                                            required></v-autocomplete>
                                        </v-col>
                                      </v-row>
                                      <v-row no-gutters v-if="suplidor" justify="center">
                                        <v-col cols="10">
                                          <v-autocomplete dense outlined v-model="selectedWithholdingsSuplidor
                                            " item-text="name" item-value="id" :items="withholdings" color="cyanText"
                                            label="Retenciones Suplidor" required></v-autocomplete>
                                        </v-col>
                                      </v-row>

                                      <v-row v-if="cliente || suplidor" class="pb-3" no-gutters justify="center">
                                        <span style="
                                            font-size: 22px;
                                            color: #616161;
                                          ">Configuración contabilidad
                                        </span>
                                      </v-row>

                                      <v-row no-gutters justify="center">
                                        <v-col cols="10">
                                          <v-autocomplete v-show="cliente == true" dense v-model="cxc" :items="accounts"
                                            color="cyanText" label="Cuenta por cobrar" outlined required></v-autocomplete>
                                        </v-col>
                                      </v-row>

                                      <v-row no-gutters justify="center">
                                        <v-col cols="10">
                                          <v-autocomplete v-if="suplidor == true" dense v-model="cxp" :items="accounts"
                                            color="cyanText" label="Cuenta por pagar" outlined required></v-autocomplete>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card>
                                </v-col>
                              </v-row> </v-container></v-row></v-col></v-row>
                    </v-card-text>
                    <v-row class="mt-5" align="center" justify="space-around">
                      <v-spacer></v-spacer>
                      <v-btn @click="close" class="mx-3" outlined>
                        Cancelar
                      </v-btn>

                      <v-btn @click="save" color="primary"> Guardar </v-btn>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    suplidor: false,
    cliente: false,
    loading: false,
    disabledName: false,
    plazoPagoSuplidor: null,
    plazoPagoCliente: null,
    documents: [],
    itemsAccountypes: [],
    rncValid: false,
    typeDescription: "",
    descriptionSucursal: "",
    ncfTypes: [],
    accounts: [],
    provincies: [],
    credit_days: [],
    withholdings: [],
    typeContacts: [],
    itemContacts: [],
    itemsSucursal: [],
    itemsBanks: [],
    currencies: [],
    seleted_currencies: null,
    addressSucursal: "",
    cxc: "",
    cxp: "",
    selectedDocument: "",
    selectedWithholdingsCliente: null,
    selectedWithholdingsSuplidor: null,
    selectedtypesNcfCliente: null,
    entityData: [],
    selectedtypesNcfSuplidor: null,
    selectedCreditDaysCliente: null,
    selectedCreditDaysSuplidor: null,
    selectedProvincies: null,
    selectedTypes: null,
    bussiness_account: [],
    newAccount: {
      id: "",
      account_type_id: "",
      account_type_description: "",
      account_bank_id: "",
      bank_description_short: "",
      bank_description_long: "",
      account_name: "",
      currencie_id: "",
      check_digit: "",
      status_id: "",
    },
    headersSucursal: [
      { text: "Descripción", value: "name" },
      { text: "Dirección", value: "address" },

      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    headersContacts: [
      {
        text: "Tipo",
        align: "start",
        sortable: false,
        value: "description_type",
        width: "20%",
      },
      { text: "Descripcion", value: "description" },

      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    headersAccount: [
      {
        text: "Tipo",
        align: "start",
        sortable: false,
        value: "bank_description_long",
      },
      { text: "Tipo ", value: "account_type_description" },
      { text: "Cuenta ", value: "account_check_digit" },

      {
        text: "Por defecto",
        value: "is_default",
        sortable: false,
        width: "10%",
      },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    headers: [
      {
        text: "Nombre/Razón social",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Identificación", value: "document_id" },
      { text: "Teléfono", value: "phone_number" },
      { text: "Email", value: "email" },

      { text: "Actions", value: "actions", sortable: false },
    ],

    editedIndex: false,
    editedItem: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo contacto" : "Editar contacto";
    },
  },
  props: {
    entitys: {
      type: Array,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  watch: {
    search(val, newVal) {
      if (val.length >= 2) {
        this.searchClient(val);
      } else {
        this.entityData = this.entitys;
        //this.clients = [];
      }
    },
    "editedItem.document_id"(val, newVal) {
      if (val != newVal) {
        this.disabledName = false;
      }
    },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    // if (this.type == 2) {
    //   this.cliente = true;
    // }
    // if (this.type == 3) {
    //   this.suplidor = true;
    // }
    this.initialize();
  },

  methods: {
    async searchClient(val, type) {
      this.loading = true;
      this.entityData = [];
      let typ = "";
      typ = this.type === 1 ? "" : "";
      typ = this.type === 2 ? "AND IS_CUSTOMER=true" : "";
      typ = this.type === 3 ? "AND IS_SUPPLIER=true" : "";

      // Agregar un retraso de 300 ms (ajusta este valor según tus necesidades)
      await new Promise((resolve) => setTimeout(resolve, 300));

      const responseRequest = await this.api_Post("accounting/search_entity", {
        name: val,
        type: typ,
      });
      if (responseRequest.status == 200) {
        let data = await responseRequest.json();

        this.entityData = data;
      } else {
        this.$toast.error("¡Error al agregar!");
      }
      this.loading = false;
    },
    addSucursal() {
      this.itemsSucursal.push({
        name: this.descriptionSucursal,
        address: this.addressSucursal,
      });
      this.descriptionSucursal = "";
      this.addressSucursal = "";
    },
    addContacts() {
      if (this.selectedTypes === null) {
        this.$toast.warning("Debes seleccionar un tipo!");
        return;
      }
      if (
        this.typeDescription === "" ||
        this.typeDescription === undefined ||
        this.typeDescription === null
      ) {
        this.$toast.warning(
          "Debes agregar una descripción a " +
          this.selectedTypes.description +
          " !"
        );
        return;
      }
      this.itemContacts.push({
        id_type: this.selectedTypes.id,
        description_type: this.selectedTypes.description,
        description: this.typeDescription,
      });
    },
    addAccount() {
      if (this.newAccount.account_bank_id == "") {
        this.$toast.warning("Escoja el banco!");
        return;
      }
      if (this.newAccount.account_type_id == "") {
        this.$toast.warning("Indique el tipo de cuenta!");
        return;
      }

      let banc_text = this.itemsBanks.find(
        (element) => element.bank_id == this.newAccount.account_bank_id
      );
      let tipeAcon_text = this.itemsAccountypes.find(
        (element) => element.id == this.newAccount.account_type_id
      );
      // let currencie_text = this.itemsCurrencies.find(
      //   (element) => element.id == this.newAccount.currencie_id
      // );

      if (
        parseInt(banc_text.bank_digits) !=
        parseInt(this.newAccount.check_digit.length)
      ) {
        this.$toast.warning(
          "La cantidad de digitos debe ser " + banc_text.bank_digits
        );
        return;
      }

      this.bussiness_account.push({
        account_type_id: this.newAccount.account_type_id,
        account_type_description: tipeAcon_text.description,
        account_bank_id: this.newAccount.account_bank_id,
        bank_description_long: banc_text.bank_description_long,
        account_check_digit: this.newAccount.check_digit,
        is_default: false,
      });

      this.newAccount = {
        account_type_id: "",
        account_bank_id: "",
        bank_description_long: "",
        check_digit: "",
        is_default: false,
      };
      this.Validate_edit_account = true;
    },
    handleDefaultChange(selectedItem) {
      // Cambia todos los valores predeterminados (is_default) a falso
      this.bussiness_account.forEach((item) => {
        if (item !== selectedItem) {
          item.is_default = false;
        }
      });
    },
    deleteItemContact(item) {
      let index = this.itemContacts.indexOf(item);

      this.itemContacts.splice(index, 1);
    },
    deleteItemAccount(item) {
      let index = this.bussiness_account.indexOf(item);

      this.bussiness_account.splice(index, 1);
    },

    async getData() {
      let resultEntity = await this.api_Get("accounting/entity_all");

      this.entitys = await resultEntity.json();
    },
    async initialize() {
      // this.getData();
      this.loading = true;
      const resultCurrencies = await this.api_Get("accounting/currencies");
      this.currencies = await resultCurrencies.json();
      this.itemsBanks = await this.g_Bancos();
      this.itemsAccountypes = await this.g_type_account();
      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      const dataTypeAccounts = await resultTypeAccounts.json();
      for (let item of dataTypeAccounts) {
        this.accounts.push({
          text: item.name,
          value: item.id,
          dgii_category_id: item.dgii_category_id,
        });
      }

      let resultTypeContacts = await this.api_Get("entity/types_contacts");
      let dataTypeContacts = await resultTypeContacts.json();
      this.typeContacts = dataTypeContacts;

      let resultDocuments = await this.api_Get("accounting/documents");
      let data = await resultDocuments.json();

      for (let item of data) {
        this.documents.push({ text: item.name, value: item.id });
      }
      let resultCreditDays = await this.api_Get("accounting/credit_days");
      for (let item of await resultCreditDays.json()) {
        this.credit_days.push({ text: item.name, value: item.id });
      }
      let resultWithholdings = await this.api_Get("accounting/withholdings");
      this.withholdings = await resultWithholdings.json();

      let resultTypesNcf = await this.api_Get("accounting/nfc_types");
      for (let item of await resultTypesNcf.json()) {
        this.ncfTypes.push({ text: item.name, value: item.id });
      }
      let reslutprovincies = await this.api_Get("accounting/provincies");
      for (let item of await reslutprovincies.json()) {
        this.provincies.push({ text: item.name, value: item.id });
      }
      this.entityData = this.entitys;
      this.loading = false;
    },
    async searchRnc() {
      const responseRnc = await this.api_Post("accounting/consult_rnc", {
        rnc: this.editedItem.document_id,
      });
      let dataRnc = await responseRnc.json();
      if (dataRnc.length == 0) {
        this.rncValid = true;
        this.$toast.warning("El RNC no es valido");
        return false;
      }
      this.disabledName = true;
      this.rncValid = false;

      this.editedItem.name = dataRnc[0].name;
      return true;
    },
    editItem(item) {
      this.editedIndex = true;

      this.editedItem = Object.assign({}, item);

      this.bussiness_account = [];
      let branches = [];

      if (this.editedItem.entity_branches != null) {
        for (let item of this.editedItem.entity_branches) {
          branches.push(item.supplier);
        }
      }

      this.editedItem.entity_branches = branches;

      this.selectedDocument = this.editedItem.document_id_type_id;
      if (this.editedItem.currency_id != null) {
        let currencies_selected = this.currencies.find(
          (element) => element.id == this.editedItem.currency_id
        );
        this.seleted_currencies = currencies_selected.id;
      }

      if (this.editedItem.entity_contact_json == null) {
        this.itemContacts = [];
      } else {
        this.itemContacts = this.editedItem.entity_contact_json;
      }
      if (this.editedItem.entity_branches == null) {
        this.itemsSucursal = [];
      } else {
        this.itemsSucursal = this.editedItem.entity_branches;
      }
      if (this.editedItem.customer_details == null) {
      } else {
        this.selectedtypesNcfCliente =
          this.editedItem.customer_details[0].customer.nfc_type_id;

        this.selectedCreditDaysCliente =
          this.editedItem.customer_details[0].customer.credit_day_id;
        this.selectedWithholdingsCliente =
          this.editedItem.customer_details[0].customer.withholding_id;
        this.cxc =
          this.editedItem.customer_details[0].customer.account_payable_id;
        this.plazoPagoCliente =
          this.editedItem.customer_details[0].customer.credit_days;
        this.cliente = true;
      }
      if (this.editedItem.supplier_details == null) {
      } else {
        this.selectedtypesNcfSuplidor =
          this.editedItem.supplier_details[0].supplier.nfc_type_id;
        this.selectedCreditDaysSuplidor =
          this.editedItem.supplier_details[0].supplier.credit_day_id;
        this.selectedWithholdingsSuplidor =
          this.editedItem.supplier_details[0].supplier.withholding_id;

        this.cxp =
          this.editedItem.supplier_details[0].supplier.account_receivable_id;

        this.plazoPagoSuplidor =
          this.editedItem.supplier_details[0].supplier.credit_day_id;
        this.suplidor = true;

        for (let item of this.editedItem.supplier_accounts) {
          let banc_text = this.itemsBanks.find(
            (element) => element.bank_id == item.bank_id
          );
          let tipeAcon_text = this.itemsAccountypes.find(
            (element) => element.id == item.account_type_id
          );

          this.bussiness_account.push({
            account_type_id: item.account_type_id,
            account_type_description: tipeAcon_text.description,
            account_bank_id: item.bank_id,
            bank_description_long: banc_text.bank_description_long,
            account_check_digit: item.account,
            is_default: item.is_default,
          });
        }
      }

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.entitys.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.entitys.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.cliente = false;
      this.suplidor = false;
      this.cxc = "";
      this.cxp = "";
      this.plazoPagoCliente = "";
      this.plazoPagoSuplidor = "";
      this.selectedProvincies = "";
      this.descriptionSucursal = "";
      this.addressSucursal = "";
      this.itemsSucursal = [];
      this.itemContacts = [];

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      let suppliers_json = [];
      let customers_json = [];
      let supplier_accounts = [];

      this.bussiness_account.forEach((account) => {
        supplier_accounts.push({
          supplier_id: this.editedItem.entity_id,
          bank_id: account.account_bank_id,
          account: account.account_check_digit,
          account_type_id: account.account_type_id,
          is_default: account.is_default,
        });
      });

      if (this.bussiness_account.length > 0) {
        let isAcccounDefault = this.bussiness_account.some(
          (item) => item.is_default
        );
        if (isAcccounDefault == false) {
          this.$toast.warning(
            "Debes selecionar una cuenta de banco por defecto!"
          );
          return;
        }
      }

      if (this.suplidor == "") {
        let suppliers_json = null;
      } else {
        suppliers_json = {
          account_receivable_id: this.cxp,
          credit_day_id: this.selectedCreditDaysSuplidor,
          nfc_type_id: this.selectedtypesNcfSuplidor,
          withholding_id: this.selectedWithholdingsSuplidor,
          supplier_accounts: supplier_accounts,
        };
      }
      if (this.cliente == "") {
      } else {
        customers_json = {
          account_payable_id: this.cxc,
          credit_day_id: this.selectedCreditDaysCliente,
          nfc_type_id: this.selectedtypesNcfCliente,
          withholding_id: this.selectedWithholdingsCliente,
        };
      }

      if (this.selectedDocument == 1) {
        if (this.searchRnc() == true) {
        }
      }
      let currencies_selected = this.currencies.find(
        (element) => element.id == this.seleted_currencies
      );
      if (this.editedIndex == true) {
        let data = {
          entity_id: this.editedItem.entity_id,
          document_id_type_id: this.selectedDocument,
          document_id: this.editedItem.document_id,
          name: this.editedItem.name.trimStart(),
          contact_json: this.itemContacts,
          is_supplier: this.suplidor,
          is_customer: this.cliente,
          currency_id: currencies_selected.id,
          supplier_details: suppliers_json,
          customer_details: customers_json,
          branch_details: this.itemsSucursal,
        };

        const responseRequest = await this.api_Put("accounting/entity", data);
        if (responseRequest.status == 200) {
          this.$emit("update");
          this.$toast.success("actualizado con exito!");
        } else {
          this.$toast.error("error al agregar!");
        }
      } else {
        const responseRequest = await this.api_Post("accounting/entity", {
          entity_id: null,
          document_id_type_id: this.selectedDocument,
          document_id: this.editedItem.document_id,
          name: this.editedItem.name.trimStart(),
          contact_json: this.itemContacts,
          is_supplier: this.suplidor,
          is_customer: this.cliente,
          currency_id: currencies_selected.id,
          supplier_details: suppliers_json,
          customer_details: customers_json,
          branch_details: this.itemsSucursal,
        });

        if (responseRequest.status == 200) {
          this.$emit("update");
          this.$toast.success("agregado con exito!");
        } else {
          this.$toast.error("error al agregar!");
        }
      }

      this.close();
    },
  },
};
</script>

<style scoped></style>
