<template>
  <v-flex>
    <v-row class="mt-2" justify="center">
      <v-col cols="12" md="12">
        <v-container>
          <v-row justify="start">
            <span class="mx-4" style="font-size: 34px; color: #616161">Categorías</span>
          </v-row>
          <v-row justify="start" class="mt-n1 mx-2">
            <span style="font-size: 16px; color: #757575">Utiliza las categorías para clasificar tus productos
            </span>
          </v-row>
          <v-row justify="start">
            <v-col cols="12">
              <v-data-table :headers="headers" :items="data" sort-by="calories" class="elevation-1">
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Categorias</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer> </v-spacer>
                    <v-text-field class="mt-3" dense label="Buscar.."></v-text-field>

                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="700px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                          <v-icon> add </v-icon>
                          Nueva categoria
                        </v-btn>
                      </template>
                      <v-card height="auto">
                        <v-row no-gutters justify="center">
                          <v-card-title class="text-center">
                            <span class="text-h5 text-center">{{
                              formTitle
                            }}</span>
                          </v-card-title>
                        </v-row>

                        <v-card-text>
                          <v-row justify="center">
                            <v-col cols="12" md="12">
                              <v-container>
                                <v-row justify="center">
                                  <v-col cols="12" md="10">
                                    <v-row no-gutters>
                                      <v-text-field v-model="editedItem.name" label="Nombre" outlined
                                        dense></v-text-field>
                                    </v-row>

                                    <v-row no-gutters>
                                      <v-textarea v-model="editedItem.note" label="Descripcion" outlined
                                        dense></v-textarea>
                                    </v-row>

                                    <v-row dense>
                                      <v-col cols="6">
                                        <v-autocomplete dense v-model="editedItem.item_chart_of_account_id
                                          " :items="accounts" color="cyanText" label="Cuenta contable" outlined
                                          required></v-autocomplete>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-autocomplete dense v-model="editedItem.inventory_chart_of_account_id
                                          " :items="accounts" color="cyanText" label="Cuenta de inventario" outlined
                                          required></v-autocomplete>
                                      </v-col>
                                    </v-row>

                                    <v-row dense>
                                      <v-col cols="6">
                                        <v-autocomplete dense v-model="editedItem.cost_chart_of_account_id
                                          " :items="accounts" color="cyanText" label="Cuenta de costo de venta"
                                          outlined required></v-autocomplete>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <!-- <v-col cols="12" md="4">
                                    <v-hover v-slot="{ hover }">
                                      <v-card
                                        flat
                                        height="200"
                                        @click="takeImage()"
                                        :class="
                                          hover
                                            ? 'grey lighten-3'
                                            : 'grey lighten-4'
                                        "
                                      >
                                        <v-container fill-height fluid>
                                          <v-row
                                            align="center"
                                            justify="center"
                                          >
                                            <v-col
                                              cols="12"
                                              md="12"
                                              align="center"
                                            >
                                              <v-icon
                                                color="grey lighten-2"
                                                x-large
                                                >add_a_photo</v-icon
                                              >
                                            </v-col>
                                          </v-row>
                                        </v-container>
                                      </v-card>
                                    </v-hover>

                                 
                                  </v-col> -->
                                </v-row>
                              </v-container>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-card-actions class="m">
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="close">
                            Cancelar
                          </v-btn>
                          <v-btn outlined color="blue darken-1" @click="save">
                            Guardar
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="600px">
                      <v-card>
                        <v-card-title class="text-h5">Estas seguro que desea eliminar esta
                          letiante?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.options`]="{ item }">
                  <span v-for="(items, i) of item.letiants_details" :key="i">
                    {{ items.letiant.name }} /
                  </span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
                <template v-slot:no-data>
                  <v-row justify="center" class="pt-10">
                    <v-icon size="80px">token</v-icon>
                  </v-row>
                  <v-row justify="center" class="pb-6">
                    No hay datos
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col></v-row>
  </v-flex>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    itemsletiant: [],
    headers: [
      {
        text: "Codigo",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Nombre", value: "name" },
      { text: "Cuenta contable", value: "item_chart_of_account_name" },
      {
        text: "Cuenta de inventario",
        value: "inventory_chart_of_account_name",
      },
      { text: "Cuenta de costo de venta", value: "cost_chart_of_account_name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    data: [],
    accounts: [],

    editedIndex: -1,
    editedItem: {
      name: "",
      item_chart_of_account_id: null,
      inventory_chart_of_account_id: null,
      cost_chart_of_account_id: null,
    },
    defaultItem: {
      name: "",
      item_chart_of_account_id: null,
      inventory_chart_of_account_id: null,
      cost_chart_of_account_id: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva categoria" : "Editar categoria";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      let resultCategories = await this.api_Get("accounting/categories");
      //let dat = await resultCategories.json();
      this.data = await resultCategories.json();
      let resultTypeAccounts = await this.api_Get("accounting/accounts");
      const dataTypeAccounts = await resultTypeAccounts.json();
      for (let item of dataTypeAccounts) {
        this.accounts.push({
          text: item.name,
          value: item.id,
          dgii_category_id: item.dgii_category_id,
        });
      }
    },

    editItem(item) {
      this.itemsletiant = [];
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.data.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        let data = {
          id: this.editedItem.id,
          item_chart_of_account: this.editedItem.item_chart_of_account_id,
          iventory_chart_of_account:
            this.editedItem.inventory_chart_of_account_id,
          cost_chart_of_account: this.editedItem.cost_chart_of_account_id,
          name: this.editedItem.name,
          note: this.editedItem.note,
        };

        const responseRequest = await this.api_Put(
          "accounting/categories",
          data
        );
        if (responseRequest.status == 200) {
          this.initialize();
          this.dialog = false;
        } else {
          this.$toast.error("error al actualizar!");
        }
      } else {
        let data = {
          item_chart_of_account: this.editedItem.item_chart_of_account_id,
          iventory_chart_of_account:
            this.editedItem.inventory_chart_of_account_id,
          cost_chart_of_account: this.editedItem.cost_chart_of_account_id,
          name: this.editedItem.name,
          note: this.editedItem.note,
        };

        const responseRequest = await this.api_Post(
          "accounting/categories",
          data
        );

        if (responseRequest.status == 200) {
          this.initialize();

          // this.$toast.success("agregado con exito!");
          this.dialog = false;
        } else {
          this.$toast.error("error al agregar!");
        }
      }

      this.close();
    },
  },
};
</script>
