<template>
    <v-card width="100%" outlined>

        <apexchart width="500" type="line" :options="options" :series="data"></apexchart>
    </v-card>
</template>
<script>
export default {
    data: () => ({
        data: [
            {
                x: 1994,
                y: 2543763
            },
            {
                x: 1995,
                y: 4486659
            },
            {
                x: 1996,
                y: 7758386
            },
            {
                x: 1997,
                y: 12099221
            },
            {
                x: 1998,
                y: 18850762
            },
            {
                x: 1999,
                y: 28153765
            },
            {
                x: 2000,
                y: 41479495
            },
            {
                x: 2001,
                y: 50229224
            },
            {
                x: 2002,
                y: 66506501
            },
            {
                x: 2003,
                y: 78143598
            },
            {
                x: 2004,
                y: 91332777
            },
            {
                x: 2005,
                y: 103010128
            },
            {
                x: 2006,
                y: 116291681
            },
            {
                x: 2007,
                y: 137322698
            },
            {
                x: 2008,
                y: 157506752
            },
            {
                x: 2009,
                y: 176640381
            },
            {
                x: 2010,
                y: 202320297
            },
            {
                x: 2011,
                y: 223195735
            },
            {
                x: 2012,
                y: 249473624
            },
            {
                x: 2013,
                y: 272842810
            },
            {
                x: 2014,
                y: 295638556
            },
            {
                x: 2015,
                y: 318599615
            },
            {
                x: 2016,
                y: 342497123
            }
        ],
        options: {
            chart: {
                height: 350,
                type: "line",
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "straight"
            },
            series: [
                {
                    name: "Logarithmic",
                    data: this.data
                },
                {
                    name: "Linear",
                    data: this.data
                }
            ],

            title: {
                text: "Logarithmic Scale",
                align: "left"
            },
            markers: {
                size: 0
            },
            xaxis: {
                type: "datetime"
            },
            yaxis: [
                {
                    min: 1000000,
                    max: 500000000,
                    tickAmount: 4,
                    logarithmic: true,
                    seriesName: "Logarithmic",
                    labels: {
                        formatter: labelFormatter,
                    }
                },
                {
                    min: 1000000,
                    max: 500000000,
                    opposite: true,
                    tickAmount: 4,
                    seriesName: "Linear",
                    labels: {
                        formatter: labelFormatter
                    }
                }
            ]
        },
        series: [
            {
                name: "series-1",
                data: [],
            },
        ],

    }),
    props: {
        title_header: {
            type: String
        },
        data: {
            type: Object,
        },

    },
    methods: {
        labelFormatter(value) {
            let val = Math.abs(value);
            if (val >= 1000000) {
                val = (val / 1000000).toFixed(1) + " M";
            }
            return val;
        }
    }
    // watch: {
    //     data: {
    //         handler: function (nuevoValor, viejoValor) {
    //             // Lógica para manejar el cambio del objeto filters

    //              this.options.xaxis.categories = nuevoValor.label
    //              this.series[0].data = nuevoValor.series
    //              this.options.title.text=this.title_header
    //         },
    //         deep: true, // Para obserlet cambios profundos en el objeto filters
    //         immediate: true // Para ejecutar el watch inmediatamente al crear el componente
    //     }
    // },

};
</script>