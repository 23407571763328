<template>
  <v-container>

    <v-btn-toggle v-model="typeForm" tile color="primary" group>
      <v-btn> Cobros </v-btn>

      <v-btn> Listado de cobro </v-btn>
    </v-btn-toggle>
    <v-row class="mt-2" justify-center> </v-row>
    <v-stepper class="mt-2" v-model="typeForm">
      <v-row justify="center" class="mt-1 mx-1 mb-2">
        <v-icon class="mx-2" size="35px">receipt_long</v-icon>

        <span style="font-size: 34px; color: #616161">Cuentas por cobrar</span>
      </v-row>
      <v-row justify="center" class="mb-1">
        <v-col cols="12" md="6">
          <v-row justify="center">
            <search-bussines-locations @filter_loaded="changeBussines($event)" :type="false" />
          </v-row>
        </v-col>
      </v-row>

      <v-stepper-items>
        <v-stepper-content step="0">
          <v-row justify="center">
            <v-col cols="12">
              <v-row v-if="hasData === true" no-gutters justify="start">
                <v-card outlined elevation="1" width="100%" height="100px">
                  <v-layout fill-height align-center justify-center>
                    <v-col cols="2.4">
                      <v-row justify="center">
                        <span class="subtitle-1">Cantidad Facturas</span></v-row>
                      <br />
                      <v-row justify="center">
                        <span class="font-weight-bold" style="font-size: 24px; color: #58586a">{{
                          clients.reduce(
                            (sum, value) =>
                              sum + parseFloat(value.invoice_quantity),
                            0
                          )
                        }}
                        </span></v-row>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="2.4">
                      <v-row justify="center">
                        <span class="subtitle-1">Por cobrar</span></v-row>
                      <br />
                      <v-row justify="center">
                        <span class="font-weight-bold" style="font-size: 24px; color: #e85e48">
                          {{
                            currencyFormat(
                              clients.reduce(
                                (sum, value) =>
                                  sum + parseFloat(value.total_pending_amount),
                                0
                              )
                            )
                          }}
                        </span></v-row>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="2.4">
                      <v-row justify="center">
                        <span class="subtitle-1">Notas de crédito </span></v-row>
                      <br />
                      <v-row justify="center">
                        <span class="font-weight-bold" style="font-size: 24px; color: #e85e48">
                          {{
                            currencyFormat(
                              clients.reduce(
                                (sum, value) =>
                                  sum + parseFloat(value.total_credit_amount),
                                0
                              )
                            )
                          }}
                        </span></v-row></v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="2.4">
                      <v-row justify="center">
                        <span class="subtitle-1">Cobrado </span>
                      </v-row>
                      <br />
                      <v-row justify="center">
                        <span class="font-weight-bold" style="font-size: 24px; color: #00c6b8">
                          {{
                            currencyFormat(
                              clients.reduce(
                                (sum, value) =>
                                  sum + parseFloat(value.total_paid_amount),
                                0
                              )
                            )
                          }}
                        </span></v-row></v-col>
                  </v-layout>
                </v-card>
              </v-row>
              <v-card class="rounded-lg mt-4">
                <v-data-table v-if="hasData === true" :search="search" :headers="headers" :items="clients"
                  class="elevation-0">
                  <template v-slot:top>
                    <v-toolbar class="elevation-0 rounded-lg">
                      <v-toolbar-title>Clientes</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.total_pending_amount`]="{ item }">
                    <!-- <v-chip class="ma-2" text-color="white" color="red" outlined > -->
                    <span style="color: red">
                      {{ currencyFormat(item.total_pending_amount) }}</span>
                    <!-- </v-chip> -->
                  </template>
                  <template v-slot:[`item.total_credit_amount`]="{ item }">
                    <span> {{ currencyFormat(item.total_credit_amount) }}</span>
                  </template>
                  <template v-slot:[`item.total_paid_amount`]="{ item }">
                    <span style="color: green">
                      {{ currencyFormat(item.total_paid_amount) }}</span>
                  </template>

                  <template v-slot:[`item.entity_name`]="{ item }">
                    <v-hover v-slot="{ hover }">
                      <span @click="viewEntity(item.entity_id, true)" style="font-size: 14px"
                        class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                          color: hover ? 'red' : 'black',
                          cursor: hover ? 'pointer' : '',
                          'text-decoration': hover ? 'underline' : '',
                        }">{{ item.entity_name }}</span></v-hover>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="viewInvoices(item)" :type="true" color="success" class="mx-2 mt-n2" v-bind="attrs"
                          v-on="on">
                          payments
                        </v-icon>
                      </template>
                      <span>Agregar pago</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:no-data>
                    <v-row justify="center" class="pt-10">
                      <v-icon size="80px">token</v-icon>
                    </v-row>
                    <v-row justify="center" class="pb-6"> No hay datos </v-row>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="1">
          <v-row v-if="loading" justify="center">
            <v-col cols="12" md="10">
              <v-skeleton-loader type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions">
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <v-data-table v-else :search="search" :headers="headersTransactions" :items="transactions" class="elevation-0">
            <template v-slot:top>
              <v-toolbar class="elevation-0 rounded-lg">
                <v-toolbar-title>Pagos</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template v-slot:[`item.invoice_payment_id`]="{ item }">
              <v-hover v-slot="{ hover }">
                <span @click="showPayments(item.invoice_payment_id,1)"
                  class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                    color: hover ? 'red' : 'black',
                    cursor: hover ? 'pointer' : '',
                    'text-decoration': hover ? 'underline' : '',
                  }">
                  {{ filLeft(item.invoice_payment_id.toString(), 12, "0") }}
                </span>
              </v-hover>
            </template>
            <template v-slot:[`item.entity`]="{ item }">
      
              <span> {{ item.payments_details[0].entity_name }}</span> 
            </template>

            <template v-slot:[`item.total_amount`]="{ item }">
              <span>
                {{ currencyFormat(item.total_amount) }}
                {{ item.currency_code }}</span>
            </template>
            <template v-slot:[`item.status_id`]="{ item }">
              <v-chip small outlined color="success" v-if="item.status_id == 1">
                Pagado
              </v-chip>
              <v-chip small outlined color="red" v-else> Anulado </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon v-if="item.status_id == 1" @click="(dialogCancelPayment = true), (selectPayments = item)"
                color="red" class="mx-2 mt-n2">
                cancel
              </v-icon>
            </template>
          </v-data-table>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog transition="slide-x-transition" v-model="dialog" fullscreen>
      <v-card style="background-color: #f4f5fa">
        <v-toolbar flat dark color="transparent">
          <!-- <v-btn @click="dialog=false" icon dark >
                                                <v-icon color="black">mdi-close</v-icon>
                                            </v-btn> -->
          <!-- <v-toolbar-title v-if="dialog">Cuentas por cobrar ( {{ selectedClients[0].entity_name }} ) </v-toolbar-title>
                                            <v-spacer></v-spacer> -->
        </v-toolbar>
        <v-container>
          <v-row  class="mt-n12" justify="end" v-if="dialog">
            <v-btn icon dark @click="dialog=false">
                        <v-icon color="black">mdi-close</v-icon>
                      </v-btn>
          </v-row>
          <v-row justify="center" class="mt-n12" v-if="dialog">
            <span class="text-h4">
              Cuentas por cobrar ( {{ selectedClients[0].entity_name }} )
            </span>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="11">
              <viewInvoices v-if="!laoded" @close="reset()" :data_invoices="selectedClients" :filters="filters" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCancelPayment" transition="dialog-top-transition" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>Anular pago</v-toolbar>
        <v-card-text class="text-center">
          <div class="text-h5 pa-5 text-center">
            Seguro que deseas eliminar este pago?
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="dialogCancelPayment = false">Volver</v-btn>
          <v-btn outlined @click="cancelPayment">Anular pago</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
//import Filters from "@/components/FilterBussinesLocations";
import viewInvoices from "@/components/Transactions/ViewInvoicesCxC.vue";

export default {
  components: { viewInvoices },
  data: () => ({
    loading: false,
    dialogCancelPayment: false,
    e1: 1,
    filters:{},
    typeForm: 0,
    laoded: true,
    dialog: false,
    search: "",
    clients: [],
    hasData: false,
    headers: [
      { text: "Cliente", value: "entity_name", align: "start" },
      { text: "Cant facturas", value: "invoice_quantity", align: "end" },
      {
        text: "Balance pendiente",
        value: "total_pending_amount",
        align: "end",
      },
      { text: "Notas de credito", value: "total_credit_amount", align: "end" },
      { text: "Total Pagado", value: "total_paid_amount", align: "end" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    transactions: [],
    headersTransactions: [
      {
        text: "Numero pago",
        align: "start",
        sortable: false,
        value: "invoice_payment_id",
      },
      {
        text: "Cliente",
        value: "entity",
      },
      {
        text: "Usuario",
        value: "payment_by_name",
      },
      {
        text: "Monto total",
        align: "end",
        value: "total_amount",
      },
      {
        text: "Status",
        value: "status_id",
      },
      { text: "Actions", value: "actions", sortable: false },
      // {
      //     text: "Balance pendiente",
      //     align: "end",

      //     value: "total_pending_amount",
      // },

      // { text: "Total pagado", value: "total_paid_amount", align: "end" },
    ],
    selectPayments: null,
    lastData: null,
    selectedClients: [],
    editedIndex: -1,
    editedItem: [],
  }),

  computed: {},

  watch: {
    async typeForm(val) {
      if (val == 1) {
        await this.getPayments();
      }
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async getData(data) {
      // this.clients=[]
      this.laoded = false;
      this.filters=data
      let filter = "WHERE invoice_type_id=2 ";
      filter +=
        data.business == null ? "" : " AND business_unit_id=" + data.business;
      filter +=
        data.location == null ||    data.location ==0? "" : " AND location_id=" + data.location;
      let resultclients = await this.api_Post(
        "transactions/customers_pending_balances",
        { filters: filter }
      );
      if (resultclients.status == 200) {
        this.clients = [];
        this.clients = await resultclients.json();
      }
      this.laoded = true;
    },
    async getPayments(datas) {
      this.loading = true;
      let filter = " where invoice_type_id=2 ";
      let data = {};
      if (datas != undefined) {
        data = datas;
      } else {
        data = this.lastData;
      }

      if (data != null) {
        filter +=
          data.business == null ? "" : "AND business_unit_id=" + data.business;
        filter +=
          data.location == null ? "" : " AND location_id=" + data.location;
      }

      let resultpayments = await this.api_Post(
        "transactions/viewAllPayemnts",
        { filters: filter }
      );
      if (resultpayments.status == 200) {
        this.transactions = [];
        this.transactions = await resultpayments.json();
      }
      this.loading = false;
    },
    async cancelPayment() {
      let result = await this.api_Post("transactions/cancel_payment", {
        id_payments: this.selectPayments.invoice_payment_id,
      });
      if (result.status == 200) {
        this.$toast.success("Pago anulado");
        this.dialogCancelPayment = false;
        this.getPayments();
      }
    },
    async reset() {
      this.laoded = true;
      this.laoded = false;
      await this.changeBussines(this.lastData);
      this.dialog = false;
    },
    async changeBussines(data) {
      if (data.business == 0 || data.business == null) {
        this.hasData = false;
      } else {
        this.hasData = true;
        if (this.typeForm === 0) {
          await this.getData(data);
        } else {
          await this.getPayments(data);
        }
        this.lastData = data;
      }
    },
    async viewInvoices(item) {
      //  console.log(item)
      let data = {
        entity_id: item.entity_id,
        busines_unit: item.business_unit_id,
      };
      let responseRequest = await this.api_Post(
        "transactions/invoice_sales",
        data
      );

      if (responseRequest.status == 200) {
        this.laoded = true;
        this.laoded = false;
        this.selectedClients = await responseRequest.json();
        this.dialog = true;
      }

      //console.log(item)
    },
    async initialize() { },

    editItem(item) {
      this.editedIndex = this.entitys.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.entitys.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.entitys.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save(item) {
      const responseRequest = await this.api_Post(
        "income/invoice_register",
        item
      );

      if (responseRequest.status == 200) {
        this.getData();

        let data = await responseRequest.json();

        let route = this.$router.resolve({
          name: "ViewEntitys",
          params: { id: data[0].id },
        });
        window.open(route.href);
        // this.$toast.success("agregado con exito!");
        this.dialog = false;
      } else {
        this.$toast.error("error al registrar la factura!");
      }
    },
  },
};
</script>

<style></style>
