<template>
    <v-container>
        <!-- <v-row> <invoice /> </v-row> -->
        <v-row justify="center">
            <v-col cols="12">
                <v-row justify="center" class="mt-4 mx-1 mb-2">
                    <v-icon class="mx-2" size="35px">receipt_long</v-icon>

                    <span style="font-size: 34px; color: #616161">Factura provedores</span>
                </v-row>
                <v-row justify="center" class="mb-1">
                    <v-col cols="12" md="10">
                        <v-row justify="center">
                            <search-header @dataFilters="changeBussines($event)" :type="false" />
                        </v-row>
                    </v-col>


                </v-row>
                <v-card class="rounded-lg">
                    <v-data-table :search="search" :headers="headers" :items="invoices" class="elevation-0">
                        <template v-slot:top>
                            <v-toolbar class="elevation-0 rounded-lg">
                                <v-toolbar-title>Facturas</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
                                <v-spacer></v-spacer>
                                <v-dialog transition="slide-x-transition" v-model="dialog" fullscreen>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                            <v-icon small>add</v-icon>
                                            Nueva factura
                                        </v-btn>
                                    </template>
                                    <v-card style="background-color: #f4f5fa">
                                        <v-toolbar flat dark color="transparent">


                                            <v-spacer></v-spacer>
                                        </v-toolbar>
                                        <v-container>
                                            <v-row justify="center">
                                                <v-col cols="12" md="11">
                                                    <invoice :type="1" @close-dialog="dialog = false" :itbis="itbis"
                                                        @save-invoice="save($event)" />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        <v-card-text>
                                            <v-row> </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialogDelete" max-width="500px">
                                    <v-card>
                                        <v-card-title class="text-h5">Are you sure you want to delete this
                                            item?</v-card-title>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.invoice_number`]="{ item }">
                            <v-hover v-slot="{ hover }">
                                <span @click="showInvoice(item)"
                                    class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                                        color: hover ? 'red' : 'black',
                                        cursor: hover ? 'pointer' : '',
                                        'text-decoration': hover ? 'underline' : '',
                                    }">
                                    {{ item.invoice_number }}</span>
                            </v-hover>
                        </template>
                        <template v-slot:[`item.total_amount`]="{ item }">
                            <span>{{ item.currency_code }}
                                {{ currencyFormat(item.total_amount) }}</span>
                        </template>
                        <template v-slot:[`item.entity_name`]="{ item }">
                            <v-hover v-slot="{ hover }">
                                <span @click="viewEntity(item.entity_id)" style="font-size: 14px"
                                    class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                                        color: hover ? 'red' : 'black',
                                        cursor: hover ? 'pointer' : '',
                                        'text-decoration': hover ? 'underline' : '',
                                    }">{{ item.entity_name }}</span></v-hover>
                        </template>

                        <!-- <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template> -->
                        <template v-slot:no-data>
                            <v-row justify="center" class="pt-10">
                                <v-icon size="80px">token</v-icon>
                            </v-row>
                            <v-row justify="center" class="pb-6">
                                No hay datos
                            </v-row>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import invoice from "../../components/Income/Invoice.vue";
export default {
    components: { invoice },
    data: () => ({
        search: "",
        itbis:[],
        dialog: false,
        dialogDelete: false,
        suplidor: false,
        cliente: false,
        disabledName: false,
        plazoPagoSuplidor: null,
        plazoPagoCliente: null,
        documents: [],
        rncValid: false,
        ncfTypes: [],
        accounts: [],
        provincies: [],
        invoices: [],
        cxc: "",
        cxp: "",
        selectedDocument: "",
        selectedtypesNcfCliente: "",
        selectedtypesNcfSuplidor: "",
        selectedProvincies: "",
        headers: [
            {
                text: "Factura",
                align: "start",

                value: "invoice_number",
            },
            { text: "Unidad negocio", value: "business_unit_description" },
            { text: "Location", value: "location_description" },
            { text: "Cliente", value: "entity_name" },
            { text: "Total", value: "total_amount", align: "end" },
            { text: "Status", value: "sta_description" },

            //{ text: "Actions", value: "actions", sortable: false },
        ],

        editedIndex: -1,
        editedItem: [],
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "Nuevo contacto" : "Editar contacto";
        },
    },

    watch: {},

    created() {
        this.initialize();
    },

    methods: {
        async getData(data) {
            let filters = ""
            filters += data.business == null ? " " : " AND  BUSINESS_UNIT_ID in (" + data.business + ") "
            filters += data.location == null || data.location == 0 ? "" : " AND LOCATION_ID IN (" + data.location + ")"
            filters += data.department_id == null || data.department_id == 0 ? "" : " AND DEPARTMENT_ID =" + data.department_id + ""
            filters +=
                " AND EMISSION_DATE::DATE BETWEEN '" +
                data.dateStart +
                "' and '" +
                data.dateEnd +
                "' ";
            let resultInvoices = await this.api_Post("shopping/invoice_shop", { filters: filters });

            this.invoices = await resultInvoices.json();
            //   console.log(this.invoices);
        },

        async initialize() {
            let resultItbis = await this.api_Get("accounting/taxes");
      this.itbis = await resultItbis.json();
            // this.getData();
            //   let resultTypeAccounts = await this.api_Get("accounting/accounts");
            //   const dataTypeAccounts = await resultTypeAccounts.json();
            //   for (let item of dataTypeAccounts) {
            //     this.accounts.push({
            //       text: item.name,
            //       value: item.id,
            //       dgii_category_id: item.dgii_category_id,
            //     });
            //   }

            //   let resultDocuments = await this.api_Get("accounting/documents");
            //   let data = await resultDocuments.json();

            //   for (let item of data) {
            //     this.documents.push({ text: item.name, value: item.id });
            //   }
            //   let resultTypesNcf = await this.api_Get("accounting/nfc_types");
            //   for (let item of await resultTypesNcf.json()) {
            //     this.ncfTypes.push({ text: item.name, value: item.id });
            //   }
            //   let reslutprovincies = await this.api_Get("accounting/provincies");
            //   for (let item of await reslutprovincies.json()) {
            //     this.provincies.push({ text: item.name, value: item.id });
            //   }
        },
        changeBussines(data) {

            if (data.business == 0) {
                this.getData({ business: this.$store.state.auth.user.acess_business_unit_finance, location: null, dateStart: data.dateStart, dateEnd: data.dateEnd });
            }
            else {
                this.getData(data);
            }
            //   this.getData(data.data)
            // if(data.data.busines)
        },
        editItem(item) {
            this.editedIndex = this.entitys.indexOf(item);
            this.editedItem = Object.assign({}, item);

            this.dialog = true;
        },

        deleteItem(item) {
            this.editedIndex = this.entitys.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm() {
            this.entitys.splice(this.editedIndex, 1);
            this.closeDelete();
        },

        close() {
            this.dialog = false;

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        async save(item) {
            const responseRequest = await this.api_Post(
                "income/invoice_register",
                item
            );

            if (responseRequest.status == 200) {
                this.getData();

                let data = await responseRequest.json();

                this.$router.push({
                    name: "ViewInvoice",
                    params: { id: btoa(data[0].invoice_number) },
                });

                // this.$toast.success("agregado con exito!");
                this.dialog = false;
            } else {
                this.$toast.error("error al registrar la factura!");
            }
        },
    },
};
</script>
  
<style></style>
  