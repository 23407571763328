<template>
  <v-dialog v-model="showDialog" max-width="70%" persistent>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <span class="headline">Nuevo anticipo</span>
        <v-spacer></v-spacer>
        <v-menu bottom left>
          <template v-slot:activator="{ }">
            <v-btn icon @click="close">
              <v-icon>clear</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters justify="center">
          <!-- <v-card class="folded_corner" style="" width="90%" min-height="700px"> -->
          <div class="folded_corner">
            <v-row>
              <v-divider class="mr-10 mt-2 mb-4" inset></v-divider>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="3" sm="12">
                <v-autocomplete v-model="newPayment.business_unit_id" :items="$store.state.auth.bussines_units"
                  label="Unidad de negocio" color="cyanText" outlined required>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-autocomplete v-model="newPayment.location_id" :items="$store.state.auth.locations.filter(
                  (element) => element.bus_id == newPayment.business_unit_id
                )
                  " color="cyanText" label="Location" outlined required clearable>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-autocomplete v-model="newPayment.department" :items="itemsDepartment" return-object color="cyanText"
                  label="Departamento" outlined required clearable>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="6">
                <v-row no-gutters>
                  <v-col cols="12" md="10">
                    <v-text-field dense outlined readonly label="Cliente" v-model="entity.name">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-n4" no-gutters>
                  <v-col cols="12" md="10">
                    <v-text-field dense outlined readonly label="RNC o Cedula" v-model="entity.document_id">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-n4" no-gutters>
                  <v-col cols="12" md="10">
                    <v-text-field dense outlined readonly label="Telefono" v-model="entity.phone_number">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row no-gutters justify="end">
                  <v-col cols="12" md="8">
                    <v-menu :close-on-content-click="false" :nudge-right="10" transition="scale-transition" offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field dense outlined label="Fecha" v-model="paymentDateFormat"
                          prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-n3" justify="end">
                  <v-col cols="12" md="8">
                    <v-autocomplete dense v-model="newPayment.entity_payment_type" :items="entity_Payment_types"
                      color="cyanText" prepend-inner-icon="checklist" item-text="name" item-value="id" return-object
                      label="Tipo de pago" outlined required></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-n3" justify="end">
                  <v-col cols="12" md="8">
                    <v-autocomplete dense v-model="newPayment.invoice_payment_type" :items="paymentsType" outlined
                      item-text="name" item-value="id" color="cyanText" label="Metodo de pago"
                      prepend-inner-icon="mdi-card-account-details-outline" required return-object></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-n3" justify="end">
                  <v-col cols="12" md="8">
                    <v-autocomplete dense v-model="newPayment.currency" :items="currencies" color="cyanText"
                      prepend-inner-icon="attach_money" item-text="description" item-value="id" return-object
                      label="Moneda" outlined required></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-n3" justify="end">
                  <v-col cols="12" md="10">
                    <v-text-field dense outlined label="Monto" v-model="newPayment.amount" type="number">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="my-3"></div>
            <v-divider class="mx-8"></v-divider>
            <div class="my-3"></div>
            <v-row class="mt-0 mb-4" align="center" justify="space-around">
              <v-spacer></v-spacer>
              <v-btn class="mx-3" color="error" @click="close" text>
                Cancelar
              </v-btn>

              <v-btn :loading="loading" color="success" @click="save()" text>
                Registrar anticipo
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>
          </div>
          <!-- </v-card> -->
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({
    menuDate: false,
    itemsDepartment: [],
    paymentsType: [],
    currencies: [],
    entity_Payment_types: [],
    newPayment: {
      business_unit_id: "",
      location_id: "",
      department: "",
      invoice_payment_type: "",
      currency: "",
      payment_date: moment().format("YYYY-MM-DD"),
      payment_by: "",
      amount: "",
      consumed: "",
      entity_payment_type: "",
    },
    loading: false,
  }),
  name: "Advance",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Object,
      default() {
        return {
          entity_id: "",
          document_id: "",
          name: "",
          email: "",
          phone_number: "",
        };
      },
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    paymentDateFormat() {
      return moment(this.newPayment.payment_date).format("DD/MM/YYYY");
    },
  },
  watch: {},
  methods: {
    async initialize() {
      this.itemsDepartment = await this.g_departments();
      const resultTypePayments = await this.api_Get("income/payments_types");
      this.paymentsType = await resultTypePayments.json();
      const resultCurrencies = await this.api_Get("accounting/currencies");
      this.currencies = await resultCurrencies.json();
      const entity_Payment_types = await this.api_Get(
        "income/entity_Payment_types"
      );
      this.entity_Payment_types = await entity_Payment_types.json();
      this.newPayment.entity_payment_type = this.entity_Payment_types[0];
    },
    async save() {
      if (Number.isInteger(this.newPayment.business_unit_id) == false) {
        this.$toast.warning("Indique la unidad de negocios!");
        return;
      }
      if (Number.isInteger(this.newPayment.location_id) == false) {
             this.$toast.warning("Indique el location!");
        return;
      }
      if (Number.isInteger(this.newPayment.department.value) == false) {
             this.$toast.warning("Indique el departamento!");
        return;
      }
      if (Number.isInteger(this.newPayment.currency.id) == false) {
             this.$toast.warning("Indique la moneda!");
        return;
      }
      if (Number.isInteger(this.newPayment.invoice_payment_type.id) == false) {
             this.$toast.warning("Indique el metodo de pago!");
        return;
      }
      if (this.newPayment.amount == 0) {
             this.$toast.warning("Indique el monto!");
        return;
      }
      this.loading = true;
      try {
        let dataPayment = {
          business_unit_id: this.newPayment.business_unit_id,
          location_id: this.newPayment.location_id,
          department_id: this.newPayment.department.value,
          entity_id: this.entity.entity_id,
          invoice_payment_type_id: this.newPayment.invoice_payment_type.id,
          currency_id: this.newPayment.currency.id,
          payment_date: this.newPayment.payment_date,
          payment_by: this.$store.state.auth.user.user.user_id,
          amount: this.newPayment.amount,
          entity_payment_type_id: this.newPayment.entity_payment_type.id,
        };

        const request = await this.api_Post(
          "income/entityPayment",
          dataPayment
        );
        if (request.status == 200) {
          this.$toast.success("Pago registrado con exito!");
          this.clear();
          this.loading = false;
          this.$emit("closeDialog", true);
        }
      } catch (error) {
        // console.log(error);
        this.loading = false;
      }
    },
    close() {
      this.$emit("closeDialog", false);
    },
    clear() {
      this.newPayment = {
        business_unit_id: "",
        location_id: "",
        department: "",
        invoice_payment_type: "",
        currency: "",
        payment_date: moment().format("YYYY-MM-DD"),
        payment_by: "",
        amount: "",
        consumed: "",
        entity_payment_type: "",
      };
    },
  },
  components: {},
};
</script>
