<template>
    <v-layout align-start>
        <v-flex>
            <v-card outlined>
                <v-row no-gutters justify="center">
                    <span style="font-size: 20px; padding-top: 1rem " class="mb-2">
                        Menu <v-icon>restaurant_menu</v-icon>
                    </span>
                </v-row>
                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12" sm="3" md="3" class="pt-0 pb-0">
                            <v-autocomplete dense v-model="filters.business_unit_id"
                                :items="$store.state.auth.bussines_units" label="Unidad de negocio" color="cyanText"
                                prepend-inner-icon="corporate_fare" outlined required></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" md="3" class="pt-0 pb-0">
                            <v-autocomplete dense v-model="filters.location_id" :items="$store.state.auth.locations.filter(
                                (element) => element.bus_id == filters.business_unit_id
                            )
                                " color="cyanText" label="Location" prepend-inner-icon="my_location" outlined required
                                clearable>
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="1" md="1" class="pt-0 pb-0">
                            <v-row class="pt-2" justify="center" no-gutters>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="primary" class="mr-2" size="40px" v-bind="attrs" v-on="on"
                                            @click="loadMenus()">
                                            search
                                        </v-icon>
                                    </template>
                                    <span>Buscar menu con estos parametros</span>
                                </v-tooltip>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-card outlined class="mt-4 pt-3">
                        <v-card-text>
                            <v-data-table :headers="headerMenu" :search="search" :items="menus">
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-toolbar-title>MENU</v-toolbar-title>
                                        <v-divider class="mx-4" inset vertical></v-divider>
                                        <span>{{ menus.length }}</span>
                                        <v-spacer></v-spacer>
                                        <v-text-field class="text-xs-center" v-model="search" append-icon="search"
                                            label="Buscar" single-line hide-details>
                                        </v-text-field>
                                        <v-spacer></v-spacer>
                                        <v-divider class="mx-3" inset vertical></v-divider>
                                        <v-dialog hide-overlay transition="dialog-bottom-transition" v-model="dialog"
                                            fullscreen>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                    <v-icon>add</v-icon>
                                                    Nuevo menu
                                                </v-btn>
                                            </template>

                                            <v-card color="#F4F5FB">
                                                <v-toolbar color="#F4F5FB" elevation="0">
                                                    <v-spacer></v-spacer>
                                                    <v-btn icon dark @click="close">
                                                        <v-icon color="black">mdi-close</v-icon>
                                                    </v-btn>
                                                    <v-toolbar-title></v-toolbar-title>
                                                </v-toolbar>
                                                <v-container fluid>
                                                    <v-row justify="center" class="mt-n15">
                                                        <v-col cols="12" sm="3" md="3">
                                                            <v-autocomplete dense outlined
                                                                v-model="editedItem.in_business_unit_id"
                                                                :items="itemsBussines" label="Unidad de negocio"
                                                                color="cyanText" @change="
                                                                    (editedItem.in_menu_details = []),
                                                                    (itemsLocations2 = updateCombo(
                                                                        itemsLocations,
                                                                        itemsLocations2,
                                                                        editedItem.in_business_unit_id,
                                                                        'location'
                                                                    ))
                                                                    " :readonly="editedIndex != -1"
                                                                prepend-inner-icon="corporate_fare"
                                                                required></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" sm="3" md="3">
                                                            <v-autocomplete dense outlined
                                                                v-model="editedItem.in_location_id" :items="itemsLocations2"
                                                                :readonly="editedIndex != -1" @change="
                                                                    loadTypes(), loadCatalogue()
                                                                    " color="cyanText" label="Location"
                                                                prepend-inner-icon="my_location" required></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" sm="3" md="3">
                                                            <v-text-field dense outlined v-model="editedItem.in_name"
                                                                label="Nombre" required></v-text-field> </v-col>
                                                    </v-row>
                                                    <v-row justify="center" class="mt-n9">
                                                        <v-col cols="12" md="2">
                                                            <v-menu v-model="menu" :close-on-content-click="false"
                                                                :nudge-right="10" transition="scale-transition" offset-y
                                                                min-width="auto">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field class="mx-8" dense outlined
                                                                        v-model="editedItem.in_from_date" label="Desde"
                                                                        prepend-inner-icon="mdi-calendar" readonly
                                                                        v-bind="attrs" v-on="on"></v-text-field>
                                                                </template>
                                                                <v-date-picker v-model="editedItem.in_from_date"
                                                                    @input="menu = false"></v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                        <v-col cols="12" md="2">
                                                            <v-menu v-model="menu2" :close-on-content-click="false"
                                                                :nudge-right="10" transition="scale-transition" offset-y
                                                                min-width="auto">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field class="mx-8" dense outlined
                                                                        v-model="editedItem.in_to_date" label="Hasta"
                                                                        prepend-inner-icon="mdi-calendar" readonly
                                                                        v-bind="attrs" v-on="on"></v-text-field>
                                                                </template>
                                                                <v-date-picker v-model="editedItem.in_to_date"
                                                                    @input="menu2 = false"></v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                        <v-col cols="12" md="2">

                                                            <v-menu ref="menu3" v-model="menu3"
                                                                :close-on-content-click="false" :nudge-right="40"
                                                                :return-value.sync="editedItem.in_from_time"
                                                                transition="scale-transition" offset-y max-width="290px"
                                                                min-width="290px">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field v-model="editedItem.in_from_time"
                                                                        label="Hora inicial" dense outlined
                                                                        prepend-inner-icon="mdi-clock-time-four-outline"
                                                                        readonly v-bind="attrs" v-on="on"></v-text-field>
                                                                </template>
                                                                <v-time-picker v-if="menu3" format="ampm"
                                                                    v-model="editedItem.in_from_time" full-width
                                                                    @click:minute="$refs.menu3.save(editedItem.in_from_time)"></v-time-picker>
                                                            </v-menu>

                                                        </v-col>
                                                        <v-col cols="12" md="2">

                                                            <v-menu ref="menu4" v-model="menu4"
                                                                :close-on-content-click="false" :nudge-right="40"
                                                                :return-value.sync="editedItem.in_to_time"
                                                                transition="scale-transition" offset-y max-width="290px"
                                                                min-width="290px">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field v-model="editedItem.in_to_time"
                                                                        label="Hora final" dense outlined
                                                                        prepend-inner-icon="mdi-clock-time-four-outline"
                                                                        readonly v-bind="attrs" v-on="on"></v-text-field>
                                                                </template>
                                                                <v-time-picker v-if="menu4" format="ampm"
                                                                    v-model="editedItem.in_to_time" full-width
                                                                    @click:minute="$refs.menu4.save(editedItem.in_to_time)"></v-time-picker>
                                                            </v-menu>

                                                        </v-col>
                                                    </v-row>

                                                    <v-container fluid class="mt-n12">
                                                        <v-row justify="center">
                                                            <v-col cols="12" md="5">
                                                                <v-row justify="center">
                                                                    <v-col cols="12">
                                                                        <ArticleFilters :items_catalogue="true"
                                                                            :catalogues_id="selected_catalogue"
                                                                            @getItemToEdit="addItem($event)"
                                                                            :showButonNewArticle="false"
                                                                            icon="arrow_right_alt" name="Agregar" />
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col cols="12" md="7">
                                                                <v-data-table :headers="headersItemSelected"
                                                                    :items="editedItem.in_menu_details" :search="search"
                                                                    :items-per-page="8" dense class="elevation-1 mt-5"
                                                                    style="height: 68vh;">
                                                                    <template v-slot:top>
                                                                        <v-toolbar flat>
                                                                            <v-toolbar-title>Articulos
                                                                                selecionados</v-toolbar-title>
                                                                            <v-divider class="mx-4" inset
                                                                                vertical></v-divider>
                                                                            <span>{{ editedItem.in_menu_details.length
                                                                            }}</span>

                                                                            <v-spacer></v-spacer>
                                                                            <v-text-field class="text-xs-center"
                                                                                v-model="search" append-icon="search"
                                                                                label="Buscar" single-line hide-details>
                                                                            </v-text-field>

                                                                            <v-spacer></v-spacer>
                                                                        </v-toolbar>
                                                                    </template>

                                                                    <template v-slot:[`item.categories_id`]="{ item }">

                                                                        <v-autocomplete outlined dense
                                                                            style="font-size: 0.8vw"
                                                                            v-model="item.categories_id" :items="types"
                                                                            label="Categoria" item-text="name" multiple
                                                                            item-value="id" color="cyanText" hide-details
                                                                            single-line>
                                                                        </v-autocomplete>
                                                                    </template>
                                                                    <template v-slot:[`item.sides`]="{ item }">

                                                                        <v-autocomplete outlined dense
                                                                            style="font-size: 0.8vw" v-model="item.sides"
                                                                            multiple
                                                                            :items="categoryDetail(item.categories_id)"
                                                                            label="Detalle categoria" item-text="name"
                                                                            item-value="id" color="cyanText" hide-details
                                                                            single-line>
                                                                        </v-autocomplete>
                                                                    </template>
                                                                    <template
                                                                        v-slot:[`item.production_center_id`]="{ item }">

                                                                        <v-autocomplete outlined dense
                                                                            style="font-size: 0.8vw"
                                                                            v-model="item.production_center_id"
                                                                            :items="centersProductions"
                                                                            label="Centro produccion" item-text="name"
                                                                            item-value="id" color="cyanText" hide-details
                                                                            single-line>
                                                                        </v-autocomplete>
                                                                    </template>

                                                                    <template v-slot:[`item.actions`]="{ item }">
                                                                        <v-tooltip top>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-icon v-bind="attrs" v-on="on" medium
                                                                                    color="red" @click="deleteItem(item)">
                                                                                    delete
                                                                                </v-icon>
                                                                            </template>
                                                                            <span>Quitar</span>
                                                                        </v-tooltip>
                                                                        <!-- <v-icon small class="mr-2" @click="editItem(item)">
                              mdi-pencil
                            </v-icon>
                            <v-icon small @click="deleteItem(item)">
                              mdi-delete
                            </v-icon> -->
                                                                    </template>
                                                                    <template v-slot:no-data>
                                                                        <v-row justify="center" class="pt-10">
                                                                            <v-icon size="80px">token</v-icon>
                                                                        </v-row>
                                                                        <v-row justify="center" class="pb-6">
                                                                            No hay datos
                                                                        </v-row>
                                                                    </template>
                                                                </v-data-table>
                                                            </v-col></v-row>

                                                        <v-row justify="center">
                                                            <v-btn @click="close" text class="mx-2">Cancelar</v-btn>
                                                            <v-btn @click="save" color="primary" outlined>Guardar</v-btn>
                                                        </v-row>
                                                    </v-container>
                                                </v-container>
                                            </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" medium color="blue" @click="editItem(item)">
                                                edit
                                            </v-icon>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="mx-1" v-bind="attrs" v-on="on" medium color="green"
                                                @click="editDetails(item)">
                                                add
                                            </v-icon>
                                        </template>
                                        <span>Agregar {{ item.name }}</span>
                                    </v-tooltip>
                                </template>

                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-card-text>


            </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
import moment from "moment";
import ArticleFilters from "@/components/Inventory/ArticleSelectionList.vue";
export default {
    data: () => ({
        date: moment().format("YYYY-MM-DD"),
        date2: moment().format("YYYY-MM-DD"),
        time: null,
        time2: null,
        editedIndex: -1,
        menu: false,
        menu2: false,
        menu3: false,
        menu4: false,
        editedItem: {
            in_menu_id: null,
            in_business_unit_id: null,
            in_location_id: null,
            in_name: "",
            in_from_date: null,
            in_to_date: null,
            in_from_time: null,
            in_to_time: null,
            in_menu_details: []

        },
        selected_catalogue: 0,
        filters: {
            business_unit_id: null,
            location_id: null,

        },
        menus: [],
        centersProductions: [],
        search: "",
        headersItemSelected: [
            {
                text: "ID",
                align: "start",
                value: "item_id",
                width: "5%",
            },
            { text: "Descripcion", value: "item_name" },
            { text: "Categoria", value: "categories_id", align: "center" },
            { text: "Detalle categoria", value: "sides" },
            { text: "Centro produccion", value: "production_center_id" },
            { text: "Acciones", value: "actions", sortable: false },
        ],

        itemsLocations: [],
        itemsLocations2: [],
        dialog: false,
        headerMenu: [
            {
                text: "Id",
                align: "start",
                value: "id",
            },
            { text: "Nombre", value: "name" },
            { text: "Unidad de negocio", value: "business_unit_description_long" },
            { text: "Location", value: "location_description_long" },
            { text: "Location", value: "location_description_long" },
            { text: "Actions", value: "actions", sortable: false },
        ],
        headerDetails: [
            {
                text: "Id",
                align: "start",
                value: "id",
            },
            { text: "Nombre", value: "name" },
            { text: "Tipo", value: "type_name" },
            { text: "Item", value: "item_name" },
            { text: "Precio", value: "price" },
            { text: "Actions", value: "actions", sortable: false },
        ],
        types: [],
        DetailsType: [],

    }),
    components: { ArticleFilters },
    created() {
        this.getData();
    },
    computed: {

    },
    watch: {},
    methods: {
        getDate(date) {
            let newDate = moment(date).format("YYYY-MM-DD").toString();
            return newDate;
        },
        async getData() {
            this.itemsBussines = Object.assign([], this.$store.state.auth.bussines_units)
            this.itemsLocations = this.$store.state.auth.locations;

        },
        async getGetailsType(id) {
            let filters = ` where location_id=${id}`
            const detail_sides = await this.api_Post("restaurant/view_side", {
                filters: filters,
            });
            this.DetailsType = await detail_sides.json();

        },
        categoryDetail(items) {
            if (items === undefined) {
                return []
            }

            // return []
            return this.DetailsType.filter(item => items.includes(item.side_type_id))
        },
        async getCenterProduction(id) {
            let filters = `where location_id=${id}`

            const response = await this.api_Post("restaurant/view_centers", { filters: filters });

            this.centersProductions = await response.json();
        },
        async loadMenus() {
            if (this.filters.location_id === null) {
                this.$toast.warning("seleciona un location!");
            }
            let filters = ""
            filters += this.filters.location_id === null ? '' : `where location_id=${this.filters.location_id}`
            const responseRequest = await this.api_Post(
                "restaurant/view_menu", {
                filters: filters
            }

            );

            if (responseRequest.status == 200) {
                let data = await responseRequest.json();
                this.menus = data
                //this.initialize();

                this.dialog = false;
            } else {

            }
        },
        async loadTypes() {

            this.menus = [];

            let filtros = " WHERE BUSINESS_UNIT_ID=" + this.editedItem.in_business_unit_id;
            filtros += Number.isInteger(this.editedItem.in_location_id)
                ? " AND LOCATION_ID=" + this.editedItem.in_location_id
                : " ";

            try {
                const boxesRequest = await this.api_Post("restaurant/all_side_type", {
                    filters: filtros,
                });
                const typeData = await boxesRequest.json();

                if (boxesRequest.status != 200) {
                    this.$toast.error(typeData.error);
                }
                this.getGetailsType(this.editedItem.in_location_id)
                this.getCenterProduction(this.editedItem.in_location_id)
                this.types = typeData;
            } catch (error) {
                console.log(error);
            }

        },
        async loadCatalogue() {
            this.selected_catalogue = 0
            let filters = `where location_id=${this.editedItem.in_location_id}`
            let resultCatalogues = await this.api_Post("inventory/catalogues_details", { filters: filters });
            let data = await resultCatalogues.json();
            if (data.length > 0) {
                this.selected_catalogue = data[0].id
            }


        },

        showMenu() {


            this.dialog = true;
        },
        updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
            itemsDestino.splice(0, itemsDestino.length);

            for (let item of itemsOrigen) {
                if (item.bus_id === condicion) {
                    itemsDestino.push({
                        value: item.value,
                        text: item.text,
                        routes: item.routes,
                    });
                }
            }

            return itemsDestino;
        },
        addItem(item) {
            let newItem = item;
            newItem.max = 0;
            newItem.min = 0;

            if (this.editedItem.in_business_unit_id == null) {

                this.$toast.info("Debes seleccionar una unidad de negocio!");
                return;
            }
            if (this.editedItem.in_location_id == null) {
                this.$toast.info("Debes selecionar un location!");
                return;
            }
            let status = this.editedItem.in_menu_details.find(
                (element) => element.item_id == item.item_id
            );

            if (status == undefined) {
                this.editedItem.in_menu_details.unshift(item);
            } else {
                this.$toast.info("El articulo no esta, o ya encuentra agregado!");
            }
        },

        async editItem(item) {

            this.editedIndex = this.menus.indexOf(item);

            let data = Object.assign({}, item);
            //console.log(data)
            this.editedItem.in_menu_id = data.id
            this.editedItem.in_business_unit_id = data.business_unit_id
            this.editedItem.in_location_id = data.location_id
            this.editedItem.in_name = data.name
            this.editedItem.in_from_date = this.getDate(data.from_date)
            this.editedItem.in_to_date = this.getDate(data.to_date)
            this.editedItem.in_from_time = data.from_time
            this.editedItem.in_to_time = data.to_time
            //  this.editedItem.in_menu_details=data.details

            this.itemsLocations2 = this.updateCombo(this.itemsLocations, this.itemsLocations2, this.editedItem.in_business_unit_id, 'location')
            this.loadTypes()
            this.getGetailsType(this.editedItem.in_location_id)
            this.getCenterProduction(this.editedItem.in_location_id)
            let details = [];
            for (let item of data.details) {
                let uniqueSideTypeIds = [];
                let sideIdsArray = []
                if (item.sides != null && item.sides.length > 0) {
                    sideIdsArray = item.sides.map(item => item.side_id);
                    item.sides.forEach(side => {
                        if (!uniqueSideTypeIds.includes(side.side_type_id)) {
                            uniqueSideTypeIds.push(side.side_type_id);
                        }
                    });
                }
                item.categories_id = uniqueSideTypeIds;
                item.sides = sideIdsArray

                details.push({
                    item_id: item.item_id,
                    item_name: item.item_name,
                    production_center_id: item.production_center_id,
                    categories_id: uniqueSideTypeIds,
                    sides: sideIdsArray,
                });
            }


            this.editedItem.in_menu_details = details

            this.loadCatalogue()
            // console.log(this.editedItem)
            this.dialog = true;
        },


        close() {
            this.dialog = false;
            this.editedIndex = -1
            this.editedItem.in_menu_details = [];
            this.editedItem.in_menu_id = null
            this.editedItem.in_business_unit_id = null
            this.editedItem.in_location_id = null
            this.editedItem.in_name = ''
            this.editedItem.in_from_date = null
            this.editedItem.in_to_date = null
            this.editedItem.in_from_time = null
            this.editedItem.in_to_time = null
        },
        async save() {

            if (this.editedItem.in_business_unit_id == null) {
                this.$toast.error("debes selecionar una unidad de negocio!");
                return
            }
            if (this.editedItem.in_location_id == null) {
                this.$toast.error("debes selecionar un location!");
                return
            }

            if (this.editedItem.in_name == "") {
                this.$toast.error("debes selecionar un nombre!");
                return
            }
            let in_menu_details = [];
            for (let item of this.editedItem.in_menu_details) {
                if (item.production_center_id == undefined) {
                    this.$toast.info(
                        "debes agregar un centro de produccion a " + item.item_name + " !"
                    );
                    return;
                }


                let sides = item.sides === undefined ? [] : item.sides.map(side => ({ side_id: side }));

                in_menu_details.push({
                    item_id: item.item_id,

                    production_center_id: item.production_center_id,
                    sides: sides,
                });
            }

            console.log(this.editedItem.in_to_date)
            if(this.editedItem.in_to_date=="Invalid date")
            {
                this.editedItem.in_to_date=null
            }
            let datas = {
                in_menu_id: this.editedItem.in_menu_id === undefined ? null : this.editedItem.in_menu_id,
                in_location_id: this.editedItem.in_location_id,
                in_name: this.editedItem.in_name,
                in_from_date: this.editedItem.in_from_date === null ? `${this.editedItem.in_from_date}` : `'${this.editedItem.in_from_date}'`,
                in_to_date: this.editedItem.in_to_date === null ? `${this.editedItem.in_to_date}` : `'${this.editedItem.in_to_date}'`,
                in_from_time: this.editedItem.in_from_time === null ? `${this.editedItem.in_from_time}` : `'${this.editedItem.in_from_time}'`,
                in_to_time: this.editedItem.in_to_time === null ? `${this.editedItem.in_to_time}` : `'${this.editedItem.in_to_time}'`,
                in_menu_details: in_menu_details

            };


            const responseRequest = await this.api_Post(
                "restaurant/add_menu",
                datas
            );

            if (responseRequest.status == 200) {
                let data = await responseRequest.json();
                //this.initialize();
                this.$toast.success("agregado con exito!");
                if (this.filters.location_id != null) {
                    this.loadMenus()
                }
                this.dialog = false;
            } else {
                this.$toast.error("error al registrar el menu!");
            }


            this.close();
        },




        async deleteItem(item) {
            let indice = this.editedItem.in_menu_details.indexOf(item);
            this.editedItem.in_menu_details.splice(indice, 1);
            // const request = await this.api_Delete(
            //     "restaurant/side/" + item.id
            // );
            // if (request.status == 200) {
            //     await this.getGetails(item.side_type_id);
            //     this.$toast.success("eliminado!");

            //     this.DialogAddDetails = false
            // }
        },

    },
};
</script>
  