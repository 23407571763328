<template>
  <v-flex>
    <v-row v-if="type == 1" no-gutters class="mx-1 mt-0 mb-2" justify="start">
      <!-- <v-icon class="mx-2" size="35px">receipt_long</v-icon> -->
      <v-btn
        small
        @click="beforeRoute"
        color="primary"
        outlined
        class="mr-2 rounded text-capitalize"
        ><v-icon>keyboard_backspace</v-icon> Volver</v-btn
      >
      <!-- <v-btn
          small
          outlined
          class="mr-2 rounded text-capitalize"
          tile
          color="primary"
        >
          <v-icon left>edit</v-icon>
          Editar
        </v-btn> -->
      <v-btn
        @click="print"
        small
        outlined
        class="mx-2 rounded text-capitalize"
        tile
        color="primary"
      >
        <v-icon left> print </v-icon>
        Imprimir
      </v-btn>
      <v-btn
        :disabled="quote[0].order_id != null"
        @click="updateChanges"
        small
        outlined
        class="mx-2 rounded text-capitalize"
        tile
        color="primary"
      >
        <v-icon left> save </v-icon>
        Guardar cambios
      </v-btn>
      <!-- <v-btn
        small
        outlined
        class="mx-2 rounded text-capitalize"
        tile
        color="primary"
      >
        <v-icon left> mdi-send </v-icon>
        Enilet a suplidor
      </v-btn> -->
      <v-btn
        :disabled="quote[0].order_id != null"
        @click="goToOrder"
        small
        outlined
        class="mx-2 rounded text-capitalize"
        tile
        color="primary"
      >
        <v-icon left> receipt </v-icon>
        Convertir en orden
      </v-btn>
    </v-row>
    <v-card class="rounded-lg" outlined height="auto">
      <v-responsive :aspect-ratio="16 / 9">
        <v-row justify="center">
          <v-col :cols="type == 1 ? 7 : 12">
            <div class="mx-10">
              <v-row class="mt-6" no-gutters>
                <v-col cols="12" md="1">
                  <!-- <v-img
                  contain
                  width="100"
                  height="100"
                  :src="loadLogo(quote[0].business_unit_id)"
                ></v-img> -->
                </v-col>
                <v-col cols="12" md="10">
                  <v-row no-gutters justify="center">
                    <span style="font-size: 1.4em; color: #616161">
                      COTIZACION #{{ quote[0].quotation_id }}</span
                    >
                  </v-row>
                  <v-row no-gutters justify="center">
                    <span style="font-size: 1.2em; color: #616161">
                      {{ quote[0].bussines_unit_description }}</span
                    >
                  </v-row>
                  <v-row no-gutters justify="center">
                    <span style="font-size: 1.2em; color: #616161">
                      {{ quote[0].location_description }}</span
                    >
                  </v-row>
                </v-col>
                <v-col cols="12" md="1 ">
                  <!-- <v-row class="mt-2" no-gutters justify="center">
                  <span style="font-size: 14px; color: #616161">
                    COTIZACION# {{ quote[0].quotation_id }}</span
                  >
                </v-row> -->
                </v-col>
              </v-row>
              <!-- <v-row>
              <v-divider class="mr-10 mt-2 mb-4" inset></v-divider>
            </v-row> -->
              <v-row no-gutters justify="center">
                <v-col cols="12" md="12">
                  <v-row no-gutters>
                    <div
                      v-if="quote[0].supplier_id === quote[0].order_supplier_id"
                    >
                      <span style="font-size: 1em">Orden</span>
                      <span
                        @click="showOrder(quote[0].order_id)"
                        style="
                          text-decoration: underline;
                          cursor: pointer;
                          font-size: 1em;
                        "
                        class="mx-6 font-weight-bold"
                        >{{
                          filLeft(quote[0].order_id.toString(), 12, "0")
                        }}</span
                      >
                    </div>
                    <div v-else>
                      <span>-</span>
                    </div>
                  </v-row>
                  <v-row no-gutters>
                    <span style="font-size: 1em">Suplidor</span>
                    <span
                      @click="viewEntity(quote[0].supplier_id)"
                      style="
                        text-decoration: underline;
                        cursor: pointer;
                        font-size: 1em;
                      "
                      class="mx-2 font-weight-bold"
                      >{{ quote[0].supplier_name }}</span
                    >
                  </v-row>
                  <v-row no-gutters>
                    <span
                      >Creacion
                      <b>{{ quote[0].created_at }}</b>
                    </span>
                  </v-row>
                </v-col>
                <!-- <v-col cols="2" md="6">
                <span class="mr-12 float-right"
                  >Creacion
                  <b>{{ quote[0].created_at }}</b>
                </span>
              </v-col> -->
                <v-divider class="ml-1 mb-5 mt-1 mr-1"></v-divider>
              </v-row>
              <v-row justify="center" no-gutters>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="quote"
                    item-key="item.item_id "
                    dense
                    hide-default-footer
                  >
                    <template v-slot:[`item.item_code_supplier`]="{ item }">
                      <span style="font-size: 1vw"
                        >#{{ item.item_code_supplier }}
                      </span>
                    </template>
                    <template v-slot:[`item.item_name`]="{ item }">
                      <span style="font-size: 0.8vw">{{ item.item_name }}</span>
                    </template>

                    <template v-slot:[`item.price`]="{ item }">
                      <v-text-field
                        :readonly="quote[0].order_id != null"
                        style="font-size: 1vw"
                        prefix="$"
                        type="number"
                        min="1"
                        v-model.number="item.price"
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </template>

                    <template
                      v-slot:[`item.quotation_detail_quantity`]="{ item }"
                    >
                      <v-text-field
                        :readonly="true"
                        style="font-size: 1vw"
                        v-model="item.quotation_detail_quantity"
                        type="number"
                        :min="1"
                        :value="1"
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </template>
                    <template v-slot:[`item.total`]="{ item }">
                      <span style="font-size: 1vw">{{
                        currencyFormat(
                          item.quotation_detail_quantity * item.price
                        )
                      }}</span>
                    </template>

                    <template v-slot:footer>
                      <v-row
                        class="mb-10 mt-4"
                        justify="end"
                        style="margin-right: 0.2vh"
                      >
                        <v-card
                          style="margin-top: 5px; margin-bottom: 20px"
                          elevation="0"
                          width="30%"
                        >
                          <v-row no-gutters dense>
                            <v-divider
                              class="mt-4"
                              style="background-color: grey"
                            ></v-divider>
                          </v-row>
                          <v-row no-gutters dense>
                            <span
                              style="font-size: 1.2em"
                              class="font-weight-bold"
                              >Total</span
                            >
                            <v-spacer></v-spacer>
                            <span
                              style="font-size: 1.2em"
                              class="font-weight-bold"
                              >{{ currencyFormat(total) }}
                            </span>
                          </v-row>
                        </v-card>
                      </v-row>
                      <v-row class="mb-8 mt-n4" justify="end" no-gutters dense>
                        <span style="font-size: 1.2em">
                          <b>Solicitante:</b>
                          {{ quote[0].use_fullname }}</span
                        >
                      </v-row>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col v-if="type == 1" cols="5">
            <!-- <v-row>
              <span>Cotiazacion recibida</span>
            </v-row>
            <v-row> </v-row> -->
            <v-card
              v-if="selectedAttached == ''"
              width="80%"
              @click="takeImage"
              style="
                cursor: pointer;
                border: 0.5px solid rgb(0, 0, 0);
                border-radius: 6px;
                background-color: #fafafa;
              "
              class="mx-2 mt-6 align-self-center"
              outlined
            >
              <v-row no-gutters justify="center">
                <v-card-title class="headline">Archivos adjuntos </v-card-title>
              </v-row>
              <v-row class="mb-4" no-gutters justify="center">
                <v-icon style="transform: rotate(30deg)" size="60px">
                  attach_file
                </v-icon>
              </v-row>
            </v-card>
            <div
              class="align-self-center"
              v-else
              style="
                position: relative;
                padding-bottom: 56.25%; /* 16:9 */
                padding-top: 25px;
                height: 0;
              "
            >
              <iframe
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100vh;
                "
                :src="selectedAttached"
                frameborder="0"
              >
              </iframe>
            </div>
          </v-col>
        </v-row>
        <v-file-input
          v-show="false"
          style="opacity: 0"
          name="upload"
          id="fileInput"
          @change="onFileChange"
        ></v-file-input>
      </v-responsive>
    </v-card>
  </v-flex>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      storage_json: [],
      selectedAttached: "",
      headers: [
        {
          text: "Codigo",
          value: "item_code_supplier",
          width: "3%",
          align: "start",
        },
        {
          text: "Producto/servicio",
          align: "start",
          sortable: false,
          value: "item_name",
          width: "25%",
        },

        { text: "Unidad", value: "unit_name", width: "10%", align: "start" },
        {
          text: "Precio",
          value: "price",
          width: "20%",
          align: "start",
        },

        {
          text: "	Cantidad",
          value: "quotation_detail_quantity",
          width: "10%",
          align: "start",
        },
        { text: "	Total", value: "total", width: "10%", align: "end" },
        //   {
        //     text: "Actions",
        //     value: "actions",
        //     sortable: false,
        //     width: "3%",
        //     align: "start",
        //   },
      ],
      tot: 0,
    };
  },
  props: {
    quote: {
      type: Array,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  created() {
    this.getData();
  },
  computed: {
    total() {
      let tot = 0;
      for (let item of this.quote) {
        if (item.price != null) {
          tot += tot + item.price * item.quotation_detail_quantity;
        }
      }
      return tot;
    },
  },
  methods: {
    formatN(number) {
      return number.toFixed(2);
    },
    async print() {
      let invoice_details = [];
      for (let item of this.quote) {
        invoice_details.push({
          item_id: item.item_code_supplier,
          item_name: item.item_name,
          price: item.price,
          quantity: parseFloat(item.quotation_detail_quantity).toFixed(2),
          total: (
            parseFloat(item.quotation_detail_quantity) * parseFloat(item.price)
          ).toFixed(2),
        });
      }

      let invoice = {
        business_unit_description: this.quote[0].bussines_unit_description,
        location_description: this.quote[0].location_description,
        business_unit_rnc: this.quote[0].quote_business_unit_id,
        invoice_number: this.quote[0].quote_id,
        emission_date: this.quote[0].requested_at,
        quotation_id: this.quote[0].quote_id,
        supplier_id: this.quote[0].supplier_id,
        invoice_details: invoice_details,
        total_amount: parseFloat(this.total).toFixed(2),
        entity_name: this.quote[0].supplier_name,
      };
      const responseRequest = await this.api_Post(
        "shopping/invoices_quotation",
        {
          data: invoice,
        }
      );
      let data = await responseRequest.json();

      if (responseRequest.status == 200) {
        this.$toast.success("Guardado!");

        // let storage = await this.get_storage(
        //   "quotes",
        //   data.quote
        // );
        // console.log(storage)

        this.downloadQuotation(data.quote);
        this.getData();
      } else {
        this.$toast.error("error al cargar!");
      }
    },
    downloadQuotation(id) {
      const routeData = this.$router.resolve({
        name: "DownloadQuotation",
        params: { id: id },
      });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   name: "DownloadQuotation",
      //   params: { id: id },
      // });
    },
    async goToOrder() {
      for (let item of this.quote) {
        // console.log(item);
        if (item.price == null || item.price < 1) {
          this.$toast.error(
            "el Item " + item.item_name + " Debe tener un precio mayor que 0"
          );
          return;
        }
      }
      const responseRequest = await this.api_Post("shopping/quote_to_order", {
        request_id: this.quote[0].request_id,
        supplier_id: this.quote[0].supplier_id,
        quotation_id: this.quote[0].quotation_id,
      });
      if (responseRequest.status == 200) {
        let data = await responseRequest.json();

        this.showOrder(data[0].id);
        // this.getData();
      } else {
      }
    },
    async updateChanges() {
      let data = [];
      for (let item of this.quote) {
        // console.log(item);
        data.push({
          item_id: item.item_id,
          price: item.price == null ? 0 : item.price,
        });
      }

      const responseRequest = await this.api_Post(
        "shopping/update_price_quotation",
        {
          supplier_id: this.quote[0].supplier_id,
          quotation_id: this.quote[0].quotation_id,
          json: data,
        }
      );
      if (responseRequest.status == 200) {
        this.$toast.success("Guardado!");

        this.getData();
      } else {
        this.$toast.error("error al cargar!");
      }
    },
    async viewAddtached(item) {
      this.loadingAttachment = true;
      this.dialogAttached = true;
      try {
        this.selectedAttached = await this.get_storage(
          "quotes_suppliers",
          item.id
        );
      } catch (err) {
        console.log(err);
      }

      this.loadingAttachment = false;
    },

    getData() {
      if (this.quote[0].data_suppliers == null) {
        return;
      }
      if (this.type == 1) {
        let data =
          this.quote[0].data_suppliers == null
            ? []
            : this.quote[0].data_suppliers;

        this.storage_json =
          this.quote[0].data_suppliers == null
            ? []
            : this.quote[0].data_suppliers;

        if (data != []) {
          this.viewAddtached(data);
        }

        // for (let item of data) {
        //   console.log(item)
        //   if (item.supplier == this.quote[0].supplier_id) {

        //   }
        // }
      }
    },
    async onFileChange() {
      try {
        const fileInput = document.getElementById("fileInput");
        let record = await this.upload_images("quotes_suppliers", fileInput);

        this.storage_json.push({
          request_id: this.quote[0].request_id,
          quotation_id: this.quote[0].quotation_id_number,
          supplier: this.quote[0].supplier_id,
          record: record,
        });
        //  console.log( this.storage_json)
        const responseRequest = await this.api_Post(
          "shopping/attaches_quotes",
          {
            request_id: this.quote[0].request_id,
            quotation_id: this.quote[0].quotation_id_number,
            supplier: this.quote[0].supplier_id,
            record: record,
          }
        );

        if (responseRequest.status == 200) {
          this.$toast.success("Guardado!");
          this.viewAddtached(record);
          //this.getData();
        } else {
          this.$toast.error("error al cargar!");
        }
      } catch (e) {
        this.$toast.error("error " + e.message);
      }
    },
    takeImage() {
      //this.fileInput.click();
      document.getElementById("fileInput").click();

      //this.this.dialogImage = true;
    },
    getDate(item) {
      return moment(item).format("DD/MM/YYYY");
    },
    async loadLogo() {
      return await this.get_storage(
        "logo_bussines_units",
        this.filters.business.image_storage
      );
    },
  },
};
</script>

<style scoped>
.folded_corner {
  width: 100%;

  height: auto;
  border-radius: 5px;

  /* background: #58a; */
  background: linear-gradient(
        to left bottom,
        transparent 50%,
        rgba(194, 193, 193, 0.4) 0
      )
      no-repeat 100% 0 / 2em 2em,
    linear-gradient(-135deg, transparent 1.5em, rgb(255, 255, 255) 0);

  /* -webkit-filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
  /* filter: drop-shadow(20px 30px rgba(0, 0, 0, 0.1)); */
}

/* .flex-item {
  padding: 5px;
  width: 50%;
  height: 40px;
  font-weight: bold;
}

.folded-paper:before {
  content: "";
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 30px 30px 0;
  border-style: solid;
  border-color: rgb(74, 92, 67) #fff;
  transition: 0.3s;
  border-radius: 0 0 0 4px;
}

.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
} */
</style>
