<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-card flat color="#F4F5FB" height="150%" width="100%">
          <v-row justify="center">
            <v-col cols="8">
              <FilterBussinesLocations @filter_loaded="update_data" class="mt-5" :type="false" :data="false" />
            </v-col>


          </v-row>
          <v-tabs v-model="tab" class=" " background-color="transparent" centered>
            <v-tab v-for="item of dashTypes.types" :key="item.id" :value="item.id">{{
              item.name
            }}</v-tab>

            <v-tab-item class="backColor" v-if="$store.state.auth.user.user.permition_level === 99">
              <DashboardAdmin :filters="filters" />
            </v-tab-item>
            <v-tab-item class="backColor" v-if="dashTypes.access.ventas == true">
              <DashboardSales :filters="filters" />
            </v-tab-item>
            <v-tab-item class="backColor" v-if="dashTypes.access.compras == true">
              <v-container>
                <v-row>
                  <DashboardCompra :filters="filters" />
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item class="backColor" v-if="dashTypes.access.contabilidad == true">
              <v-container>
                <v-row>
                  <h1>Contabildad</h1>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item class="backColor" v-if="dashTypes.access.inventario == true">
              <v-container>
                <v-row>
                  <h1>Inventario</h1>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item class="backColor" v-if="dashTypes.access.cxc == true">
              <v-container>
                <v-row>
                  <h1>CXC</h1>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item class="backColor" v-if="dashTypes.access.cxp == true">
              <v-container>
                <v-row>
                  <h1>CXP</h1>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>


        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import DonutChart from "@/components/Charts/DonutChart.vue";
import DashboardAdmin from "@/components/Dashboard.vue";
import DashboardCompra from "@/components/Shop/Dashboard.vue";
import FilterBussinesLocations from "@/components/FilterBussinesLocations.vue"
import DashboardSales from "@/components/Income/Dashboard.vue"
export default {
  data: () => ({
    //     total de venta y compra
    //
    filters: {
      business: null,
      location: null,
      department_id: null
    },
    tab: null,
  }),
  components: {
    DashboardAdmin,
    DashboardSales,
    DashboardCompra, FilterBussinesLocations
  },
  computed: {
    dashTypes() {
      let data = [];
      let ventas = this.$store.state.auth.user.type_access.some(
        (element) => element.access.type_access == 2 && element.access.status == true
      )
      let compras = this.$store.state.auth.user.type_access.some(
        (element) => element.access.type_access == 1 && element.access.status == true
      );
      let contabilidad = this.$store.state.auth.user.type_access.some(
        (element) => element.access.type_access == 4 && element.access.status == true
      );
      let inventario = this.$store.state.auth.user.type_access.some(
        (element) => element.access.type_access == 3 && element.access.status == true
      );
      let cxc = this.$store.state.auth.user.type_access.some(
        (element) => element.access.type_access == 5 && element.access.status == true
      );
      let cxp = this.$store.state.auth.user.type_access.some(
        (element) => element.access.type_access == 6 && element.access.status == true
      );
      let access = {
        ventas: ventas,
        compras: compras,
        contabilidad: contabilidad,
        inventario: inventario,
        cxc: cxc,
        cxp: cxp
      }
      if (this.$store.state.auth.user.user.permition_level == 99) {
        data.push({ id: data.length, name: "Administrativo" });
      }

      if (ventas === true) {
        data.push({ id: data.length, name: "Ventas" });
      }
      if (compras === true) {
        data.push({ id: data.length, name: "Compras" });
      }
      if (contabilidad === true) {
        data.push({ id: data.length, name: "Contabilidad" });
      }
      if (inventario === true) {
        data.push({ id: data.length, name: "Inventario" });
      }
      if (cxc === true) {
        data.push({ id: data.length, name: "CXC" });
      }
      if (cxp === true) {
        data.push({ id: data.length, name: "CXP" });
      }
      return { types: data, access: access };
    },
  },
  methods: {
    update_data(data) {

      this.filters = data
    },
  },
  created() { },
};
</script>
<style scoped>
.backColor {
  background-color: #F4F5FB;
}
</style>
