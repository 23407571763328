<template>
  <v-container>
    <v-row v-if="!loading" justify="center">
      <v-col cols="12">
        <CustomerOrSupplier :entitys="entitys" :type="1" @update="getData" />
      </v-col>
    </v-row>
    <v-row justify="center" v-else>
      <v-col cols="12" md="10">
        <v-skeleton-loader type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions">
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerOrSupplier from "../../components/Accounting/CustomerOrSupplier.vue";
export default {
  data: () => ({
    loading: false,
    dialog: false,
    dialogDelete: false,
    suplidor: false,
    cliente: false,
    disabledName: false,
    plazoPagoSuplidor: null,
    plazoPagoCliente: null,
    documents: [],
    rncValid: false,
    ncfTypes: [],
    accounts: [],
    provincies: [],

    cxc: "",
    cxp: "",
    selectedDocument: "",
    selectedtypesNcfCliente: "",
    selectedtypesNcfSuplidor: "",
    selectedProvincies: "",
    headers: [
      {
        text: "Nombre/Razon social",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Identificacion", value: "document_id" },
      { text: "Telefono", value: "phone_number" },
      { text: "Email", value: "email" },

      { text: "Actions", value: "actions", sortable: false },
    ],
    entitys: [],
    editedIndex: -1,
    editedItem: [],
  }),
  components: { CustomerOrSupplier },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo contacto" : "Editar contacto";
    },
  },

  watch: {
    "editedItem.document_id"(val, newVal) {
      if (val != newVal) {
        this.disabledName = false;
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async getData() {
      this.loading = true
      let resultEntity = await this.api_Get("accounting/entity_all/3");

      this.entitys = await resultEntity.json();
      this.loading = false
    },
    async initialize() {
      this.getData();
      //   let resultTypeAccounts = await this.api_Get("accounting/accounts");
      //   const dataTypeAccounts = await resultTypeAccounts.json();
      //   for (let item of dataTypeAccounts) {
      //     this.accounts.push({
      //       text: item.name,
      //       value: item.id,
      //       dgii_category_id: item.dgii_category_id,
      //     });
      //   }

      //   let resultDocuments = await this.api_Get("accounting/documents");
      //   let data = await resultDocuments.json();

      //   for (let item of data) {
      //     this.documents.push({ text: item.name, value: item.id });
      //   }
      //   let resultTypesNcf = await this.api_Get("accounting/nfc_types");
      //   for (let item of await resultTypesNcf.json()) {
      //     this.ncfTypes.push({ text: item.name, value: item.id });
      //   }
      //   let reslutprovincies = await this.api_Get("accounting/provincies");
      //   for (let item of await reslutprovincies.json()) {
      //     this.provincies.push({ text: item.name, value: item.id });
      //   }
    },
    async searchRnc() {
      const responseRnc = await this.api_Post("accounting/consult_rnc", {
        rnc: this.editedItem.document_id,
      });
      let dataRnc = await responseRnc.json();
      if (dataRnc.length == 0) {
        this.rncValid = true;
        this.$toast.info("El RNC no es valido");
        return false;
      }
      this.disabledName = true;
      this.rncValid = false;

      this.editedItem.name = dataRnc[0].name;
      return true;
    },
    editItem(item) {
      this.editedIndex = this.entitys.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.selectedDocument = this.editedItem.document_id_type_id;
      this.selectedProvincies = this.editedItem.province_id;
      if (this.editedItem.customer_details == null) {
      } else {
        this.cxc =
          this.editedItem.customer_details[0].customer.account_payable_id;
        this.plazoPagoCliente =
          this.editedItem.customer_details[0].customer.credit_days;
        this.cliente = true;
      }
      if (this.editedItem.supplier_details == null) {
      } else {
        this.cxp =
          this.editedItem.supplier_details[0].supplier.account_receivable_id;

        this.plazoPagoSuplidor =
          this.editedItem.supplier_details[0].supplier.credit_days;
        this.suplidor = true;
      }

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.entitys.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.entitys.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.cliente = false;
      this.suplidor = false;
      this.cxc = "";
      this.cxp = "";
      this.plazoPagoCliente = "";
      this.plazoPagoSuplidor = "";
      this.selectedProvincies = "";
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.suplidor == "") {
        let suppliers_json = null;
      } else {
        let suppliers_json = {
          account_receivable_id: this.cxp,
          credit_days: this.plazoPagoSuplidor,
        };
      }
      if (this.cliente == "") {
      } else {
        let customers_json = {
          account_payable_id: this.cxc,
          credit_days: this.plazoPagoCliente,
        };
      }

      if (this.selectedDocument == 1) {
        if (this.searchRnc() == true) {
        }
      }

      if (this.editedIndex > -1) {
        const responseRequest = await this.api_Put("accounting/entity", {
          entity_id: this.editedItem.id,
          document_id_type_id: this.selectedDocument,
          document_id: this.editedItem.document_id,
          name: this.editedItem.name,
          address: this.editedItem.address,
          email: this.editedItem.email,
          phone_number: this.editedItem.phone_number,
          telephone_1: this.editedItem.telephone_1,
          telephone_2: this.editedItem.telephone_2,
          province_id: this.selectedProvincies,
          is_supplier: this.suplidor,
          is_customer: this.cliente,
          supplier_details: suppliers_json,
          customer_details: customers_json,
        });
        if (responseRequest.status == 200) {
          this.getData();
          this.$toast.success("actualizado con exito!");
        } else {
          this.$toast.error("error al agregar!");
        }
      } else {
        const responseRequest = await this.api_Post("accounting/entity", {
          entity_id: null,
          document_id_type_id: this.selectedDocument,
          document_id: this.editedItem.document_id,
          name: this.editedItem.name,
          address: this.editedItem.address,
          email: this.editedItem.email,
          phone_number: this.editedItem.phone_number,
          telephone_1: this.editedItem.telephone_1,
          telephone_2: this.editedItem.telephone_2,
          province_id: this.selectedProvincies,
          is_supplier: this.suplidor,
          is_customer: this.cliente,
          supplier_details: suppliers_json,
          customer_details: customers_json,
        });

        if (responseRequest.status == 200) {
          this.getData();
          this.$toast.success("agregado con exito!");
        } else {
          this.$toast.error("error al agregar!");
        }
      }

      this.close();
    },
  },
};
</script>

<style></style>
