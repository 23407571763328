<template>
    <v-card width="100%" outlined>
        <apexchart width="100%" height="250%" type="bar" :options="options" :series="series"></apexchart>
    </v-card>
</template>
<script>
export default {
    data: () => ({
        options: {
            title: {
                text: "",
                align: "center",
            },
            chart: {
                id: "vuechart-example",
            },
            yaxis: {
                labels: {
                    formatter: function (number) {

                        if (number >= 1000) {
                            const rounded = Math.round(number / 1000);

                            return `${rounded}k`;
                        } else {
                            return number.toString();
                        }
                    }
                },
            },
            xaxis: {
                categories: [],

            },
            plotOptions: {
                bar: {
                    distributed: true
                }
            }
        },
        series: [
            {
                name: "series-1",
                data: [],
            },
        ],

    }),
    props: {
        title_header: {
            type: String
        },
        data: {
            type: Object,
        },

        colors: {
            type: Boolean,
            default: false
        },

    },

    watch: {
        data: {
            handler: function (nuevoValor, viejoValor) {
                // Lógica para manejar el cambio del objeto filters

                this.options.xaxis.categories = nuevoValor.label
                this.series[0].data = nuevoValor.series
                this.options.title.text = this.title_header
                this.options.plotOptions.bar.distributed = this.colors
            },
            deep: true, // Para obserlet cambios profundos en el objeto filters
            immediate: true // Para ejecutar el watch inmediatamente al crear el componente
        }
    },
    methods: {
        formatNumber(number) {
            if (number >= 1000) {
                const rounded = Math.round(number / 1000);
                return `${rounded}k`;
            } else {
                return number.toString();
            }
        }

    }

};
</script>