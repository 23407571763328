<template>
  <v-container fluid>
    <v-card outlined>
      <v-row no-gutters justify="center">
        <span style="font-size: 24px; padding-top: 1rem" class="mb-2">
          Centro produccion <v-icon>production_quantity_limits</v-icon>
        </span>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="3" md="3" class="pt-0 pb-0">
          <v-autocomplete dense v-model="filters.business_unit_id" :items="$store.state.auth.bussines_units"
            label="Unidad de negocio" color="cyanText" prepend-inner-icon="corporate_fare" outlined
            required></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3" md="3" class="pt-0 pb-0">
          <v-autocomplete dense v-model="filters.location_id" :items="$store.state.auth.locations.filter(
            (element) => element.bus_id == filters.business_unit_id
          )
            " color="cyanText" label="Location" prepend-inner-icon="my_location" outlined required clearable>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="1" md="1" class="pt-0 pb-0">
          <v-row class="pt-2" justify="center" no-gutters>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" class="mr-2" size="40px" v-bind="attrs" v-on="on" @click="loadCenters()">
                  search
                </v-icon>
              </template>
              <span>Buscar centro con estos parametros</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table :headers="headers" :items="centers" :search="search" sort-by="calories" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Centros produccion</v-toolbar-title>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field class="mt-6" v-model="search" label="Buscar"></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" width="65%" height="50vh" persistent hide-overlay
              transition="dialog-bottom-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" outlined dark class="mb-2" v-bind="attrs" v-on="on">
                  <v-icon>add</v-icon>
                  Nuevo centro produccion
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  {{ formTitle }}
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <v-row justify="center">
                      <v-col cols="12" sm="4" md="4" class="pt-0 pb-0">
                        <v-autocomplete dense v-model="editedItem.business_unit_id"
                          :items="$store.state.auth.bussines_units" label="Unidad de negocio" color="cyanText"
                          prepend-inner-icon="corporate_fare" outlined required></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="pt-0 pb-0">
                        <v-autocomplete dense v-model="editedItem.location_id" :items="$store.state.auth.locations.filter(
                          (element) =>
                            element.bus_id == editedItem.business_unit_id
                        )
                          " color="cyanText" label="Location" prepend-inner-icon="my_location" outlined required
                          clearable>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="4" sm="4" class="pt-0 pb-0">
                        <v-text-field dense v-model="editedItem.name" label="Nombre centro produccion" outlined />
                      </v-col>
                      <v-col cols="12" md="2" sm="2" class="pt-0 pb-0">
                        <v-row>
                          <v-checkbox v-model="editedItem.usb" @change="editedItem.red = !editedItem.usb"
                            label="USB"></v-checkbox>
                          <v-checkbox v-model="editedItem.red" @change="editedItem.usb = !editedItem.red"
                            label="RED"></v-checkbox>
                        </v-row>
                      </v-col>

                      <v-col cols="12" md="3" sm="3" class="pt-0 pb-0">
                        <v-text-field dense v-model="editedItem.ip_printer" label="IP Printer" outlined />
                      </v-col>
                      <v-col cols="12" md="3" sm="3" class="pt-0 pb-0">
                        <v-text-field dense v-model="editedItem.ip_server" label="IP Server" outlined />
                      </v-col>
                      <v-col cols="12" md="2" sm="2" class="pt-0 pb-0">
                        <v-text-field dense type="number" v-model="editedItem.puerto_server" label="Puerto" outlined />
                      </v-col>
                    </v-row>
                    <v-row justify="center" class="mt-2">
                      <v-btn @click="
                        (dialog = false),
                        (editedItem.name = ''),
                        (editedItem.id = null)
                        " plain>Cancelar</v-btn>
                      <v-btn @click="save" color="primary" outlined>Guardar</v-btn>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="600px">
              <v-card>
                <v-card-title class="text-h5">Esta seguro que deseas eliminar este proyecto?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small color="blue" class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small color="red" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { throws } from "assert";
import moment from "moment";
export default {
  name: "my-perfil",
  data() {
    return {
      dialogDelete: false,
      search: "",
      headers: [
        {
          text: "Codigo",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Nombre", value: "name" },
        { text: "Location", value: "description_long" },

        { text: "Actions", value: "actions", sortable: false },
      ],

      filters: {
        business_unit_id: "",
        location_id: "",
      },
      centers: [],
      editedIndex: -1,
      editedItem: {
        id: null,
        business_unit_id: null,
        location_id: null,
        name: "",
      },

      dialog: false,
    };
  },
  created() {
    //    this.init();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo centro produccion"
        : "Editar centro produccion";
    },
  },

  methods: {
    async loadCenters() {
      let filters = `where location_id=${this.filters.location_id}`;

      const response = await this.api_Post("restaurant/view_centers", {
        filters: filters,
      });

      this.centers = await response.json();
    },
    async editItem(item) {
      this.editedItem = { id: null, name: "" };
      this.editedIndex = this.centers.indexOf(item);

      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.centers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        const request = await this.api_Delete(
          "accounting/projects/" + this.editedItem.id
        );
        if (request.status == 200) {
          this.$toast.success("Categoria eliminado!");
          this.init();
        }
      } catch (error) {
        console.log(error);
      }
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.image_storage = null;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedItem.name == "") {
        this.$toast.info("Debes elegir un nombre");
        return;
      }
      if (this.editedItem.business_unit_id == null) {
        this.$toast.info("Debes elegir una unidad de negocio");
        return;
      }
      if (this.editedItem.location_id == null) {
        this.$toast.info("Debes elegir un location");
        return;
      }

      try {
        let data = {
          id: this.editedItem.id,
          name: this.editedItem.name,
          location_id: this.editedItem.location_id,
          usb: this.editedItem.usb,
          red: this.editedItem.red,
          ip_printer: this.editedItem.ip_printer,
          ip_server: this.editedItem.ip_server,
          puerto_server: this.editedItem.puerto_server
        };

        if (this.editedItem.id === null) {
          const response = await this.api_Post("restaurant/center", data);
          if (response.status == 200) {
            this.$toast.success("Centro creado con exito");
            this.loadCenters();
            this.clear();
          } else {
            this.$toast.error("error ");
          }
        } else {
          const response = await this.api_Put("restaurant/center", data);
          if (response.status == 200) {
            this.$toast.success("Centro actualizada con exito");
            this.dialog = false;
            this.clear();
            this.loadCenters();
          } else {
            this.$toast.error("error ");
          }
        }
      } catch (e) {
        this.$toast.error("error " + e.message);
      }

      // this.close();
    },
    clear() {
      this.editedItem.id = null;
      this.editedItem.business_unit_id = null;
      this.editedItem.location_id = null;
      this.editedItem.name = "";
    },
    getDate(date) {
      let newDate = moment(date).format("YYYY-MM-DD HH:MM:SS A").toString();
      return newDate;
    },
  },
};
</script>
