<template>
  <v-flex>
    <v-row class="mt-2" justify="center">
      <v-col cols="12" md="12">
        <v-row justify="center">
          <span class="mx-3" style="font-size: 34px; color: #616161">Aprobadores</span>
        </v-row>
        <v-row justify="center" class="mt-n1 mx-1">
          <span style="font-size: 16px; color: #757575">Crea nivles para los aprobadores
          </span>
        </v-row>
        <v-row justify="center">
          <search-header @dataFilters="changeBussines($event)" :type="false" :show_department="false"
            :show_weeks="false" />
        </v-row>
        <v-row justify="center">
          <v-col cols="10">
            <v-data-table v-if="!loading" :headers="headers" :items="approvers" :search="search" sort-by="calories"
              class="elevation-1">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Aprobadores</v-toolbar-title>

                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field class="mt-2" v-model="search" label="Buscar"></v-text-field>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="1000px" hide-overlay transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-show="last_data.business != 0 && last_data.location != 0
                        " color="primary" outlined dark class="mb-2" v-bind="attrs" v-on="on">
                        <v-icon>add</v-icon>
                        Nuevo aprobador
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar dark color="primary">
                        <v-btn icon dark @click="close">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <v-card-text class="mt-4">
                        <v-row justify="center">
                          <v-col cols="12" md="4">
                            <v-autocomplete dense outlined v-model="editedItem.user_id" :items="users" label="Usuario"
                              color="cyanText" item-text="use_fullname" item-value="use_id"
                              @change="updateRange(rangeSelected)" required></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-autocomplete dense outlined v-model="editedItem.status_id" :items="order_status"
                              label="Nivel" color="cyanText" item-text="name" item-value="id"
                              @change="updateRange(rangeSelected)" required></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field type="number" dense outlined v-model="editedItem.limit_amount"
                              label="Monto"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="justify-center">
                        <v-btn color="red darken-1" text @click="close">
                          Cancelar
                        </v-btn>
                        <v-btn color="primary" outlined >
                          Guardar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to delete this
                        item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-show="last_data.business != 0 && last_data.location != 0
                      " v-bind="attrs" v-on="on" medium color="primary" @click="editItem(item)">
                      edit
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.status_id`]="{ item }">
                <span v-if="order_status.length > 0">
                  {{
                    order_status.filter(
                      (element) => element.id === item.status_id
                    )[0].name
                  }}</span>
              </template>
              <template v-slot:[`item.limit_amount`]="{ item }">
                <span> {{ currencyFormat(item.limit_amount) }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-flex>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  data: () => ({
    last_data: {
      business: 0,
      location: 0,
      department_id: 0,
      dateStart: moment().startOf("month").format("YYYY-MM-DD"),
      dateEnd: moment().endOf("month").format("YYYY-MM-DD"),
    },
    headers: [
      // {
      //     text: "Codigo",
      //     align: "start",
      //     sortable: false,
      //     value: "code",
      // },
      { text: "Nombre", value: "use_fullname" },
      { text: "Unidad negocio", value: "business_units_description" },
      { text: "Location", value: "location_description" },
      { text: "Status", value: "status_id" },
      { text: "Monto", value: "limit_amount" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      business_unit_id: 0,
      location_id: 0,
      user_id: null,
      limit_amount: null,
      status_id: null,
      business_units_description: "",
      location_description: "",
      use_fullname: "",
    },
    defaultItem: {
      business_unit_id: 0,
      location_id: 0,
      user_id: null,
      limit_amount: null,
      status_id: null,
      business_units_description: "",
      location_description: "",
      use_fullname: "",
    },
    approvers: [],
    order_status: [],
    users: [],
    search: "",
    dialog: false,
    dialogDelete: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva aprobador" : "Editar aprobador";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    //  this.initialize();
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      let result_orders = await this.api_Get("shopping/order_status");
      const data = await result_orders.json();
      for (let item of data) {
        if (item.id >= 4 && item.id <= 9) {
          this.order_status.push(item);
        }
      }
      const responseUsers = await this.api_Get("auth/users");

      this.users = await responseUsers.json();
    },
    async getData(data) {
      this.loading = true;
      let filters = " ";
      filters +=
        data.business == 0
          ? " "
          : " WHERE  BUSINESS_UNITS.ID in (" + data.business + ")";
      //  filters += data.location == null || data.location == 0 ? " " : " AND  LOCATION_ID IN (" + data.location + ")"
      let resultapprovers = await this.api_Post("shopping/approvers", {
        filters: filters,
      });

      this.approvers = await resultapprovers.json();
      this.loading = false;
      console.log(this.approvers);
    },
    async changeBussines(data) {
      this.last_data = data;
      if (data.business == 0) {
        this.getData({
          business: this.$store.state.auth.user.acess_business_unit_finance,
          location: this.$store.state.auth.user.acess_locations,
          dateStart: data.dateStart,
          dateEnd: data.dateEnd,
        });
      } else {
        this.getData(data);
      }
    },
    async editItem(item) {
      this.editedIndex = this.approvers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      // let data = {
      //     business_unit_id: this.editedItem.business_unit_id,
      //     invoice_voucher_type_id: this.editedItem.invoice_voucher_type_id
      // }
      // try {
      //     let resultVouchers = await this.api_Post("accounting/resume_vouchers", data);
      //     this.vouchers_selected = await resultVouchers.json()
      //     this.dialogViewVoucher = true
      // } catch (error) {

      //     console.log(error)
      // }
    },
    deleteItemConfirm() {
      this.approvers.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped></style>
