<template>
    <v-container>
        <!-- <v-row> <invoice /> </v-row> -->
        <v-row justify="center">
            <v-col cols="12">
                <v-row justify="center" class="mt-4 mx-1 mb-2">
                    <v-icon class="mx-2" size="35px">receipt_long</v-icon>

                    <span style="font-size: 34px; color: #616161">Recepcion de ordenes</span>
                </v-row>
                <v-row justify="center" class="mb-4">
                    <v-col cols="12" md="12">
                        <v-row justify="center">
                            <search-header @dataFilters="changeBussines($event)" :selected_week="5" class="pt-3" />
                        </v-row>
                    </v-col>


                </v-row>
                <v-card class="rounded-lg">
                    <v-data-table :search="search" :headers="headers" :items="orders" class="elevation-0">
                        <template v-slot:top>
                            <v-toolbar class="elevation-0 rounded-lg">
                                <v-toolbar-title>Ordenes</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-text-field label="Buscar..." class="mt-6" v-model="search"></v-text-field>
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                    <!-- <v-autocomplete class="mt-6" dense outlined v-model="selectedStatus"
                                        :items="order_status" item-text="name" item-value="value" color="cyanText"
                                        label="Status" required></v-autocomplete> -->
                                </v-col>

                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.order_id`]="{ item }">
                            <v-hover v-slot="{ hover }">
                                <span @click="showOrder(item.order_id, false, 2)"
                                    class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                                        color: hover ? 'red' : 'black',
                                        cursor: hover ? 'pointer' : '',
                                        'text-decoration': hover ? 'underline' : '',
                                    }">
                                    {{ filLeft(item.order_id.toString(), 12, '0') }}</span>
                            </v-hover>
                        </template>

                        <template v-slot:[`item.entity_name`]="{ item }">
                            <v-hover v-slot="{ hover }">
                                <span @click="viewEntity(item.entity_id)" style="font-size: 14px"
                                    class="primary--text font-weight-normal text-truncate font-weight-semibold" :style="{
                                        color: hover ? 'red' : 'black',
                                        cursor: hover ? 'pointer' : '',
                                        'text-decoration': hover ? 'underline' : '',
                                    }">{{ item.entity_name }}</span></v-hover>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip outlined small class="white--text" :color="time_line_orders(item.last_status_id).color">

                                {{ item.last_status_name }}
                            </v-chip>
                        </template>
                        <template v-slot:no-data>
                            <v-row justify="center" class="pt-10">
                                <v-icon size="80px">token</v-icon>
                            </v-row>
                            <v-row justify="center" class="pb-6">
                                No hay datos
                            </v-row>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Filters from "../../components/FilterBussinesLocations";
import HeaderFilters from "@/components/Shop/HeaderFilters.vue";
import Order from "../../components/Shop/Order.vue";
export default {
    components: { Order, HeaderFilters },
    data: () => ({
        search: "",
        dialog: false,
        dialogDelete: false,
        suplidor: false,
        cliente: false,
        disabledName: false,
        plazoPagoSuplidor: null,
        plazoPagoCliente: null,
        documents: [],
        rncValid: false,
        selectedStatus: "",
        accounts: [],
        order_status: [],
        orders: [],
        cxc: "",
        cxp: "",
        selectedDocument: "",
        selectedtypesNcfCliente: "",
        selectedtypesNcfSuplidor: "",
        selectedProvincies: "",
        headers: [
            {
                text: "Orden",
                align: "start",

                value: "order_id",
            },
            { text: "Unidad negocio", value: "business_unit_description_long" },
            { text: "Location", value: "location_description_long" },
            { text: "Suplidor", value: "entity_name" },
            { text: "Status", value: "status" },
            // { text: "Total", value: "total_amount", align: "end" },
            // { text: "Status", value: "sta_description" },

            //{ text: "Actions", value: "actions", sortable: false },
        ],

        editedIndex: -1,
        editedItem: [],
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "Nueva orden" : "Editar orden";
        },
    },

    watch: {},

    created() {
        this.initialize();
    },

    methods: {

        changeBussines(item) {

            let filters = ""

            filters += "AND  last_status_id in (13,14) "
            filters += item.business != 0 ? " AND business_unit_id=" + item.business : ""
            filters += item.location != 0 ? " AND location_id=" + item.location : ""
            filters += item.department_id != 0 ? " AND location_id=" + item.location : ""
            filters += item.dateStart != 0 ? " and requested_at>= " + `'${item.dateStart}'` : ''
            filters += item.dateEnd != 0 ? " and requested_at<= " + `'${item.dateEnd}'` : ''
            this.getData(filters)
        },
        async getData(filters) {

            let resultOrders = await this.api_Post("shopping/orders", { filters: filters });
            this.orders = await resultOrders.json();

        },
        async initialize() {


            let result_orders = await this.api_Get("shopping/order_status");
            this.order_status = await result_orders.json();
            this.order_status.unshift({ id: 0, name: "Todos" })
            this.selectedStatus = this.order_status[0].id

        },

        editItem(item) {
            this.editedIndex = this.entitys.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        deleteItem(item) {
            this.editedIndex = this.entitys.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm() {
            this.entitys.splice(this.editedIndex, 1);
            this.closeDelete();
        },

        close() {
            this.dialog = false;

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        async save(item) {

            const responseRequest = await this.api_Post(
                "shopping/order_register",
                item
            );

            if (responseRequest.status == 200) {
                this.getData();

                let data = await responseRequest.json();

                // this.$router.push({
                //     name: "ViewInvoice",
                //     params: { id: data[0].invoice_number },
                // });

                // this.$toast.success("agregado con exito!");
                this.dialog = false;
            } else {
                this.$toast.error("error al registrar la factura!");
            }
        },
    },
};
</script>

<style></style>
