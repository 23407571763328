import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
import SearchBussinesLocations from "./components/FilterBussinesLocations.vue";
import SearchHeader from "./components/SearchHeader.vue";
import Gfunctions from "./services/global_functions";
import Toast from "vue-toastification";
import VueApexCharts from 'vue-apexcharts'

import "vue-toastification/dist/index.css";
import "./plugins/vuetify-mask.js";
import Carousel3d from "vue-carousel-3d";
import MaterialCard from "./components/base/MaterialCard.vue"

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import CountryFlag from 'vue-country-flag'
// Vue.prototype.$localhost = process.env.VUE_APP_CONNECTION;
const options = {
  // You can set your default options here
};

Vue.config.productionTip = false;

Vue.component('base-material-card',MaterialCard)
Vue.component("search-bussines-locations", SearchBussinesLocations);
Vue.component("search-header", SearchHeader);
Vue.component("apexchart", VueApexCharts);
Vue.component('country-flag', CountryFlag)
Vue.use(LottieVuePlayer);
Vue.use(Toast, options);

Vue.use(VueApexCharts);
Vue.use(PerfectScrollbar)
Vue.use(Gfunctions);

Vue.use(Carousel3d);
new Vue({
    router,
    vuetify,
    store,
  render: (h) => h(App),
}).$mount("#app");
