<template>
  <v-container fluid pa-8>
    <v-layout align-start>
      <v-flex>
        <v-card class="pl-9" width="100%" outlined>
          <v-card-title>
            <v-spacer></v-spacer>
            <span>Informacion personal</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" sm="3">
                <div>
                  <v-img style="border-radius: 50%" :aspect-ratio="8 / 8" width="70%" max-width="220px"
                    :src="image_profile">
                  </v-img>
                  <v-btn depressed class="pl-4" text color="primary" @click="dialogImage = true">
                    Cambiar imagen
                    <v-icon>photo_camera</v-icon>
                  </v-btn>
                </div>
                <div class="my-3"></div>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-text-field class="pl-6" label="Nombre" prepend-icon="account_box" v-model="data_users.use_fullname"
                  readonly></v-text-field>
                <v-text-field class="pl-6" label="Nombre de usuario" prepend-icon="account_box"
                  v-model="data_users.use_username" readonly></v-text-field>
                <v-text-field class="pl-6" label="Rol" prepend-icon="account_box" v-model="data_users.userRol"
                  readonly></v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-text-field label="Contraseña actual" prepend-icon="lock_open"
                  :type="showPassword.old ? 'text' : 'password'" :append-icon="showPassword.old ? 'visibility' : 'visibility_off'
                    " @click:append="showPassword.old = !showPassword.old" v-model="data_users.oldPassword"
                  :disabled="updatePassword == false"></v-text-field>
                <v-text-field label="Nueva Contraseña" prepend-icon="key" :type="showPassword.new ? 'text' : 'password'"
                  :append-icon="showPassword.new ? 'visibility' : 'visibility_off'
                    " @click:append="showPassword.new = !showPassword.new" v-model="data_users.newPassword"
                  :disabled="updatePassword == false"></v-text-field>
                <v-text-field label="Repita la nueva contraseña" v-model="data_users.checkPassword" prepend-icon="key"
                  :type="showPassword.check ? 'text' : 'password'" :append-icon="showPassword.check ? 'visibility' : 'visibility_off'
                    " @click:append="showPassword.check = !showPassword.check"
                  :disabled="updatePassword == false"></v-text-field>
                <v-btn depressed color="success" @click="changePassword()" block v-show="updatePassword == true">
                  <v-icon>lock_reset</v-icon>
                  Actualizar!
                </v-btn>
                <v-btn depressed text color="primary" block @click="updatePassword = true"
                  v-show="updatePassword == false">
                  <v-icon>lock</v-icon>
                  Actualizar contraseña
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="pt-6"></v-divider>
            <v-card flat>
              <v-card-title>
                <v-spacer></v-spacer>
                <span>Apariencia</span>

                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-card @mouseenter="size.indigo = 30" @mouseleave="size.indigo = 25"
                          @click="changeColor('indigo')" v-bind="attrs" v-on="on" outlined :height="size.indigo"
                          :width="size.indigo" color="#3F51B5"></v-card>
                      </template>
                      <span>Indigo</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" sm="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-card @click="changeColor('azul')" @mouseenter="size.azul = 30" @mouseleave="size.azul = 25"
                          v-bind="attrs" v-on="on" outlined :height="size.azul" :width="size.azul"
                          color="#2196F3"></v-card>
                      </template>
                      <span>Azul</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" sm="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-card @click="changeColor('naranja')" @mouseenter="size.yellow = 30"
                          @mouseleave="size.yellow = 25" v-bind="attrs" v-on="on" outlined height="25" width="25"
                          color="#FF9800"></v-card>
                      </template>
                      <span>Naranja</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-card @click="changeColor('rojo')" @mouseenter="size.red = 30" @mouseleave="size.red = 25"
                          v-bind="attrs" v-on="on" outlined height="25" width="25" color="#FF3D00"></v-card>
                      </template>
                      <span>Rojo</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" sm="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-card @click="changeColor('gris')" @mouseenter="size.gray = 30" @mouseleave="size.gray = 25"
                          v-bind="attrs" v-on="on" outlined height="25" width="25" color="#424242"></v-card>
                      </template>
                      <span>Gris</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" sm="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-card @click="changeColor('verde')" @mouseenter="size.green = 30" @mouseleave="size.green = 25"
                          v-bind="attrs" v-on="on" outlined height="25" width="25" color="success"></v-card>
                      </template>
                      <span>Verde</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="saveChanges()" text>
              <v-icon> done </v-icon>
              Guardar cambios
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-dialog v-model="dialogImage" max-width="35%">
          <v-card>
            <v-card-title class="headline">Seleccionar imagen</v-card-title>
            <v-card-text>
              <v-file-input v-model="foto" name="upload" id="fileInput" accept="image/png, image/jpeg, image/jpg"
                placeholder="Imagen" prepend-icon="photo_camera" label="Escoja un archivo"></v-file-input>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="red darken-1" text @click="dialogImage = false">Cancelar!</v-btn>
              <v-btn color="success" text @click="upImage()">Subir imagen!</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
//import UploadService from "@/services/UploadFilesUsersServices";

import App from "@/App";

export default {
  data: () => ({
    censo: [],
    updatePassword: false,
    dialogImage: false,
    image: "",
    foto: null,
    image_profile: null,
    transparent: "rgba(255, 255, 255, 0)",
    loadingImage: false,
    newPerfil: {

      oldPassword: "",
      newPassword: "",
      checkPassword: "",

      userRol: "USUARIO",
      color_preference: ""
    },
    size: {
      indigo: 25,
      azul: 25,
      yellow: 25,
      red: 25,
      gray: 25,
      green: 25,
    },
    data_users: [],
    showPassword: {
      old: false,
      new: false,
      check: false,
    },
  }),

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      let id = this.$store.state.auth.user.user.user_id;

      const resultData = await this.api_Get("auth/user_details/" + id);
      let data = await resultData.json();

      this.data_users = data[0];

      if (this.data_users.image_storage != null) {
        this.image_profile = await this.get_storage(
          "image_users",
          this.data_users.image_storage
        );
      }
    },
    changeColor(color) {
      let setColor = "";
      switch (color) {
        case "indigo":
          setColor = "#3F51B5";
          break;
        case "azul":
          setColor = "#1976D2";
          break;
        case "naranja":
          setColor = "#FF9800";
          break;
        case "verde":
          setColor = "#009688";
          break;
        case "rojo":
          setColor = "#FF3D00";
          break;
        case "gris":
          setColor = "#424242";
          break;
      }

      //this.newPerfil.color_preference = setColor;
      this.$vuetify.theme.themes.light.primary = setColor;
      window.localStorage.setItem("color_preference", setColor);
    },
    async saveChanges() {
      // $vuetify.theme.dark=true
      const result = await this.api_Put("auth/perfil", {
        id: this.newPerfil.id,
        color_preference: this.newPerfil.color_preference,
      });

      const data = await result.json();
      // src="http://128.1.4.88:3040/api/recruitment/persons/file/1/241427">
      if (result.status == 200) {
        this.$toast.success("Cambios guardados con exito!");
      }
    },
    changeTheme() {
      $vuetify.theme.dark = !$vuetify.theme.dark;
      //@click="$vuetify.theme.dark=!$vuetify.theme.dark"
    },
    async changePassword() {
      if (this.data_users.newPassword != this.data_users.checkPassword) {
        this.$toast.warning("Las contraseñas no coinciden!");
        return;
      }
      console.log(this.newPerfil.oldPassword)
      const result = await this.api_Put("auth/updatePassword", {
        id: this.$store.state.auth.user.user.user_id,
        password: this.data_users.oldPassword,
        newpassword: this.data_users.newPassword,
      });
      if (result.status == 200) {
        this.$toast.success("Cambios guardados con exito!");
        this.data_users.oldPassword = "";
        this.data_users.newPassword = "";
        this.data_users.checkPassword = "";
        this.updatePassword = false;
      } else if (result.status == 401) {
        this.$toast.error("Contraseña Incorrecta!");
      } else {
        this.$toast.error("Error al cambiar la contraseña!");
      }
    },

    async upImage() {
      try {
        const fileInput = document.getElementById("fileInput");
        let record = await this.upload_images("image_users", fileInput);

        const responseRequest = await this.api_Put("auth/userImage", {
          user_id: this.$store.state.auth.user.user.user_id,
          image_storage: record.id,
        });
        if (responseRequest.status == 200) {
          this.$toast.success("imagen actualizada con exito!");
          if (this.data_users.image_storage != null) {

          }
          this.initialize();
        } else {

          this.$toast.error("error al agregar!");
        }
      } catch (e) {
        this.$toast.error("error " + e.message);
      }
      this.dialogImage = false;
    },
  },
};
</script>
