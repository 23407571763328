<template>
  <v-dialog v-model="show_dialog" fullscreen persistent>
    <v-card outlined color="#F4F5FB">
      <v-card-title>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" small @click="showBar = !showBar" v-click-outside="onClickOutside"
              color="primary" outlined class="mr-3">
              <v-icon>{{ showBar == false ? "menu" : "menu_open" }}</v-icon>
            </v-btn>
          </template>
          <span>{{ showBar == false ? "Ver menu" : "Ocultar menu" }}</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <span :style="`color:#757575;font-size:20px`">{{ dialogTitle }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('closeDialog', false)">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-divider class="mx-2" inset></v-divider>
        <NavigationButonsTop :showBar="showBar" />
        <v-container fluid class="pa-1">
          <v-row class="mt-2" justify="center">
            <v-col cols="12" md="7" class="pt-0 mt-0 pb-0">
              <v-card width="100%" min-height="350" outlined>
                <v-card-title class="pb-0 mb-0">
                  <v-row>
                    <v-col cols="12" md="9" class="pb-0 mb-2">
                      <span class="pb-0 mb-0">
                        Lista de articulos solicitados</span>
                      <br class="pa-0 ma-0" />
                      <span :style="`color:#757575;font-size:15px`" class="pt-0 mt-0">Enviado desde:
                        {{ firtsLetterMinRestUp(item.warehause_from) }}</span>
                    </v-col>
                    <v-col cols="12" md="3" align="end" class="pb-0 mb-0 pt-0 mt-0">
                      <v-checkbox v-model="receivedType" label="Recepcion completa" @change="updateReceived">
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-container fluid class="pt-0">
                  <v-data-table :headers="headersArticle" :search="search" :items="item_list">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <span>Total: {{ item_list.length }}</span>
                        <v-spacer></v-spacer>
                        <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar"
                          single-line hide-details>
                        </v-text-field>
                        <v-spacer></v-spacer>
                        <v-divider class="mx-3" inset vertical></v-divider>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.item_image`]="{ item }">
                      <v-avatar size="40">
                        <v-img :src="item.image"></v-img>
                      </v-avatar>
                    </template>
                    <template v-slot:[`item.received_quantity`]="{ item }">
                      <v-text-field class="text-xs-center" v-model="item.received_quantity" dense :disabled="receivedType"
                        :max="item.requested_quantity" type="number" min="0">
                      </v-text-field>
                    </template>
                  </v-data-table>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :color="receivedType == true ? 'success' : 'primary'" text @click="saveReceived()">
                    {{
                      receivedType == true
                      ? "Guardar recepcion completa"
                      : "Guardar recepcion parcial"
                    }}
                    <v-icon class="pl-2 pb-1">
                      {{
                        receivedType == true
                        ? "system_update_alt"
                        : "assignment_returned"
                      }}</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import NavigationButonsTop from "@/components/Inventory/NavigationButonsTop.vue";
export default {
  data: () => ({
    receivedType: true,
    search: "",
    showBar: false,
    headersArticle: [
      {
        align: "start",
        value: "item_image",
      },
      { text: "ID", value: "item_id" },
      { text: "Articulo", value: "item_name" },
      {
        text: "Cantidad solicitada",
        value: "requested_quantity",
        align: "center",
      },
      {
        text: "Cantidad recibida",
        value: "received_quantity",
        width: "20%",
        align: "center",
      },
      { text: "", value: "remove" },
    ],
  }),
  name: "ReceptionItems",
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    item_list: {
      type: Array,
      default() {
        return [];
      },
    },
    item: {
      type: Object,
      default() {
        return {
          status: null,
          location_from: null,
          warehouse_from: null,
        };
      },
    },
  },
  created() { },
  mounted() {
    this.updateReceived();
  },
  computed: {
    dialogTitle() {
      return "Recepcion de articulos #" + this.item.id;
    },
  },
  watch: {},
  methods: {
    saveReceived() {
      this.$emit("save_received", this.item.id);
    },
    updateReceived() {
      for (let key in this.item_list) {
        this.item_list[key].received_quantity =
          this.receivedType == true
            ? this.item_list[key].requested_quantity
            : 0;
      }
    },
    onClickOutside() {
      this.showBar = false;
    },
  },
  components: {
    NavigationButonsTop,
  },
};
</script>
