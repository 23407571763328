<template>
  <v-row justify="center">
    <v-col cols="12" md="2" class="pt-0 pb-0 pr-0">
      <v-autocomplete v-model="business_selected" :items="$store.state.auth.bussines_units" label="Unidad de negocio"
        color="cyanText" prepend-inner-icon="corporate_fare" outlined dense required></v-autocomplete>
    </v-col>
    <v-col cols="12" md="2" class="pt-0 pb-0 pr-0">
 
      <v-autocomplete v-model="location_selected" :items="locationsFrombusiness" color="cyanText" label="Location"
        prepend-inner-icon="my_location" outlined return-object required dense>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="2" class="pt-0 pb-0 pr-0">
      <v-autocomplete v-model="filters.warehouse" :items="itemsWarehouse.filter(
        (element) => element.location_id == location_selected.id
      )
        " color="cyanText" label="Almacen" item-value="id" item-text="name" prepend-inner-icon="business" outlined
        required dense return-object>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="3" md="2" class="pt-0 pb-0 pr-0">
      <v-autocomplete dense outlined v-model="rangeSelected" :items="rangeTime" label="Rango" color="cyanText"
        prepend-inner-icon="mdi-calendar" @change="updateRange()" required></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="2" md="2" class="pt-0 pb-0 pr-0" v-show="rangeSelected == 5">
      <v-menu v-model="menuStart" :close-on-content-click="false" :nudge-right="10" transition="scale-transition" offset-y
        min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field dense outlined v-model="dateFormatDMY" label="Desde" prepend-inner-icon="mdi-calendar" readonly
            v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker :max="filters.dateEnd" v-model="filters.dateStart"
          @input="refresFilters(), (menuStart = false)"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="2" md="2" class="pt-0 pb-0 pr-0" v-show="rangeSelected == 5">
      <v-menu v-model="menuEnd" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
        min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field dense outlined v-model="date2FormatDMY" label="Hasta" prepend-inner-icon="mdi-calendar" readonly
            v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker :min="filters.dateStart" v-model="filters.dateEnd"
          @input="refresFilters(), (menuEnd = false)"></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
import moment from "moment";
export default {
  data: () => ({
    filters: {
      location: { catalogue_id: null, id: null },
      business: null,
      warehouse: { id: null, name: null },
      dateStart: moment().startOf("month").format("YYYY-MM-DD"),
      dateEnd: moment().endOf("month").format("YYYY-MM-DD"),
    },
    rangeTime: [
      { text: "Hoy", value: "1" },
      { text: "Este semana", value: "2" },
      { text: "Este mes", value: "3" },
      { text: "Este año", value: "4" },
      { text: "Personalizado", value: "5" },
    ],
    itemsWarehouse: [],
    rangeSelected: "3",
    menuStart: false,
    menuEnd: false,
  }),
  name: "HeaderFilters",
  props: {
    filter_default: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {
    this.initialize();
  },
  computed: {
    business_selected: {
      get() {
        return this.$store.state.app.filters.business;
      },
      set(value) {
        this.$store.commit("setBusiness", value);
      },
    },
    location_selected: {
      get() {
        return this.$store.state.app.filters.location;
      },
      set(value) {
        this.$store.commit("setLocation", value);
      },
    },
    locationsFrombusiness() {
      let data=this.$store.state.auth.locations.filter(
        (element) => element.bus_id == this.business_selected
      );
    
      return  data
    },
    dateFormatDMY() {
      return moment(this.filters.dateStart).format("DD/MM/YYYY");
    },
    date2FormatDMY() {
      return moment(this.filters.dateEnd).format("DD/MM/YYYY");
    },
  },
  watch: {
    "filters.business"() {
      let locations = this.locationsFrombusiness;
      if (locations.length > 0 && this.filter_default.length == 0) {
        this.filters.location = locations[0];
      }
    },
    location_selected() {
      let warehouse = this.itemsWarehouse.filter(
        (element) => element.location_id == this.location_selected.id
      );
      if (warehouse.length > 0 && this.filter_default.length == 0) {
        this.filters.warehouse = warehouse[0];
      }
    },
    "filters.warehouse"() {
      this.refresFilters();
    },
  },
  methods: {
    async initialize() {
      const warehouses = await this.api_Get("inventory/allWarehouses");
      this.itemsWarehouse = await warehouses.json();

      if (this.itemsWarehouse.length > 0) {
        this.filters.warehouse = this.itemsWarehouse.filter(
          (element) => element.location_id == this.location_selected.id
        )[0];
      }
    },
    refresFilters() {
      this.filters.business = this.business_selected;
      this.filters.location = this.location_selected;

      this.$emit("refreshWarehouse", this.filters);
    },
    updateRange() {
      if (this.rangeSelected == 5) {
        return;
      }
      if (this.rangeSelected == 1) {
        this.filters.dateStart = moment().format("YYYY-MM-DD");
        this.filters.dateEnd = moment(this.filters.dateStart)
          .add(1, "days")
          .format("YYYY-MM-DD");
      }
      if (this.rangeSelected == 3) {
        this.filters.dateStart = moment().startOf("month").format("YYYY-MM-DD");
        this.filters.dateEnd = moment().endOf("month").format("YYYY-MM-DD");
      }
      if (this.rangeSelected == 2) {
        this.filters.dateStart = moment().startOf("week").format("YYYY-MM-DD");
        this.filters.dateEnd = moment().endOf("week").format("YYYY-MM-DD");
      }

      if (this.rangeSelected == 4) {
        this.filters.dateStart = moment().startOf("year").format("YYYY-MM-DD");
        this.filters.dateEnd = moment().endOf("year").format("YYYY-MM-DD");
      }
      this.refresFilters();
    },
  },
  components: {},
};
</script>
