<template>
  <v-flex>
    <v-row class="mt-2" justify="center">
      <v-col cols="12" md="12">
        <v-container>
          <v-row justify="center" class="mx-2">
            <span style="font-size: 34px; color: #616161">Almacenes </span>
          </v-row>
          <v-row justify="center" class="mt-n1 mx-2">
            <span style="font-size: 16px; color: #757575">Crea nuevos almacenes para distribuir y gestionar tu inventario.
            </span>
          </v-row>

          <v-row justify="center">
            <v-col cols="12" md="6">
              <search-bussines-locations :type="false" @filter_loaded="changeBussines($event)" />
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-dialog v-model="dialog" max-width="700px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="reaload">
                  <v-icon> add </v-icon>
                  Nuevo almacen
                </v-btn>
              </template>
              <v-card height="auto">
                <v-row no-gutters justify="center">
                  <span class="text-h4 text-center">{{ formTitle }}</span>
                </v-row>
                <v-row no-gutters justify="center">
                  <span class="text-center">Pertenece a <b> ({{ seletecName }})</b></span>
                </v-row>

                <v-card-text>
                  <v-row justify="center">
                    <v-col cols="12" md="12">
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" md="11">
                            <v-row class="pt-2">
                              <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                                <v-autocomplete dense outlined v-model="editedItem.business_units_id"
                                  :items="itemsBussines" :readonly="disableInput" label="Unidad de negocio"
                                  color="cyanText" @change="
                                    itemsLocations2 = updateCombo(
                                      itemsLocations,
                                      itemsLocations2,
                                      editedItem.business_units_id,
                                      'location'
                                    )
                                    " prepend-inner-icon="corporate_fare" required></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                                <v-autocomplete dense outlined :readonly="disableInput" v-model="editedItem.location_id"
                                  :items="itemsLocations2" color="cyanText" label="Location"
                                  prepend-inner-icon="my_location" required></v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-text-field v-model="editedItem.name" label="Nombre" outlined dense></v-text-field>
                            </v-row>
                            <v-row no-gutters>
                              <v-text-field v-model="editedItem.address" label="Direccion" outlined dense></v-text-field>
                            </v-row>

                            <v-row no-gutters>
                              <v-textarea v-model="editedItem.note" label="Descripcion" outlined dense></v-textarea>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions class="m">
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn outlined color="blue darken-1" @click="save">
                    Guardar
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <v-row v-if="lastFilter == undefined || lastFilter == '' || lastFilter == null
            " no-gutters justify="center">
            <v-col cols="12" class="mt-8">
              <v-row justify="center">
                <v-icon size="80px">token</v-icon>
              </v-row>
              <v-row justify="center">
                <span>Seleciona la unidad de negocio</span>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters justify="center">
            <v-col cols="12" md="6">
              <v-treeview return-object class="mt-0" activatable :items="data">
                <template slot="label" slot-scope="{ item }">
                  <span class="primary--text font-weight-bold">{{ item.id }} -
                  </span>
                  <span>{{ item.name }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon color="success"> store </v-icon>
                </template>
                <template slot="append" slot-scope="{ item }">
                  <v-row>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="ViewWarehouse(item.id)"><v-icon
                            color="primary">visibility</v-icon></v-btn>
                      </template>
                      <span>Ver almacen {{ item.name }}</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="editItem(item)"><v-icon
                            color="orange">edit</v-icon></v-btn>
                      </template>
                      <span>Editar almacen {{ item.name }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="addWerehouse(item)"><v-icon
                            color="success">add</v-icon></v-btn>
                      </template>
                      <span>Agregar nuevo almacen dentro de {{ item.name }}</span>
                    </v-tooltip>

                    <v-switch @change="updateState(item)" class="pa-3" style="margin-top: -4px" v-model="item.active"
                      :label="item.active == true ? 'ACTIVO' : 'INACTIVO'"></v-switch>
                  </v-row>
                </template>
              </v-treeview>
            </v-col>
          </v-row>
        </v-container> </v-col></v-row>
  </v-flex>
</template>

<script>
//import Filters from "../../components/FilterBussinesLocations";
export default {
  components: {},
  data: () => ({
    viewTable: false,
    dialog: false,
    dialogDelete: false,
    disableInput: false,
    lastFilter: undefined,
    itemsletiant: [],
    seletecName: "",
    itemsBussines: [],
    itemsLocations: [],
    itemsLocations2: [],
    itemSaved: [],
    filters: {
      business: null,
      location: null,
      department_id: null,
      routes: "",
    },
    headers: [
      {
        text: "Codigo",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Nombre", value: "name" },
      { text: "Unidad de negocio", value: "bussines_unit_description" },
      { text: "Location", value: "location_description_long" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    data: [],

    editedIndex: -1,
    editedItem: {
      name: "",
      note: "",
      address: "",
      business_units_id: null,
      location_id: null,
      parent: null,
    },
    defaultItem: {
      name: "",
      address: "",
      note: "",
      business_units_id: null,
      location_id: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo almacen" : "Editar almacen";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    reaload() {
      this.disableInput = false;
      this.editedIndex = -1;
      this.seletecName = "";
    },
    async updateState(item) {
      let data = {
        id: item.id,
        active: item.active,
      };
      const responseRequest = await this.api_Post(
        "inventory/warehouse/updateState",
        data
      );

      if (responseRequest.status == 200) {
      } else {
        this.$toast.error("error al actualizar!");
      }
    },
    async changeBussines(filter) {
      this.lastFilter = filter;
      // this.editedItem.business_units_id = filter.data.busines;
      // this.editedItem.location_id = filter.data.location;
      if (filter.business == 0) {
        this.data = [];
        return;
      }

      let filters = " where WAREHOUSES.BUSINESS_UNITS_ID =" + filter.business;
      if (filter.location != 0) {
        filters +=
          filter.location == null
            ? ""
            : " AND WAREHOUSES.LOCATION_ID=" + filter.location;
      }
      let data = {
        filters: filters,
      };
      const responseRequest = await this.api_Post(
        "inventory/warehouse_item",
        data
      );

      if (responseRequest.status == 200) {
        let data = await responseRequest.json();

        let child = [];
        for (let items of data) {
          child.push({
            id: items.id,
            name: items.name,
            parent: items.parent_warehouse_id,
            business_units_id: items.business_units_id,
            bussines_unit_description: items.bussines_unit_description,
            location_id: items.location_id,
            location_description_long: items.location_description_long,
            address: items.address,
            note: items.note,
            image_url: items.image_url,
            active: items.active,
          });
        }

        const nest = (items, id = null, link = "parent") =>
          items
            .filter((item) => item[link] === id)
            .map((item) => ({ ...item, children: nest(items, item.id) }));

        let result = nest(child);

        this.data = result;
      } else {
      }
    },
    async initialize() {
      this.itemsBussines = Object.assign(
        [],
        this.$store.state.auth.bussines_units
      );

      this.itemsLocations = this.$store.state.auth.locations;
    },
    addWerehouse(item) {
      this.disableInput = true;
      this.editedIndex = -1;
      let data = Object.assign({}, item);
      this.seletecName = data.name;
      this.editedItem = data;
      this.editedItem.parent = item.id;
      this.editedItem.name = "";
      this.editedItem.address = "";
      this.editedItem.note = "";
      this.itemSaved = data;
      this.itemsLocations2 = this.updateCombo(
        this.itemsLocations,
        this.itemsLocations2,
        this.editedItem.business_units_id,
        "location"
      );
      //this.editedItem.name = "";

      this.dialog = true;
    },
    updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
      itemsDestino.splice(0, itemsDestino.length);

      for (let item of itemsOrigen) {
        if (item.bus_id === condicion) {
          itemsDestino.push({
            value: item.value,
            text: item.text,
          });
        }
      }

      // this.filters.location = this.itemsLocations2[0].value;

      return itemsDestino;
    },
    editItem(item) {
      this.itemsletiant = [];
      this.editedIndex = 1;

      this.editedItem = Object.assign({}, item);
      this.itemsLocations2 = this.updateCombo(
        this.itemsLocations,
        this.itemsLocations2,
        this.editedItem.business_units_id,
        "location"
      );

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.data.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        let data = {
          id: this.editedItem.id,
          parent_warehouse_id: this.editedItem.parent,
          business_units_id: this.editedItem.business_units_id,
          location_id: this.editedItem.location_id,
          name: this.editedItem.name,
          address: this.editedItem.address,
          note: this.editedItem.note,
          image_url: "",
        };

        const responseRequest = await this.api_Put("inventory/warehouse", data);

        if (responseRequest.status == 200) {
          this.initialize();

          // this.$toast.success("agregado con exito!");
          this.dialog = false;
        } else {
          this.$toast.error("error al actualizar!");
        }
      } else {
        let data = {
          parent_warehouse_id:
            this.editedItem.parent == undefined ? null : this.editedItem.parent,
          business_units_id: this.editedItem.business_units_id,
          location_id: this.editedItem.location_id,
          name: this.editedItem.name,
          address: this.editedItem.address,
          note: this.editedItem.note,
          image_url: "",
        };

        const responseRequest = await this.api_Post(
          "inventory/warehouse",
          data
        );

        if (responseRequest.status == 200) {
          this.initialize();

          // this.$toast.success("agregado con exito!");
          this.dialog = false;
        } else {
          this.$toast.error("error al agregar!");
        }
      }
      this.changeBussines(this.lastFilter);
      this.close();
    },
  },
};
</script>
