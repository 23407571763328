<template>
  <v-flex>
    <v-btn @click="dialogSearchInvoice = true" small color="primary" class="mt-4" outlined>
      <v-icon>search</v-icon>Buscar factura
    </v-btn>
    <v-dialog persitent width="70%" v-model="dialogSearchInvoice">
      <v-card width="100%" height="100%">
        <v-toolbar flat dark color="transparent">
          <v-spacer></v-spacer>
          <v-btn @click="dialogSearchInvoice = false" icon dark>
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> </v-toolbar-title>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <v-card elevation="0">
            <v-data-table id="tabla" :headers="headers" :items="invoices" :search="search">
              <template v-slot:top>
                <v-toolbar flat id="toolbar">
                  <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar" single-line
                    hide-details>
                  </v-text-field>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon large color="blue" class="mr-2" v-bind="attrs" v-on="on" @click="selectItem(item)">
                      done
                    </v-icon>
                  </template>
                  <span>Selecionar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card></v-card-text>
      </v-card>
    </v-dialog>
  </v-flex>
</template>
<script>
export default {
  data: () => ({
    invoices: [],
    search: "",

    headers: [
      {
        text: "Factura",
        align: "start",

        value: "invoice_number",
      },
      { text: "Unidad negocio", value: "business_unit_description" },
      { text: "Location", value: "location_description" },
      { text: "Cliente", value: "entity_name" },
      { text: "Total", value: "total_amount", align: "end" },
      { text: "Status", value: "status_id" },

      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo contacto" : "Editar contacto";
    },
  },
  props: {
    types_invoices: {
      type: Number,
    },
    dialogSearchInvoice:{
      type:Boolean
    }
  },
  watch: {
    search(val, newVal) {
      if (newVal.length >= 1) {
        this.searchInvoice(newVal);
      } else {
        this.invoices = [];
      }
    },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    formTitle() {
      return this.types_invoices === 1 ? "Clientes" : "Facturas";
    },
  },
  created() {
    // console.log(this.type);
    // if (this.type == 2) {
    //   this.cliente = true;
    // }
    // if (this.type == 3) {
    //   this.suplidor = true;
    // }
    this.initialize();
  },

  methods: {
    async searchInvoice(val) {
      this.loading = true;
      let filters = "";
      filters +=
        " AND  BUSINESS_UNIT_ID in (" +
        this.$store.state.auth.user.acess_business_unit_finance +
        ") ";
      filters +=
        "  AND ( invoice_number ilike '%" +
        val +
        "%' OR entity_name ilike '%" +
        val +
        "%')";

      let resultInvoices = await this.api_Post("income/invoice_sales", {
        filters: filters,
      });

      this.invoices = await resultInvoices.json();
      this.loading = false;
    },

    async initialize() {
      // this.getData();
    },
    selectItem(item) {
      // console.log(item);
      this.$emit("selected-item", item);
    },
    deleteItemConfirm() {
      this.entitys.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style></style>
