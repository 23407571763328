<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="center">
          <span class="mx-3 mt-2" style="font-size: 34px; color: #616161">Catalogos</span>
        </v-row>
        <v-row justify="center" class="mt-n1 mx-1">
          <span style="font-size: 16px; color: #757575"
            >Crea nuevos catalogos de articulos
          </span>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <search-bussines-locations
              :type="false"
              @filter_loaded="changeBussines($event)"
            />
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="catalogues"
          :search="search"
          sort-by="calories"
          class="elevation-1 mt-2"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Catalogos</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                class="text-xs-center"
                v-model="search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
              >
              </v-text-field>
              <v-spacer></v-spacer>
              <v-dialog
                hide-overlay
                transition="dialog-bottom-transition"
                v-model="dialog"
                fullscreen
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    <v-icon>add</v-icon>
                    Nuevo catalogo
                  </v-btn>
                </template>

                <v-card color="#F4F5FB">
                  <v-toolbar color="#F4F5FB" elevation="0">
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="close">
                      <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title></v-toolbar-title>
                  </v-toolbar>
                  <v-container fluid>
                    <v-row justify="center" class="mt-n15">
                      <v-col cols="12" sm="3" md="3">
                        <v-autocomplete
                          dense
                          outlined
                          v-model="editedItem.business_unit_id"
                          :items="itemsBussines"
                          label="Unidad de negocio"
                          color="cyanText"
                          @change="
                            (itemSelected = []),
                              (itemsLocations2 = updateCombo(
                                itemsLocations,
                                itemsLocations2,
                                editedItem.business_unit_id,
                                'location'
                              ))
                          "
                          :readonly="editedIndex != -1"
                          prepend-inner-icon="corporate_fare"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3" md="3">
                        <v-autocomplete
                          dense
                          outlined
                          v-model="editedItem.location_id"
                          :items="itemsLocations2"
                          :readonly="editedIndex != -1"
                          @change="showWarehouse(), checkExits(), (itemSelected = [])"
                          color="cyanText"
                          label="Location"
                          prepend-inner-icon="my_location"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          dense
                          outlined
                          v-model="editedItem.name"
                          label="Nombre"
                          required
                        ></v-text-field> </v-col
                    ></v-row>

                    <v-container class="mt-n10">
                      <v-row class="mt-2" justify="center">
                        <span v-if="editedItem.business_unit_id">
                          MONEDA BASE
                          <b
                            >{{
                              itemsBussines.filter(
                                (element) => element.value == editedItem.business_unit_id
                              )[0].currency_description
                            }}
                          </b>
                          <country-flag
                            :country="
                              getFlag(
                                itemsBussines.filter(
                                  (element) =>
                                    element.value == editedItem.business_unit_id
                                )[0].currency_id
                              )
                            "
                            size="small"
                          />
                        </span>
                      </v-row>

                      <v-row class="mt-n2" justify="center">
                        <!-- <v-col cols="12" md="3">
                          <v-row justify="center">
                            <v-col cols="12">
                              <ArticleFilters
                                @getItemToEdit="addItem($event)"
                                :showButonNewArticle="false"
                                icon="arrow_right_alt"
                                name="Agregar"
                              />
                            </v-col>
                          </v-row>
                        </v-col> -->
                        <v-col cols="12" md="12">
                          <v-data-table
                            fixed-footer
                            fixed-header
                            :headers="headersItemSelected"
                            :items="itemSelected"
                            :search="search"
                            :items-per-page="8"
                            dense
                            class="elevation-1 mt-5"
                          >
                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-toolbar-title>Articulos selecionados</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <span>{{ itemSelected.length }}</span>

                                <v-spacer></v-spacer>
                                <v-text-field
                                  class="text-xs-center"
                                  v-model="search"
                                  append-icon="search"
                                  label="Buscar"
                                  single-line
                                  hide-details
                                >
                                </v-text-field>

                                <v-spacer></v-spacer>
                                <ArticleFilters
                                  @getItemToEdit="addItem($event)"
                                  :showButonNewArticle="false"
                                  icon="arrow_right_alt"
                                  name="Agregar"
                                />
                              </v-toolbar>
                            </template>
                            <template v-slot:[`item.min`]="{ item }">
                              <v-row class="mt-2">
                                <v-text-field
                                  style="font-size: 0.8vw"
                                  type="number"
                                  dense
                                  outlined
                                  min="0"
                                  v-model="item.min"
                                >
                                </v-text-field>
                              </v-row>
                            </template>

                            <template v-slot:[`item.max`]="{ item }">
                              <v-row class="mt-2">
                                <v-text-field
                                  style="font-size: 0.8vw"
                                  type="number"
                                  dense
                                  outlined
                                  min="0"
                                  v-model="item.max"
                                >
                                </v-text-field>
                              </v-row>
                            </template>
                            <template v-slot:[`item.category`]="{ item }">
                              <v-autocomplete
                                outlined
                                dense
                                style="font-size: 0.8vw"
                                v-model="item.category_id"
                                :items="
                                  categories.filter(function (objeto) {
                                    return objeto.parent_category_id !== null;
                                  })
                                "
                                label="Categoria"
                                item-text="name"
                                item-value="id"
                                color="cyanText"
                                hide-details
                                single-line
                              ></v-autocomplete>
                            </template>
                            <template v-slot:[`item.warehouse`]="{ item }">
                              <v-autocomplete
                                v-if="item.item_type_id == 1 || item.item_type_id == 5"
                                outlined
                                dense
                                style="font-size: 0.8vw"
                                v-model="item.warehouse_id"
                                :items="itemsWarehouse"
                                label="Almacen"
                                item-text="name"
                                item-value="id"
                                color="cyanText"
                                hide-details
                                single-line
                              ></v-autocomplete>
                              <span v-else>----</span>
                            </template>
                            <template v-slot:[`item.code`]="{ item }">
                              <v-row class="mt-2">
                                <v-text-field
                                  style="font-size: 0.8vw"
                                  dense
                                  outlined
                                  min="1"
                                  v-model="item.code"
                                >
                                </v-text-field>
                              </v-row>
                            </template>
                            <template v-slot:[`item.sale_price`]="{ item }">
                              <v-row class="mt-2" v-if="editedItem.business_unit_id">
                                <v-text-field
                                  style="font-size: 0.8vw"
                                  prefix="$"
                                  :suffix="
                                    itemsBussines.filter(
                                      (element) =>
                                        element.value == editedItem.business_unit_id
                                    )[0].currency_code
                                  "
                                  type="number"
                                  dense
                                  outlined
                                  min="1"
                                  v-model="item.sale_price"
                                >
                                </v-text-field>
                              </v-row>
                            </template>
                            <template v-slot:[`item.cost_price`]="{ item }">
                              <v-row class="mt-2" v-if="editedItem.business_unit_id">
                                <v-text-field
                                  style="font-size: 0.8vw"
                                  prefix="$"
                                  :suffix="
                                    itemsBussines.filter(
                                      (element) =>
                                        element.value == editedItem.business_unit_id
                                    )[0].currency_code
                                  "
                                  type="number"
                                  dense
                                  outlined
                                  min="1"
                                  v-model="item.cost_price"
                                >
                                </v-text-field>
                              </v-row>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    medium
                                    color="red"
                                    @click="deleteItemSelected(item)"
                                  >
                                    delete
                                  </v-icon>
                                </template>
                                <span>Quitar</span>
                              </v-tooltip>
                              <!-- <v-icon small class="mr-2" @click="editItem(item)">
                              mdi-pencil
                            </v-icon>
                            <v-icon small @click="deleteItem(item)">
                              mdi-delete
                            </v-icon> -->
                            </template>
                            <template v-slot:no-data>
                              <v-row justify="center" class="pt-10">
                                <v-icon size="80px">token</v-icon>
                              </v-row>
                              <v-row justify="center" class="pb-6"> No hay datos </v-row>
                            </template>
                          </v-data-table>
                        </v-col></v-row
                      >

                      <v-row justify="center">
                        <v-btn @click="dialog = false" text class="mx-2">Cancelar</v-btn>
                        <v-btn @click="save" color="primary" outlined>Guardar</v-btn>
                      </v-row>
                    </v-container>
                  </v-container>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this item?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  medium
                  color="blue"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-row justify="center" class="pt-10">
              <v-icon size="80px">token</v-icon>
            </v-row>
            <v-row justify="center" class="pb-6"> No hay datos </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ArticleFilters from "@/components/Inventory/ArticleSelectionList.vue";
export default {
  components: { ArticleFilters },
  props: {
    amount: {
      type: Number,
    },
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Unidad de negocio", value: "bussines_unit_description" },
      { text: "Location", value: "location_description" },

      { text: "Actions", value: "actions", sortable: false },
    ],
    headersItemSelected: [
      {
        text: "ID",
        align: "start",
        value: "item_id",
        width: "5%",
      },
      { text: "Descripcion", value: "item_name", width: "20%" },
      { text: "Medida", value: "unit_name", width: "11%", align: "center" },
      { text: "Categoria", value: "category", width: "12%" },
      { text: "R Min", value: "min", width: "5%", align: "center" },
      { text: "R Max", value: "max", width: "5%", align: "center" },
      {
        text: "Almacen a descontar",
        value: "warehouse",
        align: "center",
        width: "12%",
      },
      { text: "Codigo barra", value: "code", width: "8%" },
      { text: "Precio Compra", value: "cost_price", width: "10%" },
      { text: "Precio venta", value: "sale_price", width: "10%" },
      { text: "Acciones", value: "actions", sortable: false, width: "7%" },
    ],
    search: "",
    itemSelected: [],
    catalogues: [],
    editedIndex: -1,
    itemsBussines: [],
    itemsLocations: [],
    itemsLocations2: [],
    itemsWarehouse: [],
    categories: [],
    filters: {
      business: null,
      location: null,
    },
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New catalogo" : "Editar catalogo";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.itemsBussines = Object.assign([], this.$store.state.auth.bussines_units);

      //combo locations----------------------------------------------
      this.itemsLocations = this.$store.state.auth.locations;

      // this.filters.business = this.itemsBussines[0].value;
      // let resultCatalogues = await this.api_Get("inventory/catalogues");

      // this.catalogues = await resultCatalogues.json();
    },
    async changeBussines(filter) {
      let filters = " where   CATALOGUES.BUSINESS_UNIT_ID =" + filter.business;
      filters +=
        filter.location != null && filter.location != 0
          ? " and   CATALOGUES.LOCATION_ID =" + filter.location
          : "";
      const responseRequest = await this.api_Post("inventory/catalogues_bussines", {
        filters: filters,
      });

      if (responseRequest.status == 200) {
        this.catalogues = await responseRequest.json();
      }
    },
    checkExits() {
      let exits = this.catalogues.filter(
        (element) => element.location_id == this.editedItem.location_id
      );

      if (exits.length !== 0) {
        this.$toast.info("Ya existe un catalago en este location!!");
        this.dialog = false;
        this.itemSelected = [];
        this.editedItem = [];
      }
    },
    async showWarehouse() {
      if (
        this.editedItem.location_id != undefined ||
        this.editedItem.location_id != "undefined"
      ) {
        const warehouses = await this.api_Get(
          "inventory/warehouseForLocation/" + this.editedItem.location_id
        );
        this.itemsWarehouse = await warehouses.json();

        const categories = await this.api_Get(
          "inventory/categoryLocation/" + this.editedItem.location_id
        );
        this.categories = await categories.json();
      } else {
      }
    },
    async editItem(item) {
      this.editedIndex = this.catalogues.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.itemsLocations2 = this.updateCombo(
        this.itemsLocations,
        this.itemsLocations2,
        this.editedItem.business_unit_id,
        "location"
      );
      let resultCatalogues = await this.api_Get(
        "inventory/catalogues/details/" + this.editedItem.id
      );

      this.itemSelected = await resultCatalogues.json();
      console.log(this.itemSelected);
      this.showWarehouse();

      this.dialog = true;
    },
    addItem(item) {
      let newItem = item;
      newItem.max = 0;
      newItem.min = 0;

      if (this.editedItem.business_unit_id == undefined) {
        con;
        this.$toast.info("Debes seleccionar una unidad de negocio!");
        return;
      }
      if (this.editedItem.location_id == undefined) {
        this.$toast.info("Debes selecionar un location!");
        return;
      }
      let status = this.itemSelected.find((element) => element.item_id == item.item_id);

      if (status == undefined) {
        this.itemSelected.unshift(item);
      } else {
        this.$toast.info("El articulo no esta, o ya encuentra agregado!");
      }
    },
    async deleteItemSelected(item) {
      let data = { catalogue_id: item.catalogue_id, item_id: item.item_id };

      const responseRequest = await this.api_Post(
        "inventory/catalogues/deleteItem",
        data
      );

      if (responseRequest.status == 200) {
        let editedIndex = this.itemSelected.indexOf(item);

        this.itemSelected.splice(editedIndex, 1);
        this.$toast.success("articulo eliminado con exito!");
      } else {
        this.$toast.error("error al eliminar el catalogo!");
      }
    },
    deleteItem(item) {
      this.editedIndex = this.catalogues.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.catalogues.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    updateCombo(itemsOrigen, itemsDestino, condicion, combo) {
      itemsDestino.splice(0, itemsDestino.length);

      for (let item of itemsOrigen) {
        if (item.bus_id === condicion) {
          itemsDestino.push({
            value: item.value,
            text: item.text,
            routes: item.routes,
          });
        }
      }

      return itemsDestino;
    },
    close() {
      this.dialog = false;
      this.itemSelected = [];
      this.editedItem = [];
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      let details = [];
      let warehouse = null;
      for (let item of this.itemSelected) {
        if (item.category_id == undefined) {
          this.$toast.info(
            "debes agregar una categoria al item " + item.item_name + " !"
          );
          return;
        }
        if (item.sale_price == undefined || item.sale_price == null) {
          this.$toast.info("Debes agregar un precio al producto " + item.item_name);
          return;
        }
        if (item.sale_price < 0) {
          this.$toast.info(
            "el item del producto " + item.item_name + " debe ser mayor que 0!"
          );
          return;
        }
        if (item.item_type_id == 1 || item.item_type_id == 5) {
          if (item.warehouse_id == undefined) {
            this.$toast.info(
              "debes agregar un almacen a descontar para " + item.item_name + " !"
            );
            return;
          } else {
            warehouse = item.warehouse_id;
          }
        } else {
          warehouse = null;
        }
        console.log(item);
        details.push({
          item_id: item.item_id,
          cost_price: parseFloat(item.cost_price),
          sale_price: parseFloat(item.sale_price),
          sale_price2: parseFloat(item.sale_price2),
          sale_price3: parseFloat(item.sale_price3),
          code: item.code,
          warehouse_id: warehouse,
          min: item.min,
          max: item.max,
          category_id: item.category_id,
        });
      }
      let item = {
        catalogue_id: this.editedItem.id,
        details: details,
        bussines_unit: this.editedItem.business_unit_id,
        location: this.editedItem.location_id,
        name: this.editedItem.name.toUpperCase().trim(),
      };

      if (this.editedIndex > -1) {
        const responseRequest = await this.api_Put("inventory/catalogues/details", item);

        if (responseRequest.status == 200) {
          let data = await responseRequest.json();
          this.initialize();
          this.$toast.success("agregado con exito!");
          this.dialog = false;
        } else {
          this.$toast.error("error al registrar el catalogo!");
        }
      } else {
        if (this.editedItem.business_unit_id == "") {
          this.$toast.error("debes selecionar una unidad de negocio!");
        }
        if (this.editedItem.location == "") {
          this.$toast.error("debes selecionar un location!");
        }

        if (this.editedItem.name == "") {
          this.$toast.error("debes selecionar un nombre!");
        }
        const responseRequest = await this.api_Post("inventory/catalogues/details", item);

        if (responseRequest.status == 200) {
          let data = await responseRequest.json();
          this.initialize();
          this.$toast.success("agregado con exito!");
          this.dialog = false;
        } else {
          this.$toast.error("error al registrar el catalogo!");
        }
        //this.catalogues.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
