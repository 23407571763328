<template>
  <v-row class="pt-2">
    <v-col cols="12" sm="4" :md="type ? 4 : 6" class="pt-0 pb-0">
      <v-autocomplete dense outlined v-model="business_selected" :items="bussines_units" @input="sendEmit()"
        @change="setLocation" label="Unidad de negocio" color="cyanText" prepend-inner-icon="corporate_fare"
        required></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="4" :md="type ? 4 : 6" class="pt-0 pb-0">
      <v-autocomplete dense outlined v-model="location_selected" return-object :items="locationsFrombusiness"
        @input="sendEmit()" color="cyanText" label="Location" prepend-inner-icon="my_location" required>
        <template v-slot:no-data>
          <div class="px-4">Seleciona la unidad de negocio</div>
        </template>
      </v-autocomplete>
    </v-col>

    <v-col v-if="type" cols="12" sm="4" md="4" class="pt-0 pb-0">
      <v-autocomplete dense outlined v-model="filters.department_id" :items="itemsDepartment" @input="sendEmit()"
        return-object color="cyanText" label="Departamento" prepend-inner-icon="business" required></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    bussines_units: [],
    itemsDepartment: [],
    filters: {
      business: null,
      location: null,
      department_id: null,
    },
  }),
  props: {
    type: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.initialized();
  },

  computed: {
    business_selected: {
      get() {
        return this.$store.state.app.filters.business;
      },
      set(value) {
        this.$store.commit("setBusiness", value);
      },
    },
    location_selected: {
      get() {
        return this.$store.state.app.filters.location;
      },
      set(value) {
        this.$store.commit("setLocation", value);
      },
    },
    locationsFrombusiness() {
      let locations = Object.assign(
        [],
        this.$store.state.auth.locations.filter(
          (element) => element.bus_id == this.filters.business
        )
      );
      if (locations.length > 1) {
        locations.unshift({ id: 0, value: 0, text: "Todos" });
      }
      //    this.filters.location = locations[0].id;
      return locations;
    },
  },
  watch: {},
  methods: {
    setLocation() {
      if (this.business_selected != 0 || this.business_selected != null) {
        if (this.locationsFrombusiness.length > 0) {
          this.filters.location = this.locationsFrombusiness[0].id;
        }
      } else {
        this.filters.location = null;
      }
    },
    async initialized() {
      this.editedItem = Object.assign(
        {},
        this.$store.state.auth.bussines_units
      );
      this.bussines_units = Object.assign(
        [],
        this.$store.state.auth.bussines_units
      );

      //   if(this.$store.state.auth.bussines_units.length>1)
      //   {
      //     if(this.data==true)
      //     {
      //     this.bussines_units.unshift({ id: 0, value: 0, text: 'Seleciona una unidad' })
      //   }
      // }

      //   this.filters.business = this.bussines_units[0].id;
      //this.itemsDepartment = await this.g_departments();
      this.itemsDepartment.unshift({ text: "Todos", value: 0 });
      this.filters.department_id = this.itemsDepartment[0].value;
      this.sendEmit();
    },
    sendEmit() {
      this.filters.business = this.business_selected;
      this.filters.location = this.location_selected.id;
      this.$emit("filter_loaded", this.filters);
    },
  },
};
</script>

<style></style>

<style></style>
