<template>
  <v-container fluid>
    <v-layout align-start>
      <v-flex>
        <v-card flat>
          <v-row no-gutters justify="center">
            <span style="font-size: 34px; color: #616161"><v-icon large class="pr-1 pb-2">autorenew</v-icon>Ajuste de
              inventario</span>
          </v-row>
          <v-card-text>
            <HeaderFilters v-if="showHeader" @refreshWarehouse="getAdjustments" :filter_default="defaultFilters" />
            <v-card outlined class="mt-2 pt-2">
              <v-card-text class="pa-0">
                <v-data-table :headers="headerArticle" :search="search" :items="adjustments">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Activos</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <span>{{ adjustments.length }}</span>
                      <v-spacer></v-spacer>
                      <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Buscar"
                        single-line hide-details>
                      </v-text-field>
                      <v-spacer></v-spacer>
                      <v-divider class="mx-3" inset vertical></v-divider>
                      <v-btn color="success" class="mr-2" outlined @click="newAdjustment()">
                        Nuevo ajuste
                        <v-icon>add</v-icon>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.show`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="primary" @click="viewDetail(item)" large>
                          swap_vertical_circle
                        </v-icon>
                      </template>
                      <span>Ajustar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
        <v-dialog v-model="dialogAdjunct" fullscreen persistent>
          <v-card outlined color="#F4F5FB">
            <v-card-title>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" small @click="showBar = !showBar" v-click-outside="onClickOutside"
                    color="primary" outlined class="mr-3">
                    <v-icon>{{
                      showBar == false ? "menu" : "menu_open"
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  showBar == false ? "Ver menu" : "Ocultar menu"
                }}</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-btn v-show="$route.params.id != undefined" small @click="backToInventoryValue()" color="primary" outlined
                class="mr-3">
                <v-icon>keyboard_backspace</v-icon>
                Volver</v-btn>
              <span :style="`color:#757575;font-size:20px`">{{
                dialogTitle
              }}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="(dialogAdjunct = false), ($route.params.id = undefined)">
                <v-icon>clear</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-divider class="mx-2" inset></v-divider>
              <NavigationButonsTop :showBar="showBar" />
              <v-container>
                <v-card class="pt-3" outlined>
                  <v-container>
                    <v-row justify="center" v-if="isNewAdjustment" class="mb-3">
                      <v-col cols="12" md="8">
                        <v-card-text class="pa-3">
                          <v-text-field v-model="note" outlined placeholder="Comentario"></v-text-field>
                          </v-card-text>
                        <v-card outlined @click="dialogItems = true" fill-height>
                      
                          <v-card-text class="pa-3">
                            <span :style="`font-size:20px`" class="d-flex justify-center align-center" align="center"
                              justify="center">
                              Agregar articulo<v-icon color="success">add</v-icon>
                            </span>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row justify="center" class="mb-3">
                      <v-col cols="12" md="6" class="pa-0">
                        <span :style="`font-size:20px`" class="d-flex justify-center align-center" align="center"
                          justify="center">
                          {{ adjustmentDetails.length }} articulos ajustados por
                          {{ responsable }}</span>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-3" v-if="adjustmentDetails.length > 0"></v-divider>
                    <v-row justify="center" v-for="item in adjustmentDetails" :key="item.item_id" class="ma-0">
                      <v-col cols="12" md="3">
                        <v-text-field v-model="item.name" label="Articulo" dense outlined readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field v-model="item.old_quantity" label="Cantidad anterior" dense outlined readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field v-model="item.new_quantity" label="Nueva cantidad" dense outlined
                          :readonly="!isNewAdjustment" type="number">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field :value="itemDetailDifferent(item)" label="Diferencia" dense outlined readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="1" class="mt-2" v-if="isNewAdjustment">
                        <v-icon color="error" @click="removeItem(item)">close</v-icon>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12" align="right">
                        <v-btn text color="success" @click="saveAdjustment"
                          v-if="isNewAdjustment && adjustmentDetails.length > 0">Guardar</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-dialog v-if="$store.state.app.filters.location != null" width="50%" v-model="dialogItems">
      
      <articles_catalogue v-if="refresArticles" @selected-item="addItem" :removeItemSelected="false" :data="{
        location: $store.state.app.filters.location.id,
        business: $store.state.app.filters.business,
        warehouse: filters.warehouse.id,
        catalogue: filters.location.catalogue_id,
      }" @close-item="dialogItems = false" />
    </v-dialog>
  </v-container>
</template>
<script>
import moment from "moment";
import articles_catalogue from "@/components/Inventory/CatalogeForAdjustment.vue";
import HeaderFilters from "@/components/Inventory/HeaderFilters.vue";
import NavigationButonsTop from "@/components/Inventory/NavigationButonsTop.vue";
export default {
  data: () => ({
    showBar: false,
    showHeader: false,
    filters: {
      location: { catalogue_id: "", id: "" },
      business: "",
      warehouse: { id: "", name: "" },
      dateStart: null,
      dateEnd: null,
    },
    note:"",
    dialogAdjunct: false,
    refresArticles: false,
    newQuantity: 0,
    categorys: [],
    dialogItems: false,
    adjustmentDetails: [
      {
        item_id: null,
        name: null,
        old_quantity: null,
        new_quantity: null,
        quantity_difference: null,
      },
    ],
  
    isNewAdjustment: false,
    adjustments: [],
    search: "",
    headerArticle: [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      { text: "Nota", value: "note" },
      { text: "Location", value: "location" },
      { text: "Fecha", value: "effective_date" },
      { text: "Responsable", value: "use_fullname" },
      { text: "", value: "show", sortable: false },
    ],
    responsable: null,
    showAll: true,
    articleSelected: { item_name: "", available: 0 },
    rangeSelected: "3",
    dateHeader: "2022-01-01",
    defaultFilters: [],
  }),
  components: {
    articles_catalogue,
    HeaderFilters,
    NavigationButonsTop,
  },
  created() { },
  mounted() {
    if (this.$route.params.id == undefined) {
      this.showHeader = true;
    }
    //  this.getAdjustments();
  },
  computed: {
    dialogTitle() {
      if (this.filters.warehouse == undefined) {
        return "Ajuste";
      }
      if (this.isNewAdjustment == true) {
        return "Ajustar el inventario del " + this.filters.warehouse.name;
      }
      return `Ajuste del ${this.filters.warehouse.name} (${this.dateHeader})`;
    },
  },
  watch: {},
  methods: {
    async getAdjustments(filters) {
  
      //si recibe parametros de la ventana de stock ejecuta esa consulta
      if (this.$route.params.id != undefined) {
        this.filters = Object.assign({}, this.$route.params.filtersDefault);
        this.defaultFilters = [];
        this.defaultFilters.push(
          Object.assign({}, this.$route.params.filtersDefault)
        );
        this.viewDetail({
          id: this.$route.params.id,
          use_fullname: this.$route.params.user,
          effective_date: this.$route.params.effective_date,
        });

        if (
          this.filters.warehouse == undefined ||
          this.filters.warehouse.id == null
        ) {
          return;
        }
      } else {
        this.filters = filters;
        if (
          this.filters.warehouse == undefined ||
          this.filters.warehouse.id == null
        ) {
          this.$toast.warning("Escoja un almacen!");
          return;
        }
      }
      this.refresArticles = false;
      this.adjustments = [];
      if (this.filters.location.id == null) {
        return;
      }
      let filtros = `WHERE WAREHOUSE_ID=${this.filters.warehouse.id}  AND LOCATION_ID=${this.filters.location.id}
	
      AND EFFECTIVE_DATE BETWEEN '${this.filters.dateStart}' AND '${this.filters.dateEnd}' 
      `;

      const request = await this.api_Post("inventory/adjustmentsHistory", {
        filters: filtros,
      });
      const data = await request.json();

      if (request.status == 200) {
        this.adjustments = data;
      } else {
        this.$toast.error(data.error);
      }

      this.refresArticles = true;
      this.showHeader = true;
    },
    async viewDetail(item) {
      this.dateHeader = moment(item.effective_date).format("DD/MM/YYYY HH:mm");
      this.isNewAdjustment = false;
      const request = await this.api_Get(
        "inventory/adjustmentDetails/" + item.id
      );
      const data = await request.json();
      if (request.status == 200) {
        this.adjustmentDetails = data;
        this.responsable = item.use_fullname;
      } else {
        this.$toast.error(data.error);
      }
      this.dialogAdjunct = true;
    },
    newAdjustment() {
    
      if (this.filters.warehouse == undefined) {
        this.$toast.warning("Escoja un almacen!");
        return;
      }
      this.responsable = this.$store.state.auth.user.user.fullname;
      this.adjustmentDetails = [];
      this.isNewAdjustment = true;
      this.dialogAdjunct = true;
    },
    addItem(item) {
      let existArticle = this.adjustmentDetails.find(
        (element) => element.item_id == item.item_id
      );

      if (existArticle != undefined) {
        this.$toast.warning(
          "Este articulo ya se encuentra en la lista, puede modificar su nueva cantidad!"
        );
      } else {
        this.adjustmentDetails.push({
          item_id: item.item_id,
          name: item.item_name,
          old_quantity: item.quantity,
          new_quantity: item.quantity,
          quantity_difference: 0,
        });
      }
      this.dialogItems = false;
    },
    async saveAdjustment() {
      try {
        if (this.adjustmentDetails.length == 0) {
          this.$toast.warning("Agregue al menos un articulo!");
          return;
        }
        const request = await this.api_Post("inventory/do_adjustment", {
          created_by: this.$store.state.auth.user.user.user_id,
          warehouse_id: this.filters.warehouse.id,
          business_unit_id: this.filters.business,
          location_id: this.filters.location.id,
          adjustment: this.adjustmentDetails,
          note:this.note
        });
        const data = await request.json();
        if (request.status == 200) {
          this.$toast.success("Registrado correctamente!");
          this.getAdjustments(this.filters);
          this.note=""
          this.dialogAdjunct = false;
        } else {
          this.$toast.error(data.error);
        }
      } catch (error) { }
    },
    itemDetailDifferent(item) {
      // if (parseFloat(item.new_quantity) == 0) {
      //   return 0;
      // }
      return parseFloat(item.new_quantity) - parseFloat(item.old_quantity);
    },
    removeItem(item) {
      let indice = this.adjustmentDetails.indexOf(item);
      this.adjustmentDetails.splice(indice, 1);
    },
    backToInventoryValue() {
      this.$router.push({
        name: "InventoryManagement",
        params: {
          filtersDefault: this.filters,
        },
      });
    },
    onClickOutside() {
      this.showBar = false;
    },
  },
};
</script>
